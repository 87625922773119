/* global isMobile */

const templateForDevice = (
  desktopTemplate,
  mobileTemplate,
  customOptions = {}
) => {
  const options = {
    phoneOnly: true,
    ...customOptions,
  }

  return isMobile.check(options) ? mobileTemplate : desktopTemplate
}

export { templateForDevice }
