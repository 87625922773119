const BZScoreModalCtrl = [
  '$uibModalInstance',
  'inputData',
  '$scope',
  'trackBzScoreService',
  function ($uibModalInstance, inputData, $scope, trackBzScoreService) {
    this.$onInit = function () {
      $scope.contractor = inputData.contractor
      $scope.omitQuoteCta = inputData.omitQuoteCta
      trackBzScoreService.explanationModalViewed()
    }

    $scope.dismiss = function ($event) {
      $uibModalInstance.dismiss('cancel')
      if ($event) {
        $event.preventDefault()
      }
    }
  },
]

export default BZScoreModalCtrl
