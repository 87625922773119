angular.module('bzProposalsModule').factory('documentModel', [
  '$http',
  'urlConstants',
  'modelService',
  function ($http, urlConstants, modelService) {
    return {
      apiPath: urlConstants.api.v3.path + 'documents/',

      destroy: function (slug) {
        return $http.delete(this.apiPath + slug).then(modelService.extractData)
      },

      duplicate: function (slugHash) {
        return $http
          .post(this.apiPath + 'duplicate', {
            slug_hash: slugHash,
            format: 'dashboard',
          })
          .then(modelService.extractData)
          .then(function (res) {
            return res.document
          })
      },

      saveAsTemplate: function (slugHash) {
        return $http
          .post(this.apiPath + 'save_as_template', {
            slug_hash: slugHash,
            format: 'dashboard',
          })
          .then(modelService.extractData)
          .then(function (res) {
            return res.document
          })
      },
    }
  },
])
