/* globals angular */
angular.module('bzAnalyticsModule', [])

angular.module('bzAnalyticsModule').run([
  'landingPageService',
  'utmService',
  function (landingPageService, utmService) {
    landingPageService.set()
    utmService.setFromUrlParams()
  },
])
