const bzScoreService = [
  function () {
    return {
      degreesToRadians: function (degrees) {
        return degrees * (Math.PI / 180)
      },

      posOnCircle: function (theta, radius, strokeOffset) {
        var x = Math.cos(this.degreesToRadians(theta)) * radius
        var y = Math.sin(this.degreesToRadians(theta)) * radius

        return {
          top: radius - y + strokeOffset,
          left: radius - x + strokeOffset,
        }
      },
    }
  },
]

export default bzScoreService
