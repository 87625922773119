/*
	Example usage:
		<file-upload-button-wrapper on-upload="$ctrl.myCallback(files)">
			<div class="upload-button">
				My upload button here
			</div>
		</file-upload-button-wrapper>
*/

bz_app.component('fileUploadButtonWrapper', {
  transclude: true,
  bindings: {
    onUpload: '&',
    buttonClass: '@',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/file_upload_button_wrapper'
      )
    },
  ],
  controller: [
    function () {
      this.$onInit = function () {
        this.fileUploadId = Math.random().toString().slice(2)
      }
    },
  ],
})
