/* global bz_app */
bz_app.component('datepicker', {
  bindings: {
    date: '=',
    hour: '<',
    handleChange: '&?',
    customDatepickerOptions: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('_components/components/datepicker')
    },
  ],
  controller: [
    'uibDatepickerPopupConfig',
    function (uibDatepickerPopupConfig) {
      this.$onInit = function () {
        this.setMinDate()
        this.format = 'MM/dd/yyyy'
        this.calendarOpen = false
        this.defaultDateOptions = {
          minDate: this.minDate,
          showWeeks: false,
          timezone: undefined,
        }
        this.dateOptions = angular.extend(
          this.defaultDateOptions,
          this.customDatepickerOptions
        )
        uibDatepickerPopupConfig.showButtonBar = false
      }

      this.setMinDate = function () {
        var date = new Date()
        date.setDate(date.getDate())
        this.minDate = date
      }

      this.openCalendar = function () {
        this.calendarOpen = true
      }

      this.onChange = function () {
        this.date &&
          typeof this.hour !== 'undefined' &&
          this.date.setHours(this.hour)
        this.handleChange({ newDate: this.date })
      }
    },
  ],
})
