bz_app.controller('PlanToBidModalCtrl', [
  'inputData',
  '$uibModalInstance',
  'trackLeadsService',
  'leadModel',
  function (inputData, $uibModalInstance, trackLeadsService, leadModel) {
    this.$onInit = function () {
      this.lead = inputData.lead
    }

    this.planToBid = function () {
      trackLeadsService.bidIntent(this.lead, { action: 'CO intends to bid' })
      leadModel.setBidReminder(this.lead.project.id)
      this.closeModal()
    }

    this.notReadyToBid = function () {
      trackLeadsService.bidIntent(this.lead, {
        action: 'CO does not intend to bid',
      })
      this.closeModal()
    }

    this.closeModal = function () {
      $uibModalInstance.close('ok')
    }
  },
])
