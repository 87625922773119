/* global angular, google */
angular.module('bzMapModule').factory('mapConfigService', function () {
  return {
    disableDefaultOptions: function () {
      return {
        disableDoubleClickZoom: true,
        disableDefaultUI: true,
        panControl: false,
        zoomControl: false,
        scaleControl: false,
        scrollwheel: false,
        gestureHandling: 'none',
      }
    },
    mapById: function (id, options) {
      return new google.maps.Map(document.getElementById(id), options)
    },
    marker: function (options) {
      return new google.maps.Marker({
        position: options.position,
        map: options.map,
        icon: options.icon || null,
        title: options.title || null,
        opacity: options.opacity || 1,
      })
    },
    milesRadius: function (miles) {
      return (miles || 25) * 1609.34
    },
  }
})
