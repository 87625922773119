/* eslint no-mixed-spaces-and-tabs: 0 */
bz_app.factory('reviewResponseModel', [
  '$rootScope',
  '$timeout',
  'reviewResponseData',
  'reviewData',
  function ($rootScope, $timeout, reviewResponseData, reviewData) {
    return {
      reviewResponseAPI: new ReviewResponseAPI(),
      data: reviewData.response,
      create: function () {
        var that = this
        this.reviewResponseAPI
          .create(JSON.parse(angular.toJson(this.data)))
          .then(function (response) {
            if (response.success) {
              $timeout(function () {
                $rootScope.$apply(function () {
                  that.data.id = response.review_response.id
                })
              })
              var $item = $('ul').find(
                "li[data-review-id='" +
                  response.review_response.review_id +
                  "']"
              )
              $item.find('.review-response-button').addClass('hide')
            } else {
              $timeout(function () {
                $rootScope.$apply(function () {
                  that.data.status = {
                    submitted: false,
                    error: true,
                    errorMessage: response.message,
                  }
                })
              })
            }
          })
      },
      update: function () {
        var that = this
        this.reviewResponseAPI
          .update(JSON.parse(angular.toJson(this.data)))
          .then(function (response) {
            if (response.success) {
              var $item = $('ul').find(
                "li[data-review-id='" +
                  response.review_response.review_id +
                  "']"
              )
              $item.find('.review_response .status').text('Pending')
              $item
                .find('.review_response .body')
                .text(response.review_response.body)
              $item
                .find('.review-response-button')
                .data('review-response-body', response.review_response.body)
              $item
                .find('.review-response-button')
                .data('review-response-status', 'Pending')
            } else {
              $timeout(function () {
                $rootScope.$apply(function () {
                  that.data.status = {
                    submitted: false,
                    error: true,
                    errorMessage: response.message,
                  }
                })
              })
            }
          })
      },
      destroy: function () {
        this.data.status = 'deleted'
        this.reviewResponseAPI
          .update(JSON.parse(angular.toJson(this.data)))
          .then(function (response) {
            if (response.success) {
              var $item = $('ul').find(
                "li[data-review-id='" +
                  response.review_response.review_id +
                  "']"
              )
              $item.find('.review_response .status').text('Deleted')
              $item.find('.review-response-button').addClass('hide')
            }
          })
      },
      updateStatus: function (status) {
        this.data.status = status
        this.reviewResponseAPI
          .update(JSON.parse(angular.toJson(this.data)))
          .then(function (response) {
            if (response.success) {
              var $item = $('ul').find(
                "li[data-review-id='" +
                  response.review_response.review_id +
                  "']"
              )
              $item.find('.review-response-button').addClass('hide')
            }
          })
      },
    }
  },
])
