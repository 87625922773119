/* global angular */

import contractorClaimBusinessUserFullScreen from './components/contractor_claim_business_user_fullscreen_component'
import { contractorClaimTooltipIndicator } from './components/contractor_claim_tooltip_indicator_component'
import { trackClaimScreenService } from './services/track_claim_screen_service'

angular
  .module('bzClaimModalModule', [])
  .component(
    'contractorClaimBusinessUserFullScreen',
    contractorClaimBusinessUserFullScreen
  )
  .component('contractorClaimTooltipIndicator', contractorClaimTooltipIndicator)
  .service('trackClaimScreenService', trackClaimScreenService)
