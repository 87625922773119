/* global angular */
angular
  .module('bzProjectPreferencesModule')
  .factory('contractorProjectPreferencesService', [
    'attributeConversionService',
    function (attributeConversionService) {
      return {
        prepareContractor: function (data) {
          var contractor =
            attributeConversionService.underscoreToCamelDeepAttributes(
              data.contractorData
            )

          if (!contractor.workPreferences) {
            contractor.workPreferences = {}
          }

          if (data.contractorProjectPreferences) {
            var extra =
              attributeConversionService.underscoreToCamelDeepAttributes(
                data.contractorProjectPreferences.extra
              )
            if (extra) {
              contractor.minJobValue = extra.minJobValue
              contractor.maxJobValue = extra.maxJobValue
              contractor.travelRange = extra.travelRange
            }

            if (contractor.location) {
              contractor.location.latitude =
                data.contractorProjectPreferences.latitude
              contractor.location.longitude =
                data.contractorProjectPreferences.longitude
            } else {
              contractor.location = {
                latitude: data.contractorProjectPreferences.latitude,
                longitude: data.contractorProjectPreferences.longitude,
              }
            }
          }

          contractor.validLocation = false
          contractor.license = {
            number: contractor.licenseNumber || null,
            agencyId: contractor.licenseSource
              ? contractor.licenseSource.id
              : null,
            agencyName: contractor.licenseSource
              ? contractor.licenseSource.fullName
              : null,
            notNeeded: false,
          }

          return contractor
        },
        displayGroups: function (contractorTypeId) {
          return contractorTypeId === 1
        },
        getSelectedGroupProjectTypeIds: function (projectTypesDisplayed) {
          return [].concat.apply(
            [],
            projectTypesDisplayed
              .filter(function (group) {
                return group.selected
              })
              .map(function (group) {
                return group.projectTypes.map(function (type) {
                  return type.id
                })
              })
          )
        },
        getSelectedProjectTypeIds: function (projectTypesDisplayed) {
          return projectTypesDisplayed
            .filter(function (type) {
              return type.selected
            })
            .map(function (type) {
              return type.id
            })
        },
        setProjectTypesDisplayed: function (groups, contractorTypeId) {
          if (this.displayGroups(contractorTypeId)) {
            return groups
          } else {
            return [].concat.apply(
              [],
              groups.map(function (group) {
                return group.projectTypes
              })
            )
          }
        },

        getContractorType: function (contractorTypeId, contractorTypes) {
          for (let i = 0; i < contractorTypes.length; i++) {
            if (contractorTypes[i].id === contractorTypeId)
              return contractorTypes[i]
          }
          return null
        },
      }
    },
  ])
