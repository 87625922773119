/* global angular */
angular.module('bzUserModule').factory('claimBusinessSearchModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      searchApiPath: urlConstants.api.v3.path + 'claim_business_contractors',
      loadContractors: function (params) {
        params = attributeConversionService.camelToUnderscoreDeepAttributes(
          angular.merge({ pageSize: 5 }, params)
        )

        return $http
          .get(this.searchApiPath, { params: params })
          .then(this.extractData)
      },
      extractData: function (res) {
        if (res.data) {
          return attributeConversionService.underscoreToCamelDeepAttributes(
            res.data
          )
        } else {
          return null
        }
      },
    }
  },
])
