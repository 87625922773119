/* global UserData, angular */
angular.module('bzProjectFormModule').factory('projectFormService', [
  'projectFormModel',
  'contractorsSearchService',
  '$timeout',
  'serviceRequestInstance',
  'urlService',
  'urlConstants',
  function (
    projectFormModel,
    contractorsSearchService,
    $timeout,
    serviceRequestInstance,
    urlService,
    urlConstants
  ) {
    return {
      projectTypeGroupIds: {
        NEW_CONSTRUCTION: 1,
        HOME_ADDITION: 2,
      },
      searchContractors: function (location) {
        let service = projectFormModel.data.title
        let url
        if (location.slug && service) {
          url = '/' + location.slug + '/' + service.trim().replace(/\s+/g, '-')
        } else if (location.slug) {
          url = '/' + location.slug
        } else if (service) {
          url = '/search?keywords=' + service.trim().replace(/\s+/g, '-')
        }

        let data = {
          city: location.city + ', ' + location.state,
          keywords: service,
          latitude: location.lat,
          longitude: location.lon,
        }
        if (url) {
          return contractorsSearchService.submitSearchForm(url, data)
        }
      },
      newProject: function (data) {
        let project = new serviceRequestInstance().set({
          id: null,
          open: true,
          summary: {
            title: data.title && data.title.length >= 3 ? data.title : '',
            urgency: data.urgency,
            budget: null,
            description: data.description || '',
          },
          location: {
            streetAddress: null,
            cityState: null,
            zipcode: data.zipcode,
          },
          projectOwner: {
            userId: null,
            fullName: data.fullName || '',
            phone: data.phone || '',
            email: data.email || '',
            password: '',
            isNew: true,
          },
          outcome: {},
          expectedResponses: this.isExclusiveLead(data) ? 1 : null,
          isPotentialLead: this.isExclusiveLead(data) ? 1 : 0,
          currentStep: 'matching',
          projectType: {
            projectTypeGroupId: null,
          },
        })

        if (UserData.user_id()) {
          project.projectOwner.userId = UserData.user_id()
          project.projectOwner.email = UserData.user_email()
          project.projectOwner.phone = UserData.user_phone().toString()
          project.projectOwner.isNew = false
        }

        return project
      },
      isExclusiveLead: function (data) {
        return data.potentialLeadFormVersion && data.potentialLeadFormVersion
      },
      shouldBePotentialLead: function (expectedResponses, budget) {
        let budgetRange = this.parseBudget(budget)
        return (
          expectedResponses === 1 &&
          budget !== 'private' &&
          (budgetRange.min < 20000 || !budgetRange.min)
        )
      },
      parseBudget: function (budget) {
        let range = { min: null, max: null }

        if (budget) {
          let splitBudget = budget.split('-')

          if (splitBudget && splitBudget.length > 1) {
            range.min = parseInt(splitBudget[0])
            range.max = parseInt(splitBudget[1])
          }
        }

        return range
      },
      onCloseRedirect: function (project) {
        if (project && project.id) {
          document.location = '/project/' + project.id
        } else if (
          !document.referrer ||
          document.referrer.match(/buildzoom.com/g) === null
        ) {
          document.location = urlService.homepageUrl()
        } else if (
          project &&
          project.analytics &&
          project.analytics.potentialLeadContractorId
        ) {
          document.location =
            urlConstants.contractor.profileURL +
            project.analytics.potentialLeadContractorId
        } else if (window.history && window.history.pushState) {
          window.history.back()
        } else if (document.referrer && document.referrer.length) {
          document.location = document.referrer
        } else {
          document.location = urlService.homepageUrl()
        }
      },
      getCloseConfirmVersion: function (options) {
        if (document.location.pathname === '/') {
          if (options && options.version) {
            return options.version
          } else {
            return [2, 4, 5][Math.floor(Math.random() * 3)]
          }
        }

        return 2
      },
      currentDevice: function () {
        let device = 'desktop'

        if (isMobile.check({ phoneOnly: true })) {
          device = 'mobile'
        } else if (isMobile.check()) {
          device = 'tablet'
        }

        return device
      },
    }
  },
])
