/**	service provides functionality for Stripe	*/
bz_app.factory('stripeService', [
  'applicationConstants',
  'urlConstants',
  function (applicationConstants, urlConstants) {
    return {
      checkout_handler: null,
      default_checkout_config: {
        key: applicationConstants.stripe.key,
        image: applicationConstants.stripe.stock_image_url,
        locale: 'auto',
      },
      open_checkout: function (email) {
        var settings = {
          name: 'Buildzoom',
          description: '',
          panelLabel: 'Continue',
          email: email || '',
        }
        this.checkout_handler.open(settings)
      },
      init_checkout: function (callback) {
        $.getScript(applicationConstants.stripe.checkout_url, callback)
      },
      init_lib: function () {
        $.getScript(applicationConstants.stripe.lib_url, this.load_key)
      },
      load_key: function () {
        Stripe.setPublishableKey(applicationConstants.stripe.key)
      },
      createToken: function (form, responseHandler) {
        Stripe.card.createToken(form, responseHandler)
      },
      getDateByMonthAndYear: function (month, year) {
        return new Date(new Date().setUTCFullYear(year, month - 1))
      },
      dateYearDifference: function (date1, date2) {
        return Math.floor((date1 - date2) / (1000 * 60 * 60 * 24 * 365))
      },
      validateExpiry: function (expiry) {
        if (!Stripe.card.validateExpiry(expiry)) {
          return false
        }

        var expiryMonth = parseInt(expiry.split('/')[0])
        var expiryYear = parseInt(expiry.split('/')[1])

        // anything card with an expiration greater than 50 years not allowed by stripe
        return (
          this.dateYearDifference(
            this.getDateByMonthAndYear(expiryMonth, expiryYear),
            new Date()
          ) <= 50
        )
      },
      validateCardNumber: function (number) {
        return Stripe.card.validateCardNumber(number)
      },
      validateCVC: function (cvc) {
        return Stripe.card.validateCVC(cvc)
      },
    }
  },
])
