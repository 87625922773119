/* global bz_app, angular */
bz_app.factory('bidAttachmentModel', [
  'filepickerService',
  'filestackService',
  'uploadModel',
  '$q',
  function (filepickerService, filestackService, uploadModel, $q) {
    return {
      attach: function (bid, callback, options) {
        options = options || {}

        if (options.version === 2) {
          return this.attachV2(bid, options)
        } else {
          this.attachV1(bid, callback)
        }
      },
      attachV1: function (bid, callback) {
        filepickerService.upload(
          function (InkBlobs) {
            // bring up upload modal
            if (InkBlobs && InkBlobs.length) {
              angular.forEach(
                InkBlobs,
                function (file) {
                  var attachment = uploadModel.inkBlobToAttachment(file)
                  attachment.bidId = undefined
                  attachment.contractorId = bid.contractorId

                  uploadModel.add(attachment).then(
                    function (response) {
                      if (response) {
                        attachment.id = response.image
                        bid.attachments.push(attachment)

                        if (callback !== undefined) {
                          callback()
                        }
                      }
                    }.bind(this)
                  )
                }.bind(this)
              )
            }
          }.bind(this)
        )
      },
      attachV2: function (bid, options) {
        var files = []
        var deferred = $q.defer()

        angular.forEach(
          options.files,
          function (selectedFile) {
            filestackService.upload(selectedFile).then(function (file) {
              var attachment = uploadModel.inkBlobToAttachment(file)
              attachment.bidId = undefined
              attachment.contractorId = bid.contractorId

              uploadModel.add(attachment).then(
                function (response) {
                  if (response) {
                    attachment.id = response.image
                    files.push(attachment)

                    bid.attachments.push(attachment)

                    if (files.length === options.files.length) {
                      deferred.resolve(files)
                    }
                  }
                }.bind(this)
              )
            })
          }.bind(this)
        )

        return deferred.promise
      },
    }
  },
])
