import template from './lead_referral_screen.html'

const leadReferralScreen = {
  bindings: {
    lead: '<',
    onComplete: '&',
  },
  template,
  controller: [
    'leadReferralInstance',
    'trackDeclineLeadService',
    function (leadReferralInstance, trackDeclineLeadService) {
      this.$onInit = function () {
        trackDeclineLeadService.viewReferralForm(this.lead)
        this.referral = new leadReferralInstance()
        this.referral.set({
          serviceRequestId: this.lead.project.id,
          lead: this.lead,
        })
      }

      this.createReferral = function () {
        this.submitting = true

        this.referral.create().then(
          function (res) {
            this.submitting = false
            if (res.success) {
              this.onComplete()
            }
          }.bind(this)
        )
      }

      this.sendButtonText = function () {
        return this.submitting ? 'Sending...' : 'Send'
      }
    },
  ],
}

export default leadReferralScreen
