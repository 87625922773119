/* global $, alert, BZAPI */

var UserAPI = function () {
  this.show_sign_in_modal = function (callback, hide_flash, show_tabs) {
    return this.show_gateway_modal('sign_in', callback, hide_flash, show_tabs)
  }

  this.show_sign_up_modal = function (
    callback,
    hide_flash,
    show_tabs,
    logged_in_callback,
    options
  ) {
    return this.show_gateway_modal(
      'sign_up',
      callback,
      hide_flash,
      show_tabs,
      logged_in_callback,
      options
    )
  }

  this.show_gateway_modal = function (
    type,
    callback,
    hide_flash,
    show_tabs,
    logged_in_callback,
    options
  ) {
    var that = this

    this.status().then(function (response) {
      if (that.is_status_logged_in(response)) {
        if (logged_in_callback) {
          logged_in_callback(response)
        } else {
          document.location = '//' + location.host + '/manage'
        }
      } else {
        $('#new_session_Modal').remove()
        var path = '/user/' + type + '.js?new_user=true'
        path += show_tabs ? '&tabs=true' : '&tabs=false'
        if (options) {
          if (options.contractor) {
            path += '&contractor_id=' + options.contractor.id
          }
          if (options.simple_form) {
            path += '&simple_form=true'
          }
          if (options.active_tab) {
            path += '&active_tab=' + options.active_tab
          }
        }

        return $.when($.get(path)).then(
          function (res) {
            if (callback !== undefined) {
              callback(res)
            }
            if (hide_flash) {
              $('.flash').html('')
            }
          },
          function (failure_res) {
            document.location = path.split('.js')[0]
          }
        )
      }
    })
  }

  this.show_claim_modal = function (
    contractor_id,
    service_request_id,
    interested,
    callback
  ) {
    $('#claim_Modal').remove()
    var path =
      '/api/v3/claim_business_contractors/new?contractor_id=' + contractor_id
    if (service_request_id) {
      path = path + '&service_request_id=' + service_request_id
    }
    if (interested) {
      path = path + '&interested=' + interested
    }

    return $.when($.get(path)).then(
      function (res) {
        if (callback) {
          callback()
        }
      },
      function (failure_res) {
        alert('something went wrong')
      }
    )
  }

  /**	notification settings functios	*/
  this.load_notification_settings = function () {
    return $.when($.get(this.user_api_path() + '/conversation_settings'))
  }

  this.update_notification_settings = function (settings) {
    settings.phone.value = settings.phone.value
      ? settings.phone.value.replace(/\D/g, '')
      : ''

    return $.when(
      $.ajax({
        url: this.user_api_path() + '/conversation_settings',
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({ conversation_settings: settings }),
        dataType: 'json',
      })
    )
  }

  this.verify_contact_method = function (contact_method, allowPoSend = false) {
    var url = this.user_api_path() + '/verify?type=' + contact_method
    if (allowPoSend) {
      url += '&allow_po_send=true'
    }
    return $.when($.get(url))
  }

  this.reset_password = function (user_id) {
    return $.when(
      $.get(this.user_api_path() + '/' + user_id + '/password_reset')
    )
  }

  this.find_existing_user = function (email_str, allow_logged_in) {
    var url =
      this.user_api_path() +
      '/find_existing_user?email=' +
      encodeURIComponent(email_str)
    if (allow_logged_in) {
      url += '&allow_logged_in=true'
    }
    return $.when($.get(url))
  }

  this.status = function () {
    return $.when($.get(this.user_api_path() + '/status'))
  }

  this.payment_settings = function () {
    return $.when($.get(this.user_api_path() + '/payment_settings'))
  }

  this.is_status_logged_in = function (status) {
    return status && status.success && status.logged_in ? true : false
  }

  this.set_sms_subscription = function (
    subscribe,
    callable_type,
    callable_id,
    phone_number
  ) {
    var subscribeBool = subscribe ? '1' : '0'

    return $.when(
      $.ajax({
        url: this.customer_phones_api_path() + '/' + phone_number,
        type: 'PATCH',
        contentType: 'application/json',
        data: JSON.stringify({
          callable_type: callable_type,
          callable_id: callable_id,
          subscribe: subscribeBool,
          number: phone_number,
        }),
        dataType: 'json',
      })
    )
  }

  this.set_email_subscription = function (subscribe, email) {
    return $.when(
      $.ajax({
        url: this.api.v1_path + '/email/set_subscribe_status',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          subscribe: subscribe,
          email: email,
        }),
        dataType: 'json',
      })
    )
  }
}

UserAPI.prototype = new BZAPI()
UserAPI.prototype.constructor = UserAPI

window.UserAPI = UserAPI
