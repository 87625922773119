import {
  RA_BODY_COPY,
  RA_BODY_EXPIRED_COPY,
  RA_BODY_ONLY_CLB_UNSIGNED,
  RA_BODY_UNSIGNED_CLB_WITH_OTHERS_PRESENT,
  RA_CLB_HEADER,
} from 'Vanilla/constants/referral_agreement'

const referralAgreementModalService = [
  'urlConstants',
  'modalScreenService',
  function ({ referralAgreement }, modalScreenService) {
    return {
      launchModal: ({
        source,
        hasSignedReferralAgreement,
        hasOnlyExpiredSignedReferralAgreements,
        hasActiveUnsignedClbAgreementWithNonClbsPresent,
        hasOnlyUnsignedClbReferralAgreement,
        onModalSuccessCallback,
        isLargeProject,
        headerCopy,
        referralFee,
        lead,
      }) => {
        let headCopy = headerCopy
        let bodyCopy = RA_BODY_COPY

        if (hasActiveUnsignedClbAgreementWithNonClbsPresent) {
          headCopy = RA_CLB_HEADER
          bodyCopy = RA_BODY_UNSIGNED_CLB_WITH_OTHERS_PRESENT
        } else if (hasOnlyUnsignedClbReferralAgreement) {
          headCopy = RA_CLB_HEADER
          bodyCopy = RA_BODY_ONLY_CLB_UNSIGNED
        } else if (hasOnlyExpiredSignedReferralAgreements) {
          bodyCopy = RA_BODY_EXPIRED_COPY
        }

        const isClbAgreement =
          hasActiveUnsignedClbAgreementWithNonClbsPresent ||
          hasOnlyUnsignedClbReferralAgreement

        if (lead) {
          if (lead.referralFeeAcceptance && hasSignedReferralAgreement) {
            document.location = `${referralAgreement}?from=${window.location.pathname}&source=${source}`
          } else {
            modalScreenService.initDetachedComponent({
              component: 'referralAgreementModalTest',
              windowClass: 'v3-modal v3-modal-simple medium',
              inputData: {
                source,
                onModalSuccessCallback,
                isLargeProject,
                headerCopy: headCopy,
                bodyCopy,
                referralFee,
                hasSignedReferralAgreement,
                lead,
              },
            })
          }
        } else if (hasSignedReferralAgreement) {
          document.location = `${referralAgreement}?from=${window.location.pathname}&source=${source}`
        } else {
          modalScreenService.initDetachedComponent({
            component: 'referralAgreementModal',
            windowClass: 'v3-modal v3-modal-simple medium',
            inputData: {
              source,
              isClbAgreement,
              onModalSuccessCallback,
              isLargeProject,
              headerCopy: headCopy,
              bodyCopy,
            },
          })
        }
      },
    }
  },
]

export default referralAgreementModalService
