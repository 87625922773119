/* global angular, isMobile, document */

import AdTrackingApi from 'Vanilla/api/ad_tracking_api'

angular.module('bzProjectFormModule').directive('submitToModalButton', [
  '$timeout',
  'projectFormData',
  '$rootScope',
  'projectFormModalService',
  'bzHttp',
  function (
    $timeout,
    projectFormData,
    $rootScope,
    projectFormModalService,
    bzHttp
  ) {
    return {
      restrict: 'AE',
      scope: true,
      link: function (scope, element, attrs) {
        scope.submitToModal = function ($event) {
          if (attrs.adLocation) {
            const apiModel = new AdTrackingApi(bzHttp)
            apiModel.trackEvent({
              contractorTrackId: attrs.contractorTrackId,
              userTrackId: !UserData.user_track_id()
                ? null
                : UserData.user_track_id(),
              adType: 'contractor_ad',
              adLocation: attrs.adLocation,
              eventType: 'click',
            })
          }

          projectFormModalService.submitToModal({
            referralSource: attrs.referralSource,
            title: attrs.title,
            zipcode: attrs.zipcode,
            /*eslint-disable */
            inviteSelectedContractors: eval(attrs.inviteSelectedContractors),
            /*eslint-enable */
            contractorId: attrs.contractorId,
          })

          $event.preventDefault()
        }
      },
    }
  },
])
