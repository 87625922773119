import template from 'StandaloneComponents/subscription_checkboxes.html'
import SubscriptionCheckboxModule from 'StandaloneComponents/subscription_checkbox_component'

const SubscriptionCheckboxesModule = angular
  .module('subscriptionCheckboxes', [SubscriptionCheckboxModule])
  .component('subscriptionCheckboxes', {
    bindings: {
      subscriptions: '<',
      onSubscriptionToggle: '&',
      showDetail: '<',
      columns: '<',
    },
    template,
    controller: [
      'applicationConstants',
      function (applicationConstants) {
        this.isActive = function () {
          return UserData.is_admin() || !this.isDisabledType()
        }

        this.isDisabledType = function () {
          return (
            this.subscription.type.indexOf(
              'account_activity_subscribed_email'
            ) > -1 ||
            this.subscription.type.indexOf('messages_subscribed_email') > -1
          )
        }
      },
    ],
  }).name
export default SubscriptionCheckboxesModule
