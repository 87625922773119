angular.module('bzProjectLabelsModule').controller('NewProjectLabelModalCtrl', [
  '$scope',
  '$uibModalInstance',
  'projectLabelsModel',
  function ($scope, $uibModalInstance, projectLabelsModel) {
    $scope.details = {
      labelName: '',
    }

    $scope.ok = function ($event) {
      if ($scope.details.labelName.length) {
        projectLabelsModel
          .create($scope.details.labelName)
          .then(function (response) {
            if (response && response.success)
              $uibModalInstance.dismiss('cancel')
          })
      } else if ($event) $event.preventDefault()
    }

    $scope.okFromKeyboard = function ($event) {
      if ($event.keyCode === 13) $scope.ok($event)
    }

    $scope.cancel = function ($event) {
      $uibModalInstance.dismiss('cancel')

      if ($event) $event.preventDefault()
    }
  },
])
