import KeyFormatConverter from 'Vanilla/services/key_format_converter'
import { googleGeocode } from 'GlobalShared/utilities/googleGeocoder'

const recentResults = []

export default class Geocoder {
  geocode(query) {
    let cacheResult = this.geocodeFromCache(query)
    if (cacheResult) {
      return new Promise((resolve, reject) => resolve(cacheResult))
    }
    return googleGeocode(query).then((result) => {
      KeyFormatConverter.mutateToCamel(result)
      this.recordResult(result, query)
      return result
    })
  }

  queryFromAddressParts({
    streetAddress = null,
    city = null,
    state = null,
    zipcode = null,
  }) {
    let queryParams = []

    if (streetAddress) queryParams.push(streetAddress)
    if (city) queryParams.push(city)
    if (state) queryParams.push(state)

    let query = queryParams.join(', ')
    if (zipcode) query += ' ' + zipcode

    return query
  }

  /* Service Request specific methods below -- jliu todo: consider refactoring before adding more methods */
  isServiceRequestMatchingGeocode(serviceRequest, result) {
    if (!serviceRequest || !serviceRequest.location || !result) {
      return false
    }

    let streetMatches = this.isStreetAddressMatch(
      serviceRequest.location.streetAddress,
      result.streetAddress
    )
    let zipMatches = serviceRequest.location.zipcode === result.zipcode

    return streetMatches && zipMatches
  }

  assignServiceRequestGeocodeResult(serviceRequest, result) {
    serviceRequest.location.streetAddress =
      result.streetAddress || result.neighborhood
    serviceRequest.location.cityState = result.city + ', ' + result.state
    serviceRequest.location.city = result.city
    serviceRequest.location.state = result.state
    serviceRequest.location.zipcode = result.zipcode
    serviceRequest.geocodedLatitude = result.lat
    serviceRequest.geocodedLongitude = result.lng
  }

  recordServiceRequestLocationIfValid(serviceRequest) {
    if (
      serviceRequest.geocodedLatitude &&
      serviceRequest.geocodedLatitude !== 0 &&
      serviceRequest.geocodedLongitude &&
      serviceRequest.geocodedLongitude !== 0
    ) {
      let query = this.queryFromAddressParts(serviceRequest.location)
      let result = {
        streetAddress: serviceRequest.location.streetAddress,
        city: serviceRequest.location.city,
        state: serviceRequest.location.state,
        zipcode: serviceRequest.location.zipcode,
        lat: serviceRequest.geocodedLatitude,
        lng: serviceRequest.geocodedLongitude,
      }

      this.recordResult(result, query)
    }
  }

  /* end of Service Request specific methods */

  /* Contractor specific methods below -- jliu todo: consider refactoring into contractor Geocoder */
  isContractorMatchingGeocode(contractor, result) {
    if (!contractor || !result) {
      return false
    }

    let streetMatches = this.isStreetAddressMatch(
      contractor.streetAddress,
      result.streetAddress
    )
    let zipMatches = contractor.zipcode === result.zipcode

    return streetMatches && zipMatches
  }

  assignContractorGeocodeResult(contractor, result) {
    contractor.streetAddress = result.streetAddress
    contractor.cityState = result.city + ', ' + result.state // mostly for typeahead only
    contractor.city = result.city
    contractor.state = result.state
    contractor.zipcode = result.zipcode
    contractor.lat = result.lat
    contractor.lng = result.lng
  }

  recordContractorLocationIfValid(contractor) {
    if (
      contractor.lat &&
      contractor.lat !== 0 &&
      contractor.lng &&
      contractor.lng !== 0
    ) {
      let query = this.queryFromAddressParts(contractor)
      let result = {
        streetAddress: contractor.streetAddress,
        city: contractor.city,
        state: contractor.state,
        zipcode: contractor.zipcode,
        lat: contractor.lat,
        lng: contractor.lng,
      }

      this.recordResult(result, query)
    }
  }

  /* end of Contractor specific methods */

  /* Private methods below -- jliu todo: move these outside class def to make real private */
  geocodeFromCache(query) {
    return recentResults.find(
      (result) => result.rawQuery === query || result.completeQuery === query
    )
  }

  recordResult(result, query) {
    if (!result) return
    result.rawQuery = query
    result.completeQuery = this.queryFromAddressParts(result)
    recentResults.push(result) // record for future lookups
  }

  isStreetAddressMatch(queryStreet, resultStreet) {
    let isMatch = false

    let isExactMatch = queryStreet === resultStreet
    let isFalsyMatch =
      (queryStreet === '' ||
        queryStreet === null ||
        queryStreet === undefined) &&
      (resultStreet === '' ||
        resultStreet === null ||
        resultStreet === undefined)

    if (isExactMatch || isFalsyMatch) {
      isMatch = true
    } else if (
      typeof queryStreet === 'string' &&
      typeof resultStreet === 'string' &&
      resultStreet.length > 0
    ) {
      let qAsLowercase = queryStreet.toLowerCase()
      let rAsLowercase = resultStreet.toLowerCase()

      let editDistance = getEditDistance(qAsLowercase, rAsLowercase)
      let maxLetters = Math.max(qAsLowercase.length, rAsLowercase.length)
      let editDistancePercentage = (editDistance / maxLetters) * 100

      isMatch = editDistancePercentage < 30
    }

    return isMatch
  }

  /* end of Private methods */
}

function getEditDistance(a, b) {
  // gist from https://gist.github.com/andrei-m/982927
  if (a.length == 0) return b.length
  if (b.length == 0) return a.length

  let matrix = []

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }

  for (let i = 1; i <= b.length; i++) {
    // Fill in the rest of the matrix
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          ) // deletion
        )
      }
    }
  }

  return matrix[b.length][a.length]
}
