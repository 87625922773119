/* global bz_app */
const globalBannerTracking = [
  function () {
    return {
      title: 'Banner Tracking',
      viewBanner(bannerKey) {
        Track.send(this.title, {
          action: 'view banner',
          url: location.pathname,
          bannerKey,
        })
      },
      clickBannerCTA(bannerKey) {
        Track.send(this.title, {
          action: 'click banner cta',
          url: location.pathname,
          bannerKey,
        })
      },
      clickClose(bannerKey) {
        Track.send(this.title, {
          action: 'close banner',
          url: location.pathname,
          bannerKey,
        })
      },
    }
  },
]

export default globalBannerTracking
