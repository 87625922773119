/* global angular, bz_app */
bz_app.directive('showConversationLink', function () {
  return {
    restrict: 'AEC',
    replace: false,
    scope: {
      conversationId: '@',
    },
    controller: [
      '$scope',
      '$element',
      '$state',
      function ($scope, $element, $state) {
        $element.click(function () {
          angular.element('.mobile-messages-back-link').hide() // hide link back to messages outside of ctrl
          $state.go('conversation', { id: $scope.conversationId })
        })
      },
    ],
  }
})
