import template from './generic_banner_with_buttons.html'

const poBidRequestedBanner = {
  restrict: 'A',
  controllerAs: '$ctrl',
  bindings: {
    bannerKey: '<',
    serviceRequestId: '<',
    poBidRequest: '<',
    poName: '<',
    disablePostpone: '<',
    srTitle: '<',
    hasBlock: '<',
  },
  template,
  controller: [
    'urlService',
    'urlConstants',
    '$uibModal',
    'coBidPostponeModalService',
    'localStorageModel',
    'poBidRequestBannerTracking',
    'dateService',
    function (
      urlService,
      urlConstants,
      $uibModal,
      coBidPostponeModalService,
      localStorageModel,
      poBidRequestBannerTracking,
      dateService
    ) {
      this.$onInit = () => {
        this.className = this.hasBlock ? 'bg-red' : 'hidden-xs'
        const bidRequestedAt = dateService.prettyShortDate(
          new Date(this.poBidRequest.createdAt)
        )

        this.copy = `${this.poName} requested an official bid for their <a class="underline" href='/lead/${this.serviceRequestId}'>${this.srTitle}</a> project on ${bidRequestedAt}.`

        if (this.hasBlock) {
          this.copy +=
            "<br> You'll regain access to new projects once you provide a response to the client."
        }

        this.buttons = [
          {
            class: `u-bg-white ${
              this.hasBlock ? 'u-alert-red' : 'u-banner-green'
            }`,
            text: 'Submit Bid',
            onClick: () => {
              localStorageModel.set('display_bid_modal', 1)
              poBidRequestBannerTracking.bannerSubmitBid(this.poBidRequest.id)
              window.location = `/lead/${this.serviceRequestId}`
            },
          },
        ]

        if (this.poBidRequest.postponeNotificationsSentCount < 3) {
          this.buttons.push({
            class: 'v3-hollow-white-button',
            text: 'Not ready yet',
            onClick: () => {
              poBidRequestBannerTracking.bannerNotReady(this.poBidRequest.id)
              coBidPostponeModalService.open(
                this.poBidRequest,
                this.serviceRequestId
              )
            },
          })
        } else {
          this.buttons.push({
            class: 'v3-hollow-white-button',
            text: 'Lost Interest',
            onClick: () => {
              poBidRequestBannerTracking.bannerLostInterest(
                this.poBidRequest.id
              )
              window.location = `/lead/${this.serviceRequestId}?page_state=archive`
            },
          })
        }
      }
    },
  ],
}

export default poBidRequestedBanner
