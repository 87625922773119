/* global angular */
angular.module('bzNavbarModule').directive('sectionHeaderLink', [
  function () {
    return {
      restrict: 'AE',
      scope: {},
      link: function (scope, element, attrs) {
        element.click(function () {
          if (attrs.href) {
            document.location = attrs.href
          }
        })
      },
    }
  },
])
