/* global bz_app, Track, angular, isMobile */
angular.module('bzUserModule').factory('trackAuthService', [
  function () {
    return {
      title: 'Auth Action',
      defaultData: function (input) {
        return angular.extend(
          {
            action: input.action ? input.action : '',
            platform: isMobile.check() ? 'mobile' : 'desktop',
          },
          input
        )
      },
      toggleUserType: function (userType) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'toggle user type',
            user_type: userType,
          })
        )
      },
      clickToAddNewContractor: function (options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'click to add new contractor',
            variation: options.variation,
            form_version: options.formVersion,
          })
        )
      },
      clickToClaimContractor: function (options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'click to claim contractor',
            variation: options.variation,
            form_version: options.formVersion,
          })
        )
      },
      clearContractor: function () {
        Track.send(
          this.title,
          this.defaultData({
            action: 'clear contractor',
          })
        )
      },
      showSignUp: function (options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'initiate sign up',
            user_type: options.userType,
            variation: options.variation,
            form_version: options.formVersion,
          })
        )
      },
      showSignIn: function (options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'initiate sign in',
            user_type: options.userType,
            variation: options.variation,
            form_version: options.formVersion,
          })
        )
      },
      clickSignUpSubmit: function (options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'click sign up submit',
            user_type: options.userType,
            variation: options.variation,
            form_version: options.formVersion,
          })
        )
      },
      clickCoSignUp: function (variation) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'click co sign up',
            variation: variation,
          })
        )
      },
      signUp: function (userType) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'sign up',
            user_type: userType,
          })
        )
      },
    }
  },
])
