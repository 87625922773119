/* globals angular, document */
/* eslint no-console: 0 */
angular.module('bzProjectFormModule').component('titleInput', {
  bindings: {
    className: '@',
    nameValue: '@',
    idName: '@',
    placeholder: '@',
    titleTypeAheadShowOnFocus: '=?',
    typeaheadAppendToBody: '@',
    formData: '=',
    autoFocus: '<?',
    onEnterKeyUp: '&?',
  },
  template: `
		<input
			id="{{ $ctrl.idName }}"
			class="user form-control {{ $ctrl.className }}"
			name="{{ $ctrl.nameValue }}"
			type="text"
			maxlength="200"
			autocomplete="off"
			ng-attr-placeholder="{{$ctrl.placeholder}}"
			uib-typeahead="projectType.name for projectType in $ctrl.projectTypeOptions | filter:$viewValue | limitTo:10"
			typeahead-focus-first="false" typeahead-focus
			typeahead-focus-state="{{ $ctrl.titleTypeAheadShowOnFocus }}"
			typeahead-append-to-body="true"
			ng-model="$ctrl.formData.title"
			ng-keyup="$ctrl.enterKeyUp($event)"/>
	`,
  controller: [
    '$element',
    'projectPreferencesModel',
    '$timeout',
    '$document',
    function ($element, projectPreferencesModel, $timeout, $document) {
      this.$onInit = function () {
        projectPreferencesModel.getProjectTypes().then((response) => {
          this.projectTypeOptions = response.filter((projectType) => {
            return projectType.name.indexOf('Other') == -1
          })
        })

        this.idName = this.valueOr(
          this.idName,
          `service_request_title_${new Date().getTime()}`
        )
        this.nameValue = this.valueOr(this.nameValue, 'service_request[title]')
        this.placeholder = this.valueOr(
          this.placeholder,
          'ex: Bathroom remodeling'
        )

        const isIE = !!window.MSInputMethodContext && !!document.documentMode

        if (this.autoFocus && !isIE) {
          $element.find('input').focus()
        }
      }

      this.valueOr = function (val, alt) {
        if (!!val && val.length) {
          return val
        }
        return alt
      }

      this.enterKeyUp = ($event) => {
        if ($event.keyCode === 13) {
          $event.target.blur()

          $timeout(() => {
            if (this.onEnterKeyUp) {
              this.onEnterKeyUp($event)
            }
          }, 200)
        }
      }
    },
  ],
})
