import template from './generic_modal_container.html'

const genericModalContainer = {
  bindings: {
    headerText: '<',
    onClose: '&',
    hideCloseButton: '<?',
  },
  transclude: true,
  template,
  controller: [
    function () {
      this.$init = () => {
        this.hideCloseButton = this.hideCloseButton || false
      }

      this.close = () => {
        this.onClose()
      }
    },
  ],
}

export default genericModalContainer
