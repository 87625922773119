/* global angular */
angular.module('bzCommonUtilitiesModule').factory('numberService', [
  function () {
    return {
      getRoundNumberArray: function (n, roundType) {
        if (roundType === 'floor') {
          return new Array(Math.floor(n))
        } else if (roundType === 'ceil') {
          return new Array(Math.ceil(n))
        } else {
          return new Array(Math.round(n))
        }
      },
    }
  },
])
