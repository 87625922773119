bz_app.component('leadSearchForm', {
  bindings: {
    query: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrlForDevice(
        'contractor/leads/lead_search_form'
      )
    },
  ],
  controller: [
    '$rootScope',
    'leadModel',
    function ($rootScope, leadModel) {
      this.$onInit = function () {
        if (isMobile.check()) {
          this.visible = false
        } else {
          this.visible = true
        }
      }

      this.search = function () {
        if (this.query) {
          var options = {
            query: this.query,
            page: 1,
            tab: 'search',
          }
          LeadsDashboardTrack().search(this.query)
          leadModel.index(options).then(
            function (response) {
              $rootScope.$broadcast(
                'sr_search_complete',
                response.data.leads,
                this.query
              )
            }.bind(this)
          )
        }
      }

      this.toggleSearchField = function () {
        this.visible = !this.visible
        if (this.visible) {
          angular.element('.dashboard-tab-title').hide()
          angular.element('.search-form-control').show()
        } else {
          this.query = null
          angular.element('.dashboard-tab-title').show()
          angular.element('.search-form-control').hide()
        }
      }

      $rootScope.$on(
        'clear_query',
        function () {
          this.query = null
        }.bind(this)
      )
    },
  ],
})
