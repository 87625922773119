/* globals angular */
angular.module('bzNavbarModule').directive('navbarProjectsLinkOverwrite', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        projects: '<',
      },
      controller: [
        '$rootScope',
        '$element',
        'trackNavbarActionsService',
        function ($rootScope, $element, trackNavbarActionsService) {
          this.$onInit = function () {
            trackNavbarActionsService.projectsLinkClick($element)

            $element.click(function () {
              $rootScope.$broadcast('closeBulletinMenus')
            })
          }

          this.$onChanges = function (changes) {
            if (changes.projects.previousValue === null) {
              var projects = changes.projects.currentValue

              if (projects && projects.active) {
                if (projects.active.count === 1) {
                  $element.attr(
                    'href',
                    '/project/' +
                      projects.active.recent.serviceRequestId +
                      '/summary'
                  )
                }
              }
            }
          }
        },
      ],
    }
  },
])
