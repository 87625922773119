bz_app.directive('mobileSubnav', function () {
  return {
    restrict: 'AE',
    link: function (scope, element, attr) {
      element.change(function (e) {
        var page = element.val()

        if (page) document.location = '/' + page
      })
    },
  }
})
