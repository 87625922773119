/* global angular */
angular.module('bzUserModule').factory('userBulletinInstance', [
  'attributeConversionService',
  'userBulletinModel',
  'urlConstants',
  function (attributeConversionService, userBulletinModel, urlConstants) {
    return function () {
      this.alerts = null
      this.inbox = null
      this.projects = null

      this.init = false

      this.set = function (data) {
        if (data) {
          this.setAttributes(
            attributeConversionService.underscoreToCamelDeepAttributes(data)
          )
          this.setInbox()
          this.init = true

          return true
        } else {
          return false
        }
      }

      this.setAttributes = function (data) {
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
            this[property] = data[property]
          }
        }
      }

      this.setInbox = function () {
        if (this.inbox && this.inbox.conversations) {
          angular.forEach(this.inbox.conversations, function (conversation) {
            if (conversation.lastMessage) {
              conversation.lastMessage.sender =
                conversation.users[conversation.lastMessage.senderUserId]
              conversation.lastMessage.recipient = Object.values(
                conversation.users
              ).find((user) => {
                return user.id !== conversation.lastMessage.sender_user_id
              })
              conversation.participant =
                conversation.users[conversation.participantUserId]
              conversation.unread =
                conversation.lastMessage.status === 'unread' ? true : false
            }
          })
        }
      }

      this.loadAndSet = function () {
        return userBulletinModel.load().then(
          function (response) {
            if (response.success) {
              return this.set(response.bulletin)
            }

            return false
          }.bind(this)
        )
      }

      this.alertsCount = function () {
        if (this.alerts) {
          var count = 0

          if (this.alerts.verifyEmail) {
            count++
          }

          if (this.alerts.verifyPhone) {
            count++
          }

          if (this.alerts.invalidLicense) {
            count++
          }

          if (this.alerts.blocksCount) {
            count++
          }

          if (this.alerts.unreadNotifications) {
            count += this.alerts.unreadNotifications
          }

          if (this.alerts.noProjectPreferences) {
            count++
          }

          return count
        } else {
          return null
        }
      }

      this.unreadMessagesCount = function () {
        if (this.inbox) {
          return this.inbox.unreadCount
        } else {
          return null
        }
      }

      this.activeProjectsCount = function () {
        if (this.projects && this.projects.active) {
          return this.projects.active.count
        } else {
          return null
        }
      }

      this.projectsPath = function () {
        if (this.projects.active.count === 1) {
          return (
            '/project/' +
            this.projects.active.recent.serviceRequestId +
            '/summary'
          )
        } else if (this.projects.active.count > 1) {
          return (
            urlConstants.dashboard.homeownerServiceRequests + '?status=open'
          )
        }
      }
    }
  },
])
