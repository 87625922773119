import { deepConvertToCamel } from 'Shared/utilities/attributes'

const API_V1_PATH = bzGlobal.urlConstants.api.v1.path
const API_V3_PATH = bzGlobal.urlConstants.api.v3.path
export default class AutocompleteApi {
  autocompleteCity(query, client) {
    return client.get(`${API_V1_PATH}/location_autocomplete_suggestions/city`, {
      params: {
        query: query,
      },
    })
  }

  autocompleteCityV2(query, client) {
    return client.get(
      `${API_V1_PATH}/location_autocomplete_suggestions/city_v2`,
      {
        params: {
          query: query,
        },
      }
    )
  }

  autocompleteCounty(query, client) {
    return client.get(
      `${API_V1_PATH}/location_autocomplete_suggestions/county`,
      {
        params: {
          query: query,
        },
      }
    )
  }

  autocompleteState(query, client) {
    return client.get(
      `${API_V1_PATH}/location_autocomplete_suggestions/state`,
      {
        params: {
          query: query,
        },
      }
    )
  }

  autocompleteNeighborhood(query, client) {
    return client.get(
      `${API_V1_PATH}/location_autocomplete_suggestions/neighborhood`,
      {
        params: {
          query: query,
        },
      }
    )
  }

  projectTypes(client) {
    return client
      .get(`${API_V3_PATH}/contractor_project_preferences/project_types`)
      .then(({ data }) => deepConvertToCamel(data))
  }
}
