angular.module('bzProjectLabelsModule').directive('newProjectLabel', [
  'newProjectLabelModalService',
  function (newProjectLabelModalService) {
    return {
      restrict: 'AE',
      link: function (scope, element, attr) {
        element.click(function (e) {
          newProjectLabelModalService.show()
          e.preventDefault()
        })
      },
    }
  },
])
