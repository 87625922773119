/* global bz_app, angular, $ */
angular.module('bzReviewsModule').factory('reviewsModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiReviewPath: urlConstants.api.v3.path + 'reviews',
      create: function (review) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(review)
        return $http
          .post(this.apiReviewPath, {
            review: data,
            service_request_id: data.service_request_id,
          })
          .then(this.extractData)
      },
      update: function (review) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(review)
        return $http
          .put(this.apiReviewPath + '/' + data.id, { review: data })
          .then(this.extractData)
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
      submit: function (data) {
        return $http.post('/reviews', { review: data })
      },
    }
  },
])
