bz_app.directive('bookmarkTooltip', [
  'urlService',
  'localStorageModel',
  function (urlService, localStorageModel) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {},
      templateUrl: urlService.templateUrl(
        '_components/directives/bookmark_tooltip'
      ),

      link: function ($scope) {
        $scope.hideLinks = function () {
          angular.element('#fixed-bottom-contractor-nav').hide()
        }

        $scope.showLinks = function () {
          angular.element('#fixed-bottom-contractor-nav').show()
        }
      },

      controller: [
        '$scope',
        '$timeout',
        function ($scope, $timeout) {
          $scope.initShow = function () {
            if (!browser.isSafari() || !localStorageModel.isPresent()) {
              return
            }
            var status = localStorageModel.get('bookmarkTooltipStatus')
            $scope.show = !status || !status.dismissed
            if ($scope.show) {
              $timeout(function () {
                $scope.hideLinks()
              })
            }
          }

          $scope.dismiss = function () {
            $scope.show = false
            $scope.showLinks()
            localStorageModel.set('bookmarkTooltipStatus', {
              dismissed: true,
              dismissedAt: new Date(),
            })
          }

          $scope.initShow()
        },
      ],
    }
  },
])
