export default class MessageRedirectHelper {
  constructor(urlConstants, conversation, serviceRequestId, contractorId) {
    this.conversation = conversation
    this.serviceRequestId = serviceRequestId
    this.contractorId = contractorId
    this.urlConstants = urlConstants
  }

  isContractorTheProjectOwner() {
    return (
      this.conversation.contractor.id === this.conversation.serviceRequestUserId
    )
  }

  projectUrl() {
    if (this.serviceRequestId) {
      if (UserData.is_contractor() && !this.isContractorTheProjectOwner()) {
        if (this.conversation.messageType == 'Email') {
          return '/lead/' + this.serviceRequestId + '/email'
        }
        return '/lead/' + this.serviceRequestId
      } else if (this.contractorId) {
        if (this.conversation.messageType == 'Email') {
          return (
            '/project/' +
            this.serviceRequestId +
            '/contractor/' +
            this.contractorId +
            '?communicationsTab=email'
          )
        }
        return (
          '/project/' +
          this.serviceRequestId +
          '/contractor/' +
          this.contractorId
        )
      } else {
        return '/project/' + this.serviceRequestId
      }
    } else {
      return this.messageTabUrl()
    }
  }

  redirectToProjectUrl($event) {
    document.location = this.projectUrl()
  }

  messageTabUrl() {
    return UserData.is_contractor()
      ? this.urlConstants.contractor.conversationsURL
      : this.urlConstants.homeowner.conversationsURL
  }

  redirectToMessageTabUrl() {
    document.location = this.messageTabUrl()
  }
}
