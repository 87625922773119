angular.module('bzReviewsModule').controller('ReviewDisputeModalCtrl', [
  '$uibModalInstance',
  'attributeConversionService',
  'inputData',
  'urlConstants',
  'bzHttp',
  'filestackService',
  '$timeout',
  '$scope',
  function (
    $uibModalInstance,
    attributeConversionService,
    inputData,
    urlConstants,
    bzHttp,
    filestackService,
    $timeout,
    $scope
  ) {
    this.$onInit = () => {
      this.isUpdating = false
      this.review = inputData.review
      this.reviewDispute = inputData.reviewDispute
      this.userType = UserData.is_contractor() ? 'contractor' : 'homeowner'
      this.userType === 'contractor'
        ? this.prepForContractor()
        : this.prepForHomeowner()
      this.newFiles = []

      $timeout(() => {
        angular
          .element('#add-attachments-selector')
          .on('change', this.uploadFiles)
      }, 250)
    }

    this.prepForContractor = () => {
      let dispute = this.reviewDispute
      this.url = `${urlConstants.contractor.reviewsURL}/${this.review.id}/review_disputes`

      if (dispute) {
        this.url += `/${dispute.id}`
        this.message = dispute.message
        this.isUpdating = true
        this.supportingFiles = dispute.images
      }
    }

    this.prepForHomeowner = () => {
      let userResponse = this.reviewDispute.disputeResponse
      this.url = `${urlConstants.homeowner.reviewsURL}/${this.review.id}/review_disputes`

      if (userResponse) {
        this.url += `/${userResponse.id}`
        this.message = userResponse.message
        this.isUpdating = true
        this.supportingFiles = userResponse.images
      }
    }

    this.uploadFiles = ($event) => {
      let currentNewFileCount = this.newFiles.length
      angular.forEach($event.target.files, (selectedFile) => {
        $scope.$apply(() => (this.filesUploading = true))
        filestackService.upload(selectedFile).then((file) => {
          this.newFiles.push(file)
          if (
            this.newFiles.length ===
            $event.target.files.length + currentNewFileCount
          ) {
            $scope.$applyAsync(() => {
              this.filesUploading = false
              this.filesSelectedText =
                this.newFiles.length === 1
                  ? '1 New file selected:'
                  : `${this.newFiles.length} New files selected:`
            })
          }
        })
      })
    }

    this.submit = (buttonActions) => {
      buttonActions.showLoading()

      if (this.isUpdating) {
        return bzHttp
          .put(this.url, {
            message: this.message,
            images: this.newFiles,
          })
          .then((res) => {
            if (res.success) {
              this.userType === 'contractor'
                ? Object.assign(this.reviewDispute, res.reviewDispute)
                : Object.assign(
                    this.reviewDispute.disputeResponse,
                    res.reviewDispute
                  )
              this.showCompleteMessage = true
            } else {
              this.showError = true
            }
          })
      } else {
        return bzHttp
          .post(this.url, {
            message: this.message,
            images: this.newFiles,
          })
          .then((res) => {
            res.success
              ? (this.showCompleteMessage = true)
              : (this.showError = true)
          })
      }
    }

    this.canSubmitDispute = () => !this.filesUploading && this.termsAccepted

    this.disabledText = () =>
      this.filesUploading ? 'Uploading files...' : 'Submit'

    this.close = () => $uibModalInstance.dismiss('cancel')
  },
])
