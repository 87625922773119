/* global bz_app $ */
bz_app.factory('marketSizeModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  'modelService',
  function ($http, urlConstants, attributeConversionService, modelService) {
    return {
      apiMarketSizesPath: urlConstants.api.v3.path + 'market_sizes',

      loadMarketSize: function (params) {
        return $http
          .get(this.apiMarketSizesPath + modelService.queryString(params))
          .then(modelService.extractData)
      },
    }
  },
])
