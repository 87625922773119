/* Blob, BlobBuilder */
import saveAs from 'file-saver'

// modified from: https://github.com/nwcell/ics.js
export default class ics {
  constructor() {
    this.uidDomain = 'default'
    this.SEPARATOR = navigator.appVersion.indexOf('Win') !== -1 ? '\r\n' : '\n'
    this.calendarEvents = []

    this.calendarStart = [
      'BEGIN:VCALENDAR',
      'PRODID: Calendar',
      'VERSION:2.0',
    ].join(this.SEPARATOR)

    this.calendarEnd = this.SEPARATOR + 'END:VCALENDAR'
  }

  events() {
    return this.calendarEvents
  }

  calendar() {
    return (
      this.calendarStart +
      this.SEPARATOR +
      this.calendarEvents.join(this.SEPARATOR) +
      this.calendarEnd
    )
  }

  addEvent(subject, description, location, begin, stop) {
    const start_date = new Date(begin)
    const end_date = new Date(stop)
    const now_date = new Date()

    const start_year = ('0000' + start_date.getFullYear().toString()).slice(-4)
    const start_month = ('00' + (start_date.getMonth() + 1).toString()).slice(
      -2
    )
    const start_day = ('00' + start_date.getDate().toString()).slice(-2)
    const start_hours = ('00' + start_date.getHours().toString()).slice(-2)
    const start_minutes = ('00' + start_date.getMinutes().toString()).slice(-2)
    const start_seconds = ('00' + start_date.getSeconds().toString()).slice(-2)

    const end_year = ('0000' + end_date.getFullYear().toString()).slice(-4)
    const end_month = ('00' + (end_date.getMonth() + 1).toString()).slice(-2)
    const end_day = ('00' + end_date.getDate().toString()).slice(-2)
    const end_hours = ('00' + end_date.getHours().toString()).slice(-2)
    const end_minutes = ('00' + end_date.getMinutes().toString()).slice(-2)
    const end_seconds = ('00' + end_date.getSeconds().toString()).slice(-2)

    const now_year = ('0000' + now_date.getFullYear().toString()).slice(-4)
    const now_month = ('00' + (now_date.getMonth() + 1).toString()).slice(-2)
    const now_day = ('00' + now_date.getDate().toString()).slice(-2)
    const now_hours = ('00' + now_date.getHours().toString()).slice(-2)
    const now_minutes = ('00' + now_date.getMinutes().toString()).slice(-2)
    const now_seconds = ('00' + now_date.getSeconds().toString()).slice(-2)

    // Since some calendars don't add 0 second events, we need to remove time if there is none...
    let start_time = ''
    let end_time = ''
    if (
      start_hours +
        start_minutes +
        start_seconds +
        end_hours +
        end_minutes +
        end_seconds !=
      0
    ) {
      start_time = 'T' + start_hours + start_minutes + start_seconds
      end_time = 'T' + end_hours + end_minutes + end_seconds
    }

    let now_time = 'T' + now_hours + now_minutes + now_seconds

    const start = start_year + start_month + start_day + start_time
    const end = end_year + end_month + end_day + end_time
    const now = now_year + now_month + now_day + now_time

    const calendarEvent = [
      'BEGIN:VEVENT',
      'UID:' + this.calendarEvents.length + '@' + this.uidDomain,
      'CLASS:PUBLIC',
      'DESCRIPTION:' + description,
      'DTSTAMP;VALUE=DATE-TIME:' + now,
      'DTSTART;VALUE=DATE-TIME:' + start,
      'DTEND;VALUE=DATE-TIME:' + end,
      'LOCATION:' + location,
      'SUMMARY;LANGUAGE=en-us:' + subject,
      'TRANSP:TRANSPARENT',
      'END:VEVENT',
    ]

    this.calendarEvents.push(calendarEvent.join(this.SEPARATOR))
    return calendarEvent
  }

  download(filename = 'meeting', ext = '.ics') {
    if (this.calendarEvents.length < 1) {
      return false
    }

    const calendar = this.calendar()

    let blob
    if (navigator.userAgent.indexOf('MSIE 10') === -1) {
      // chrome or firefox
      blob = new window.Blob([calendar], { type: 'text/calendar' })
    } else if (window.BlobBuilder) {
      // ie
      let bb = new window.BlobBuilder()
      bb.append(calendar)
      blob = bb.getBlob('text/x-vCalendar;charset=' + document.characterSet)
    }

    saveAs(blob, filename + ext)

    return calendar
  }
}
