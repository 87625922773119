bz_app.factory('reviewData', [
  '$rootScope',
  '$sce',
  function ($rootScope, $sce) {
    return {
      id: null,
      stars: null,
      body: null,
      name: null,
      created_at: null,
      location: null,
      response: {
        id: null,
        body: null,
        status: 'Pending',
        review_id: null,
      },

      set: function (review) {
        this.id = review.id
        this.stars = review.stars
        this.body = review.body
        this.name = review.name
        this.created_at = review.created_at
        this.location = review.location
        this.setResponse(review)
      },
      setResponse: function (review) {
        if (review.response.id != '' && review.response.id !== undefined)
          this.response.id = review.response.id
        if (
          review.response.review_id != '' &&
          review.response.review_id !== undefined
        )
          this.response.review_id = review.id
        this.response.body = review.response.body
        if (
          review.response.status != '' &&
          review.response.status !== undefined
        )
          this.response.status = review.response.status
        this.response.review_id = review.id
      },
    }
  },
])
