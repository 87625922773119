/* global bz_app */
bz_app.controller('ConfirmWinModalCtrl', [
  'inputData',
  '$uibModalInstance',
  'trackLeadsService',
  'trackCheckoutService',
  function (
    inputData,
    $uibModalInstance,
    trackLeadsService,
    trackCheckoutService
  ) {
    this.$onInit = function () {
      this.lead = inputData.lead
      this.source = inputData.source
      this.buttonLabel = 'confirm hire'
      this.onCancel = inputData.onCancel

      this.lead.initiateWin()
      trackLeadsService.initiateWin(this.lead, this.source)
      this.trackCheckoutInitiatedWin()
    }

    this.ok = function () {
      this.buttonLabel = 'confirming...'
      this.markHire()
    }

    this.cancel = function () {
      if (inputData.onCancel) {
        inputData.onCancel()
      }
      $uibModalInstance.dismiss('cancel')
    }

    this.markHire = function () {
      trackLeadsService.markHired(this.lead)
      trackCheckoutService.confirmedWin(this.lead)
      var sourceInfo = {
        hireSource: this.source,
        medium: 'web',
      }
      this.lead.markHire(null, sourceInfo).then(
        function (response) {
          if (response && response.success) {
            this.redirectToCheckout(this.lead)
          }
        }.bind(this)
      )
    }

    this.redirectToCheckout = function (lead) {
      document.location = '/checkout/' + lead.project.id
    }

    this.trackCheckoutInitiatedWin = function () {
      if (this.source && this.source === 'CO: Leads Dashboard') {
        trackCheckoutService.initiatedWinOnLeadsDashboard(this.lead)
      } else {
        trackCheckoutService.initiatedOnLeadPage(this.lead)
      }
    }
  },
])
