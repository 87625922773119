/* global angular */
angular.module('bzMessageBannerModule').directive('messageBanner', [
  'urlService',
  function (urlService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        options: '<',
      },
      templateUrl: function (element, attrs) {
        var templateUrl = '_components/directives/message_banner'

        if (attrs && attrs.templateUrl) {
          templateUrl = attrs.templateUrl
        }

        return urlService.templateUrl(templateUrl)
      },
      controller: [
        '$scope',
        '$element',
        '$timeout',
        '$compile',
        function ($scope, $element, $timeout, $compile) {
          this.$onInit = function () {
            if (!this.options) {
              this.options = {}
            } else if (this.options.cssClass) {
              $element.addClass(this.options.cssClass)
            }

            this.initContentDirective()
            this.initAutoClose()

            this.messageBannerClasses = {
              'banner-position-overlay': this.options.position === 'overlay',
              'banner-position-block': this.options.position === 'block',
              'fixed-top-mobile': this.options.isFixedTopMobile,
            }

            if (!this.options.hideIfGlobal) {
              $timeout(function () {
                $element.removeClass('message-banner-hidden')
              }, 30)
            }
          }

          this.initContentDirective = function () {
            var elementHtml =
              '<' +
              this.options.contentDirective +
              ' options="$ctrl.options"></' +
              this.options.contentDirective +
              '>'
            $element.find('.container').append($compile(elementHtml)($scope))
          }

          this.initAutoClose = function () {
            if (this.options.displayDuration !== 0) {
              $timeout(
                function () {
                  this.close()
                }.bind(this),
                this.options.displayDuration || 3000
              )
            }
          }

          this.close = function () {
            $element.addClass('message-banner-hidden')
          }
        },
      ],
    }
  },
])
