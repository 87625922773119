/* global angular */
angular.module('bzChartModule').component('reviewStars', {
  bindings: {
    value: '<',
  },
  template:
    '' +
    '<div>' +
    '	<div ng-repeat="star in $ctrl.solidStars() track by $index" class="review-star solid-review-star">' +
    '		<div ng-if="$last && $index === 4" class="marker-tooltip">' +
    '			<div class="tooltip-review-icon"></div>' +
    '			x 4.75' +
    '		</div>' +
    '	</div>' +
    '	<div ng-if="$ctrl.value < 5" class="review-star-separator"></div>' +
    '	<div ng-repeat="star in $ctrl.hollowStars() track by $index" class="review-star hollow-review-star">' +
    '		<div ng-if="$last" class="marker-tooltip">' +
    '			<div class="tooltip-review-icon"></div>' +
    '			x 4.75' +
    '		</div>' +
    '	</div>' +
    '</div>',
  controller: [
    function () {
      this.$onInit = function () {
        if (!this.value) {
          this.value = 0
        }
      }

      this.solidStars = function () {
        return new Array(Math.floor(this.value))
      }

      this.hollowStars = function () {
        return new Array(5 - Math.floor(this.value))
      }
    },
  ],
})
