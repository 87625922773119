/* global angular */
import template from './multi_select_dropdown.html'

import Set from 'es6-set'

export default {
  bindings: {
    options: '<',
    selectedValues: '<',
    selectedValue: '<',
    onChange: '&',
    setNullForSelectAll: '<',
    singleSelect: '<',
    themeClass: '@',
    customToggleIconClass: '@',
    cannotBeBlank: '<',
  },
  template,
  controller: [
    '$scope',
    '$timeout',
    function ($scope, $timeout) {
      this.$onInit = () => {
        this.isOpen = false
        this.buildLabelLookup()
        $scope.$watch('$ctrl.options', this.buildLabelLookup)
        this.buildSelectedValuesLookup()
        $scope.$watch('$ctrl.selectedValues', this.buildSelectedValuesLookup)
        $scope.$watch('$ctrl.selectedValue', this.buildSelectedValuesLookup)
        angular.element('body').on('click', this.close)
      }

      this.$onDestroy = () => angular.element('body').off('click', this.close)

      this.buildLabelLookup = () => {
        this.optionLabels = {}

        this.options.forEach(
          ({ value, label }) => (this.optionLabels[value] = label)
        )
      }

      this.buildSelectedValuesLookup = () => {
        if (this.singleSelect) {
          this._selectedValues = this.selectedValue ? [this.selectedValue] : []
        } else {
          this._selectedValues = this.selectedValues
        }

        if (!this._selectedValues) {
          this._selectedValues = this.setNullForSelectAll
            ? this.allOptionValues()
            : []
        }

        this.selectedValuesSet = new Set(this._selectedValues)

        this.setToggleText()
      }

      this.allOptionValues = () => this.options.map(({ value }) => value)

      this.setToggleText = () => {
        if (this.singleSelect) {
          this.toggleText = this.optionLabels[this.selectedValue]
        } else if (this._selectedValues.length === this.options.length) {
          this.toggleText = 'All'
        } else if (this._selectedValues.length === 0) {
          this.toggleText = 'None'
        } else {
          this.toggleText = `${this._selectedValues.length} selected`
        }
      }

      this.isSelected = (val) => this.selectedValuesSet.has(val)

      this.selectAll = ($event) => {
        $event.stopPropagation()
        this.setSelectedValues(
          this.setNullForSelectAll ? null : this.allOptionValues()
        )
      }

      this.clearAll = ($event) => {
        $event.stopPropagation()
        this.setSelectedValues([])
      }

      this.toggleSelected = ($event, val) => {
        $event.stopPropagation()
        this.isSelected(val) ? this.deselectValue(val) : this.selectValue(val)
      }

      this.deselectValue = (val) => {
        var newValues = this._selectedValues.filter(
          (selectedValue) => val !== selectedValue
        )

        if (newValues.length === 0 && this.cannotBeBlank) {
          this.close()
        } else {
          this.setSelectedValues(newValues)
        }
      }

      this.selectValue = (val) =>
        this.singleSelect
          ? this.setSelectedValues([val])
          : this.setSelectedValues([...this._selectedValues, val])

      this.setSelectedValues = (values) => {
        this.singleSelect
          ? this.onChange({ value: values[0] })
          : this.onChange({ values })

        if (this.singleSelect) this.close()
      }

      this.handleToggleClick = ($event) => {
        if (!this.isOpen) $timeout(() => (this.isOpen = true))
      }

      this.close = () => {
        this.isOpen = false
        $scope.$applyAsync()
      }
    },
  ],
}
