const referralAgreementLink = [
  'referralAgreementModalService',
  function (referralAgreementModalService) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const {
          source,
          hasSignedReferralAgreement,
          hasOnlyExpiredSignedReferralAgreements,
          hasActiveUnsignedClbAgreementWithNonClbsPresent,
          hasOnlyUnsignedClbReferralAgreement,
        } = attrs
        element.click(() => {
          referralAgreementModalService.launchModal({
            source,
            hasSignedReferralAgreement: hasSignedReferralAgreement === 'true',
            hasOnlyExpiredSignedReferralAgreements:
              hasOnlyExpiredSignedReferralAgreements === 'true',
            hasActiveUnsignedClbAgreementWithNonClbsPresent:
              hasActiveUnsignedClbAgreementWithNonClbsPresent === 'true',
            hasOnlyUnsignedClbReferralAgreement:
              hasOnlyUnsignedClbReferralAgreement === 'true',
          })
        })
      },
    }
  },
]

export default referralAgreementLink
