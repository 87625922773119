/* global google */

export default class GoogleSessionToken {
  constructor() {}

  createToken() {
    if (google) {
      this.token = new google.maps.places.AutocompleteSessionToken()
      this.createdDate = new Date()
    }
  }

  getToken() {
    if (!this.isValidToken()) {
      this.createToken()
    }

    return this.token
  }

  isValidToken() {
    return (
      this.token &&
      this.createdDate &&
      (new Date() - this.createdDate) / 60000 <= 3
    ) // 3 min or less
  }
}
