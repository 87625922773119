/* global Track, UserData	*/
var LeadsDashboardTrack = function (leadsData) {
  return {
    title: 'Leads Dashboard Action',

    view: function (leadStatus) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'view leads list',
          leadStatus: leadStatus,
        })
      )
    },
    leadStatusUpdate: function (leadStatus) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'update lead status',
          leadStatus: leadStatus,
        })
      )
    },
    leadStatusCTA: function (action) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'lead status CTA action',
          leadStatus: action,
        })
      )
    },
    leadStatusConfirmWin: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({ action: 'confirm lead win' })
      )
    },
    clickSort: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({ action: 'click sort' })
      )
    },
    leadEmptyAdPromoClick: function (leadStatus, clickType) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'click empty ad promo',
          leadStatus: leadStatus,
          page: 'dashboard',
          clickType: clickType,
        })
      )
    },
    leadEmptyAdPromoView: function (leadStatus) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'view empty ad promo',
          leadStatus: leadStatus,
          page: 'dashboard',
        })
      )
    },
    leadView: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'view link clicked',
        })
      )
    },
    trackPhoneLink: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({ action: 'call homeowner' })
      )
    },
    clickMessageLink: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'click message link',
        })
      )
    },
    search: function (query) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'search',
          query: query,
        })
      )
    },
    meetingModalOpened: function (origin) {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({
          action: 'open meeting reminder modal',
          origin,
        })
      )
    },
    clickSetMeeting: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({ action: 'click submit meeting date' })
      )
    },
    clickSetMeetingReminderText: function () {
      Track.impersonation_check().send(
        this.title,
        this.defaultData({ action: 'click send meeting reminder text' })
      )
    },
    defaultData: function (input) {
      var platform = isMobile.check() ? 'mobile' : 'desktop'
      var data = {
        action: input.action,
        platform: platform,
      }

      if (leadsData === undefined) leadsData = {}

      if (input.leadStatus !== undefined) data.lead_status = input.leadStatus
      if (leadsData.serviceRequestId !== undefined)
        data.service_request_id = leadsData.serviceRequestId
      if (input.origin) data.origin = input.origin
      if (input.query !== undefined) {
        data.query = input.query
      }
      if (input.clickType !== undefined) data.clickType = input.clickType
      if (leadsData.contractorId !== undefined)
        data.contractor_id = leadsData.contractorId
      if (window.location.href.indexOf('/manage/contractor/leads') === -1) {
        data.page = 'cockpit'
      } else {
        data.page = 'dashboard'
      }
      return data
    },
  }
}

window.LeadsDashboardTrack = LeadsDashboardTrack
