const dataApplicationModel = [
  '$http',
  'urlConstants',
  function ($http, urlConstants) {
    return {
      apiPath: urlConstants.api.v1.path + 'data_applications',

      sendEmail: function (application, propertyId) {
        return $http.post(this.apiPath, {
          application: { ...application, url: window.location.href },
          property_id: propertyId,
        })
      },
    }
  },
]
export default dataApplicationModel
