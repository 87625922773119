/* global angular */
angular.module('bzSearchModule').directive('searchKeywordsInput', [
  'applicationConstants',
  function (applicationConstants) {
    return {
      restrict: 'A',
      replace: true,
      template:
        '<input uib-typeahead="projectType as projectType.name for projectType in searchSuggestions($viewValue) | limitTo:10" ' +
        'typeahead-focus-first="false" ' +
        'ng-class="{ loading: loadingContractorSearch, noResults: noResultsContractorSearch }"' +
        'typeahead-on-select="keywordsSelected($item)" ' +
        'class="modern-nav-search-form-field search-form-keyword-field" name="search[keywords]" ng-model="keywords" ' +
        'type="text" autocomplete="off" ng-model-options="{ debounce: 300 }" ' +
        'typeahead-template-url="/assets/modules/search/suggestion_item.html?v=' +
        applicationConstants.templateVersion +
        '"/>',
    }
  },
])
