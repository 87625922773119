/* global bz_app, angular*/
angular
  .module('bzProjectPreferencesModule')
  .controller('ConfirmSwitchContractorTypeModalCtrl', [
    '$uibModalInstance',
    function ($uibModalInstance) {
      this.changePreferences = function () {
        $uibModalInstance.close(true)
      }

      this.close = function () {
        $uibModalInstance.close(false)
      }
    },
  ])
