/* global angular, isMobile */

angular.module('bzProjectFormModule').component('titleScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/title'
      )
    },
  ],
  controller: [
    '$element',
    '$timeout',
    'projectFormScreenService',
    function ($element, $timeout, projectFormScreenService) {
      this.$onInit = function () {
        this.predefinedProjectTypes = [
          { label: 'Bathroom Remodel', cssClass: 'bathroom' },
          { label: 'Kitchen Remodel', cssClass: 'kitchen' },
          { label: 'Multi-Room Remodel', cssClass: 'multi-room' },
          { label: 'Home Addition', cssClass: 'home-addition' },
          { label: 'New Home Construction', cssClass: 'new-home' },
          { label: 'Roofing', cssClass: 'roofing' },
          { label: 'Solar Installation', cssClass: 'solar' },
          { label: 'New Commercial Remodel', cssClass: 'commercial' },
        ]

        if (!this.project.summary.title) {
          $timeout(function () {
            $element.find('#form-service-request-title').focus()
          }, 200)
        }

        const landingPageProjectType =
          window.location.pathname.split('/projects/')[1]
        if (
          landingPageProjectType &&
          landingPageProjectType.includes('architect')
        ) {
          this.professionalName = 'architects'
        } else {
          this.professionalName = 'contractors'
        }
      }

      this.showNextScreen = function () {
        if (projectFormScreenService.isCurrentScreen('title')) {
          if (this.project.projectType) {
            this.project.projectType.projectTypeGroupId = null
          }

          projectFormScreenService.showNextScreen()
        }
      }

      this.isFormVersion = function (formVersion) {
        return this.project.analytics.formVersion === formVersion
      }

      this.selectProjectType = function (projectType) {
        this.project.summary.title = projectType.label
        this.project.projectType = { name: projectType.label }
        this.showNextScreen()
      }
    },
  ],
})
