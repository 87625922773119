$(function () {
  if ($('ul').hasClass('gallery')) {
    $('.favorite-btn').show()
  }
  $('li.image').hover(
    function () {
      $(this).addClass('fadeContainer')
      $(this).find('.favorite-btn').show()
      $(this).children('.carousel-caption').show('.carousel-caption')
    },
    function () {
      $(this).removeClass('fadeContainer')
      $(this).find('.favorite-btn').hide()
      $(this).children('.carousel-caption').hide('.carousel-caption')
    }
  )

  if ($('#session_photo_type').length != 0) {
    var rated = false
    var categorized = false
    var minRating = 5
    var photoType = $('#session_photo_type').text()
    //if rate only, then submit once a rating is given
    if (photoType == 'rate') {
      $('.photo_review_buttons.btn-group .btn-rate').addClass('active')
      $('.edit_project_photo .btn-group a').on('click', function () {
        var photoRating = $(this).html()
        $('#project_photo_photo_rating').attr('value', photoRating)
        $('form.edit_project_photo').submit()
      })
    }
    //if categorize only, then submit once the select is toggled
    else if (photoType == 'categorize') {
      $('.photo_review_buttons.btn-group .btn-categorize').addClass('active')
      $('.edit_project_photo select').change(function () {
        $('form.edit_project_photo').submit()
      })
    }
    //members only + contractors only + area
    else {
      // show active button
      if (photoType == 'area') {
        $('.photo_review_buttons.btn-group .btn-area').addClass('active')
      } else if (photoType == 'all') {
        $('.photo_review_buttons.btn-group .btn-all').addClass('active')
      } else {
        $('.photo_review_buttons.btn-group .btn-members').addClass('active')
      }

      $('.edit_project_photo .btn-group a').on('click', function () {
        rated = true
        var photoRating = $(this).html()
        $('#project_photo_photo_rating').attr('value', photoRating)
        if (photoRating < minRating) {
          $('form.edit_project_photo').submit()
        }
        if (categorized == true) {
          $('form.edit_project_photo').submit()
        }
      })
      // if photo has been rated, then submit form when select is toggled
      $('.edit_project_photo select').change(function () {
        categorized = true
        if (rated == true) {
          $('form.edit_project_photo').submit()
        }
      })
    }

    $('select#area').change(function () {
      //var area_id = $('select#area').val();
      $('form#area').submit()
    })
  }
})
