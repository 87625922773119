import { isArray, forEach, snakeCase, isObject, camelCase } from 'lodash'

const traverseAndMutateKeys = (value, keyMutationCallback) => {
  if (isArray(value)) {
    return value.map((innerValue) =>
      traverseAndMutateKeys(innerValue, keyMutationCallback)
    )
  } else if (isObject(value)) {
    forEach(Object.keys(value), (key) => {
      value[keyMutationCallback(key)] = traverseAndMutateKeys(
        value[key],
        keyMutationCallback
      )
      if (keyMutationCallback(key) !== key) delete value[key]
    })
    return value
  } else {
    return value
  }
}

const keyFormatConverter = {
  mutateToUnderscore: (value) => {
    traverseAndMutateKeys(value, snakeCase)
    return value
  },

  mutateToCamel: (value) => {
    traverseAndMutateKeys(value, camelCase)
    return value
  },

  jsonToCamel: (jsonStr) =>
    keyFormatConverter.mutateToCamel(JSON.parse(jsonStr)),
  jsonToUnderscore: (jsonStr) =>
    keyFormatConverter.mutateToUnderscore(JSON.parse(jsonStr)),
}

export default keyFormatConverter
