angular.module('bzProjectFormModule').filter('keysAsValues', function () {
  //when we store values via the keys present in an Array pointing to boolean values
  return function (items, props) {
    return Object.keys(items)
      .filter(function (key) {
        return items[key]
      })
      .join()
  }
})
