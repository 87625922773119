/* global bz_app, UserData */
bz_app.factory('archiveLeadModalService', [
  'urlService',
  '$uibModal',
  function (urlService, $uibModal) {
    return {
      show: function (inputData) {
        var modalInstance = $uibModal.open({
          templateUrl: urlService.templateUrl(
            'leads/unlocked_lead/archive_lead_modal'
          ),
          controller: 'ArchiveLeadModalCtrl',
          controllerAs: '$ctrl',
          bindToController: true,
          windowClass: 'v3-modal lead-modal',
          resolve: {
            inputData: function () {
              return inputData
            },
          },
        })

        return modalInstance
      },
    }
  },
])
