/* global angular */
angular.module('bzNavbarModule').component('navbarBadgeIndicator', {
  bindings: {
    count: '&',
    level: '@',
  },
  template:
    '<div ng-if="$ctrl.count()" class="navbar-badge-indicator {{ $ctrl.level }}">' +
    '	<div ng-bind="$ctrl.prettyCount()" class="indicator-value"></div>' +
    '</div>',
  controller: [
    function () {
      this.prettyCount = function () {
        var count = this.count()

        if (count) {
          if (count > 99) {
            return '*'
          } else {
            return count
          }
        } else {
          return 0
        }
      }
    },
  ],
})
