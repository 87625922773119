angular.module('bzProjectLabelsModule').factory('projectLabelsModel', [
  'projectLabelsData',
  '$timeout',
  function (projectLabelsData, $timeout) {
    return {
      projectApi: new ProjectAPI(),
      data: projectLabelsData,
      load: function () {
        var that = this
        return this.projectApi.load_labels().then(function (response) {
          if (response && response.projects_labels)
            that.data.set({ labels: response.projects_labels }, true)
        })
      },
      loadDelayed: function () {
        var that = this
        $timeout(function () {
          if (!that.data.labels.length && !that.data.initialized) that.load()
        }, 1000)
      },
      create: function (labelName) {
        if (labelName && labelName.length) {
          var that = this

          return this.projectApi
            .create_label(labelName)
            .then(function (response) {
              if (response.success) that.data.addLabel(response)
            })
        } else return null
      },
      update: function (labelId, labelName) {
        if (labelId && labelName && labelName.length)
          return this.projectApi.update_label(labelId, labelName)
        else return null
      },
      delete: function (labelId) {
        if (labelId) {
          var that = this

          return this.projectApi
            .delete_label(labelId)
            .then(function (response) {
              if (response.success) that.data.removeLabel(response.id)
            })
        } else return null
      },
      setProjectsLabel: function (labelId, projectIds) {
        return this.projectApi.set_label(projectIds, labelId)
      },
    }
  },
])
