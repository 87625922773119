/* global angular */
angular.module('bzUserModule').controller('AuthModalCtrl', [
  'inputData',
  '$uibModalInstance',
  '$scope',
  function (inputData, $uibModalInstance, $scope) {
    this.$onInit = function () {
      this.logIn = !!inputData.logIn
      this.userType = inputData.contractor ? 'contractor' : 'propertyOwner'
      this.registerContractor = inputData.registerContractor
      this.disableTabs = inputData.disableTabs
      this.redirectPath = inputData.redirectPath || null
      this.variation = inputData.variation

      $scope.$on(
        'modal.closing',
        function () {
          if (this.redirectPath) {
            document.location = this.redirectPath
          }
        }.bind(this)
      )
    }

    this.headerText = function () {
      if (this.logIn) {
        return 'Welcome Back'
      } else {
        return 'Sign Up'
      }
    }

    this.dismiss = function () {
      $uibModalInstance.dismiss('cancel')
    }
  },
])
