export default [
  '$httpProvider',
  '$compileProvider',
  '$qProvider',
  function ($httpProvider, $compileProvider, $qProvider) {
    $httpProvider.useApplyAsync(true)
    $httpProvider.defaults.headers.common['xsrfCookieName'] = 'X-XSRF-Token'
    $httpProvider.interceptors.push(function () {
      return {
        response: function (res) {
          // Test if request is failing because of a required cloudflare
          // challenge. Redirect them to it rather then rendering error response.
          if (
            typeof res.data === 'string' &&
            res.data.includes('bz-cf-challenge')
          ) {
            const currentUrl = window.location.pathname + window.location.search
            window.location.href = `/cf_challenge?originalUrl=${currentUrl}`
          }
          return res
        },
      }
    })
    $compileProvider.debugInfoEnabled(false)
    $compileProvider.aHrefSanitizationWhitelist(
      /^\s*(https?|ftp|mailto|file|sms|tel):/
    )

    // backward compatibility for ui router w/angular 1.6.0
    if (typeof $qProvider.errorOnUnhandledRejections === 'function') {
      $qProvider.errorOnUnhandledRejections(false)
    }

    // legacy support for controller binding assignment outside of $onInit()
    if (typeof $compileProvider.preAssignBindingsEnabled === 'function') {
      $compileProvider.preAssignBindingsEnabled(true)
    }
  },
]
