/* global bz_app, isMobile, angular, urlService */
angular.module('bzReviewsModule').component('reviewFormWrapper', {
  bindings: {
    step: '=',
    contractor: '<',
    review: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('reviews/review_form_wrapper')
    },
  ],
  controller: [
    'attributeConversionService',
    'reviewFormScreenService',
    'trackReviewFormService',
    'urlConstants',
    'reviewInstance',
    '$injector',
    function (
      attributeConversionService,
      reviewFormScreenService,
      trackReviewFormService,
      urlConstants,
      reviewInstance,
      $injector
    ) {
      this.$onInit = function () {
        this.submissionBlocked = false
        this.isMobile = isMobile.check({ phoneOnly: true })
        this.contractor =
          attributeConversionService.underscoreToCamelDeepAttributes(
            this.contractor
          )
        if (typeof this.review !== 'undefined') {
          var temp = this.review
          this.review = new reviewInstance()
          this.review.set(temp)
        }
        this.screenService = reviewFormScreenService
        trackReviewFormService.view({ formType: 'modal' })
      }

      this.close = function () {
        trackReviewFormService.close({})
        if (isMobile.check({ phoneOnly: true })) {
          if (this.contractor.alias) {
            document.location = this.contractorProfileUrl()
          } else if (!document.referrer) {
            document.location = urlService.homepageUrl()
          } else if (window.history && window.history.pushState) {
            window.history.back()
          } else if (document.referrer && document.referrer.length) {
            document.location = document.referrer
          } else {
            document.location = urlService.homepageUrl()
          }
        } else if ($injector.has('$uibModalInstance')) {
          $uibModalInstance.dismiss('cancel')
        } else {
          document.location = this.contractorProfileUrl()
        }
      }

      this.closeIconUrl = function () {
        return urlConstants.s3.assetUrl + '_thumbnails/x-close-icon.png'
      }

      this.backButtonUrl = function () {
        return urlConstants.s3.assetUrl + '_thumbnails/back-button2.png'
      }

      this.contractorThumbnail = function (size) {
        if (size === undefined) {
          size = 'square_150'
        }

        return this.contractor && this.contractor.coverUrl
          ? urlService.imgCropUrl(this.contractor.coverUrl, size)
          : urlConstants.s3.assetUrl + '_thumbnails/contractor-default-icon.png'
      }

      this.contractorProfileUrl = function () {
        if (this.contractor) {
          return urlConstants.contractor.profileURL + this.contractor.alias
        }
      }
    },
  ],
})
