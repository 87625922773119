/* global angular */
import messageTextComponent from './components/message_text_component'
import createCalendarReminderModal from './components/create_calendar_reminder_modal_component'
import financeStatusModel from './models/finance_status_model'
import landOwnershipModel from './models/land_ownership_model'
import permitStatusModel from './models/permit_status_model'
import designStatusModel from './models/design_status_model'

angular
  .module('bzProjectModule', [])
  .component('messageText', messageTextComponent)
  .component('createCalendarReminderModal', createCalendarReminderModal)
  .factory('financeStatusModel', financeStatusModel)
  .factory('landOwnershipModel', landOwnershipModel)
  .factory('permitStatusModel', permitStatusModel)
  .factory('designStatusModel', designStatusModel)
