import unsubscribeConfirmationModalComponent from './components/unsubscribe_confirmation_modal_component'
import CommunicationPreferencesModule from 'BundledModules/communication_preferences/communication_preferences_module.js'

const UnsubscribeConfirmationModalModule = angular
  .module('unsubscribeConfirmationModal', [CommunicationPreferencesModule])
  .component(
    'unsubscribeConfirmationModal',
    unsubscribeConfirmationModalComponent
  ).name

export default UnsubscribeConfirmationModalModule
