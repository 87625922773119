/* global angular, Track, isMobile, UserData, bzGlobal, _vis_opt_goal_conversion */
angular
  .module('bzProjectFormModule')
  .factory('trackProjectFormService', function () {
    return {
      title: 'Service Request Action',
      basicEvent: function (options) {
        Track.send(this.title, this.defaultData(options))
      },
      view: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend(
              {
                action: 'view',
                formType: options.formType || 'modal',
              },
              options
            )
          )
        )
        Track.sendFb('AddToCart')
        Track.sendGa(this.title, 'view', options.referralSource)
        Track.sendGa(
          'sr_flow',
          'opened',
          'v1',
          window.bzGlobal.baseAnalyticsParams || {}
        )
        Track.sendBing(this.title, 'view', options.referralSource)
      },
      sendGenericAdEvent: function (eventName, options) {
        Track.send(
          this.title,
          this.defaultData(angular.extend({ action: eventName }, options))
        )
        Track.sendFbCustom(eventName)
      },
      semConvert: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend({ action: 'sem conversion' }, options)
          )
        )
        Track.sendFb('Purchase')
        Track.sendFbCustom('HV SR Submission')
        Track.semConversionGa('AW-1008188915/KzoSCIDUitYYEPP73uAD', this.id)
        Track.sendBing(this.title, 'submit', options.referralSource)
      },
      submit: function (options) {
        Track.sendGa(
          'sr_flow',
          'submitted',
          'v1',
          window.bzGlobal.baseAnalyticsParams || {}
        )
        Track.send(
          this.title,
          this.defaultData(angular.extend({ action: 'submit' }, options))
        )
        if (
          !UserData.user_signed_in() &&
          UserData.is_production_environment()
        ) {
          window._vis_opt_queue = window._vis_opt_queue || []
          window._vis_opt_queue.push(function () {
            _vis_opt_goal_conversion(202)
          })
        }
      },
      skipTitleScreen: function (options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'title screen skipped',
            formVersion: options.formVersion,
          })
        )
      },
      selectPropertyType: function (propertyType, options) {
        Track.send(
          this.title,
          this.defaultData({
            action: 'property type selected',
            property_type: propertyType,
            formVersion: options.formVersion,
          })
        )
      },
      viewStep: function (step, options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend({ action: 'view step', step: step }, options)
          )
        )
      },
      complete: function (options, callback) {
        Track.sendGa(
          'sr_flow',
          'completed',
          'v1',
          window.bzGlobal.baseAnalyticsParams || {}
        )
        Track.send_callback(
          this.title,
          this.defaultData(angular.extend({ action: 'complete' }, options)),
          callback
        )
      },
      failedToComplete: function (options, callback) {
        Track.send_callback(
          this.title,
          this.defaultData(
            angular.extend({ action: 'failed to complete' }, options)
          ),
          callback
        )
      },
      attachFiles: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend(
              {
                action: 'initial attachment',
              },
              options
            )
          )
        )
      },
      changeEmail: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend(
              {
                action: 'change email',
              },
              options
            )
          )
        )
      },
      budgetSelected: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend(
              {
                action: 'budget selected',
                budget_error: options.budgetError || false,
              },
              options
            )
          )
        )
      },
      closeConfirmAction: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend({ close_confirm_version: options.version }, options)
          )
        )
      },
      selectedCompetingBidsAnswer: function (options) {
        let input = angular.copy(options)
        input.action = 'selected competing bids answer'

        let data = this.defaultData(input)
        data.screenVersion = options.screenVersion
        data.selected_responses = options.selectedResponses

        Track.send(this.title, data)
      },
      viewInformationLoadingScreen: function () {
        Track.send(
          this.title,
          this.defaultData({ action: 'view information loading screen' })
        )
      },
      submitIntroScreen: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend({ action: 'submit intro screen' }, options)
          )
        )
      },
      sendToThumbtack: function (options) {
        Track.send(
          this.title,
          this.defaultData(
            angular.extend({ action: 'sent to Thumbtack' }, options)
          )
        )
      },
      defaultData: function (input) {
        return angular.extend(
          {
            action: input.action ? input.action : '',
            platform: isMobile.check() ? 'mobile' : 'desktop',
            user_signed_in: UserData.present()
              ? UserData.user_signed_in()
              : 'unknown',
            user_type: UserData.present()
              ? UserData.user_role() || 'unknown'
              : 'unknown',
            form_version: input.formVersion ? input.formVersion : '',
            last_screen_viewed: input.lastScreenViewed || '',
            referral_source: input.referralSource ? input.referralSource : '',
            landing_page: input.landingPage ? input.landingPage : '',
            referrer: input.referrer ? input.referrer : '',
            service_request_id: input.serviceRequestId
              ? input.serviceRequestId
              : '',
            utm_source: input.utmSource ? input.utmSource : '',
            source: input.source ? input.source : '',
            reftag: input.reftag ? input.reftag : '',
            variation: input.variation || '',
          },
          input
        )
      },
    }
  })
