/* global SearchContractorsAPI, angular, $ */
angular.module('bzSearchModule').factory('searchModel', [
  '$q',
  'projectPreferencesModel',
  'filterFilter',
  '$timeout',
  function ($q, projectPreferencesModel, filterFilter, $timeout) {
    return {
      searchContractorsAPI: new SearchContractorsAPI(),
      getSearchSuggestions: function (locationSlug, keywords) {
        return this.getSearchContractors(locationSlug, keywords, {
          page_size: 10,
        }).then(
          function (contractors) {
            return this.prepareSuggestions(
              contractors,
              projectPreferencesModel.data.searchContractorProjectTypes,
              keywords
            )
          }.bind(this)
        )
      },
      getSearchContractorProjectTypesSuggestions: function (keywords) {
        return this.prepareSuggestions(
          [],
          projectPreferencesModel.data.searchContractorProjectTypes,
          keywords,
          true
        )
      },
      getSearchContractorProjectTypes: function () {
        return projectPreferencesModel.getSearchContractorProjectTypes(true)
      },
      searchThrottleMilliseconds: 95,
      lastSearchContractors: {},
      getSearchContractors: function (locationSlug, keywords, filters) {
        var that = this
        var deferred = $q.defer()
        that.lastSearchContractors.cancelled = true
        that.lastSearchContractors = deferred

        $timeout(function () {
          if (!deferred.cancelled) {
            that.searchContractorsAPI
              .load_matches(locationSlug, keywords, filters)
              .then(function (response) {
                if (response.success) {
                  deferred.resolve(response.results.contractors)
                }
              })
          }
        }, that.searchThrottleMilliseconds)
        return deferred.promise
      },

      prepareSuggestions: function (
        contractors,
        searchContractorProjectTypes,
        keywords,
        skipHeaders
      ) {
        var results = [],
          foundFirstContractor = false,
          foundFirstService = false,
          serviceCount = 0,
          contractorCount = 0
        var preppedContractors = $.map(contractors, function (c) {
          return {
            name: c.business_name,
            slug: c.alias,
            itemType: 'contractor',
          }
        })
        var filtered = filterFilter(
          searchContractorProjectTypes,
          keywords
        ).concat(preppedContractors)
        filtered.forEach(function (item) {
          if (item.itemType === 'contractor') {
            if (foundFirstContractor || skipHeaders) {
              item.first = false
            } else {
              item.first = true
              foundFirstContractor = true
            }

            contractorCount++

            if (contractorCount <= 5) {
              results.push(item)
            }
          } else {
            if (foundFirstService || skipHeaders) {
              item.first = false
            } else {
              item.first = true
              foundFirstService = true
            }

            serviceCount++

            if (serviceCount <= 5) {
              results.push(item)
            }
          }
        })

        return results
      },
    }
  },
])
