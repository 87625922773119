bz_app.factory('reviewResponseModalService', [
  '$uibModal',
  'applicationConstants',
  function ($uibModal, applicationConstants) {
    return {
      show: function (reviewData) {
        var modalInstance = $uibModal.open({
          templateUrl:
            '/assets/review_responses/review_response_form_modal.html?v=' +
            applicationConstants.templateVersion,
          controller: 'ReviewResponseModalCtrl',
          windowClass: 'modal-modern review-response-modal',
          size: 'md',
          resolve: {
            reviewData: function () {
              return reviewData
            },
          },
        })
        return modalInstance
      },
      showSuccessModal: function () {
        var modalInstance = $uibModal.open({
          templateUrl:
            '/assets/review_responses/review_response_success_modal.html?v=' +
            applicationConstants.templateVersion,
          controller: 'ReviewResponseModalCtrl',
          windowClass: 'modal-modern',
        })
        return modalInstance
      },
    }
  },
])
