/* global angular */
angular
  .module('bzProjectModule')
  .factory('propertyRelationshipsModel', function () {
    return {
      getAll: function () {
        return [
          { value: 'owner-manager', text: 'I own or help manage it' },
          { value: 'renter', text: 'I rent it' },
          { value: 'potential owner', text: "I'm considering buying it" },
          { value: 'real estate agent', text: "I'm a real estate agent" },
          { value: 'other', text: 'Other' },
        ]
      },
      getNreia: function () {
        return this.getAll().filter((item) => {
          return item.value !== 'renter'
        })
      },
    }
  })
