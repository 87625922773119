export default {
  bindings: {
    contactType: '@',
  },
  template:
    '<button 	ng-click="$ctrl.verifyContactMethod()" class="contact-info-verification-resend btn-link"' +
    '					ng-bind="$ctrl.verificationText"' +
    '					ng-disabled="$ctrl.buttonDisabled">' +
    '</button>',
  controller: [
    'userModel',
    function (userModel) {
      this.$onInit = function () {
        this.verificationText =
          this.contactType == 'sms'
            ? 'Resend verification sms'
            : 'Resend verification e-mail'
        this.buttonDisabled = false
      }

      this.verifyContactMethod = function () {
        if (!this.buttonDisabled) {
          userModel.verifyContactMethod(this.contactType, true)
          this.verificationText = 'Verification Message Sent'
          this.buttonDisabled = true
        }
      }
    },
  ],
}
