const customerPhoneModel = [
  '$http',
  'urlConstants',
  function ($http, urlConstants) {
    return {
      apiPhonePath: urlConstants.api.v1.path + 'customer_phones',
      setPreferredPhone: function (
        callableType,
        callableId,
        phoneNumber,
        phoneType
      ) {
        var data = {
          callable_type: callableType,
          callable_id: callableId,
          phone_number: phoneNumber,
        }
        if (phoneType === 'sms') {
          data.sms_preferred = 1
        } else if (phoneType === 'call') {
          data.call_preferred = 1
        }
        return $http.patch(this.apiPhonePath + '/' + phoneNumber, data)
      },
    }
  },
]

export default customerPhoneModel
