/* globals getURLParameter */
import globalBannerManagerComponent from './components/global_banner_manager_component'
import unpaidSubscriptionBanner from './components/banners/unpaid_subscription_banner_component'
import blocksBanner from './components/banners/_blocks_banner_component'
import genericBanner from './components/banners/generic_banner_component'
import maydayLevel1Banner from './components/banners/mayday_level_1_banner_component'
import maydayLevel2Banner from './components/banners/mayday_level_2_banner_component'
import maydayLevel3Banner from './components/banners/mayday_level_3_banner_component'
import poBidRequestedBanner from './components/banners/po_bid_requested_banner_component'
import downloadCoIosAppBanner from './components/banners/download_co_ios_app_banner_component'
import addMissingPermitsBanner from './components/banners/add_missing_permits_banner'
import genericBannerButton from './components/banners/generic_banner/generic_banner_button_component'

import blocksService from './services/blocks_service'
import coBidPostponeModalService from './services/co_bid_postpone_modal_service'
import globalBannerTracking from './services/global_banner_tracking_service'
import poBidRequestBannerTracking from './services/po_bid_request_banner_tracking_service'
import globalNotificationsModel from './services/global_notifications_model'
import globalBannerService from './services/global_banner_service'
import globalModalService from './services/global_modal_service'

import winRateModalController from './controllers/win_rate_modal_controller'

import UnsubscribeConfirmationModalModule from 'BundledModules/unsubscribe_confirmation_modal/unsubscribe_confirmation_modal_module'

const BzGlobalAlertManager = angular
  .module('bzGlobalAlertManager', [
    'bzCommonUtilitiesModule',
    UnsubscribeConfirmationModalModule,
  ])
  .component('genericBanner', genericBanner)
  .component('genericBannerButton', genericBannerButton)
  .component('globalBannerManager', globalBannerManagerComponent)
  .component('unpaidSubscriptionBanner', unpaidSubscriptionBanner)
  .component('blocksBanner', blocksBanner)
  .component('maydayLevel1Banner', maydayLevel1Banner)
  .component('maydayLevel2Banner', maydayLevel2Banner)
  .component('maydayLevel3Banner', maydayLevel3Banner)
  .component('poBidRequestedBanner', poBidRequestedBanner)
  .component('downloadCoIosAppBanner', downloadCoIosAppBanner)
  .component('addMissingPermitsBanner', addMissingPermitsBanner)
  .controller('WinRateModalCtrl', winRateModalController)
  .factory('blocksService', blocksService)
  .factory('globalBannerTracking', globalBannerTracking)
  .factory('poBidRequestBannerTracking', poBidRequestBannerTracking)
  .factory('globalNotificationsModel', globalNotificationsModel)
  .factory('coBidPostponeModalService', coBidPostponeModalService)
  .factory('globalBannerService', globalBannerService)
  .factory('globalModalService', globalModalService)
  .value('globalBannerData', {
    isDisabled: false,
    disabledBannerKey: undefined,
  })
  .run([
    '$rootScope',
    'globalBannerData',
    'globalModalService',
    function ($rootScope, globalBannerData, globalModalService) {
      $rootScope.$on('closeGlobalBanner', (disabledBannerKey) => {
        // To handle the case where the banner is closed before the banner manager component is loaded
        globalBannerData.isDisabled = true
        globalBannerData.disabledBannerKey = disabledBannerKey
      })

      if (getURLParameter('referral_source') === 'join_proposals') {
        return
      }

      globalModalService.checkActiveGlobalModal(true)
    },
  ]).name

export default BzGlobalAlertManager
