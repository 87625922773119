/* global angular */
angular.module('bzMarketingModule').component('partnershipsBanner', {
  bindings: {},
  template: `
		<div ng-click="$ctrl.close()" class="close">&times;</div>
		<span ng-if="!$ctrl.isDataPage()">
			<strong>Looking for more data?</strong>
			Click <a href="/data">here</a> to explore options and request further access!
		</span>
		<span ng-if="$ctrl.isDataPage()">
			<strong>Questions about our data?</strong>
			Call us at <a ng-href="tel:{{$ctrl.contactUsPhone()}}">
				{{$ctrl.contactUsPhone()}}
			</a> or email <a href="mailto:{{$ctrl.contactUsEmail()}}">
				{{$ctrl.contactUsEmail()}}
			</a>
		</span>
	`,
  controller: [
    '$element',
    'applicationConstants',
    function ($element, applicationConstants) {
      this.close = () => {
        $element.removeClass('visible')
      }

      this.isDataPage = () => location.pathname === '/data'
      this.contactUsPhone = () => applicationConstants.support.dataDealsPhone
      this.contactUsEmail = () => applicationConstants.support.dataDealsEmail
    },
  ],
})
