/* global bz_app */
const blocksService = [
  'urlConstants',
  'applicationConstants',
  '$filter',
  'localStorageModel',
  'dateService',
  'stringService',
  'leadModel',
  function (
    urlConstants,
    applicationConstants,
    $filter,
    localStorageModel,
    dateService,
    stringService,
    leadModel
  ) {
    return {
      blockDisplayData: function (block) {
        let info = null
        const checkoutUrl = `/checkout/${block.serviceRequestId}`
        const leadDashboardUrl = `${urlConstants.contractor.leadsDashboardURL}?lead_type=needs_update`

        if (block) {
          const { blockType, projectTitle } = block
          const showStaleLeadAlternative =
            blockType === 'stale_lead' &&
            document.location.pathname ===
              urlConstants.contractor.leadsDashboardURL

          const projectOwner = block.projectOwner || 'a homeowner'

          if (blockType === 'unpaid_invoice') {
            info = {
              text: `
								<p>Your account has been suspended due to an unpaid invoice. Please resolve this asap.</p>
							`,
              modalText: `
								Your account has been suspended due to an unpaid invoice. Please settle your referral fee payment.
							`,
              ctaLabel: 'Pay Now',
              ctaUrl: checkoutUrl,
              action: 'modal',
            }
          } else if (blockType === 'missing_win_price') {
            info = {
              text: `
								<p>
									<span class="project-owner-name">${stringService.capitalizeFirstLetter(
                    projectOwner
                  )}</span> hired you.
									To get access to new projects, please take care of an outstanding payment.
								</p>
							`,
              modalText: `
								<p>${projectOwner} hired you. To access new projects, please settle your referral fee payment.</p>
							`,
              checkoutText:
                'Please complete the following to keep getting access to new projects.',
              ctaLabel: 'Complete Payment',
              ctaUrl: checkoutUrl,
              action: 'redirect',
            }
          } else if (blockType === 'abandoned_payment') {
            info = {
              text: `
								<p>
									Our records show that <span class="project-owner-name">${projectOwner}</span>
									hired you for a
									<span class="block-project-title">${projectTitle}</span> project.
								</p>
								<p>
									Your account has been suspended. To continue accessing new projects,
									please set up your referral fee payment.
								</p>
							`,
              modalText: `
								<p>Our records show that ${projectOwner} hired you.</p>
								<p>Please settle your referral fee payment, as your account has been suspended.</p>
							`,
              checkoutText:
                'Please complete the following to keep getting access to new projects.',
              ctaLabel: 'Pay Now',
              ctaUrl: checkoutUrl,
              action: 'modal',
            }
          } else if (blockType === 'failed_payment') {
            info = {
              text: `
								<p>Our records show that we were unable to process a payment. Your account has been suspended.</p>
								<p>Please resolve this issue by settling the amount owed.</p>
							`,
              modalText: `
								<p>Oops - we were unable to process your payment for ${projectTitle}.</p>
								<p>Please settle the amount owed to access new projects.</p>
							`,
              checkoutText:
                'Please complete the following to keep getting access to new projects.',
              ctaLabel: 'Pay Now',
              ctaUrl: checkoutUrl,
              action: 'modal',
            }
          } else if (blockType === 'questionable_win_amount') {
            info = {
              text: `
								<p>
									Your project total for ${projectOwner}’s
									${projectTitle} project does not match our records.
									To continue accessing projects, adjust the price or upload the contract to complete payment.
								</p>
							`,
              ctaLabel: 'Complete Payment',
              ctaUrl: checkoutUrl,
              action: 'modal',
            }
          } else if (blockType === 'unconfirmed_win') {
            info = {
              text: `
								<p>
									Our records indicate that <span class="project-owner-name">${projectOwner}</span>
									hired you for a <span class="block-project-title">${projectTitle}</span> project. Verify and pay for this win to continue accessing projects on BuildZoom.
								</p>
							`,
              checkoutText:
                'Please complete the following to keep getting access to new projects.',
              ctaLabel: 'Complete Payment',
              onCtaClick: () => {
                leadModel
                  .markHire(block.serviceRequestId, undefined, {
                    hireSource: 'unconfirmed_win_banner',
                    medium: 'web',
                  })
                  .then(() => {
                    document.location = checkoutUrl
                  })
              },
              action: 'modal',
            }
          } else if (blockType === 'stale_lead') {
            if (showStaleLeadAlternative) {
              info = {
                text: `
									<p class="u-text-center mobile-slim">
										Please update the projects marked in your 'Needs Update' queue, below.
									</p>
								`,
                ctaLabel: 'Go To Projects Dashboard',
                ctaUrl: leadDashboardUrl,
                action: 'modal',
              }
            } else {
              info = {
                text: `
									<p>
										Please update us on your active projects.
										We only send new projects to contractors with great close rates.
									</p>
								`,
                ctaLabel: 'Go To Projects Dashboard',
                ctaUrl: leadDashboardUrl,
                action: 'modal',
              }
            }
          } else if (blockType === 'invalid_license') {
            info = {
              text: `
								<p>To unlock new projects, we need to confirm you have an active license.</p>
							`,
              modalText: `
								<p>Please confirm you have an active license to unlock new projects on BuildZoom.</p>
							`,
              ctaLabel: 'Update Your License',
              ctaUrl: urlConstants.contractor.licenseDashboardURL,
              action: 'modal',
            }
          } else if (blockType === 'unresponsive_bid_request') {
            info = {
              text: `
								<p>To unlock new projects, we need to confirm you have an active license.</p>
							`,
              modalText: `
								<p>You have not responded to ${projectOwner}'s request for a bid on their project.</p>
								<p>Please submit a bid or let them know you are not ready.</p>
							`,
              ctaLabel: '',
              ctaUrl: `/lead/${block.serviceRequestId}`,
              action: 'modal',
            }
          }

          if (info) {
            info = Object.assign(block, info)
            info.shouldShowButton = !showStaleLeadAlternative
          }
        }

        return info
      },

      redirectToCta: function (blockInfo) {
        localStorageModel.set(
          'referrerServiceRequestId',
          blockInfo.serviceRequestId
        )

        if (blockInfo.onCtaClick) {
          blockInfo.onCtaClick()
        } else {
          document.location = blockInfo.ctaUrl
        }
      },
    }
  },
]

export default blocksService
