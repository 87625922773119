/* global angular */
angular.module('bzProjectModule').factory('urgencyTypesModel', function () {
  return {
    getInitial: function () {
      return [
        { value: '', text: '- Select one -' },
        { value: "I'm flexible", text: "I'm flexible" },
        { value: 'As soon as possible', text: 'As soon as possible' },
        { value: 'Within the next few weeks', text: 'Within a few weeks' },
        { value: 'Within the next few months', text: 'Within a few months' },
      ]
    },
    getAll: function () {
      return [
        ...this.getInitial(),
        {
          value: 'In more than a few months',
          text: 'In more than a few months',
        },
      ]
    },
  }
})
