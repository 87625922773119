import VerifyContactInfoComponent from './components/_verify_contact_info_component'
import ContactInfoController from './controllers/contact_info_controller'

const BzContactInfo = angular
  .module('bzContactInfo', [])
  .component('verifyContactInfo', VerifyContactInfoComponent)
  .controller('ContactInfoCtrl', ContactInfoController).name

export default BzContactInfo

var ContractorProfileEdit = function () {
  this.current_email = ''

  this.init = function () {
    this.current_email = $('#contractor_email').val()

    $('#contractor_email').blur({ _self: this }, this.on_email_change)

    this.init_phone_format()
    this.init_primary_phone_selection()
    this.init_phone_add()
    this.init_save_phone()
    this.init_verify_email()
  }

  this.init_verify_email = function () {
    $('#verify-email-link').click(function (e) {
      e.preventDefault()
      $.ajax({
        type: 'GET',
        url: '/api/v1/user/verify/',
        data: { type: 'email' },
        dataType: 'json',
        success: function () {
          $('.verify-email').html(
            'Your verification email is on the way! If you don\'t see the email in your inbox, please check your spam folder.  If the email ends up in your spam folder, please mark the email as "Not Spam" to ensure that future emails go directly to your inbox.'
          )
        },
      })
    })
  }

  // select checkbox for receiving leads if user changes email
  this.on_email_change = function (e) {
    var _self = e.data._self
    if ($(this).val() != _self.current_email) {
      $('#contractor_email_subscribed').prop('checked', true)
      _self.current_email = $(this).val()
    }
  }

  this.init_phone_format = function () {
    var that = this
    $('.contractor_phone').each(function () {
      that.format_phone(this)
    })
  }

  this.format_phone = function (obj) {
    $(obj).mask('(999) 999-9999')
  }

  this.init_primary_phone_selection = function () {
    //Enforce only one sms phone when enabling. Do not enforce when disabling.
    $('.sms_subscribed_radio').off('click') // clear any previous event set up

    $('.sms_subscribed_radio').click(function () {
      if ($(this).is(':checked')) {
        $('.sms_subscribed_radio').prop('checked', false)
        $(this).prop('checked', true)
      }
    })
  }

  this.init_phone_add = function () {
    var that = this

    $('.contractor-phone-number-add').click(function (e) {
      var phone_numbers = $('.phone-numbers-form .phone-number-wrapper')
      var phone_numbers_count = phone_numbers.length
      var new_phone_number = phone_numbers.last().clone() // make copy of last
      var new_phone_number_field = new_phone_number.find('.contractor_phone')
        .length
        ? new_phone_number.find('.contractor_phone')
        : null
      new_phone_number
        .children('.row')
        .children()
        .first()
        .children()
        .children('.verification-label')
        .attr('class', 'verification-label unverified')
      new_phone_number
        .children('.row')
        .children()
        .first()
        .children()
        .children('.verification-label')
        .html('Unverified')
      // at least one phone already present
      if (
        new_phone_number_field !== null &&
        new_phone_number_field.val().length
      ) {
        new_phone_number.find('.control-label')[0].innerText =
          'Additional Phone' // change label of new phone number to 'Additional Number'

        that.create_next_phone_number(
          new_phone_number,
          new_phone_number_field,
          phone_numbers_count
        )
        phone_numbers.last().after(new_phone_number) // add new row to form

        that.init_primary_phone_selection()
      }
      // no phones present
      else if (new_phone_number_field === null) {
        var new_phone_number_template = $(
          '.new-phone-template .phone-number-wrapper'
        )
        new_phone_number = new_phone_number_template.clone() // copy the template
        new_phone_number_template.remove() // remove the template
        new_phone_number_field = new_phone_number.find('.contractor_phone') // set form field of the template

        that.create_next_phone_number(
          new_phone_number,
          new_phone_number_field,
          0
        )
        $('.phone-numbers-form .phone-numbers-wrapper').append(new_phone_number) // add new row to form

        that.init_primary_phone_selection()
        $('.phone-numbers-save-button').show()
      }

      e.preventDefault()
    })
  }

  this.create_next_phone_number = function (
    new_phone_number,
    new_phone_number_field,
    index
  ) {
    new_phone_number_field.val('') // clear current value
    this.format_phone(new_phone_number_field) // init phone formatting
    new_phone_number.find('.contractor-phone-delete-link').remove() // remove DELETE link (not needed for new)
    new_phone_number_field.attr(
      'name',
      'contractor[phones_attributes][' + index + '][number]'
    )

    new_phone_number
      .find(
        "input[name='" +
          new_phone_number.find('.sms_subscribed_radio').attr('name') +
          "']"
      )
      .attr(
        'name',
        'contractor[phones_attributes][' + index + '][sms_subscribed]'
      )
      .prop('checked', index == 0)
  }

  this.init_save_phone = function () {
    $('.phone-numbers-wrapper')
      .closest('form')
      .submit(function (e) {
        $('.phone-number-wrapper').each(function (phone_number) {
          if (!$(this).find('.contractor_phone').val().length) $(this).remove()
        })
      })
  }
}

$(function () {
  // init listeners for logic related to contractor dashboard profile edit
  var contractor_profile_edit = new ContractorProfileEdit()
  contractor_profile_edit.init()
})
