const declineLeadService = [
  'urlConstants',
  '$filter',
  function (urlConstants, $filter) {
    return {
      explanationObjs: [
        {
          value: 'notEnoughInfo',
          alias: 'Not enough information about the lead',
          selected: false,
        },
        {
          value: 'lowBudget',
          alias: 'Budget too low for this size project',
          selected: false,
        },
        { value: 'tooFar', alias: 'Too far away', selected: false },
        {
          value: 'notAvailable',
          alias: 'Not available right now',
          selected: false,
        },
        {
          value: 'wrongProjectSize',
          alias: "I don't do this size project",
          selected: false,
        },
        {
          value: 'wrongProjectType',
          alias: "I don't do this type of work",
          selected: false,
        },
      ],
      explanationMapping: {
        notEnoughInfo: 'not_enough_info',
        lowBudget: 'low_budget',
        tooFar: 'too_far',
        notAvailable: 'not_available',
        wrongProjectSize: 'wrong_project_size',
        wrongProjectType: 'wrong_project_type',
        Other: 'other',
      },
      explanationLinks: {
        notEnoughInfo: urlConstants.contractor.leadsDashboardURL,
        lowBudget: urlConstants.contractor.leadsDashboardURL,
        tooFar: urlConstants.contractor.servicesURL,
        notAvailable: urlConstants.contractor.leadsDashboardURL,
        wrongProjectSize: urlConstants.contractor.servicesURL,
        wrongProjectType: urlConstants.contractor.servicesURL,
        other: urlConstants.contractor.leadsDashboardURL,
      },
      explanationLinkDestinations: {
        notEnoughInfo: 'Back to Projects Dashboard',
        lowBudget: 'Back to Projects Dashboard',
        tooFar: 'Update distance preferences',
        notAvailable: 'Back to Projects Dashboard',
        wrongProjectSize: 'Update size preferences',
        wrongProjectType: 'Update services',
        other: 'Back to Projects Dashboard',
      },
      explanationLinkDestinationDescriptions: {
        notEnoughInfo: 'Thanks for letting us know.',
        lowBudget: 'Thanks for letting us know.',
        tooFar:
          'If you want, you can update how far you are willing to travel on your dashboard:',
        notAvailable: 'Thanks for letting us know.',
        wrongProjectSize:
          'If you want, you can update your project size preferences on your dashboard:',
        wrongProjectType:
          'If you want, you can update the type of work you do on your dashboard:',
        other: 'Thanks for letting us know.',
      },
      defaultModal: function (explanation) {
        return (
          ['wrongProjectType', 'wrongProjectSize', 'tooFar'].indexOf(
            explanation
          ) < 0
        )
      },
      explanation: function (explanation) {
        if (this.explanationMapping[explanation]) {
          return this.explanationMapping[explanation]
        }
        return explanation
      },
      explanationLink: function (explanation) {
        if (this.explanationLinks[explanation]) {
          return this.explanationLinks[explanation]
        }
        return this.explanationLinks.other
      },
      explanationLinkDestination: function (explanation) {
        if (this.explanationLinkDestinations[explanation]) {
          return this.explanationLinkDestinations[explanation]
        }
        return this.explanationLinkDestinations.other
      },
      explanationLinkDestinationDescription: function (explanation) {
        if (this.explanationLinkDestinationDescriptions[explanation]) {
          return this.explanationLinkDestinationDescriptions[explanation]
        }
        return this.explanationLinkDestinationDescriptions.other
      },
      randomOrderExplanationObjs: function () {
        var explanationObjsCopy = this.explanationObjs.slice(0)
        explanationObjsCopy = explanationObjsCopy.sort(function (a, b) {
          return 0.5 - Math.random()
        })
        return explanationObjsCopy
      },
      screenSequence: function (explanation) {
        return (
          {
            wrongProjectType: ['survey', 'projectPreferences', 'referral'],
            wrongProjectSize: ['survey', 'budget', 'referral'],
            tooFar: ['survey', 'travel', 'referral'],
            notAvailable: ['survey', 'availability'],
            lowBudget: ['survey', 'projectBudgetMin'],
          }[explanation] || ['survey', 'referral']
        )
      },
      prettyJobValue: function (numericValue) {
        return !numericValue && numericValue !== 0
          ? null
          : $filter('currency')(numericValue, '$', 0)
      },
      numericJobValue: function (prettyJobValue) {
        return prettyJobValue.replace(/\D/g, '')
      },
      saveButtonText: function (submitting) {
        return submitting ? 'Saving...' : 'Save'
      },
    }
  },
]

export default declineLeadService
