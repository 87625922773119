import template from './consultant_details.html'

const consultantDetailsComponent = {
  bindings: {
    consultant: '<',
    highTouch: '<',
  },
  template,
  controller: [
    'applicationConstants',
    'urlService',
    function (applicationConstants, urlService) {
      this.$onInit = () => {
        this.consultantName = `${this.consultant.firstName} ${this.consultant.lastName}`
        this.consultantInitials = `${this.consultant.firstName[0]}${this.consultant.lastName[0]}`
        this.consultantEmail =
          (this.highTouch && this.consultant.highTouchEmail) ||
          this.consultant.email ||
          this.consultant.contactInfo.email.value
        this.consultantPhone =
          this.consultant.phoneNumber ||
          this.consultant.contactInfo.phone.value ||
          applicationConstants.support.phone

        if (this.consultant.profileImageSlug) {
          this.consultantImage = urlService.imgCropUrl(
            this.consultant.profileImageSlug,
            'square_200'
          )
        } else if (
          this.consultantEmail === applicationConstants.support.email
        ) {
          this.consultantImage =
            applicationConstants.filepicker.stock_image_url +
            '_thumbnails/project/retina/sofie-smile.png'
        }
      }
    },
  ],
}

export default consultantDetailsComponent
