/* global angular */
angular.module('bzCommonUtilitiesModule').filter('newlines', function () {
  return function (text) {
    return text.replace(/\n/g, '<br/>')
  }
})

angular
  .module('bzCommonUtilitiesModule')
  .filter('newlines_and_returns', function () {
    return function (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }
  })

angular.module('bzCommonUtilitiesModule').filter('lineFeed', function () {
  return function (text) {
    return text.replace(/&#10;/g, '<br/>')
  }
})

angular.module('bzCommonUtilitiesModule').filter('phone', function () {
  return function (tel, omitParens) {
    if (!tel) {
      return ''
    }

    var value = tel.toString().trim().replace(/^\+/, '')

    if (value.match(/[^0-9]/)) {
      return tel
    }

    var country, city, number

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1
        city = value.slice(0, 3)
        number = value.slice(3)
        break

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0]
        city = value.slice(1, 4)
        number = value.slice(4)
        break

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3)
        city = value.slice(3, 5)
        number = value.slice(5)
        break

      default:
        return tel
    }

    if (country == 1) {
      country = ''
    }

    number = number.slice(0, 3) + '-' + number.slice(3)

    if (omitParens) {
      return (city + '-' + number).trim()
    } else {
      return (country + ' (' + city + ') ' + number).trim()
    }
  }
})

angular.module('bzCommonUtilitiesModule').filter('capitalize', function () {
  return function (input) {
    if (!input) {
      return ''
    }

    return input.capitalize()
  }
})

angular.module('bzCommonUtilitiesModule').filter('limitHtml', [
  '$sce',
  function ($sce) {
    return function (text, limit) {
      var changedString = String(text).replace(/<[^>]+>/gm, '')
      return $sce.trustAsHtml(
        changedString.length > limit
          ? changedString.substr(0, limit - 2) + '&hellip;'
          : changedString
      )
    }
  },
])

angular.module('bzCommonUtilitiesModule').filter('trustedHtml', [
  '$sce',
  function ($sce) {
    return function (text) {
      return $sce.trustAsHtml(text)
    }
  },
])

angular.module('bzCommonUtilitiesModule').filter('trustedResourceUrl', [
  '$sce',
  function ($sce) {
    return function (text) {
      return $sce.trustAsResourceUrl(text)
    }
  },
])

angular.module('bzCommonUtilitiesModule').filter('truncateString', function () {
  return function (text, limit) {
    if (!text.length) {
      return ''
    }

    if (text.length > limit) {
      return text.slice(0, limit - 3) + '...'
    } else {
      return text.slice(0, limit)
    }
  }
})
