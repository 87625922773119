angular.module('bzProjectLabelsModule').directive('menuProjectLabel', [
  'urlConstants',
  'projectLabelsModel',
  '$rootScope',
  function (urlConstants, projectLabelsModel, $rootScope) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        label: '=',
        currentLabelId: '@',
      },
      template:
        '<li>' +
        '<a ng-class=\'{ "list-sub-nav-a-selected": (currentLabelId == label.id) }\' ng-href="{{ menuUrl() }}?label={{ label.id }}">' +
        '<div class="list-sub-nav-remove pull-right" ng-click="deleteLabel($event)"><i class="fas fa-times"></i></div>' +
        '<span class="badge badge-clear pull-right">{{ label.project_count }}</span><span class="list-sub-nav-label">{{ label.name }}</span>' +
        '</a>' +
        '</li>',
      link: function (scope, element, attr) {
        scope.menuUrl = function () {
          if (UserData.user_role() == 'contractor')
            return urlConstants.dashboard.contractorServiceRequests
          else return urlConstants.dashboard.homeownerServiceRequests
        }

        scope.deleteLabel = function ($event) {
          projectLabelsModel.delete(scope.label.id).then(function (response) {
            if (response && response.success)
              $rootScope.$broadcast('deleteLabelFromServiceRequestsList', {
                labelId: scope.label.id,
              })
          })
          $event.preventDefault()
        }
      },
    }
  },
])
