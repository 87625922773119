var VisitorTrackerAPI = function () {
  this.loadEntityVisitors = function (entityType, entityId) {
    return $.when(
      $.get(
        this.visitor_tracker_api_path() +
          '?entity_type=' +
          entityType +
          '&entity_id=' +
          entityId
      )
    )
  }

  this.track = function (userId, entityType, entityId) {
    if (!entityId) entityId = 0

    return $.when(
      $.ajax({
        url: this.visitor_tracker_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          user_id: userId,
          entity_type: entityType,
          entity_id: entityId,
        }),
        dataType: 'json',
      })
    )
  }
}

VisitorTrackerAPI.prototype = new BZAPI()
VisitorTrackerAPI.prototype.constructor = VisitorTrackerAPI

window.VisitorTrackerAPI = VisitorTrackerAPI
