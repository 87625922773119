/* global angular, bz_app, bzGlobal, google */
angular.module('bzMapModule').directive('permitMap', [
  'propertiesService',
  '$timeout',
  'positionService',
  function (propertiesService, $timeout, positionService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        properties: '@',
        latitude: '@',
        longitude: '@',
        locationId: '@',
        zoom: '@',
        radius: '@',
        limit: '@',
        requireContractor: '@',
        mapLink: '@',
        mapClickSelector: '@',
        listenForUpdates: '=?',
        style: '@',
        allowZoomControl: '<',
        allowDrag: '<',
        loadInViewport: '<',
      },
      template: '<div class="cities-permit-map"></div>',
      link: function (scope, element, attrs) {
        scope.createMap = function () {
          // options for map positioning
          scope.map_options = {
            zoom: scope.zoom,
            center: new google.maps.LatLng(scope.latitude, scope.longitude),
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            panControl: false,
            zoomControl: scope.allowZoomControl
              ? scope.allowZoomControl
              : false,
            scaleControl: false,
            scrollwheel: false,
            gestureHandling: scope.allowDrag ? 'auto' : 'none',
            styles: scope.style ? null : bzGlobal.map.style,
          }
          // create instance of map (use the ID of the directive declaraction attribute)
          scope.map = new google.maps.Map(
            document.getElementById(attrs.id),
            scope.map_options
          )
        }

        scope.loadMapData = function () {
          // Initial pins added to map and list, query in more pins
          if (scope.service) {
            propertiesService.setServiceType(scope.service)
          }

          if (scope.locationId) {
            propertiesService.setLocationId(scope.locationId)
          }

          propertiesService.setPinCursor('default') // shows standard arrow over pins
          propertiesService.setPageSize(scope.limit ? scope.limit : 15)
          propertiesService.setDateSort()
          propertiesService.setRequireContractor(scope.requireContractor)

          // load data from direct input
          if (scope.properties) {
            scope.properties = JSON.parse(decodeURI(scope.properties))
            propertiesService.clearAttributes()
            propertiesService.addPinsToMapFromInput(
              scope.map,
              bzGlobal.map.marker.circle.blue,
              scope.properties,
              {}
            )
          }
          // load data from API
          else {
            propertiesService.addPinsToMapFromAPI(
              scope.map,
              bzGlobal.map.marker.circle.blue
            )
          }
        }

        scope.registerMapListeners = function () {
          google.maps.event.addListenerOnce(scope.map, 'idle', function (ev) {
            scope.loadMapData()
          })

          // on window resize, reposition center of map
          google.maps.event.addDomListener(window, 'resize', function () {
            scope.map.setCenter(scope.map_options.center)
          })

          if (scope.mapLink && scope.mapClickSelector) {
            angular.element('.' + scope.mapClickSelector).click(function (e) {
              document.location = scope.mapLink
            })
          }

          scope.registerUpdateMapListener()
        }

        scope.registerUpdateMapListener = function () {
          scope.$on('properties.setPinsOnMap', function ($event, data) {
            if (scope.listenForUpdates) {
              propertiesService.clearMap(scope.map)
              propertiesService.addPinsToMapFromInput(
                scope.map,
                bzGlobal.map.marker.circle.blue,
                data.properties,
                {}
              )

              google.maps.event.trigger(scope.map, 'resize')
              scope.map.setCenter(scope.map_options.center)
            } else {
              // when maintaining static data, a dynamic update event should reset the same data on the map
              $timeout(function () {
                propertiesService.addPinsToMapFromInput(
                  scope.map,
                  bzGlobal.map.marker.circle.blue,
                  scope.properties,
                  {}
                )
              }, 100)
            }
          })
        }

        scope.setInputs = function () {
          if (!scope.zoom) {
            if (scope.radius) {
              scope.radius = Number(scope.radius)
              scope.zoom = Math.round(14 - Math.log(scope.radius) / Math.LN2)
            } else {
              scope.zoom = 13
            }
          } else {
            scope.zoom = Number(scope.zoom)
          }

          if (
            scope.requireContractor === undefined ||
            scope.requireContractor === 'true'
          ) {
            scope.requireContractor = true
          } else if (scope.requireContractor === 'false') {
            scope.requireContractor = false
          }

          if (scope.listenForUpdates === undefined) {
            scope.listenForUpdates = true
          }
        }

        scope.setInputs()

        if (scope.loadInViewport) {
          positionService.triggerInViewport(
            `#${attrs.id}`,
            () => {
              scope.createMap()
              scope.registerMapListeners()
              Track.sendGa('Google Map', 'city page')
            },
            { checkForMap: true }
          )
        } else {
          scope.createMap()
          scope.registerMapListeners()
        }
      },
    }
  },
])
