/* global bz_app, angular, isMobile */
bz_app
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    'applicationConstants',
    function (
      $stateProvider,
      $urlRouterProvider,
      $locationProvider,
      applicationConstants
    ) {
      var device_template_extension =
        (isMobile.check({ phoneOnly: true }) ? 'mobile' : 'desktop') +
        '.html?v=' +
        applicationConstants.templateVersion
      var queryStringInputs = '?auth_token'

      $stateProvider
        .state('default', {
          url: '/' + queryStringInputs,
          templateUrl:
            '/assets/messages/conversations/conversations_' +
            device_template_extension,
          controller: 'ConversationsCtrl as $ctrl',
        })
        .state('messages', {
          url: '/{status}' + queryStringInputs,
          templateUrl:
            '/assets/messages/conversations/conversations_' +
            device_template_extension,
          controller: 'ConversationsCtrl as $ctrl',
        })

      $urlRouterProvider.otherwise('/')

      if (window.history && history.pushState) {
        $locationProvider.html5Mode(true)
      }
    },
  ])
  .run([
    '$transitions',
    function ($transitions) {
      $transitions.onSuccess({}, function () {
        angular.element('html, body').animate({ scrollTop: 0 }, 200)
      })
    },
  ])
