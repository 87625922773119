/* global angular */
angular.module('bzProjectFormModule').component('passwordScreen', {
  bindings: {
    project: '=',
    onRedirect: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/password'
      )
    },
  ],
  controller: [
    '$scope',
    'userModel',
    'trackProjectFormService',
    'projectFormScreenService',
    function (
      $scope,
      userModel,
      trackProjectFormService,
      projectFormScreenService
    ) {
      this.$onInit = function () {
        this.submitButtonLabel = 'submit'
        this.newPassword = ''
        this.errors = {
          password: '',
        }

        projectFormScreenService.setScreenCallbacks.push(() => {
          projectFormScreenService.focusInputOnScreenShow(
            'password',
            '.project-form-password'
          )
        })
      }

      this.validateBeforeNextScreen = function () {
        this.validatePassword()

        if (!this.errors.password) {
          if (this.newPassword && this.newPassword.length) {
            userModel.updatePassword(this.newPassword).then(() => {
              this.redirectToProject()
            })
          } else {
            this.redirectToProject()
          }
        }
      }

      this.validatePassword = function () {
        if (!this.newPassword || this.newPassword.length < 6) {
          this.errors.password = 'Password should be at least 6 characters.'
        } else {
          this.errors.password = ''
        }
      }

      this.redirectToProject = function () {
        this.submitButtonLabel = 'submitting...'
        this.project.analytics.lastScreenViewed = 'project-page'

        this.project.saveSummary().then(() => {
          trackProjectFormService.complete(
            angular.extend(
              {
                projectTypeGroupId: this.project.projectType
                  ? this.project.projectType.projectTypeGroupId
                  : null,
                title: this.project.summary.title,
              },
              this.project.analytics
            ),
            () => {
              this.onRedirect()
            }
          )
        })
      }

      this.goToUserScreen = function () {
        trackProjectFormService.changeEmail(this.project.analytics)
        projectFormScreenService.backToScreen('user')
      }

      this.keyboardToNextScreen = function ($event) {
        if ($event.keyCode === 13) {
          this.validateBeforeNextScreen()
        }
      }

      this.isFormVersion = function (formVersion) {
        return this.project.analytics.formVersion === formVersion
      }

      this.headerText = function () {
        if (
          this.project.analytics &&
          this.project.analytics.potentialLeadFormVersion
        ) {
          return (
            'Lastly, set up your account to connect with ' +
            (this.project.analytics.potentialLeadContractorBusinessName ||
              'the contractor')
          )
        } else {
          return 'Create a password to easily access your matches.'
        }
      }
    },
  ],
})
