/* global angular */

class updateLinkToCityController {
  constructor($element, $timeout, locationService, userLocationModel) {
    this.$element = $element
    this.$timeout = $timeout
    this.locationService = locationService
    this.userLocationModel = userLocationModel

    if (document.readyState === 'complete') {
      this.updateLinkToCity()
    } else if (window.addEventListener) {
      window.addEventListener('load', this.updateLinkToCity.bind(this), false)
    } else if (window.attachEvent) {
      window.attachEvent('onload', this.updateLinkToCity.bind(this))
    }
  }

  updateLinkToCity() {
    this.userLocationData = this.userLocationModel.data

    this.$timeout(() => {
      if (this.userLocationData.city && this.userLocationData.state) {
        let path = this.locationService.to_slug(
          this.userLocationData.city + ', ' + this.userLocationData.state
        )

        if (path && path.length > 3) {
          this.$element.attr('href', `/${path}`)
        }
      }
    }, 500)
  }
}

export const updateLinkToCity = function () {
  return {
    restrict: 'A',
    scope: {},
    controllerAs: '$ctrl',
    bindToController: {},
    controller: updateLinkToCityController,
  }
}

updateLinkToCityController.$inject = [
  '$element',
  '$timeout',
  'locationService',
  'userLocationModel',
]
