// import chrono from 'chrono-node'
import template from './message_text.html'

const getSenderPhoneTooltip = () => {
  let oppositeUser = 'contractors'

  if (UserData.is_contractor()) {
    oppositeUser = 'project owners'
  }

  return (
    `We replace your phone number with an anonymous one that will route ` +
    `directly to your phone, and do the same for ${oppositeUser} to protect our users' ` +
    `personal information. To maintain security and safety on BuildZoom, ` +
    `we ask that you use our anonymous numbers.`
  )
}

const getSenderEmailTooltip = () => {
  let oppositeUser = 'contractors'

  if (UserData.is_contractor()) {
    oppositeUser = 'project owners'
  }

  return (
    `We replace your email with an anonymous one that will route ` +
    `directly to your inbox, and do the same for ${oppositeUser} to protect our users' ` +
    `personal information. To maintain security and safety on BuildZoom, ` +
    `we ask that you use our anonymous emails.`
  )
}

const TRACK_TITLE = 'BZ Message Event Action'

export default {
  bindings: {
    message: '<',
    className: '@',
    iconColor: '@',
    tooltipPlacement: '@',
    hideTooltip: '<',
    hideDateEvents: '<',
  },
  template,
  controller: [
    'stringService',
    'modalScreenService',
    function (stringService, modalScreenService) {
      this.$onInit = () => {
        this.iconColor = this.iconColor || 'blue'
        this.classObj = {
          [this.className]: true,
          'generated-message-text': true,
        }
        this.tooltipPlacement = this.tooltipPlacement || 'top'
        this.chronoDates = [] //chrono
        // .parse(this.message.body)
        // .filter(({ tags }) => !tags.ExtractTimezoneOffsetRefiner)
        this.matchers = this.buildMatchers()
        this.messageBody = this.buildMessageBody()

        if (this.message.conversation_id) {
          this.defaultTrackingParams = {
            userId: UserData.user_id(),
            isContractor: UserData.is_contractor(),
            conversationId: this.message.conversation_id,
          }
        }
      }

      this.track = (action, options = {}) => {
        const params = Object.assign(
          { action },
          options,
          this.defaultTrackingParams
        )
        Track.send(TRACK_TITLE, params)
      }

      this.openReminderModal = (chronoDateIndex) => {
        const chronoDate = this.chronoDates[chronoDateIndex]

        this.track('clicked event date', {
          eventText: chronoDate.text,
          eventDate: chronoDate.start.date(),
        })

        modalScreenService.initDetachedComponent({
          component: 'createCalendarReminderModal',
          windowClass: 'v3-modal-simple small clear calendar-reminder-modal',
          inputData: {
            chronoDate,
            message: this.message,
            track: this.track,
          },
        })
      }

      this.buildMatchers = () => {
        if (this.hideDateEvents) {
          return this.emailAndPhoneMatchers()
        }

        return this.emailAndPhoneMatchers().concat(this.dateMatchers())
      }

      this.emailAndPhoneMatchers = () => [
        {
          pattern: /\[\[sender_phone\]\]/g,
          template: this.tooltipTemplate(getSenderPhoneTooltip()),
        },
        {
          pattern: /\[\[sender_email\]\]/g,
          template: this.tooltipTemplate(getSenderEmailTooltip()),
        },
      ]

      this.dateMatchers = () =>
        this.chronoDates.map((chronoDate, index) => {
          const dateText = chronoDate.text.replace(/[()]/g, '')

          return {
            pattern: new RegExp(`\\b${dateText}\\b`, 'g'),
            template: this.dateTemplate(dateText, index),
          }
        })

      this.tooltipTemplate = (text) =>
        `<i uib-tooltip="${text}" ` +
        `ng-hide="$ctrl.hideTooltip" ` +
        `tooltip-placement="${this.tooltipPlacement}" ` +
        `tooltip-class="tooltip-modern" ` +
        `class="message-text-info-icon ${this.iconColor}-icon u-background-contain"> ` +
        `</i>`

      this.dateTemplate = (dateText, index) => {
        return `<a class="u-clickable" style="text-decoration: underline;" ng-click="$ctrl.openReminderModal(${index})">${dateText}</a>`
      }

      this.buildMessageBody = () =>
        stringService.desktopReady(
          this.matchers.reduce(this.applyMatcher, this.message.body)
        )

      this.applyMatcher = (body, { pattern, template }) =>
        body.replace(pattern, template)
    },
  ],
}
