/* global bz_app, angular */
bz_app.component('leadAction', {
  bindings: {
    lead: '=',
    handleLeadAction: '&',
    openReminderModal: '&',
    needsUpdate: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('contractor/leads/lead_action')
    },
  ],
  controller: [
    '$scope',
    'bidModalService',
    'archiveLeadModalService',
    'planToBidModalService',
    'trackCheckoutService',
    'applicationConstants',
    'leadModel',
    'contractorLeadBlocksFlow',
    'dateService',
    'denyWinModalService',
    'confirmWinModalService',
    'poBidRequestBannerTracking',
    'coBidPostponeModalService',
    'urlConstants',
    'serviceRequestEventModel',
    function (
      $scope,
      bidModalService,
      archiveLeadModalService,
      planToBidModalService,
      trackCheckoutService,
      applicationConstants,
      leadModel,
      contractorLeadBlocksFlow,
      dateService,
      denyWinModalService,
      confirmWinModalService,
      poBidRequestBannerTracking,
      coBidPostponeModalService,
      urlConstants,
      serviceRequestEventModel
    ) {
      this.$onInit = function () {
        this.outcome = this.lead.outcome.contractorReportedState
        if (this.outcome === 'expired') {
          this.outcome = 'inactive'
        }

        this.updated = false

        this.leadStatuses = [
          { value: 'needs_update', text: 'Needs Update' },
          { value: 'contacted', text: 'Contacted' },
          {
            value: 'meeting_scheduled',
            text:
              this.lead.type == 'meeting_scheduled' &&
              this.lead.hasStaleLeadBlock() &&
              !this.updated
                ? 'Waiting for Meeting'
                : 'Meeting Scheduled',
          },
          {
            value: 'preparing_bid',
            text:
              this.lead.type == 'preparing_bid' &&
              this.lead.hasStaleLeadBlock() &&
              !this.updated
                ? 'Still Preparing Bid'
                : 'Prepare Bid',
          },
          {
            value: 'closing',
            text:
              this.lead.type == 'closing' &&
              this.lead.hasStaleLeadBlock() &&
              !this.updated
                ? 'Still Closing'
                : 'Closing',
          },
          { value: 'won', text: 'Hired' },
          {
            value: 'inactive',
            text: this.outcome === 'inactive' ? 'Trash' : 'Send to Trash',
          },
        ]

        if (this.needsUpdate) {
          this.selectedStatus = this.getNeedsUpdateStatus()
          this.insertClientUnresponsiveStatus()
        } else {
          this.selectedStatus = this.leadStatuses.find(
            (leadStatus) => leadStatus.value === this.outcome
          )
        }

        this.hideConfirmWin = false
        this.bzNumber = applicationConstants.support.phone
        this.bzNumberLink = 'tel:' + applicationConstants.support.phoneNumber
        this.isImpersonating = UserData.current_staff_user()
      }

      this.resetLeadStatusText = function () {
        if (this.selectedStatus.value === 'meeting_scheduled') {
          this.selectedStatus.text = 'Meeting Scheduled'
        } else if (this.selectedStatus.value === 'preparing_bid') {
          this.selectedStatus.text = 'Prepare Bid'
        } else if (this.selectedStatus.value === 'closing') {
          this.selectedStatus.text = 'Closing'
        }
      }

      this.getNeedsUpdateStatus = () =>
        this.leadStatuses.find(({ value }) => value === 'needs_update')

      this.insertClientUnresponsiveStatus = () => {
        const index = this.leadStatuses.findIndex(
          ({ value }) => value === 'won'
        )
        this.leadStatuses.splice(index, 0, {
          value: 'po_unresponsive',
          text: 'Client Unresponsive',
        })
      }

      this.statusColor = function () {
        if (typeof this.selectedStatus === 'undefined') {
          return ''
        } else if (this.needsUpdate) {
          return 'needs_update'
        } else if (this.selectedStatus.value === 'inactive') {
          return 'inactive'
        } else if (this.selectedStatus.value === 'won') {
          return 'won'
        } else {
          return ''
        }
      }

      this.updateStatus = function (status) {
        this.handleLeadAction({
          lead: this.lead,
          newType: this.selectedStatus.value,
          previousType: this.lead.type,
        })
        this.lead.outcome.contractorOutcomeUpdatedAt = new Date()
        this.updated = true

        if (typeof status === 'undefined') {
          status = this.selectedStatus.value
        }

        this.lead.clearStaleLeadBlock()

        leadModel.setOutcome(this.lead.project.id, status).then(
          function (data) {
            this.lead.type = this.selectedStatus.value
            this.lead.outcome.contractorReportedState = this.lead.type
            this.trackLeadStatusUpdate()
            this.resetLeadStatusText()

            if (this.lead.outcome.blockWasEnded) {
              contractorLeadBlocksFlow.run(this.lead.contractor.id, 'new')
            }
          }.bind(this)
        )
      }

      this.shouldShowOption = (status) => {
        return status.value !== 'needs_update' || this.needsUpdate
      }

      this.handleStatusChange = function (newStatus) {
        if (this.lead.bid == null && newStatus.value === 'closing') {
          //if closing is selected and no bid ever submitted, don't update status yet
          this.handleStatusClosing(newStatus)
        } else if (newStatus.value === 'won') {
          this.handleStatusWon(newStatus)
        } else if (newStatus.value === 'meeting_scheduled') {
          this.handleStatusMeetingScheduled(newStatus)
        } else if (newStatus.value === 'po_unresponsive') {
          this.handleClientUnresponsive()
        } else {
          this.handleStatusDefault(newStatus)
        }
      }

      this.handleClientUnresponsive = () => {
        serviceRequestEventModel.create({
          id: this.lead.project.id,
          event: 'co_claimed_po_unresponsive',
          source: 'web',
        })

        this.updateStatus(this.lead.type) // keep old status
      }

      this.handleStatusDefault = (newStatus) => {
        this.selectedStatus = newStatus

        if (
          this.selectedStatus.value === 'closing' &&
          this.lead.type !== 'closing'
        ) {
          this.openBidModal()
        } else if (this.selectedStatus.value === 'inactive') {
          this.archive()
        }
        this.updateStatus()
      }

      this.archive = ($event) => {
        archiveLeadModalService.show({
          lead: this.lead,
        })

        if ($event) {
          $event.preventDefault()
        }
      }

      this.handleStatusClosing = (newStatus) => {
        this.openBidModal()

        $scope.$watch(
          () => {
            return this.lead.bid
          },
          (newValue, oldValue) => {
            if (newValue !== oldValue) {
              this.selectedStatus = newStatus
              this.updateStatus()
            }
          }
        )
      }

      this.openBidModal = () => {
        bidModalService.show({
          lead: this.lead,
          onCancel: function () {
            if (this.lead.hasStaleLeadBlock()) {
              this.selectedStatus = this.getNeedsUpdateStatus()
            }
          }.bind(this),
        })
      }

      this.handleStatusWon = (newStatus) => {
        const hireSourceDevice = isMobile.check({ phoneOnly: true })
          ? 'Mobile'
          : 'Desktop'
        confirmWinModalService.openModal(
          this.lead,
          `CO: Leads Dashboard ${hireSourceDevice}`,
          () => {
            if (this.lead.hasStaleLeadBlock()) {
              this.selectedStatus = this.getNeedsUpdateStatus()
            }
          }
        )
      }

      this.handleStatusMeetingScheduled = (newStatus) => {
        this.openReminderModal(this.lead)
        this.handleStatusDefault(newStatus)
      }

      this.hiredText = function () {
        if (!this.lead.shouldConfirmWin) {
          return ''
        }
        if (this.projectOwnerNameExists()) {
          return this.lead.project.projectOwner.fullName + ' hired you.'
        } else {
          return 'You were hired.'
        }
      }

      this.promptInfoText = function () {
        var hiredBy = this.projectOwnerNameExists()
          ? this.lead.project.projectOwner.fullName + ' hired you'
          : 'you were hired'
        var text =
          'Our records indicate that ' +
          hiredBy +
          ' for a project (' +
          this.lead.project.title +
          ') on ' +
          dateService.prettyDate(
            this.lead.outcome.poStatusUpdatedAt,
            'mediumDate'
          ) +
          '. Verify this win to continue accessing projects on BuildZoom. If you believe our records are incorrect, '
        return text
      }

      this.projectOwnerNameExists = function () {
        return (
          this.lead.project.projectOwner &&
          this.lead.project.projectOwner.fullName &&
          this.lead.project.projectOwner.fullName.length
        )
      }

      this.toggleShowPromptInfo = function () {
        this.showPromptInfo = !this.showPromptInfo
      }

      this.trackLeadStatusUpdate = function () {
        LeadsDashboardTrack({
          serviceRequestId: this.lead.project.id,
          contractorId: this.lead.contractor.id,
        }).leadStatusUpdate(this.selectedStatus.value)
      }

      this.redirectToCheckout = function () {
        document.location = this.lead.checkoutURL()
      }

      this.toggleConfirmWin = function () {
        this.hideConfirmWin = !this.hideConfirmWin
      }

      this.showLeadButton = function () {
        return (
          (!this.lead.isConnected() &&
            this.lead.openForContractor &&
            this.lead.respondedAt === null) ||
          this.lead.canWaitlist()
        )
      }

      this.showPaymentInfo = function () {
        return (
          this.lead.isPaymentRefundable() &&
          !this.lead.hasQuestionableWinAmountBlock()
        )
      }

      this.leadActionRequired = function () {
        if (this.lead.hasExpired()) {
          return 'expired'
        } else if (this.showLeadButton()) {
          return 'leadPage'
        } else if (
          this.lead.isDisconnectedForNoContact() &&
          !this.lead.isArchived()
        ) {
          return 'declined'
        } else if (this.lead.promptToContact()) {
          return 'promptToContact'
        } else if (
          this.lead.promptForPayment() ||
          this.lead.hasQuestionableWinAmountBlock()
        ) {
          return 'payment'
        } else if (this.lead.askToConfirmWin() && !this.hideConfirmWin) {
          return 'confirmWin'
        } else if (this.lead.hasOpenPoBidRequest()) {
          return 'poBidRequestBlock'
        } else if (this.lead.isPaymentRefundable() && !this.isImpersonating) {
          return null
        } else if (
          (this.lead.isConnected() || this.lead.isDisconnected()) &&
          this.lead.type !== 'won'
        ) {
          return 'statusUpdate'
        } else if (this.lead.waitlisted) {
          return 'waitlisted'
        }
      }

      this.redirectToSubmitBidToClient = () => {
        poBidRequestBannerTracking.dashboardSubmitBid(this.lead.poBidRequest.id)
        window.location = `/lead/${this.lead.project.id}?page_state=submit_bid`
      }

      this.postponePoBidRequest = () => {
        poBidRequestBannerTracking.dashboardNotReady(this.lead.poBidRequest.id)
        coBidPostponeModalService.open(
          this.lead.poBidRequest,
          this.lead.project.id
        )
      }

      this.isAllowedToPostpone = () => {
        return (
          this.lead.poBidRequest &&
          this.lead.poBidRequest.status === 'REQUESTED' &&
          this.lead.poBidRequest.postponeNotificationsSentCount < 3
        )
      }

      this.leadCTA = function (action) {
        LeadsDashboardTrack({
          serviceRequestId: this.lead.project.id,
          contractorId: this.lead.contractor,
        }).leadStatusCTA(action)
        switch (action) {
          case 'checkout':
            this.redirectToCheckout()
            break
          case 'confirmWin':
            this.selectedStatus = { value: 'won', text: 'Won' }
            this.updateStatus()
            this.toggleConfirmWin()
            this.redirectToCheckout()
            break
          case 'denyWin':
            this.openDenyWinModal()
            this.updateStatus(this.lead.type)
            this.selectedStatus = this.getNeedsUpdateStatus()
            this.toggleConfirmWin()
            break
        }
      }

      this.openDenyWinModal = function () {
        denyWinModalService.openModal(this.lead)
      }

      this.showPendingPaymentDueDate = function () {
        return (
          this.lead.isPaymentRefundable() &&
          this.lead.checkout.pendingPaymentDueDate &&
          this.lead.checkout.pendingPaymentDueDate.length
        )
      }

      this.pendingPaymentDueDate = function () {
        return (
          'Due: ' +
          dateService.prettyDate(
            this.lead.checkout.pendingPaymentDueDate,
            'mediumDate'
          )
        )
      }

      this.pendingCheckoutDetail = function () {
        return (
          this.lead.checkout.paidInvoiceCount() +
          ' of ' +
          this.lead.checkout.activeInvoiceCount() +
          ' payments complete'
        )
      }

      this.isPending = function () {
        return this.lead.checkout.promptToPayBalance()
      }

      this.isPaid = function () {
        return this.lead.checkout.isPaid()
      }

      this.isOutstanding = function () {
        return this.lead.checkout.isOutstanding()
      }

      this.isMobileView = function () {
        return isMobile.check()
      }

      this.trackPhoneClick = function () {
        LeadsDashboardTrack({
          serviceRequestId: this.lead.project.id,
          contractorId: this.lead.contractor.id,
        }).trackPhoneLink()
      }

      this.trackMessageClick = function () {
        LeadsDashboardTrack({
          serviceRequestId: this.lead.project.id,
          contractorId: this.lead.contractor.id,
        }).clickMessageLink()
      }
    },
  ],
})
