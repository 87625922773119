import template from './contractor_availability_screen.html'

const contractorAvailabilityScreen = {
  bindings: {
    lead: '<',
    onComplete: '&',
  },
  template,
  controller: [
    'trackDeclineLeadService',
    'dateService',
    'contractorModel',
    function (trackDeclineLeadService, dateService, contractorModel) {
      this.$onInit = function () {
        this.availabilityOptions = [
          { value: 5, alias: 'In a few days', selected: false },
          { value: 14, alias: 'In a couple of weeks', selected: false },
          { value: 30, alias: 'In a month', selected: false },
          { value: 90, alias: 'In a few months', selected: false },
          { value: 45, alias: 'Not sure', selected: false },
        ]

        trackDeclineLeadService.viewAvailabilitySurvey(this.lead)
      }

      this.setAvailability = function (selectedAvailability) {
        angular.forEach(
          this.availabilityOptions,
          function (availability) {
            availability.selected =
              selectedAvailability.value === availability.value
          }.bind(this)
        )

        contractorModel
          .selfSnooze({
            contractorId: this.lead.contractor.id,
            serviceRequestId: this.lead.project.id,
            snoozeType: 'lead_response',
            snoozeUntil: this.snoozeDate(selectedAvailability.value),
          })
          .then(
            function () {
              trackDeclineLeadService.submitAvailabilitySurvey(
                this.lead,
                selectedAvailability.value,
                function () {
                  this.onComplete()
                }.bind(this)
              )
            }.bind(this)
          )
      }

      this.snoozeDate = function (daysToSnooze) {
        return dateService.toISOString(
          dateService.addDays(new Date(), daysToSnooze)
        )
      }
    },
  ],
}

export default contractorAvailabilityScreen
