bz_app.directive('reviewPhotoAttachment', [
  'filepickerService',
  '$compile',
  'imageModel',
  function (filepickerService, $compile, imageModel) {
    return {
      restrict: 'AE',
      replace: false,
      link: function (scope, element, attr) {
        filepickerService.initForContractor(element.data('contractor-id'))

        scope.new_images = []
        scope.attachFile = function (e, contractor_id, review_id) {
          filepickerService.upload(function (InkBlobs) {
            if (InkBlobs && InkBlobs.length) {
              angular.forEach(InkBlobs, function (file) {
                var data = {
                  filename: file.filename,
                  filepicker_slug: file.url,
                  image_type: 'review',
                  review_id: review_id,
                  contractor_id: contractor_id,
                  s3_slug: file.key,
                  cloudfront_slug: filepickerService.getCloudFrontUrl(file.url),
                }
                imageModel.add_image(data).then(scope.uploadCallback)
              })
            }
          })
        }

        scope.setNewImagesInput = function () {
          var $input = angular.element(element.data('image-input-selector'))
          $input.val(scope.new_images.toString())
        }

        scope.deleteImageId = function (image_id) {
          var index
          for (index in scope.new_images) {
            if (scope.new_images[index] == image_id) {
              scope.new_images.splice(index, 1)
              break
            }
          }
          scope.setNewImagesInput()
        }

        scope.deletableImageTemplate = function (
          image_id,
          cloudfront_slug,
          thumbnail_size
        ) {
          var image = {
            id: image_id,
            cloudfront_slug: encodeURIComponent(cloudfront_slug),
          }
          return (
            '<div rotating-image image=' +
            JSON.stringify(image) +
            " size='" +
            thumbnail_size +
            "'></div><div ng-click='deleteImage($event," +
            image_id +
            ")' class='btn-delete-file not-bound'><i class='fas fa-trash-alt-o'></i></div></div>"
          )
        }

        scope.deleteImage = function ($event, image_id) {
          scope.delete_file($event, image_id)
        }

        scope.uploadCallback = function (response, status, xhr) {
          var $form_group = angular.element(element.data('form-group-selector'))
          //var review_id = element.data('review-id');
          scope.image_id = response.image
          var compiled_photo = $compile(
            scope.deletableImageTemplate(
              scope.image_id,
              response.cloudfront_slug,
              'square_100'
            )
          )(scope)
          scope.new_images.push(scope.image_id)
          scope.setNewImagesInput()
          $form_group.find('#files_uploaded').append(compiled_photo)
        }

        scope.delete_file = function ($event, image_id) {
          var data = {
            object: 'image',
            id: image_id,
            parent_type: 'review',
            parent_id: element.data('review-id'),
          }
          scope.deleteImageId(image_id)
          imageModel.delete_image({ id: image_id }, data).then(function () {
            $event.currentTarget.previousElementSibling.remove()
            $event.currentTarget.remove()
          })
        }
      },
    }
  },
])
