/* global angular */

class contractorClaimTooltipIndicatorController {
  constructor($scope, $timeout) {
    this.$scope = $scope
    this.$timeout = $timeout
    this.tooltipVisible = false
  }

  toggleTooltip() {
    this.tooltipVisible = !this.tooltipVisible

    this.$timeout(() => {
      this.$scope.$digest()
    })
  }
}

export const contractorClaimTooltipIndicator = {
  bindings: {
    tooltip: '<',
  },
  template: `<div ng-click="$ctrl.toggleTooltip()" class="setup-indicator-container"><div class="setup-indicator-icon"></div></div>
				<div ng-if="$ctrl.tooltipVisible" ng-click="$ctrl.toggleTooltip()" class="setup-tooltip-mobile-overlay"></div>
				<setup-tooltip ng-if="$ctrl.tooltipVisible" tooltip="$ctrl.tooltip" on-close="$ctrl.toggleTooltip()" is-closable="true"></setup-tooltip>`,
  controller: contractorClaimTooltipIndicatorController,
}

contractorClaimTooltipIndicatorController.$inject = ['$scope', '$timeout']
