/* global angular, $ */
angular.module('bzCommonUtilitiesModule').factory('locationModel', [
  'bzHttp',
  'urlConstants',
  function (bzHttp, urlConstants) {
    return {
      locationPath: urlConstants.api.v1.path + 'location/search_state',
      searchState: function (stateKeyword) {
        return bzHttp.get(this.locationPath, {
          params: { state_keyword: stateKeyword },
        })
      },
    }
  },
])
