/* global bz_app */
bz_app.directive('currencyWithFlag', [
  '$filter',
  '$locale',
  'stringService',
  function ($filter, $locale, stringService) {
    return {
      require: 'ngModel',
      scope: {
        isValidInput: '=',
        error: '=',
      },
      link: function (scope, element, attrs, ngModel) {
        var initialFormat = scope.$watch(
          function (scope) {
            return ngModel.$modelValue
          },
          function (newValue, oldValue) {
            if (!isNaN(newValue) && newValue && newValue.length) {
              scope.format(newValue.toString())
              initialFormat()
            }
          }
        )

        scope.format = function (value) {
          var res = stringService.toCurrency(value)
          ngModel.$setViewValue(res)
          ngModel.$render()
        }

        scope.validateInput = function (value) {
          if (value.charAt(0) === '0') {
            scope.$apply(function () {
              scope.isValidInput = false
              scope.error = 'Enter a valid dollar amount.'
            })
            return false
          }

          if (value.replace(/[^a-zA-Z]/gi, '').length > 0) {
            scope.$apply(function () {
              scope.isValidInput = false
              scope.error = 'Enter a valid dollar amount.'
            })

            return false
          } else {
            scope.error = ''
            scope.isValidInput = true
          }

          return true
        }

        element.bind('keyup', function (e) {
          var value = ''

          if (
            ngModel.$modelValue !== null &&
            ngModel.$modelValue !== undefined
          ) {
            value = ngModel.$modelValue.toString()
          }

          if (scope.validateInput(value)) {
            scope.format(value)
          }
        })
      },
    }
  },
])
