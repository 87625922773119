/* global angular, ProjectAPI, Cookie, $ */
angular.module('bzProjectFormModule').directive('myProjectsButton', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'AEC',
      scope: {
        userSignedIn: '=',
      },
      link: function (scope, element, attrs) {
        scope.init = function () {
          scope.isInitialized = false
          scope.projectApi = new ProjectAPI()

          angular.element('body').on('project_data_load', function (e, data) {
            scope.loadData(data)
          })

          scope.loadData()
        }

        scope.loadData = function (settings) {
          if (!scope.isInitialized) {
            if (scope.userSignedIn) {
              scope.projectApi.load_all('open').then(function (data) {
                scope.processLoadedData(data, settings)
              })
            } else {
              scope.triggerDataNotLoadedEvent()
            }
          }
        }

        scope.processLoadedData = function (data, settings) {
          $timeout(function () {
            scope.$apply(function () {
              if (data.projects.length > 0) {
                scope.setData(data.projects)
                scope.isInitialized = true

                if (
                  settings === undefined ||
                  settings.trigger_project_data_loaded === undefined ||
                  settings.trigger_project_data_loaded === true
                ) {
                  scope.triggerDataLoadedEvent()
                }
              } else {
                scope.triggerDataNotLoadedEvent()
              }
            })
          })
        }

        scope.setData = function (projects) {
          angular.element('body').prepend('<div id="project_data"></div>')

          var projectData = angular.element('#project_data')
          var firstProject = projects[0]
          var currentProject = null
          var currentProjectId = Cookie.get_current_project_id()

          // set current project as selected
          if (currentProjectId === undefined) {
            Cookie.set_current_project_id(firstProject.id)
            currentProject = firstProject
          } else {
            // find current project in list
            $.each(projects, function (index, project) {
              if (project.id === currentProjectId) {
                currentProject = project
              }
            })

            // did not find current project in list, use first project
            if (currentProject === null) {
              Cookie.set_current_project_id(firstProject.id)
              currentProject = firstProject
            }
          }

          projectData.attr('data-project_id', currentProject.id)
          projectData.attr(
            'data-project_type_slug',
            currentProject.projectType.slug
          )

          scope.initButtonClick(scope.serviceRequestCount(projects))
        }

        scope.serviceRequestCount = function (projects) {
          var serviceRequestCount = 0

          // add up number of projects with service requests
          $.each(projects, function (index, project) {
            if (
              project.components.ServiceRequest !== undefined &&
              project.components.ServiceRequest.length > 0
            ) {
              serviceRequestCount++
            }
          })

          return serviceRequestCount
        }

        scope.initButtonClick = function (service_request_count) {
          element.click(function (e) {
            // take user directly to SR if only 1, dashboard if 2+
            if (
              service_request_count === 1 &&
              Cookie.get_current_service_request_id()
            ) {
              document.location =
                '/project/' + Cookie.get_current_service_request_id()
            } else {
              document.location = '/manage/homeowner/service_requests'
            }
          })
        }

        scope.triggerDataLoadedEvent = function () {
          angular.element('body').trigger('project_data_loaded')
        }

        scope.triggerDataNotLoadedEvent = function () {
          angular.element('body').trigger('project_data_not_loaded') // event when project nav will not be loaded on page
        }

        scope.init()
      },
    }
  },
])
