angular
  .module('bzProjectModule')
  .factory('serviceRequestBuildingPermitsModel', [
    'urlConstants',
    '$http',
    'modelService',
    function (urlConstants, $http, modelService) {
      return {
        apiBuildingPermitsPath: urlConstants.api.v3.path + 'building_permits',

        update: function (serviceRequestId, permitId, params) {
          var data = modelService.toUnderscore({
            buildingPermit: params,
            serviceRequestId: serviceRequestId,
          })

          return $http
            .put(this.apiBuildingPermitsPath + '/' + permitId, data)
            .then(modelService.extractData.bind(this))
        },
      }
    },
  ])
