const contractorGalleryModel = [
  '$http',
  '$q',
  'urlConstants',
  'filestackService',
  'modelService',
  ($http, $q, urlConstants, filestackService, modelService) => {
    return {
      apiPath: `${urlConstants.api.v1.path}gallery`,
      createGallery(data) {
        return $http.post(this.apiPath, data).then(modelService.extractData)
      },
      updateGallery(galleryId, data) {
        return $http
          .put(`${this.apiPath}/${galleryId}`, data)
          .then(modelService.extractData)
      },
      reorderGallery(galleryId, data) {
        return $http
          .put(`${this.apiPath}/${galleryId}`, data)
          .then(modelService.extractData)
      },
      reorderGalleries(data) {
        return $http
          .put(`${urlConstants.api.v1.path}contractor/galleries/reorder`, data)
          .then(modelService.extractData)
      },
      addGalleryImages(files, galleryId) {
        let promises = $.map(files, (file) => {
          return filestackService.uploadImage(file).then((filestackData) => {
            return {
              url: filestackData.url,
              cdn_slug: filestackData.handle,
              s3_slug: filestackData.key,
            }
          })
        })
        return $q.all(promises).then((data) => {
          return this.persistGalleryImages({ images: data }, galleryId)
        })
      },
      persistGalleryImages(data, galleryId) {
        return $http
          .post(`${this.apiPath}/${galleryId}/images`, data)
          .then(modelService.extractData)
      },
      rotateGalleryImage(file, galleryId) {
        return filestackService.rotate(file, 90).then((filestackData) => {
          let data = {
            image: {
              cdn_slug: filestackData.handle,
              s3_slug: filestackData.key,
            },
          }
          return this.updateGalleryImage(galleryId, file.id, data)
        })
      },
      updateGalleryImage(galleryId, imageId, data) {
        return $http
          .put(`${this.apiPath}/${galleryId}/images/${imageId}`, data)
          .then(modelService.extractData)
      },
      deleteGalleryImage(galleryId, imageId) {
        return $http
          .delete(`${this.apiPath}/${galleryId}/images/${imageId}`)
          .then(modelService.extractData)
      },
      deleteGallery(galleryId) {
        return $http
          .delete(`${this.apiPath}/${galleryId}`)
          .then(modelService.extractData)
      },
      addLogo(options) {
        return this.setImageByType(options.files, 'logo')
      },
      addHeaderImage(options) {
        return this.setImageByType(options.files, 'background')
      },
      setImageByType(files, type) {
        let deferred = $q.defer()

        this.uploadFiles(files).then((image) => {
          this.deleteGalleryImageByType(type).then(
            () => {
              this.addGalleryImageByType(type, image).then(() => {
                deferred.resolve(image)
              })
            },
            (error) => {
              console.log(error)
            }
          )
        })

        return deferred.promise
      },
      uploadFiles(files) {
        let deferred = $q.defer()

        angular.forEach(files, (selectedFile) => {
          filestackService.uploadImage(selectedFile).then((file) => {
            deferred.resolve({
              url: file.url,
              slug: file.url.split('/').pop(),
              s3_slug: file.key,
            })
          })
        })

        return deferred.promise
      },
      deleteLogo() {
        return this.deleteGalleryImageByType('logo')
      },
      deleteHeaderImage() {
        return this.deleteGalleryImageByType('background')
      },
      addGalleryImageByType(type, data) {
        return $http.post(
          urlConstants.api.v1.path + 'contractor/' + type + '/add/picture',
          data
        )
      },
      deleteGalleryImageByType(type) {
        return $http.get(
          urlConstants.api.v1.path + 'gallery/delete/' + type + '/'
        )
      },
    }
  },
]

export default contractorGalleryModel
