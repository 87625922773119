/* bz_app	*/
bz_app.factory('projectStageModel', function () {
  return {
    getAll: function () {
      return {
        starting: 'Starting research',
        plans: 'Drawings & plans finalized',
        'in-progress': 'Work has started',
      }
    },
    getProjectStageDescription: function (key) {
      return this.getAll()[key]
    },
  }
})
