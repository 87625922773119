/* global angular */
angular.module('bzCommonUtilitiesModule').factory('objectService', [
  function () {
    return {
      objectsToIdsArray: function (objects, key) {
        var result = []

        if (key !== undefined) {
          objects.forEach(function (object) {
            var item = {}
            item[key] = object.id
            result.push(item)
          })
        } else {
          objects.forEach(function (object) {
            result.push(object.id)
          })
        }

        return result
      },
    }
  },
])
