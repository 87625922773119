/* global angular, isMobile */
angular
  .module('bzPotentialLeadFormModule')
  .component('contactContractorButton', {
    bindings: {
      contractor: '<',
    },
    controller: [
      '$element',
      'potentialLeadFormModalService',
      'urlService',
      function ($element, potentialLeadFormModalService, urlService) {
        this.$onInit = function () {
          $element
            .find('.contact-contractor-v2, .contact-button.message-button')
            .on('click', this.openPotentialLeadModal.bind(this))
        }

        this.openPotentialLeadModal = function () {
          if (isMobile.any()) {
            document.location = urlService.contactContractorUrl(
              this.contractor.alias
            )
          } else {
            potentialLeadFormModalService.openPotentialLeadModal(
              this.contractor
            )
          }
        }
      },
    ],
  })
