/* global angular, UserData */
angular.module('bzNavbarModule').controller('NavbarBulletinCtrl', [
  '$timeout',
  '$window',
  'userBulletinInstance',
  function ($timeout, $window, userBulletinInstance) {
    this.$onInit = function () {
      this.bulletin = new userBulletinInstance()

      if (UserData.user_signed_in() && !UserData.is_admin()) {
        if (document.readyState === 'complete') {
          this.loadAndSet()
        } else if (window.addEventListener) {
          window.addEventListener('load', this.loadAndSet.bind(this), false)
        } else if (window.attachEvent) {
          window.attachEvent('onload', this.loadAndSet.bind(this))
        }
      }
    }

    this.loadAndSet = function () {
      this.bulletin.loadAndSet().then(
        function (response) {
          // insert badge in dropdown next to INBOX
          var count = 0

          if (this.bulletin.unreadMessagesCount()) {
            if (this.bulletin.unreadMessagesCount() > 99) {
              count = '99+'
            } else {
              count = this.bulletin.unreadMessagesCount()
            }
          }

          if (count) {
            angular
              .element('.user-dashboard-dropdown-messages a')
              .prepend(
                '<span class="user-dashboard-dropdown-messages-badge badge badge-warning pull-right">' +
                  count +
                  '</span>'
              )
          }
        }.bind(this)
      )
    }
  },
])
