/* global bz_app, angular, isMobile */
import template from './bz_score_modal.html'

const showScoreExplanationModal = [
  'modalScreenService',
  function (modalScreenService) {
    return {
      restrict: 'AE',
      scope: {
        contractor: '<',
        omitQuoteCta: '<',
      },
      link: function (scope, element) {
        element.click(function ($event) {
          $event.stopPropagation()
          var scoreModal = modalScreenService.initDetached({
            controller: 'BZScoreModalCtrl',
            template,
            windowClass: 'v3-modal-simple',
            inputData: {
              contractor: scope.contractor,
              omitQuoteCta: scope.omitQuoteCta,
            },
          })

          $('body').addClass('prevent-scroll')

          scoreModal.closed.then(function () {
            $('body').removeClass('prevent-scroll')
          })
        })
      },
    }
  },
]

export default showScoreExplanationModal
