/* global angular */

import { updateLinkToCity } from './directives/update_link_to_city_directive'
import compileElementDirective from './directives/compile_element_directive'
import MultiSelectDropdownComponent from './components/multi_select_dropdown_component'
import SelectDropdownComponent from './components/select_dropdown_component'
import dateService from './services/date_service'
import positionService from './services/position_service'

const BzCommonUtilitiesModule = angular
  .module('bzCommonUtilitiesModule', ['ui.bootstrap', 'bzLocationModule'])
  .directive('updateLinkToCity', updateLinkToCity)
  .directive('compileElement', compileElementDirective)
  .component('multiSelectDropdown', MultiSelectDropdownComponent)
  .component('selectDropdown', SelectDropdownComponent)
  .factory('dateService', dateService)
  .factory('positionService', positionService).name

export default BzCommonUtilitiesModule
