/* global angular */
angular.module('bzLocationModule').factory('userLocationData', [
  '$rootScope',
  '$timeout',
  function ($rootScope, $timeout) {
    return {
      city: '',
      zipcode: '',
      state: '',
      lat: '',
      lng: '',
      pending: false,
      init: false,

      set: function (data) {
        if (data) {
          $timeout(
            function () {
              $rootScope.$apply(
                function () {
                  if (data.city) {
                    this.city = data.city
                  }

                  if (data.zipcode) {
                    this.zipcode = data.zipcode
                  } else if (data.zip) {
                    this.zipcode = data.zip
                  }

                  if (data.state) {
                    this.state = data.state
                  }
                  if (data.lat) {
                    this.lat = data.lat
                  }
                  if (data.lng) {
                    this.lng = data.lng
                  }

                  this.init = true
                }.bind(this)
              )
            }.bind(this)
          )
        }
      },
      setPending: function (pending) {
        this.pending = pending
      },
      cityState: function () {
        return this.city && this.state ? this.city + ', ' + this.state : null
      },
    }
  },
])
