/* global angular */
angular.module('bzCommonUtilitiesModule').directive('deferImageLoad', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        imageSrc: '@',
      },
      controller: [
        '$element',
        function ($element) {
          this.$onInit = function () {
            if (document.readyState === 'complete') {
              this.setImageSrc()
            } else if (window.addEventListener) {
              window.addEventListener(
                'load',
                this.setImageSrc.bind(this),
                false
              )
            } else if (window.attachEvent) {
              window.attachEvent('onload', this.setImageSrc.bind(this))
            }
          }

          this.setImageSrc = function () {
            $element.attr('src', this.imageSrc)
          }
        },
      ],
    }
  },
])
