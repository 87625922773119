/* globals Track, UserData, isMobile */
/**	uses generic Track class and adds Search-specific tracking	*/

var SearchTrack = function () {}

SearchTrack.submit = function (data, callback) {
  Track.send_callback(
    'Search Action',
    {
      action: 'submit',
      keywords: data.keywords,
      location: data.location,
      form_type: data.form_type,
      search_type: 'contractors',
      user_role: UserData.data('user_role') || 'anonymous',
      platform: isMobile.check() ? 'mobile' : 'desktop',
      referral_source:
        (data && data.analytics && data.analytics.referralSource) || null,
    },
    callback
  )
}

SearchTrack.propertySubmit = function (address, form_type, callback) {
  Track.send_callback(
    'Search Action',
    { address: address, form_type: form_type, search_type: 'property' },
    callback
  )
}

window.SearchTrack = SearchTrack
