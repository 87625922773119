/* global angular */
angular.module('bzProjectFormModule').controller('ProjectFormCtrl', [
  '$scope',
  'projectFormModel',
  'validationService',
  function ($scope, projectFormModel, validationService) {
    $scope.formData = projectFormModel.data
    $scope.contactFormLabel = ''

    $scope.init = function (data) {
      if (data) {
        $scope.titleTypeAheadShowOnFocus = data.titleTypeAheadShowOnFocus
          ? data.titleTypeAheadShowOnFocus
          : 'on'
        $scope.validateInput = data.validateInput ? data.validateInput : false

        if ($scope.validateInput) {
          $scope.initFormValidation(data.showFormErrors)
        }
        if (data.user) {
          projectFormModel.data.set(data.user)
        }
        if (data.title) {
          projectFormModel.data.set({ title: data.title })
        }
      }
    }

    $scope.initFormValidation = function (showFormErrors) {
      $scope.showFormErrors = showFormErrors ? showFormErrors : false

      angular
        .element('form.new_service_request .project-form-submit')
        .click($scope.formSubmitClick)

      // listen for post-load events to init validation
      $scope.$on('initProjectFormValidation', function (e, data) {
        if (data && data.selector) {
          angular.element(data.selector).off('click')
          angular.element(data.selector).click($scope.formSubmitClick)
        }
      })
    }

    $scope.formSubmitClick = function ($event) {
      if (!validationService.isFormValid()) {
        $scope.$apply(function () {
          $scope.showFormErrors = true
        })

        $event.preventDefault()
      }
    }
  },
])
