import { omit } from 'lodash'
/* global bz_app, angular */
bz_app.factory('bidInstance', [
  'attributeConversionService',
  'v2BidModel',
  'bidAttachmentModel',
  '$timeout',
  function (
    attributeConversionService,
    v2BidModel,
    bidAttachmentModel,
    $timeout
  ) {
    return function () {
      this.attachments = []
      this.track = {}
      this.init = false
      this.isAttaching = false

      this.set = function (data) {
        if (data) {
          this.setAttributes(
            attributeConversionService.underscoreToCamelDeepAttributes(data)
          )

          if (this.startDate && typeof this.startDate !== 'object') {
            this.startDate = new Date(this.startDate)
          }

          if (this.endDate && typeof this.endDate !== 'object') {
            this.endDate = new Date(this.endDate)
          }

          if (this.price && typeof this.price === 'string') {
            let floatPrice = parseFloat(this.price)
            if (!isNaN(floatPrice)) {
              this.price = floatPrice
            } else {
              throw new Error(
                'Bid (id: ' +
                  this.id +
                  ') does not have a valid float price: ' +
                  this.price
              )
            }
          }

          this.init = true
          return true
        } else {
          return false
        }
      }

      this.initAttachments = function (selectorId) {
        angular.element('#' + selectorId).on('change', ($event) => {
          this.attachmentSelected($event.target.files)
        })
      }

      this.setAttributes = function (data) {
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
            this[property] = data[property]
          }
        }
      }

      this.setTracking = function (options) {
        if (options) {
          if (options.attachFilesClicked) {
            this.track.attachFilesClicked = options.attachFilesClicked
          }

          if (options.filesAdded) {
            this.track.filesAdded = options.filesAdded
          }
        }
      }

      this.hasAttachments = function () {
        return this.attachments && this.attachments.length > 0
      }

      this.removeAttachment = function (index) {
        this.attachments.splice(index, 1)
      }

      this.objectify = function () {
        return {
          id: this.id,
          contractorId: this.contractorId,
          serviceRequestId: this.serviceRequestId,
          bidUploaderType: this.bidUploaderType,
          bidUploaderId: this.bidUploaderId,
          price: v2BidModel.cleanPrice(this.price),
          startDate: this.startDate,
          endDate: this.endDate,
          description: this.description,
          attachments: this.attachments,
          createdAt: this.createdAt,
          bidType: this.bidType,
          includesFinishAllowance: this.includesFinishAllowance,
        }
      }

      this.create = function () {
        return v2BidModel
          .create(omit(this, ['id', 'createdAt', 'updatedAt']))
          .then((response) => {
            if (response && response.success) {
              this.id = response.bid.id
              this.createdAt = response.bid.created_at
              this.updatedAt = response.bid.updated_at
            }
            return response
          })
      }

      this.save = function () {
        if (this.id) {
          return v2BidModel.update(this.id, this)
        } else {
          return v2BidModel.create(this).then(
            function (response) {
              if (response && response.success) {
                this.id = response.bid.id
                this.createdAt = response.bid.created_at
              }

              return response
            }.bind(this)
          )
        }
      }

      this.destroy = function (reason) {
        return v2BidModel.destroy(this.id, reason).then((res) => {
          if (res.success) {
            this.bidType = 'inactive'
          }
          return res
        })
      }

      this.attachFiles = function ($event, callback) {
        if (typeof this.track.attachFilesClicked === 'function') {
          this.track.attachFilesClicked()
        }

        if ($event) {
          $event.preventDefault()
        }

        return bidAttachmentModel.attach(this, () => {
          if (typeof this.track.filesAdded === 'function') {
            this.track.filesAdded()
          }

          if (typeof callback === 'function') {
            callback()
          }
        })
      }

      this.attachmentSelected = function (files) {
        this.isAttaching = true

        return bidAttachmentModel
          .attach(this, null, { files: files, version: 2 })
          .then((uploadedFiles) => {
            if (uploadedFiles.length) {
              if (typeof this.track.filesAdded === 'function') {
                this.track.filesAdded()
              }
            }

            this.isAttaching = false
          })
      }
    }
  },
])
