import template from '../controllers/decline_lead_modal.html'

const declineLeadModalService = [
  'modalScreenService',
  function (modalScreenService) {
    return {
      openModal: function (lead) {
        return modalScreenService.initDetached({
          controller: 'DeclineLeadModalCtrl',
          template,
          windowClass: 'v3-modal v3-modal-simple small decline-lead-modal',
          inputData: {
            lead: lead,
          },
        })
      },
    }
  },
]

export default declineLeadModalService
