/* global angular */
angular.module('bzValidationModule').factory('validationModel', [
  '$http',
  '$q',
  'urlConstants',
  'attributeConversionService',
  'modelService',
  function ($http, $q, urlConstants, attributeConversionService, modelService) {
    // From http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
    function escapeRegExp(str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
    }

    return {
      apiPath: urlConstants.api.v1.path,
      verifyZipcode: function (zipcode) {
        var url =
          this.apiPath +
          '/location/validate_zipcode?zipcode=' +
          encodeURIComponent(zipcode)
        return $http.get(url).then(modelService.extractData.bind(this))
      },

      validationStates: {
        LOADING: 'LOADING',
        ERROR: 'ERROR',
        WARNING: 'WARNING',
        SUCCESS: 'SUCCESS',
      },

      verifyUrlResponses: {
        NOT_FOUND: 'NOT_FOUND',
        EXISTS: 'EXISTS',
        TIMEOUT: 'TIMEOUT',
        INVALID_URL: 'INVALID_URL',
      },

      asyncIsValidUrl: function (url, options) {
        var validationModelThis = this
        return $q(function (resolve, reject) {
          var domainRegex
          if (options.domain) {
            domainRegex = new RegExp(
              'https?://' + escapeRegExp(options.domain),
              'g'
            )
          }

          if (domainRegex && !domainRegex.test(url)) {
            resolve(validationModelThis.verifyUrlResponses.NOT_FOUND)
          } else {
            $http({
              method: 'get',
              url: '/api/v3/verify_url?url=' + url,
              cache: true,
            }).then(
              function (resp) {
                resolve(resp.data.status)
              },
              function () {
                // reject errors to connect are invalid urls
                resolve(validationModelThis.verifyUrlResponses.NOT_FOUND)
              }
            )
          }
        })
      },
    }
  },
])
