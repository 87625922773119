/**generic API parent */

window.BZAPI = function () {
  this.api = {
    v1_path: bzGlobal.urlConstants.api.v1.path,
    v2_path: bzGlobal.urlConstants.api.v2.path,
  }

  this.building_permit_api_path = function () {
    return this.api.v1_path + 'building_permits'
  }

  this.properties_api_path = function () {
    return this.api.v1_path + 'properties'
  }

  this.images_api_path = function (v) {
    if (typeof v == 'undefined' || v == 2) return this.api.v2_path + 'images'
    else if (v == 1) return this.api.v1_path + 'images'
  }

  this.project_api_path = function () {
    return this.api.v1_path + 'projects'
  }

  this.project_label_api_path = function () {
    return this.api.v1_path + 'projects_labels'
  }

  this.invoice_api_path = function () {
    return this.api.v1_path + 'invoices'
  }

  this.contractor_api_path = function () {
    return this.api.v1_path + 'contractor'
  }

  this.contractor_profile_api_path = function () {
    return this.api.v1_path + 'contractor_profile'
  }

  this.contractor_intro_message_path = function () {
    return this.api.v1_path + 'contractor_intro_message'
  }

  this.contractor_employee_api_path = function () {
    return this.api.v1_path + 'contractor_employee'
  }

  this.contractors_api_path = function () {
    return this.api.v1_path + 'contractors'
  }

  this.property_api_path = function () {
    return this.api.v1_path + 'property'
  }

  this.services_api_path = function () {
    return this.api.v1_path + 'services'
  }

  this.service_request_api_path = function () {
    return this.api.v1_path + 'service_requests'
  }

  this.service_request_admin_api_path = function () {
    return this.api.v2_path + 'manage/admin/service_requests'
  }

  this.contractor_admin_v2_api_path = function () {
    return this.api.v2_path + 'manage/admin/contractor'
  }

  this.admin_communication_templates_path = function () {
    return this.api.v2_path + 'manage/admin/communication_templates'
  }

  this.admin_profile_notes_path = function () {
    return this.api.v2_path + 'manage/admin/comments'
  }

  this.search_contractors_api_path = function () {
    return this.api.v1_path + 'search_contractors'
  }

  this.search_contractors_matches_api_path = function () {
    return this.api.v1_path + 'search_contractors_matches'
  }

  this.review_responses_api_path = function () {
    return this.api.v1_path + 'review_responses'
  }

  this.sms_conversation_api_path = function () {
    return this.api.v1_path + 'conversation'
  }

  this.admin_sms_conversation_api_path = function () {
    return this.api.v2_path + 'manage/admin/conversations'
  }

  this.admin_phone_numbers_api_path = function () {
    return this.api.v2_path + 'manage/admin/phone_numbers'
  }

  this.admin_users_path = function () {
    return this.api.v2_path + 'manage/admin/users'
  }

  this.conversations_api_path = function () {
    return this.api.v1_path + 'conversations'
  }

  this.messages_api_path = function () {
    return this.api.v1_path + 'messages'
  }

  this.user_api_path = function () {
    return this.api.v1_path + 'user'
  }

  this.customer_phones_api_path = function () {
    return this.api.v1_path + 'customer_phones'
  }

  this.location_api_path = function () {
    return this.api.v1_path + 'location'
  }

  this.visitor_tracker_api_path = function () {
    return this.api.v1_path + 'latest_visits'
  }

  this.review_comment_api_path = function (review_id) {
    return this.api.v1_path + 'reviews/' + review_id + '/comments'
  }

  this.state_api_path = function () {
    return this.api.v1_path + 'state'
  }

  this.referral_terms_api_path = function () {
    return this.api.v1_path + 'referral_terms'
  }

  this.admin_license_path = function (id) {
    return this.api.v1_path + 'admin/licenses/'
  }

  this.credit_card_api_path = function () {
    return this.api.v1_path + 'credit_cards'
  }

  this.bank_account_api_path = function () {
    return this.api.v1_path + 'bank_accounts'
  }

  this.admin_path = function () {
    return this.api.v1_path + 'admin/'
  }

  this.admin_license_path = function () {
    return this.admin_path() + 'licenses/'
  }

  this.contractors_ranking_adjustments_api_path = function () {
    return this.admin_path() + 'contractors_rankings_adjustments'
  }
}

// AJAX interceptors

$(document).ajaxSuccess((_event, request, settings) => {
  // Test if API request is failing because of a required cloudflare
  // challenge. Redirect them to it rather then rendering error response.
  if (
    settings.url.includes('/api/') &&
    request.responseText.includes('bz-cf-challenge')
  ) {
    const currentUrl = window.location.pathname + window.location.search
    window.location.href = `/cf_challenge?originalUrl=${currentUrl}`
  }
})
