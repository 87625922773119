/* global  angular*/
angular
  .module('bzProjectPreferencesModule')
  .component('projectPreferencesForm', {
    bindings: {
      contractor: '<',
      contractorTypeOptions: '<',
      projectTypeGroups: '<',
      onSubmit: '&',
    },
    templateUrl: [
      'urlService',
      function (urlService) {
        return urlService.templateUrl(
          '_components/components/project_preferences/project_preferences_form'
        )
      },
    ],
    controller: [
      'contractorProjectPreferencesService',
      'projectPreferencesModel',
      'attributeConversionService',
      'contractorModel',
      function (
        contractorProjectPreferencesService,
        projectPreferencesModel,
        attributeConversionService,
        contractorModel
      ) {
        this.$onInit = function () {
          this.setProjectTypes()
          this.errorsOn = false
        }

        this.updateContractorTypeId = function () {
          this.setProjectTypesFromApi()
          this.errorsOn = false
        }

        this.submit = function () {
          if (this.isValidInput()) {
            contractorModel
              .updateClaim(
                attributeConversionService.camelToUnderscoreDeepAttributes(
                  this.contractor
                )
              )
              .then(
                function () {
                  if (typeof this.onSubmit === 'function') {
                    this.onSubmit()
                  }
                }.bind(this)
              )
          } else {
            this.errorsOn = true
          }
        }

        this.isValidInput = function () {
          return (
            this.contractor.contractorTypeId &&
            this.contractor.projectTypesOffered &&
            this.contractor.projectTypesOffered.length
          )
        }

        this.isValidProjectTypes = function () {
          return (
            this.contractor.projectTypesOffered &&
            this.contractor.projectTypesOffered.length
          )
        }

        this.hasContractorTypeError = function () {
          return this.errorsOn && !this.contractor.contractorTypeId
        }

        this.hasProjectTypesError = function () {
          return this.errorsOn && !this.isValidProjectTypes()
        }

        this.setProjectTypesFromApi = function () {
          return projectPreferencesModel
            .getProjectTypesByContractorTypeId(this.contractor.contractorTypeId)
            .then(
              function (projectTypeGroups) {
                this.projectTypeGroups =
                  attributeConversionService.underscoreToCamelDeepAttributes(
                    projectTypeGroups
                  )
                this.setProjectTypes()
              }.bind(this)
            )
        }

        this.setProjectTypes = function () {
          this.contractor.projectTypesOffered = []
          this.projectTypesDisplayed =
            contractorProjectPreferencesService.setProjectTypesDisplayed(
              this.projectTypeGroups,
              this.contractor.contractorTypeId
            ) || []

          // select all by default, then get list of project type ids and save
          // angular.forEach(this.projectTypesDisplayed, function (projectType) {
          // 	projectType.selected = true;
          // });

          this.projectTypeCheckUpdate(
            contractorProjectPreferencesService.displayGroups(
              this.contractor.contractorTypeId
            )
              ? 'ProjectTypeGroup'
              : ''
          )
        }

        this.projectTypeCheckUpdate = function (className) {
          this.contractor.projectTypesOffered =
            className === 'ProjectTypeGroup'
              ? contractorProjectPreferencesService.getSelectedGroupProjectTypeIds(
                  this.projectTypesDisplayed
                )
              : contractorProjectPreferencesService.getSelectedProjectTypeIds(
                  this.projectTypesDisplayed
                )
        }

        this.toggleProjectTypeSelect = function (projectType) {
          projectType.selected = !projectType.selected
          this.projectTypeCheckUpdate(projectType.className)
        }
      },
    ],
  })
