/* global bz_app, angular, UserData */
bz_app.component('conversationReply', {
  bindings: {
    conversation: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('messages/conversation/reply_form')
    },
  ],
  controller: [
    '$scope',
    '$rootScope',
    'userInfoService',
    'trackMessagingService',
    'dashboardUIService',
    '$timeout',
    'messageAttachmentModel',
    'connectionsMessageModel',
    function (
      $scope,
      $rootScope,
      userInfoService,
      trackMessagingService,
      dashboardUIService,
      $timeout,
      messageAttachmentModel,
      connectionsMessageModel
    ) {
      this.$onInit = function () {
        this.message = ''
        this.attachments = []
        this.user = userInfoService

        $scope.$on('elastic:resize', function () {
          dashboardUIService.scrollConversationToBottom()
        })

        angular.element('#message-attachment-selector').on(
          'change',
          function ($event) {
            this.attachmentSelected($event.target.files)
          }.bind(this)
        )
      }

      this.sendMessage = function () {
        if (this.message.length || this.attachments.length) {
          this.setMessageSentIndicator('Sending...')
          this.showMessageSentIndicator()

          if (!this.message.length && this.attachments.length) {
            this.message = 'New files added.'
          }

          this.sendMessageAction(
            this.conversation.connection_id,
            angular.copy(this.message),
            this.attachments
          ).then(
            function () {
              $rootScope.$broadcast('conversation-reload')
              this.message = ''
              this.attachments = []

              trackMessagingService.messageSent(this.conversation.id)
              this.hideMessageSentIndicator()

              dashboardUIService.scrollConversationToBottom()
            }.bind(this),
            this.sendMessageFailure.bind(this)
          )
        }
      }

      this.sendMessageAction = function (connectionId, message, attachments) {
        if (UserData.is_contractor()) {
          return connectionsMessageModel.sendMessageFromContractor(
            connectionId,
            message,
            attachments
          )
        } else {
          return connectionsMessageModel.sendMessageFromUser(
            connectionId,
            message,
            attachments
          )
        }
      }

      this.sendMessageFailure = function (error) {
        if (error.status === 401) {
          this.setMessageSentIndicator('You may no longer message this user')
        } else {
          this.setMessageSentIndicator(
            'System Error. Please contact our support team.'
          )
        }

        $timeout(
          function () {
            this.hideMessageSentIndicator()
            this.setMessageSentIndicator('')
          }.bind(this),
          4000
        )
      }

      this.attachFiles = function () {
        //todo: add some analytics tracking
      }

      this.attachmentSelected = function (files) {
        this.isAttaching = true
        $timeout(function () {
          $scope.$digest()
        })

        messageAttachmentModel.attach(null, { files: files, version: 3 }).then(
          function (uploadedFiles) {
            if (uploadedFiles.length) {
              this.attachments = this.attachments.concat(uploadedFiles)
            }

            this.isAttaching = false
          }.bind(this)
        )
      }

      this.removeAttachment = function ($index) {
        if (this.attachments && this.attachments.length) {
          this.attachments.splice($index, 1)
        }
      }

      this.isConversationArchived = function () {
        return this.conversation.status === 'archived'
      }

      this.setMessageSentIndicator = function (text) {
        angular
          .element('.message-sent-indicator')
          .find('.message-sent-indicator-text-status')
          .text(text)
      }

      this.showMessageSentIndicator = function () {
        angular.element('.message-sent-indicator').show()
      }

      this.hideMessageSentIndicator = function () {
        angular.element('.message-sent-indicator').fadeOut()
      }
    },
  ],
})
