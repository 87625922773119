/* global angular, SearchTrack */
angular.module('bzSearchModule').controller('SearchPropertiesCtrl', [
  '$scope',
  'locationService',
  function ($scope, locationService) {
    $scope.init = function (input) {
      $scope.locationInput = ''
      $scope.getLocation = locationService.find_address
      $scope.formType = ''

      if (input) {
        if (input.formType) {
          $scope.formType = input.formType
        }
      }
    }

    $scope.locationSelected = function ($item) {
      if ($item) {
        locationService.find_details($item).then(function (location) {
          $scope.location = location
        })
      }
    }

    $scope.submitPropertySearch = function () {
      if ($scope.location && $scope.locationInput) {
        SearchTrack.propertySubmit(
          $scope.locationInput,
          $scope.formType,
          function () {
            document.location = locationService.locationMapUrl($scope.location)
          }
        )
      } else if (!$scope.location && $scope.locationInput) {
        document.location = '/map'
      }
    }
  },
])
