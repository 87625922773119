import template from './contractor_project_preferences_screen.html'

const contractorProjectPreferencesScreen = {
  bindings: {
    lead: '<',
    taxonomy: '<',
    onUpdate: '&',
  },
  template,
  controller: [
    'projectPreferencesModel',
    '$timeout',
    'contractorModel',
    'trackDeclineLeadService',
    'declineLeadService',
    function (
      projectPreferencesModel,
      $timeout,
      contractorModel,
      trackDeclineLeadService,
      declineLeadService
    ) {
      this.$onInit = function () {
        this.contractor = this.lead.contractor
        this.projectTypesChanged = false
        trackDeclineLeadService.projectPreferencesScreenOpened(this.lead)
      }

      this.updateProjectTypesOffered = function (projectTypesOffered) {
        this.projectTypesChanged = true
        this.contractor.projectTypesOffered = projectTypesOffered
      }

      this.updateprojectTypes = function () {
        trackDeclineLeadService.saveProjectTypes(
          this.lead,
          this.projectTypesChanged
        )

        this.submitting = true

        contractorModel
          .updateProfile(this.contractor.id, {
            project_types_offered: this.contractor.projectTypesOffered,
            project_preferences_update_location: 'decline_lead_flow',
          })
          .then(
            function () {
              this.submitting = false
              this.onUpdate()
            }.bind(this)
          )
      }

      this.skip = function () {
        trackDeclineLeadService.projectPreferencesScreenSkipped(this.lead)
        this.onUpdate()
      }

      this.saveButtonText = function () {
        return declineLeadService.saveButtonText(this.submitting)
      }
    },
  ],
}

export default contractorProjectPreferencesScreen
