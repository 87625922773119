import { compact, forEach } from 'lodash'
import template from './dashboard_communication_preferences.html'

const dashboardCommunicationPreferencesComponent = {
  bindings: {},
  template,
  controller: [
    '$filter',
    'unsubscribeFromProjectsModalService',
    'communicationPreferenceModel',
    'customerPhoneModel',
    'urlConstants',
    function (
      $filter,
      unsubscribeFromProjectsModalService,
      communicationPreferenceModel,
      customerPhoneModel,
      urlConstants
    ) {
      this.$onInit = function () {
        this.isContractor = UserData.is_contractor()

        this.preferenceOrder = [
          'newProjects',
          'projectUpdates',
          'generalAndPromotional',
          'accountActivity',
          'acquisition',
        ]

        this.preferenceContent = {
          new_projects: {
            name: 'New Projects',
          },
          account_activity: {
            name: 'Account Activity',
            description: this.isContractor
              ? 'Payment notices, security alerts, and system messages.'
              : 'Notifications about security alerts, system messages, etc. You must receive these by email',
            getDisabledChoices(isEmailSubscribed, isSmsSubscribed) {
              if (isEmailSubscribed) {
                return ['email']
              }
            },
          },
          project_updates: {
            name: 'Project Updates',
            description: this.isContractor
              ? 'Important notifications regarding your active leads'
              : 'Important notifications regarding your active projects',
          },
          general_and_promotional: {
            name: 'General and Promotional',
            description:
              'Notifications about product updates, reviews, endorsements, etc.',
          },
          acquisition: {
            name: 'Acquisition',
          },
        }

        this.editLink = this.isContractor
          ? urlConstants.contractor.contactInfoURL
          : urlConstants.homeowner.poContactInfoURL

        this.loadCommunicationPreferences().then((responseData) => {
          this.data = responseData
          this.orderedCommunicationPreferences = compact(
            this.preferenceOrder.map((preferenceKey) => {
              return this.data.communicationPreferences[preferenceKey]
            })
          )

          // Fallback if no prefered numbers
          this.smsPreferredPhone = this.data.phoneNumbers[0]
          this.callPreferredPhone = this.data.phoneNumbers[0]

          forEach(this.data.phoneNumbers, (value, key) => {
            value.formattedNumber = $filter('phone')(value.number)

            if (value.smsPreferred === 1) {
              this.smsPreferredPhone = value.number
            }

            if (value.callPreferred === 1) {
              this.callPreferredPhone = value.number
            }
          })

          if (this.isUnsubscribeRequest()) {
            this.unsubscribeFromLink()
          }
        })
      }

      this.unsubscribeFromLink = function () {
        const communicationType = getURLParameter('communication_type')
        const subscriptionType = getURLParameter('subscription_type')
        const subscriptionKey =
          this.getSubscriptionKeyFromType(subscriptionType)

        this.updatePreference({
          preferenceKey: subscriptionKey,
          isSubscribed: false,
          medium: communicationType,
        })
      }

      this.getSubscriptionKeyFromType = (subscriptionType) => {
        return subscriptionType
          .replace('_subscribed_email', '')
          .replace('_subscribed_email', '')
      }

      this.isUnsubscribeRequest = function () {
        return (
          getURLParameter('unsubscribe') &&
          parseInt(getURLParameter('unsubscribe')) === 1
        )
      }

      this.loadCommunicationPreferences = function () {
        var communicableType = 'User'
        var communicableId = UserData.user_id()

        if (UserData.contractor_id() > 0) {
          communicableType = 'Contractor'
          communicableId = UserData.contractor_id()
        }

        return communicationPreferenceModel
          .show(communicableId, {
            communicable_type: communicableType,
            minimal: true,
          })
          .then(({ data }) => data)
      }

      this.setPreferenceSubscription = (
        preferenceKey,
        medium,
        isSubscribed
      ) => {
        const preference = this.orderedCommunicationPreferences.find(
          (commPref) => commPref.preferenceKey === preferenceKey
        )

        preference[medium] = isSubscribed
      }

      this.updatePreference = ({ preferenceKey, medium, isSubscribed }) => {
        this.setPreferenceSubscription(preferenceKey, medium, isSubscribed)

        if (preferenceKey == 'new_projects' && !isSubscribed) {
          unsubscribeFromProjectsModalService.show({
            medium,
            onClose: (closeModal) => {
              this.setPreferenceSubscription(preferenceKey, medium, true)
              closeModal()
            },
            onUnsubscribe: (closeModal) => {
              communicationPreferenceModel
                .updateIndividualPreference(preferenceKey, medium, isSubscribed)
                .then(() => {
                  closeModal()
                })
            },
          })
        } else {
          communicationPreferenceModel.updateIndividualPreference(
            preferenceKey,
            medium,
            isSubscribed
          )
        }
      }

      this.setPreferredSmsPhone = () => {
        customerPhoneModel.setPreferredPhone(
          this.data.communicableType,
          this.data.communicableId,
          this.smsPreferredPhone,
          'sms'
        )
      }

      this.setPreferredCallPhone = () => {
        customerPhoneModel.setPreferredPhone(
          this.data.communicableType,
          this.data.communicableId,
          this.callPreferredPhone,
          'call'
        )
      }
    },
  ],
}

export default dashboardCommunicationPreferencesComponent
