/* globals bugsnagClient */

const RAGE_MIN_CLICK_COUNT = 5
const RAGE_CLICK_SESSION_DURATION = 2000

const track = (title, params) => {
  bugsnagClient.notify(new Error(title), {
    severity: 'error',
    metaData: {
      params,
    },
  })
}

export default class RageTracker {
  constructor(options) {
    this.title = options.title || 'Rage Click'
    this.counter = 0
    this.lastClickTimestamp = null
  }

  click(params) {
    const now = Date.now()

    if (
      this.lastClickTimestamp &&
      now - this.lastClickTimestamp > RAGE_CLICK_SESSION_DURATION
    ) {
      this.counter = 0
    }

    this.counter++

    if (this.counter >= RAGE_MIN_CLICK_COUNT) {
      track(this.title, params)
      this.counter = 0
    }

    this.lastClickTimestamp = now
  }
}
