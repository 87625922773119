/* global angular*/
angular.module('bzProjectModule').factory('budgetTypesModel', function () {
  return {
    getAll: function (blankValue, blankLabel) {
      var budgetTypes = this.budgetTypes(blankLabel)

      if (blankValue === undefined) {
        blankValue = ''
      }

      return [
        { value: blankValue, text: budgetTypes[''] },
        { value: '0-1000', text: budgetTypes['0-1000'] },
        { value: '1000-5000', text: budgetTypes['1000-5000'] },
        { value: '5000-20000', text: budgetTypes['5000-20000'] },
        { value: '20000-50000', text: budgetTypes['20000-50000'] },
        { value: '50000-100000', text: budgetTypes['50000-100000'] },
        { value: '100000-250000', text: budgetTypes['100000-250000'] },
        { value: '250000-500000', text: budgetTypes['250000-500000'] },
        { value: '500000-1000000', text: budgetTypes['500000-1000000'] },
        { value: '1000000-9999999', text: budgetTypes['1000000-9999999'] },
        { value: 'private', text: budgetTypes['private'] },
      ]
    },
    budgetTypes: function (blankLabel) {
      if (blankLabel === undefined) {
        blankLabel = 'Click to Select'
      }

      return {
        '': blankLabel,
        private: 'Need guidance',
        '0-1000': 'Less than $1,000',
        '1000-5000': '$1,000 - $5,000',
        '5000-20000': '$5,000 - $20,000',
        '20000-50000': '$20,000 - $50,000',
        '50000-100000': '$50,000 - $100,000',
        '100000-250000': '$100,000 - $250,000',
        '250000-500000': '$250,000 - $500,000',
        '500000-1000000': '$500,000 - $1 million',
        '1000000-9999999': 'More than $1 million',
      }
    },

    material_types: function () {
      return {
        low: 'Economy',
        mid: 'Mid-range',
        high: 'High-end',
      }
    },
  }
})
