/**	global $, BZAPI	*/
var PropertyAPI = function () {
  this.follow = function (
    property_id,
    email,
    type,
    successCallback,
    failCallback
  ) {
    return $.when(
      $.post(this.property_api_path() + '/follow', {
        property_id: property_id,
        email: email,
        type: type,
      }).then(successCallback, failCallback)
    )
  }

  this.cancelFollow = function (
    property_id,
    email,
    type,
    zipcode,
    successCallback,
    failCallback
  ) {
    return $.when(
      $.post(this.property_api_path() + '/cancel_follow', {
        property_id: property_id,
        email: email,
        type: type,
        zipcode: zipcode,
      }).then(successCallback, failCallback)
    )
  }

  this.show_follow_modal = function (callback, property_id, type) {
    $('#follow_modal').remove()

    return $.when($.get(this.property_api_path() + '/follow')).then(function (
      res
    ) {
      if (callback !== undefined) callback(res, property_id, type)
    })
  }

  this.claim = function (property_id, user_id) {
    return $.when(
      $.ajax({
        url: this.property_api_path() + '/claim',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          property_id: property_id,
          user_id: user_id,
        }),
        dataType: 'json',
      })
    )
  }

  this.cancelClaim = function (
    property_id,
    email,
    successCallback,
    failCallback
  ) {
    return $.when(
      $.post(this.property_api_path() + '/cancel_claim', {
        property_id: property_id,
        email: email,
      }).then(successCallback, failCallback)
    )
  }
}

PropertyAPI.prototype = new BZAPI()
PropertyAPI.prototype.constructor = PropertyAPI

window.PropertyAPI = PropertyAPI
