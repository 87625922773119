/**	object wrapper for browser cookie access	*/

var Cookie = function () {}

Cookie.key = {
  current_project_id: 'bz_current_project_id',
  current_service_request_id: 'bz_current_sr_id',
}

Cookie.set = function (name, value, expires) {
  if (expires === undefined) {
    expires = 365
  }
  $.cookie(name, value, { expires: expires, path: '/' })
}

Cookie.get = function (name) {
  return $.cookie(name)
}

Cookie.remove = function (name) {
  return $.removeCookie(name, { path: '/' })
}

Cookie.set_current_project_id = function (project_id) {
  Cookie.set(Cookie.key.current_project_id, project_id)
}

Cookie.get_current_project_id = function () {
  return Cookie.get(Cookie.key.current_project_id)
}

Cookie.delete_current_project_id = function () {
  return Cookie.remove(Cookie.key.current_project_id)
}

Cookie.set_current_service_request_id = function (sr_id) {
  Cookie.set(Cookie.key.current_service_request_id, sr_id)
}

Cookie.get_current_service_request_id = function () {
  return Cookie.get(Cookie.key.current_service_request_id)
}

Cookie.delete_current_service_request_id = function () {
  return Cookie.remove(Cookie.key.current_service_request_id)
}

window.Cookie = Cookie
