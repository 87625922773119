/* global angular, ServiceRequestsTrack, Track, UserData, isMobile */
angular
  .module('bzProjectModule')
  .factory('trackServiceRequestsActionsService', [
    '$location',
    'attributeConversionService',
    function ($location, attributeConversionService) {
      return {
        title: 'Service Request Action',
        initQueryParams: function () {
          const qs = $location.search()

          this.templateId = qs.template_id
          this.communicationCampaign = qs.communication_campaign
        },
        projectFormSubmit: function (serviceRequestId) {
          Track.impersonation_check().send(
            this.title,
            this.defaultData({
              action: 'submit step one',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        inviteContractor: function (projectId, contractorId) {
          ServiceRequestsTrack.bookmark(
            'invite contractor - project page while wait',
            projectId,
            contractorId
          )
        },
        dismissContractor: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'skip contractor - project page while wait',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          this.track(this.title, data)
        },
        viewProjectSummaryPage: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'PPSPPageView',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        attachProjectFiles: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'attachment added by homeowner',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        hireContractor: function (serviceRequestId, contractorId, source) {
          let data = this.defaultData({
            action: 'hire',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.source = source

          this.track(this.title, data)
        },
        hireFromModal: function (serviceRequestId, contractorId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'hire - hire modal',
              serviceRequestId,
              contractorId,
            })
          )
        },
        viewCloseSurvey: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'close survey view',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        submitCloseSurvey: function (
          serviceRequestId,
          reason,
          details,
          didUploadAgreement
        ) {
          let data = this.defaultData({
            action: 'close survey submit',
            serviceRequestId: serviceRequestId,
          })
          data.reason = reason
          data.details = details
          data.didUploadAgreement = didUploadAgreement
          this.track(this.title, data)
        },
        viewReviewForm: function (serviceRequestId, contractorId, source) {
          let data = this.defaultData({
            action: 'review form view',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.source = source

          this.track(this.title, data)
        },
        viewBidDetails: function (serviceRequestId, contractorId, source) {
          let data = this.defaultData({
            action: 'view bid details',
            serviceRequestId: serviceRequestId,
          })

          data.contractor_id = contractorId
          data.source = source

          this.track(this.title, data)
        },
        viewContractorChat: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'view contractor chat',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          Track.send(this.title, data)
        },
        projectsCompleted: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'projects completed link',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        submitReviewForm: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'review form submit',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          this.track(this.title, data)
        },
        unHireContractor: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'unhire',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          this.track(this.title, data)
        },
        hireContractorConfirm: function (
          serviceRequestId,
          contractorId,
          bidAmount,
          version
        ) {
          let data = this.defaultData({
            action: 'hire confirm',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.bid_amount = bidAmount
          data.version = version

          this.track(this.title, data)
        },
        hireContractorUploadAgreement: function (
          serviceRequestId,
          contractorId,
          attachmentsCount,
          version
        ) {
          let data = this.defaultData({
            action: 'hire upload agreement',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.attachments_count = attachmentsCount || 0
          data.version = version

          this.track(this.title, data)
        },
        completeWhyFlow: function (
          serviceRequestId,
          contractorId,
          returnTo,
          version
        ) {
          let data = this.defaultData({
            action: 'complete why flow',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.return_to = returnTo
          data.version = version

          this.track(this.title, data)
        },
        goToWhyFlow: function (
          serviceRequestId,
          contractorId,
          reason,
          version
        ) {
          let data = this.defaultData({
            action: 'go to why flow',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.reason = reason
          data.version = version

          this.track(this.title, data)
        },
        viewMoreDetails: function (
          serviceRequestId,
          contractorId,
          page,
          version
        ) {
          let data = this.defaultData({
            action: 'view more',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.page = page
          data.version = version

          this.track(this.title, data)
        },
        declineContractor: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'decline contractor',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          this.track(this.title, data)
        },
        declineContractorConfirm: function (
          serviceRequestId,
          contractorId,
          reason
        ) {
          let data = this.defaultData({
            action: 'decline contractor confirm',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.reason = reason

          this.track(this.title, data)
        },
        unDeclineContractor: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'undecline contractor',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          this.track(this.title, data)
        },
        closeProject: function (serviceRequestId) {
          let data = this.defaultData({
            action: 'close project',
            serviceRequestId: serviceRequestId,
          })

          this.track(this.title, data)
        },
        reopenProject: function (serviceRequestId) {
          let data = this.defaultData({
            action: 'reopen project',
            serviceRequestId: serviceRequestId,
          })

          this.track(this.title, data)
        },
        messageSent: function (conversationId, userId, serviceRequestId) {
          let data = this.defaultData({
            action: 'message sent',
            serviceRequestId: serviceRequestId,
          })
          data.conversation_id = conversationId
          data.user_role = 'homeowner'
          data.user_id = userId

          this.track('Messaging Action', data)
        },
        addMessageAttachmentClicked: function (serviceRequestId) {
          let data = this.defaultData({
            action: 'add message attachment',
            serviceRequestId: serviceRequestId,
          })

          this.track(this.title, data)
        },
        messageAttachmentAdded: function (serviceRequestId, fileCount) {
          let data = this.defaultData({
            action: 'message attachment added',
            serviceRequestId: serviceRequestId,
          })
          data.file_count = fileCount

          this.track(this.title, data)
        },
        quickMessages: function (serviceRequestId, messageType) {
          let data = this.defaultData({
            action: 'quick message clicked',
            serviceRequestId: serviceRequestId,
          })
          data.message_type = messageType

          this.track(this.title, data)
        },
        shareFilesClicked: function (serviceRequestId) {
          let data = this.defaultData({
            action: 'share files clicked',
            serviceRequestId: serviceRequestId,
          })

          this.track(this.title, data)
        },
        filesAdded: function (serviceRequestId, fileCount) {
          let data = this.defaultData({
            action: 'files added',
            serviceRequestId: serviceRequestId,
          })
          data.file_count = fileCount

          this.track(this.title, data)
        },
        contractorTabClicked: function (serviceRequestId, tab) {
          let data = this.defaultData({
            action: 'contractor tab clicked',
            serviceRequestId: serviceRequestId,
          })
          data.tab = tab

          this.track(this.title, data)
        },
        messageTabClicked: function (serviceRequestId, tab) {
          let data = this.defaultData({
            action: 'messages viewed',
            serviceRequestId: serviceRequestId,
          })
          data.tab = tab

          this.track(this.title, data)
        },
        setPassword: function (serviceRequestId) {
          let data = this.defaultData({
            action: 'set password',
            serviceRequestId: serviceRequestId,
          })

          this.track(this.title, data)
        },
        phoneLink: function (serviceRequestId) {
          let data = this.defaultData({
            action: 'call contractor',
            serviceRequestId: serviceRequestId,
          })

          this.track(this.title, data)
        },
        requestAdditionalContractor: function (
          serviceRequestId,
          outcome,
          source
        ) {
          let data = this.defaultData({
            action: 'request additional contractor',
            serviceRequestId: serviceRequestId,
          })
          data.outcome = outcome
          data.source = source

          this.track(this.title, data)
        },
        viewPoBidModal: function (
          serviceRequestId,
          contractorId,
          origin,
          isSafeHire
        ) {
          let data = this.defaultData({
            action: 'view po bid modal',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.origin = origin
          data.safe_hire = !!isSafeHire

          this.track(this.title, data)
        },
        bidCreated: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'bid created',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        bidUpdated: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'bid updated',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        bidUploadFilesClicked: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'bid upload files clicked',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          this.track(this.title, data)
        },
        bidFilesAdded: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'bid files added',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId

          this.track(this.title, data)
        },
        happyWithContractors: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'happy with contractors',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        unhappyWithContractors: function (serviceRequestId) {
          this.trackGeneric({ action: 'request - more COs', serviceRequestId })
        },
        unhappyWithContractorsReason: function (serviceRequestId, reason) {
          let data = this.defaultData({
            action: 'select reason for unhappy with contractors',
            serviceRequestId: serviceRequestId,
          })
          data.reason = reason
          this.track(this.title, data)
        },
        exitFlow: function (serviceRequestId, flow, screen) {
          let data = this.defaultData({
            action: 'exit flow',
            serviceRequestId: serviceRequestId,
          })
          data.screen = screen
          data.flow = flow
          this.track(this.title, data)
        },
        happyWithBids: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'happy with bids',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        unhappyWithBids: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'unhappy with bids',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        viewHireModal: function (serviceRequestId) {
          this.trackGeneric({ action: 'view - hire modal', serviceRequestId })
        },
        clickHireModalNotReady: function (serviceRequestId) {
          this.trackGeneric({
            action: 'click next - hire modal',
            serviceRequestId,
          })
        },
        viewEstimateModal: function (serviceRequestId, numCosAppearing) {
          this.trackGeneric({
            action: 'view - estimate modal',
            serviceRequestId,
            custom: {
              numCosAppearing,
            },
          })
        },
        dismissEstimateModal: function (serviceRequestId) {
          this.trackGeneric({
            action: 'click dismiss - estimate modal',
            serviceRequestId,
          })
        },
        viewConnectedReviewsForm: function (serviceRequestId, numCosAppearing) {
          this.trackGeneric({
            action: 'view - connected reviews form',
            serviceRequestId,
            custom: {
              numCosAppearing,
            },
          })
        },
        submitConnectedReviewsForm: function (
          serviceRequestId,
          numCosAppearing,
          numReviewsSubmitted
        ) {
          this.trackGeneric({
            action: 'submit - connected reviews form',
            serviceRequestId,
            custom: {
              numCosAppearing,
              numReviewsSubmitted,
            },
          })
        },
        requestedSafeHire: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'request safe hire',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        rejectedSafeHire: function (serviceRequestId) {
          this.track(
            this.title,
            this.defaultData({
              action: 'reject safe hire',
              serviceRequestId: serviceRequestId,
            })
          )
        },
        requestBid: function (serviceRequestId, contractorId, source) {
          let data = this.defaultData({
            action: 'bid requested',
            serviceRequestId: serviceRequestId,
          })
          data.contractor_id = contractorId
          data.source = source

          this.track(this.title, data)
        },
        poClickEditBid: function (serviceRequestId, contractorId, source) {
          let data = this.defaultData({
            action: 'po click edit bid',
            serviceRequestId: serviceRequestId,
          })

          data.contractor_id = contractorId
          data.source = source

          this.track(this.title, data)
        },
        poSaveEditBid: function (serviceRequestId, contractorId) {
          let data = this.defaultData({
            action: 'po save edit bid',
            serviceRequestId: serviceRequestId,
          })

          data.contractor_id = contractorId
          this.track(this.title, data)
        },
        track: function (title, data) {
          Track.impersonation_check().send(title, data)
        },
        trackGeneric: function (customData) {
          this.track(this.title, this.defaultData(customData))
        },
        defaultData: function (input) {
          return {
            action: input.action ? input.action : '',
            url: document.location.pathname,
            user_signed_in: UserData.present()
              ? UserData.user_signed_in()
              : 'unknown',
            user_type: UserData.present() ? UserData.user_role() : 'unknown',
            platform: isMobile.check() ? 'mobile' : 'desktop',
            service_request_id: input.serviceRequestId
              ? input.serviceRequestId
              : '',
            template_id: this.templateId,
            communication_campaign: this.communicationCampaign,
            ...attributeConversionService.camelToUnderscoreAttributes(
              input.custom || {}
            ),
          }
        },
      }
    },
  ])
