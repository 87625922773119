import 'AngularBase/_components/bookmark'

/** bookmark for generic (not logged in or no project) photo search */
var PhotoSearchGenericBookmark = function () {
  this.init = function () {
    this.init_thumbnail_generic()
    this.init_modal()
  }

  /**	thumbnails displayed on image search when user not logged in or has no projects	*/
  this.init_thumbnail_generic = function () {
    var that = this

    // reusable internal function
    var inject_bookmarks = function () {
      var thumbnails = $('#images_wrapper .image_item').has(
        '.buttons .favorite'
      ) // only select photos that don't have bookmark yet
      thumbnails.find('.buttons .favorite').remove()
      thumbnails.append(that.photo_bookmark_template('thumbnail_bookmark'))

      thumbnails.find('.thumbnail_bookmark').on('click', function (e) {
        var bookmark = $(this)
        var photo_id = bookmark.closest('.image_item').data('image_id')

        if (that.user_signed_in) {
          // create project
          that.create_project_with_photo(photo_id, function (res) {
            if (res.success) {
              bookmark.addClass('selected') // highlight photo's bookmark
              $('body').trigger('project_data_load', {
                trigger_project_data_loaded: false,
              }) // inject project nav

              thumbnails.find('.thumbnail_bookmark').off('click') // remove generic bookmark event
              $('body').off('photo_search_more_loaded') // remove generic event from load more photos event

              // initialize logic for existing project; call parent's init thumb function
              that.init_thumbnail('existing')
            }
          })
        } else {
          // allow user to sign up/in before creating project
          that.redirect_to_sign_in()
        }
      })
    }

    inject_bookmarks()

    $('body').on('photo_search_more_loaded', function (e) {
      inject_bookmarks() // put removable LIKE / addition of bookmark into function from above code and call here to inject
    })
  }

  /**	gallery modal displayed when photo on image search is clicked when user not logged in or has no projects	*/
  this.init_modal = function () {
    var that = this

    $('#blueimp-gallery').on('slide', function (event, index, slide) {
      var photo_id = $('.image_item').eq(index).data('image_id')
      //var project_id = that.project_data().attr('data-project_id');

      var modal_content = $('#blueimp-gallery .modal-content')
      modal_content.find('.favorite').remove()
      modal_content.find('.photo_search_modal_bookmark').remove()
      modal_content.append(
        that.photo_bookmark_template('photo_search_modal_bookmark')
      )

      $('.photo_search_modal_bookmark').on('click', function (e) {
        if (that.user_signed_in) {
          // create project
          that.create_project_with_photo(photo_id, function (res) {
            if (res.success) {
              $('.photo_search_modal_bookmark').addClass('selected') // highlight photo's modal bookmark
              $('.image_item[data-image_id="' + photo_id + '"]')
                .find('.thumbnail_bookmark')
                .addClass('selected') // highlight photo's thumbnail bookmark;
              $('body').trigger('project_data_load') // inject project nav

              $('#blueimp-gallery').off('slide')
              $('.photo_search_modal_bookmark').off('click') // remove generic event from load more photos event
            }
          })
        } else {
          that.redirect_to_sign_in()
        }
      })
    })
  }
}

PhotoSearchGenericBookmark.prototype = new PhotoSearchBookmark()
PhotoSearchGenericBookmark.prototype.constructor = PhotoSearchGenericBookmark

window.PhotoSearchGenericBookmark = PhotoSearchGenericBookmark
