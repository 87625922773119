/* global angular, isMobile, Track */
const trackBzScoreService = class {
  constructor() {
    this.title = 'BZ Score Action'
  }

  buildData = (action) => ({
    action,
    platform: isMobile.check() ? 'mobile' : 'desktop',
  })

  send = (action) => Track.send(this.title, this.buildData(action))

  explanationModalViewed = () => this.send('explanation modal viewed')
}

export default trackBzScoreService
