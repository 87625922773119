/* UserData */
import template from './company_logo.html'

const companyLogo = {
  bindings: {
    logoUrl: '<',
    logoDescription: '@',
  },
  template,
  controller: [
    '$element',
    'urlService',
    'contractorGalleryModel',
    function ($element, urlService, contractorGalleryModel) {
      this.$onInit = () => {
        this.utils = urlService

        angular.element('#logo-selector').on('change', ($event) => {
          this.attachmentSelected($event.target.files)
        })
      }

      this.attachmentSelected = (files) => {
        this.isAttaching = true

        contractorGalleryModel
          .addLogo({ files: files })
          .then((uploadedFile) => {
            this.logoUrl = uploadedFile.url
            this.isAttaching = false
          })
      }

      this.deleteLogo = () => {
        contractorGalleryModel.deleteLogo().then(() => {
          this.logoUrl = null
        })
      }
    },
  ],
}

export default companyLogo
