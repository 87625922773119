const trackDeclineLeadService = [
  '$window',
  function ($window) {
    return {
      title: 'Decline Lead Action',
      defaultData: function (lead, params) {
        return angular.extend(
          {
            action: params.action ? params.action : '',
            service_request_id: lead.project.id,
            platform: isMobile.check() ? 'mobile' : 'desktop',
            contractor_id: lead.contractor.id,
            lead_referer: this.leadReferer(),
          },
          params
        )
      },
      archive: function (lead, explanation) {
        this.send(lead, { action: 'archive', explanation: explanation })
      },
      saveBudget: function (lead, changed) {
        this.send(lead, { action: 'save budget', changed: changed })
      },
      saveTravelRange: function (lead, changed) {
        this.send(lead, { action: 'save travel range', changed: changed })
      },
      viewReferralForm: function (lead) {
        this.send(lead, { action: 'view referral form' })
      },
      viewAvailabilitySurvey: function (lead) {
        this.send(lead, { action: 'view availability survey' })
      },
      submitProjectBudgetMin: function (lead) {
        this.send(lead, { action: 'submit project budget min' })
      },
      submitAvailabilitySurvey: function (lead, snoozeDays, callback) {
        Track.send_callback(
          this.title,
          this.defaultData(lead, {
            action: 'submit availability survey',
            snooze_days: snoozeDays,
          }),
          callback
        )
      },
      saveProjectTypes: function (lead, changed) {
        this.send(lead, { action: 'project types updated', changed: changed })
      },
      projectPreferencesScreenOpened: function (lead) {
        this.send(lead, { action: 'view project preferences screen' })
      },
      projectPreferencesScreenSkipped: function (lead) {
        this.send(lead, { action: 'project preferences screen skipped' })
      },
      leadReferer: function () {
        if (document.location.search.indexOf('type=email') >= 0) {
          return 'email'
        } else if (document.location.pathname.indexOf('/job/') >= 0) {
          return 'sms'
        } else {
          return $window.isMobile.check() ? 'mobile_web' : 'web'
        }
      },
      send: function (lead, params) {
        Track.send(this.title, this.defaultData(lead, params))
      },
    }
  },
]

export default trackDeclineLeadService
