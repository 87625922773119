/* global angular */
angular
  .module('bzMessageBannerModule')
  .directive('messageBannerDefaultContent', [
    'urlService',
    function (urlService) {
      return {
        restrict: 'AE',
        replace: true,
        scope: {},
        controllerAs: '$ctrl',
        bindToController: {
          options: '<',
        },
        template: `
				<div
					ng-bind-html="$ctrl.options.message"
					class="text-center">
				</div>
			`,
        controller: [
          function () {
            this.$onInit = function () {}
          },
        ],
      }
    },
  ])
