bz_app.controller('ArchiveLeadModalCtrl', [
  '$uibModalInstance',
  'inputData',
  'trackLeadsService',
  'searchModel',
  'attributeConversionService',
  function (
    $uibModalInstance,
    inputData,
    trackLeadsService,
    searchModel,
    attributeConversionService
  ) {
    this.$onInit = function () {
      this.classes = {
        hidden: 'hidden-element',
        visible: 'visible-element',
      }
      this.lead = inputData.lead
      this.reasonArchived = null
      this.otherReasonExplanation = ''
      this.submissionBlocked = false
      this.hiddenClass = this.classes.hidden
      this.otherContractorName = ''
      this.otherContractor = {
        id: null,
        businessName: null,
      }

      this.reasonArchivedOptions = {
        isOtherContractorHired: false,
        isBadFit: false,
        isProjectOwnerUnresponsive: false,
        isBidRejected: false,
        isBusy: false,
        isOther: false,
      }

      this.showProjectBudgetMinForm = false
    }

    this.setReasonArchived = function (reasonArchived) {
      this.reasonArchived = reasonArchived
      this.hiddenClass = this.classes.hidden
    }

    this.ok = function () {
      if (
        this.reasonArchivedOptions.isOther &&
        this.otherReasonExplanation.length
      ) {
        this.reasonArchived = this.otherReasonExplanation
      }

      if (this.reasonArchivedOptions.isOtherContractorHired) {
        this.reasonArchived += ': ' + this.otherContractorDetails()
      }

      if (this.reasonArchived) {
        this.submissionBlocked = false

        this.lead.archive(this.reasonArchived).then(
          function () {
            trackLeadsService.notInterestedExplanationSubmitted(
              this.lead,
              this.reasonArchived
            )

            if (this.reasonArchived === 'archive - bid rejected') {
              this.showProjectBudgetMinForm = true
            } else {
              $uibModalInstance.close('ok')
            }
          }.bind(this)
        )
      } else {
        this.submissionBlocked = true
      }
    }

    this.cancel = function ($event) {
      $uibModalInstance.dismiss('cancel')
      if ($event) {
        $event.preventDefault()
      }
    }

    this.selectOther = function () {
      angular.forEach(
        this.reasonArchivedOptions,
        function (value, key) {
          this.reasonArchivedOptions[key] = false
        }.bind(this)
      )

      this.hiddenClass = this.classes.visible
      this.reasonArchivedOptions.isOther = true
      this.reasonArchived = null
    }

    this.setOtherContractor = function (contractor) {
      this.otherContractor = contractor
    }

    this.otherContractorMatch = function () {
      return (
        this.otherContractor.id &&
        this.otherContractorName === this.otherContractor.businessName
      )
    }

    this.otherContractorDetails = function () {
      if (this.otherContractorMatch()) {
        return this.otherContractor.id
      } else if (this.otherContractorName.length > 0) {
        return this.otherContractorName
      } else {
        return 'unspecified'
      }
    }

    this.contractorSuggestions = function () {
      return searchModel
        .getSearchContractors(null, this.otherContractorName, {
          zipcode: this.lead.property.location.zipcode,
          mile_radius: 200,
        })
        .then(
          attributeConversionService.underscoreToCamelDeepAttributes.bind(
            attributeConversionService
          )
        )
    }

    this.onSubmitProjectBudgetMin = () => {
      $uibModalInstance.close('ok')
    }

    this.onCancelProjectBudgetMin = () => {
      this.showProjectBudgetMinForm = false
    }

    this.onCloseProjectBudgetMin = () => {
      $uibModalInstance.close('ok')
    }
  },
])
