import template from './bz_score.html'

const bzScore = {
  bindings: {
    contractor: '<',
    onSubmit: '&?',
    omitQuoteCta: '<',
  },
  template,
}

export default bzScore
