/* global angular */
angular.module('bzProjectFormModule').component('timelineItem', {
  bindings: {
    isActive: '=',
    isMostRecentActive: '=',
    itemType: '=',
    descriptionText: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/timeline_item'
      )
    },
  ],
  controller: [
    function () {
      this.getItemImageClass = () => {
        return this.isActive
          ? `white-${this.itemType}`
          : `gray-${this.itemType}`
      }
    },
  ],
})
