/* global bz_app, angular, isMobile */
import template from './blocks_banner.html'
const blocksBanner = {
  bindings: {
    data: '<',
    bannerKey: '<',
  },
  template,
  controller: [
    '$rootScope',
    'blocksService',
    'leadModel',
    'globalBannerTracking',
    function ($rootScope, blocksService, leadModel, globalBannerTracking) {
      this.$onInit = () => {
        this.blockDisplayData = blocksService.blockDisplayData(this.data.block)
        this.blockData = this.data.block
      }

      this.redirectToCta = () => {
        globalBannerTracking.clickBannerCTA(this.bannerKey)
        blocksService.redirectToCta(this.blockDisplayData)
      }
    },
  ],
}

export default blocksBanner
