const trackMapService = [
  function () {
    return {
      title: 'Map Action',
      mapPanorama: function (action, referralSource) {
        Track.send(
          this.title,
          this.defaultData({
            action: `map panorama ${action}`,
            referral_source: referralSource,
          })
        )
      },
      defaultData: function (input) {
        return angular.extend(
          {
            action: input.action || '',
            platform: isMobile.check() ? 'mobile' : 'desktop',
          },
          input
        )
      },
    }
  },
]

export default trackMapService
