/* global angular, Track */
angular.module('bzRegistrationModule').factory('registrationModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  'utmService',
  'bzHttp',
  function (
    $http,
    urlConstants,
    attributeConversionService,
    utmService,
    bzHttp
  ) {
    return {
      apiRegistrationsPath: urlConstants.api.v1.path + 'registrations',
      apiUsersPath: urlConstants.api.v1.path + 'user',

      // generic method for CO sign up, CO claim, and user sign up
      signUp: function (user) {
        if (user.nid && !user.contractorId) {
          user.contractorId = user.nid
        }
        user.utmSource = utmService.getUtmSource()
        var data = this.toUnderscore(user)

        return $http
          .post(this.apiRegistrationsPath, data)
          .then(this.extractData.bind(this))
          .then(function (data) {
            if (data && data.user && data.user.email) {
              Track.alias(data.user.email)

              if (user.contractorId) {
                Track.sendGa(
                  'Claim Screen Action',
                  'claim profile submitted',
                  ''
                )
                Track.sendBing('Claim Screen Action', 'claim profile submitted')
              }
            }

            return data
          })
      },

      createDataSubscriptionUser: function (
        email,
        password,
        fullName,
        phoneNumber,
        propertyId,
        referralSource
      ) {
        return bzHttp.post(
          `${this.apiRegistrationsPath}/create_data_subscription_user`,
          {
            email,
            password,
            fullName,
            phoneNumber,
            propertyId,
            referralSource,
          }
        )
      },

      checkEmailAvailability: function (email) {
        return $http
          .get(this.apiUsersPath + '/find_existing_user?email=' + email)
          .then(this.extractData.bind(this))
      },

      toUnderscore: function (data) {
        return attributeConversionService.camelToUnderscoreDeepAttributes(data)
      },

      toCamel: function (data) {
        return attributeConversionService.underscoreToCamelDeepAttributes(data)
      },

      extractData: function (response) {
        return this.toCamel(response.data) || null
      },
    }
  },
])
