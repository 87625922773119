/* global angular */
angular.module('bzUserModule').component('claimBusinessSearchContractors', {
  bindings: {
    contractors: '=',
    claimContractor: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('users/claim_business_search_contractors')
    },
  ],
})
