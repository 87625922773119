/* global bz_app, isMobile, angular */
angular.module('bzReviewsModule').component('baseStep', {
  bindings: {
    review: '=',
    step: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('reviews/base_step')
    },
  ],
  controller: [
    'reviewFormScreenService',
    'formValidationService',
    function (reviewFormScreenService, formValidationService) {
      this.$onInit = function () {
        this.submissionBlocked = false
        this.buttonDisabled = false
        this.isMobile = isMobile.check({ phoneOnly: true })
        this.signalValues = this.review.hiredSignalValues()
        this.review.formVersion = 1.3
      }

      this.buttonText = function () {
        return 'next'
      }

      this.submit = function () {
        this.buttonDisabled = true
        if (this.isValid()) {
          this.submissionBlocked = false
          this.review.save().then(
            function (response) {
              if (response && response.success) {
                this.review.id = response.review.id
                this.buttonDisabled = false
                if (UserData.user_signed_in()) {
                  reviewFormScreenService.goTo('details')
                } else {
                  reviewFormScreenService.goTo('user')
                }
              }
            }.bind(this)
          )
        } else {
          this.submissionBlocked = true
          this.buttonDisabled = false
        }
      }

      this.isValid = function () {
        return (
          this.review.description &&
          this.review.description.length >= 25 &&
          this.review.stars &&
          typeof this.review.hire !== 'undefined' &&
          this.validateSignals()
        )
      }

      this.setHire = function () {
        this.review.hire = true

        if (
          this.review.reviewType !== 'hired' &&
          this.review.reviewType !== 'connected'
        ) {
          this.review.reviewType = 'posted'
        }
      }

      this.setNoHire = function () {
        this.review.hire = false

        if (
          this.review.reviewType !== 'hired' &&
          this.review.reviewType !== 'connected'
        ) {
          this.review.reviewType = 'posted-no-hire'
        }
      }

      this.signalCollectionText = function () {
        return this.review.stars && this.review.stars > 3
          ? 'What did you like about this contractor?'
          : 'What went wrong?'
      }

      this.errorMessage = function () {
        if (!this.review.stars) {
          return 'Please select overall star rating.'
        } else if (
          this.review.showSignals() &&
          this.review.signals.length === 0 &&
          this.review.stars < 3
        ) {
          return 'Please select a reason for what went wrong.'
        } else if (typeof this.review.hire === 'undefined') {
          return 'Please select whether you hired this pro or not.'
        } else if (!this.review.description) {
          return 'Please share details of your experience.'
        } else if (this.review.validateDescription()) {
          return 'The description needs to be at least 25 characters.'
        } else {
          return 'Please fill out the review form.'
        }
      }

      this.validateSignals = function () {
        return this.review.validateSignals()
      }

      this.showSignals = function () {
        return this.review.showSignals()
      }

      this.updateShowSignal = function () {
        this.review.resetSignal()
        this.signalValues = this.review.hiredSignalValues()
      }

      this.setSignal = function (signal) {
        this.review.setSignal(signal)
      }

      this.isActiveSignal = function (signal) {
        return this.review.isActiveSignal(signal)
      }
    },
  ],
})
