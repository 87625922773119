var ReviewResponseAPI = function () {
  this.create = function (data) {
    return $.when(
      $.ajax({
        url: this.review_responses_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ review_response: data }),
        dataType: 'json',
      })
    )
  }

  this.update = function (data) {
    return $.when(
      $.ajax({
        url: this.review_responses_api_path() + '/' + data.id,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({ review_response: data }),
        dataType: 'json',
      })
    )
  }
}

ReviewResponseAPI.prototype = new BZAPI()
ReviewResponseAPI.prototype.constructor = ReviewResponseAPI

window.ReviewResponseAPI = ReviewResponseAPI
