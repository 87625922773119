const globalBannerService = [
  '$rootScope',
  '$cacheFactory',
  function ($rootScope, $cacheFactory) {
    return {
      close(bannerKey) {
        $rootScope.$emit('closeGlobalBanner', bannerKey)
      },
      reload() {
        this.close()
        $cacheFactory.get('$http').removeAll()
        $rootScope.$emit('reloadGlobalBanner')
      },
    }
  },
]

export default globalBannerService
