import template from './showcase_projects_carousel.html'

const showcaseProjectsCarousel = angular
  .module('showcaseProjectsCarousel', [])
  .component('showcaseProjectsCarousel', {
    bindings: {},
    template,
    controller: [
      '$timeout',
      '$window',
      '$scope',
      function ($timeout, $window, $scope) {
        this.$onInit = () => {
          this.carousel = angular.element('#projects-carousel-scrollable-div')
          this.carouselScrolling = false
          this.shouldAnimate = true

          this.projectList = [
            {
              id: 0,
              title: 'Master Bed & Bath Remodel',
              client: 'Harris E.',
              contractor: 'Mayflower Construction',
              contractor_id: 90165012,
              location: 'Fairfax, VA',
              budget: '$50,000',
              review:
                '“BuildZoom made the process very straightforward, and reviewing my bids with them was great! I hired Mayflower because of their attention to detail. They provided an excellent experience - I could tell they were serious and professional.”',
              showBefore: false,
              onClick: null,
            },
            {
              id: 1,
              title: 'Full Remodel & Addition',
              client: 'Tony W.',
              contractor: 'Greenberg Construction',
              contractor_id: 71059744,
              location: 'Redwood City, CA',
              budget: '$1,000,000',
              review:
                '"We spent a frustrating few weeks messaging contractors directly, until we learned that BuildZoom would source trusted contractors for us. BuildZoom connected us with Maor, who worked diligently to ensure the design and build were perfect."',
              showBefore: false,
              onClick: null,
            },
            {
              id: 2,
              title: 'Multi-Room Remodel',
              client: 'Judy W.',
              contractor: 'Groundwork Properties',
              contractor_id: 90071456,
              location: 'Seattle, WA',
              budget: '$50,000',
              review: `"Referrals didn't work for me in a time crunch. I appreciate the help I received from BuildZoom in finding a contractor! Ben from Groundwork understood my aesthetic and made sure the end product would have a finished look."`,
              showBefore: false,
              onClick: null,
            },
          ]

          this.carouselCount = this.projectList.length * 3
          this.populateCarouselItems(this.carouselCount)

          $timeout(this.resetCarousel, 1)

          angular.element($window).bind('resize', this.resetCarousel)
          $scope.$on('$destroy', () => {
            angular.element($window).unbind('resize', this.resetCarousel)
          })
        }

        this.defaultLeftAdjustment = () =>
          0 - (this.carouselItemWidth() * this.carouselCount) / 2

        this.populateCarouselItems = (numberOfItems) => {
          this.items = []
          let i = 0
          while (this.items.length < numberOfItems) {
            if (!this.projectList[i]) i = 0
            this.items.push(this.projectList[i])
            i++
          }
        }

        this.resetCarousel = () => {
          this.scrollAdjustment = this.defaultLeftAdjustment()
          this.focusIndex = Math.floor(this.carouselCount / 2)
          this.setFunctionalityForVisibleItems()
          this.centerCarousel()
        }

        this.setFunctionalityForVisibleItems = () => {
          this.items[this.focusIndex].onClick = this.toggleBeforeAfter
          this.items[this.focusIndex - 1].onClick = this.scrollCarouselLeft
          this.items[this.focusIndex + 1].onClick = this.scrollCarouselRight
        }

        this.centerCarousel = () => {
          this.carousel.css(
            'transform',
            `translateX(${this.defaultLeftAdjustment()}px)`
          )
        }

        this.carouselItemWidth = () =>
          angular.element('.project-container')[0].clientWidth

        this.scrollCarouselLeft = () => this.handleScrollCarousel('left')
        this.scrollCarouselRight = () => this.handleScrollCarousel('right')

        this.handleScrollCarousel = (direction) => {
          if (this.carouselScrolling) return
          this.carouselScrolling = true
          direction === 'left' ? this.focusIndex-- : this.focusIndex++

          if (this.atEndOfCarousel()) {
            this.resetThenScroll(direction)
          } else {
            this.scroll(direction)
          }

          this.setFunctionalityForVisibleItems()
          this.carouselScrolling = false
        }

        this.scroll = (direction) => {
          direction === 'left'
            ? (this.scrollAdjustment += this.carouselItemWidth())
            : (this.scrollAdjustment -= this.carouselItemWidth())
          this.carousel.css(
            'transform',
            `translateX(${this.scrollAdjustment + 'px'})`
          )
        }

        this.resetThenScroll = (direction) => {
          this.shouldAnimate = false
          this.resetCarousel()
          direction === 'left' ? this.focusIndex-- : this.focusIndex++

          $timeout(() => {
            this.shouldAnimate = true
            this.scroll(direction)
          }, 1)
        }

        this.atEndOfCarousel = () =>
          this.focusIndex === 0 || this.focusIndex === this.carouselCount - 1

        this.toggleBeforeAfter = (project) =>
          (project.showBefore = !project.showBefore)
        this.showBefore = (project) => (project.showBefore = true)
        this.showAfter = (project) => (project.showBefore = false)
      },
    ],
  }).name

export default showcaseProjectsCarousel
