const ContractorSubscriptionsModelModule = angular
  .module('contractorSubscriptionsModel', [])
  .factory('contractorSubscriptionsModel', [
    '$http',
    'urlConstants',
    'attributeConversionService',
    function ($http, urlConstants, attributeConversionService) {
      const API_PATH = urlConstants.api.v1.path + 'contractor_subscriptions'

      return {
        create: (application) =>
          $http.post(
            API_PATH,
            attributeConversionService.camelToUnderscoreObjectAttributes(
              application
            )
          ),
      }
    },
  ]).name

export default ContractorSubscriptionsModelModule
