angular.module('bzValidationModule').factory('numberValidationService', [
  function () {
    return {
      onlyNumbers: function (value) {
        var numberRegex = /^\d+|\.\d+$/
        return numberRegex.exec(value)
      },
    }
  },
])
