const winRateModalController = [
  '$uibModalInstance',
  'inputData',
  'urlConstants',
  '$sce',
  function ($uibModalInstance, inputData, urlConstants, $sce) {
    this.$onInit = function () {
      angular.extend(this, inputData)
      this.leadsUrl = urlConstants.contractor.leadsDashboardURL

      if (this.modalSeverity === 1) {
        this.modalTitle = 'Still want more free leads?'
        this.modalSubtext =
          'BuildZoom rewards pros who consistently impress, and are hired by, our property owners. Please be aware that pros who do not get hired will lose access to BuildZoom projects.'
        this.bzScoreDifference = -5
      } else if (this.modalSeverity === 2) {
        this.modalTitle = $sce.trustAsHtml(
          "<i class='yellow-warning-icon'></i>You are about to lose access to projects"
        )
        this.modalSubtext =
          'BuildZoom rewards pros who consistently impress, and are hired by, our property owners. Please be aware that pros who do not get hired will lose access to BuildZoom projects.'
        this.bzScoreDifference = -8
      } else if (this.modalSeverity === 3) {
        this.modalTitle = $sce.trustAsHtml(
          "<i class='yellow-warning-icon'></i> You have been blocked from future projects"
        )
        this.modalSubtext =
          'If you have been hired for any of our projects, you can reactivate your account immediately by reporting which jobs you were hired for'
        this.bzScoreDifference = -10
      }
    }

    this.cancel = function ($event) {
      $uibModalInstance.dismiss('cancel')
      if ($event) {
        $event.preventDefault()
      }
    }
  },
]

export default winRateModalController
