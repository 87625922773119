/* global bz_app, InvoiceAPI, ProjectAPI, angular */
import 'AngularBase/_components/api/invoice_api'
import 'AngularBase/_components/api/project_api'

bz_app.factory('invoiceModel', [
  'attributeConversionService',
  function (attributeConversionService) {
    return {
      invoiceApi: new InvoiceAPI(),
      projectApi: new ProjectAPI(),
      create: function (invoice) {
        if (invoice.invoice_type === 'commission') {
          return this.invoiceApi.create(invoice)
        } else {
          return this.invoiceApi.create({
            project_id: invoice.project.id,
            contractor_id: invoice.contractorId,
          })
        }
      },
      update: function (invoice, options) {
        return this.invoiceApi.update(this.prepareForAPI(invoice, options))
      },
      send: function (invoice) {
        return this.invoiceApi.send({ id: invoice.id })
      },
      contractorMarkPaid: function (invoice) {
        return this.invoiceApi.contractorStatusTransition({
          id: invoice.id,
          status: 'paid',
        })
      },
      loadProjects: function () {
        return this.projectApi.load_all(null, 'provided')
      },
      createProject: function (project) {
        project.source = 'external'
        return this.projectApi.create(project)
      },
      charge: function (invoice, payment_source, payment_type) {
        return this.invoiceApi.charge({
          id: invoice.id,
          payment_source_id: payment_source.id,
          payment_source_type: payment_type,
        })
      },
      prepareForAPI: function (invoice, options) {
        var _invoice = angular.copy(invoice)

        if (options) {
          if (options.excludeItems) {
            delete _invoice.items
          }
        }

        // remove non-attributes
        delete _invoice.set
        delete _invoice.create
        delete _invoice.update
        delete _invoice.updateBasicInfo
        delete _invoice.updateDetails
        delete _invoice.send
        delete _invoice.total
        delete _invoice.addNewItem
        delete _invoice.removeItemByIndex

        // convert camel-case to underscore attributes and remove all attributes with no values
        angular.forEach(_invoice, function (value, index) {
          if (value) {
            var newIndex = attributeConversionService.camelToUnderscore(index)
            _invoice[newIndex] = value

            if (newIndex !== index) {
              delete _invoice[index]
            }
          } else {
            delete _invoice[index]
          }
        })

        return _invoice
      },
    }
  },
])
