const visitorsModel = [
  'bzHttp',
  function (bzHttp) {
    return {
      createVisitor() {
        return bzHttp.post('/api/v3/visitors').then((res) => {
          if (res.success) {
            Cookie.set('buildzoom_visitor', res.visitor.id)
            return res.visitor
          }
        })
      },
    }
  },
]

export default visitorsModel
