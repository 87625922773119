/* global angular, $ */
angular.module('bzNavbarModule').factory('navbarService', [
  'localStorageModel',
  '$timeout',
  function (localStorageModel, $timeout) {
    return {
      openSelector: 'open-v2',
      shiftedForOpenSelector: 'page-shifted-for-expanded-photos',
      navInvisibleSelector: 'nav-transparent-invisible',
      navDropDownSectionSelection: '#masthead .dropdown',
      photoRibbonShownKey: 'photoRibbonShown',
      isShiftedForOpenSection: function () {
        return angular.element('body').is('.' + this.shiftedForOpenSelector)
      },
      shiftForOpenSection: function () {
        angular.element('body').addClass(this.shiftedForOpenSelector)
      },
      unshiftForOpenSection: function () {
        angular.element('body').removeClass(this.shiftedForOpenSelector)
      },
      openCurrentSection: function (element) {
        angular
          .element('.' + this.openSelector)
          .not(element)
          .removeClass(this.openSelector)
        element.addClass(this.openSelector)

        this.lightCurrentSectionHeader(element)
        this.dimOtherSectionHeaders(element)
      },
      openPhotosSection: function () {
        var photosSection = angular.element(
          this.navDropDownSectionSelection + '.dropdown-photos'
        )
        this.openCurrentSection(photosSection)
      },
      closeCurrentSection: function (element) {
        element.removeClass(this.openSelector)

        $timeout(
          function () {
            if (
              !angular.element(
                this.navDropDownSectionSelection + '.' + this.openSelector
              ).length
            ) {
              this.lightAllSectionHeaders()
            }
          }.bind(this),
          250
        )
      },
      closeAllSections: function () {
        angular
          .element(this.navDropDownSectionSelection)
          .removeClass(this.openSelector)
        this.lightAllSectionHeaders()
      },
      dimOtherSectionHeaders: function (element) {
        angular
          .element(this.navDropDownSectionSelection)
          .not(element)
          .find('.dropdown-toggle, .dropdown-link')
          .css({ opacity: 0.5 })
        angular
          .element('.non-dropdown-link')
          .find('.dropdown-link')
          .css({ opacity: 0.5 })
      },
      lightCurrentSectionHeader: function (element) {
        element.find('.dropdown-toggle, .dropdown-link').css({ opacity: 1 })
      },
      lightAllSectionHeaders: function () {
        this.lightCurrentSectionHeader(
          angular.element(this.navDropDownSectionSelection)
        )
        this.lightCurrentSectionHeader(angular.element('.non-dropdown-link'))
      },
      markPhotosSectionShown: function () {
        localStorageModel.set(this.photoRibbonShownKey, true)
      },
      isPhotosSectionMarkedShown: function () {
        return localStorageModel.get(this.photoRibbonShownKey)
      },
      isInvisibleNav: function () {
        return angular.element('header').hasClass(this.navInvisibleSelector)
      },
      toggleNavTransparency: function (threshold) {
        if ($(window).scrollTop() > threshold) {
          angular.element('header').removeClass(this.navInvisibleSelector)
        } else {
          angular.element('header').addClass(this.navInvisibleSelector)
        }
      },
    }
  },
])
