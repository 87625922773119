/* global bz_app, Track, angular */
bz_app.factory('trackCheckoutService', [
  function () {
    return {
      title: 'Checkout Action',
      defaultData: function (lead, input) {
        var platform = isMobile.check() ? 'mobile' : 'desktop'
        var data = {
          action: input.action ? input.action : '',
          service_request_id: lead.project.id,
          platform: platform,
          co_price: lead.outcome.contractorClaimingWinBidAmount,
          contractor_id: lead.contractor.id,
          impersonated: UserData.current_staff_user(),
          is_questionable_amount: lead.checkout.hasQuestionableBlock(),
        }

        data = angular.extend(data, input)

        return data
      },
      base: function () {
        return Track.impersonation_check()
      },
      showScreen: function (lead, screen) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'show ' + screen + ' screen' })
        )
      },
      closeScreen: function (lead, screen) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'close ' + screen + ' screen' })
        )
      },
      initiatedWinOnLeadsDashboard: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'leads dashboard initiated win' })
        )
      },
      initiatedOnLeadPage: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'lead page initiated win' })
        )
      },
      confirmedWin: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'confirmed win' })
        )
      },
      initiatedCheckout: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'checkout initiated' })
        )
      },
      confirmProjectPrice: function (lead, bid) {
        var data = this.defaultData(lead, { action: 'project price confirmed' })
        data.is_doc_uploaded = bid.attachments.length > 0
        this.base().send(this.title, data)
      },
      clickToViewProjectSummaryScreen: function (lead, screen) {
        var data = this.defaultData(lead, {
          action: 'click adjust project total on ' + screen + ' screen',
        })
        this.base().send(this.title, data)
      },
      clickToViewUploadContractScreen: function (lead, screen) {
        var data = this.defaultData(lead, {
          action:
            'click to view upload contract screen on ' + screen + ' screen',
        })
        this.base().send(this.title, data)
      },
      clickToOpenFileUploader: function (lead, screen) {
        var data = this.defaultData(lead, {
          action: 'click to open file uploader on ' + screen + ' screen',
        })
        this.base().send(this.title, data)
      },
      uploadToFileUploader: function (lead, screen) {
        var data = this.defaultData(lead, {
          action: 'upload to file uploader on ' + screen + ' screen',
        })
        this.base().send(this.title, data)
      },
      uploadToBZ: function (lead, screen) {
        var data = this.defaultData(lead, {
          action: 'upload to BZ on ' + screen + ' screen',
        })
        this.base().send(this.title, data)
      },
      removeFileOnBZ: function (lead, screen) {
        var data = this.defaultData(lead, {
          action: 'delete file on BZ on ' + screen + ' screen',
        })
        this.base().send(this.title, data)
      },
      showExitPrompt: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'exit modal shown' })
        )
      },
      returnToCheckoutFromExit: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, {
            action: 'return to checkout from exit prompt',
          })
        )
      },
      paidQuestionableAmount: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'paid questionable amount' })
        )
      },
      uploadedDocForQuestionablePrice: function (lead, bid) {
        this.base().send(
          this.title,
          this.defaultData(lead, {
            action: 'uploaded doc for questionable amount',
          })
        )
      },
      fixQuestionableAmount: function (lead) {
        var data = this.defaultData(lead, {
          action: 'fixed questionable amount',
        })
        data.is_doc_uploaded = lead.checkoutBid.attachments.length > 0
        this.base().send(this.title, data)
      },
      paidAdjustedProjectPrice: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'paid adjusted project price' })
        )
      },
      exit: function (lead) {
        this.base().send(
          this.title,
          this.defaultData(lead, { action: 'checkout abandoned' })
        )
      },
      complete: function (lead, paymentMethod, paymentStatus) {
        var data = this.defaultData(lead, { action: 'checkout completed' })
        data.payment_method = paymentMethod
        data.payment_status = paymentStatus
        data.payment_success = paymentStatus === 'succeeded'
        this.base().send(this.title, data)
      },
      addPaymentMethod: function (lead, paymentMethod) {
        var data = this.defaultData(lead, { action: 'payment method added' })
        data.payment_method = paymentMethod
        this.base().send(this.title, data)
      },
      deletedPaymentMethod: function (lead, paymentMethod) {
        var data = this.defaultData(lead, { action: 'payment method deleted' })
        data.payment_method = paymentMethod
        this.base().send(this.title, data)
      },
    }
  },
])
