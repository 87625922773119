import postReviewScreenDetailsGenerator from '../data/post_review_screen_details'

/* global bz_app, isMobile, angular */
angular.module('bzReviewsModule').component('postReviewStep', {
  bindings: {
    review: '=',
    contractor: '<',
    closeModal: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('reviews/post_review_step')
    },
  ],
  controller: [
    'reviewFormScreenService',
    'applicationConstants',
    'projectFormModalService',
    function (
      reviewFormScreenService,
      applicationConstants,
      projectFormModalService
    ) {
      this.$onInit = function () {
        this.isMobile = isMobile.check({ phoneOnly: true })
        this.contactNumber = applicationConstants.support.conciergePhone
        this.showContactNumber = false

        this.setPostReviewScreenDetails()
      }

      this.setPostReviewScreenDetails = function () {
        const postReviewScreenDetails = postReviewScreenDetailsGenerator(
          this.contractor
        )
        this.screenDetails =
          postReviewScreenDetails[this.review.getReviewQuality()][
            this.review.getReviewType()
          ]
      }

      this.openProjectFormModal = function () {
        this.closeModal()

        projectFormModalService.submitToModal({
          referralSource: 'post review screen (contractor profile)',
        })
      }
    },
  ],
})
