/* global angular, isMobile, Track */
angular
  .module('bzProjectPreferencesModule')
  .factory('trackProjectPreferencesService', function () {
    return {
      title: 'Project Preferences Action',
      basicAction: function (action, analytics, callback) {
        if (typeof callback === 'function') {
          Track.send_callback(
            this.title,
            this.defaultData(angular.extend({}, analytics, { action: action })),
            callback
          )
        } else {
          Track.send(
            this.title,
            this.defaultData(angular.extend({}, analytics, { action: action }))
          )
        }
      },
      submitModal: function (analytics, callback) {
        this.basicAction('submit modal', analytics, callback)
      },
      exitModal: function (analytics, callback) {
        this.basicAction('exit modal', analytics, callback)
      },
      openModal: function (analytics, callback) {
        this.basicAction('open modal', analytics, callback)
      },
      defaultData: function (input) {
        var data = {
          action: input.action ? input.action : '',
          type: input.type ? input.type : '',
          platform: isMobile.check({ phoneOnly: true }) ? 'mobile' : 'desktop',
          referral_source: input.referralSource || '',
        }

        return data
      },
    }
  })
