/* global angular, filepicker, $	*/

/*

WARNING:
THIS SERVICE IS DEPRECATED. PLEASE USE 'filestackService' if you can help it.

*/

angular.module('bzCommonUtilitiesModule').service('filepickerService', [
  'applicationConstants',
  'urlConstants',
  'urlService',
  function (applicationConstants, urlConstants, urlService) {
    this.options = {}

    this.isInit = false
    this.isLoading = false

    this.init = function (options) {
      if (!this.isInit && !this.isLoading) {
        this.setOptions(options)
        this.isLoading = true

        $.getScript(
          applicationConstants.filepicker.lib_url,
          function (data, textStatus, jqxhr) {
            filepicker.setKey(applicationConstants.filepicker.key)
            this.isInit = true
            this.isLoading = false
          }.bind(this)
        )
      }
    }

    this.initForHomeowner = function (userId, options) {
      if (userId) {
        this.init(
          Object.assign(
            {
              path: 'homeowner/project/' + userId.toString() + '/attachments/',
            },
            options
          )
        )
      }
    }

    this.initForContractor = function (contractorId, options) {
      if (contractorId) {
        this.init(
          Object.assign(
            {
              path:
                'contractor/' +
                contractorId.toString().substr(0, 4) +
                '/' +
                contractorId.toString() +
                '/',
            },
            options
          )
        )
      }
    }

    this.upload = function (callback) {
      filepicker.pickAndStore(
        {
          mimetype: this.options.mimetype ? this.options.mimetype : '*/*',
          folders: this.options.folders ? this.options.folders : true,
          multiple: this.options.multiple ? this.options.multiple : true,
          maxSize: this.options.maxSize ? this.options.maxSize : 31457280,
          services: [
            'CONVERT',
            'BOX',
            'COMPUTER',
            'DROPBOX',
            'EVERNOTE',
            'FACEBOOK',
            'GMAIL',
            'GOOGLE_DRIVE',
            'SKYDRIVE',
            'PICASA',
            'URL',
            'WEBCAM',
            'INSTAGRAM',
          ],
          conversions: ['crop', 'rotate', 'filter'],
          maxFiles: 15,
        },
        {
          location: this.options.location ? this.options.location : 'S3',
          path: this.options.path ? this.options.path : '',
        },
        function (InkBlobs) {
          if (callback !== undefined) {
            callback(InkBlobs)
          }
        }
      )
    }

    this.setOptions = function (options) {
      this.options = options ? options : {}
    }

    this.getCloudFrontUrl = function (s) {
      var url = urlService.strToUrl(s)
      if (url.pathname.substr(0, 1) !== '/') {
        return '//' + urlConstants.cloudfront.url + url.pathname
      } else if (url.pathname.indexOf('/api/file') > -1) {
        return (
          urlConstants.cloudfront.url.slice(0, -1) +
          url.pathname.replace('/api/file', '')
        )
      } else {
        return '//' + urlConstants.cloudfront.url.slice(0, -1) + url.pathname
      }
    }

    this.hashUrlFromCloudfrontSlug = function (cloudfront_slug) {
      return (
        'https://www.filepicker.io/api/file/' + cloudfront_slug.split('/').pop()
      )
    }

    this.savePhoto = function (data, callback) {
      var image_id = data.image_id
      var fp_url = this.hashUrlFromCloudfrontSlug(data.cloudfront_slug)
      return filepicker.convert(
        fp_url,
        { rotate: data.direction },
        function (modified) {
          filepicker.store(modified, function (ink) {
            $.ajax({
              url: data.renew_image_api,
              type: 'post',
              data: { id: image_id, slug: ink.url, s3_slug: ink.key },
              success: function (o_response, s_status) {
                callback(data)
              },
            })
          })
        }
      )
    }
  },
])
