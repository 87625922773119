import template from './unpaid_subscription_banner.html'

const unpaidSubscriptionBanner = {
  bindings: {
    bannerKey: '<',
    data: '<',
  },
  template,
  controller: [
    'urlConstants',
    'applicationConstants',
    'localStorageModel',
    'sessionStorageModel',
    'userBulletinModel',
    'contractorModel',
    'globalBannerTracking',
    function (
      urlConstants,
      applicationConstants,
      localStorageModel,
      sessionStorageModel,
      userBulletinModel,
      contractorModel,
      globalBannerTracking
    ) {
      this.$onInit = function () {
        this.contractor = this.data.contractor
        this.displayThis = true
        this.banner = {
          text:
            'Reminder: Renew your <a class="underlined white-link" href="' +
            urlConstants.dashboard.userSettingsURL +
            '">' +
            this.subscriptionBlockText() +
            '</a> subscription to maintain access to features such as better search rankings.',
          ctaLabel: 'Pay now',
          ctaUrl: urlConstants.dashboard.userSettingsURL,
          planBLabel: 'Cancel my subscription',
          action: 'modal',
        }
      }

      this.hideBanner = function () {
        userBulletinModel.create('contractor.unpaid_subscription')
        var snoozedBannerKey = 'snoozedBanner'
        sessionStorageModel.set(snoozedBannerKey, true)

        if (this.isMobile()) {
          this.displayThis = false
        } else {
          var $bannerDiv = angular.element('#unpaid-subscription-banner')
          $('html,body').animate({ scrollTop: $bannerDiv.height() })
          setTimeout(function () {
            $bannerDiv.remove()
          }, 500)
        }
      }

      this.subscriptionBlockText = function () {
        return this.contractor.unpaidSubscription.category.length
          ? this.contractor.unpaidSubscription.category
          : 'subscription'
      }

      this.leftAlignClass = function () {
        return this.contractor &&
          this.contractor.subscription &&
          !this.contractor.subscription
          ? 'left-align'
          : ''
      }

      this.redirectToCta = function () {
        globalBannerTracking.clickBannerCTA(this.bannerKey)
        document.location = this.banner.ctaUrl
      }

      this.isMobile = function () {
        return isMobile.check({ phoneOnly: true })
      }

      this.scrollPastBanner = function () {
        var $chevron = angular.element('.unpaid-subscription-scroll-to-here')
        $('html,body').animate({
          scrollTop: $chevron.offset().top + $chevron.height() - 10,
        })
      }

      this.cancelSubscription = function () {
        contractorModel.cancelSubscription(
          this.contractor.unpaidSubscription.id,
          this.contractor.id
        )
        globalBannerTracking.clickBannerCTA(this.bannerKey)
        this.displayThis = false
      }
    },
  ],
}

export default unpaidSubscriptionBanner
