/* global angular, isMobile */
angular.module('bzUserModule').component('claimBusinessSearch', {
  bindings: {
    claimContractor: '&?',
    showLogIn: '&?',
    shown: '<?',
    showFormTitle: '<',
    authVariation: '@?',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('users/claim_business_search')
    },
  ],
  controller: [
    'userLocationModel',
    'claimBusinessSearchModel',
    'attributeConversionService',
    'searchModel',
    '$element',
    '$timeout',
    '$scope',
    'authModalService',
    'urlConstants',
    function (
      userLocationModel,
      claimBusinessSearchModel,
      attributeConversionService,
      searchModel,
      $element,
      $timeout,
      $scope,
      authModalService,
      urlConstants
    ) {
      this.$onInit = function () {
        this.searchFields = {
          businessName: '',
          zipcode: userLocationModel.data.zipcode,
          page: 1,
        }

        this.errors = {
          businessName: null,
        }

        this.inputsChanged = false

        if (this.showFormTitle === undefined) {
          this.showFormTitle = true
        }

        this.nameField = $element.find('.claim-business-search-form-name')
        this.focusName()

        this.resultsHeight = 0
        this.resultsEl = $element.find('.claim-business-search-results-content')
      }

      this.focusName = function () {
        var watcher = $scope.$watch(
          '$ctrl.shown',
          function () {
            if (this.shown) {
              $timeout(this.nameField.focus.bind(this.nameField))
              watcher()
            }
          }.bind(this)
        )
      }

      this.submit = function () {
        if (this.inputsChanged) {
          this.search()
        } else {
          this.createContractor()
        }
      }

      this.search = function () {
        this.loading = true
        claimBusinessSearchModel.loadContractors(this.searchFields).then(
          function (res) {
            this.searchSubmitted = true
            this.contractorResults = res.contractors
            this.page = res.page
            this.totalPages = res.totalPages
            this.inputsChanged = false
            $timeout(
              function () {
                this.loading = false
                this.resultsHeight = this.resultsEl.height()
              }.bind(this)
            )
          }.bind(this)
        )
      }

      this.createContractor = function () {
        if (this.searchFields.businessName) {
          var contractorFields = {
            businessName: this.searchFields.businessName,
            zipcode: this.searchFields.zipcode,
          }

          this.claim(contractorFields)
        } else {
          this.errors.businessName = 'Please enter your business name'
        }
      }

      this.claim = function (contractor) {
        this.claimContractor
          ? this.claimContractor({ contractor: contractor })
          : this.triggerAuthScreen(contractor)
      }

      this.triggerAuthScreen = function (contractor) {
        if (isMobile.any()) {
          document.location =
            urlConstants.contractor.claimURL +
            (contractor.alias || '') +
            '&variation=landing&contractor_data=' +
            (contractor.alias ? '{}' : JSON.stringify(contractor))
        } else {
          authModalService.openModal({
            registerContractor: contractor,
            userType: 'contractor',
            variation: this.authVariation,
          })
        }
      }

      this.handleBusinessNameChange = function () {
        this.inputsChanged = true

        if (this.searchFields.businessName.length > 0) {
          this.errors.businessName = null
          this.setPage(1)
        }
      }

      this.handleZipcodeChange = function () {
        this.inputsChanged = true

        var zipLength = this.searchFields.zipcode.length
        if (zipLength > 4 || zipLength === 0) {
          this.setPage(1)
        }
      }

      this.setPage = function (page) {
        this.searchFields.page = page
        this.search()
      }
    },
  ],
})
