/* global angular */
angular.module('bzProjectFormModule').factory('projectFormData', function () {
  return {
    title: '',
    zipcode: '',
    urgency: '',
    fullName: '',
    email: '',
    phone: '',
    description: '',
    existingUserName: null,
    invitedContractors: {},
    inviteSelectedContractors: false,
    referralSource: null,
    reftag: null,
    potentialLeadContractorId: null,
    formType: null,
    set: function (input) {
      if (input) {
        if (input.title) {
          this.title = input.title
        }
        if (input.zipcode) {
          this.zipcode = input.zipcode
        }
        if (input.urgency) {
          this.urgency = input.urgency
        }
        if (input.fullName) {
          this.fullName = input.fullName
        }
        if (input.email) {
          this.email = input.email
        }
        if (input.phone) {
          this.phone = input.phone
        }
        if (input.description) {
          this.description = input.description
        }
        if (input.potentialLeadContractorId) {
          this.potentialLeadContractorId = input.potentialLeadContractorId
        }
        if (input.potentialLeadFormVersion) {
          this.potentialLeadFormVersion = input.potentialLeadFormVersion
        }
        if (input.potentialLeadContractorBusinessName) {
          this.potentialLeadContractorBusinessName =
            input.potentialLeadContractorBusinessName
        }
        if (input.referralSource) {
          this.referralSource = input.referralSource
        }
        if (input.formType) {
          this.formType = input.formType
        }
        if (input.reftag) {
          this.reftag = input.reftag
        }
      }
    },
  }
})
