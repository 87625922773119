/* global angular, bzGlobal */
angular.module('bzMapModule').directive('locationMapModalTrigger', [
  function () {
    return {
      restrict: 'AE',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        contractorAddress: '@',
      },
      controller: [
        '$scope',
        '$element',
        'modalScreenService',
        function ($scope, $element, modalScreenService) {
          this.$onInit = function () {
            $element.click(
              function ($event) {
                $event.preventDefault()

                this.showModal()
              }.bind(this)
            )
          }

          this.showModal = function () {
            this.mapModal = modalScreenService.init(
              $scope,
              [],
              '_components/directives/location_map_modal',
              'v3-modal-simple medium'
            )

            this.mapModal.openModal()
          }
        },
      ],
    }
  },
])
