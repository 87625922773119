/* global bz_app, UserData, gtag */
import { map, cloneDeep } from 'lodash'
import template from './bulk_data_application_form.html'
const bulkDataApplicationForm = {
  bindings: {
    close: '&?',
  },
  template,
  controller: [
    'dataApplicationModel',
    '$window',
    'trackDataApplications',
    function (dataApplicationModel, $window, trackDataApplications) {
      this.industryOptions = [
        'Real Estate',
        'Insurance',
        'Financial Services',
        'Other',
      ]
      this.baseFieldsList = [
        {
          element: 'input',
          label: 'Name',
          modelName: 'name',
          placeholder: 'John Smith',
          type: 'text',
          required: 'true',
        },
        {
          element: 'input',
          label: 'Business email address',
          modelName: 'email',
          placeholder: 'example@gmail.com',
          type: 'email',
          required: 'true',
        },
        {
          element: 'input',
          label: 'Company',
          modelName: 'company',
          placeholder: 'My Company Name',
          type: 'text',
          required: 'true',
        },
        {
          element: 'select',
          label: 'Industry',
          modelName: 'industry',
          name: 'industry',
          options: this.industryOptions,
          required: 'true',
        },
        {
          element: 'input',
          label: 'Phone number',
          modelName: 'phone',
          placeholder: '(000) 000-0000',
          type: 'tel',
          required: 'true',
        },
        {
          element: 'textarea',
          label: 'Please give us a short description on how our data can help',
          modelName: 'description',
          placeholder: 'We would love to partner with you!',
          required: 'true',
        },
      ]

      this.gtag_report_conversion = () => {
        if (!UserData.is_production_environment() || UserData.is_admin()) {
          return false
        }

        gtag('event', 'conversion', {
          send_to: 'AW-1008188915/cP4oCJrxj64BEPP73uAD',
          value: 100.0,
          currency: 'USD',
        })

        return true
      }

      this.$onInit = () => {
        this.currentFields = map(this.baseFieldsList, cloneDeep)
        this.rolesByIndustry = {
          'Real Estate': [
            'Realtor',
            'Individual Investor',
            'Property Management',
          ],
          Insurance: ['Underwriting', 'Claims', 'Analytics', 'Innovation'],
          'Financial Services': [
            'Lender',
            'Hedge Fund',
            'Institutional Investor',
          ],
          Other: [
            'Contractor',
            'Homeowner',
            'Student',
            'Municipal/Government',
            'Inspector',
            'Appraiser',
            'Legal',
            'Data Broker',
            'Other',
          ],
        }

        this.submitButtonText = 'Request access'
        this.header = 'Request for BuildZoom data access'
        this.subheader =
          "Thank you for your interest in our permit data. Please fill out the form below and we'll be in touch shortly."
        trackDataApplications.bulkApplicationFormOpened()
      }

      this.handleUpdate = (fieldName, value) => {
        if (fieldName === 'industry' && value !== undefined) {
          let roleIndex = this.getFieldIndexByLabel('Role')
          if (roleIndex !== -1) {
            this.currentFields.splice(roleIndex, 1)
          }
          let roleOptions = this.rolesByIndustry[value]
          let roleSelect = {
            element: 'select',
            label: 'Role',
            modelName: 'role',
            name: 'role',
            options: roleOptions,
            required: 'true',
          }
          let industryIndex = this.getFieldIndexByLabel('Industry')
          this.currentFields.splice(industryIndex + 1, 0, roleSelect)
        }
      }

      this.getFieldIndexByLabel = (fieldLabel) =>
        this.currentFields.findIndex((field) => field.label == fieldLabel)

      this.exit = () => {
        this.close ? this.close() : $window.history.back()
      }

      this.submit = (form) => {
        this.gtag_report_conversion()
        return dataApplicationModel.sendEmail(form)
      }
    },
  ],
}

export default bulkDataApplicationForm
