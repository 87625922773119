/* global angular, isMobile */
angular.module('bzProjectFormModule').component('similarContractorTile', {
  bindings: {
    contractor: '<',
    subtext: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/similar_contractor_tile'
      )
    },
  ],
  controller: [
    'urlService',
    'numberService',
    function (urlService, numberService) {
      this.$onInit = function () {
        this.utils = urlService
        this.numberService = numberService
      }
    },
  ],
})
