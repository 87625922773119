/* global angular */
angular.module('bzCommonUtilitiesModule').directive('scrollToElement', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        selector: '@',
        offset: '@',
      },
      controller: [
        '$element',
        function ($element) {
          this.$onInit = function () {
            $element.click(
              function () {
                angular.element('html,body').animate(
                  {
                    scrollTop: $(this.selector).offset().top - this.offset,
                  },
                  500
                )
              }.bind(this)
            )
          }
        },
      ],
    }
  },
])
