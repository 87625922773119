/* global angular */
angular.module('bzPotentialLeadFormModule').component('potentialLeadForm', {
  bindings: {
    contractor: '<',
    dismiss: '&',
    dismissRedirect: '<',
  },
  templateUrl: [
    'urlService',
    '$attrs',
    'potentialLeadFormService',
    function (urlService, $attrs, potentialLeadFormService) {
      switch (potentialLeadFormService.getFormVersion()) {
        case 3.4:
          return urlService.templateUrl(
            '_components/components/potential_leads/potential_lead_form_v3_4'
          )
        default:
          return urlService.templateUrl(
            '_components/components/potential_leads/potential_lead_form_v3_4'
          )
      }
    },
  ],
  controller: [
    '$timeout',
    'trackContractorContactService',
    'potentialLeadFormService',
    'potentialLeadFormModalService',
    'projectFormModalService',
    'projectFormData',
    'formValidationService',
    'potentialLeadModel',
    'attributeConversionService',
    'urlConstants',
    '$element',
    function (
      $timeout,
      trackContractorContactService,
      potentialLeadFormService,
      potentialLeadFormModalService,
      projectFormModalService,
      projectFormData,
      formValidationService,
      potentialLeadModel,
      attributeConversionService,
      urlConstants,
      $element
    ) {
      this.$onInit = function () {
        this.contractor =
          attributeConversionService.underscoreToCamelDeepAttributes(
            this.contractor
          )

        this.errorsOn = false
        this.currentScreen = 'is-project'
        this.potentialLead = potentialLeadFormService.newPotentialLead()

        this.setDismissRedirect()

        $timeout(function () {
          $element.find('.service-request-title-input').focus()
        })
      }

      this.openProjectModal = function ($event) {
        this.errorsOn = true

        if (!this.hasTitleError()) {
          if (!this.dismissRedirect) {
            this.dismiss()
          }

          projectFormData.set({
            email: this.potentialLead.email,
            fullName: this.potentialLead.name,
            phone: this.potentialLead.phone,
            description: this.potentialLead.message,
            potentialLeadFormVersion: potentialLeadFormService.getFormVersion(),
            potentialLeadContractorBusinessName: this.contractor.businessName,
            potentialLeadContractorId: this.contractor.nid,
          })

          projectFormModalService.submitToModal({
            referralSource:
              'potential lead form v' +
              potentialLeadFormService.getFormVersion(),
            inviteSelectedContractors: true,
          })

          trackContractorContactService.projectFormButtonClicked({
            contractor_id: this.contractor.nid,
            version: potentialLeadFormService.getFormVersion(),
          })
        }

        if ($event) {
          $event.preventDefault()
        }
      }

      this.showTitleForm = function () {
        this.currentScreen = 'title'
        this.errorsOn = false

        projectFormData.title = ''
        $timeout(function () {
          $element.find('#potential-lead-title').focus()
        }, 50)

        trackContractorContactService.aboutProjectButtonClicked({
          contractor_id: this.contractor.nid,
          version: potentialLeadFormService.getFormVersion(),
        })
      }

      this.showPotentialLeadInfoForm = function (sourceCta) {
        this.currentScreen = 'message-form'
        this.errorsOn = false

        trackContractorContactService.messageButtonClicked({
          contractor_id: this.contractor.nid,
          version: potentialLeadFormService.getFormVersion(),
          sourceCta: sourceCta || '',
        })
      }

      this.submitPotentialLead = function () {
        this.errorsOn = true

        if (this.hasTitleError(true)) {
          projectFormData.title = 'Question'
        }

        if (!this.hasFullFormError()) {
          this.sendingMessage = true
          potentialLeadModel
            .createMessage(
              angular.extend(this.potentialLead, {
                contractorId: this.contractor.nid,
                title: projectFormData.title,
                formVersion: potentialLeadFormService.getFormVersion(),
              })
            )
            .then(this.handlePotentialLeadSubmission.bind(this))
        }
      }

      this.handlePotentialLeadSubmission = function (response) {
        if (response.success) {
          this.sendingMessage = false
          this.currentScreen = 'done'

          trackContractorContactService.sendMessageClicked({
            contractor_id: this.contractor.nid,
            version: potentialLeadFormService.getFormVersion(),
          })

          $timeout(
            function () {
              this.dismiss()
            }.bind(this),
            2500
          )
        }
      }

      this.showPrevScreen = function () {
        switch (this.currentScreen) {
          case 'title':
          case 'message-form':
            this.currentScreen = 'is-project'
            break
        }
      }

      this.sendMessageButtonText = () => {
        return this.sendingMessage ? 'Sending...' : 'Send Message'
      }

      this.setDismissRedirect = function () {
        if (this.dismissRedirect) {
          this.dismiss = function () {
            document.location =
              urlConstants.contractor.profileURL + this.contractor.alias
          }
        }
      }

      this.messageText = function () {
        return this.contractor.score >= 70
          ? (this.contractor.businessName || this.contractor.business_name) +
              ' plus two of our best contractors.'
          : ' three of our best contractors.'
      }

      this.hasFullFormError = function () {
        return (
          this.hasTitleError() ||
          this.hasPotentialLeadInfoFormError() ||
          this.hasMessageError()
        )
      }

      this.hasTitleError = function (ignoreErrorSwitch) {
        return (this.errorsOn || ignoreErrorSwitch) && !projectFormData.title
      }

      this.hasPotentialLeadInfoFormError = function (ignoreErrorSwitch) {
        return (
          (this.errorsOn || ignoreErrorSwitch) &&
          (!this.potentialLead.name || this.hasEmailError(ignoreErrorSwitch))
        )
      }

      this.hasEmailError = function (ignoreErrorSwitch) {
        return (
          (this.errorsOn || ignoreErrorSwitch) &&
          !formValidationService.isValidEmail(this.potentialLead.email)
        )
      }

      this.hasMessageError = function (ignoreErrorSwitch) {
        return (
          (this.errorsOn || ignoreErrorSwitch) &&
          (!this.potentialLead.message || !this.potentialLead.message.length)
        )
      }

      this.messagePlaceholder = function () {
        return (
          'Provide some detail about your project to ' +
          (this.contractor.businessName || 'the contractor')
        )
      }

      this.isCurrentScreen = function (screen) {
        return this.currentScreen === screen
      }
    },
  ],
})
