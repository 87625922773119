/* global angular */
angular.module('bzNavbarModule').directive('navbarCloseBulletinMenus', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element) {
        element.click(function () {
          $rootScope.$broadcast('closeBulletinMenus')
        })
      },
    }
  },
])
