export default [
  function () {
    return {
      init: function () {
        let headerStyle = 'font-size:15px; color:#01a1dd; font-weight: bold'
        let regularStyle = 'font-size:12px; color:#01a1dd;'

        console.group('%cWe are hiring!', headerStyle)
        console.log(
          '%cMake BuildZoom even better by joining or recommending someone for our growing team!',
          regularStyle
        )
        console.log(
          '%cCheck out our careers page for details: https://jobs.lever.co/buildzoom?utm_campaign=po_referrals&utm_source=dev_console&utm_medium=web',
          regularStyle
        )
        console.groupEnd()
      },
    }
  },
]
