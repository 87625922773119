angular.module('bzProposalsModule').component('proposalsForm', {
  bindings: {
    formAuthToken: '<',
    proposalTemplates: '<',
    onDestroyTemplate: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('proposals/proposals_form')
    },
  ],
  controller: [
    function () {
      this.$onInit = function () {
        this.formItems = [
          {
            alias: 'blank',
            name: 'Blank',
            blank: true,
          },
          {
            alias: 'home',
            name: 'Custom Home Build',
            image: null,
          },
          {
            alias: 'kitchen',
            name: 'Kitchen Remodel',
            image: null,
          },
          {
            alias: 'bathroom',
            name: 'Bathroom Remodel',
            image: null,
          },
        ]
      }
    },
  ],
})
