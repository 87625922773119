/* global bz_app, angular */
angular.module('bzReviewsModule').component('detailsStep', {
  bindings: {
    review: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('reviews/details_step')
    },
  ],
  controller: [
    'reviewFormScreenService',
    'formValidationService',
    function (reviewFormScreenService, formValidationService) {
      this.$onInit = function () {
        this.submissionBlocked = false
        this.errors = {}
        this.customDatepickerOptions = { minDate: null }
      }

      this.updateDate = function (date) {
        this.review.startDate = date
      }

      this.submit = function () {
        if (this.isValid()) {
          this.submissionBlocked = false
          this.review.save().then(
            function (response) {
              if (response && response.success) {
                reviewFormScreenService.complete()
                reviewFormScreenService.goTo('post_review')
              }
            }.bind(this)
          )
        } else {
          this.submissionBlocked = true
        }
      }

      this.isValid = function () {
        return this.validateZipCode()
      }

      this.validateZipCode = function () {
        if (
          !!this.review.zipCode &&
          !formValidationService.isValidZipCode(this.review.zipCode)
        ) {
          this.errors.zipCode = 'Please provide a valid zip code.'
          return false
        } else {
          return true
        }
      }

      this.errorMessage = function () {
        if (this.errors.zipCode) {
          return this.errors.zipCode
        }
      }
    },
  ],
})
