/* global angular, UserData, isMobile */
angular.module('bzMarketingModule').service('partnershipsBannerService', [
  '$rootScope',
  '$compile',
  '$timeout',
  'landingPageService',
  function ($rootScope, $compile, $timeout, landingPageService) {
    return {
      init: function () {
        if (
          !isMobile.check() &&
          (!UserData.user_signed_in() || UserData.is_homeowner())
        ) {
          $timeout(
            function () {
              if (this.forceBanner() || this.visitedFrequently()) {
                this.showBanner()
              }
            }.bind(this),
            5000
          )
        }
      },
      showBanner: function () {
        if (!angular.element('partnerships-banner').length) {
          angular
            .element('body')
            .append(
              $compile('<partnerships-banner></partnerships-banner>')(
                $rootScope
              )
            )
        }

        $timeout(() => {
          angular.element('partnerships-banner').addClass('visible')
        }, 100)
      },
      forceBanner: function () {
        return ['/data'].includes(location.pathname)
      },
      visitedFrequently: function () {
        let impressionsByPage = landingPageService.getImpressionsByPage()
        return (
          impressionsByPage &&
          impressionsByPage.listing.count +
            impressionsByPage.property.count +
            impressionsByPage.profile.count >=
            50
        )
      },
    }
  },
])
