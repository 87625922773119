bz_app.factory('imageModel', function () {
  return {
    imageAPI: new ImageAPI(),
    data: {},
    show_contractor_best_photos: function (contractorIds, numPhotos) {
      return this.imageAPI.show_contractor_best_photos(contractorIds, numPhotos)
    },
    add_image: function (data) {
      return this.imageAPI.add_image(data)
    },
    load: function (image_id) {
      var that = this
      var image = this.imageAPI.load(image_id)
      image.then(function (response) {
        that.data = response
      })
      return image
    },

    delete_image: function (image, data) {
      var that = this
      if (image !== 'undefined') {
        that.image = image
      }

      return this.imageAPI.delete_image(this.image, data)
    },

    search_images: function (page, category) {
      //var that = this;
      var url = '/photos'
      if (typeof category != 'undefined') {
        url = url + '/' + category
      }
      url = url + '.js'
      if (typeof page != 'undefined') {
        url = url + '?page=' + page
      }
      return this.imageAPI.search_images(url)
    },
  }
})
