/* global $, BZAPI */
var LocationAPI = function () {
  this.load_by_current_ip = function () {
    return $.when($.get(this.location_api_path() + '/get_location_by_ip'))
  }

  this.load_by_zipcode = function (zipcode_data) {
    return $.when(
      $.ajax({
        url: this.location_api_path() + '/get_location_by_zipcode',
        type: 'GET',
        contentType: 'application/json',
        data: zipcode_data,
        dataType: 'json',
      })
    )
  }

  this.blocked_from_analytics = function () {
    return $.when($.get(this.location_api_path() + '/blocked_from_analytics'))
  }
}

LocationAPI.prototype = new BZAPI()
LocationAPI.prototype.constructor = LocationAPI

window.LocationAPI = LocationAPI
