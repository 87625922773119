angular.module('bzChartModule').directive('donutChart', [
  'urlService',
  '$timeout',
  function (urlService, $timeout) {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        percentile: '<?',
        stroke: '<?',
        size: '<?',
        duration: '<?',
        shouldTriggerAnimateOnLoad: '<?',
      },
      templateUrl: urlService.templateUrl('_components/directives/donut_chart'),

      controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
          this.$onInit = () => {
            // set max at 97 for visual purposes
            this.visualPercentile = Math.min(this.percentile, 97)
            this.fullRadius = this.size / 2
            this.innerRadius = this.fullRadius - this.stroke / 2

            this.increments = this.duration / (1000 / 60)
            this.incrementAmount = this.visualPercentile / this.increments

            this.primarySegment = $element.find('.primary-segment')

            if (isMobile.check()) {
              this.draw(this.visualPercentile)
            }

            if (this.shouldTriggerAnimateOnLoad) {
              this.delayedAnimate()
            }
          }

          this.animate = function () {
            this.extendSegment(0)
          }

          this.delayedAnimate = function () {
            this.draw(0)
            $timeout(
              function () {
                this.extendSegment(0)
              }.bind(this),
              600
            )
          }

          this.extendSegment = function (percentile) {
            this.draw(percentile)

            percentile += this.incrementAmount

            if (percentile < this.visualPercentile) {
              $timeout(
                this.extendSegment.bind(this, percentile),
                1000 / 60,
                false
              )
            }
          }

          this.draw = function (percentile) {
            var theta = (360 * percentile * 0.01 * Math.PI) / 180.0

            var x_from_origin = this.innerRadius * Math.sin(theta)
            var y_from_origin = this.innerRadius * Math.cos(theta)

            var sweep = percentile > 50 ? 1 : 0
            var x = x_from_origin + this.fullRadius
            var y = y_from_origin * -1 + this.fullRadius

            var d =
              'M' +
              this.fullRadius +
              ',' +
              this.stroke / 2 +
              ' A' +
              this.innerRadius +
              ',' +
              this.innerRadius +
              ' 0 ' +
              sweep +
              ',1 ' +
              x +
              ',' +
              y

            this.primarySegment.attr('d', d)
          }
        },
      ],
    }
  },
])
