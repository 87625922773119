/* global angular, $ */
const leadModel = [
  '$http',
  'bzHttp',
  'urlConstants',
  'attributeConversionService',
  'modelService',
  function (
    $http,
    bzHttp,
    urlConstants,
    attributeConversionService,
    modelService
  ) {
    return {
      apiLeadPath: urlConstants.api.v3.path + 'leads/',
      index: function (options) {
        var data = {
          params: options,
        }
        return $http.get(this.apiLeadPath, data)
      },
      update: function (serviceRequestId, lead) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(lead)
        return $http
          .put(this.apiLeadPath + serviceRequestId, data)
          .then(this.extractData)
      },
      respond: function (
        serviceRequestId,
        interested,
        explanation,
        contractorId,
        hash,
        force
      ) {
        return this.update(serviceRequestId, {
          interest: {
            interested: interested,
            explanation: explanation,
          },
          contractorId: contractorId,
          hash: hash,
          force: force,
        })
      },
      initiateWin: function (serviceRequestId, initiate) {
        initiate = initiate || true
        return this.update(serviceRequestId, { coInitiatedWin: initiate })
      },
      markHire: function (serviceRequestId, price, sourceInfo) {
        var coState = { status: 'won' }
        for (var property in sourceInfo) {
          if (sourceInfo.hasOwnProperty(property)) {
            coState[property] = sourceInfo[property]
          }
        }
        return this.setCoState(serviceRequestId, coState, price)
      },
      setOutcome: function (serviceRequestId, outcome, price) {
        return this.setCoState(serviceRequestId, { status: outcome }, price)
      },
      denyWin: function (serviceRequestId, data) {
        return $http
          .put(this.apiLeadPath + serviceRequestId + '/deny_win', data)
          .then(this.extractData)
      },
      setPrice: function (serviceRequestId, price) {
        return this.update(serviceRequestId, { price: price })
      },
      setNotes: function (serviceRequestId, notes) {
        return this.update(serviceRequestId, { notes: notes })
      },
      setCoState: function (serviceRequestId, coState, price) {
        var requestBody = {
          coState: coState,
        }

        if (price) {
          requestBody.price = price
        }

        return this.update(serviceRequestId, requestBody)
      },
      archive: function (serviceRequestId, explanation) {
        var state = {
          status: 'inactive',
          statusReason: explanation,
        }

        return this.setCoState(serviceRequestId, state)
      },
      loadPropertyStats: function (serviceRequestId) {
        return $http
          .get(this.apiLeadPath + serviceRequestId + '/property_stats')
          .then(this.extractData)
      },
      loadAliasNumber: function (leadId) {
        return $http
          .get(`${this.apiLeadPath}${leadId}/phone_alias`)
          .then(modelService.extractData)
      },
      recordBlockView: function (block) {
        if (block) {
          return $http.put(
            '/api/v1/contractor_blocks/' + block.id + '/record_view'
          )
        }
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
      setCheckoutPaymentSource: function (
        serviceRequestId,
        paymentSourceType,
        paymentSourceId
      ) {
        return $http.put(
          '/api/v1/checkouts/' + serviceRequestId,
          attributeConversionService.camelToUnderscoreDeepAttributes({
            paymentSourceType: paymentSourceType,
            paymentSourceId: paymentSourceId,
          })
        )
      },
      setBidReminder: function (serviceRequestId) {
        return $http.put(
          this.apiLeadPath + serviceRequestId + '/set_bid_reminder'
        )
      },
      setMeetingDate: function (serviceRequestId, date, sendReminder) {
        return bzHttp.put(
          this.apiLeadPath + serviceRequestId + '/set_meeting_date',
          { date, sendReminder }
        )
      },
      postponePoBidRequest: function (connectionId, dayDuration, source) {
        return $http.post(`/api/v3/${connectionId}/postpone_po_bid_request`, {
          day_duration: dayDuration,
          source,
        })
      },
    }
  },
]

export default leadModel
