angular.module('bzProjectLabelsModule').factory('projectLabelService', [
  '$rootScope',
  'projectLabelsModel',
  '$filter',
  function ($rootScope, projectLabelsModel, $filter) {
    return {
      updateServiceRequestListLabels: function (projectIds, label) {
        angular.forEach(projectIds, function (projectId) {
          angular
            .element('.project-' + projectId + '-label')
            .text(label.label_name)
          angular
            .element('.project-' + projectId + '-label')
            .attr('data-label_id', label.label_id)
        })
      },
      initDeleteLabelListener: function () {
        $rootScope.$on(
          'deleteLabelFromServiceRequestsList',
          function ($event, data) {
            angular
              .element(".project-label[data-label_id='" + data.labelId + "']")
              .text('')
          }
        )
      },
      selectedServiceRequestsToIds: function (selectedServiceRequests) {
        let projectIds = []

        angular.forEach(selectedServiceRequests, function (item, index) {
          projectIds.push(index)
        })

        return projectIds
      },
      updateServiceRequestLabels: function (selectedServiceRequests, labelId) {
        let projectIds = this.selectedServiceRequestsToIds(
          selectedServiceRequests
        )

        return projectLabelsModel
          .setProjectsLabel(labelId, projectIds)
          .then((response) => {
            if (response && response.success) {
              this.updateServiceRequestListLabels(projectIds, response)
              projectLabelsModel.load() // update nav list of labels counts
              return response
            }
          })
      },
      hasSelectedServiceRequests: function (selectedServiceRequests) {
        return $filter('filter')(selectedServiceRequests, true).length
      },
    }
  },
])
