import template from './progress_bar.html'

const ProgressBarModule = angular
  .module('progressBar', [])
  .component('progressBar', {
    bindings: {
      percentage: '<',
      barColor: '<',
      hasSquareEnds: '<?',
      isDisabled: '<',
    },
    template,
    controller: [
      function () {
        this.barCompletionStyle = () => {
          let style = { width: this.percentage + '%' }
          if (this.barColor) {
            style.backgroundColor = this.barColor
          }
          return style
        }
        this.className = 'rounded-ends'
        if (this.hasSquareEnds) {
          this.className = 'square-ends'
        }
      },
    ],
  }).name

export default ProgressBarModule
