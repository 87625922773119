/**    service provides contractor utils functionality    */
bz_app.factory('contractorService', [
  '$sce',
  '$filter',
  'urlConstants',
  'urlService',
  function ($sce, $filter, urlConstants, urlService) {
    return {
      parse_api_response: function (contractor, type) {
        if (!contractor.aggregate_review) {
          contractor.aggregate_review = { avg_stars: '0.0', total_reviews: 0 }
        }

        // clean ABOUT for new lines
        if (contractor.about && contractor.about.length > 0) {
          contractor.about = $sce.trustAsHtml(
            $filter('newlines_and_returns')(contractor.about)
          )
        }

        // pick out photo to use as cover
        if (contractor.listing_page_photo) {
          contractor.cover_url = contractor.listing_page_photo.url
          contractor.cover_url_generic = contractor.listing_page_photo.generic
            ? true
            : false
        } else if (contractor.thumbnails && contractor.thumbnails.length > 0) {
          contractor.cover_url = contractor.thumbnails[0].url
          contractor.cover_url_generic = contractor.thumbnails[0].generic
            ? true
            : false
        }

        // set type based on input
        if (type !== undefined) {
          contractor.type = type
        }

        return contractor
      },
      components_to_ids: function (components) {
        var contractor_ids = []

        components.forEach(function (contractor) {
          contractor_ids.push({ contractor_id: contractor.component_id })
        })

        return contractor_ids
      },
      id_array_to_ids: function (id_array) {
        return $.map(id_array, function (id) {
          return { contractor_id: id }
        })
      },
      contractor_array_to_ids: function (contractors) {
        var result_arr = []
        contractors.forEach(function (c) {
          result_arr.push({ contractor_id: c.id })
        })

        return result_arr
      },
      defaultMessage: function (homeowner, contractor, messagePrefix) {
        if (!messagePrefix) {
          messagePrefix = ''
        }

        return (
          this.defaultMessageSalutation(homeowner, '\n') +
          messagePrefix +
          this.defaultMessageBody(contractor) +
          this.defaultMessageSignature(contractor, '\n')
        )
      },
      defaultMeetingMessage: function (input) {
        if (input.options === undefined) {
          input.options = { separator: '\n', includeSignature: true }
        }

        var text =
          this.defaultMessageSalutation(
            input.homeowner,
            input.options.separator
          ) +
          this.defaultMeetingMessageBody(
            input.meetingFutureAction,
            input.text,
            input.options.separator
          )

        if (input.options.includeSignature) {
          text += this.defaultMessageSignature(
            input.contractor,
            input.options.separator
          )
        }

        return text
      },
      defaultMessageSalutation: function (homeowner, separator) {
        var intro = 'Greetings!'
        if (separator === undefined) {
          separator = '\n'
        }

        if (homeowner.first_name && homeowner.first_name.length) {
          intro = 'Hi ' + homeowner.first_name
        }

        return intro + ',' + separator + separator
      },
      defaultMessageBody: function (contractor, includeContactText) {
        includeContactText =
          includeContactText !== undefined ? includeContactText : true

        var message =
          "I'm writing on behalf of " +
          contractor.business_name +
          ". I'd like to give you a custom quote on the project you submitted through BuildZoom.\n\n"

        if (includeContactText) {
          if (contractor.pretty_phone_number) {
            message +=
              "If there's a specific time you'd like to talk or meet just let me know, or please call me at your earliest convenience: " +
              contractor.pretty_phone_number +
              '\n\n'
          } else {
            message +=
              "If there's a specific time you'd like to talk or meet just let me know, or please email me at your earliest convenience: " +
              contractor.email +
              '\n\n'
          }
        }

        message +=
          'In the meantime, check out our BuildZoom profile here: ' +
          "<a href='" +
          this.contractorProfileURL(contractor.alias) +
          "'>" +
          this.contractorProfileURL(contractor.alias) +
          '</a>\n\n' +
          'I look forward to hearing from you!'

        return message
      },
      defaultMeetingMessageBody: function (
        meetingFutureAction,
        meetingText,
        separator
      ) {
        return (
          "I'd like to speak with you about your project." +
          separator +
          separator +
          'I will ' +
          meetingFutureAction +
          ' at the time below.' +
          separator +
          separator +
          meetingText
        )
      },
      defaultMessageSignature: function (contractor, separator) {
        if (separator === undefined) {
          separator = '\n'
        }

        return (
          separator +
          separator +
          'Thanks,' +
          separator +
          contractor.business_name +
          separator +
          "<a href='" +
          this.contractorProfileURL(contractor.alias) +
          "'>" +
          this.contractorProfileURL(contractor.alias) +
          '</a>'
        )
      },
      contractorProfileURL: function (contractor_alias) {
        return urlConstants.contractor.profileURL + contractor_alias
      },
    }
  },
])
