/* global bz_app, angular, $ */
bz_app.factory('v2BidModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  'bzHttp',
  function ($http, urlConstants, attributeConversionService, bzHttp) {
    return {
      apiBidPath: urlConstants.api.v1.path + 'bids',
      create: function (bid) {
        return $http
          .post(this.apiBidPath, { bid: this.cleanData(bid) })
          .then(this.extractData)
      },
      update: function (bidId, bid) {
        return $http
          .put(this.apiBidPath + '/' + bidId, { bid: this.cleanData(bid) })
          .then(this.extractData)
      },
      destroy: function (bidId, inactive_reason) {
        return bzHttp.delete(this.apiBidPath + '/' + bidId, {
          params: { inactive_reason: inactive_reason },
        })
      },
      cleanData: function (bid) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(bid)

        var cleanData = {
          id: data.id,
          contractor_id: data.contractor_id,
          service_request_id: data.service_request_id,
          price: this.cleanPrice(data.price),
          start_date: data.start_date,
          end_date: data.end_date,
          description: data.description,
          attachments: data.attachments,
          bid_uploader_id: data.bid_uploader_id
            ? data.bid_uploader_id
            : data.contractor_id,
          bid_uploader_type: data.bid_uploader_type
            ? data.bid_uploader_type
            : 'Contractor',
          bid_type: data.bid_type,
          includes_finish_allowance: data.includes_finish_allowance,
        }

        if (!data.id) {
          delete cleanData.id
        }

        angular.forEach(cleanData.attachments, function (attachment) {
          attachment.image_id = attachment.id
          delete attachment.id
        })

        return cleanData
      },
      cleanPrice: function (price) {
        if (typeof price === 'string') {
          return price.replace(/[^\d.-]/g, '')
        } else {
          return price
        }
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
    }
  },
])
