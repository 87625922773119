import desktopTemplate from './business_user_fullscreen_desktop.html'
import mobileTemplate from './business_user_fullscreen_mobile.html'
import footerTemplate from './business_user_fullscreen_footer.html'
import { templateForDevice } from 'Vanilla/helpers/templates'

export default {
  bindings: {
    contractor: '<',
  },
  template: templateForDevice(desktopTemplate, mobileTemplate) + footerTemplate,
  controller: [
    'modalScreenNavigationService',
    'trackClaimScreenService',
    'urlConstants',
    function (
      modalScreenNavigationService,
      trackClaimScreenService,
      urlConstants
    ) {
      this.$onInit = () => {
        this.claimReferralSource = 'profile-claim-v2'

        if (this.isMobileVersion()) {
          this.screenService = modalScreenNavigationService.init({
            screens: ['account'],
            initialScreen: 'account',
          })
        } else {
          this.screenService = modalScreenNavigationService.init({
            screens: ['business', 'account'],
            initialScreen: 'business',
          })
        }

        this.trackScreenView()
        this.screenService.setScreenCallbacks.push(
          this.trackScreenView.bind(this)
        )
        this.submittingClaimContractor = false
        this.externalSubmitTrigger = 1

        this.tooltip = {
          title: 'Millions of property owners visit BuildZoom every year.',
          body: 'We send qualified and legitimate projects to contractors on our platform.<br/><br/><strong>Grow your business.</strong>',
        }

        this.errors = {
          businessName: false,
        }
      }

      this.next = () => {
        switch (this.screenService.currentScreen) {
          case 'business':
            if (this.contractor.businessName) {
              this.errors.businessName = false
              this.screenService.showNextScreen()
            } else {
              this.errors.businessName = true
            }
            break
          case 'account':
            if (this.isMobileVersion()) {
              if (this.contractor.businessName) {
                this.errors.businessName = false

                this.submittingClaimContractor = true
                this.externalSubmitTrigger++
              } else {
                this.errors.businessName = true
              }
            } else {
              this.submittingClaimContractor = true
              this.externalSubmitTrigger++
            }
            break
        }
      }

      this.back = () => {
        this.screenService.showPrevScreen()
      }

      this.onClaimContractor = (response) => {
        this.submittingClaimContractor = false

        if (response && response.success) {
          trackClaimScreenService.claimProfileSubmitted(
            this.contractor,
            function () {
              document.location = urlConstants.contractor.profileSetupURL
            }.bind(this)
          )
        }
      }

      this.onClaimContractorFail = () => {
        this.submittingClaimContractor = false
      }

      this.isNextButtonDisabled = () => {
        return this.submittingClaimContractor
      }

      this.nextButtonText = () => {
        if (this.submittingClaimContractor) {
          return 'submitting...'
        } else {
          return this.screenService.isLastScreen() ? 'done' : 'next'
        }
      }

      this.trackScreenView = () => {
        trackClaimScreenService.claimViewStep(
          this.contractor,
          this.screenService.currentScreen,
          'standard'
        )
      }

      this.claimFormVersion = (version) => {
        return this.contractor.analytics.claimFormVersion === version
      }

      this.isMobileVersion = () => {
        return this.contractor.analytics.isMobile
      }
    },
  ],
}
