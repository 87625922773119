angular.module('bzCommonUtilitiesModule').factory('stepSequenceService', [
  function (urlService, $uibModal) {
    return {
      init: function (steps, initialStep, onSwitch) {
        /*
					steps:
						['step_key', 'step_key2', ...] or
						[{
							key: 'step_key',
							excludeProgress: boolean
							...other keys to be passed to onSwitch
						},  ...]
				*/

        steps = steps.map(function (step) {
          if (typeof step === 'string') {
            return { key: step }
          } else if (!step.key) {
            throw new Error(
              'You must provide argument steps as an array of strings or as an array of objects with a key property'
            )
          }

          return step
        })

        var currentStepIndex = 0

        if (initialStep) {
          var stepIndex = steps.findIndex(function (step) {
            return step.key === initialStep
          })

          if (stepIndex) {
            currentStepIndex = stepIndex
          }
        }

        function switchStepCallback(previousStepIndex) {
          if (onSwitch) {
            onSwitch(steps[currentStepIndex], steps[previousStepIndex])
          }
        }

        return {
          steps: () => steps,

          nextStep: function () {
            if (this.isLastStep()) {
              return false
            }

            currentStepIndex++
            switchStepCallback(currentStepIndex - 1)

            return true
          },

          previousStep: function () {
            if (this.isFirstStep()) {
              return false
            }

            currentStepIndex--
            switchStepCallback(currentStepIndex + 1)

            return true
          },

          goToStepKey: function (stepKey) {
            var found = false
            const previousStepIndex = currentStepIndex
            steps.forEach(function (step, ind) {
              if (step.key === stepKey) {
                currentStepIndex = ind
                found = true
              }
            })

            switchStepCallback(previousStepIndex)
            return found
          },

          goToStepIndex: function (stepIndex) {
            currentStepIndex = stepIndex
          },

          currentStepKey: function () {
            return steps[currentStepIndex].key
          },

          isStep: function (stepKey) {
            return this.currentStepKey() === stepKey
          },

          isLastStep: function () {
            return currentStepIndex === steps.length - 1
          },

          isFirstStep: function () {
            return currentStepIndex === 0
          },

          getCurrentIndex: function () {
            return currentStepIndex
          },

          getProgess: function () {
            var progressSteps = steps.filter(function (step) {
              return !step.excludeProgess
            })

            var indexInProgressSteps = progressSteps.findIndex(function (step) {
              return step.key === steps[currentStepIndex].key
            })

            return Math.round(
              ((indexInProgressSteps + 1) / progressSteps.length) * 100
            )
          },
        }
      },
    }
  },
])
