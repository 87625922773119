/* global angular, bzGlobal, isMobile */
angular.module('bzProjectFormModule').factory('projectFormModalService', [
  '$rootScope',
  '$timeout',
  'projectFormData',
  function ($rootScope, $timeout, projectFormData) {
    return {
      submitToModal: function (options) {
        projectFormData.inviteSelectedContractors =
          options.inviteSelectedContractors || false
        $timeout(() => {
          angular
            .element('.signup-modal')
            .first()
            .trigger('click', {
              referral_source: options.referralSource,
              zipcode: options.zipcode,
              title: projectFormData.title || options.title,
              urgency: projectFormData.urgency,
              email: projectFormData.email,
              fullName: projectFormData.fullName,
              phone: projectFormData.phone,
              potentialLeadFormVersion:
                projectFormData.potentialLeadFormVersion,
              potentialLeadContractorBusinessName:
                projectFormData.potentialLeadContractorBusinessName,
              potentialLeadContractorId:
                projectFormData.potentialLeadContractorId,
              contractor_id: options.contractorId,
            })
        }, 0)
      },
      variation: function () {
        return (bzGlobal && bzGlobal.variation) || ''
      },
    }
  },
])
