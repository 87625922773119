bz_app.directive('collapsedMessages', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'AE',
      replace: true,
      template:
        '<div class="conversation-message-collapsed-count" ng-show="messageCount">{{ messageCount }} more messages</div>',
      scope: {
        messageCount: '=',
      },
      link: function (scope, element) {
        if (scope.messageCount) {
          angular
            .element('.conversation-message-collapsed')
            .last()
            .after(element)
          angular
            .element('.message-content-truncated .message-expand-link')
            .hide()
          element.show()

          // clicking on collapsed message summary expands all
          element.click(function () {
            angular.element('.conversation-message-collapsed').show()
            angular
              .element('.message-content-truncated .message-expand-link')
              .show()
            element.remove()

            $rootScope.$broadcast('conversation-messages-expanded')
          })
        }
      },
    }
  },
])
