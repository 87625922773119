angular
  .module('bzProjectPreferencesModule')
  .factory('projectPreferencesModel', [
    '$http',
    '$q',
    'projectPreferencesData',
    'applicationConstants',
    function ($http, $q, projectPreferencesData, applicationConstants) {
      let contractorTypesDeferred
      let projectTypesDeferred
      return {
        data: projectPreferencesData,
        getSearchContractorProjectTypes: function (cache) {
          if (cache) {
            this.data.setSearchContractorProjectTypes(
              applicationConstants.contractorAndProjectTypesList,
              false
            )
          } else {
            cache = false
          }

          if (
            !cache ||
            (cache &&
              !this.data.searchContractorProjectTypesPending &&
              (!this.data.searchContractorProjectTypes ||
                !this.data.searchContractorProjectTypes.length))
          ) {
            return this.getContractorAndProjectTypes('list')
          }
        },
        getContractorTypes: function () {
          if (!contractorTypesDeferred) {
            contractorTypesDeferred = $q.defer()
            $http
              .get('/api/v3/contractor_project_preferences/contractor_types')
              .then(function (data) {
                contractorTypesDeferred.resolve(data.data.contractor_types)
              })
          }

          return contractorTypesDeferred.promise
        },
        getProjectTypes: function () {
          const topProjectTypes = [
            'custom-homes',
            'bathroom-remodel',
            'kitchen-remodel',
            'whole-home-remodeling',
            'multi-family-remodeling',
            'room-addition',
            'solar-installation',
            'restaurant-renovation',
            'office-renovation',
          ]

          if (!projectTypesDeferred) {
            projectTypesDeferred = $q.defer()
            $http
              .get('/api/v3/contractor_project_preferences/project_types')
              .then((data) => {
                let projectTypes = data.data.project_types
                const queriedSearchTerm =
                  window.location.pathname.split('/projects/')[1]
                projectTypes.forEach((projectType) => {
                  if (
                    queriedSearchTerm &&
                    (projectType.slug.includes(queriedSearchTerm) ||
                      projectType.slug.includes(
                        queriedSearchTerm.substring(
                          0,
                          queriedSearchTerm.length / 2
                        )
                      ))
                  ) {
                    projectType.display_order = 0
                  } else if (topProjectTypes.includes(projectType.slug)) {
                    projectType.display_order = 1
                  }
                })

                projectTypes.sort((a, b) => a.display_order - b.display_order)
                projectTypesDeferred.resolve(projectTypes)
              })
          }

          return projectTypesDeferred.promise
        },
        getContractorAndProjectTypes: function (format) {
          return $http
            .get(
              '/api/v3/contractor_project_preferences/contractor_and_project_types_flat_list'
            )
            .then(
              function (response) {
                this.data.setSearchContractorProjectTypes(
                  response.data.contractor_and_project_types_list,
                  true
                )
              }.bind(this)
            )
        },
        getProjectTypesByContractorTypeId: function (contractorTypeId) {
          return $http
            .get(
              '/api/v3/contractor_project_preferences/taxonomy_for_contractor_type/' +
                contractorTypeId
            )
            .then(function (data) {
              return data.data.taxonomy.project_type_groups
            })
        },
        getTaxonomyForContractorByType: function (
          contractorId,
          contractorTypeId
        ) {
          return $http
            .get(
              '/api/v3/contractor_project_preferences/taxonomy_for_contractor_by_type/' +
                contractorId +
                '?contractor_type_id=' +
                contractorTypeId
            )
            .then(function (data) {
              return data.data.taxonomy.project_type_groups
            })
        },
        getTaxonomy: function () {
          return $http
            .get('/api/v3/contractor_project_preferences/taxonomy')
            .then(function (data) {
              return data.data.taxonomy.project_type_groups
            })
        },
      }
    },
  ])
