/**	service provides dashboard UI	*/
bz_app.factory('dashboardUIService', [
  'conversationModel',
  function (conversationModel) {
    return {
      updateUnreadCountUI: function () {
        conversationModel.getUnreadCount().then(function (response) {
          // update inbox unread count
          if (response) {
            if (response.unread > 0) {
              angular
                .element('.conversations-unread-count')
                .text(response.unread)
              angular
                .element('.user-dashboard-link-messages-badge')
                .text(response.unread)
              angular
                .element('.user-dashboard-dropdown-messages-badge')
                .text(response.unread)
            } else {
              angular.element('.conversations-unread-count').hide()
              angular.element('.user-dashboard-link-messages-badge').hide()
              angular.element('.user-dashboard-dropdown-messages-badge').hide()
            }
          }
        })
      },
      setSubnavSelection: function (conversations_status) {
        angular
          .element('ul.list-sub-nav li a')
          .removeClass('list-sub-nav-a-selected')
        angular
          .element(
            "ul.list-sub-nav li a[data-conversations-status='" +
              conversations_status +
              "']"
          )
          .addClass('list-sub-nav-a-selected')
      },
      setSubnavInteraction: function () {
        if (isMobile.check()) {
          // show/hide main content and sub-nav when in messages angularJS view
          angular.element('ul.sub-nav-messages li a').off('click') // clear any previously set click event listeners
          angular.element('ul.sub-nav-messages li a').click(function () {
            angular.element(this).closest('.col-sub-nav').hide()
            angular.element('.col-viewport').show()
          })
        }
      },
      scrollConversationToTop: function () {
        angular.element('html, body').animate({ scrollTop: 0 })
      },
      scrollConversationToBottom: function () {
        angular
          .element('.col-viewport')
          .animate({ scrollTop: $('.col-viewport .section-content').height() }) // scroll to bottom of message list
      },
    }
  },
])
