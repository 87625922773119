/* global bz_app */
import Geocoder from 'Vanilla/services/geocoder'

angular
  .module('bzProjectPreferencesModule')
  .component('projectPreferencesSubmit', {
    bindings: {
      contractorProfile: '<',
      projectPreferencesUpdateLocation: '@',
      disabled: '<',
      onSaveSuccess: '&',
    },
    template:
      '<div class="form-actions">' +
      '<button ng-disabled="$ctrl.disabled" ng-click="$ctrl.submitForm($event);" type="submit" id="contractor_services_submit_button" data-disable-with="Saving..." class="btn modern-button modern-dark-blue-button">Save</button>' +
      '</div>',
    controller: [
      '$element',
      'contractorProjectPreferencesModel',
      'trackProfileService',
      '$timeout',
      'attributeConversionService',
      'formValidationService',
      'locationService',
      '$scope',
      function (
        $element,
        contractorProjectPreferencesModel,
        trackProfileService,
        $timeout,
        attributeConversionService,
        formValidationService,
        locationService,
        $scope
      ) {
        this.$onInit = function () {
          this.submitButton = $element.find('button')

          this.geocoder = new Geocoder()
          this.geocoder.recordContractorLocationIfValid(this.contractorProfile)
        }

        this.attemptGeocodeBeforeSubmit = () => {
          this.contractorProfile.lat = null
          this.contractorProfile.lng = null

          return this.geocoder
            .geocode(
              this.geocoder.queryFromAddressParts(this.contractorProfile)
            )
            .then((result) => {
              if (
                this.geocoder.isContractorMatchingGeocode(
                  this.contractorProfile,
                  result
                )
              ) {
                this.geocoder.assignContractorGeocodeResult(
                  this.contractorProfile,
                  result
                )
                $scope.$applyAsync()
              }
            })
        }

        this.submitForm = function (event) {
          event.preventDefault()
          this.submitButton.attr('disabled', 'disabled')

          this.attemptGeocodeBeforeSubmit().finally(() => {
            let data =
              attributeConversionService.camelToUnderscoreDeepAttributes(
                this.contractorProfile
              )
            data.project_preferences_update_location =
              this.projectPreferencesUpdateLocation

            contractorProjectPreferencesModel
              .update(data)
              .then(() => {
                this.saveSubmitTextAnimate('Saved')
                trackProfileService.servicesUpdated()
                if (typeof this.onSaveSuccess === 'function') {
                  this.onSaveSuccess()
                }
              })
              .catch(() => {
                this.saveSubmitTextAnimate('Did Not Save')
              })
          })
        }

        this.saveSubmitTextAnimate = function (message) {
          this.submitButton.attr('disabled', false)
          this.submitButton.html(message)
          $timeout(
            function () {
              this.submitButton.html('Save')
            }.bind(this),
            5000
          )
        }
      },
    ],
  })
