const DashboardMenu = function () {
  this.init = function () {
    this.initHeaderMenuNav()
    this.initSubNav()
    this.initMainContent()
  }

  this.initHeaderMenuNav = () => {
    if (getURLParameter('menu')) {
      this.showSubnav(`#${getURLParameter('menu')}`)

      setTimeout(() => {
        $('.sub-nav-visible').removeClass('sub-nav-visible')
        $('.col-viewport').removeClass('hidden')
      }, 500)
    }
  }

  this.initSubNav = function () {
    // take users to main nav from sub nav
    $('.mobile-main-nav-link').click((e) => {
      $('#modern-nav-dropdown-toggle').click()
      e.preventDefault()
    })
  }

  this.initMainContent = function () {
    $('.mobile-menu-back-link').click((e) => {
      this.showSubnav($(e.currentTarget).data('target'))
      e.preventDefault()
    })
  }

  this.showSubnav = (selector) => {
    $(selector).addClass('show-left').parent().show()
    $('.col-viewport').hide()
    this.showTop()
  }

  this.showTop = function () {
    $('html, body').animate({ scrollTop: 0 })
  }

  this.goTo = function (url) {
    if (window.history && history.pushState) {
      history.pushState({}, '', url)
    }
  }
}

$(function () {
  let dashboard_menu = new DashboardMenu()
  dashboard_menu.init()
})
