/*global bz_app*/
import template from './generic_field.html'

const genericField = {
  bindings: {
    attributes: '<',
    errorText: '<',
    updateField: '&',
  },
  template,
  controller: [
    function () {
      this.$onInit = () => {
        Object.assign(this, this.attributes)
        this.updateField({ fieldName: this.modelName, value: this.model })
      }

      this.inputType = () =>
        this.element === 'input' ? this.type : this.element

      this.isRequired = () => this.required

      this.onChange = () => {
        this.updateField({ fieldName: this.modelName, value: this.model })
      }
    },
  ],
}

export default genericField
