window.browser = {
  isChrome: function () {
    return !!navigator.userAgent.match(/Chrome|CriOS/i)
  },
  isSafari: function () {
    return !!navigator.userAgent.match(/Safari/i) && !browser.isChrome()
  },
  isFirefox: function () {
    return !!navigator.userAgent.match(/Firefox/i)
  },
  isExplorer: function () {
    return !!navigator.userAgent.match(/Explorer/i)
  },
}
