import { zipObj, type, identity, ifElse, is, map, keys, values } from 'ramda'

const toCamel = ifElse(
  is(String),
  (str) =>
    str.replace(/(_[a-z])/g, (seg) => seg.toUpperCase().replace('_', '')),
  identity
)

function toProperCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
  )
}

const toUnderscore = ifElse(
  is(String),
  (str) =>
    str
      .replace(/\W+/g, '_')
      .replace(/([a-z\d])([A-Z])/g, '$1_$2')
      .toLowerCase(),
  identity
)

const deepConvertObj = (mod, obj) =>
  zipObj(
    map(mod, keys(obj)),
    map((val) => deepConvert(mod, val), values(obj))
  )

const deepConvertArray = (mod, arr) => map((val) => deepConvert(mod, val), arr)

const deepConvertMethod = (thing) =>
  ({
    Object: deepConvertObj,
    Array: deepConvertArray,
  }[type(thing)] || (() => thing))

const deepConvert = (mod, thing) => deepConvertMethod(thing)(mod, thing)

const deepConvertToCamel = (thing) => deepConvert(toCamel, thing)

const deepConvertToUnderscore = (thing) => deepConvert(toUnderscore, thing)

export {
  deepConvertToCamel,
  deepConvertToUnderscore,
  toCamel,
  toUnderscore,
  toProperCase,
}
