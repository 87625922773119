/* global angular, isMobile */
angular.module('bzNavbarModule').directive('sectionDropDown', [
  'navbarService',
  function (navbarService) {
    return {
      restrict: 'AE',
      scope: {},
      link: function (scope, element) {
        if (!isMobile.check()) {
          element.mouseover(function () {
            if (!element.hasClass(navbarService.openSelector)) {
              navbarService.openCurrentSection(element)
            }
          })

          element.mouseout(function () {
            if (!navbarService.isShiftedForOpenSection()) {
              navbarService.closeCurrentSection(element)
            }
          })
        }
      },
    }
  },
])
