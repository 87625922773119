export default (contractor) => {
  const POSTED_SUBHEADER_TEXT =
    "Thank you for your review. You've done your community a service. We evaluate thousands of reviews and maintain a strong commitment to the truth. Before we can publish, we will reach out to verify your email address and may ask more questions - please be on the lookout for that email."
  const POSTED_CTA_BUTTON_TEXT = 'Submit your project'

  return {
    bad: {
      posted: {
        title: 'Your voice has been heard.',
        subheader: POSTED_SUBHEADER_TEXT,
        bodyHeader: 'Can we help you find a great contractor?',
        ctaButtonText: POSTED_CTA_BUTTON_TEXT,
      },
      connected: {
        title: "We're sorry you had a bad experience.",
        subheader: `This is unacceptable and we expect better of our contractors. Your review will affect ${contractor.businessName}\'s access to new BuildZoom projects.`,
        bodyHeader: `Can we help you find another contractor to replace ${contractor.businessName}?`,
        ctaButtonText: 'Request another contractor',
      },
      hired: {
        title: "We're sorry you had a bad experience.",
        subheader: `This is unacceptable and we expect better of our contractors. Your review will affect ${contractor.businessName}\'s access to new BuildZoom projects.`,
        bodyHeader:
          'If you’d like another contractor to fix your project, please give us a call.',
        showContactNumber: true,
      },
    },
    good: {
      posted: {
        title: 'Thanks for your feedback.',
        subheader: POSTED_SUBHEADER_TEXT,
        bodyHeader: 'Can we help you find a great contractor?',
        ctaButtonText: POSTED_CTA_BUTTON_TEXT,
      },
      connected: {
        title: 'We want to find you someone awesome.',
        subheader:
          'Thank you for your feedback! We strive for perfection and want you to have an amazing experience with the contractors we recommend. Looks like we have room for improvement.',
        bodyHeader: `Can we help you find another contractor to replace ${contractor.businessName}?`,
        ctaButtonText: 'Request another contractor',
      },
      hired: {
        title: 'Thank you for your feedback.',
        subheader:
          'We strive for perfection and want you to have an amazing experience with your contractor. Looks like we have room for improvement.',
        bodyHeader: `Whenever you’re ready to start another project, we hope we can help you find someone even better than ${contractor.businessName}.`,
        ctaButtonText: 'Start another project',
      },
    },
    great: {
      posted: {
        title: 'Congratulations!',
        subheader: POSTED_SUBHEADER_TEXT,
        bodyHeader: 'Do you have another project we can help with?',
        ctaButtonText: POSTED_CTA_BUTTON_TEXT,
      },
      connected: {
        title: 'Nice!',
        subheader: `We\’re so glad you liked ${contractor.businessName}! Let us know who you hire so we can review your contract to ensure it provides you with standard protections.`,
        bodyHeader:
          'Are you happy with all of your recommended contractors or would you like another option?',
        ctaButtonText: 'Request another contractor',
      },
      hired: {
        title: 'Congratulations!',
        subheader: `We’re so glad you liked ${contractor.businessName}. :)`,
        bodyHeader:
          'Whenever you’re ready to start another project, we hope we can help you again!',
        ctaButtonText: 'Start another project',
      },
    },
  }
}
