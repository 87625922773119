angular.module('bzProjectPreferencesModule').factory('projectPreferencesData', [
  '$rootScope',
  '$timeout',
  function ($rootScope, $timeout) {
    return {
      searchContractorProjectTypes: [],
      classifications: [],
      commonServices: [],
      searchContractorProjectTypesPending: false,
      setSearchContractorProjectTypes: function (input, isDataAsync) {
        if (isDataAsync === undefined) {
          isDataAsync = true
        }

        if (isDataAsync) {
          this.searchContractorProjectTypesPending = true
          $timeout(
            function () {
              $rootScope.$apply(
                function () {
                  this.processSearchContractorProjectTypes(input)
                }.bind(this)
              )
            }.bind(this)
          )
        } else {
          this.processSearchContractorProjectTypes(input)
        }
      },
      processSearchContractorProjectTypes: function (input) {
        input.forEach(function (item) {
          // listingPage.itemType = 'listingPage';
          item.name = item.name + ' ' // hack added to properly show top suggestions on click of input
        })

        this.searchContractorProjectTypes = input
        this.searchContractorProjectTypesPending = false
      },
      setCommonServices: function (input) {
        $timeout(
          function () {
            $rootScope.$apply(
              function () {
                this.commonServices = input
              }.bind(this)
            )
          }.bind(this)
        )
      },
    }
  },
])
