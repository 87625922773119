/* global angular */
angular.module('bzProjectFormModule').component('informationLoadingScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/information_loading_screen'
      )
    },
  ],
  controller: [
    '$scope',
    'v2ServiceRequestModel',
    'localStorageModel',
    'trackProjectFormService',
    function (
      $scope,
      v2ServiceRequestModel,
      localStorageModel,
      trackProjectFormService
    ) {
      this.$onInit = () => {
        const { projectType, summary, location } = this.project

        const lowerCaseProjectName =
          projectType && projectType.name && projectType.name.toLowerCase()

        this.progress = 0
        this.projectCity = (location && location.city) || 'you'
        this.projectTitle = (summary.title && summary.title.toLowerCase()) || ''
        this.projectType = lowerCaseProjectName
          ? `${lowerCaseProjectName} projects`
          : 'this type of work'
        this.currentStepKey = 'tools'
        this.loadedItems = ['tools']
        const breakEl = '<br class="hidden-sm hidden-xs">'
        this.itemData = [
          {
            stepKey: 'tools',
            descriptionText: `Reviewing ${breakEl}Project`,
            duration: 1,
            filteredContractorCount: undefined,
          },
          {
            stepKey: 'id-card',
            descriptionText: `Verifying ${breakEl}Licenses`,
            duration: 2,
            filteredContractorCount: undefined,
          },
          {
            stepKey: 'location',
            descriptionText: `Searching ${breakEl}Your Area`,
            duration: 3,
            filteredContractorCount: undefined,
          },
          {
            stepKey: 'bookmark',
            descriptionText: `Evaluating ${breakEl}Work History`,
            duration: 2.5,
            filteredContractorCount: undefined,
          },
          {
            stepKey: 'headphones',
            descriptionText: `Notifying Your ${breakEl}Project Consultant`,
            duration: 3,
            filteredContractorCount: undefined,
          },
        ]

        if (this.project.location.city) {
          v2ServiceRequestModel
            .getLoadingScreenStatistics(this.project.id)
            .then((resp) => {
              this.totalContractors = resp.stats.contractorsInLargeRadius
              this.generateContractorCountsForSteps(resp.stats).forEach(
                (contractorCount, ind) =>
                  (this.itemData[ind].filteredContractorCount = contractorCount)
              )

              this.totalContractors = this.itemData[0].filteredContractorCount
              this.finalContractorCount =
                this.itemData[this.itemData.length - 2].filteredContractorCount
              localStorageModel.set(
                'loadingScreenNumberOfMatches',
                this.finalContractorCount
              )

              this.beginLoadingAnimation()
            })
        } else {
          this.beginLoadingAnimation()
        }
        trackProjectFormService.viewInformationLoadingScreen()
      }

      this.generateContractorCountsForSteps = ({
        contractorsInLargeRadius,
        contractorsInMediumRadius,
        contractorsInMediumRadiusWithPermits,
        projectTypeContractorsInMediumRadius,
      }) => {
        return [
          {
            inputData: contractorsInLargeRadius,
            min: 659,
            max: 12400 + Math.random() * 100,
          },
          {
            inputData: contractorsInMediumRadius,
            min: 103,
            max: 3550 + Math.random() * 100,
          },
          {
            inputData: contractorsInMediumRadiusWithPermits,
            min: 68,
            max: 940 + Math.random() * 100,
          },
          {
            inputData: projectTypeContractorsInMediumRadius,
            min: 23,
            max: 127 + Math.random() * 50,
          },
        ].map((contractorStepConfig, ind, contractorStepConfigs) => {
          const { inputData, min, max } = contractorStepConfig
          let filteredContractorCount = inputData
          if (ind !== 0) {
            const prevFilteredContractorCount =
              contractorStepConfigs[ind - 1].filteredContractorCount
            if (
              !filteredContractorCount ||
              filteredContractorCount >= prevFilteredContractorCount
            ) {
              filteredContractorCount =
                prevFilteredContractorCount / (Math.random() * 5 + 1)
            }
          }

          contractorStepConfig.filteredContractorCount = this.scalarWithinRange(
            filteredContractorCount,
            min,
            max,
            20
          )
          return contractorStepConfig.filteredContractorCount
        })
      }

      this.scalarWithinRange = (num, min, max, fuzzFactor) => {
        let finalNum = num
        let fuzzerNumber = Math.random() * fuzzFactor
        if (finalNum < min) {
          finalNum = min - fuzzerNumber
        } else if (finalNum > max) {
          finalNum = max + fuzzerNumber
        }

        return Math.floor(finalNum)
      }

      this.beginLoadingAnimation = () => {
        this.loadedItems = []
        this.scheduleLoadingSteps(
          this.itemData,
          (stepKey, ind, { filteredContractorCount, duration }) => {
            this.currentStepKey = stepKey
            this.loadedItems.push(stepKey)
            if (filteredContractorCount) {
              this.transitionContractorAmount(filteredContractorCount, duration)
            }
          },
          this.redirectToSr
        )
      }

      this.isActive = (item) => this.loadedItems.includes(item)
      this.isMostRecentActive = (item) =>
        this.loadedItems[this.loadedItems.length - 1] === item

      this.redirectToSr = () => {
        document.location = '/project/' + this.project.id
      }

      this.setContractorAmount = (el, newValue) => {
        el.text(newValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'))
      }

      this.transitionContractorAmount = (newNumber, duration) => {
        const amountEl = $('.approved-contractor-amount')
        if (!amountEl.text()) {
          this.setContractorAmount(amountEl, newNumber)
        }

        let currentValue = parseInt(amountEl.text().replace(/,/g, ''))
        const fps = 60
        const secondsToNewNumber = duration - 0.7
        const shrinkAmount = Math.max(
          1,
          Math.floor((currentValue - newNumber) / (secondsToNewNumber * fps))
        )

        const frameInterval = setInterval(() => {
          if (currentValue > newNumber) {
            currentValue -= 1 * shrinkAmount
            this.setContractorAmount(amountEl, currentValue)
          } else {
            this.setContractorAmount(amountEl, newNumber)
            clearInterval(frameInterval)
          }
        }, 1000 / fps)

        setTimeout(() => {
          clearInterval(frameInterval)
        }, duration * 1000)
      }

      this.scheduleLoadingSteps = (schedule, onEachStep, onFinish, delay) => {
        const totalTime = schedule.reduce(
          (total, { duration }) => total + duration,
          0
        )
        let currentScheduledItemIndex = 0

        const scheduleNextItem = (scheduleItem) => {
          $scope.$applyAsync(() => {
            onEachStep(
              scheduleItem.stepKey,
              currentScheduledItemIndex,
              scheduleItem
            )

            this.progress += (100 / totalTime) * scheduleItem.duration
          })

          const loadingLoop = setTimeout(() => {
            currentScheduledItemIndex++
            if (currentScheduledItemIndex < schedule.length) {
              scheduleNextItem(schedule[currentScheduledItemIndex])
            } else {
              if (onFinish) onFinish()
              clearInterval(loadingLoop)
            }
          }, scheduleItem.duration * 1000)
        }

        setTimeout(
          () => scheduleNextItem(schedule[currentScheduledItemIndex]),
          delay
        )
      }
    },
  ],
})
