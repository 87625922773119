/* global angular */
angular.module('bzUserModule').component('impersonationBanner', {
  bindings: {
    impersonationData: '<?',
    revertUrl: '@',
    revertCopy: '@',
    formAuthToken: '@',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('users/impersonation_banner')
    },
  ],
  controller: [
    'attributeConversionService',
    'userModel',
    function (attributeConversionService, userModel) {
      this.$onInit = function () {
        this.impersonationData =
          attributeConversionService.underscoreToCamelDeepAttributes(
            this.impersonationData
          )

        window.addEventListener('focus', this.fetchImpersonationData.bind(this))
      }

      this.currentStaffUser = function () {
        return this.impersonationData.currentStaffUser
      }

      this.currentUser = function () {
        return this.impersonationData.currentUser
      }

      this.currentUserContractor = function () {
        return this.impersonationData.currentUserContractor
      }

      this.isImpersonating = function () {
        return !!this.currentStaffUser()
      }

      this.fetchImpersonationData = function () {
        userModel.getImpersonationData().then(
          function (response) {
            if (response.success) {
              this.impersonationData = response.impersonationData
            }
          }.bind(this)
        )
      }

      this.revertText = function () {
        return this.submitting ? 'Reverting...' : this.revertCopy
      }

      this.onSubmit = function ($event) {
        this.submitting && $event.preventDefault()
        this.submitting = true
      }
    },
  ],
})
