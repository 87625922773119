const bulkDataApplicationButton = [
  '$uibModal',
  function ($uibModal) {
    return {
      restrict: 'AE',
      scope: {},
      link: function (scope, element, attrs) {
        element.on('click', () => {
          if (isMobile.check({ phoneOnly: true })) {
            document.location = '/data/apply'
          } else {
            scope.modalInstance = $uibModal.open({
              component: 'bulkDataApplicationForm',
              windowClass: 'v3-modal-simple full-width',
            })
          }
        })
      },
    }
  },
]

export default bulkDataApplicationButton
