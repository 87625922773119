/* global angular, history, bugsnagClient */
angular.module('bzCommonUtilitiesModule').factory('urlService', [
  '$sce',
  'applicationConstants',
  'urlConstants',
  'mediaHelperService',
  function ($sce, applicationConstants, urlConstants, mediaHelperService) {
    return {
      templateUrl: function (filePath, extension, raw) {
        if (extension === '-') {
          extension = ''
        } else if (!extension) {
          extension = '.html'
        } else {
          extension = '.' + extension
        }

        if (raw === undefined) {
          raw = false
        }

        var url =
          '/assets/' +
          filePath +
          extension +
          '?v=' +
          applicationConstants.templateVersion
        if (raw) {
          return url
        } else {
          return $sce.trustAsResourceUrl(url)
        }
      },
      templateUrlForDevice: function (
        filePath,
        extension,
        raw,
        tabletIsMobile
      ) {
        var flagValue = true
        if (tabletIsMobile === true) {
          flagValue = false
        }
        return this.templateUrl(
          filePath +
            '_' +
            (isMobile.check({ phoneOnly: flagValue }) ? 'mobile' : 'desktop'),
          extension,
          raw
        )
      },
      impersonateUrl: function (isClaimed, id) {
        if (isClaimed) {
          return urlConstants.user.impersonate + id
        } else {
          return urlConstants.user.impersonateUnclaimedContractor + id
        }
      },
      eventUrl: function ($event) {
        return $event.target.href
      },
      imgCropUrl: function (url, image_size) {
        if (url === undefined || url === null || !url.length) return ''

        const filestackID = mediaHelperService.urlToFilestackId(url)
        if (filestackID) {
          return mediaHelperService.filestackImageUrl(filestackID, {
            sizeLabel: image_size,
          })
        }

        // Fall back to images.buildzoom.com in case the url is not a filepicker id. We want
        // to track occurrences of this though so we can weed them out.
        bugsnagClient.notify('non-filestack URL passed to imgCropUrl: ' + url)
        return url + this.imagesCdnAddOn(image_size)
      },
      imagesCdnAddOn: function (image_size) {
        var result = '/convert?'
        result +=
          'w' +
          '=' +
          (applicationConstants.imageSizes[image_size]['width'] || '')
        result += '&'
        result +=
          'h' +
          '=' +
          (applicationConstants.imageSizes[image_size]['height'] || '')
        result +=
          bzGlobal.applicationConstants.imageHelpers
            .imageCdnAddonSettings40Quality

        return result
      },
      absoluteUrl: function (relativeUrl) {
        return location.origin + relativeUrl
      },
      strToUrl: function (s) {
        var parser = document.createElement('a')
        parser.href = s
        return parser
      },
      setUrl: function (url) {
        if (history.pushState != null) {
          history.pushState({}, '', url)
          return true
        } else {
          document.location = url
          return false
        }
      },
      homepageUrl: function () {
        return document.location.protocol + '//' + document.location.host
      },
      contactContractorUrl: function (contractorAlias) {
        return '/contractor/' + contractorAlias + '/contact'
      },
    }
  },
])
