const messageAttachmentModel = [
  'filepickerService',
  'filestackService',
  'uploadModel',
  '$q',
  function (filepickerService, filestackService, uploadModel, $q) {
    return {
      attach: function (callback, options) {
        options = options || {}

        if (options.version === 2) {
          return this.attachV2(callback)
        } else if (options.version === 3) {
          return this.attachV3(options)
        } else {
          this.attachV1(callback)
        }
      },
      attachV1: function (callback) {
        filepickerService.upload(
          function (InkBlobs) {
            // bring up upload modal
            if (InkBlobs && InkBlobs.length) {
              var count = 0
              var files = []

              angular.forEach(
                InkBlobs,
                function (file) {
                  var attachment = uploadModel.inkBlobToAttachment(file)
                  attachment.imageType = 'message'

                  uploadModel.add(attachment).then(
                    function (response) {
                      if (response) {
                        attachment.id = response.id
                        files.push(attachment)
                        count++

                        if (
                          count === InkBlobs.length &&
                          callback !== undefined
                        ) {
                          callback(files)
                        }
                      }
                    }.bind(this)
                  )
                }.bind(this)
              )
            }
          }.bind(this)
        )
      },
      attachV2: function (callback) {
        return filestackService.pickAndUpload().then(function (result) {
          if (result && result.filesUploaded && result.filesUploaded.length) {
            var files = []

            angular.forEach(
              result.filesUploaded,
              function (file) {
                var attachment = uploadModel.inkBlobToAttachment(file)
                attachment.imageType = 'message'

                uploadModel.add(attachment).then(
                  function (response) {
                    if (response) {
                      attachment.id = response.id
                      files.push(attachment)

                      if (
                        files.length === result.filesUploaded.length &&
                        callback !== undefined
                      ) {
                        callback(files)
                      }
                    }
                  }.bind(this)
                )
              }.bind(this)
            )
          }

          return result
        })
      },
      attachV3: function ({ files }) {
        var uploadedFiles = []
        var deferred = $q.defer()

        angular.forEach(files, (selectedFile) => {
          filestackService.upload(selectedFile).then((file) => {
            var attachment = uploadModel.inkBlobToAttachment(file)
            attachment.imageType = 'message'

            uploadModel.add(attachment).then((response) => {
              if (response) {
                attachment.id = response.id
                uploadedFiles.push(attachment)

                if (uploadedFiles.length === files.length) {
                  deferred.resolve(uploadedFiles)
                }
              }
            })
          })
        })

        return deferred.promise
      },
    }
  },
]

export default messageAttachmentModel
