/* global angular, UserData */
import MessageRedirectHelper from 'Vanilla/services/message_redirect_helper'
import { get } from 'lodash'

angular.module('bzNavbarModule').component('navbarMessages', {
  bindings: {
    inbox: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('_components/navbar/navbar_messages')
    },
  ],
  controller: [
    'urlService',
    'urlConstants',
    'trackNavbarActionsService',
    function (urlService, urlConstants, trackNavbarActionsService) {
      this.redirectToProjectUrl = function ($event, conversation) {
        let contractorId = get(conversation, 'contractor.nid')
        this.projectUrlHelper = new MessageRedirectHelper(
          urlConstants,
          conversation,
          conversation.serviceRequestId,
          contractorId
        )

        trackNavbarActionsService.messageClick(
          function () {
            this.projectUrlHelper.redirectToProjectUrl()
          }.bind(this)
        )

        $event.preventDefault()
      }

      this.redirectToMessagesUrl = function ($event) {
        this.projectUrlHelper = new MessageRedirectHelper(urlConstants)
        trackNavbarActionsService.allMessageClick(
          function () {
            this.projectUrlHelper.redirectToMessageTabUrl()
          }.bind(this)
        )

        $event.preventDefault()
      }

      this.isMessageReply = function (conversation) {
        return conversation.sendingUser.id !== conversation.lastMessage.senderId
      }

      this.isDesktop = () => !isMobile.check()

      this.showMessage = (conversation) =>
        conversation.messageType != 'Email' ||
        (conversation.messageType == 'Email' && this.isDesktop())

      this.participantNameString = (conversation) => {
        if (conversation.sendingUser.name) {
          return `${conversation.messageType} from ${conversation.sendingUser.name}`
        }
        return `${conversation.messageType}`
      }
    },
  ],
})
