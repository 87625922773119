/* global angular */
angular.module('bzUserModule').service('emailPasswordFormService', [
  'applicationConstants',
  'urlConstants',
  'authValidationService',
  '$q',
  function (applicationConstants, urlConstants, authValidationService, $q) {
    return {
      supportPhone: function () {
        return applicationConstants.support.crPhone
      },

      resetPasswordLink: function () {
        return urlConstants.user.resetPassword
      },

      termsLink: function () {
        return urlConstants.site.tosURL
      },

      // all validation methods return promise
      validate: function (fields, logIn, checkCoEmail) {
        return $q
          .all([
            this.validateEmail(fields.email, logIn, checkCoEmail),
            this.validatePassword(fields.password),
          ])
          .then(function (responses) {
            return { email: responses[0], password: responses[1] }
          })
      },

      validateEmail: function (email, logIn, checkCoEmail) {
        return authValidationService.validateEmail(email, !logIn, checkCoEmail)
      },

      validatePassword: function (password) {
        return authValidationService.validatePassword(password)
      },
    }
  },
])
