/* globals LocationAPI */
angular.module('bzProjectFormModule').factory('projectFormModel', [
  'projectFormData',
  function (projectFormData) {
    return {
      data: projectFormData,
      locationApi: new LocationAPI(),
      locationDetailsByIp: function () {
        return this.locationApi.load_by_current_ip()
      },
      locationDetailsByZipcode: function (zipcode) {
        return this.locationApi.load_by_zipcode({ zipcode: zipcode })
      },
      getLocationDetails: function (callback) {
        var location = UserData.data('location')
        if (!location.slug) {
          this.locationDetailsByZipcode(this.data.zipcode).then(
            function (zipLocationDetails) {
              if (!zipLocationDetails.slug) {
                this.locationDetailsByIp().then(
                  function (ipLocationDetails) {
                    callback(ipLocationDetails)
                  }.bind(this)
                )
              } else {
                callback(zipLocationDetails)
              }
            }.bind(this)
          )
        } else {
          callback(location)
        }
      },
    }
  },
])
