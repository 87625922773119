bz_app.directive('projectForm', [
  'applicationConstants',
  'urlService',
  function (applicationConstants, urlService) {
    return {
      restrict: 'AE',
      templateUrl: urlService.templateUrl('property/directives/project_form'),
      scope: {
        referralSource: '@',
        titleLabel: '@',
        urgencyLabel: '@',
        cssClass: '@',
        submitButtonCssClass: '@',
        submitButtonText: '@',
      },
      link: function (scope, element, attrs) {
        if (!scope.submitButtonText) scope.submitButtonText = 'get started'
      },
    }
  },
])
