bz_app.component('refundButton', {
  bindings: {
    lead: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('checkouts/refund_button')
    },
  ],
  controller: [
    '$scope',
    'checkoutModel',
    'modalScreenService',
    function ($scope, checkoutModel, modalScreenService) {
      this.$onInit = function () {
        this.submitted = false
      }

      this.openRefundModal = function () {
        this.refundConfirmed = false
        this.refundConfirmationModal = modalScreenService.init(
          $scope,
          ['confirmation'],
          '_components/refund_modal',
          'v3-modal-simple small',
          this.handleModalClose.bind(this),
          {}
        )
        this.refundConfirmationModal.setScreen('confirmation')
      }

      this.confirmRefund = function () {
        this.refundConfirmed = true
        this.refundConfirmationModal.dismissModal()
      }

      this.handleModalClose = function () {
        if (this.refundConfirmed) {
          this.submit()
        }
      }

      this.submit = function () {
        var refund = {
          service_request_id: this.lead.project.id,
          contractor_id: this.lead.contractor.id,
        }
        checkoutModel.refund(refund).then(
          function (response) {
            this.submitted = true
            if (response.data.success) {
              this.success = true
            } else {
              this.success = false
            }
          }.bind(this)
        )
      }
    },
  ],
})
