const communicationPreferenceModel = [
  'urlConstants',
  'modelService',
  'bzHttp',
  function (urlConstants, modelService, bzHttp) {
    const API_PATH = urlConstants.api.v1.path + 'communication_preferences/'

    return {
      show: function (communicableId, options) {
        return bzHttp.get(
          API_PATH + communicableId + modelService.queryString(options)
        )
      },
      update: function (communicationPreference) {
        return bzHttp.patch(
          API_PATH + communicationPreference.communicableId,
          communicationPreference
        )
      },
      updateIndividualPreference: function (
        preferenceKey,
        medium,
        isSubscribed
      ) {
        return bzHttp.post(API_PATH + 'update_individual_preference', {
          preferenceKey,
          medium,
          isSubscribed,
        })
      },
    }
  },
]

export default communicationPreferenceModel
