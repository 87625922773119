/**	bookmark functionality for contractors and photos	*/
var Bookmark = function () {
  this.user_signed_in = false
  this.project_api = new ProjectAPI()
  this.user_api = new UserAPI()

  if (UserData.user_signed_in() == true) this.user_signed_in = true
}

Bookmark.factory = function () {
  var user_signed_in = false

  if (UserData.user_signed_in()) {
    user_signed_in = true
  }

  if (user_signed_in) {
    if (Bookmark.is_contractor_galleries_page()) {
      new ContractorBookmark().init()
    }

    if (Bookmark.is_photo_search_page()) {
      new PhotoSearchBookmark().init() // thumbnail & modal
    }

    if (this.is_contractor_gallery_page()) {
      new ContractorGalleryBookmark().init()
    }
  }
}

Bookmark.factory_generic = function () {
  if (this.is_photo_search_page('generic')) {
    new PhotoSearchGenericBookmark().init()
  }

  if (Bookmark.is_contractor_galleries_page()) {
    $('.contractor-header-action-buttons').show()
  }
}

// initialization when user is logged in and has at least 1 project
Bookmark.prototype.init = function () {
  //bookmark children implement this method
}

Bookmark.prototype.project_data = function () {
  return $('#project_data')
}

Bookmark.prototype.is_photo_bookmarked_for_project = function (
  project_id,
  photo_ids,
  callback
) {
  if (this.user_signed_in) {
    if (this.project_data().length > 0) {
      this.project_api
        .is_photo_bookmarked(project_id, photo_ids)
        .then(function (res) {
          if (callback !== undefined) {
            if (res.bookmarked_images !== undefined)
              callback(res.bookmarked_images)
            else callback(null)
          }
        })
    }
  }
}

Bookmark.prototype.redirect_to_sign_in = function () {
  document.location = 'user/sign_in'
}

/**	create generic project and bookmark passed in photo to project	*/
Bookmark.prototype.create_project_with_photo = function (photo_id, callback) {
  var that = this
  this.project_api
    .create({
      name: 'My remodeling project',
      service: { id: 88, name: 'remodeling' },
    })
    .then(function (res) {
      if (res.success) {
        // set new project id in cookie
        Cookie.set_current_project_id(res.project_id)

        // create bookmark for clicked photo
        that.project_api
          .create_photo_bookmark(res.project_id, [{ image_id: photo_id }])
          .then(function (res) {
            if (callback !== undefined) callback(res)
          })
      }
    })
}

Bookmark.prototype.toggle_photo_bookmark = function (
  bookmark,
  project_id,
  photo_id,
  callback
) {
  if (bookmark.hasClass('selected')) {
    this.project_api
      .delete_photo_bookmark(project_id, photo_id)
      .then(function () {
        bookmark.removeClass('selected')

        if (callback !== undefined) callback('remove')
      })
  } else {
    this.project_api
      .create_photo_bookmark(project_id, [{ image_id: photo_id }])
      .then(function () {
        bookmark.addClass('selected')
        if (callback !== undefined) callback('add')
      })
  }
}

Bookmark.prototype.make_photo_id_array = function (thumbnails) {
  var photo_ids = []

  $.each(thumbnails, function (index, thumbnail) {
    photo_ids.push({ image_id: $(thumbnail).data('image_id') })
  })

  return photo_ids
}

Bookmark.is_contractor_profile_page = function () {
  if ($('body').hasClass('contractors') && $('body').hasClass('show'))
    return true
  else return false
}

/**	check page is a contractor profile gallery page of any type	*/
Bookmark.is_contractor_galleries_page = function () {
  if (
    Bookmark.is_contractor_all_galleries_page() ||
    Bookmark.is_contractor_gallery_page()
  )
    return true
  else return false
}

/**	check page is a contractor profile individual gallery page	*/
Bookmark.is_contractor_gallery_page = function () {
  if ($('body').hasClass('contractors_photos') && $('body').hasClass('show'))
    return true
  else return false
}

/**	check page is the directory of galleries for a contractor profile	*/
Bookmark.is_contractor_all_galleries_page = function () {
  if ($('body').hasClass('contractors_photos') && $('body').hasClass('index'))
    return true
  else return false
}

Bookmark.is_photo_search_page = function (type) {
  if ($('body').hasClass('search_images') && $('body').hasClass('index')) {
    if (type === undefined || type == 'generic') return true
    else return false
  } else return false
}

Bookmark.prototype.photo_bookmark_template = function (css_class) {
  return '<div class="' + css_class + '"><i class="fas fa-bookmark"></i></div>'
}

/**	INIT LISTENERS	*/

// load bookmarks after project nav is loaded
$('body').on('project_data_loaded', function () {
  Bookmark.factory()
})

// load generic bookmark logic after project nav says it will not be loaded
$('body').on('project_data_not_loaded', function () {
  Bookmark.factory_generic()
})

window.Bookmark = Bookmark
export default Bookmark
