/* global angular, Track, isMobile */
angular
  .module('bzPotentialLeadFormModule')
  .factory('trackContractorContactService', [
    function () {
      return {
        title: 'Contact Contractor Action',
        defaultData: function (options) {
          return angular.extend(
            {
              action: '',
              platform: isMobile.check() ? 'mobile' : 'desktop',
            },
            options
          )
        },
        contactButtonClicked: function (options) {
          var data = angular.extend(options, {
            action: 'contact us button clicked',
          })
          Track.send(this.title, this.defaultData(data))
        },
        projectFormButtonClicked: function (options) {
          var data = angular.extend(options, {
            action: 'project form button clicked',
          })
          Track.send(this.title, this.defaultData(data))
        },

        messageButtonClicked: function (options) {
          var data = angular.extend(options, {
            action: 'message button clicked',
          })
          Track.send(this.title, this.defaultData(data))
        },
        sendMessageClicked: function (options) {
          var data = angular.extend(options, {
            action: 'send message clicked',
          })
          Track.send(this.title, this.defaultData(data))
          Track.sendGa(this.title, data.action, '')
          Track.sendBing(this.title, data.action)
        },
        aboutProjectButtonClicked: function (options) {
          var data = angular.extend(options, {
            action: 'this is a project button clicked',
          })
          Track.send(this.title, this.defaultData(data))
        },
        titleFormSubmitted: function () {
          Track.send(
            this.title,
            this.defaultData({
              action: 'title form submitted',
            })
          )
        },
      }
    },
  ])
