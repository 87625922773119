bz_app.controller('DenyWinModalCtrl', [
  'inputData',
  '$uibModalInstance',
  'trackLeadsService',
  'leadModel',
  'dateService',
  'urlConstants',
  function (
    inputData,
    $uibModalInstance,
    trackLeadsService,
    leadModel,
    dateService,
    urlConstants
  ) {
    this.$onInit = function () {
      this.lead = inputData.lead
      this.denyWinReason = null
    }

    this.submitModal = function () {
      this.submitButtonClicked = true
      if (this.denyWinReason) {
        this.denyWin()
        this.closeModal()
      }
    }

    this.submitButtonDisabled = function () {
      return this.submitButtonClicked || !this.denyWinReason
    }

    this.closeModal = function () {
      $uibModalInstance.close('ok')
    }

    this.denyWin = function () {
      this.lead.denyWin(this.denyWinReason)
    }

    this.hiredByText = function () {
      if (this.projectOwnerNameExists()) {
        return this.lead.project.projectOwner.fullName + ' hired you'
      } else {
        return 'you were hired'
      }
    }

    this.hiredAt = function () {
      return dateService.prettyDate(
        this.lead.outcome.poStatusUpdatedAt,
        'mediumDate'
      )
    }

    this.contractorRelationsPhone = function () {
      return urlConstants.site.contractorRelationsPhoneDisplay
    }

    this.projectOwnerNameExists = function () {
      return (
        this.lead.project.projectOwner &&
        this.lead.project.projectOwner.fullName &&
        this.lead.project.projectOwner.fullName.length
      )
    }

    this.isMobile = function () {
      return isMobile.check()
    }
  },
])
