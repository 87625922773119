const trackTitle = 'Contractor Subscriptions'

function isLoggedInContractor() {
  return (
    UserData.present() && UserData.user_signed_in() && UserData.is_contractor()
  )
}

export const trackLandingPageView = (referrer, section = null) => {
  let action = isLoggedInContractor()
    ? 'logged in landing page view'
    : 'logged out landing page view'
  Track.send(trackTitle, {
    action: action,
    referrer: referrer,
    section: section,
  })
}

export const trackApplyButtonClick = (planSlug, source) => {
  let action = isLoggedInContractor()
    ? 'logged in co apply click'
    : 'logged out co apply click'
  let contractorId = isLoggedInContractor() ? UserData.contractor_id() : null

  Track.send(trackTitle, {
    action: action,
    plan: planSlug,
    source: source,
    contractor_id: contractorId,
  })
}

export const trackApplyFormSubmit = (planSlug, source) => {
  if (isLoggedInContractor()) return // this track should only be for not logged in co's

  Track.send(trackTitle, {
    action: 'logged out co form submit',
    plan: planSlug,
    source: source,
  })
}
