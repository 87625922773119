/* global angular, isMobile */
angular.module('bzCommonUtilitiesModule').directive('hoverOrTap', [
  function () {
    return {
      scope: {
        onSelect: '&?',
        onDeselect: '&?',
      },
      restrict: 'A',
      controller: [
        '$element',
        '$scope',
        function ($element, $scope) {
          this.$onInit = function () {
            this.useCallbacks = !!($scope.onSelect && $scope.onDeselect)

            if (isMobile.any()) {
              this.tapped = false
              $element.on('click', this.toggleTapped.bind(this))
            } else if (this.useCallbacks) {
              $element.on('mouseenter', this.toggleTapped.bind(this))
              $element.on('mouseleave', this.toggleTapped.bind(this))
            } else {
              $element.addClass('hoverable')
            }
          }

          this.toggleTapped = function () {
            this.tapped = !this.tapped
            if (this.useCallbacks) {
              this.tapped ? $scope.onSelect() : $scope.onDeselect()
            } else {
              this.tapped
                ? $element.addClass('tapped')
                : $element.removeClass('tapped')
            }
          }
        },
      ],
    }
  },
])
