/* global angular */
angular.module('bzCommonUtilitiesModule').directive('addClassOnLoad', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        cssClass: '@',
      },
      controller: [
        '$element',
        function ($element) {
          this.$onInit = function () {
            if (document.readyState === 'complete') {
              this.addClassToElement()
            } else if (window.addEventListener) {
              window.addEventListener(
                'load',
                this.addClassToElement.bind(this),
                false
              )
            } else if (window.attachEvent) {
              window.attachEvent('onload', this.addClassToElement.bind(this))
            }
          }

          this.addClassToElement = function () {
            $element.addClass(this.cssClass)
          }
        },
      ],
    }
  },
])
