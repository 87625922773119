/* global bz_app, Track, isMobile */
bz_app.factory('trackProfileService', function () {
  return {
    title: 'Contractor Profile Action',

    navLinkClicked: function (linkType) {
      var data = this.defaultData({ action: 'nav link clicked' })
      data.link_type = linkType

      Track.send(this.title, data)
    },
    externalAdClicked: function (adClient) {
      var data = this.defaultData({ action: 'external ad clicked' })
      data.ad_client = adClient

      Track.send(this.title, data)
    },
    claimClickedInit: function (selector) {
      var data = this.defaultData({ action: 'claim button clicked' })
      Track.send_link(selector, this.title, data)
    },
    claimClicked: function (contractor, variation, callback) {
      var data = this.defaultData({
        action: 'claim button clicked',
        variation: variation,
      })
      data.contractor_id = contractor.id

      if (contractor.analytics && contractor.analytics.claimFormVersion) {
        data.form_version = contractor.analytics.claimFormVersion
      }

      Track.send_callback(this.title, data, callback)
    },
    shareLinkClicked: function (type) {
      var data = this.defaultData({ action: 'share link clicked', type: type })
      Track.send(this.title, data)
    },
    phoneLinkClicked: function (callback) {
      Track.send_callback(
        this.title,
        this.defaultData({ action: 'call contractor' }),
        callback
      )
    },
    showMoreProjectTypesClicked: function (totalProjectTypes) {
      Track.send(
        this.title,
        this.defaultData({
          action: 'show more project types clicked',
          'number of project types': totalProjectTypes,
        })
      )
    },
    yelpReviewsLinkClicked: function (type) {
      var data = this.defaultData({
        action: 'yelp reviews link clicked',
        type: type,
      })
      Track.send(this.title, data)
    },
    bidBannerShown: function () {
      var data = this.defaultData({ action: 'bid banner shown' })
      Track.send(this.title, data)
    },
    bidBannerLinkClicked: function (linkType) {
      var data = this.defaultData({
        action: 'bid banner link clicked',
        type: linkType,
      })
      Track.send(this.title, data)
    },
    promoteProfileBannerShown: function () {
      var data = this.defaultData({ action: 'ad promo banner shown' })
      Track.send(this.title, data)
    },
    promoteProfileBannerLinkClicked: function (linkType) {
      var data = this.defaultData({
        action: 'ad promo banner clicked',
        type: linkType,
      })
      Track.send(this.title, data)
    },
    basicProfileInfoUpdated: function (type, selector) {
      var data = this.defaultData({ action: 'basic profile info updated' })
      if (type === 'link') {
        Track.send_link('#' + selector, this.title, data)
      } else if (type === 'form') {
        Track.send_form('#' + selector, this.title, data)
      }
    },
    locationInfoUpdated: function (type, selector) {
      var data = this.defaultData({ action: 'location info updated' })
      if (type === 'link') {
        Track.send_link('#' + selector, this.title, data)
      } else if (type === 'form') {
        Track.send_form('#' + selector, this.title, data)
      }
    },
    servicesUpdated: function () {
      var data = this.defaultData({ action: 'services updated' })
      Track.send(this.title, data)
    },
    viewed: function (contractor) {
      var data = this.defaultData({ action: 'viewed' })
      data.contractor_id = contractor.nid
      data.current_user_id = UserData.user_id()
      data.current_contractor_id = UserData.contractor_id()
      data.current_user_type = UserData.data('user_role')

      Track.send(
        'Contractor Profile View Action',
        angular.extend(data, {
          contractor_id: contractor.nid,
          current_user_id: UserData.user_id(),
          current_contractor_id: UserData.contractor_id(),
          current_user_type: UserData.data('user_role'),
        })
      )
    },
    defaultData: function (input) {
      var platform = isMobile.check() ? 'mobile' : 'desktop'

      var data = {
        action: input.action ? input.action : '',
        type: input.type ? input.type : '',
        variation: input.variation,
        platform: platform,
      }

      return data
    },
  }
})
