/* global angular, Track, isMobile, UserData */
angular
  .module('bzReviewsModule')
  .factory('trackReviewFormService', function () {
    return {
      title: 'Review Action',
      view: function (options) {
        options.action = 'view form'
        Track.send(this.title, this.defaultData(options))
      },
      undefinedReview: function (options) {
        var input = angular.copy(options)
        input.action = 'undefined review'
        Track.send(this.title, this.defaultData(input))
      },
      submit: function (options) {
        var input = angular.copy(options)
        input.action = 'submit'

        Track.send(this.title, this.defaultData(input))
        Track.sendGa(this.title, input.action, input.referralSource)
        Track.sendBing(this.title, input.action, input.referralSource)
        // if (!UserData.user_signed_in() && UserData.is_production_environment()) {
        // 	window._vis_opt_queue = window._vis_opt_queue || [];
        // 	window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(202);});
        // }
      },
      close: function (options, callback) {
        var input = angular.copy(options)
        input.action = 'close'

        Track.send_callback(this.title, this.defaultData(input), callback)
      },
      clickBack: function (options, callback) {
        var input = angular.copy(options)
        input.action = 'click back'

        Track.send_callback(this.title, this.defaultData(input), callback)
      },
      viewStep: function (step, options) {
        var input = angular.copy(options)
        input.action = 'view step'

        var data = this.defaultData(input)
        data.step = step

        Track.send(this.title, data)
      },
      complete: function (options, callback) {
        var input = angular.copy(options)
        input.action = 'complete form flow'

        Track.send_callback(this.title, this.defaultData(input), callback)
      },
      defaultData: function (input) {
        var platform = isMobile.check() ? 'mobile' : 'desktop'

        var data = {
          action: input.action ? input.action : '',
          platform: platform,
          user_signed_in: UserData.present()
            ? UserData.user_signed_in()
            : 'unknown',
          user_type: UserData.present() ? UserData.user_role() : 'unknown',
          form_version: input.formVersion,
          form_type: 'modal',
          referral_source: input.referralSource ? input.referralSource : '',
          landing_page: input.landingPage ? input.landingPage : '',
          referrer: input.referrer ? input.referrer : '',
          utm_source: input.utmSource ? input.utmSource : '',
          source: input.source ? input.source : '',
        }

        return data
      },
    }
  })
