import template from './referral_agreement_modal_test.html'

const referralAgreementModalTest = {
  bindings: {
    resolve: '<',
    close: '&',
  },
  template: template,
  controller: [
    function () {
      this.$onInit = () => {
        const {
          source,
          lead,
          onModalSuccessCallback,
          isLargeProject,
          hasSignedReferralAgreement,
        } = this.resolve.inputData

        Object.assign(this, {
          source,
          lead,
          onModalSuccessCallback,
          isLargeProject,
          hasSignedReferralAgreement,
        })
      }

      this.onComplete = () => {
        if (this.onModalSuccessCallback) {
          this.onModalSuccessCallback()
        }
        this.close()
      }
    },
  ],
}

export default referralAgreementModalTest
