const BzHttpModule = angular.module('bzHttp', []).factory('bzHttp', [
  '$http',
  'modelService',
  function ($http, modelService) {
    const bzHttpClient = (url, method, config) => {
      return $http(
        Object.assign(config, {
          url,
          method,
          transformResponse: $http.defaults.transformResponse.concat(
            (respData) => modelService.toCamel(respData)
          ),
          transformRequest: [
            (requestData) => modelService.toUnderscore(requestData),
          ].concat($http.defaults.transformRequest),
        })
      ).then(({ data }) => data)
    }

    return {
      get: (url, config = {}) => {
        config.params = modelService.toUnderscore(config.params)
        return bzHttpClient(url, 'GET', config)
      },
      delete: (url, config = {}) => bzHttpClient(url, 'DELETE', config),
      head: (url, config = {}) => bzHttpClient(url, 'HEAD', config),
      post: (url, data, config = {}) => {
        config.data = data
        return bzHttpClient(url, 'POST', config)
      },
      patch: (url, data, config = {}) => {
        config.data = data
        return bzHttpClient(url, 'PATCH', config)
      },
      put: (url, data, config = {}) => {
        config.data = data
        return bzHttpClient(url, 'PUT', config)
      },
    }
  },
]).name

export default BzHttpModule
