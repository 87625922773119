bz_app.factory('serviceRequestEventModel', [
  '$http',
  'urlConstants',
  'modelService',
  'attributeConversionService',
  function ($http, urlConstants, modelService, attributeConversionService) {
    return {
      apiServiceRequestEventsPath:
        urlConstants.api.v1.path + 'service_request_events',
      create: function (eventData) {
        return $http
          .post(
            this.apiServiceRequestEventsPath,
            modelService.toUnderscore(eventData)
          )
          .then(modelService.extractData)
      },
    }
  },
])
