/* global bz_app, angular */
bz_app.directive('neighborhoodProjects', [
  'urlService',
  function (urlService) {
    return {
      restrict: 'AE',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        propertyId: '<?',
        properties: '<?',
        listLimit: '<?',
        version: '@',
      },
      templateUrl: urlService.templateUrl(
        '_components/directives/neighborhood_projects'
      ),
      controller: [
        'urlService',
        function (urlService) {
          this.$onInit = function () {
            if (this.listLimit === undefined) {
              this.listLimit = 10
            }
          }

          this.thumbnail = function (property) {
            return urlService.imgCropUrl(property.logo, 'square_60')
          }

          this.recentProject = function (property) {
            return property &&
              property.relevant_permits &&
              property.relevant_permits.length
              ? property.relevant_permits[0]
              : null
          }
        },
      ],
    }
  },
])
