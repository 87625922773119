/* global angular */
angular.module('bzAnalyticsModule').service('landingPageService', [
  'localStorageModel',
  function (localStorageModel) {
    return {
      storageKey: 'landingPageAnalytics',
      data: {
        landingPage: undefined,
        referrer: undefined,
        impressions: { count: 0, lastVisit: undefined },
        impressionsByPage: {
          listing: { count: 0, lastVisit: undefined },
          property: { count: 0, lastVisit: undefined },
          profile: { count: 0, lastVisit: undefined },
        },
      },
      set: function () {
        if (this.get()) {
          this.data = this.get()
        }

        this.setImpressions()
        this.setImpressionsByPage('listing', 'search_contractors')
        this.setImpressionsByPage('property', 'property')
        this.setImpressionsByPage('profile', 'contractors')
        this.setExternalData()

        localStorageModel.set(this.storageKey, this.data)
      },
      setImpressions: function () {
        if (this.data.impressions === undefined) {
          this.data.impressions = { count: 0, lastVisit: undefined }
        }

        if (this.data.impressions.lastVisit) {
          if (
            this.timeDifferenceInDays(
              new Date() - new Date(this.data.impressions.lastVisit)
            ) >= 14
          ) {
            this.data.impressions.count = 0
          }
        }

        this.data.impressions.count++
        this.data.impressions.lastVisit = new Date().toUTCString()
      },
      setImpressionsByPage: function (page, bodyClass) {
        this.data.impressionsByPage = this.data.impressionsByPage || {}
        this.data.impressionsByPage[page] = this.data.impressionsByPage[
          page
        ] || { count: 0, lastVisit: undefined }

        if (angular.element('body').hasClass(bodyClass)) {
          if (this.data.impressionsByPage[page].lastVisit) {
            if (
              this.timeDifferenceInDays(
                new Date() -
                  new Date(this.data.impressionsByPage[page].lastVisit)
              ) >= 14
            ) {
              this.data.impressionsByPage[page].count = 0
            }
          }

          this.data.impressionsByPage[page].count++
          this.data.impressionsByPage[page].lastVisit = new Date().toUTCString()
        }
      },
      setExternalData: function () {
        if (document.location.pathname && this.data.landingPage == null) {
          this.data.landingPage = document.location.pathname
        }
        if (
          document.referrer.length &&
          document.referrer.match(/buildzoom.com/g) === null
        ) {
          this.data.referrer = document.referrer
        }
      },
      get: function () {
        return localStorageModel.get(this.storageKey)
      },
      getLandingPage: function () {
        return this.get() ? this.get().landingPage : null
      },
      getReferrer: function () {
        return this.get() ? this.get().referrer : null
      },
      // getImpressions: function () {
      // 	return this.get() ? this.get().impressions : null;
      // },
      getImpressionsByPage: function () {
        return this.get() ? this.get().impressionsByPage : null
      },
      timeDifferenceInDays: function (milliseconds) {
        return Math.floor(milliseconds / 1000 / 60 / 60 / 24)
      },
    }
  },
])
