/* global bz_app, UserData */
import template from './confirm_switch_contractor_type_modal.html'
angular
  .module('bzProjectPreferencesModule')
  .factory('confirmSwitchContractorTypeModalService', [
    '$uibModal',
    function ($uibModal) {
      return {
        show: function () {
          return $uibModal.open({
            template,
            controller: 'ConfirmSwitchContractorTypeModalCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            windowClass:
              'v3-modal v3-modal-simple small confirm-switch-contractor-type-modal',
          })
        },
      }
    },
  ])
