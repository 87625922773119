import 'ContractorInvoices/models/_invoice_instance'
/* global bz_app, UserData */
bz_app.factory('checkoutInstance', [
  '$filter',
  'dateService',
  'invoiceInstance',
  'attributeConversionService',
  function ($filter, dateService, invoiceInstance, attributeConversionService) {
    return function () {
      this.init = false
      this.set = function (data) {
        if (data) {
          this.setAttributes(
            attributeConversionService.underscoreToCamelDeepAttributes(data)
          )
          this.init = true
          return true
        } else {
          return false
        }
      }

      this.setAttributes = function (data) {
        for (var property in data) {
          if (data.hasOwnProperty(property) && data[property] !== undefined) {
            if (property === 'invoices') {
              this.invoices = data[property].map((invoiceData) => {
                var invoice = new invoiceInstance()
                invoice.set(invoiceData)
                return invoice
              })
            } else if (
              ['propertyValue', 'balance', 'extraAmount'].includes(property)
            ) {
              this[property] = parseFloat(data[property])
            } else {
              this[property] = data[property]
            }
          }
        }
      }

      this.isLargeProject = function () {
        if (this.projectValue != null) {
          var result = this.projectValue >= 40000
          return result
        } else {
          return false
        }
      }

      this.isDraftLargeProject = function () {
        return this.isDraft() && this.isLargeProject()
      }

      this.hasQuestionableBlock = function () {
        return !!this.questionableBlock
      }

      this.isFlagged = function () {
        return this.flagged === 1
      }

      this.isAppSet = function () {
        return !!this.appCreatedAt
      }

      this.skipProjectSummaryStep = function () {
        return this.isFlagged() || !this.isDraft()
      }

      this.promptToPayBalance = function () {
        return this.isPending() && this.isOutstanding()
      }

      this.isOutstanding = function () {
        //includes paid and pending invoices
        if (this.balance) {
          return this.balance && this.balance > 0
        }
        return true
      }

      this.preventPay = function () {
        return this.isPaid() || !this.isOutstanding() || this.balance <= 0
      }

      this.isPaymentRefundable = function () {
        return (this.isPaid() || this.isPending()) && this.invoices.length > 0
      }

      this.isDraft = function () {
        return this.status === 'draft'
      }

      this.isPaid = function () {
        return this.status === 'paid'
      }

      this.isFailed = function () {
        return this.status === 'failed'
      }

      this.isWontPay = function () {
        return this.status === 'wont_pay'
      }

      this.isPending = function () {
        return this.status === 'pending'
      }

      this.paidInvoiceCount = () =>
        this.invoices.filter((invoice) => invoice.status === 'paid').length

      this.draftInvoiceCount = () =>
        this.invoices.filter((invoice) => invoice.status === 'draft').length

      this.pendingInvoiceCount = () =>
        this.invoices.filter((invoice) => invoice.status === 'pending').length

      this.activeInvoiceCount = () => {
        return this.invoices.filter((invoice) =>
          ['draft', 'paid', 'pending'].includes(invoice.status)
        ).length
      }

      this.hasPaidOrPendingInvoices = function () {
        return this.pendingInvoiceCount() > 0 || this.paidInvoiceCount() > 0
      }

      this.amountPaid = () => {
        return this.invoices
          .filter((invoice) => ['paid', 'pending'].includes(invoice.status))
          .map((invoice) => invoice.amount)
          .reduce((a, b) => a + b, 0)
      }

      this.promptForPayment = function () {
        return (
          this.isWontPay() ||
          this.isDraft() ||
          this.isFailed() ||
          (this.isPending() &&
            this.invoices.length == 0 &&
            this.paymentSourceType !== 'BankAccount')
        )
      }

      this.commissionTotal = function (commissionRate) {
        if (this.projectValue) {
          return Math.round(this.projectValue * commissionRate * 100) / 100
        } else {
          return 0
        }
      }

      this.total = (commissionRate) =>
        this.commissionTotal(commissionRate) + this.extraAmount

      this.nextPaymentAmount = function (commissionRate, amountPaid) {
        return this.commissionTotal(commissionRate) - amountPaid
      }

      this.amountDueNow = function (paymentOption) {
        if (paymentOption !== null) {
          if (
            this.isDraft() &&
            this.amountPaid() === 0 &&
            paymentOption.installmentCommissionRates
          ) {
            return this.total(paymentOption.installmentCommissionRates[0])
          } else if (this.isPaid()) {
            return 0
          } else if (this.isPending()) {
            return this.total(this.commissionRate) - this.amountPaid()
          } else {
            return this.total(paymentOption.commissionRate) - this.amountPaid()
          }
        }
      }

      this.prepareDraftInvoices = function (paymentOption) {
        if (!this.hasPaidOrPendingInvoices()) {
          if (this.invoices && this.invoices.length > 0) {
            this.invoices = this.invoices.filter((invoice) => invoice.id)
          } else {
            this.invoices = []
          }

          let invoice = new invoiceInstance()

          if (paymentOption.singleLeg) {
            invoice.initBase(
              'draft',
              this.commissionTotal(paymentOption.commissionRate),
              null
            )
            this.invoices.push(invoice)
          } else if (paymentOption.id === 'two-leg') {
            invoice.initBase(
              'draft',
              this.commissionTotal(paymentOption.installmentCommissionRates[0]),
              null
            )
            let secondInvoice = new invoiceInstance()
            const amount = this.nextPaymentAmount(
              paymentOption.commissionRate,
              this.commissionTotal(paymentOption.installmentCommissionRates[0])
            )
            const dueAt = dateService.addDays(new Date(), 30)
            secondInvoice.initBase('draft', amount, dueAt)
            this.invoices.push(invoice, secondInvoice)
          }
        }
      }

      this.updateReferralRate = function (referralRate) {
        this.referralRate = referralRate
      }
    }
  },
])
