angular.module('bzSearchJumboTronModule').directive('searchTab', function () {
  return {
    restrict: 'AEC',
    link: function (scope, element, attrs) {
      element.click(function (e) {
        // select tab
        angular.element('.search-tab').removeClass('search-tab-selected')
        element.addClass('search-tab-selected')

        // show form
        angular.element('.search-form').hide()
        angular.element('.search-' + attrs.type + '-form').show()
      })
    },
  }
})
