const contractorProjectBudgetMin = {
  bindings: {
    lead: '<',
    onSubmit: '&',
    onClose: '&',
    onCancel: '&',
    showClose: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/contractor_project_budget_min'
      )
    },
  ],
  controller: [
    '$timeout',
    'contractorModel',
    'trackDeclineLeadService',
    function ($timeout, contractorModel, trackDeclineLeadService) {
      this.$onInit = () => {
        this.minBudget = null
        this.errorMessage = null
        this.status = 'new'

        if (this.showClose === undefined) {
          this.showClose = true
        }
      }

      this.location = () => {
        if (
          this.lead &&
          this.lead.property &&
          this.lead.property.location &&
          this.lead.property.location.city
        ) {
          return this.lead.property.location.city
        } else {
          return 'this area'
        }
      }

      this.projectType = () => {
        return this.lead.project.projectType.name
      }

      this.isValidBidPrice = () => {
        return (
          this.minBudget &&
          this.minBudget.length &&
          !isNaN(parseFloat(this.minBudget))
        )
      }

      this.submitLabel = () => {
        return this.status === 'saving' ? 'Submitting...' : 'Submit'
      }

      this.save = () => {
        if (this.isValidBidPrice()) {
          this.errorMessage = null
          this.status = 'saving'

          contractorModel
            .updateProjectBudgetMin({
              contractorId: this.lead.contractor.id,
              serviceRequestId: this.lead.project.id,
              projectTypeId: this.lead.project.projectType.id,
              minBudget: this.minBudget,
              projectBudget: this.lead.project.summary.budget,
            })
            .then(() => {
              trackDeclineLeadService.submitProjectBudgetMin(this.lead)
              this.status = 'saved'

              if (typeof this.onSubmit === 'function') {
                $timeout(() => {
                  this.onSubmit()
                }, 1500)
              }
            })
        } else {
          this.errorMessage = 'Please provide a valid amount'
        }
      }

      this.isSaved = () => {
        return this.status === 'saved'
      }

      this.hasOnClose = () => {
        return this.showClose
      }
    },
  ],
}

export default contractorProjectBudgetMin
