/* global angular */
angular.module('bzChartModule').component('progressGoalBar', {
  bindings: {
    max: '<',
    goal: '<?',
    value: '<',
    valueSuffixSymbol: '@',
  },
  template:
    '' +
    '<div class="goal-bar-bg">' +
    '	<div ng-style="$ctrl.goalBarValueStyle()" ' +
    '		 ng-class="{\'bar-value-reached\': $ctrl.isGoalReached()}" ' +
    '		 class="goal-bar-value" ></div>' +
    '</div>' +
    '<div class="goal-bar-marker" ng-if="$ctrl.goal" ng-style="$ctrl.goalBarMarkerStyle()">' +
    '	<div ng-class="{\'tooltip-mobile-right\' : $ctrl.rateOfGoal() > 0.9}" class="marker-tooltip">' +
    '		<span ng-bind="$ctrl.goal"></span><span ng-if="$ctrl.valueSuffixSymbol" ng-bind="$ctrl.valueSuffixSymbol"></span>' +
    '	</div>' +
    '</div>',
  controller: [
    function () {
      this.$onInit = function () {
        this.goal = this.goal || 0
      }

      this.goalBarMarkerStyle = function () {
        return {
          left: Math.round(this.rateOfGoal() * 100) + '%',
        }
      }

      this.rateOfGoal = function () {
        return this.goal / this.max
      }

      this.isGoalReached = function () {
        return this.value >= this.goal
      }

      this.goalBarValueStyle = function () {
        var width = 0

        if (this.max <= this.value) {
          width = 100
        } else {
          width = Math.round((this.value / this.max) * 100)
        }

        return {
          width: width + '%',
        }
      }
    },
  ],
})
