const AD_TRACK_URL = bzGlobal.urlConstants.site.trackBuildZoomUrl

export default class AdTrackingApi {
  constructor(httpClient) {
    this.client = httpClient
  }

  trackEvent({
    contractorTrackId,
    userTrackId,
    adType,
    adLocation,
    eventType,
  }) {
    return this.client.get(`${AD_TRACK_URL}/${adType}`, {
      params: {
        track_id: contractorTrackId,
        obfuscated_acting_user_id: userTrackId,
        ad_location: adLocation,
        event_type: eventType,
      },
    })
  }
}
