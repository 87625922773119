export default [
  function () {
    return {
      getAll: function () {
        return [
          { value: 'needs agent', text: 'Not owned, needs real estate agent' },
          { value: 'no site', text: 'Has real estate agent, no site selected' },
          { value: 'not purchased', text: 'Site selected, not purchased' },
          { value: 'closing', text: 'Site selected, in closing' },
          { value: 'owns', text: 'Owns/manages' },
        ]
      },
      getForProjectForm: function () {
        return [
          { value: 'owns', text: 'Yes' },
          { value: 'closing', text: 'In process' },
          { value: 'needs agent', text: 'No' },
        ]
      },
    }
  },
]
