/* global angular, isMobile */
angular.module('bzUserModule').component('emailPasswordForm', {
  bindings: {
    logIn: '<',
    contractor: '<',
    showLogIn: '&',
    showSignUp: '&',
    onAuthentication: '&',
    onAuthenticationFail: '&?',
    referralId: '=?',
    variation: '=?',
    referralSource: '<',
    hidePasswordField: '<',
    externalSubmitTrigger: '<',
    externalData: '<',
    disableLinks: '<?',
    formVersion: '<?',
    ctaLabel: '<?',
  },
  templateUrl: [
    '$attrs',
    'urlService',
    function ($attrs, urlService) {
      return urlService.templateUrl(
        $attrs && $attrs.templateUrl
          ? $attrs.templateUrl
          : 'users/email_password_form'
      )
    },
  ],
  controller: [
    'registrationModel',
    'sessionModel',
    'userModel',
    '$timeout',
    'formValidationService',
    '$scope',
    'emailPasswordFormService',
    'trackAuthService',
    '$element',
    function (
      registrationModel,
      sessionModel,
      userModel,
      $timeout,
      formValidationService,
      $scope,
      emailPasswordFormService,
      trackAuthService,
      $element
    ) {
      this.$onInit = function () {
        this.fields = {
          email: this.contractor ? this.contractor.email : null,
          password: null,
        }

        this.errors = {
          email: null,
          password: null,
        }

        this.fieldValid = {
          email: false,
          password: false,
        }

        if (this.externalData && this.externalData.email) {
          this.fields.email = this.externalData.email
        }

        this.emailField = $element.find('.email-password-form-email')
        $scope.$watch('$ctrl.logIn', this.focusEmail.bind(this))

        this.showPassword = false
        this.hidePasswordField = !!this.hidePasswordField
        this.ctaLabel = this.ctaLabel || 'Submit'

        this.init = true
      }

      this.focusEmail = function () {
        $timeout(this.emailField.focus.bind(this.emailField))
      }

      this.$onChanges = function (changes) {
        if (this.init) {
          let promise = $timeout(this.resetErrors.bind(this))

          if (
            changes &&
            changes.externalSubmitTrigger &&
            !changes.externalSubmitTrigger.isFirstChange()
          ) {
            promise.then(this.submit.bind(this))
          }
        }
      }

      this.resetErrors = function () {
        this.errors.email = null
        this.errors.password = null
        this.fieldValid.email = false
        this.fieldValid.password = false
      }

      this.submit = function ($event) {
        trackAuthService.clickSignUpSubmit({
          userType: this.userType(),
          variation: this.variation,
          formVersion: this.formVersion,
        })

        this.validate().then((valid) => {
          if (valid) {
            if (this.logIn) {
              this.signIn()
            } else {
              this.signUp()
            }
          }
        })

        if ($event) {
          $event.preventDefault()
        }
      }

      this.signIn = function () {
        if (!this.submitting) {
          this.submitting = true

          sessionModel
            .logIn(this.fields)
            .then(this.handleAuthResponse.bind(this))
        }
      }

      this.userType = function () {
        return this.isContractor() ? 'contractor' : 'projectOwner'
      }

      this.signUp = function () {
        trackAuthService.signUp(this.userType())
        if (this.contractor) {
          this.signUpContractor()
        } else {
          this.signUpUser()
        }
      }

      this.signUpContractor = function () {
        if (!this.submitting) {
          this.submitting = true
          let claimReferralSource =
            this.referralSource ||
            (this.contractor.id === undefined
              ? 'sign-up-create'
              : 'sign-up-claim')
          let fields = angular.extend(
            {
              contractorId: this.contractor.id,
              businessName: this.contractor.businessName,
              zipcode: this.contractor.zipcode,
              referralId: this.referralId,
              claimReferralSource: claimReferralSource,
              variation: this.variation,
              claimSource: isMobile.check({ phoneOnly: true })
                ? 'mobile'
                : 'desktop',
            },
            this.fields
          )
          registrationModel
            .signUp(fields)
            .then(this.handleAuthResponse.bind(this))
        }
      }

      this.signUpUser = function () {
        if (!this.submitting) {
          this.submitting = true

          registrationModel
            .signUp(this.fields)
            .then(this.handleAuthResponse.bind(this))
        }
      }

      this.handleAuthResponse = function (res) {
        if (res.success) {
          this.onAuthentication({ response: res })
        } else {
          if (this.onAuthenticationFail) {
            this.onAuthenticationFail({ response: res })
          }

          this.setInputError('email', res.errors.email)
          this.setInputError('password', res.errors.password)
          this.submitting = false
        }
      }

      this.showResetPassword = function () {
        return this.logIn && !this.disableLinks
      }

      this.showSupportPhone = function () {
        return this.isContractor() && !this.logIn
      }

      this.showTermsLink = function () {
        return !this.logIn
      }

      this.resetPasswordLink = function () {
        return emailPasswordFormService.resetPasswordLink()
      }

      this.supportPhone = function () {
        return emailPasswordFormService.supportPhone()
      }

      this.supportPhoneLink = function () {
        return 'tel:' + this.supportPhone()
      }

      this.termsLink = function () {
        return emailPasswordFormService.termsLink()
      }

      this.validate = () =>
        emailPasswordFormService
          .validate(this.fields, this.logIn, false)
          .then((res) => {
            const emailValid = this.handleValidation('email', res.email)
            const passwordValid = this.hidePasswordField
              ? true
              : this.handleValidation('password', res.password)

            return emailValid && passwordValid
          })

      this.validateEmailIfPresent = function () {
        this.fields.email && this.validateEmail()
      }

      this.validatePasswordIfPresent = function () {
        if (this.fields.password && !this.showPassword) this.validatePassword()
      }

      this.validateEmail = () =>
        emailPasswordFormService
          .validateEmail(this.fields.email, this.logIn, false)
          .then(this.handleValidation.bind(this, 'email'))

      this.validatePassword = () =>
        emailPasswordFormService
          .validatePassword(this.fields.password)
          .then(this.handleValidation.bind(this, 'password'))

      this.handleValidation = (field, res) => {
        if (res.valid) {
          this.clearInputError(field)
          this.fieldValid[field] = true
        } else {
          if (this.onAuthenticationFail) {
            this.onAuthenticationFail({ response: res })
          }

          this.setInputError(field, res.error)
        }

        return res.valid
      }

      this.clearInputError = function (field) {
        this.errors[field] = null
      }

      this.setInputError = function (field, error) {
        this.errors[field] = error
        if (error) {
          this.fieldValid[field] = false
        }
      }

      this.isContractor = function () {
        return !!(this.contractor && this.contractor.businessName)
      }

      this.contractorEmail = function () {
        return this.contractor.email && this.contractor.email.toLowerCase()
      }

      this.passwordPlaceholder = function () {
        return this.logIn ? 'password' : 'Create a password'
      }

      this.toggleShowPassword = function () {
        this.showPassword = !this.showPassword
      }
    },
  ],
})
