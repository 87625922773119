/* global angular, Track, isMobile */
export class trackClaimScreenService {
  constructor() {
    this.title = 'Claim Screen Action'
  }

  claimViewStep(contractor, step, variation) {
    var data = this.defaultData({
      action: 'claim view step',
      variation: variation,
    })
    data.contractor_id = contractor.id
    data.step = step

    if (contractor.analytics && contractor.analytics.claimFormVersion) {
      data.form_version = contractor.analytics.claimFormVersion
    }

    Track.send(this.title, data)
  }

  claimProfileSubmitted(contractor, callback) {
    var data = this.defaultData({ action: 'claim profile submitted' })
    data.contractor_id = contractor.id

    if (contractor.analytics && contractor.analytics.claimFormVersion) {
      data.form_version = contractor.analytics.claimFormVersion
    }

    Track.send_callback(this.title, data, callback)
  }

  defaultData(input) {
    let bzReferer
    if (window.location.href.indexOf('campaign=short_url') >= 0) {
      bzReferer = '/c page'
    } else if (window.location.href.indexOf('/contractor/') >= 0) {
      bzReferer = 'organic claim'
    }

    return {
      action: input.action ? input.action : '',
      type: input.type ? input.type : '',
      platform: isMobile.check() ? 'mobile' : 'desktop',
      bzReferer: bzReferer,
      variation: input.variation,
    }
  }
}
