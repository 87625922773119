import Geocoder from 'Vanilla/services/geocoder'
import template from './contractor_travel_range_screen.html'

const contractorTravelRangeScreen = {
  bindings: {
    lead: '<',
    onUpdate: '&',
  },
  template,
  controller: [
    'projectPreferencesModel',
    '$timeout',
    'contractorModel',
    'attributeConversionService',
    'trackDeclineLeadService',
    'declineLeadService',
    'locationService',
    '$scope',
    'formValidationService',
    function (
      projectPreferencesModel,
      $timeout,
      contractorModel,
      attributeConversionService,
      trackDeclineLeadService,
      declineLeadService,
      locationService,
      $scope,
      formValidationService
    ) {
      this.$onInit = function () {
        this.contractor = this.lead.contractor
        this.contractor.location = this.contractor.location || {}
        this.errors = {}

        this._initialValues = {
          zipcode: this.contractor.zipcode,
          travelRange: this.contractor.jobPreferences.travelRange,
        }

        this.checkZipcodeValidity =
          formValidationService.isValidZipCodeFromDataset
        this.getLocation = locationService.find_address
        this.geocoder = new Geocoder()
        this.geocoder.recordContractorLocationIfValid(this.contractor)
      }

      this.validateContractorZipcode = () => {
        return this.checkZipcodeValidity(this.contractor.zipcode)
          .then((response) => {
            let validZipcode = response.success ? response.valid : false
            validZipcode
              ? this.clearError('zipcode')
              : (this.errors.zipcode = 'Invalid zipcode *')
          })
          .catch((error) => {
            this.errors.zipcode = 'Invalid zipcode *'
          })
      }

      this.onZipcodeChange = () => {
        if (this.contractor.zipcode.length !== 5) return

        this.validateContractorZipcode().finally(() => {
          if (this.errors.zipcode === null) {
            this.geocoder.geocode(this.contractor.zipcode).then((result) => {
              this.contractor.city = result.city
              this.contractor.state = result.state
              $scope.$applyAsync()
            })
          }
        })
      }

      this.onStreetSelected = ($item) => {
        if (!$item) return

        let query = $item.street + ',' + $item.city + ',' + $item.state
        this.contractor.streetAddress = $item.street // make input not show [object, object] breifly

        this.geocoder.geocode(query).then((result) => {
          this.geocoder.assignContractorGeocodeResult(this.contractor, result)
          this.validateContractorZipcode()
          $scope.$applyAsync()
        })
      }

      this.clearError = function (field) {
        this.errors[field] = null
      }

      this.submit = function () {
        this.validate()

        if (this.isValid()) {
          this.submitting = true
          this.contractor.lat = null
          this.contractor.lng = null

          this.geocoder
            .geocode(this.geocoder.queryFromAddressParts(this.contractor))
            .then((result) => {
              if (
                this.geocoder.isContractorMatchingGeocode(
                  this.contractor,
                  result
                )
              ) {
                this.geocoder.assignContractorGeocodeResult(
                  this.contractor,
                  result
                )
                $scope.$applyAsync()
              }
            })
            .finally(() => {
              this.update()
            })
        }
      }

      this.validate = function () {
        var range = this.contractor.jobPreferences.travelRange,
          zipcode = this.contractor.zipcode

        if (!zipcode || zipcode.toString().length !== 5) {
          this.errors.zipcode = 'Invalid zipcode *'
        }

        if (!range || parseInt(range) < 0) {
          this.errors.range = 'Please enter a travel range greater than 0.'
        }
      }

      this.isValid = function () {
        return !this.errors.zipcode && !this.errors.range
      }

      this.update = function () {
        trackDeclineLeadService.saveTravelRange(
          this.lead,
          this.travelRangeChanged()
        )
        this.submitting = true

        contractorModel
          .updateProfile(this.contractor.id, {
            extra_attributes: {
              travel_range: this.contractor.jobPreferences.travelRange,
              id: this.contractor.id,
            },
            street_address_unit: this.contractor.streetAddressUnit,
            street_address: this.contractor.streetAddress,
            city: this.contractor.city,
            state: this.contractor.state,
            zipcode: this.contractor.zipcode,
            lat: this.contractor.lat,
            lng: this.contractor.lng,
          })
          .then(
            function (res) {
              this.submitting = false

              if (res.success) {
                this.onUpdate()
              } else {
                this.errors.zipcode = 'Please enter a valid zipcode'
              }
            }.bind(this)
          )
      }

      this.travelRangeChanged = function () {
        return (
          this._initialValues.zipcode !== this.contractor.zipcode ||
          this._initialValues.travelRange !==
            this.contractor.jobPreferences.travelRange
        )
      }

      this.skip = function () {
        this.onUpdate()
      }

      this.saveButtonText = function () {
        return declineLeadService.saveButtonText(this.submitting)
      }
    },
  ],
}

export default contractorTravelRangeScreen
