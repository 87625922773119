/* global bz_app */
import { isEmpty, omitBy } from 'lodash'
import template from './generic_form.html'

const genericForm = {
  bindings: {
    fieldsList: '<',
    header: '<',
    subheader: '<',
    submitButtonText: '<',
    onSubmit: '&',
    onClose: '&',
    hideSubmitAnimation: '<?',
    onUpdate: '&?',
  },
  template,
  controller: [
    '$timeout',
    '$q',
    function ($timeout, $q) {
      this.$onInit = () => {
        this.loading = false
        this.showSubmitSuccess = false
        this.showSubmitError = false
        this.form = {}
        this.shownFieldErrors = {}
      }

      this.updateField = (fieldName, value) => {
        delete this.shownFieldErrors[fieldName]
        this.form[fieldName] = value
        if (this.onUpdate) {
          this.onUpdate({ fieldName, value })
        }
      }

      this.submit = () => {
        this.shownFieldErrors = {}
        this.validateFields(this.form).then((fieldsWithErrors) => {
          if (isEmpty(fieldsWithErrors)) {
            if (this.hideSubmitAnimation) {
              this.onSubmit({ form: this.form })
              this.onClose()
            } else {
              this.onSubmit({ form: this.form })
                .then((resp) => {
                  this.showSubmitSuccess = true
                })
                .catch(() => {
                  this.showSubmitError = true
                })
                .finally(() => {
                  $timeout(() => {
                    this.showSubmitSuccess = false
                    this.showSubmitError = false
                    this.onClose()
                  }, 2500)
                  this.loading = false
                })
            }
          } else {
            this.shownFieldErrors = fieldsWithErrors
          }
        })
      }

      this.validateFields = (formFields) => {
        return $q
          .all(
            this.fieldsList.reduce(
              (errorMessagesByField, { modelName, getErrorMessage }) => {
                if (!getErrorMessage) return errorMessagesByField
                return Object.assign(errorMessagesByField, {
                  [modelName]: getErrorMessage(formFields[modelName]),
                })
              },
              {}
            )
          )
          .then((errorMessages) => {
            return omitBy(errorMessages, (errorMessage) => !errorMessage)
          })
      }
    },
  ],
}
export default genericForm
