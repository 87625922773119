angular
  .module('bzProjectPreferencesModule')
  .factory('contractorProjectPreferencesModel', [
    'urlConstants',
    '$http',
    function (urlConstants, $http) {
      return {
        contractorProfileApiPath:
          urlConstants.api.v1.path + 'contractor_profile',
        contractorProjectPreferencesPath:
          urlConstants.api.v3.path + 'contractor_project_preferences',
        update: function (data) {
          return $http.put(this.contractorProfileApiPath, {
            contractor_profile: data,
          })
        },
        getProjectPreferences: function (contractorId) {
          return $http.get(
            this.contractorProjectPreferencesPath +
              '/taxonomy_for_contractor/' +
              contractorId
          )
        },
      }
    },
  ])
