import WebFont from 'webfontloader'
import template from './signature_input.html'

const signatureInputComponent = {
  bindings: {
    signatureText: '=',
    placeholder: '@?',
    onChange: '&?',
  },
  template,
  controller: [
    function () {
      this.$onInit = () => {
        WebFont.load({
          google: {
            families: ['Zeyada'],
          },
        })
      }

      this.handleChange = () => this.onChange && this.onChange()
    },
  ],
}

export default signatureInputComponent
