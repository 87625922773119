/* global bz_app, angular */
angular.module('bzProjectModule').factory('projectAttachmentModel', [
  '$q',
  '$http',
  'urlConstants',
  'filepickerService',
  'filestackService',
  'uploadModel',
  'attributeConversionService',
  function (
    $q,
    $http,
    urlConstants,
    filepickerService,
    filestackService,
    uploadModel,
    attributeConversionService
  ) {
    return {
      attach: function (projectId, callback) {
        filepickerService.upload(
          function (InkBlobs) {
            // bring up upload modal
            if (InkBlobs && InkBlobs.length) {
              angular.forEach(
                InkBlobs,
                function (file) {
                  var attachment = {
                    name: file.filename,
                    filename: file.filename,
                    filepicker_slug: file.url,
                    file: true,
                    description: file.mimetype,
                    project_id: projectId,
                    s3_slug: file.key,
                    cloudfront_slug: filepickerService.getCloudFrontUrl(
                      file.url
                    ),
                  }

                  this.add(projectId, attachment).then(function (response) {
                    if (response) {
                      attachment.id = response.image_id

                      if (callback !== undefined) {
                        callback(attachment)
                      }
                    }
                  })
                }.bind(this)
              )
            }
          }.bind(this)
        )
      },
      attachV2: function (options) {
        let files = []
        let deferred = $q.defer()

        angular.forEach(options.files, (selectedFile) => {
          filestackService.upload(selectedFile).then((file) => {
            let attachment = uploadModel.inkBlobToAttachment(file)
            attachment.projectId = options.projectId

            this.add(options.projectId, attachment).then((response) => {
              if (response) {
                attachment.id = response.image_id
                files.push(attachment)

                if (files.length === options.files.length) {
                  deferred.resolve(files)
                }
              }
            })
          })
        })

        return deferred.promise
      },
      add: function (projectId, attachment) {
        let data =
          attributeConversionService.camelToUnderscoreDeepAttributes(attachment)
        return $http
          .post(
            urlConstants.api.v1.path +
              'projects/' +
              projectId +
              '/homeowner_attachment',
            data
          )
          .then(this.extractData)
      },
      delete: function (projectId, attachmentId) {
        return $http
          .delete(
            urlConstants.api.v1.path +
              'projects/' +
              projectId +
              '/homeowner_attachment/' +
              attachmentId
          )
          .then(this.extractData)
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
    }
  },
])
