bz_app.directive('trackContractorProfileAction', [
  function () {
    return {
      restrict: 'AE',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        actionType: '@',
        selector: '@',
        action: '@',
      },

      controller: [
        'trackProfileService',
        function (trackProfileService) {
          this.$onInit = function () {
            if (this.actionType) {
              this.trackRedirect(this.actionType)
            }
          }

          this.trackRedirect = function (type) {
            if (this.action === 'updateBasicInfo') {
              trackProfileService.basicProfileInfoUpdated(type, this.selector)
            } else if (this.action === 'updateLocationInfo') {
              trackProfileService.locationInfoUpdated(type, this.selector)
            }
          }
        },
      ],
    }
  },
])
