export default [
  '$compile',
  function ($compile) {
    return function ($scope, $element, attrs) {
      const cancelWatch = $scope.$watch(
        ($scope) => $scope.$eval(attrs.compileElement),
        (value) => {
          $element.html(value)
          $compile($element.contents())($scope)
          cancelWatch()
        }
      )
    }
  },
]
