const authValidationService = [
  'formValidationService',
  'applicationConstants',
  'contractorModel',
  'userModel',
  '$q',
  function (
    formValidationService,
    applicationConstants,
    contractorModel,
    userModel,
    $q
  ) {
    return {
      validateEmail: function (email, checkExistingUsers, checkCoEmail) {
        if (!formValidationService.isValidEmail(email)) {
          return $q(function (resolve) {
            resolve({
              valid: false,
              error: 'Invalid email format',
            })
          })
        } else if (checkExistingUsers) {
          return userModel.findExistingUserByEmail(email, true).then(
            function (res) {
              if (res.existingUser && !res.signedIn) {
                return {
                  valid: false,
                  error: 'A user with that email already exists',
                }
              } else if (checkCoEmail) {
                return this.validateContractorEmail(email)
              } else {
                return { valid: true }
              }
            }.bind(this)
          )
        } else {
          return $q(function (resolve) {
            resolve({ valid: true })
          })
        }
      },

      validateContractorEmail: function (email) {
        return contractorModel
          .findExistingContractorByEmail(email)
          .then(function (res) {
            if (res.existing_contractor) {
              return {
                valid: false,
                error:
                  'A contractor profile with that email ' +
                  'already exists. Please contact ' +
                  applicationConstants.support.phone +
                  ' for support.',
              }
            } else {
              return { valid: true }
            }
          })
      },

      validatePassword: function (password) {
        return $q(function (resolve) {
          if (!password || password.length < 6 || password.length > 128) {
            resolve({
              valid: false,
              error: 'Password must be between 6 and 128 characters',
            })
          } else {
            resolve({ valid: true })
          }
        })
      },
    }
  },
]

export default authValidationService
