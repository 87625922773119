/* global angular, isMobile */
angular.module('bzProjectFormModule').component('closeConfirmScreen', {
  bindings: {
    project: '<',
    close: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/close_confirm'
      )
    },
  ],
  controller: [
    'urgencyTypesModel',
    'projectFormScreenService',
    'trackProjectFormService',
    function (
      urgencyTypesModel,
      projectFormScreenService,
      trackProjectFormService
    ) {
      this.dismiss = function () {
        projectFormScreenService.isClosing = false

        trackProjectFormService.closeConfirmAction({
          action: 'close confirm continue',
          version: this.project.analytics.closeConfirmVersion,
        })
      }

      this.confirm = function () {
        this.close({ referralSource: 'quit button' })
      }
    },
  ],
})
