/* global bz_app, Track, angular, isMobile */
bz_app.factory('calculateWinImpactService', [
  function () {
    return {
      winBoostBounds: {
        0: { min: 2, max: 10 },
        1: { min: 2, max: 10 },
        2: { min: 3, max: 16 },
        3: { min: 4, max: 18 },
        4: { min: 4, max: 19 },
        5: { min: 4, max: 20 },
      },
      winBoostPercentages: {
        0: 0.33,
        1: 0.33,
        2: 0.5,
        3: 0.6,
        4: 0.66,
        5: 0.7,
      },
      calculateNumProjectsBoost: function (wins, leadsLastMonth) {
        var boost =
          Math.floor(leadsLastMonth * (1 + this.winBoostPercentage(wins))) -
          leadsLastMonth
        if (boost < this.winBoostBound(wins).min) {
          boost = this.winBoostBound(wins).min
        } else if (boost > this.winBoostBound(wins).max) {
          boost = this.winBoostBound(wins).max
        }
        return boost
      },
      winBoostBound: function (wins) {
        return this.winBoostBounds[wins]
          ? this.winBoostBounds[wins]
          : { min: 4, max: 20 }
      },
      winBoostPercentage: function (wins) {
        return this.winBoostPercentages[wins]
          ? this.winBoostPercentages[wins]
          : 0.7
      },
      winImpactExplanation: function () {
        return (
          'We rank all the contractors in your area and send projects to the pros at the top. ' +
          'Our goal is the give the best possible experience to our homeowners, so pros who win more projects ' +
          'are ranked more highly on our list, and receive more of our best projects.<br><br>' +
          'Estimate based on previous methods; actual projects received may vary with location, season, demand, ' +
          'and other factors.'
        )
      },
    }
  },
])
