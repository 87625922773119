bz_app.factory('leadsDashboardService', [
  function () {
    return {
      budgetSortValues: {
        null: 0,
        '': 0,
        '1000-5000': 1,
        '5000-20000': 2,
        '20000-50000': 3,
        '50000-100000': 4,
        '100000-250000': 5,
        '250000-500000': 6,
        '500000-1000000': 7,
      },
    }
  },
])
