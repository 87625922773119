bz_app.factory('notificationsModel', [
  '$q',
  '$rootScope',
  'notificationsSettingsData',
  function ($q, $rootScope, notificationsSettingsData) {
    return {
      settings: notificationsSettingsData,
      user_api: new UserAPI(),
      load: function (callback) {
        var that = this

        this.user_api.load_notification_settings().then(function (settings) {
          that.settings.set(settings)

          if (typeof callback == 'function') callback(settings)
        })
      },
      update: function (callback) {
        var that = this
        this.user_api.update_notification_settings(this.settings).then(
          function (settings) {
            that.settings.set(settings)
            that.settings.set_errors(null)

            if (typeof callback == 'function') callback(settings)
          },
          function (response) {
            that.settings.set_errors(JSON.parse(response.responseText))
            that.load()

            if (typeof callback == 'function') callback(response)
          }
        )
      },
      verify: function (type, allowPoSend) {
        this.user_api.verify_contact_method(type, allowPoSend)
      },
    }
  },
])
