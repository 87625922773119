const PlainStarsModule = angular
  .module('plainStars', [])
  .component('plainStars', {
    bindings: {
      value: '<',
    },
    template: `
			<div
				class="star fa u-dib"
				ng-class="$ctrl.getStarClass($index)"
				ng-repeat="star in $ctrl.stars track by $index">
			</div>
		`,
    controller: [
      function () {
        this.$onInit = () => {
          this.stars = new Array(5)
        }

        this.getStarClass = (starIndex) => {
          const remainingStarsToShow = this.value - starIndex
          if (remainingStarsToShow >= 0.25 && remainingStarsToShow < 0.75) {
            return 'fa-star-half'
          } else if (remainingStarsToShow >= 0.75) {
            return 'fa-star'
          } else {
            return 'fa-star empty'
          }
        }
      },
    ],
  }).name

export default PlainStarsModule
