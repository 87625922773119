angular.module('bzYelpModule').directive('yelpReviews', [
  'urlService',
  function (urlService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        contractorId: '@',
        contractorBusinessName: '@',
        yelpId: '@',
      },
      templateUrl: urlService.templateUrl(
        '_components/directives/yelp_reviews'
      ),

      controller: [
        'yelpModel',
        '$timeout',
        'urlConstants',
        'trackProfileService',
        function (yelpModel, $timeout, urlConstants, trackProfileService) {
          this.$onInit = function () {
            this.searchComplete = false
            this.reviews = []
            this.reviewCount = null
            this.rating = null
            this.yelpBusinessUrl = null
            this.STAR_IMAGE_PATH = `${urlConstants.s3.assetUrl}_thumbnails/yelp-review-stars/`
            $timeout(this.loadReviews.bind(this), 1000)
          }

          this.loadReviews = function () {
            yelpModel.contractorReviews(this.contractorId).then(
              function ({ success, yelpBusiness }) {
                if (success) {
                  const { reviews, reviewCount, rating, url } = yelpBusiness
                  this.reviews = reviews.map((review) => ({
                    ...review,
                    timeCreated: new Date(review.timeCreated),
                  }))
                  this.reviewCount = reviewCount
                  this.rating = rating
                  this.yelpBusinessUrl = url
                }
                this.setYelpLinkCopy()
                this.searchComplete = true
              }.bind(this)
            )
          }

          this.yelpLogoUrl = function () {
            return urlConstants.s3.yelpLogoUrl
          }

          this.reviewStarsImageFile = function (rating) {
            if (rating > 4.5) {
              return 'large_5'
            } else if (rating > 4) {
              return 'large_4_half'
            } else if (rating > 3.5) {
              return 'large_4'
            } else if (rating > 3) {
              return 'large_3_half'
            } else if (rating > 2.5) {
              return 'large_3'
            } else if (rating > 2) {
              return 'large_2_half'
            } else if (rating > 1.5) {
              return 'large_2'
            } else if (rating > 1) {
              return 'large_1_half'
            } else if (rating > 0.5) {
              return 'large_1'
            } else {
              // no half star
              return 'large_0'
            }
          }

          this.reviewStarsImageUrl = function (rating) {
            return `${this.STAR_IMAGE_PATH}${this.reviewStarsImageFile(
              rating
            )}.png`
          }

          this.trackReviewsLinkClick = function ($event, type) {
            trackProfileService.yelpReviewsLinkClicked(type)
          }

          this.setYelpLinkCopy = function () {
            this.yelpLinkCopy = 'Read full review'
            if (this.reviews && this.reviews.length > 1) {
              this.yelpLinkCopy += 's'
            }
          }
        },
      ],
    }
  },
])
