const leadReferralInstance = [
  'attributeConversionService',
  'formValidationService',
  'searchModel',
  'contractorReferralsModel',
  '$q',
  function (
    attributeConversionService,
    formValidationService,
    searchModel,
    contractorReferralsModel,
    $q
  ) {
    return function () {
      this.serviceRequestId = null
      this.contractorId = null
      this.businessName = null
      this.email = null
      this.phone = null
      this.lead = null

      this.set = function (data) {
        Object.keys(data).forEach(
          function (key) {
            if (this.hasOwnProperty(key)) {
              this[key] = data[key]
            }
          }.bind(this)
        )
      }

      this.isValid = function () {
        return (
          formValidationService.isValidEmail(this.email || '') ||
          formValidationService.isValidPhone(this.phone || '')
        )
      }

      this.clearError = function () {
        this.error = null
      }

      this.validate = function () {
        this.clearError()

        if (!this.isValid()) {
          this.error = 'Please provide a valid email or phone'
        }
      }

      this.contractorSuggestions = function (searchTerms) {
        return searchModel
          .getSearchContractors(null, searchTerms, {
            zipcode: this.lead.property
              ? this.lead.property.location.zipcode
              : this.lead.zipcode,
            mile_radius: 150,
          })
          .then(
            attributeConversionService.underscoreToCamelDeepAttributes.bind(
              attributeConversionService
            )
          )
      }

      this.create = function () {
        this.validate()

        return this.error
          ? $q(
              function (resolve) {
                resolve({ valid: false, error: this.error })
              }.bind(this)
            )
          : contractorReferralsModel.createLeadReferral(this)
      }
    }
  },
]

export default leadReferralInstance
