/* global angular */
angular.module('bzProjectModule').factory('serviceRequestPermitsModel', [
  '$http',
  'urlConstants',
  'modelService',
  function ($http, urlConstants, modelService) {
    return {
      apiPath: function (id) {
        return urlConstants.api.v3.path + 'service_requests/' + id + '/permits'
      },

      fetch: function (serviceRequestId) {
        return $http
          .get(this.apiPath(serviceRequestId))
          .then(modelService.extractData)
      },

      create: function (serviceRequestId) {
        return $http
          .post(this.apiPath(serviceRequestId), {})
          .then(modelService.extractData)
      },

      update: function (serviceRequestId, permitId, params) {
        var path = this.apiPath(serviceRequestId) + '/' + permitId
        var data = modelService.toUnderscore({
          serviceRequestPermit: params,
          serviceRequestId: serviceRequestId,
        })

        return $http.put(path, data).then(modelService.extractData)
      },

      delete: function (serviceRequestId, permitId) {
        var path = this.apiPath(serviceRequestId) + '/' + permitId

        return $http.delete(path).then(modelService.extractData)
      },
    }
  },
])
