/* global bz_app, $, angular */
import MessageRedirectHelper from 'Vanilla/services/message_redirect_helper'
import { get, hasIn } from 'lodash'

bz_app.controller('ConversationsCtrl', [
  '$scope',
  '$stateParams',
  'conversationModel',
  'urlConstants',
  'userInfoService',
  'dashboardUIService',
  'attributeConversionService',
  '$timeout',
  function (
    $scope,
    $stateParams,
    conversationModel,
    urlConstants,
    userInfoService,
    dashboardUIService,
    attributeConversionService,
    $timeout
  ) {
    this.$onInit = function () {
      this.conversations = []
      this.conversations_status = ''
      this.urlConstants = urlConstants
      this.userInfoService = userInfoService
      this.conversationsPlaceholder = 'Loading messages...'
      this.conversationsLoadMoreText = 'Load Older Messages'
      this.loadingInProgress = false
      this.pagination = {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
      }

      this.conversations_status = !$stateParams.status
        ? 'inbox'
        : $stateParams.status
      this.conversationsLoad()

      dashboardUIService.setSubnavSelection(this.conversations_status)
      dashboardUIService.setSubnavInteraction()
      dashboardUIService.updateUnreadCountUI()

      $scope.$on(
        'conversation-archived',
        function () {
          this.conversationsLoad()
        }.bind(this)
      )

      $scope.$on(
        'conversation-unarchived',
        function () {
          this.conversationsLoad()
        }.bind(this)
      )
    }

    this.subNavBackClick = function ($event) {
      $(angular.element($event.currentTarget).data('target'))
        .addClass('show-left')
        .parent()
        .show()
      $('.col-viewport').hide()
    }

    this.conversationsLoad = function (appendConversations) {
      if (appendConversations === undefined) {
        appendConversations = false
      }

      conversationModel
        .loadByStatus(
          $stateParams.status,
          this.pagination.currentPage,
          this.pagination.pageSize
        )
        .then(
          function (response) {
            response =
              attributeConversionService.underscoreToCamelDeepAttributes(
                response
              )
            if (appendConversations) {
              this.conversations = this.conversations.concat(
                response && response.conversations ? response.conversations : []
              )
            } else {
              this.conversations =
                response && response.conversations ? response.conversations : []
            }

            // set pagination data based on response
            if (response.summary) {
              this.pagination.currentPage = response.summary.page
              this.pagination.pageSize = response.summary.pageSize
              this.pagination.totalCount = response.summary.totalConversations
            }

            this.conversationsPlaceholder =
              'You have no new messages. When project owners contact you, the messages will appear here.'
            this.loadingInProgress = false
            angular
              .element('.conversations-load-more')
              .text(this.conversationsLoadMoreText)

            this.initBlockAuthTokenLinkClick()
          }.bind(this)
        )
    }

    this.conversationsLoadMore = function ($event) {
      if (!this.loadingInProgress) {
        angular.element('.conversations-load-more').text('Loading...')
        this.loadingInProgress = true
        this.pagination.currentPage++
        this.conversationsLoad(true)
      }

      if ($event) {
        $event.preventDefault()
      }
    }

    this.conversationsPresent = function () {
      return this.conversations && this.conversations.length
    }

    this.moreConversationsPresent = function () {
      return (
        this.conversations &&
        this.pagination.totalCount > this.conversations.length
      )
    }

    this.senderColumnTitle = function () {
      return userInfoService.role === 'homeowner' ? 'Contractor' : 'Client'
    }

    this.initBlockAuthTokenLinkClick = function () {
      if (this.userIsContractor()) {
        $timeout(function () {
          angular
            .element('[href*="auth_token="]')
            .each(function (index, element) {
              angular.element(element).attr('href', 'javascript:void(0)')
            })
        }, 500)
      }
    }

    this.redirectToProjectUrl = function ($event, conversation) {
      if ($event) {
        $event.preventDefault()
      }
      let contractorId = get(conversation, 'contractor.nid')
      this.projectUrlHelper = new MessageRedirectHelper(
        urlConstants,
        conversation,
        conversation.serviceRequestId,
        contractorId
      )
      this.projectUrlHelper.redirectToProjectUrl($event)
    }

    this.shouldShowLocation = (conversation) => {
      return (
        hasIn(conversation, 'sendingUser.location.city') &&
        hasIn(conversation, 'sendingUser.location.state')
      )
    }

    this.userIsContractor = () => {
      return userInfoService.role === 'contractor'
    }
  },
])
