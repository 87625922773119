/* global bz_app, Track, isMobile */
bz_app.factory('trackMessagingService', [
  'userInfoService',
  function (userInfoService) {
    return {
      messageSent: function (conversationId) {
        Track.send(
          'Messaging Action',
          this.defaultData({
            action: 'message sent',
            conversation_id: conversationId,
          })
        )
      },
      conversationArchived: function (conversationId) {
        Track.send(
          'Messaging Action',
          this.defaultData({
            action: 'conversation archived',
            conversation_id: conversationId,
          })
        )
      },
      conversationUnarchived: function (conversationId) {
        Track.send(
          'Messaging Action',
          this.defaultData({
            action: 'conversation unarchived',
            conversation_id: conversationId,
          })
        )
      },
      defaultData: function (input) {
        var platform = isMobile.check() ? 'mobile' : 'desktop'

        return {
          action: input.action ? input.action : '',
          conversation_id: input.conversation_id ? input.conversation_id : '',
          platform: platform,
          user_role: userInfoService.role,
          user_id: userInfoService.id,
        }
      },
    }
  },
])
