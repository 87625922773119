/* global angular */
const surveyScreen = {
  bindings: {
    data: '<',
    dismissModal: '&?',
    onSubmit: '&',
    lead: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('_components/components/survey_screen')
    },
  ],
  controller: [
    function () {
      this.$onInit = function () {
        this.otherExplanation = null
        this.submissionBlocked = false
        this.extractData()
      }

      this.extractData = function () {
        this.title = this.data.title
        this.surveyQuestion = this.data.surveyQuestion
        this.explanationObjs = this.data.explanationObjs
      }

      this.setExplanation = function (explObj) {
        // stored in two places to support radiobutton
        this.explanation = explObj.value
        angular.forEach(this.explanationObjs, function (expl) {
          if (expl.value === explObj.value) {
            expl.selected = true
          } else {
            expl.selected = false
          }
        })
      }

      this.setExplanationAndSubmit = function (explObj) {
        this.setExplanation(explObj)
        this.handleSubmit()
      }

      this.handleSubmit = function () {
        if (this.explanation && this.explanation.length) {
          if (this.explanation === this.otherExplanation) {
            if (this.otherExplanationIsValid()) {
              this.submissionBlocked = false
              this.onSubmit({ explanation: this.otherExplanation })
            } else {
              this.submissionBlocked = true
            }
          } else {
            this.submissionBlocked = false
            this.onSubmit({ explanation: this.explanation })
          }
        } else {
          this.submissionBlocked = true
        }
      }

      this.otherExplanationIsValid = function () {
        return (
          this.otherExplanation &&
          this.otherExplanation.length &&
          this.otherExplanation !== ''
        )
      }

      this.setOtherExplanation = function () {
        if (this.otherExplanationIsValid()) {
          this.setExplanation({ value: this.otherExplanation })
        }
      }

      this.showSubmit = function () {
        return this.otherExplanationIsValid()
      }
    },
  ],
}

export default surveyScreen
