angular.module('bzProjectLabelsModule').factory('projectLabelsData', [
  '$timeout',
  '$rootScope',
  '$filter',
  function ($timeout, $rootScope, $filter) {
    return {
      labels: [],
      initialized: false,

      set: function (input, async) {
        if (input) {
          if (async) {
            var that = this
            $timeout(function () {
              $rootScope.$apply(function () {
                that.setData(input)
              })
            }, 0)
          } else this.setData(input)
        }
      },
      setData: function (input) {
        if (input.labels) this.labels = input.labels
        this.initialized = true
      },
      addLabel: function (label) {
        var that = this
        $timeout(function () {
          $rootScope.$apply(function () {
            that.labels.push(label)

            that.labels = $filter('orderObjectBy')(
              that.labels,
              'name',
              'asc',
              'string'
            )
          })
        }, 0)
      },
      removeLabel: function (labelId) {
        var that = this
        $timeout(function () {
          $rootScope.$apply(function () {
            //that.labels.push(label);
            //angular.forEach(that.labels, function (item) {
            //
            //});

            that.labels = that.labels.filter(function (label) {
              return label.id !== labelId
            })
          })
        }, 0)
      },
    }
  },
])
