/* global bz_app */
//This component is no longer in use 09/12/2018
bz_app.factory('planToBidModalService', [
  'trackServiceRequestsActionsService',
  'modalScreenService',
  'urlService',
  '$uibModal',
  function (
    trackServiceRequestsActionsService,
    modalScreenService,
    urlService,
    $uibModal
  ) {
    return {
      openModal: function (options) {
        $uibModal.open({
          controller: 'PlanToBidModalCtrl',
          controllerAs: '$ctrl',
          resolve: {
            inputData: function () {
              return options
            },
          },
          windowClass: 'v3-modal-simple small',
          templateUrl: urlService.templateUrl('leads/plan_to_bid_modal'),
          backdrop: true,
          keyboard: true,
        })
      },
    }
  },
])
