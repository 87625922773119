angular.module('bzProposalsModule').component('proposalsList', {
  bindings: {
    proposals: '<',
    onSaveAsTemplate: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('proposals/proposals_list')
    },
  ],
  controller: [
    'attributeConversionService',
    function (attributeConversionService) {
      this.$onInit = function () {
        this.proposalHighlighted = {}

        this.proposals =
          attributeConversionService.underscoreToCamelDeepAttributes(
            this.proposals
          )
      }

      this.removeProposal = function (proposal) {
        this.proposals = this.proposals.filter(function (pr) {
          return pr.id !== proposal.id
        })
      }

      this.addProposal = function (proposal) {
        this.proposalHighlighted[proposal.id] = true
        this.proposals = this.proposals.concat(proposal)
      }
    },
  ],
})
