angular.module('bzValidationModule').factory('formValidationService', [
  'validationModel',
  function (validationModel) {
    return {
      isValidEmail: function (email, options) {
        let response = null

        if (options) {
          if (options.allowBlank && email === '') {
            response = true
          }
        }

        if (response !== null) {
          return response
        } else {
          // This regex is a mirror of EmailAddress::EMAIL_REGEX on the backend.
          let re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i
          return re.test(email)
        }
      },
      isValidPhone: function (phone) {
        if (!phone) {
          return false
        }

        const onlyDigitsPhone = phone.replace(/\D/g, '')

        if (
          onlyDigitsPhone &&
          onlyDigitsPhone.length &&
          onlyDigitsPhone[0] === '1'
        ) {
          return false
        }

        // check 10 digits and that the first 3 are not the same
        return (
          /^\d{10}$/.test(onlyDigitsPhone) && !/^(\d)\1\1/.test(onlyDigitsPhone)
        )
      },
      isValidZipCode: function (zipcode) {
        if (zipcode) {
          let parsedZipCode = parseInt(zipcode)
          if (isNaN(parsedZipCode)) {
            return false
          } else {
            return parsedZipCode < 501 || parsedZipCode > 99950
              ? false
              : /^\d{5}(-\d{4})?$/.test(zipcode)
          }
        } else {
          return false
        }
      },
      isValidZipCodeFromDataset: function (zipcode) {
        return validationModel.verifyZipcode(zipcode)
      },
      isValidDate: function (date) {
        return !date || isNaN(new Date(date.toString())) ? false : true
      },
      isDateMinYearsAgo: function (date, minYearsAgo) {
        let parts = date ? date.match(/(\d+)/g) : null

        if (parts && parts.length === 3 && parts[2] && parts[2].length === 4) {
          date = `${parts[2]}/${parts[0]}/${parts[1]}`
        }

        let _date = typeof date === 'string' ? new Date(date) : date

        return (
          this.isValidDate(_date) &&
          new Date(
            _date.getFullYear() + minYearsAgo,
            _date.getMonth(),
            _date.getDate()
          ) <= new Date()
        )
      },
    }
  },
])
