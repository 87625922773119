/* global angular, Storage, sessionStorage */
angular
  .module('bzLocalStorageModule')
  .factory('sessionStorageModel', function () {
    return {
      isPresent: function () {
        try {
          sessionStorage.setItem('test', '1')
          var testValue = sessionStorage.getItem('test')
          sessionStorage.removeItem('test')

          if (testValue) {
            return true
          } else {
            return false
          }
        } catch (error) {
          if (typeof Storage !== 'undefined') {
            Storage.prototype._setItem = Storage.prototype.setItem
            Storage.prototype.setItem = function () {}
          }

          /*eslint-disable */
          console.log(
            'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.'
          )
          /*eslint-enable */
          return false
        }
      },
      set: function (key, value) {
        if (this.isPresent()) {
          if (key && value) {
            sessionStorage.setItem(key, JSON.stringify(value))
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      get: function (key) {
        if (this.isPresent()) {
          if (key) {
            var value = sessionStorage.getItem(key, JSON.stringify(value))
            return value ? JSON.parse(value) : value
          } else {
            return false
          }
        } else {
          return false
        }
      },
      remove: function (key) {
        if (this.isPresent()) {
          if (key) {
            sessionStorage.removeItem(key)
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
    }
  })
