/* global angular */
angular.module('bzProjectFormModule').directive('urgencyOptions', [
  'urgencyTypesModel',
  function (urgencyTypesModel) {
    return {
      restrict: 'AE',
      replace: true,
      template:
        '<select class="form-control modern-form-control" ng-model="formData.urgency" ng-options="s.value as s.text for s in urgencyOptions"></select>',
      link: function (scope, element, attr) {
        scope.urgencyOptions = urgencyTypesModel.getInitial()
      },
    }
  },
])
