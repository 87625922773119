import { contains } from 'ramda'

export const adjustUrlFormat = (url, urlType) => {
  if (urlType) {
    const indexOfUrlType = url.indexOf(urlType)
    if (indexOfUrlType) {
      const urlPrefix =
        urlType === 'maps' ? 'https://www.google.com/' : 'https://www.'
      return {
        success: true,
        formattedUrl: urlPrefix + url.substring(indexOfUrlType),
      }
    }
    return { success: false, formattedUrl: null }
  } else {
    const urlRegex = /(http[s]?:\/\/)?([^\.]*)(.*\..*)/g
    // Group 1: scheme, group 2: subdomain, group 3: everything else (including leading dot)
    let matches = urlRegex.exec(url)
    if (matches === null) {
      return { success: false, formattedUrl: null }
    }
    let formattedUrl = url
    if ((matches[3].match(/\./g) || []).length === 1) {
      formattedUrl = `${matches[1] || ''}www.${matches[2]}${matches[3]}`
    }

    if (matches[1] === undefined) {
      formattedUrl = `http://${url}`
    }

    return { success: true, formattedUrl: formattedUrl }
  }
}

export const validatePublicProfileUrl = (url, urlType) => {
  if (urlType === 'facebook' && contains('facebook.com/search', url)) {
    return false
  } else if (urlType === 'yelp' && !contains('/biz', url)) {
    return false
  } else if (urlType === 'maps' && !contains('/maps', url)) {
    return false
  }
  return true
}
