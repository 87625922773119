import template from './decline_lead_screen.html'

const declineLeadScreen = {
  bindings: {
    lead: '<',
    dismiss: '<',
  },
  template,
  controller: [
    'declineLeadService',
    'urlConstants',
    'trackDeclineLeadService',
    'contractorProjectPreferencesModel',
    'attributeConversionService',
    function (
      declineLeadService,
      urlConstants,
      trackDeclineLeadService,
      contractorProjectPreferencesModel,
      attributeConversionService
    ) {
      this.$onInit = function () {
        this.surveyModalData = {
          title: 'Not interested in this project',
          surveyQuestion: "Why wasn't this a good fit?",
          explanationObjs: declineLeadService.randomOrderExplanationObjs(),
        }
        this.setProjectPreferencesTaxonomy()

        this.screen = 'survey'
      }

      this.archiveLead = function (explanation) {
        this.lead
          .respond({
            interested: false,
            explanation: declineLeadService.explanation(explanation),
          })
          .then(this.handleArchive.bind(this, explanation))
        this.lead.setNotInterested()
      }

      this.handleArchive = function (explanation) {
        trackDeclineLeadService.archive(this.lead, explanation)
        this.notInterestedExplanation = explanation
        this.setScreenSequence(explanation)
        this.showNextScreen()
      }

      this.setProjectPreferencesTaxonomy = function () {
        contractorProjectPreferencesModel
          .getProjectPreferences(this.lead.contractor.id)
          .then(
            function (data) {
              this.projectPreferencesTaxonomy =
                attributeConversionService.underscoreToCamelDeepAttributes(
                  data.data.taxonomy
                )
            }.bind(this)
          )
      }

      this.setScreenSequence = function (explanation) {
        this.screenSequence = declineLeadService.screenSequence(explanation)
      }

      this.showNextScreen = function () {
        this.setScreen(this.nextScreen())
      }

      this.showPrevScreen = function () {
        this.setScreen(this.prevScreen())
      }

      this.nextScreen = function () {
        return this.screenSequence[this.screenIdx() + 1] || this.screen
      }

      this.prevScreen = function () {
        return this.screenSequence[this.screenIdx() - 1] || this.screen
      }

      this.screenIs = function (val) {
        return this.screen === val
      }

      this.setScreen = function (val) {
        return (this.screen = val)
      }

      this.screenIdx = function () {
        return this.screenSequence.indexOf(this.screen)
      }

      this.showBack = function () {
        return this.screenSequence && this.screenIdx() > 0
      }

      this.redirectToLeads = function () {
        if (
          document.location['pathname'] !==
          urlConstants.contractor.leadsDashboardURL
        ) {
          document.location = urlConstants.contractor.leadsDashboardURL
        } else {
          this.dismiss()
        }
      }
    },
  ],
}

export default declineLeadScreen
