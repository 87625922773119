import template from './bz_score_factors_chart.html'

const bzScoreFactorsChart = {
  bindings: {
    contractor: '<',
  },
  template,
  controller: [
    'bzScoreService',
    '$scope',
    function (bzScoreService, $scope) {
      this.$onInit = function () {
        this.radius = this.chartRadius()
        this.strokeWidth = this.radius / 3
        this.segmentSpacing = this.strokeWidth / 18
        this.svgSize = this.radius * 2 + this.strokeWidth

        this.segments = [
          {
            color: '#48cdde',
            range: [20, 60],
            label: {
              text: 'Responsiveness<span class="hidden-sm hidden-xs"> and communication<span>',
              class: 'responsiveness-label',
              pos: this.posOnCircle(40),
            },
            description:
              'A responsive and communicative contractor is dramatically better to work with. Pros who are more quick to respond and who are more attentive to communication and timeliness are rated more highly.',
          },
          {
            color: '#2bbda6',
            range: [60, 88],
            label: {
              text: 'Additional factors',
              class: 'additional-label',
              pos: this.posOnCircle(79),
            },
            description:
              'BuildZoom ensures that contractors follow best business practices for insurance, bonds, etc., and reflects that accordingly in the BuildZoom Score.',
          },
          {
            color: '#4abde9',
            range: [88, 205],
            label: {
              text: 'Work experience',
              class: 'experience-label',
              pos: this.posOnCircle(147),
            },
            description:
              'Experience is key when choosing a contractor. BuildZoom holds the largest collection of construction permit data in the world, and rewards pros with the most extensive project histories.',
          },
          {
            color: '#258fb2',
            range: [205, 300],
            label: {
              text: 'Customer feedback',
              class: 'feedback-label',
              pos: this.posOnCircle(253),
            },
            description:
              'Knowing how well a contractor performed in previous jobs is critical. BuildZoom considers reviews from our own site as well as from around the web, and incorporates those reviews to appropriately boost (or decrement) the BuildZoom Score.',
          },
          {
            color: '#57bdb0',
            range: [300, 20],
            label: {
              text: 'Licensing<span class="hidden-sm hidden-xs"> and certification<span>',
              class: 'license-label',
              pos: this.posOnCircle(340),
            },
            description:
              'BuildZoom manually verifies the license of every contractor before connecting them with homeowners, and any contractor without a valid license immediately loses all their BZ Score points.',
          },
        ]

        this.clearSegment()
      }

      this.posOnCircle = function (theta, strokeOffset) {
        return bzScoreService.posOnCircle(theta, this.radius, strokeOffset || 0)
      }

      this.coordsForSegmentEndpoint = function (theta) {
        var pos = this.posOnCircle(theta, this.strokeWidth / 2)

        return [pos.left, pos.top].join(' ')
      }

      this.pathFor = function (segment) {
        return (
          'M' +
          this.coordsForSegmentEndpoint(
            segment.range[0] + this.segmentSpacing
          ) +
          ' ' +
          'A ' +
          this.radius +
          ' ' +
          this.radius +
          ' 0, 0, 1, ' +
          this.coordsForSegmentEndpoint(segment.range[1] - this.segmentSpacing)
        )
      }

      this.selectSegment = function (segment) {
        this.activeSegment = segment
        $scope.$applyAsync()
      }

      this.clearSegment = function () {
        this.activeSegment = null
        $scope.$applyAsync()
      }

      this.isSegmentHidden = function (segment) {
        return !!this.activeSegment && this.activeSegment !== segment
      }

      this.isSegmentActive = function (segment) {
        return !!this.activeSegment && this.activeSegment === segment
      }

      this.onDeselect = function (segment) {
        if (isMobile.any()) this.clearSegment()
      }

      this.chartRadius = function () {
        return $(window).width() < 992 ? 55 : 95
      }
    },
  ],
}

export default bzScoreFactorsChart
