import template from './button_with_spinner.html'

const ButtonWithSpinnerModule = angular
  .module('buttonWithSpinner', [])
  .component('buttonWithSpinner', {
    bindings: {
      onClick: '&?',
      className: '<',
      disabled: '<',
      disabledText: '<',
      ctaText: '<',
      loadingText: '<',
      succeededText: '<',
      failedText: '<',
      height: '<',
    },
    template,
    controller: [
      '$timeout',
      function ($timeout) {
        this.$onInit = () => {
          this.CTA_STATE = 'cta'
          this.LOADING_STATE = 'loading'
          this.SUCCEEDED_STATE = 'succeeded'
          this.FAILED_STATE = 'failed'

          this.STATES = [
            this.CTA_STATE,
            this.LOADING_STATE,
            this.SUCCEEDED_STATE,
            this.FAILED_STATE,
          ]

          this.height = this.height || 46 // this is in px always
          this.disabled = this.disabled || false

          this.setTextDefaults()

          this.setState(this.CTA_STATE)
        }

        this.setTextDefaults = () => {
          this.disabledText = this.disabledText || 'Disabled'
          this.ctaText = this.ctaText || 'Submit'
          this.loadingText = this.loadingText || 'Loading...'
          this.succeededText = this.succeededText || 'Thank you!'
          this.failedText = this.failedText || 'Error - Please try again'
        }

        this.setState = (state) => {
          if (this.STATES.indexOf(state) < 0) return // dont set non-existing state

          this.state = state
        }

        this.isClickable = () => !this.disabled && this.state === this.CTA_STATE

        this.click = ($event) => {
          $event.preventDefault() // todo: consider adding propagateClick (boolean) flag on init
          if (!this.isClickable()) return

          this.onClick({
            buttonActions: {
              showCta: () => {
                this.setState(this.CTA_STATE)
              },
              showLoading: () => {
                this.setState(this.LOADING_STATE)
              },
              showSucceeded: () => {
                this.setState(this.SUCCEEDED_STATE)
              },
              showFailed: () => {
                this.setState(this.FAILED_STATE)
              },
              flashFailed: (ms) => {
                this.setState(this.FAILED_STATE)
                $timeout(() => {
                  this.setState(this.CTA_STATE)
                }, ms)
              },
            },
          })
        }
      },
    ],
  }).name

export default ButtonWithSpinnerModule
