/* global angular */
angular.module('bzReviewsModule').factory('reviewFormScreenService', [
  '$anchorScroll',
  'trackReviewFormService',
  function ($anchorScroll, trackReviewFormService) {
    return {
      screens: ['base', 'user', 'details', 'post_review'],
      currentScreen: null,
      init: function () {
        this.goTo('base')
      },
      isActive: function (screen) {
        return this.currentScreen === screen
      },
      goTo: function (screen) {
        trackReviewFormService.viewStep(screen, {})
        $anchorScroll()
        this.currentScreen = screen
      },
      isFirstScreen: function () {
        return this.screens.indexOf(this.currentScreen) === 0
      },
      allowBack: function () {
        return !this.isFirstScreen()
      },
      previousScreen: function () {
        const currentScreenIndex = this.screens.indexOf(this.currentScreen)

        if (
          UserData.user_signed_in() &&
          this.screens[currentScreenIndex - 1] === 'user'
        ) {
          return this.screens[currentScreenIndex - 2]
        } else {
          return this.screens[currentScreenIndex - 1]
        }
      },
      goBack: function () {
        if (this.allowBack()) {
          trackReviewFormService.clickBack({})
          this.goTo(this.previousScreen(this.currentScreen))
        } else {
          return false
        }
      },
      reloadPage: function () {
        window.location.reload()
      },
      complete: function () {
        trackReviewFormService.complete({})
      },
    }
  },
])
