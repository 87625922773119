/* global bz_app, UserData */
bz_app.factory('extModalService', [
  '$uibModal',
  'urlService',
  function ($uibModal, urlService) {
    return {
      show: function (inputData) {
        if (!inputData) {
          inputData = {}
        }

        var modalInstance = $uibModal.open({
          templateUrl: urlService.templateUrl(
            '_components/directives/ext_modal'
          ),
          controller: 'ExtModalCtrl',
          windowClass: 'modal-modern ext-modal',
          resolve: {
            inputData: function () {
              return inputData
            },
          },
        })

        return modalInstance
      },
    }
  },
])
