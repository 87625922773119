/* global angular, Track */
angular.module('bzNavbarModule').directive('leadsLink', [
  function () {
    return {
      restrict: 'AE',
      replace: false,
      scope: {},
      link: function (scope, element, attrs) {
        element.on('click', function () {
          Track.send_link('#' + attrs.id, 'Navbar Action', {
            action: 'contractor lead link click',
          })
        })
      },
    }
  },
])
