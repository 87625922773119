const connectionsMessageModel = [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiPath: urlConstants.api.v1.path + 'connections_messages',
      sendMessageFromUser: function (connectionId, body, attachments) {
        return this.sendMessage({
          connectionId: connectionId,
          body: body,
          senderType: 'user',
          attachmentIds: this.objectToAttributeArray(attachments, 'id'),
        })
      },
      sendMessageFromContractor: function (connectionId, body, attachments) {
        return this.sendMessage({
          connectionId: connectionId,
          body: body,
          senderType: 'contractor',
          attachmentIds: this.objectToAttributeArray(attachments, 'id'),
        })
      },
      sendBidRequestMessageToCo: function (connectionId) {
        return $http
          .post(`${this.apiPath}/send_bid_request_message_to_co`, {
            connection_id: connectionId,
            sender_type: 'user',
          })
          .then(this.extractData)
      },
      sendMessage: function (options) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(options)

        return $http.post(this.apiPath, data).then(this.extractData)
      },
      objectToAttributeArray: function (objects, attribute) {
        var result = []

        if (objects && objects.length) {
          angular.forEach(objects, function (object) {
            result.push(object[attribute])
          })
        }

        return result
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
    }
  },
]

export default connectionsMessageModel
