/* global angular */
angular.module('bzProjectFormModule').component('nameScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/name'
      )
    },
  ],
  controller: [
    '$timeout',
    'projectFormScreenService',
    function ($timeout, projectFormScreenService) {
      this.$onInit = () => {
        // add callback to be triggered when screen is changed to re-focus name field cursor
        projectFormScreenService.setScreenCallbacks.push(() => {
          projectFormScreenService.focusInputOnScreenShow(
            'name',
            '.project-owner-name'
          )
        })
      }

      this.validateBeforeNextScreen = function () {
        var hasErrors = false
        this.resetErrors()

        if (!this.project.projectOwner.fullName) {
          this.errors.fullName = 'Please enter your full name'
          hasErrors = true
        }

        if (!hasErrors && projectFormScreenService.isCurrentScreen('name')) {
          projectFormScreenService.showNextScreen()
        }
      }

      this.keyboardToNextScreen = function ($event) {
        if ($event.keyCode === 13) {
          $event.target.blur()

          $timeout(
            function () {
              this.validateBeforeNextScreen()
            }.bind(this),
            200
          )
        }
      }

      this.resetErrors = function () {
        if (!this.errors) {
          this.errors = {}
        }

        this.errors.fullName = null
      }
    },
  ],
})
