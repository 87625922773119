const UnsubscribeFromProjectsModalCtrl = [
  '$uibModalInstance',
  'communicationPreferenceModel',
  'inputData',
  function ($uibModalInstance, communicationPreferenceModel, inputData) {
    this.$onInit = () => {
      const { medium, onUnsubscribe, onClose } = inputData

      this.medium = medium
      this.onUnsubscribe = onUnsubscribe
      this.onClose = onClose
    }

    this.close = () => {
      this.onClose(() => $uibModalInstance.close('ok'))
    }

    this.unsubscribe = function ($event) {
      this.onUnsubscribe(() => $uibModalInstance.dismiss('cancel'))

      if ($event) {
        $event.preventDefault()
      }
    }
  },
]

export default UnsubscribeFromProjectsModalCtrl
