import template from './create_calendar_reminder_modal.html'
import ics from 'Vanilla/services/ics'

export default {
  bindings: {
    resolve: '<',
    close: '&?',
  },
  template,
  controller: [
    '$window',
    'modelService',
    function ($window, modelService) {
      this.$onInit = () => {
        this.message = modelService.toCamel(this.resolve.inputData.message)
        this.chronoDate = this.resolve.inputData.chronoDate
        this.track = this.resolve.inputData.track
        this.serviceRequest = this.message.serviceRequest

        this.defaultModalTrackingParams = {
          eventText: this.chronoDate.text,
          eventDate: this.chronoDate.start.date(),
        }

        this.setCalendarData()
      }

      this.setCalendarData = () => {
        const { city, state } = this.serviceRequest.location
        this.location = this.serviceRequest.streetAddress || `${city}, ${state}`
        this.recipient =
          this.message.recipient.name ||
          (UserData.is_contractor() ? 'client' : 'contractor')

        if (UserData.is_contractor()) {
          this.subject = `${this.location} - ${this.serviceRequest.title} for ${this.recipient}`
        } else {
          this.subject = `Appointment with ${this.recipient} - ${this.serviceRequest.title}`
        }
      }

      this.convertDate = (date) =>
        new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, '')

      this.googleLink = () => {
        return (
          'https://calendar.google.com/calendar/r/eventedit' +
          modelService.queryString(this.googleCalendarParams())
        )
      }

      this.googleCalendarParams = () => {
        const start = this.chronoDate.start.date()
        const end = this.chronoDate.end
          ? this.chronoDate.end.date()
          : start.getTime() + 3600 * 1000

        return {
          trp: true,
          sf: true,
          sprop: 'name:',
          text: this.subject,
          dates: `${this.convertDate(start)}/${this.convertDate(end)}`,
          details: '',
          location: '',
        }
      }

      this.downloadICS = (eventType) => {
        const start = this.chronoDate.start.date()
        const end = this.chronoDate.end
          ? this.chronoDate.end.date()
          : start.getTime() + 3600 * 1000
        const ext = eventType === 'ical' ? '.ics' : '.vcs'

        const cal = new ics()
        cal.addEvent(this.subject, '', '', start, end)
        cal.download(this.serviceRequest.id, ext)
      }

      this.createEvent = (eventType) => {
        this.track(
          `click export to: ${eventType}`,
          this.defaultModalTrackingParams
        )

        switch (eventType) {
          case 'google':
            return $window.open(this.googleLink(), '_blank')
          case 'ical':
          case 'outlook':
            return this.downloadICS(eventType)
        }

        this.close()
      }

      this.events = [
        { eventType: 'google', label: 'Google Calendar' },
        { eventType: 'ical', label: 'iCal' },
        { eventType: 'outlook', label: 'Outlook' },
      ]
    },
  ],
}
