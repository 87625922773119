/* global UserData */
bz_app.factory('userInfoService', function () {
  return {
    id: UserData.user_id(),
    userInfoType: 'messages/userinfo',
    thumbnail_url: UserData.thumbnail_url(),
    role: UserData.user_role(),
    current_staff_user: UserData.current_staff_user(),
    participant: function (conversation, user_id) {
      return conversation.users[user_id]
    },
    email_participant: function (conversation, contractor, homeowner) {
      //sender is contractor
      if (conversation.last_message.sender_id == contractor.id) {
        return contractor
      } else {
        return homeowner
      }
    },
  }
})
