const unclaimedDeclineLeadScreen = {
  bindings: {
    resolve: '<',
    close: '&?',
  },
  template: `
		<div class="modal-content-wrapper">
			<button type="button" class="close" ng-click="$ctrl.close()">
				&times;
			</button>
			<div class="decline-lead-subscreen">
				<survey-screen data="$ctrl.surveyModalData" lead="$ctrl.lead"
					on-submit="$ctrl.archiveLead(explanation)">
				</survey-screen>
			</div>
		</div>
				`,
  controller: [
    'declineLeadService',
    'urlConstants',
    'trackDeclineLeadService',
    'modalScreenService',
    'attributeConversionService',
    function (
      declineLeadService,
      urlConstants,
      trackDeclineLeadService,
      contractorProjectPreferencesModel,
      modalScreenService,
      attributeConversionService
    ) {
      this.$onInit = function () {
        Object.assign(this, this.resolve.inputData)
        this.surveyModalData = {
          title: 'Not interested in this project',
          surveyQuestion: "Why wasn't this a good fit?",
          explanationObjs: declineLeadService.randomOrderExplanationObjs(),
        }
      }

      this.archiveLead = function (explanation) {
        this.lead
          .respond({
            interested: false,
            explanation: declineLeadService.explanation(explanation),
          })
          .then(this.handleArchive.bind(this, explanation))
        this.lead.setNotInterested()
      }

      this.handleArchive = function (explanation) {
        trackDeclineLeadService.archive(this.lead, explanation)
        this.close()
      }
    },
  ],
}

export default unclaimedDeclineLeadScreen
