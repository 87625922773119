// hide the browser advisory or admin REFRESH page banner
$('.close-reveal-div').click(function (e) {
  e.preventDefault()
  $(this)
    .parents('#greeting:first, #browser_advisory:first')
    .slideUp(400, function () {
      if (typeof $(e.target).attr('data-hook-closed') !== 'undefined') {
        try {
          /*eslint-disable */
          eval($(e.target).attr('data-hook-closed') + '()')
          /*eslint-enable */
        } catch (e) {
          console.error(e)
        }
      }
    })
})
