/* global angular */
angular.module('bzProjectFormModule').directive('projectFormTrigger', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        title: '@',
        referralSource: '@',
        contractorId: '<',
        formVersion: '<',
      },
      controller: [
        '$element',
        '$scope',
        'modalScreenService',
        'projectFormData',
        function ($element, $scope, modalScreenService, projectFormData) {
          this.$onInit = function () {
            this.formData = projectFormData
            this.formData.referralSource = this.referralSource

            if (this.contractorId) {
              this.formData.potentialLeadContractorId = this.contractorId
            }

            if (this.title) {
              this.formData.title = decodeURIComponent(this.title)
            }

            this.serviceRequestId = null

            this.projectFormModalService = modalScreenService.init(
              $scope,
              ['main'],
              '',
              `v3-modal-simple project-form-modal`,
              () => {},
              {
                backdrop: 'static',
                keyboard: false,
                template: `
									<project-multi-step-form
										form-version="$ctrl.formVersion"
										form-data="$ctrl.formData"
										service-request-id="$ctrl.serviceRequestId"
										close-modal="$ctrl.closeModal()">
									</project-multi-step-form>
								`,
              }
            )
          }

          this.$postLink = function () {
            $element.click(() => {
              this.openModal()
            })
          }

          this.openModal = function () {
            this.projectFormModalService.setScreen('main')
          }

          this.closeModal = function () {
            this.projectFormModalService.modal.close('ok')
          }
        },
      ],
    }
  },
])
