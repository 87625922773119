const declineLeadModalController = [
  '$uibModalInstance',
  'inputData',
  function ($uibModalInstance, inputData) {
    this.$onInit = function () {
      this.lead = inputData.lead
    }

    this.dismiss = function ($event) {
      $uibModalInstance.dismiss('cancel')

      if ($event) {
        $event.preventDefault()
      }
    }
  },
]

export default declineLeadModalController
