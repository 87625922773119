/* globals $, URLify, BZAPI */
var SearchContractorsAPI = function () {
  this.load = function (location_slug, keywords, filters) {
    if (!keywords) {
      keywords = ''
    }
    return $.when(
      $.get(
        this.search_contractors_api_path() +
          '/' +
          location_slug +
          '/' +
          URLify(keywords.replace(',', '')).toLowerCase(),
        filters
      )
    )
  }

  this.load_sponsored = function (
    location_slug,
    keywords,
    filters,
    excluded_contractor_ids
  ) {
    if (!keywords) {
      keywords = ''
    }

    filters.excluded_contractor_ids = excluded_contractor_ids.join(',')
    return $.when(
      $.get(
        this.search_contractors_api_path() +
          '_sponsored/' +
          location_slug +
          '/' +
          URLify(keywords.replace(',', '')).toLowerCase(),
        filters
      )
    )
  }

  this.load_matches = function (location_slug, keywords, filters) {
    if (!keywords) {
      keywords = ''
    }
    return $.when(
      $.get(
        this.search_contractors_matches_api_path() +
          '?city=' +
          location_slug +
          '&keywords=' +
          URLify(keywords.replace(',', '')).toLowerCase(),
        filters
      )
    )
  }
}

SearchContractorsAPI.prototype = new BZAPI()
SearchContractorsAPI.prototype.constructor = SearchContractorsAPI

window.SearchContractorsAPI = SearchContractorsAPI
