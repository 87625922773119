angular.module('bzProjectFormModule').factory('validationService', [
  'projectFormModel',
  'formValidationService',
  function (projectFormModel, formValidationService) {
    return {
      isValidFullName: function () {
        return projectFormModel.data.fullName &&
          projectFormModel.data.fullName.length
          ? true
          : false
      },
      isValidEmail: function () {
        return formValidationService.isValidEmail(projectFormModel.data.email)
      },
      isValidZipcode: function () {
        return formValidationService.isValidZipCode(
          projectFormModel.data.zipcode
        )
      },
      isFormValid: function () {
        return this.isValidFullName() &&
          this.isValidEmail() &&
          this.isValidZipcode()
          ? true
          : false
      },
    }
  },
])
