import template from './validate_url_input.html'
import {
  adjustUrlFormat,
  validatePublicProfileUrl,
} from 'Vanilla/services/url_validation.js'

angular.module('bzValidationModule').component('validateUrlInput', {
  bindings: {
    url: '=?',
    validationState: '=',
    name: '@',
    domain: '@',
    initialValue: '@',
    placeholder: '@',
    urlType: '@',
    showStateIndicator: '<',
    onStateUpdated: '&',
  },
  template,
  controller: [
    'validationModel',
    '$timeout',
    function (validationModel, $timeout) {
      this.$onInit = function () {
        this._validationStates = validationModel.validationStates
        this._verifyUrlResponses = validationModel.verifyUrlResponses

        this.placeholder = this.placeholder || ''
        this.url = this.initialValue
        this.validationState = this._validationStates.SUCCESS

        if (this.showStateIndicator === undefined) {
          this.showStateIndicator = true
        }
      }

      this.getIconClass = function () {
        if (this._validationStates.ERROR === this.validationState) {
          return 'fas fa-exclamation-circle'
        } else if (this._validationStates.WARNING === this.validationState) {
          return 'fas fa-exclamation-triangle'
        } else if (this._validationStates.LOADING === this.validationState) {
          return 'fas fa-spinner fa-spin'
        } else if (this._validationStates.SUCCESS === this.validationState) {
          return 'fas fa-check-circle'
        }
      }

      this.validateUrl = function () {
        if (!this.url) {
          this.setValidationState(this._validationStates.SUCCESS)
          return
        }

        if (!validatePublicProfileUrl(this.url, this.urlType)) {
          this.setValidationState(this._validationStates.ERROR)
        } else {
          let response = adjustUrlFormat(this.url, this.urlType)
          if (!response.success) {
            this.setValidationState(this._validationStates.ERROR)
            return
          }

          this.setValidationState(this._validationStates.LOADING)

          validationModel
            .asyncIsValidUrl(response.formattedUrl, { domain: this.domain })
            .then((urlStatus) => {
              if (urlStatus === this._verifyUrlResponses.EXISTS) {
                this.setValidationState(this._validationStates.SUCCESS)
              } else if (urlStatus === this._verifyUrlResponses.TIMEOUT) {
                this.setValidationState(this._validationStates.WARNING)
              } else {
                this.setValidationState(this._validationStates.ERROR)
              }
            })
        }
      }

      this.setValidationState = (validationState) => {
        this.validationState = validationState
        this._onStateUpdated(validationState)
      }

      this._onStateUpdated = (validationState) => {
        if (this.onStateUpdated !== undefined) {
          this.onStateUpdated({ state: validationState })
        }
      }
    },
  ],
})
