/* global $, angular, UserAPI */
angular.module('bzUserModule').factory('userService', [
  '$rootScope',
  function ($rootScope) {
    return {
      user_api: new UserAPI(),
      info: {
        signed_in: false,
      },
      resetPassword: function (user_id) {
        return this.user_api.reset_password(user_id)
      },
      show_sign_in_modal: function (post_login_event, post_login_callback) {
        this.set_registration_events(post_login_event, post_login_callback)

        this.user_api.show_sign_in_modal(function (res) {
          angular
            .element('#new_session_Modal .modal-dialog')
            .css('margin-top', '150px')
        })
      },
      show_sign_up_modal: function (
        post_login_event,
        post_login_callback,
        logged_in_callback,
        options
      ) {
        this.set_registration_events(post_login_event, post_login_callback)

        this.user_api.show_sign_up_modal(
          function (res) {
            angular
              .element('#new_session_Modal .modal-dialog')
              .css('margin-top', '150px')
            angular.element('[data-toggle=tooltip]').tooltip()
            if (options && options.contractor) {
              angular
                .element(
                  '#sign_up_contractor_simple .contractor_signup_form_phone_number'
                )
                .mask('(999) 999-9999')
              angular
                .element('#new_session_Modal .modal-header .modal-title')
                .html(this.contractor_value_prop(options.contractor))
            }
          }.bind(this),
          null,
          null,
          logged_in_callback,
          options
        )
      },
      set_registration_events: function (
        post_login_event,
        post_login_callback
      ) {
        if (post_login_event) {
          this.set_login_success_event(post_login_event)
        }

        if (post_login_callback) {
          this.set_login_success_callback(post_login_callback)
        } else {
          this.set_login_success_callback(function () {
            /* eslint-disable */
            document.location = document.location
            /* eslint-enable */
          })
        }
      },
      show_claim_modal: function (
        contractor_id,
        service_request_id,
        interested,
        callback
      ) {
        return this.user_api.show_claim_modal(
          contractor_id,
          service_request_id,
          interested,
          callback
        )
      },
      set_login_success_event: function (post_login_event) {
        // event listener for global event about login success
        $('body').one('login_success', function (e, data) {
          if (post_login_event) {
            $rootScope.$broadcast(post_login_event)
          }
        })
      },
      set_login_success_callback: function (callback) {
        // event listener for global event about login success
        $('body').one('login_success', function (e, data) {
          if (callback) {
            callback()
          }
        })
      },
      status: function () {
        return this.user_api.status()
      },
      logged_in: function (status) {
        return this.user_api.is_status_logged_in(status)
      },
      find_existing_user: function (
        email,
        allow_logged_in,
        successCallback,
        failCallback
      ) {
        return this.user_api
          .find_existing_user(email, allow_logged_in)
          .then(successCallback, failCallback)
      },
      get_payment_settings: function () {
        return this.user_api.payment_settings()
      },
      contractor_value_prop: function (contractor) {
        var prop = "<section id='contractor-value-prop'>"
        prop += '<p>Unlock your free account for '
        prop += contractor.business_name + '.</p>'
        prop += '<p>Get projects and see who visits your profile.</p></section>'

        return prop
      },
    }
  },
])
