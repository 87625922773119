/* global angular */
angular.module('bzUserModule').factory('userBulletinModel', [
  '$http',
  'urlConstants',
  function ($http, urlConstants) {
    return {
      apiUserBulletinPath: urlConstants.api.v3.path + 'user/bulletin',

      create: function (notificationType) {
        return $http
          .post(this.apiUserBulletinPath, {
            notification_type: notificationType,
          })
          .then(this.extractData)
      },
      load: function () {
        return $http.get(this.apiUserBulletinPath).then(this.extractData)
      },
      markRead: function (id) {
        return $http
          .put(this.apiUserBulletinPath + '/' + id)
          .then(this.extractData)
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
    }
  },
])
