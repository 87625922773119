bz_app.factory('contractorReferralsModel', [
  'urlConstants',
  '$http',
  'modelService',
  function (urlConstants, $http, modelService) {
    return {
      apiCoReferPath: urlConstants.api.v3.path + 'contractor_referrals',

      createGeneralReferral: function (emails) {
        return $http
          .post(this.apiCoReferPath, { emails: emails })
          .then(modelService.extractData)
      },

      createLeadReferral: function (referral) {
        return $http
          .post(
            this.apiCoReferPath,
            modelService.toUnderscore({ leadReferral: referral })
          )
          .then(modelService.extractData)
      },
    }
  },
])
