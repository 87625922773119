/* global bz_app, $ */
bz_app.factory('creditCardModel', [
  '$http',
  'urlConstants',
  function ($http, urlConstants) {
    return {
      create: function (data) {
        return $http.post(urlConstants.api.v1.path + 'credit_cards', data)
      },
      delete: function (creditCardId) {
        return $http.delete(
          urlConstants.api.v1.path + 'credit_cards/' + creditCardId
        )
      },
    }
  },
])
