/* global bz_app, angular */
bz_app.directive('pressWithQuotes', function () {
  return {
    restrict: 'AE',
    scope: {},
    link: function (scope, element) {
      element.find('.press-item').mouseover(function () {
        angular.element('.press-item').removeClass('press-item-selected')
        angular.element(this).addClass('press-item-selected')

        angular
          .element('span[class*=press-quote-]')
          .removeClass('press-quote-selected')
        angular
          .element('.press-quote-' + this.id)
          .addClass('press-quote-selected')
      })
    },
  }
})
