import uiRouter from '@uirouter/angularjs'

import AdminContactLinkModule from 'StandaloneComponents/admin_contact_link_module'
import SubscriptionCheckboxesModule from 'StandaloneComponents/subscription_checkboxes_component'
import PropertyReportsModule from 'BundledModules/property_reports/_property_reports_module'

var bz_app = angular.module(
  'bz_app',
  [
    uiRouter,
    'monospaced.elastic',
    'ui.bootstrap',
    'bzLocationModule',
    'bzReviewsModule',
    SubscriptionCheckboxesModule,
    AdminContactLinkModule,
    PropertyReportsModule,
  ].concat(defaultAngularModuleSet)
)

bz_app.config([
  '$httpProvider',
  function ($httpProvider) {
    $httpProvider.defaults.headers.common['xsrfCookieName'] = 'X-XSRF-Token'
  },
])

angular.element(document).ready(function () {
  angular.bootstrap(document, ['bz_app'])
})

window.bz_app = bz_app
