/* global angular */
import { ifElse, includes, without, concat } from 'ramda'

angular.module('bzReviewsModule').factory('reviewInstance', [
  'attributeConversionService',
  'reviewsModel',
  function (attributeConversionService, reviewsModel) {
    return function () {
      this.id = null
      this.serviceRequestId = null
      this.contractorId = null
      this.reviewerId = null
      this.reviewType = null
      this.subject = null
      this.description = null
      this.private = false
      this.privateName = false
      this.visitorId = null
      this.stars = null
      this.source = null
      this.signals = []

      this.init = false

      this.set = function (data) {
        if (data) {
          this.setAttributes(
            attributeConversionService.underscoreToCamelDeepAttributes(data)
          )

          this.init = true

          return true
        } else {
          return false
        }
      }

      this.setAttributes = function (data) {
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
            this[property] = data[property]
            if (property === 'comment') {
              this['description'] = data[property]
            }
          }
        }
      }

      this.save = function () {
        if (this.id) {
          return this.update()
        } else {
          return this.create()
        }
      }

      this.create = function (additionalData) {
        return reviewsModel.create(this)
      }

      this.update = function () {
        return reviewsModel.update(this)
      }

      this.resetSignal = function () {
        this.signals = []
      }

      this.showSignals = function () {
        return this.stars && this.stars !== 3
      }

      this.showNegativeSignals = function () {
        return this.stars && this.stars < 3
      }

      this.showPostiveSignals = function () {
        return this.stars && this.stars > 3
      }

      this.updateShowSignal = function () {
        this.resetSignal()
      }

      this.validateSignals = function () {
        return (
          (this.showSignals() &&
            (this.signals.length > 0 || (this.stars && this.stars > 3))) ||
          !this.showSignals()
        )
      }

      this.validateDescription = function () {
        return this.description && this.description.length < 25
      }

      this.signalDbToDisplay = {
        poor_communicator: 'Poor English',
        rude_to_po: 'Rude',
        missed_meeting: 'Missed appointment',
        stopped_responding: 'Stopped responding',
        overran_schedule: 'Overran schedule',
        overran_budget: 'Overran budget',
        poor_workmanship: 'Poor workmanship',
        took_money_and_quit: 'Took money and quit',
        rude_to_bz: 'Rude to BuildZoom staff',
        lied_about_win_amount: 'Lied about win amount',
        leakage: 'Leakage',
        fraud: 'Fraud',
        on_schedule: 'On schedule',
        on_budget: 'On budget',
        good_workmanship: 'Good workmanship',
        good_communication: 'Good communication',
        organized: 'Organized',
        too_far: 'Too Far',
        too_expensive: 'Too Expensive',
        other: 'Other',
      }

      this.nonHiredSignalValues = function () {
        if (this.showNegativeSignals()) {
          return [
            {
              value: 'stopped_responding',
              alias: this.signalDbToDisplay['stopped_responding'],
            },
            {
              value: 'rude_to_po',
              alias: this.signalDbToDisplay['rude_to_po'],
            },
            {
              value: 'missed_meeting',
              alias: this.signalDbToDisplay['missed_meeting'],
            },
            { value: 'too_far', alias: this.signalDbToDisplay['too_far'] },
            {
              value: 'too_expensive',
              alias: this.signalDbToDisplay['too_expensive'],
            },
            { value: 'other', alias: this.signalDbToDisplay['other'] },
          ]
        } else if (this.showPostiveSignals()) {
          return [
            { value: 'good_communication', alias: 'Good communication' },
            { value: 'organized', alias: 'Organized' },
            { value: 'other', alias: 'Other' },
          ]
        }
      }

      this.hiredSignalValues = function () {
        if (this.showNegativeSignals()) {
          return [
            { value: 'poor_workmanship', alias: 'Poor workmanship' },
            { value: 'overran_budget', alias: 'Overran budget' },
            { value: 'took_money_and_quit', alias: 'Took money and quit' },
            { value: 'overran_schedule', alias: 'Overran schedule' },
            { value: 'too_far', alias: 'Too Far' },
            { value: 'too_expensive', alias: 'Too Expensive' },
            { value: 'other', alias: 'Other' },
          ]
        } else if (this.showPostiveSignals()) {
          return [
            { value: 'on_schedule', alias: 'On schedule' },
            { value: 'on_budget', alias: 'On budget' },
            { value: 'good_workmanship', alias: 'Good workmanship' },
            { value: 'good_communication', alias: 'Good communication' },
            { value: 'organized', alias: 'Organized' },
            { value: 'other', alias: 'Other' },
          ]
        }
        return []
      }

      this.setSignal = function (signal) {
        this.signals = ifElse(
          includes(signal.value),
          without([signal.value]),
          concat([signal.value])
        )(this.signals)
      }

      this.isActiveSignal = function (signal) {
        return this.signals.includes(signal.value)
      }

      this.mapSignalDisplayNames = function (signalValues) {
        return signalValues.map(
          function (signalDbName) {
            return {
              displayName: this.signalDbToDisplay[signalDbName],
              dbName: signalDbName,
            }
          }.bind(this)
        )
      }

      this.getReviewQuality = function () {
        if (this.badReview()) return 'bad'
        if (this.goodReview()) return 'good'
        if (this.greatReview()) return 'great'

        return 'good'
      }

      this.getReviewType = function () {
        if (this.isPosted()) return 'posted'
        if (this.isConnected()) return 'connected'
        if (this.isHired()) return 'hired'

        return 'posted'
      }

      this.badReview = function () {
        return this.stars === 1 || this.stars === 2
      }

      this.goodReview = function () {
        return this.stars === 3 || this.stars === 4
      }

      this.greatReview = function () {
        return this.stars === 5
      }

      this.isPosted = function () {
        return (
          this.reviewType === 'posted' || this.reviewType === 'posted-no-hire'
        )
      }

      this.isConnected = function () {
        return this.reviewType === 'connected'
      }

      this.isHired = function () {
        return this.reviewType === 'hired'
      }
    }
  },
])
