/* global bz_app, angular, LeadsDashboardTrack, UserData */

bz_app.component('leadsList', {
  bindings: {
    leadType: '<',
    page: '<',
    query: '<',
    contractorId: '<',
    showMaydayModal: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrlForDevice(
        'contractor/leads/list',
        '',
        '',
        true
      )
    },
  ],
  controller: [
    '$rootScope',
    '$timeout',
    '$filter',
    '$httpParamSerializer',
    '$scope',
    'attributeConversionService',
    'urlService',
    'bidModalService',
    'leadModel',
    'leadInstance',
    'urlConstants',
    'applicationConstants',
    'orderByFilter',
    'leadsDashboardService',
    'analyticsEventsModel',
    '$uibModal',
    'contractorModel',
    'declineLeadModalService',
    'archiveLeadModalService',
    '$q',
    '$location',
    'modalScreenService',
    function (
      $rootScope,
      $timeout,
      $filter,
      $httpParamSerializer,
      $scope,
      attributeConversionService,
      urlService,
      bidModalService,
      leadModel,
      leadInstance,
      urlConstants,
      applicationConstants,
      orderBy,
      leadsDashboardService,
      analyticsEventsModel,
      $uibModal,
      contractorModel,
      declineLeadModalService,
      archiveLeadModalService,
      $q,
      $location,
      modalScreenService
    ) {
      this.$onInit = function () {
        this.processPotentialAnalyticsEvent()
        this.set()
        this.initLeads()
        this.initialLoad = true
        this.trackDashboardView()
        this.getLeads()
        this.resetSort()
        this.setSortOrder()
        this.bzNumber = applicationConstants.support.phone
        this.bzNumberLink = 'tel:' + applicationConstants.support.phoneNumber
        this.hasAdSubscription = UserData.has_ad_subscription()
        this.showPrioritySelect = {}
      }

      this.set = function () {
        this.spinner = true
        this.setQueryParam = true
        this.pagination = true
        this.pageSize = 20
        this.displaySize = 20
        this.hide = true
        this.allowSort = true
        this.queryParams = $location.search()
        this.defaultLeadType = this.queryParams.lead_type || 'fresh'
        this.setFilters()
        this.initSort()
        this.setLeadType()
      }

      this.processPotentialAnalyticsEvent = function () {
        var maydayReason = getURLParameter('mayday_reason')
        if (maydayReason) {
          var maydayReasonNumber = parseInt(maydayReason)
          this.recordAnalyticsEvent(maydayReasonNumber)
        }
      }

      this.recordAnalyticsEvent = function (maydayReasonNumber) {
        var data = {
          entityType: 'Contractor',
          entityId: UserData.contractor_id(),
          metric: 'selected_mayday_reason_' + maydayReasonNumber,
          location: 'email',
        }
        analyticsEventsModel.create(data)
      }

      this.showLeadsList = function () {
        if (this.tabCounts && this.tabCounts['needs_update'] === 0) {
          this.leadType = this.defaultLeadType
        }
        this.hide = false
        angular.element('.leads-list-wrapper').removeClass('hide')
      }

      this.displayLead = function (index) {
        return index < this.displaySize
      }

      $rootScope.$on(
        'sr_search_complete',
        function (event, data, query) {
          this.leadType = 'search'
          this.query = query
          this.setQueryString()
          this.leads['search'] = []
          if (data.length > 0) {
            this.convertLeads(data, 'search')
          }
          this.tabCounts['search'] = data.length
        }.bind(this)
      )

      this.openBidModal = function (lead) {
        bidModalService.show({
          lead: lead,
          referralSource: 'contractor dashboard',
        })
        $scope.$watch(
          function () {
            return lead.bid
          }.bind(this),
          function (newValue, oldValue) {
            if (newValue !== oldValue) {
              if (this.leadType !== 'closing' && this.leadType !== 'won') {
                leadModel.setOutcome(lead.project.id, 'closing')
                this.moveToNewTab(lead, 'closing')
                this.removeFromOldTab(lead, lead.type)
              }
            }
          }.bind(this)
        )
      }

      this.initLeads = function () {
        this.leads = {}
        for (var i = 0; i < this.leadTypeFilters.length; i++) {
          this.leads[this.leadTypeFilters[i].value] = []
        }
      }

      this.initSort = function () {
        this.sorts = {}
        this.defaultSort = 'updated'
        for (var i = 0; i < this.leadTypeFilters.length; i++) {
          this.sorts[this.leadTypeFilters[i].value] = this.defaultSort
        }
        this.sort = this.sorts[this.leadType]
      }

      this.setFilters = function () {
        this.leadTypeFilters = [
          {
            value: 'not_inactive',
            text: 'All Active',
            textMobile: 'All Active',
            isPrimary: false,
          },
          {
            value: 'fresh',
            text: 'New',
            textMobile: 'New Projects',
            isPrimary: true,
          },
          {
            value: 'contacted',
            text: 'Contacted',
            textMobile: 'Contacted',
            isPrimary: true,
          },
          {
            value: 'meeting_scheduled',
            text: 'Meeting Scheduled',
            textMobile: 'Meeting Scheduled',
            isPrimary: true,
          },
          {
            value: 'preparing_bid',
            text: 'Prepare Bid',
            textMobile: 'Prepare Bid',
            isPrimary: true,
          },
          {
            value: 'closing',
            text: 'Closing',
            textMobile: 'Closing',
            isPrimary: true,
          },
          { value: 'won', text: 'Hired', textMobile: 'Hired', isPrimary: true },
          {
            value: 'needs_update',
            text: 'Needs Update',
            textMobile: 'Needs Update',
            isPrimary: false,
          },
          {
            value: 'archived',
            text: 'Trash',
            textMobile: 'Inactive Projects',
            isPrimary: false,
          },
          {
            value: 'missed',
            text: 'Missed Opportunities',
            textMobile: 'Missed Opportunities',
            isPrimary: false,
          },
        ]

        this.leadTypeFiltersWithNeedsUpdate = this.leadTypeFilters.concat([
          {
            value: 'needs_update',
            text: 'Needs Update',
            textMobile: 'Needs Update',
            isPrimary: false,
          },
        ])
      }

      this.isActiveTab = function (tabType) {
        return this.leadType == tabType
      }

      this.showAllTabs = function () {
        return this.tabCounts['fresh'] > 0 && this.tabCounts['needs_update'] > 0
      }

      this.showArchiveButton = function (lead) {
        return !['archived', 'inactive', 'missed', 'won'].includes(lead.type)
      }

      this.priorityOptions = [
        { id: 0, text: 'low' },
        { id: 1, text: 'high' },
      ]

      this.setPriority = (lead) => {
        this.showPrioritySelect = !this.showPrioritySelect
        leadModel.update(lead.project.id, lead)
      }

      this.leadStatuses = [
        { value: 'pending', text: 'New' },
        { value: 'contacted', text: 'Contacted' },
        { value: 'meeting_scheduled', text: 'Meeting Scheduled' },
        { value: 'preparing_bid', text: 'Prepare Bid' },
        { value: 'closing', text: 'Closing' },
        { value: 'won', text: 'Hired' },
        { value: 'archived', text: 'Trash' },
        { value: 'inactive', text: 'Trash' },
        { value: 'missed', text: 'Missed Opportunity' },
        { value: 'po_unresponsive', text: 'Client Unresponsive' },
      ]

      this.allowSubmitBid = (lead) => {
        return (
          lead.type !== 'won' &&
          this.leadType !== 'fresh' &&
          this.showContactInfo(lead) &&
          !lead.isDisconnectedForNoContact()
        )
      }

      this.valueToText = (val) =>
        (this.leadStatuses.find(({ value }) => value === val) || {}).text

      this.setLeadType = function () {
        if (this.leadType === 'block' || !this.leadType) {
          this.leadType = 'needs_update'
        } else {
          var result = this.leadTypeFiltersWithNeedsUpdate.filter(
            (leadType) => leadType.value == this.leadType
          )
          if (result.length == 0 && !this.leadType) {
            this.leadType = this.defaultLeadType
          }
        }
      }

      this.convertColumnToSort = function (columnName) {
        if (columnName === 'budget') {
          return 'project.budgetRange'
        } else if (columnName === 'bid') {
          return 'bid.price'
        } else if (columnName === 'posted') {
          return 'createdAt'
        } else if (columnName === 'updated') {
          return 'lastEvent.createdAt'
        } else if (columnName === 'project') {
          return 'project.title'
        } else if (columnName === 'priority') {
          return 'priority'
        } else {
          return null
        }
      }

      this.handleQueryParams = () => {
        const params = $location.search()

        if (
          params['lead_type'] === 'archived' &&
          params['lead_notice_type'] === 'deny_hire' &&
          params['lead_notice_id']
        ) {
          this.handleArchiveFromQueryParams(params)
        }
        this.clearQuery()
      }

      this.handleArchiveFromQueryParams = (params) => {
        for (let leadType in this.leads) {
          let leadToArchive = this.leads[leadType].find((lead) => {
            return lead.id === parseInt(params['lead_notice_id'])
          })

          if (leadToArchive) return this.archive(leadToArchive)
        }
      }

      this.leadPageUrl = function (lead) {
        if (lead.isDisconnectedForNoContact()) {
          return lead.project.url + '/summary'
        } else {
          return lead.project.url
        }
      }

      this.archive = function (lead) {
        var previousType = this.leadType
        if (lead.interested) {
          archiveLeadModalService
            .show({
              lead: lead,
            })
            .closed.then(() => {
              if (lead.isArchived()) {
                this.handleLeadAction(lead, 'archived', previousType)
              }
            })
        } else {
          declineLeadModalService.openModal(lead).closed.then(() => {
            if (lead.respondedNotInterested()) {
              this.handleLeadAction(lead, 'archived', previousType)
            }
          })
        }
      }

      this.isActiveSort = function (sort) {
        return this.allowSort && this.sorts[this.leadType] === sort
      }

      this.sortBy = function (columnName) {
        if (this.allowSort) {
          LeadsDashboardTrack({
            contractorId: UserData.contractor_id(),
          }).clickSort()
          this.setSortOrder(columnName)
          this.sort = columnName
          this.sorts[this.leadType] = this.sort
          if (this.allLeadsShownInTab(this.leadType)) {
            this.sortLeads()
            this.setQueryString()
          } else {
            this.getLeads()
          }
        }
      }

      this.moveToNewTab = function (lead, newType) {
        if (newType === 'inactive') {
          newType = 'archived'
        }

        if (this.leads[newType] && this.leads[newType].indexOf(lead) < 0) {
          this.tabCounts[newType]++
          this.leads[newType].push(lead)
          this.leads[newType] = orderBy(
            this.leads[newType],
            this.defaultSort,
            !this.sortOrder
          )
          lead.hide = false
        }
      }

      this.removeFromOldTab = function (lead, previousType) {
        if (previousType === 'inactive') {
          previousType = 'archived'
        }
        if (this.leads[previousType] && this.tabCounts[previousType] !== 0) {
          this.tabCounts[previousType]--
          var index = this.leads[previousType].indexOf(lead)
          if (index >= 0) {
            this.leads[previousType].splice(index, 1)
          }
        }
        this.removeFromNeedsUpdateTab(lead)
      }

      this.removeFromNeedsUpdateTab = function (lead) {
        var needsUpdateIndex = this.leads['needs_update'].indexOf(lead)
        if (needsUpdateIndex >= 0) {
          this.leads['needs_update'].splice(needsUpdateIndex, 1)

          if (this.tabCounts['needs_update'] === 0) {
            $rootScope.$emit('closeGlobalBanner')
            this.clickLeadTypeFilter('fresh')
          }
        }
      }

      this.openReminderModal = function (lead, origin = 'clicked set meeting') {
        const tracker = LeadsDashboardTrack({
          serviceRequestId: lead.project.id,
          contractorId: lead.contractor.id,
        })

        tracker.meetingModalOpened(origin)

        modalScreenService.initDetachedComponent({
          component: 'connectionReminderModal',
          windowClass: 'v3-modal-simple small clear reminder-modal',
          inputData: {
            handleReminderSubmit: this.handleReminderSubmit,
            lead,
            tracker,
          },
        })
      }

      this.handleReminderSubmit = function (lead, meetingDate, sendReminder) {
        return leadModel
          .setMeetingDate(lead.project.id, meetingDate, sendReminder)
          .then(() => {
            lead.meetingReminder.date = meetingDate
            lead.meetingReminder.sendReminder = sendReminder

            return true
          })
      }

      this.isSubtype = function (newType, previousType) {
        return (
          ['met', 'chasing', 'pending'].indexOf(newType) > -1 &&
          ['met', 'chasing', 'pending'].indexOf(previousType) > -1
        )
      }

      this.handleLeadAction = function (lead, newType, previousType) {
        if (
          newType !== previousType &&
          !this.isSubtype(newType, previousType)
        ) {
          lead.moved = true
          lead.hide = true

          $timeout(() => {
            if (previousType === 'inactive') {
              this.moveToNewTab(lead, 'not_inactive')
            }
            this.moveToNewTab(lead, newType)
            this.removeFromOldTab(lead, previousType)
          }, 1000)
        } else if (newType === previousType) {
          for (var i = 0; i < this.leads[newType].length; i++) {
            if (this.leads[newType][i].project.id === lead.project.id) {
              this.leads[newType][i].hidePulse = true
            }
          }
        }
        this.removeFromNeedsUpdateTab(lead)
      }

      this.resetSort = function () {
        this.sortOrder = true
      }

      this.setSortOrder = function (columnName) {
        if (columnName === this.sort) {
          this.sortOrder = !this.sortOrder
        } else {
          this.sortOrder = true
        }
        this.sortOrderParam = this.sortOrder ? 'desc' : 'asc'
      }

      this.sortLeads = function () {
        const sort = this.convertColumnToSort(this.sort)
        switch (sort) {
          case 'project.budgetRange':
            this.leads[this.leadType] = this.leads[this.leadType].sort(
              this.leadBudgetSort.bind(this)
            )
            break
          default:
            this.leads[this.leadType] = orderBy(
              this.leads[this.leadType],
              this.sort,
              this.sortOrder
            )
            break
        }
      }

      this.leadBudgetSort = (leadA, leadB) => {
        const budgetAValue =
          leadsDashboardService.budgetSortValues[leadA.project.budgetRange]
        const budgetBValue =
          leadsDashboardService.budgetSortValues[leadB.project.budgetRange]
        return this.sortOrder === true
          ? budgetBValue - budgetAValue
          : budgetAValue - budgetBValue
      }

      this.numLeadsInTab = function (leadType) {
        if (this.tabCounts) {
          return this.tabCounts[leadType]
        } else {
          return 0
        }
      }

      this.allLeadsShownInTab = function (leadType) {
        return this.numLeadsInTab(leadType) <= this.pageSize
      }

      this.pageChanged = function () {
        this.getLeads()
      }

      this.clearQuery = function () {
        this.query = null
        this.tabCounts['search'] = 0
        $location.search({})
      }

      this.clickLeadTypeFilter = function (leadType) {
        this.clearQuery()
        if (leadType !== this.leadType && !this.initialLoad) {
          this.resetSort()
          this.page = 1
          this.leadType = leadType
          if (!this.tabFullyLoaded(leadType)) {
            this.getLeads()
          }

          this.setQueryString()
          this.trackDashboardView()
        }
      }

      this.selectLeadTypeFilter = function (leadType) {
        if (!this.initialLoad) {
          this.resetSort()
          this.page = 1

          this.setQueryString()
          if (!this.tabFullyLoaded(leadType)) {
            this.getLeads()
          }

          this.setQueryString()
          this.trackDashboardView()
        }
      }

      this.selectableFilters = function () {
        if (this.showNeedsUpdateTab()) {
          return this.leadTypeFiltersWithNeedsUpdate
        } else {
          return this.leadTypeFilters
        }
      }

      this.tabFullyLoaded = function (leadType) {
        if (typeof this.tabCounts === 'undefined') {
          return false
        } else {
          var maxLeadsPerPage =
            this.pageSize < this.numLeadsInTab(leadType)
              ? this.pageSize
              : this.numLeadsInTab(leadType)
          return (
            this.numLeadsInTab(leadType) === 0 ||
            maxLeadsPerPage === this.leads[leadType].length
          )
        }
      }

      this.getLeads = function () {
        this.setLeadType()
        this.loaded = false
        this.setQueryString()
        this.loading = this.spinner
        this.loadLeads(this.leadType, this.sort, this.page)
      }

      this.preloadLeads = function () {
        this.initialLoad = false
        const loadLeadPromises = this.leadTypeFilters
          .map((leadTypeFilter) => {
            if (!this.tabFullyLoaded(leadTypeFilter.value)) {
              return this.loadLeads(leadTypeFilter.value, null, 1)
            }
          })
          .filter((promise) => promise)

        return $q.all(loadLeadPromises)
      }

      this.loadLeads = function (leadType, sort, page) {
        var options = {
          tab: leadType,
          sort: sort,
          sort_order: this.sortOrderParam,
          page: page,
          pageSize: this.pageSize,
          query: this.query,
          initial_load: this.initialLoad,
        }
        return leadModel.index(options).then(
          (response) => {
            var leads = response.data.leads
            this.leads[leadType] = []
            this.convertLeads(leads, leadType)
            this.loading = false

            if (response.data.tab_counts) {
              this.tabCounts = response.data.tab_counts
            }
            this.loaded = true
            if (this.initialLoad) {
              this.preloadLeads()
              this.handleQueryParams()
            }
          },
          () => {}
        )
      }

      this.convertLeads = function (leads, leadType) {
        var lead
        for (var i = 0; i < leads.length; i++) {
          lead = new leadInstance()
          lead.set(leads[i])
          if (this.leads[leadType].indexOf(lead) < 0) {
            this.leads[leadType].push(lead)
          }
        }
      }

      this.showNeedsUpdateTab = function () {
        return this.tabCounts && this.tabCounts.needs_update > 0
      }

      this.needsUpdate = function (lead) {
        return (
          this.isNeedsUpdateView() ||
          (lead.hasStaleLeadBlock() && !lead.hidePulse)
        )
      }

      this.isNeedsUpdateView = function () {
        return this.leadType === 'needs_update'
      }

      this.showContactInfo = function (lead) {
        return (
          lead.isConnected() &&
          !lead.isDisconnectedForNoContact() &&
          !lead.isDeclinedByProjectOwner()
        )
      }

      this.promptToContact = function (lead) {
        return (
          lead.drax &&
          !lead.drax.contacted &&
          lead.isConnected() &&
          !lead.isHiredContractor() &&
          lead.isPostAutoDecline()
        )
      }

      this.updatedAtText = function (lead) {
        const map = {
          'Checkout setup': 'Unpaid Referral Fee',
          'Checkout failed': 'Unpaid Referral Fee',
          'Checkout fully paid': '',
          'Invoice paid': '',
          'CO added note': 'You added a note',
          'CO Uploaded Bid': 'You submitted a bid',
          'CO messaged PO': 'You messaged Client',
          'CO opted into project': 'Contact Client',
          'CO and PO had phone call': 'You called Client',
          'Outcome changed': 'You updated the status',
          'PO Closed Project': 'Client closed project',
          'CO and PO had phone call (PO initiated)': 'Client called you',
          'PO declined CO': 'Declined by Client',
          'PO messaged CO': 'Client messaged you',
          'PO reopened request': 'Project restarted. Contact Client ASAP',
          'PO requested bid': 'Client requested a bid',
          'PO postponed project': 'Client postponed project',
        }

        if (lead.moved) {
          return 'Moved to ' + this.valueToText(lead.type)
        } else {
          return map[lead.lastEvent.eventType]
        }
      }

      this.displayMessageCount = function (lead) {
        return lead.messages && lead.messages.unreadMessageCount > 0
      }

      this.messageCount = function (lead) {
        if (lead.conversation) {
          return lead.conversation.unreadMessageCount
        } else {
          return 0
        }
      }

      this.setQueryString = function () {
        const currentParams = $location.search()
        if (this.setQueryParam) {
          var baseQuery = Object.assign(currentParams, {
            lead_type: this.leadType,
            sort: this.sort,
          })

          if (this.page > 1) {
            baseQuery.page = this.page
          }

          if (this.query) {
            baseQuery.query = this.query
          }

          $location.search(baseQuery)
        }
      }

      this.showMobileIcons = function (lead) {
        return (
          !lead.promptForPayment() &&
          !lead.hasQuestionableWinAmountBlock() &&
          !lead.askToConfirmWin() &&
          !lead.isPaymentRefundable() &&
          UserData.current_staff_user() &&
          !lead.hasOpenPoBidRequest()
        )
      }

      this.noLeadsText = function () {
        switch (this.leadType) {
          case 'search':
            return 'Your search returned no results.'
          case 'not_inactive':
            return 'You have no active projects. When BuildZoom sends you projects, they will appear here.'
          case 'fresh':
            return 'You have no new projects. When BuildZoom sends you projects, they will appear here.'
          case 'contacted':
            return 'You have no projects that you have recently contacted.'
          case 'meeting_scheduled':
            return 'You have no meetings scheduled.'
          case 'preparing_bid':
            return 'You have no projects that need to prepare bids for.'
          case 'closing':
            return 'You have no projects that are ready for closing.'
          case 'won':
            return 'You have not been hired for any BuildZoom projects.'
          case 'archived':
            return 'You have no archived projects.'
          case 'missed':
            return 'You have not missed any opportunities'
        }
      }

      this.goToLeadsDashboard = function () {
        document.location =
          '/manage/contractor/leads?lead_type=' + this.leadType
      }

      this.trackDashboardView = function () {
        LeadsDashboardTrack({ contractorId: UserData.contractor_id() }).view(
          this.leadType
        )
      }

      this.trackLeadView = function (lead) {
        LeadsDashboardTrack({
          serviceRequestId: lead.project.id,
          contractorId: lead.contractor.id,
        }).leadView()
      }

      this.trackMessageClick = function (lead) {
        LeadsDashboardTrack({
          serviceRequestId: lead.project.id,
          contractorId: lead.contractor.id,
        }).clickMessageLink()
      }

      this.trackPhoneClick = function (lead) {
        LeadsDashboardTrack({
          serviceRequestId: lead.project.id,
          contractorId: lead.contractor.id,
        }).trackPhoneLink()
      }
    },
  ],
})
