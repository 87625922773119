bz_app.directive('phoneNumber', [
  'extModalService',
  function (extModalService) {
    return {
      restrict: 'A',
      controllerAs: '$ctrl',
      bindToController: {
        contact: '=?',
        trackCallback: '&',
      },
      scope: {},

      link: function (scope, element) {
        element.click(function ($event) {
          $event.preventDefault()
          scope.$ctrl.trackClick()
          scope.$ctrl.initiateCall()
        })
      },

      controller: [
        function () {
          this.initiateCall = function () {
            if (this.contact.ext) {
              this.initiateExtCall()
            } else {
              document.location = 'tel:' + this.contact.number
            }
          }

          this.initiateExtCall = function () {
            if (isMobile.iOSPhone()) {
              document.location =
                'tel:' + this.contact.number + ',' + this.contact.ext
            } else {
              // show modal and dial number without ext
              extModalService.show({ contact: this.contact })
            }
          }

          this.trackClick = function () {
            if (this.trackCallback) {
              this.trackCallback()
            }
          }
        },
      ],
    }
  },
])
