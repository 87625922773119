export default [
  function () {
    return {
      getAll: function () {
        return [
          { value: 'unlikely secure', text: 'Not started, unlikely to secure' },
          { value: 'likely secure', text: 'Not started, likely to secure' },
          { value: 'in process', text: 'In process' },
          { value: 'secured', text: 'Secured / ready' },
          { value: 'not needed', text: 'Not needed' },
        ]
      },
    }
  },
]
