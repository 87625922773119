/* global angular */
import projectTypeModel from 'StandaloneModels/project_type_model'
import projectMultiStepForm from './components/project_multi_step_form_component'
import surveyScreenComponent from './components/survey_screen_component'
import introScreen from './components/project_multi_step_form/intro_screen_component'

import landOwnershipModel from 'AngularBase/_components/angular/modules/project/models/land_ownership_model'
import designStatusModel from 'AngularBase/_components/angular/modules/project/models/design_status_model'
import permitStatusModel from 'AngularBase/_components/angular/modules/project/models/permit_status_model'
import financeStatusModel from 'AngularBase/_components/angular/modules/project/models/finance_status_model'

angular
  .module('bzProjectFormModule', [
    'swipe',
    'ngMask',
    'bzProjectPreferencesModule',
    'bzCommonUtilitiesModule',
    'bzAnalyticsModule',
  ])
  .factory('projectTypeModel', projectTypeModel)
  .factory('landOwnershipModel', landOwnershipModel)
  .factory('designStatusModel', designStatusModel)
  .factory('permitStatusModel', permitStatusModel)
  .factory('financeStatusModel', financeStatusModel)
  .component('projectMultiStepForm', projectMultiStepForm)
  .component('surveyScreenComponent', surveyScreenComponent)
  .component('introScreen', introScreen)

angular.module('bzProjectFormModule').config([
  '$httpProvider',
  '$compileProvider',
  function ($httpProvider, $compileProvider) {
    $httpProvider.useApplyAsync(true)
    $httpProvider.defaults.headers.common['xsrfCookieName'] = 'X-XSRF-Token'

    $compileProvider.debugInfoEnabled(false)
  },
])
