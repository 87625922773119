angular.module('bzProjectFormModule').directive('inputError', function () {
  return {
    restrict: 'AE',
    replace: true,
    scope: {
      errorText: '@',
      errorVisible: '=',
      errorValidator: '&',
    },
    template:
      '<div ng-class="{ collapse: hideError() }" class="text-danger sr_form_error" ng-bind="errorText"></div>',
    link: function (scope, element, attr) {
      scope.hideError = function () {
        if (scope.errorVisible && !scope.errorValidator()) {
          return false
        } else {
          return true
        }
      }
    },
  }
})
