const SubscriptionCheckboxModule = angular
  .module('subscriptionCheckbox', [])
  .component('subscriptionCheckbox', {
    bindings: {
      subscription: '<',
      onSubscriptionToggle: '&',
      showDetail: '<',
    },
    templateUrl: [
      'urlService',
      function (urlService) {
        return urlService.templateUrlForDevice(
          'communication_preferences/subscription_checkbox'
        )
      },
    ],
    controller: [
      'applicationConstants',
      function (applicationConstants) {
        this.isActive = () =>
          UserData.is_admin_or_impersonating() ||
          !this.isDisabledType() ||
          !this.subscription.subscribed

        this.isDisabledType = () =>
          this.subscription.type.indexOf('account_activity_subscribed_email') >
            -1 ||
          this.subscription.type.indexOf('messages_subscribed_email') > -1
      },
    ],
  }).name
export default SubscriptionCheckboxModule
