/* globals angular */

angular.module('bzContractorModule').factory('contractorData', [
  '$rootScope',
  '$timeout',
  function ($rootScope, $timeout) {
    return {
      id: '',
      business_name: '',
      email: '',
      phone_number: '',
      twilio_number_hash: '',
      init: false,

      set: function (input) {
        var that = this
        // make sure data bindings are activated
        $timeout(function () {
          $rootScope.$apply(function () {
            that.id = input.nid
            that.business_name = input.business_name
            that.email = input.email
            that.phone_number = input.phone_number
            that.twilio_number_hash = input.twilio_number_hash
            that.init = true
          })
        }, 0)
      },
    }
  },
])
