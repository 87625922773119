import template from './lead_referral_form_fields.html'

const leadReferralFormFields = {
  bindings: {
    referral: '<',
  },
  template,
  controller: [
    '$element',
    function ($element) {
      this.$onInit = function () {
        this.phoneElement = $element.find('.lead-referral-form-phone')
        this.maskPhone()
      }

      this.handleBusinessNameChange = function () {
        this.referral.clearError()
        this.referral.contractorId = null
      }

      this.handlePhoneChange = function () {
        this.referral.clearError()
        this.maskPhone()
      }

      this.maskPhone = function () {
        this.phoneElement.mask('(999) 999-9999')
      }
    },
  ],
}

export default leadReferralFormFields
