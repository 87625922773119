/* globals angular, google, $, LocationAPI, URLify */
import GoogleSessionToken from 'Vanilla/services/google_session_token_service'
import { deprecatedParseGoogleLocationApiDetails } from 'GlobalShared/utilities/googleGeocoder'
import AutocompleteApi from 'Vanilla/api/autocomplete_api'

const googleSessionTokenService = new GoogleSessionToken()
const autocompleteApi = new AutocompleteApi()
angular.module('bzLocationModule').factory('locationService', [
  '$q',
  '$http',
  'userLocationModel',
  'localStorageModel',
  function ($q, $http, userLocationModel, localStorageModel) {
    let autocompleteService = function () {
      return new google.maps.places.AutocompleteService()
    }

    // return promise for location lookup data
    let find_promise = function (query, types) {
      let deferred = $q.defer() // instance of promise for typeahead suggestions

      // get typeahead suggestions from google API
      autocompleteService().getPlacePredictions(
        {
          input: query,
          types: types,
          componentRestrictions: { country: 'us' },
          sessionToken: googleSessionTokenService.getToken(),
        },
        function (predictions, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            deferred.resolve(predictions)
          } // send in found suggestions into promise resolver
          else {
            deferred.reject(status)
          }
        }
      )

      return deferred.promise // return promise for future resolution
    }

    return {
      /** city typeahead data*/
      find_city: (query) =>
        autocompleteApi
          .autocompleteCity(query, $http)
          .then((response) => response.data.results),
      /** state typeahead data*/
      find_state: (query) =>
        autocompleteApi
          .autocompleteState(query, $http)
          .then((response) => response.data.results),
      /** county typeahead data*/
      find_county: (query) =>
        autocompleteApi
          .autocompleteCounty(query, $http)
          .then((response) => response.data.results),
      /** address typeahead data*/
      find_address: function (query, format, options) {
        if (!format) {
          format = { street: true, city: true, state: true, country: false }
        }

        return find_promise(query, ['geocode']).then(function (response) {
          return $.map(response, function (response_item) {
            if (
              response_item.description !== undefined &&
              response_item.terms !== undefined &&
              response_item.terms.length &&
              response_item.terms.length >= 4
            ) {
              var location = response_item.description.split(',')
              location.pop()
              var address = []

              if (format.street) {
                address.push(location[0])
              }
              if (format.city) {
                address.push(location[1])
              }
              if (format.state) {
                address.push(location[2])
              }
              if (format.country) {
                address.push(location[3])
              }

              if (options && options.returnFullObject) {
                return {
                  street: location[0],
                  city: location[1],
                  state: location[2],
                }
              } else {
                return address.join(',')
              }
            }
          })
        })
      },
      find_details: function (query) {
        let deferred = $q.defer()
        let geocoder = new google.maps.Geocoder()

        geocoder.geocode({ address: query }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            deferred.resolve(results)
          } else {
            deferred.reject(status)
          }
        })

        return deferred.promise.then((results) => {
          return deprecatedParseGoogleLocationApiDetails(results)
        })
      },
      findByCurrentIp: function () {
        var location_api = new LocationAPI()
        userLocationModel.data.setPending(true)

        return location_api
          .load_by_current_ip()
          .then(function (locationDetails) {
            if (locationDetails && locationDetails.city) {
              localStorageModel.set('userLocationData', locationDetails) // set in localStorage for later re-use
              userLocationModel.data.set(locationDetails) // set in model's data object for current use
            }
          })
      },
      to_slug: function (city_state) {
        var slug = URLify(
          city_state.replace(/(\s*)?,(\s*)?/, ' ')
        ).toLowerCase()
        var cityOnlySlugs = [
          'los-angeles-ca',
          'chicago-il',
          'houston-tx',
          'miami-fl',
          'seattle-wa',
        ]

        if (cityOnlySlugs.indexOf(slug) >= 0) {
          var citySlugArray = slug.split('-')
          citySlugArray.pop()
          slug = citySlugArray.join('-')
        }

        return slug
      },
      locationMapUrl: function (location) {
        return (
          '/map?full_address=' +
          location.alias +
          '&lat=' +
          location.lat +
          '&lng=' +
          location.lng +
          '&sort=proximity&zoom=19'
        )
      },
    }
  },
])
