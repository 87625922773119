import GoogleSessionToken from 'Vanilla/services/google_session_token_service'

/* globals google */
let googleSessionTokenService = new GoogleSessionToken()
let autocompleteService = new google.maps.places.AutocompleteService()
let geocoder = new google.maps.Geocoder()

export const googleGeocode = (query) => {
  return new Promise((resolve) => {
    geocoder.geocode({ address: query }, (results) => {
      let parsedResults = parseGoogleLocationApiDetails(results)
      resolve(parsedResults)
    })
  })
}

export const findAutocompletedAddressResults = (query, onLoad) => {
  autocompleteService.getPlacePredictions(
    {
      input: query,
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
      sessionToken: googleSessionTokenService.getToken(),
    },
    (predictions, status) => {
      if (status === 'OK') {
        const addresses = predictions.map(({ description }) => ({
          value: description,
          label: description,
        }))
        onLoad(addresses)
      }
    }
  )
}

export const deprecatedParseGoogleLocationApiDetails = (results) => {
  return parseGoogleLocationApiDetails(results)
}

const parseGoogleLocationApiDetails = (results) => {
  if (results === undefined || results[0] === undefined) {
    return null
  }
  let response = {
    neighborhood: '',
    street_number: '',
    street_name: '',
    street_address: '',
    city: '',
    state: '',
    zipcode: '',
    lat: '',
    lng: '',
    county: '',
  }
  let details = results[0]

  for (let i = 0; i < details.address_components.length; i++) {
    switch (details.address_components[i].types[0]) {
      case 'neighborhood':
        response.neighborhood = details.address_components[i].long_name
        break
      case 'street_number':
        response.street_number = details.address_components[i].long_name
        break
      case 'route':
        response.street_name = details.address_components[i].short_name
        break
      case 'postal_code':
        response.zipcode = details.address_components[i].long_name
        break
      case 'sublocality_level_1':
        response.city = details.address_components[i].long_name
        break
      case 'locality':
      case 'political':
        if (!response.city || !response.city.length) {
          response.city = details.address_components[i].long_name
        }
        break
      case 'administrative_area_level_1':
        response.state = details.address_components[i].short_name
        break
      case 'administrative_area_level_2':
        response.county = details.address_components[i].long_name
        break
      case 'country':
        response.country = details.address_components[i].long_name
        break
      default:
        break
    }
  }

  // special case for neighborhood with lots of contractors
  if (
    response.city === 'Los Angeles' &&
    response.neighborhood === 'Woodland Hills'
  ) {
    response.city = response.neighborhood
  }

  // special case for Bronx, NY (Google API doesn't find as city)
  if (response.county === 'Bronx County') {
    response.city = 'Bronx'
  }

  if (details.geometry.location.lat() && details.geometry.location.lng()) {
    response.lat = details.geometry.location.lat()
    response.lng = details.geometry.location.lng()
  } else if (details.geometry.location.A && details.geometry.location.F) {
    response.lat = details.geometry.location.A
    response.lng = details.geometry.location.F
  } else if (details.geometry.location.G && details.geometry.location.K) {
    response.lat = details.geometry.location.G
    response.lng = details.geometry.location.K
  }

  if (response.lat && response.lng) {
    response.coord = [response.lat, response.lng].join(',')
  }

  if (response.street_number) {
    response.street_address = response.street_number
  }

  if (response.street_name) {
    if (response.street_address && response.street_address.length) {
      response.street_address += ' '
    }
    response.street_address += response.street_name
  }

  if (response.street_number) {
    response.zoom = 18
  } else if (response.street_name || response.neighborhood) {
    response.zoom = 14
  } else {
    response.zoom = 12
  }

  if (details.formatted_address) {
    response.formatted_address = details.formatted_address
    response.alias = response.formatted_address
      .replace(/,+/g, ' ')
      .replace(/\s+/g, '-')
      .trim()
      .toLowerCase()
  }
  return response
}
