/* global angular */
angular.module('bzCommonUtilitiesModule').directive('applyClassOnDisappear', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        applyClass: '@',
      },
      controller: [
        '$element',
        '$window',
        function ($element, $window) {
          this.$onInit = function () {
            this.$w = angular.element($window)
            this.threshold = $element.offset().top
            this.$w.on('scroll', this.checkThreshold.bind(this))
            this.checkThreshold()
          }

          this.checkThreshold = function () {
            if (this.$w.scrollTop() >= this.threshold) {
              $element[0].classList.add(this.applyClass)
            } else {
              $element[0].classList.remove(this.applyClass)
            }
          }
        },
      ],
    }
  },
])
