/* global angular */
import {
  DEFAULT_SCREENS,
  NEW_CONSTRUCTION_SCREENS,
  HOME_ADDITION_SCREENS,
} from 'Vanilla/constants/project_form'

angular.module('bzProjectFormModule').factory('projectFormScreenService', [
  '$timeout',
  'trackProjectFormService',
  'projectFormService',
  function ($timeout, trackProjectFormService, projectFormService) {
    return {
      screens: DEFAULT_SCREENS,
      currentScreen: null,
      prevScreen: null,
      farthestScreen: null,
      skippedFirstScreen: false,
      isClosing: false,
      showIntro: false,
      setScreenCallbacks: [],
      setScreensByVersion: function () {
        if (this.project.projectType) {
          if (
            this.project.projectType.projectTypeGroupId ===
            projectFormService.projectTypeGroupIds.HOME_ADDITION
          ) {
            this.screens = HOME_ADDITION_SCREENS
          } else if (
            this.project.projectType.projectTypeGroupId ===
            projectFormService.projectTypeGroupIds.NEW_CONSTRUCTION
          ) {
            this.screens = NEW_CONSTRUCTION_SCREENS
          } else {
            this.screens = DEFAULT_SCREENS
          }
        } else {
          this.screens = DEFAULT_SCREENS
        }
      },
      setScreen: function (screen) {
        if (
          !this.farthestScreen ||
          this.screens.indexOf(screen) >
            this.screens.indexOf(this.farthestScreen)
        ) {
          this.farthestScreen = screen
          this.project.analytics.lastScreenViewed = this.farthestScreen

          trackProjectFormService.viewStep(
            screen,
            angular.extend(
              {
                projectTypeGroupId: this.project.projectType
                  ? this.project.projectType.projectTypeGroupId
                  : null,
              },
              this.project.analytics
            )
          )
        }

        this.prevScreen = this.prevScreenOf(screen)
        this.currentScreen = screen

        if (screen === 'title') {
          this.skippedFirstScreen = false
        }

        if (this.setScreenCallbacks.length) {
          angular.forEach(
            this.setScreenCallbacks,
            function (setScreenCallback) {
              setScreenCallback()
            }
          )
        }
      },
      addScreenBefore: function (screen, beforeScreen) {
        if (this.screens.indexOf(screen) < 0) {
          this.screens.splice(this.screens.indexOf(beforeScreen), 0, screen)
        }
      },
      removeScreen: function (screen) {
        if (screen && this.screens.indexOf(screen) >= 0) {
          this.screens.splice(this.screens.indexOf(screen), 1)
        }
      },
      isCurrentScreen: function (screen) {
        return this.currentScreen === screen
      },
      isPrevScreen: function (screen) {
        return this.prevScreen === screen
      },
      isNextVisitedScreen: function () {
        if (
          this.farthestScreen &&
          this.currentScreen &&
          this.screens.indexOf(this.currentScreen) <
            this.screens.indexOf(this.farthestScreen)
        ) {
          return true
        }

        return false
      },
      isFirstScreen: function () {
        return this.screens.indexOf(this.currentScreen) === 0 ? true : false
      },
      hasScreen: function (screen) {
        return this.screens.indexOf(screen) >= 0
      },
      nextScreenOf: function (screen) {
        if (this.screens.indexOf(screen) < this.screens.length - 1) {
          return this.screens[this.screens.indexOf(screen) + 1]
        } else {
          return null
        }
      },
      prevScreenOf: function (screen) {
        if (this.screens.indexOf(screen) > 0) {
          return this.screens[this.screens.indexOf(screen) - 1]
        } else {
          return null
        }
      },
      showNextScreen: function () {
        if (this.currentScreen) {
          document.activeElement.blur()
          this.setScreen(this.nextScreenOf(this.currentScreen))
        }
      },
      showPrevScreen: function () {
        if (this.currentScreen && this.prevScreen) {
          $timeout(function () {
            document.activeElement.blur()
          })
          this.setScreen(this.prevScreenOf(this.currentScreen))
        }
      },
      backToScreen: function (screen) {
        this.prevScreen = screen

        $timeout(
          function () {
            this.currentScreen = screen
            this.prevScreen = this.prevScreenOf(screen)
          }.bind(this),
          250
        )
      },
      progressStyle: function () {
        let width = 0

        if (this.currentScreen) {
          width = Math.round(
            ((this.screens.indexOf(this.currentScreen) + 1) /
              this.screens.length) *
              100
          )
        }

        return {
          width: width + '%',
        }
      },
      focusInputOnScreenShow: function (screen, fieldSelector) {
        $timeout(() => {
          if (this.isCurrentScreen(screen)) {
            angular.element(fieldSelector).focus()
          }
        }, 250)
      },
    }
  },
])
