import template from './download_co_ios_app_banner.html'

const BANNER_HEIGHT = 75

const downloadCoIosAppBanner = {
  bindings: {
    bannerKey: '<',
  },
  template,
  controller: [
    'globalBannerTracking',
    'globalBannerService',
    '$rootScope',
    function (globalBannerTracking, globalBannerService, $rootScope) {
      this.$onInit = function () {
        this.navEl = angular.element('#modern-nav')
        this.bodyEl = angular.element('body')

        this.bodyEl.css('padding-top', '+=' + BANNER_HEIGHT)
        this.navEl.css('top', '+=' + BANNER_HEIGHT)
      }

      this.close = () => {
        this.setCookie()

        this.bodyEl.css('padding-top', '-=' + BANNER_HEIGHT)
        this.navEl.css('top', '-=' + BANNER_HEIGHT)

        globalBannerTracking.clickClose(this.bannerKey)
        globalBannerService.close(this.bannerKey)
      }

      this.onClick = () => {
        globalBannerTracking.clickBannerCTA(this.bannerKey)
        document.location =
          'https://itunes.apple.com/us/app/buildzoom-pro/id1420208829?mt=8'
      }

      this.setCookie = () => {
        const now = new Date()
        const expiry = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1
        )
        Cookie.set(this.bannerKey, 1, expiry)
      }
    },
  ],
}

export default downloadCoIosAppBanner
