/* global angular */
angular.module('bzProjectFormModule').component('expectedResponsesScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/expected_responses'
      )
    },
  ],
  controller: [
    'projectFormScreenService',
    function (projectFormScreenService) {
      this.$onInit = function () {
        this.expectedResponsesOptions = [3, 4, 5]
      }

      this.setExpectedResponses = function (expectedResponses) {
        this.project.expectedResponses = expectedResponses || 4
        this.showNextScreen()
      }

      this.showNextScreen = function () {
        if (projectFormScreenService.isCurrentScreen('expected-responses')) {
          projectFormScreenService.showNextScreen()
        }
      }
    },
  ],
})
