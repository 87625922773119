/* global bz_app */
angular.module('bzPotentialLeadFormModule').factory('potentialLeadModel', [
  '$http',
  'modelService',
  'urlConstants',
  function ($http, modelService, urlConstants) {
    return {
      apiPath: urlConstants.api.v1.path,
      createMessage: function (potentialLead) {
        return $http
          .post(this.apiPath + 'potential_leads/create_with_message', {
            old_message: {
              s_subject: potentialLead.title || 'Question',
              s_sender_name: potentialLead.name,
              s_sender_email: potentialLead.email,
              s_sender_phone: potentialLead.phone,
              s_message: potentialLead.message,
              i_nid: potentialLead.contractorId,
            },
            referrer: potentialLead.referrer,
            landing_page: potentialLead.landingPage,
            utm_source: potentialLead.utmSource,
            utm_medium: potentialLead.utmMedium,
            utm_term: potentialLead.utmTerm,
            utm_campaign: potentialLead.utmCampaign,
            utm_content: potentialLead.utmContent,
            form_version: 'pl-' + potentialLead.formVersion,
            test: 'test_clear',
          })
          .then(modelService.extractData)
      },
    }
  },
])
