export const PROJECT_CONTROL_FORM_VERSION = 5.33

export const DEFAULT_SCREENS = [
  'title',
  'urgency',
  'property-type',
  'email',
  'phone',
  'name',
  'expected-responses',
  'location',
  'property-relationship',
  'budget',
  'description',
  'password',
]
export const HOME_ADDITION_SCREENS = [
  'title',
  'urgency',
  'property-type',
  'email',
  'phone',
  'name',
  'expected-responses',
  'location',
  'design-status',
  'budget',
  'description',
  'password',
]
export const NEW_CONSTRUCTION_SCREENS = [
  'title',
  'urgency',
  'land-ownership-status',
  'email',
  'phone',
  'name',
  'expected-responses',
  'location',
  'design-status',
  'budget',
  'description',
  'password',
]
export const DESCRIPTION_TEST_SCREENS = [
  'title',
  'urgency',
  'property-type',
  'description',
  'email',
  'phone',
  'name',
  'expected-responses',
  'location',
  'property-relationship',
  'budget',
  'password',
]
export const DESCRIPTION_TEST_HOME_ADDITION_SCREENS = [
  'title',
  'urgency',
  'property-type',
  'description',
  'email',
  'phone',
  'name',
  'expected-responses',
  'location',
  'design-status',
  'budget',
  'password',
]
export const DESCRIPTION_TEST_NEW_CONSTRUCTION_SCREENS = [
  'title',
  'urgency',
  'land-ownership-status',
  'description',
  'email',
  'phone',
  'name',
  'expected-responses',
  'location',
  'design-status',
  'budget',
  'password',
]

export const LARGE_PROJECT_BUDGETS = [
  '100000-250000',
  '250000-500000',
  '500000-1000000',
  '1000000-9999999',
]
