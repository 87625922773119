import { first, last } from 'lodash'
import 'Checkouts/models/checkout_instance'
/* global bz_app, UserData */
bz_app.factory('leadInstance', [
  '$filter',
  'attributeConversionService',
  'leadModel',
  'checkoutInstance',
  'trackLeadsService',
  'bidInstance',
  function (
    $filter,
    attributeConversionService,
    leadModel,
    checkoutInstance,
    trackLeadsService,
    bidInstance
  ) {
    return function (bidType) {
      this.init = false
      this.bidType = bidType || 'initial'
      this.respondedAt = null
      this.interested = true
      this.set = function (data) {
        var camelData =
          attributeConversionService.underscoreToCamelDeepAttributes(data)

        if (camelData) {
          if (camelData.bids) {
            const adminDetailedBid = first(
              camelData.bids.filter(
                (bid) => bid.bidType === 'admin_detailed_bid'
              )
            )
            const bidData =
              adminDetailedBid ||
              first(
                camelData.bids.filter((bid) => bid.bidType === this.bidType)
              )

            if (bidData) {
              this.bid = new bidInstance()
              this.bid.set(bidData)
            }

            const checkoutBidData = last(
              camelData.bids.filter((bid) => bid.bidType === 'checkout')
            )

            if (checkoutBidData) {
              this.checkoutBid = new bidInstance()
              this.checkoutBid.set(checkoutBidData)
            }

            delete camelData.bids
          }
          angular.extend(this, camelData)
          this.setContractor()
          this.setProject()
          this.setProperty()
          this.setCheckout()

          if (this.bid && this.bid.bidType === 'admin_detailed_bid') {
            this.outcome.contractorClaimingWinBidAmount = this.bid.price
          }

          this.init = true
          return true
        } else {
          return false
        }
      }
      this.respond = function (data) {
        return leadModel.respond(
          this.project.id,
          data.interested,
          data.explanation,
          this.contractor.id,
          this.hash
        )
      }

      this.setInterested = function (leadResponse, analytics, callback) {
        this.set(leadResponse)
        trackLeadsService.responseRecorded(this, true, analytics, callback)
      }

      this.setNotInterested = function () {
        this.respondedAt = new Date().toISOString()
        this.interested = false
        trackLeadsService.responseRecorded(this, false, {})
      }

      this.setClaimed = function (user) {
        this.contractor.claimed = true
        this.contractor.userId = user.id
        this.contractor.user = user
      }

      this.initiateWin = function (initiate) {
        return leadModel.initiateWin(this.project.id, initiate)
      }

      this.markHire = function (price, sourceInfo) {
        return leadModel.markHire(this.project.id, price, sourceInfo).then(
          function (response) {
            if (response && response.success) {
              this.outcome.contractorClaimingWin = this.contractor.id

              if (response.lead && response.lead.outcome) {
                this.outcome.contractorReportedState =
                  response.lead.outcome.contractor_reported_state
              }

              if (price) {
                this.outcome.contractorClaimingWinBidAmount = price
              }
            }

            return response
          }.bind(this)
        )
      }

      this.denyWin = function (reason) {
        return leadModel
          .denyWin(this.project.id, {
            reason: reason,
            contractor_id: this.contractor.id,
          })
          .then(
            function (response) {
              if (response && response.success) {
                this.outcome.hiredContractor = null
              }

              return response
            }.bind(this)
          )
      }

      this.undoHire = function () {
        return leadModel
          .setOutcome(this.project.id, this.deriveCurrentOutcome())
          .then(
            function (response) {
              if (response && response.success) {
                this.outcome.contractorClaimingWin = null
                this.outcome.contractorClaimingWinBidAmount = null

                if (response.lead && response.lead.outcome) {
                  this.outcome.contractorReportedState =
                    response.lead.outcome.contractor_reported_state
                }

                if (this.checkout) {
                  this.checkout.status = null
                }
              }

              return response
            }.bind(this)
          )
      }

      this.archive = function (explanation) {
        return leadModel.archive(this.project.id, explanation).then(
          function (response) {
            if (response && response.success) {
              if (response.lead && response.lead.outcome) {
                this.outcome.contractorReportedState =
                  response.lead.outcome.contractor_reported_state
              }
            }

            return response
          }.bind(this)
        )
      }

      this.unArchive = function () {
        return leadModel
          .setOutcome(this.project.id, this.deriveCurrentOutcome())
          .then(
            function (response) {
              if (response && response.success) {
                if (response.lead && response.lead.outcome) {
                  this.outcome.contractorReportedState =
                    response.lead.outcome.contractor_reported_state
                }
              }

              return response
            }.bind(this)
          )
      }

      this.setOutcome = function (outcome, price) {
        return leadModel.setOutcome(this.project.id, outcome, price).then(
          function (response) {
            if (response && response.success) {
              if (response.lead && response.lead.outcome) {
                this.outcome.contractorClaimingWin =
                  response.lead.outcome.contractor_claiming_win
                this.outcome.contractorOutcomeUpdatedAt =
                  response.lead.outcome.contractor_outcome_updated_at
                this.outcome.contractorReportedState =
                  response.lead.outcome.contractor_reported_state
              }
            }

            return response
          }.bind(this)
        )
      }

      this.setPrice = function (price) {
        return leadModel.setPrice(this.project.id, price).then(
          function (response) {
            if (response && response.success) {
              if (response.lead && response.lead.outcome) {
                this.outcome.contractorClaimingWinBidAmount =
                  response.lead.outcome.contractor_claiming_win_bid_amount
              }
            }

            return response
          }.bind(this)
        )
      }

      this.loadPropertyStats = function () {
        return leadModel.loadPropertyStats(this.project.id).then(
          function (response) {
            if (response && response.data && response.data.property_stats) {
              this.property.stats =
                attributeConversionService.underscoreToCamelDeepAttributes(
                  response.data.property_stats
                )
            }

            return response
          }.bind(this)
        )
      }

      this.recordBlockView = function () {
        return leadModel.recordBlockView(this.mainBlock())
      }

      this.setCheckoutPaymentSource = function (
        paymentSourceType,
        paymentSourceId
      ) {
        return leadModel.setCheckoutPaymentSource(
          this.project.id,
          paymentSourceType,
          paymentSourceId
        )
      }

      this.deriveCurrentOutcome = function () {
        return this.bid ? 'closing' : 'preparing_bid'
      }

      this.setContractor = function () {
        if (!this.contractor) {
          return
        }
        if (this.metadata) {
          var metadata = this.getMetaDataContractorById(this.contractor)
          this.contractor = metadata
            ? this.processContractorMetaData(metadata)
            : null
        }
      }

      this.setProject = function () {
        if (this.metadata && this.metadata.project) {
          this.project = this.metadata.project
          this.project.projectOwner = this.getProjectOwner(this.project)
          this.project.concierge = this.getConcierge(this.project)
        }
        if (typeof this.project !== 'undefined') {
          this.project.localCreatedAt = new Date(this.project.localCreatedAt)
        }
      }

      this.setProperty = function () {
        if (this.metadata && this.metadata.property) {
          this.property = this.metadata.property
        }
      }

      this.getProjectOwner = function (project) {
        var projectOwner = null

        if (project && project.projectOwner) {
          projectOwner = this.getMetaDataUserById(project.projectOwner)
          this.setFullName(projectOwner)
        }

        return projectOwner
      }

      this.getConcierge = function (project) {
        var concierge = null

        if (project && project.concierge) {
          concierge = this.getMetaDataUserById(project.concierge)
          this.setFullName(concierge)
        }

        return concierge
      }

      this.getReferralFee = () => {
        if (this.checkout.commissionRate) {
          return this.checkout.commissionRate
        } else if (this.referralFeeAcceptance) {
          return this.referralFeeAcceptance.referralFee
        } else {
          return this.referralFee
        }
      }

      this.displayReferralFee = () => {
        return (this.getReferralFee() * 100).toFixed(2) + '%'
      }

      this.setFullName = function (user) {
        if (user) {
          user.fullName = ''
          user.shortName = ''

          if (user.firstName && user.lastName) {
            user.fullName = user.firstName + ' ' + user.lastName
            user.shortName =
              user.firstName + ' ' + user.lastName.charAt(0).toUpperCase() + '.'
          } else if (user.firstName) {
            user.fullName = user.firstName
            user.shortName = user.firstName
          }
        }
      }

      this.processContractorMetaData = function (contractor) {
        contractor.contractorBid = this.getBidByContractorId(contractor.id)
        contractor.user = this.getMetaDataUserById(contractor.userId)

        this.setFullName(contractor.user)
        if (contractor.user) {
          contractor.name = contractor.user.fullName || contractor.businessName
        }

        if (
          contractor.thumbnails &&
          contractor.thumbnails.length &&
          contractor.thumbnails[0] &&
          !contractor.thumbnails[0].generic
        ) {
          contractor.coverUrl = contractor.thumbnails[0].url
        }

        return contractor
      }

      this.getMetaDataContractorById = function (contractorId) {
        if (this.metadata && this.metadata.contractor) {
          return this.metadata.contractor
        } else {
          return null
        }
      }

      this.getBidByContractorId = function (contractorId) {
        if (this.bids) {
          return this.bids[contractorId]
        } else {
          return null
        }
      }

      this.getMetaDataUserById = function (userId) {
        if (this.metadata && this.metadata.users) {
          return this.metadata.users[userId]
        } else {
          return null
        }
      }

      this.neighborhood = function () {
        return this.property.location &&
          this.property.location.neighborhood &&
          this.property.location.neighborhood.length
          ? this.property.location.neighborhood
          : ''
      }

      this.streetName = function () {
        return this.property.location &&
          this.property.location.streetName &&
          this.property.location.streetName.length
          ? this.property.location.streetName
          : ''
      }

      this.streetAddress = function () {
        return this.property.location &&
          this.property.location.streetAddress &&
          this.property.location.streetAddress.length
          ? this.property.location.streetAddress
          : ''
      }

      this.streetAndZipAddress = function () {
        var address = ''
        if (this.streetAddress()) {
          address += this.streetAddress()
          if (!!this.streetAddress() && !!this.property.location.zipcode) {
            address += ', '
          }
          if (this.property.location.zipcode) {
            address += this.property.location.zipcode
          }
        } else {
          address = this.cityStateZip()
        }
        return address
      }

      this.cityStateZip = function () {
        if (
          this.property &&
          this.property.location &&
          this.property.location.cityState
        ) {
          if (this.property.location.zipcode) {
            return (
              this.property.location.cityState +
              ', ' +
              this.property.location.zipcode
            )
          } else {
            return this.property.location.cityState
          }
        } else {
          return ''
        }
      }

      this.fullAddress = function (separator) {
        if (this.property && this.property.location) {
          if (separator === 'br') {
            separator = '<br/>'
          } else {
            separator = ', '
          }
          if (this.streetAddress().length) {
            return this.streetAddress() + separator + this.cityStateZip()
          } else {
            return this.cityStateZip()
          }
        } else {
          return ''
        }
      }

      this.projectHasAttachments = function () {
        return (
          this.project.summary.attachments &&
          this.project.summary.attachments.length
        )
      }

      this.projectHasDescription = function () {
        return this.project.summary.description.length ? true : false
      }

      this.isHiredContractor = function () {
        return this.outcome.contractorClaimingWin === this.contractor.id
      }

      this.isLostFromProjectOwner = function () {
        return this.isDeclinedByProjectOwner() || this.isClosedByProjectOwner()
      }

      this.isDeclinedByProjectOwner = function () {
        return this.outcome.poStatus === 'declined'
      }

      this.isClosedByProjectOwner = function () {
        return this.outcome.poStatus === 'closed'
      }

      this.isPostponedByProjectOwner = function () {
        return (
          this.responseId !== null &&
          this.outcome.systemState === 'inactive' &&
          this.outcome.systemSubstate === 'po_postponed'
        )
      }

      this.projectOwnerReportedWin = function () {
        return this.outcome.poStatus === 'hired'
      }

      this.isArchived = function () {
        return this.outcome.contractorReportedState === 'inactive'
      }

      this.isNotArchived = function () {
        return !this.isArchived()
      }

      this.isOptedIn = function () {
        return this.responseId !== null
      }

      this.isConnected = function () {
        return (
          this.responseId !== null &&
          (this.outcome.systemState === 'active' ||
            this.outcome.systemState === null)
        )
      }

      this.isInactive = function () {
        return this.outcome.systemState === 'inactive'
      }

      this.isDisconnected = function () {
        return this.responseId !== null && this.isInactive()
      }

      this.isDisconnectedForNoContact = function () {
        return (
          this.isDisconnected() &&
          this.outcome.systemSubstate === 'co_unresponsive'
        )
      }

      this.isUnconfirmedWin = function () {
        return (
          this.outcome.hiredContractor && !this.outcome.contractorClaimingWin
        )
      }

      this.askToConfirmWin = function () {
        return this.shouldConfirmWin
      }

      this.denyWin = function (denyReason) {
        leadModel.denyWin(this.project.id, { reason: denyReason })
        this.shouldConfirmWin = false
      }

      this.isMissingPrice = function () {
        return this.isHiredContractor() && this.projectValue() === null
      }

      this.hasExpired = function () {
        return !this.respondedAt && this.expired
      }

      this.setCheckout = function () {
        var data = this.checkout
        this.checkout = new checkoutInstance()
        this.checkout.set(data)
      }

      this.promptForPayment = function () {
        return this.checkout.promptForPayment()
      }

      this.isPaymentRefundable = function () {
        return this.checkout.isPaymentRefundable()
      }

      this.amountPaid = function () {
        return this.checkout.amountPaid()
      }

      this.projectValue = function () {
        return this.outcome.contractorClaimingWinBidAmount
      }

      this.projectBudget = function (options) {
        if (this.project.summary.budget) {
          let budgets = this.project.summary.budget.split('-')
          if (options && options.lowerBound) {
            return parseInt(budgets[0])
          } else {
            return (parseInt(budgets[0]) + parseInt(budgets[1])) / 2
          }
        } else {
          return null
        }
      }

      this.isMatchedToProperty = function () {
        return (
          this.property && this.property.location && this.property.location.slug
        )
      }

      this.hasPropertyStats = function () {
        if (
          this.isMatchedToProperty() ||
          (this.property.stats &&
            (this.property.stats.zestimate ||
              this.property.stats.squareFootage ||
              this.property.stats.bedrooms ||
              this.property.stats.bathrooms ||
              this.property.stats.yearBuilt))
        ) {
          return true
        } else {
          return false
        }
      }

      this.hasPropertyNeighborhoodStats = function () {
        if (
          this.property.stats &&
          (this.property.stats.medianHomeValue ||
            this.property.stats.medianHouseholdIncome)
        ) {
          return true
        } else {
          return false
        }
      }

      this.isUnlocked = function () {
        return this.respondedAt && this.interested && !this.waitlisted
      }

      this.respondedNotInterested = function () {
        return this.respondedAt && !this.interested
      }

      this.canWaitlist = function () {
        return (
          (!this.respondedAt || this.respondedNotInterested()) &&
          !this.openForContractor &&
          !this.expired &&
          !this.honeymoonSlotOpenForCO()
        )
      }

      this.honeymoonSlotOpenForCO = function () {
        return this.honeymoonSlotOpen && this.contractor.eligibleForHoneymoon
      }

      this.hasBlock = function (blockType) {
        if (blockType == undefined) {
          return this.blocks.size > 0
        } else {
          return !!this.blocks.find((block) => block.blockType === blockType)
        }
      }

      this.hasStaleLeadBlock = function () {
        return this.hasBlock('stale_lead')
      }

      this.clearStaleLeadBlock = function () {
        for (var i = 0; i < this.blocks.length; i++) {
          if (this.blocks[i].blockType === 'stale_lead') {
            this.blocks.splice(i, 1)
          }
        }
      }

      this.hasBlocks = function () {
        if (this.contractor && this.contractor.blocks && this.mainBlock()) {
          return true
        } else {
          return false
        }
      }

      this.hasUnpaidSubscription = function () {
        if (this.contractor && this.contractor.unpaidSubscription) {
          return true
        } else {
          return false
        }
      }

      this.lostToCompetitor = function () {
        return (
          this.outcome.systemState === 'inactive' &&
          this.outcome.systemSubstate === 'competitor_win'
        )
      }

      this.promptToContact = function () {
        return (
          this.drax &&
          !this.drax.contacted &&
          this.isConnected() &&
          !this.isHiredContractor() &&
          this.isPostAutoDecline()
        )
      }

      this.hasQuestionableWinAmountBlock = function () {
        if (typeof this.checkout !== 'undefined') {
          return this.checkout.hasQuestionableBlock()
        } else {
          return false
        }
      }

      this.mainBlock = function () {
        if (this.contractor.blocks) {
          return this.contractor.blocks.highestPriorityBlock
        }

        return null
      }

      this.isPostAutoDecline = function () {
        return new Date(this.publishedToCoAt) > new Date('09/01/2016')
      }

      this.closeRateNeedsImprovement = function () {
        return (
          this.closeRatePercentile < 33 && this.numRelevantConnections >= 10
        )
      }

      this.phoneNumberHash = function () {
        return this.project && this.project.projectOwner
          ? this.project.projectOwner.phoneNumber
          : null
      }

      this.phoneNumber = function (extensionFormat) {
        if (this.phoneNumberHash()) {
          var number = $filter('phone')(this.phoneNumberHash().number)
          if (this.phoneNumberHash().ext) {
            var ext =
              typeof extensionFormat === 'undefined' ||
              extensionFormat !== 'short'
                ? ' ext. '
                : ' x'
            number += ext + this.phoneNumberHash().ext
          }
          return number
        }
      }

      this.phonePresent = () =>
        !!(this.phoneNumberHash() && this.phoneNumberHash().number)

      this.phonePreferred = function () {
        return (
          this.project &&
          this.project.projectOwner &&
          this.project.projectOwner.phonePreferred &&
          this.phonePresent()
        )
      }

      this.loadAliasNumber = () =>
        leadModel
          .loadAliasNumber(this.id)
          .then(({ aliasNumber, communicableId }) => {
            this.project.projectOwner.phoneNumber.number = aliasNumber
            this.contractor.communicableId = communicableId
          })

      this.checkoutURL = function () {
        return '/checkout/' + this.project.id
      }

      this.leadURL = function () {
        return window.location.host + '/lead/' + this.project.id
      }

      this.isArchivable = function () {
        return (
          !this.projectOwnerReportedWin() &&
          !this.isUnconfirmedWin() &&
          this.isNotArchived() &&
          (this.isAdmin() || (!this.isHiredContractor() && !this.hasBlocks()))
        )
      }

      this.isUnarchivable = function () {
        return (
          this.isArchived() &&
          (this.isAdmin() || (!this.isHiredContractor() && !this.hasBlocks()))
        )
      }

      this.isAdmin = function () {
        return UserData.current_staff_user() || UserData.is_admin()
      }

      this.hasOpenPoBidRequest = () => {
        return (
          this.poBidRequest &&
          ['REQUESTED', 'POSTPONED'].includes(this.poBidRequest.status)
        )
      }

      this.needsToRecontact = function () {
        return (
          this.outcome.systemSubstate === 'restarted' &&
          new Date(this.drax.lastContactedAt) <
            new Date(this.outcome.systemStateUpdatedAt)
        )
      }

      this.canUndoHire = function () {
        if (this.isHiredContractor() && this.respondedAt && !this.paid) {
          if (this.isAdmin()) {
            return true
          } else if (this.projectOwnerReportedWin()) {
            return false
          } else {
            var now = new Date().getTime()
            var respondedAt = new Date(this.respondedAt).getTime()

            if (isNaN(respondedAt)) {
              return false
            } else {
              return Math.round((now - respondedAt) / 60000) <= 60
                ? true
                : false // milliseconds / 1000 =  seconds / 60 = minutes
            }
          }
        }

        return false
      }
    }
  },
])
