/* global angular */
angular.module('bzCommonUtilitiesModule').factory('modelService', [
  'attributeConversionService',
  '$httpParamSerializer',
  function (attributeConversionService, $httpParamSerializer) {
    return {
      queryString: function (params) {
        return (
          '?' +
          $httpParamSerializer(
            this.convertArrayParams(this.toUnderscore(params))
          )
        )
      },

      toUnderscore: function (data) {
        return attributeConversionService.camelToUnderscoreDeepAttributes(data)
      },

      toCamel: function (params) {
        return attributeConversionService.underscoreToCamelDeepAttributes(
          params
        )
      },

      // converts array params to the format rails expects
      // ex. { budget: [25, 50] } -> { budget[]: [25, 50] }
      // from that, $httpParamSerializer will output 'budget[]=25&budget[]=50'
      convertArrayParams: function (params) {
        var convertedParams = {}
        angular.forEach(Object.keys(params), function (key) {
          if (Array.isArray(params[key])) {
            convertedParams[key + '[]'] = params[key]
          } else {
            convertedParams[key] = params[key]
          }
        })

        return convertedParams
      },

      extractData: function (response) {
        if (response.data) {
          return attributeConversionService.underscoreToCamelDeepAttributes(
            response.data
          )
        } else {
          return null
        }
      },

      extractDataUnderscore: function (response) {
        return response.data || null
      },
    }
  },
])
