/* global bz_app, Track, angular, isMobile */
angular.module('bzAnalyticsModule').factory('trackExperimentService', [
  function () {
    return {
      title: 'Site Experiment',
      defaultData: function (input) {
        var data = {
          id: input.id ? input.id : '',
          name: input.name ? input.name : '',
          event: input.event ? input.event : '',
          variation: input.variation ? input.variation : '',
          source: input.source ? input.source : '',
          platform: isMobile.check() ? 'mobile' : 'desktop',
        }

        return angular.extend(data, input)
      },
      sendEvent: function (options, callback) {
        if (callback !== undefined) {
          Track.send_callback(this.title, this.defaultData(options), callback)
        } else {
          Track.send(this.title, this.defaultData(options))
        }
      },
    }
  },
])
