export default class DataSubscriptionApi {
  constructor(client) {
    this.client = client
  }

  createSubscription(
    email,
    planType,
    startDate,
    useCase,
    customPrice = null,
    customRequestAmount = null
  ) {
    return this.client.post('/api/v1/data_subscription/create', {
      email,
      planType,
      startDate,
      useCase,
      customPrice,
      customRequestAmount,
    })
  }

  changeSubscriptionPlan(newPlanType) {
    return this.client.post('/api/v1/data_subscription/change_plan', {
      newPlanType,
    })
  }

  endSubscriptionPlan() {
    return this.client.post('/api/v1/data_subscription/end_subscription')
  }

  activateSubscription() {
    return this.client.post('/api/v1/data_subscription/activate_subscription')
  }

  createDataSubscriptionUser(
    email,
    password,
    fullName,
    phoneNumber,
    propertyId
  ) {
    return this.client.post(
      '/api/v1/data_subscription/create_data_subscription_user',
      {
        email,
        password,
        fullName,
        phoneNumber,
        propertyId,
      }
    )
  }

  requestProperty(propertyId) {
    return this.client.post('/api/v1/data_subscription/request_property', {
      propertyId,
    })
  }
}
