/* global bz_app, $ */
bz_app.factory('bankAccountModel', [
  '$http',
  'urlConstants',
  function ($http, urlConstants) {
    return {
      create: function (data) {
        return $http.post(urlConstants.api.v1.path + 'bank_accounts', data)
      },
      delete: function (bankAccountId) {
        return $http.delete(
          urlConstants.api.v1.path + 'bank_accounts/' + bankAccountId
        )
      },
    }
  },
])
