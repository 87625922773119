/* global Track, UserData, $, isMobile	*/
var ServiceRequestsTrack = function () {}

ServiceRequestsTrack.bookmark = function (title, project_id, contractor_id) {
  Track.impersonation_check().send('Service Request Action', {
    action: title,
    project_id: project_id,
    contractor_id: contractor_id,
  })
}

window.ServiceRequestsTrack = ServiceRequestsTrack
