import template from './modern_datepicker_popup.html'

const modernDatepickerPopupModule = angular
  .module('modernDatepickerPopup', [])
  .component('modernDatepickerPopup', {
    bindings: {
      datepickerOptions: '<',
      className: '@',
      dateModel: '=',
      onClick: '&?',
      onFocus: '&?',
      onChange: '&?',
      showButtonBar: '<',
      showDateShortcuts: '<',
    },
    template,
    controller: [
      function () {
        this.$onInit = () => {
          this.datepickerOpen = false
          this.datepickerOptions = Object.assign(
            this.buildDefaultDatepickerOptions(),
            this.datepickerOptions
          )
          this.showDateShortcuts =
            this.showDateShortcuts !== null ? this.showDateShortcuts : true
          this.showButtonBar =
            this.showButtonBar !== null ? this.showButtonBar : false
        }

        this.openDatepicker = () => (this.datepickerOpen = true)

        this.click = () => {
          if (this.onClick) this.onClick()
          this.openDatepicker()
        }

        this.focus = () => {
          if (this.onFocus) this.onFocus()
          this.openDatepicker()
        }

        this.buildDefaultDatepickerOptions = () => {
          const todayDate = new Date()
          const secondsInADay = 24 * 60 * 60 * 1000
          const mondayOffset = 6
          const fourDayOffset = 4

          return {
            showDateShortcuts: this.showDateShortcuts,
            showWeeks: false,
            todayDate: todayDate,
            tomorrowDate: new Date(+todayDate + secondsInADay),
            laterThisWeekDate: new Date(
              +todayDate + fourDayOffset * secondsInADay
            ),
            nextWeekDate: new Date(
              +todayDate +
                (7 - ((todayDate.getDay() + mondayOffset) % 7)) * secondsInADay
            ),
          }
        }
      },
    ],
  }).name

export default modernDatepickerPopupModule
