export default {
  bindings: {
    surveyAnswer: '<',
    headerText: '@',
    surveyOptions: '<',
    screenName: '@',
    onSelected: '&',
  },
  template: `
	<div class="form-body-content">
		<screen-header
			header-text="{{$ctrl.headerText}}"
			show-header-icon="false"></screen-header>
		<div>
			<div ng-repeat="surveyOption in $ctrl.surveyOptions"
				 ng-bind="surveyOption.text"
				 ng-click="$ctrl._onSelected(surveyOption)"
				 ng-if="surveyOption.value"
				 ng-class="{ 'answer-button-selected' : $ctrl.surveyAnswer === surveyOption.value }"
				 class="answer-button noPreventDefault">
			</div>
		</div>
	</div>
	`,
  controller: [
    'projectFormScreenService',
    function (projectFormScreenService) {
      this._onSelected = function (surveyOption) {
        this.onSelected({ selectedOption: surveyOption })
        this.showNextScreen()
      }

      this.showNextScreen = function () {
        if (projectFormScreenService.isCurrentScreen(this.screenName)) {
          projectFormScreenService.showNextScreen()
        }
      }
    },
  ],
}
