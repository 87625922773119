export default [
  function () {
    return {
      getAll: function () {
        return [
          { value: 'pre-design', text: 'Pre-design' },
          { value: 'in process', text: 'Working on designs' },
          { value: 'finalized', text: 'Designs finalized' },
        ]
      },
      getTextByValue: function (value) {
        let option = this.getAll().find(function (option) {
          return option.value === value
        })

        return option && option.text
      },
      getForProjectForm: function () {
        return [
          { value: 'finalized', text: 'Yes' },
          { value: 'in process', text: 'In process with an architect' },
          { value: 'pre-design', text: 'No' },
        ]
      },
    }
  },
]
