/* global angular */
angular.module('bzUserModule').directive('authModalLink', [
  'authModalService',
  'trackAuthService',
  function (authModalService, trackAuthService) {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        logIn: '=?',
        contractor: '=?',
        registerContractor: '=?',
        enableLandingPage: '=?',
        disableTabs: '=?',
      },
      controller: [
        '$element',
        'userModel',
        'urlConstants',
        'coLandingPageService',
        function ($element, userModel, urlConstants, coLandingPageService) {
          this.$onInit = function () {
            $element.on('click', this.openModal.bind(this))
          }

          this.openModal = function ($event) {
            if (this.enableLandingPage && this.shouldRedirectToLandingPage()) {
              trackAuthService.clickCoSignUp('landing')
              this.redirectToLandingPage($event)
            } else if (isMobile.any()) {
              trackAuthService.clickCoSignUp('standard')
              return
            } else {
              userModel.getStatus().then(
                function (res) {
                  if (res.loggedIn) {
                    document.location = urlConstants.contractor.dashboardURL
                  } else {
                    trackAuthService.clickCoSignUp('standard')
                    authModalService.openModal(this.authModalOptions())
                  }
                }.bind(this)
              )
            }

            if ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            }
          }

          this.redirectToLandingPage = function ($event) {
            document.location = urlConstants.contractor.landingURL

            if ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            }
          }

          this.authModalOptions = function () {
            return {
              logIn: this.logIn,
              contractor: this.contractor,
              registerContractor: this.registerContractor,
              disableTabs: this.disableTabs,
            }
          }

          this.shouldRedirectToLandingPage = function () {
            return coLandingPageService.landingPageEnabled()
          }
        },
      ],
    }
  },
])
