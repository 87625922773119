/* global bz_app */
bz_app.filter('recency_date', [
  '$sce',
  '$filter',
  function ($sce, $filter) {
    return function (timestamp, includeTime, separator) {
      var now = new Date()

      separator = separator ? separator : '<br/>'

      function isToday(input) {
        return (
          now.getFullYear() === input.getFullYear() &&
          now.getMonth() === input.getMonth() &&
          now.getDate() === input.getDate()
        )
      }

      var date = new Date(timestamp)

      if (isToday(date)) {
        return $sce.trustAsHtml($filter('date')(date, 'shortTime'))
      } else if (date.getYear() === now.getYear()) {
        var value = null
        if (includeTime) {
          value = $filter('date')(date, 'MMM d,h:mm a').replace(',', separator)
        } else {
          value = $filter('date')(date, 'MMM d')
        }

        return $sce.trustAsHtml(value)
      } else {
        return $sce.trustAsHtml(
          date.toLocaleDateString(navigator.language, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
        )
      }
    }
  },
])

bz_app.filter('to12Hours', [
  'dateService',
  function (dateService) {
    return function (time) {
      if (time) {
        return dateService.to12Hours(time)
      } else {
        return null
      }
    }
  },
])

bz_app.filter('secondsToDateTime', [
  '$filter',
  function ($filter) {
    return function (seconds) {
      var format = 'mm:ss'

      if (seconds >= 3600) {
        format = 'HH:mm'
      }

      return $filter('date')(new Date(0, 0, 0).setSeconds(seconds), format)
    }
  },
])
