/* global BuildingPermitAPI */
/**	details object holds building permit information	*/
bz_app.factory('buildingPermitModel', [
  '$http',
  function ($http) {
    return {
      building_permit_api: new BuildingPermitAPI(),
      load_national_clusters: function () {
        return this.building_permit_api.load_national_clusters()
      },
      load_properties: function (bounds, query_string) {
        return this.building_permit_api.load_properties(bounds, query_string)
      },
      load_permits_sum_by_year_in_city: function (slug) {
        return this.building_permit_api.load_permits_sum_by_year_in_city(slug)
      },
      load_permits_sum_by_year_for_property: function (property_id) {
        return this.building_permit_api.load_permits_sum_by_year_for_property(
          property_id
        )
      },
      load_permits_index_by_month_in_city: function (slug) {
        return this.building_permit_api.load_permits_index_by_month_in_city(
          slug
        )
      },
    }
  },
])
