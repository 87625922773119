/* global angular */
angular.module('bzCommonUtilitiesModule').factory('animFrameService', [
  function () {
    return {
      requestAnimFrame: function (callback) {
        return this.animFrameFn()(callback)
      },

      animFrameFn: function () {
        return (
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          function (callback) {
            window.setTimeout(callback, 1000 / 60)
          }
        )
      },
    }
  },
])
