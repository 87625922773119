/* global angular */
angular.module('bzNavbarModule').factory('modernNavService', [
  '$timeout',
  function ($timeout) {
    return {
      sectionLinks: ['photos', 'about', 'manage'],

      initHoverState: function () {
        this.bodyEl = angular.element('body')
        this.dropdownToggled = false
        this.hoverState = {
          dropdown: false,
          dropdownToggled: false,
          links: {
            sections: {
              photos: false,
              about: false,
              manage: false,
            },
            anySectionHovered: false,
            lastHoveredSection: null,
          },
        }
      },

      handleEnterLink: function (section) {
        this.hoverState.links.sections[section] = true
        this.hoverState.links.lastHoveredSection = section
      },

      handleLeaveLink: function (section) {
        $timeout(
          function () {
            this.hoverState.links.sections[section] = false
          }.bind(this)
        )
      },

      handleEnterDropdown: function () {
        this.hoverState.dropdown = true
      },

      handleLeaveDropdown: function () {
        this.hoverState.dropdown = false
      },

      dropdownOpen: function () {
        return (
          this.hoverState.dropdown ||
          this.dropdownToggled ||
          this.anySectionLinkHovered()
        )
      },

      toggleDropdown: function () {
        this.dropdownToggled = !this.dropdownToggled
        this.toggleScrolling()
      },

      toggleScrolling: function () {
        var method = this.dropdownToggled ? 'addClass' : 'removeClass'
        this.bodyEl[method]('prevent-scroll')
      },

      sectionLinkHovered: function (section) {
        return this.hoverState.links.sections[section]
      },

      hoveredSectionLink: function () {
        for (var i = 0; i < this.sectionLinks.length; i++) {
          if (this.sectionLinkHovered(this.sectionLinks[i])) {
            return this.sectionLinks[i]
          }
        }

        return null
      },

      anySectionLinkHovered: function () {
        return !!this.hoveredSectionLink()
      },

      dropdownSectionOpen: function (section) {
        return (
          this.hoverState.links.lastHoveredSection === section &&
          this.dropdownOpen()
        )
      },
    }
  },
])
