/* global angular */
angular.module('bzNavbarModule').component('navbarAlerts', {
  bindings: {
    alerts: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('_components/navbar/navbar_alerts')
    },
  ],
  controller: [
    'urlConstants',
    'userBulletinModel',
    'trackNavbarActionsService',
    'referralAgreementModalService',
    function (
      urlConstants,
      userBulletinModel,
      trackNavbarActionsService,
      referralAgreementModalService
    ) {
      this.$onInit = function () {}

      this.redirectToReferralAgreement = () => {
        referralAgreementModalService.launchModal({
          source: 'referral agreement alert link',
          hasSignedReferralAgreement: this.alerts.signReferralAgreement,
        })
      }

      this.redirectToProjectPreferences = ($event) => {
        trackNavbarActionsService.alertClick(() => {
          document.location = this.projectPreferencesUrl()
        })

        if ($event) {
          $event.preventDefault()
        }
      }

      this.projectPreferencesUrl = () => urlConstants.contractor.servicesURL

      this.noAlerts = function () {
        if (!this.alerts) return true

        return (
          (!this.alerts.notifications || !this.alerts.notifications.length) &&
          !this.alerts.signReferralAgreement &&
          !this.alerts.verifyPhone &&
          !this.alerts.invalidLicense &&
          !this.alerts.blocksCount &&
          !this.alerts.noProjectPreferences
        )
      }

      this.verifyContactInfoUrl = function () {
        return urlConstants.contractor.contactInfoURL
      }

      this.updateLicenseUrl = function () {
        return urlConstants.contractor.licenseDashboardURL
      }

      this.leadsDashboardNeedsUpdateUrl = function () {
        return (
          urlConstants.contractor.leadsDashboardURL + '?lead_type=needs_update'
        )
      }

      this.redirectToVerifyContactInfo = function ($event) {
        trackNavbarActionsService.alertClick(
          function () {
            document.location = this.verifyContactInfoUrl()
          }.bind(this)
        )

        if ($event) {
          $event.preventDefault()
        }
      }

      this.redirectToUpdateLicense = function ($event) {
        trackNavbarActionsService.alertClick(
          function () {
            document.location = this.updateLicenseUrl()
          }.bind(this)
        )

        if ($event) {
          $event.preventDefault()
        }
      }

      this.redirectToLeadsDashboardNeedsUpdate = function ($event) {
        trackNavbarActionsService.alertClick(
          function () {
            document.location = this.leadsDashboardNeedsUpdateUrl()
          }.bind(this)
        )

        if ($event) {
          $event.preventDefault()
        }
      }

      this.redirectToCTA = function ($event, notificationId, ctaURL) {
        userBulletinModel.markRead(notificationId).then(function () {
          trackNavbarActionsService.alertClick(
            function () {
              document.location = ctaURL
            }.bind(this)
          )
        })

        if ($event) {
          $event.preventDefault()
        }
      }

      this.isDesktop = () => !isMobile.check()
    },
  ],
})
