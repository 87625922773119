import 'AngularBase/_components/angular/components/contractor_location_map_component'
import 'AngularBase/_components/angular/models/contractor_referrals_model'
import contractorProjectBudgetMin from 'AngularBase/_components/angular/components/contractor_project_budget_min_component'

import contractorAvailabilityScreen from 'AngularBase/_components/angular/modules/decline_lead/components/contractor_availability_screen_component'
import contractorBudgetScreen from 'AngularBase/_components/angular/modules/decline_lead/components/contractor_budget_screen_component'
import contractorProjectPreferencesScreen from 'AngularBase/_components/angular/modules/decline_lead/components/contractor_project_preferences_screen_component'
import contractorTravelRangeScreen from 'AngularBase/_components/angular/modules/decline_lead/components/contractor_travel_range_screen_component'
import declineLeadScreen from 'AngularBase/_components/angular/modules/decline_lead/components/decline_lead_screen_component'
import leadReferralScreen from 'AngularBase/_components/angular/modules/decline_lead/components/lead_referral_screen_component'
import leadReferralFormFields from 'AngularBase/_components/angular/modules/decline_lead/components/lead_referral_form_fields_component'
import unclaimedDeclineLeadScreen from 'AngularBase/_components/angular/modules/decline_lead/components/unclaimed_decline_lead_screen_component'
import declineLeadModalController from 'AngularBase/_components/angular/modules/decline_lead/controllers/decline_lead_modal_controller'
import leadReferralInstance from 'AngularBase/_components/angular/modules/decline_lead/models/lead_referral_instance'
import declineLeadService from 'AngularBase/_components/angular/modules/decline_lead/services/decline_lead_service'
import trackDeclineLeadService from 'AngularBase/_components/angular/modules/decline_lead/services/track_decline_lead_service'
import declineLeadModalService from 'AngularBase/_components/angular/modules/decline_lead/services/decline_lead_modal_service'

const requiredDependencies = [
  'bzModalModule',
  'bzCommonUtilitiesModule',
  'bzProjectPreferencesModule',
  'bzConstantsModule',
  'bzContractorModule',
  'bzValidationModule',
  'bzSearchModule',
  'bzChartModule',
  'bzMapModule',
  'bzLocationModule',
]

angular
  .module('bzDeclineLeadModule', requiredDependencies)
  .component('contractorAvailabilityScreen', contractorAvailabilityScreen)
  .component('contractorBudgetScreen', contractorBudgetScreen)
  .component(
    'contractorProjectPreferencesScreen',
    contractorProjectPreferencesScreen
  )
  .component('contractorTravelRangeScreen', contractorTravelRangeScreen)
  .component('declineLeadScreen', declineLeadScreen)
  .component('leadReferralScreen', leadReferralScreen)
  .component('leadReferralFormFields', leadReferralFormFields)
  .component('contractorProjectBudgetMin', contractorProjectBudgetMin)
  .component('unclaimedDeclineLeadScreen', unclaimedDeclineLeadScreen)
  .controller('DeclineLeadModalCtrl', declineLeadModalController)
  .factory('leadReferralInstance', leadReferralInstance)
  .factory('declineLeadService', declineLeadService)
  .factory('trackDeclineLeadService', trackDeclineLeadService)
  .factory('declineLeadModalService', declineLeadModalService)
