/**	class to access building permits api	*/
var BuildingPermitAPI = function () {
  this.load_national_clusters = function () {
    var url = this.properties_api_path() + '?nationwide=1'
    return $.when($.get(url))
  }

  this.load_properties = function (bounds, query_string) {
    var ne_boundary = bounds.getNorthEast(),
      sw_boundary = bounds.getSouthWest()

    var s_url =
      this.properties_api_path() +
      '?ne=' +
      ne_boundary.lat() +
      ',' +
      ne_boundary.lng() +
      '&sw=' +
      sw_boundary.lat() +
      ',' +
      sw_boundary.lng()
    if (typeof query_string != 'undefined' && query_string.length) {
      s_url = s_url + '&' + query_string
    }

    return $.when($.get(s_url))
  }

  this.load_permits_sum_by_year_in_city = function (city_slug) {
    //http://local.buildzoom.com:3000/api/v1/building_permits/sum/year/city/san-francisco-ca
    return $.when(
      $.get(this.building_permit_api_path() + '/sum/year/city/' + city_slug)
    )
  }

  this.load_permits_sum_by_year_for_property = function (property_id) {
    //http://local.buildzoom.com:3000/api/v1/building_permits/sum/year/property/1738698
    return $.when(
      $.get(
        this.building_permit_api_path() + '/sum/year/property/' + property_id
      )
    )
  }

  this.load_permits_index_by_month_in_city = function (city_slug) {
    //http://local.buildzoom.com:3000/api/v1/building_permits/index/month/city/sacramento-ca
    return $.when(
      $.get(this.building_permit_api_path() + '/index/month/city/' + city_slug)
    )
  }
}

BuildingPermitAPI.prototype = new window.BZAPI()
BuildingPermitAPI.prototype.constructor = window.BuildingPermitAPI

window.BuildingPermitAPI = BuildingPermitAPI
