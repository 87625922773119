/* global angular, getURLParameter */
import authValidationService from './services/auth_validation_service'

angular
  .module('bzUserModule', [])
  .service('authValidationService', authValidationService)
  .run([
    'authModalService',
    function (authModalService) {
      var isSignedIn = UserData.user_signed_in()
      var isAuthPage =
        ['/user/sign_up', '/user/sign_in'].indexOf(window.location.pathname) >
        -1

      if (!isSignedIn && !isAuthPage && getURLParameter('auth_modal')) {
        authModalService.openModal({
          logIn: getURLParameter('log_in'),
          contractor: getURLParameter('contractor'),
        })
      }
    },
  ])
