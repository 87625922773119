/* global Track, UserData */
var PaymentsTrack = function () {
  return {
    contractorEvent: 'Payments CO',
    projectOwnerEvent: 'Payments PO',
    eventName: function () {
      return UserData.is_homeowner()
        ? this.projectOwnerEvent
        : this.contractorEvent
    },
    submit: function (payment) {
      Track.impersonation_check().send(
        this.projectOwnerEvent,
        this.defaultData({
          action: 'paid',
          paymentType: payment.payment_source_type,
        })
      )
    },
    defaultData: function (input) {
      var platform = isMobile.check() ? 'mobile' : 'desktop'
      var data = {
        action: input.action,
        platform: platform,
        user_id: UserData.user_id(),
        user_type: UserData.user_role(),
      }

      if (input.paymentType !== undefined) data.payment_type = input.paymentType
      if (input.invoiceId !== undefined) data.invoice_id = input.invoiceId
      if (input.ad_type !== undefined) data.ad_type = input.ad_type
      if (input.content !== undefined) data.content = input.content

      return data
    },
  }
}

window.PaymentsTrack = PaymentsTrack
