/* global angular */
angular.module('bzUserModule').component('claimBusinessSearchFooter', {
  bindings: {
    searchSubmitted: '=',
    page: '=',
    totalPages: '=',
    setPage: '&',
    createContractor: '&',
    contractors: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('users/claim_business_search_footer')
    },
  ],
  controller: [
    function () {
      this.resultsFound = function () {
        return this.contractors && this.contractors.length
      }

      this.showPageNav = function () {
        return this.searchSubmitted && this.totalPages > 1
      }

      this.showPrevPageLink = function () {
        return this.page > 1 && this.totalPages > 1
      }

      this.showNextPageLink = function () {
        return this.page < this.totalPages
      }

      this.prevPage = function () {
        this.setPage({ page: this.page - 1 })
      }

      this.nextPage = function () {
        this.setPage({ page: this.page + 1 })
      }

      this.displayPageLinks = function () {
        var lowerBound = Math.max(this.page - 2, 1)
        var upperBound = Math.min(lowerBound + 4, this.totalPages)
        var pages = []
        for (var i = lowerBound; i <= upperBound; i++) {
          pages.push(i)
        }

        return pages
      }
    },
  ],
})
