/* global angular */
angular.module('bzProposalsModule').factory('trackProposalService', [
  function () {
    return {
      title: 'Proposal Action',
      defaultData: function (params) {
        var platform = isMobile.check() ? 'mobile' : 'desktop'
        var data = {
          action: params.action ? params.action : '',
          platform: platform,
          page: 'dashboard',
        }

        return angular.extend(data, params)
      },
      // template param is for historical consistency
      proposalCreated: function (params) {
        this.send({
          action: 'proposal created',
          template: params.templateAlias,
          templateAlias: params.templateAlias,
          customTemplateId: params.customTemplateId,
        })
      },
      proposalDuplicated: function (newDocumentId, oldDocumentId) {
        this.send({
          action: 'proposal duplicated',
          newDocumentId: newDocumentId,
          oldDocumentId: oldDocumentId,
        })
      },
      proposalDestroyed: function (documentId) {
        this.send({ action: 'proposal destroyed', documentId: documentId })
      },
      templateCreated: function (templateId, documentId) {
        this.send({
          action: 'template created',
          templateId: templateId,
          documentId: documentId,
        })
      },
      templateDestroyed: function (templateId) {
        this.send({ action: 'template destroyed', templateId: templateId })
      },
      send: function (params) {
        Track.send(this.title, this.defaultData(params))
      },
    }
  },
])
