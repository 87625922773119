bz_app.controller('BuildingPermitFormCtrl', [
  function () {
    this.init = function () {
      $('.link-tooltip').tooltip({
        html: false,
        title:
          'Please include a link to an online record or image of this permit from your local jurisdiction',
      })
      $('.gallery-tooltip').tooltip({
        html: false,
        title:
          'Add a photo gallery to your BuildZoom profile to link to this permit',
      })
    }

    this.submit = function (form, e) {
      if (form.$invalid) {
        e.preventDefault()
      }
    }
  },
])
