angular
  .module('bzPotentialLeadFormModule')
  .factory('potentialLeadFormModalService', [
    '$uibModal',
    'urlService',
    'landingPageService',
    'trackContractorContactService',
    'potentialLeadFormService',
    function (
      $uibModal,
      urlService,
      landingPageService,
      trackContractorContactService,
      potentialLeadFormService
    ) {
      return {
        openPotentialLeadModal: function (contractor) {
          const modalInstance = $uibModal.open({
            templateUrl: urlService.templateUrl(
              '_components/components/potential_leads/potential_lead_form_modal'
            ),
            controller: 'PotentialLeadFormModalCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            windowClass: 'v3-modal-simple potential-lead-form-modal-v3',
            resolve: {
              contractor: function () {
                return contractor
              },
            },
          })

          trackContractorContactService.contactButtonClicked({
            contractor_id: contractor.nid,
            version: potentialLeadFormService.getFormVersion(),
          })

          return modalInstance
        },
      }
    },
  ])
