/* global angular, getURLParameter */
angular.module('bzBidModule').component('bidFormContainer', {
  bindings: {
    leadData: '<',
  },
  template:
    '<bid-form lead="$ctrl.lead" referral-source="$ctrl.referralSource" on-close="$ctrl.close()" on-cancel="$ctrl.cancel()"></bid-form>',
  controller: [
    'leadInstance',
    'urlConstants',
    function (leadInstance, urlConstants) {
      this.$onInit = function () {
        this.originalPath = getURLParameter('src')

        this.setInputData(this.leadData)
        this.setSourceUrl()
        this.setReferralSource()
      }

      this.setInputData = function (data) {
        this.lead = new leadInstance()
        this.lead.set(data)
      }

      this.setSourceUrl = function () {
        if (this.originalPath && this.originalPath.length) {
          this.redirectUrl = '/' + decodeURIComponent(this.originalPath)
        } else if (this.lead && this.lead.project && this.lead.project.id) {
          this.redirectUrl = '/project/' + this.lead.project.id
        } else {
          this.redirectUrl = urlConstants.contractor.leadsDashboardURL
        }
      }

      this.setReferralSource = () => {
        this.referralSource = ''

        if (
          this.redirectUrl.includes(urlConstants.contractor.leadsDashboardURL)
        ) {
          this.referralSource = 'contractor dashboard'
        } else if (/lead\/([0-9]*)\/summary/.test(this.redirectUrl)) {
          this.referralSource = 'lead project details'
        } else if (this.redirectUrl.includes(urlConstants.contractor.leadURL)) {
          this.referralSource = 'lead activity header'
        }
      }

      this.close = function () {
        document.location = this.redirectUrl
      }

      this.cancel = function () {
        document.location = this.redirectUrl
      }
    },
  ],
})
