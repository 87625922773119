import AdminNotificationApi from 'Vanilla/api/admin_notification_api'

const AdminContactLinkModule = angular
  .module('adminContactLink', [])
  .component('adminContactLink', {
    bindings: {
      defaultText: '@',
      message: '@',
    },
    template: `
			<a
				ng-if="!$ctrl.displayResponse"
				class="u-clickable" ng-bind="$ctrl.displayText" ng-click="$ctrl.onClick()">
			</a>
			<span ng-if="$ctrl.displayResponse">
				Thanks! Our admins will get back to you about your request.
			</span>
		`,
    controller: [
      'bzHttp',
      function (bzHttp) {
        this.$onInit = () => {
          this.displayResponse = false
          this.displayText = this.defaultText
          this.adminNotificationApi = new AdminNotificationApi(bzHttp)
        }

        this.onClick = () => {
          this.displayResponse = true
          this.adminNotificationApi.notifyDataSubscription(this.message)
        }
      },
    ],
  }).name

export default AdminContactLinkModule
