/* global angular, document */
angular.module('bzCommonUtilitiesModule').directive('preventBodyScroll', [
  function () {
    return {
      restrict: 'A',
      controller: [
        '$element',
        function ($element) {
          this.$onInit = function () {
            var $body = angular.element(document.body)

            $element.on('mouseenter', function () {
              $body.css('overflow', 'hidden')
            })

            $element.on('mouseleave', function () {
              $body.css('overflow', 'auto')
            })
          }

          this.$onDestroy = function () {
            angular.element(document.body).css('overflow', 'auto')
          }
        },
      ],
    }
  },
])
