/* global angular */
angular.module('bzProjectModule').factory('serviceRequestPermitMatchesModel', [
  '$http',
  'urlConstants',
  'modelService',
  function ($http, urlConstants, modelService) {
    return {
      apiPath: function (id) {
        return (
          urlConstants.api.v3.path + 'service_requests/' + id + '/permit_match'
        )
      },

      create: function (serviceRequestId, params) {
        var data = modelService.toUnderscore({
          serviceRequestPermitMatch: params,
        })

        return $http
          .post(this.apiPath(serviceRequestId), data)
          .then(modelService.extractData)
      },

      destroy: function (serviceRequestId) {
        return $http
          .delete(this.apiPath(serviceRequestId))
          .then(modelService.extractData)
      },
    }
  },
])
