/* global angular, UserData */
angular.module('bzNavbarModule').controller('ModernNavCtrl', [
  'animFrameService',
  '$scope',
  'modernNavService',
  '$element',
  'trackNavbarActionsService',
  function (
    animFrameService,
    $scope,
    modernNavService,
    $element,
    trackNavbarActionsService
  ) {
    this.$onInit = function () {
      this.navService = modernNavService
      this.navService.initHoverState()
      this.sectionLinks = this.navService.sectionLinks

      this.showDropdownSections = {
        editProfile: false,
        settings: false,
      }

      $element.hasClass('modern-nav-transparent') && this.initTransparency()

      trackNavbarActionsService.linkClicked('.nav-home-link', 'home link click')
      trackNavbarActionsService.linkClicked(
        '.photo-ribbon-photo-link',
        'photo click'
      )
      trackNavbarActionsService.linkClicked(
        '.photo-ribbon-button',
        'see more photos click'
      )
      trackNavbarActionsService.linkClicked(
        '.about-section-link',
        'about section click'
      )
    }

    this.initTransparency = function () {
      this.transparent = true
      this.watchScroll()
    }

    this.watchScroll = function () {
      animFrameService.requestAnimFrame(
        function () {
          this.handleScroll()
          this.watchScroll()
        }.bind(this)
      )
    }

    this.handleScroll = function () {
      var prev = this.transparent
      this.transparent = window.scrollY <= 0

      if (this.transparent != prev) {
        $scope.$apply('$ctrl.transparent')
      }
    }

    this.handleToggleSubsection = (sectionName) => {
      this.showDropdownSections[sectionName] =
        !this.showDropdownSections[sectionName]
    }
  },
])
