/* global angular */
angular.module('bzProjectFormModule').component('loadingBarHeader', {
  bindings: {
    progress: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/loading_bar_header'
      )
    },
  ],
})
