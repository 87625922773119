/* global angular, bugsnagClient */
angular.module('bzCommonUtilitiesModule').factory('mediaHelperService', [
  'urlConstants',
  function (urlConstants) {
    const mediaHostname = urlConstants.media.hostname
    const imageSizes = urlConstants.media.imageSizes
    return {
      filestackImageUrl: function (fileId, options = {}) {
        const { protocol, width, height, metadata, fit, quality, sizeLabel } =
          options
        if (!fileId) {
          bugsnagClient.notify(
            'mediaHelperService.filestackImageUrl was called with empty fileId parameter'
          )
          return ''
        }

        let params = []
        if (sizeLabel && imageSizes[sizeLabel]) {
          options.width = imageSizes[sizeLabel].width
          options.height = imageSizes[sizeLabel].height
        }
        if (width) params.push(`width=${width}`)
        if (height) params.push(`height=${height}`)
        if (metadata) params.push(`metadata=${metadata}`)

        // Default fit to 'crop' if width AND height have been set and fit is not provided
        if (width && height && !fit) options.fit = 'crop'
        if (fit) params.push(`fit=${fit}`)
        if (quality) params.push(`quality=${quality}`)

        const scheme = protocol ? `${protocol}://` : '//'
        let url = `${scheme}${mediaHostname}/filestack/image/${fileId}`
        if (params.length > 0) url += `?${params.join('&')}`
        return url
      },
      filestackFileUrl: function (fileId, protocol = '') {
        const scheme = protocol ? `${protocol}://` : '//'
        return `${scheme}${mediaHostname}/filestack/file/${fileId}`
      },
      urlToFilestackId: function (url) {
        // Takes a URL and returns the Filestack ID.
        //
        // This is a special function unique to the Angular implementation. It exists because
        // the handling of URLs and Filestack IDs are pretty messy throughout the Angular
        // codebase. Ideally over time, we'd refactor this function out.

        if (url.match(/^[A-Za-z0-9]$/)) {
          // Alphanumeric characters only, assume this is already a filestack ID!
          return url
        }

        if (url.startsWith('//')) {
          // Assume URLs with no protocol specified are https (otherwise the URL parsing will
          // fail).
          url = 'https:' + url
        }

        let parsedURL
        try {
          // Relative URLs will throw an exception here. That's fine because they wouldn't
          // work anyway. In future we probably want to not catch this exception because it
          // would only be masking a bug, but for now we want to focus on rolling out Fastly.
          parsedURL = new URL(url)
        } catch (e) {
          bugsnagClient.notify('error parsing url ' + url + ': ' + e)
          return ''
        }

        // Examples:
        // - https://images.buildzoom.com/api/file/FILESLUGHERE
        // - https://media.buildzoom.com/filestack/image/FILESLUGHERE
        // - https://media.buildzoom.com/filestack/file/FILESLUGHERE
        // - https://cdn.filestackcontent.com/FILESLUGHERE
        // - https://images.buildzoom.com/FILESLUGHERE
        const matches = parsedURL.pathname.match(
          /^(?:\/filestack\/image|\/filestack\/file|\/api\/file)?\/([A-Za-z0-9]+)/
        )
        if (matches) {
          return matches[1]
        }

        bugsnagClient.notify('could not extract filestack ID from url: ' + url)
        return ''
      },
    }
  },
])
