/* global angular, URLify, Autolinker */
angular.module('bzCommonUtilitiesModule').factory('stringService', [
  '$sce',
  '$filter',
  function ($sce, $filter) {
    return {
      // replace string newlines characters with BR for UI presentation in HTML
      newlineToBr: function (text) {
        return $sce.trustAsHtml(text ? $filter('newlines')(text) : '')
      },
      lineFeedToBr: function (text) {
        return $filter('lineFeed')(text)
      },
      mobileReady: function (text) {
        var textWithTelLink = text
          ? text.replace(
              /\(?\d{3}\)?(\s|\-)?\d{3}(\s|\-)?\d{4}/g,
              "<a href='tel:$&'>$&</a>"
            )
          : text
        return this.desktopReady(textWithTelLink)
      },
      desktopReady: function (text) {
        if (text.indexOf('href=') >= 0) {
          return this.newlineToBr(text)
        } else {
          return this.linkify(text)
        }
      },
      linkify: function (text, target) {
        if (target === undefined) {
          target = '_blank'
        }

        return this.newlineToBr(Autolinker.link(text, target))
      },
      toSlug: function (text) {
        return URLify(text.replace(',', '')).toLowerCase()
      },
      splice: function (idx, rem, s, str) {
        return str.slice(0, idx) + s + str.slice(idx + Math.abs(rem))
      },
      replaceCommas: function (value) {
        if (value) {
          return value.toString().replace(/[^\d.]/g, '')
        } else {
          return ''
        }
      },
      toFloat: function (value) {
        if (value) {
          return parseFloat(this.replaceCommas(value))
        } else {
          return null
        }
      },
      toCurrency: function (value) {
        var point = value.indexOf('.')
        if (point >= 0) {
          value = value.slice(0, point + 3)
        }
        var decimalSplit = value.split('.')
        var intPart = decimalSplit[0]
        var decPart = decimalSplit[1]

        intPart = intPart.replace(/[^\d]/g, '')
        if (intPart.length > 3) {
          var intDiv = Math.floor(intPart.length / 3)
          while (intDiv > 0) {
            var lastComma = intPart.indexOf(',')
            if (lastComma < 0) {
              lastComma = intPart.length
            }
            if (lastComma - 3 > 0) {
              intPart = this.splice(lastComma - 3, 0, ',', intPart)
            }
            intDiv--
          }
        }

        if (decPart === undefined) {
          decPart = ''
        } else {
          decPart = '.' + decPart
        }
        var res = intPart + decPart
        return res
      },
      capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
    }
  },
])
