import { timeDifference } from 'Vanilla/services/date_utility'
import DataSubscriptionApi from 'Vanilla/api/data_subscription_api'
import AdminNotificationApi from 'Vanilla/api/admin_notification_api'
import template from './property_data_request_popup_template.html'

const propertyDataRequestPopup = {
  bindings: {
    close: '&?',
    resolve: '<',
  },
  template,
  controller: [
    'bzHttp',
    function (bzHttp) {
      this.$onInit = () => {
        const {
          remainingRequests,
          subscriptionEndDate,
          propertyId,
          requiresEmailVerified,
        } = this.resolve.inputData

        this.dataSubscriptionApi = new DataSubscriptionApi(bzHttp)
        this.adminNotificationApi = new AdminNotificationApi(bzHttp)

        this.propertyId = propertyId
        this.subscriptionEndDate = subscriptionEndDate

        this.remainingDaysInPeriod = Math.floor(
          timeDifference(new Date(), new Date(subscriptionEndDate), 'days')
        )
        this.remainingRequests = remainingRequests

        this.requiresEmailVerified = requiresEmailVerified
      }

      this.requestProperty = () => {
        this.dataSubscriptionApi.requestProperty(this.propertyId).then(() => {
          location.reload()
        })
      }

      this.contactForUpgrade = () => {
        this.adminNotificationApi.notifyDataSubscriptionUpgrade()
        this.close()
      }
    },
  ],
}

export default propertyDataRequestPopup
