/* global angular, isMobile */
angular.module('bzCommonUtilitiesModule').factory('modalScreenService', [
  'urlService',
  '$uibModal',
  function (urlService, $uibModal) {
    return {
      // *** ALERT ***
      //
      // Do not use this method to initialize a modal!
      // It passes an external scope to the template,
      // which is a horrific way of managing a modal's state.
      // Instead, use the initDetached or initDetachedComponent methods below and create
      // a Ctrl to isolate the modal's logic.
      //
      // - Connor (creator and destroyer of modalScreenService.init)
      init: function (
        $scope,
        screens,
        templateUrl,
        modalClass,
        onClose,
        options
      ) {
        function ModalScreenService() {
          this.$scope = $scope
          this.screens = screens
          this.templateUrl = templateUrl
          this.template = ''
          this.modalClass = modalClass
          this.onClose = onClose
          this.options = options

          if (this.options && this.options.template) {
            this.templateUrl = ''
            this.template = options.template
          }

          this.setScreen = function (screenName) {
            if (this.screens.indexOf(screenName) < 0) {
              return false
            }
            if (!this.modal) {
              this.openModal(this.options)
            }
            this.currentScreen = screenName
          }

          this.screenIs = function (screenName) {
            return this.currentScreen && this.currentScreen === screenName
          }

          this.openModal = function (options) {
            options = options || {}
            this.modal = $uibModal.open({
              templateUrl: this.template.length
                ? ''
                : urlService.templateUrl(this.templateUrl),
              template: this.template.length ? this.template : '',
              scope: this.$scope,
              windowClass: this.modalClass,
              backdrop: options.backdrop || true,
              keyboard: options.keyboard || true,
            })

            this.modal.closed.then(this.handleModalClosed.bind(this))
          }

          this.dismissModal = function () {
            if (this.modal) {
              this.modal.dismiss('dismissed')
            }
          }

          this.handleModalClosed = function () {
            this.modal = null
            if (this.onClose) {
              this.onClose()
            }
          }
        }

        return new ModalScreenService()
      },
      initDetached: function (userOptions) {
        const options = Object.assign(
          {
            keyboard: true,
            backdrop: true,
          },
          userOptions
        )

        return $uibModal.open({
          template: options.template,
          templateUrl: urlService.templateUrl(options.templateUrl),
          controller: options.controller,
          controllerAs: '$ctrl',
          bindToController: true,
          keyboard: options.keyboard,
          backdrop: options.backdrop,
          windowClass: options.windowClass,
          resolve: {
            inputData: function () {
              return options.inputData
            },
          },
        })
      },
      initDetachedComponent: function (options) {
        const config = {
          keyboard: true,
          backdrop: true,
          ...options,
        }

        return $uibModal.open({
          component: config.component,
          keyboard: config.keyboard,
          backdrop: config.backdrop,
          windowClass: config.windowClass,
          resolve: {
            inputData: function () {
              return config.inputData
            },
          },
        })
      },
      isSmallScreen: function (minHeight) {
        return (
          isMobile.check({ phoneOnly: true }) ||
          ($(window) && $(window).height() < (minHeight || 575))
        )
      },
    }
  },
])
