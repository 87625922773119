/* global angular, isMobile */
import template from './intro_screen.html'

export default {
  bindings: {
    project: '<',
  },
  template,
  controller: [
    'projectFormScreenService',
    'trackProjectFormService',
    function (projectFormScreenService, trackProjectFormService) {
      this.$onInit = () => {
        this.isMobile = isMobile.check({ phoneOnly: true })
        this.versionCssClass = ''
        this.promiseText = ''
        this.infoBullets = ''
        this.activateTestSettings()
      }

      this.isSmallScreen = () =>
        this.isMobile || ($(window) && $(window).width() < 900)

      this.activateTestSettings = () => {
        this.versionCssClass = 'introTestC'
        this.promiseText = `
				We'll match you with a Project Consultant like <span class="promise-name">Rory</span> —
				a real <br/>human being who will help with things like&hellip;
				`
      }

      this.dismissIntroScreen = () => {
        trackProjectFormService.submitIntroScreen(
          angular.extend(
            {
              title: this.project.summary.title,
            },
            this.project.analytics
          )
        )

        projectFormScreenService.showIntro = false
      }

      this.isFormVersion = (formVersion) =>
        this.project.analytics.formVersion === formVersion
    },
  ],
}
