bz_app.controller('ReviewCtrl', [
  '$http',
  '$scope',
  '$element',
  '$compile',
  '$timeout',
  'reviewData',
  'reviewInstance',
  'reviewResponseModel',
  'reviewResponseModalService',
  'applicationConstants',
  'filepickerService',
  'trackReviewFormService',
  'reviewsModel',
  'modalScreenService',
  'attributeConversionService',
  'positionService',
  'visitorsModel',
  function (
    $http,
    $scope,
    $element,
    $compile,
    $timeout,
    reviewData,
    reviewInstance,
    reviewResponseModel,
    reviewResponseModalService,
    applicationConstants,
    filepickerService,
    trackReviewFormService,
    reviewsModel,
    modalScreenService,
    attributeConversionService,
    positionService,
    visitorsModel
  ) {
    this.initContractorPageReview = function (contractorId) {
      this.contractorId = contractorId
      this.formReviewData = {}
    }

    this.visitorId = () => Cookie.get('buildzoom_visitor')

    this.init = function (review, currentUserNid, contractorId, reviewDispute) {
      this.review =
        attributeConversionService.underscoreToCamelDeepAttributes(review)
      this.reviewDispute =
        attributeConversionService.underscoreToCamelDeepAttributes(
          reviewDispute
        )
      this.admin_users_api = new AdminUsersAPI()
      this.formSubmitted = false
      this.review_id = $element.data('review-id')
      this.initializeDatepicker()
      this.modalShownEvent()
      this.responseActionsClicked = false
      this.showStatusExplanation = false
      this.canSubmitDispute = false
      this.trackDisputeModalSubmitted(currentUserNid === review.contractor_id)
      this.initContractorId(contractorId)
    }

    this.initContractorId = function (contractorId) {
      this.contractorId = contractorId
    }

    this.openReviewResponseModal = function (review, reviewResponse) {
      this.initReview(review, reviewResponse)
      reviewResponseModalService.show(this.review)
    }

    this.toggleResponseActionsClicked = function () {
      if (this.showStatusExplanation) {
        this.showStatusExplanation = false
      }
      this.responseActionsClicked = !this.responseActionsClicked
    }

    this.toggleShowStatusExplanation = function () {
      if (this.responseActionsClicked) {
        this.responseActionsClicked = false
      }
      this.showStatusExplanation = !this.showStatusExplanation
    }

    this.statusExplanation = function (status) {
      var statusExplanations = {
        approved: 'This review is approved and posted on your public profile.',
        disputed:
          'BuildZoom is autditing this review. We will notify you within a couple days whether we remove or uphold the review.',
        rejected: 'This review was removed from your public profile',
      }
      return statusExplanations[status]
    }

    this.deleteResponse = function (review, reviewResponse) {
      this.initReview(review, reviewResponse)
      reviewResponseModel.destroy()
    }

    this.approveResponse = function (review, reviewResponse) {
      this.initReview(review, reviewResponse)
      reviewResponseModel.updateStatus('approved')
    }

    this.rejectResponse = function (review, reviewResponse) {
      this.initReview(review, reviewResponse)
      reviewResponseModel.updateStatus('rejected')
    }

    this.verify_email = function (event, user_id) {
      event.preventDefault()
      var $span = angular.element(event.target)
      this.admin_users_api.verify_email(user_id).then(function (data) {
        $span
          .parent()
          .html("<span class='label label-primary'>EMAIL V.B. ADMIN</span>")
      })
    }

    this.fadeOut = function (e, bool) {
      if (bool) angular.element(e.target).parents('li').fadeOut('fast')
    }

    this.loadEditReviewModal = function (review_id, callback, $event) {
      angular.element('.review-modal-wrapper').remove()
      angular
        .element('body')
        .append("<div class='review-modal-wrapper'</div>")
        .find('.review-modal-wrapper')
        .load(
          '/api/v1/reviews/' +
            review_id +
            '/form?contractor_id=' +
            this.contractorId,
          function () {
            this.initializeReviewModal(angular.element('#review_modal'))

            $timeout(() => {
              $scope.$apply(function () {
                this.formSubmitted = false
              })
            })

            if (callback) callback()
          }.bind(this)
        )

      if ($event) {
        $event.preventDefault()
      }
    }

    this.initializeReviewModal = function ($reviewModal) {
      $reviewModal.modal()
      $compile($reviewModal.find('.filepicker_field'))($scope)
      angular.element('#review_flash').html('')
      angular.element('[data-toggle="tooltip"]').tooltip()
      this.initializeDatepicker()
      this.initializeFilepicker()
    }

    this.initializeFilepicker = function () {
      var that = this
      $('.attachments-wrapper .filepicker_field input').change(function (e) {
        var files = e.originalEvent.fpfiles
        var bid_id = $('#files_uploaded').attr('data-bid-id')
        var contractor_id = $('#files_uploaded').attr('data-contractor-id')
        for (var i = 0; i < files.length; i++) {
          var data = {
            filename: files[i]['filename'],
            filepicker_slug: files[i]['url'],
            file: 'true',
            bid_id: bid_id,
            contractor_id: contractor_id,
            s3_slug: files[i]['key'],
          }
          $.ajax({
            type: 'POST',
            url: '/api/v1/images',
            data: data,
            dataType: 'json',
            success: function (response, status, xhr) {
              //add image_id to field
              var current_value = $('input#new_images').val()
              $('input#new_images').val(current_value + response.image + ' ')
              // needs image_id / contractor id
              $('#files_uploaded').append(
                "<div class='image space1' data-image-id='" +
                  response.image +
                  "'><div class='btn-delete-file not-bound'><span class='glyphicon glyphicon-trash'></span></div><a href='" +
                  response.cloudfront_slug +
                  "' target='_blank'><span class='glyphicon glyphicon-download-alt' style='margin-right:4px;'></span><span class='label label-info file-label'>" +
                  response.filename +
                  '</span></a>'
              )

              $('.btn-delete-file.not-bound')
                .bind('click', function (e) {
                  that.delete_file($(this))
                })
                .removeClass('not-bound')
            },
          })
        }
      })

      $('.btn-delete-file').click(function (e) {
        that.delete_file($(this))
      })
    }

    this.delete_file = function (object) {
      object.parents('.image').remove() //remove from view
      //delete file via api
      var contractor_id = object.parents('.image').attr('data-contractor-id')
      var image_id = object.parents('.image').attr('data-image-id')
      var data = {
        id: image_id,
        contractor_id: contractor_id,
        file: 'true',
        parent_type: 'review',
      }
      $.ajax({
        type: 'DELETE',
        url: '/api/v1/images/' + image_id,
        data: data,
        dataType: 'json',
        success: function (response, status, xhr) {},
      })
    }

    this.initializeDatepicker = function () {
      angular
        .element('input.datepick')
        .datepicker()
        .on('changeDate', function (e) {
          $(this).datepicker('hide')
        })
    }

    this.renderErrorMessages = function (wrapper_object, messages, callback) {
      var html =
        "<div id='error_explanation' class='alert fade in alert-danger'><h5>Please correct the following errors:</h5><ul>"
      for (var i = 0; i < messages.length; i++) {
        html += '<li>' + messages[i] + '<li>'
      }
      html += '</ul></div>'
      wrapper_object.html(html)

      $timeout(
        function () {
          wrapper_object.html('')
        }.bind(this),
        5000
      )

      callback()
    }

    this.submitReviewWithAngularData = function (e) {
      e.preventDefault()
      if (this.formSubmitted) {
        return false
      } else {
        this.formSubmitted = true
      }

      this.formReviewData.stars = angular
        .element('.rating-dynamic.new')
        .data('value')
      this.formReviewData.visitor_id = this.visitorId()

      reviewsModel
        .submit(this.formReviewData)
        .then(this.reviewSubmissionResponseHandler.bind(this))
    }

    this.reviewSubmissionResponseHandler = function (res) {
      var allowResubmitCallback = function () {
        this.formSubmitted = false
      }.bind(this)

      if (res.data.success) {
        if (res.data.review.id) {
          this.review = new reviewInstance()
          this.review.set(res.data.review)
          allowResubmitCallback()

          $timeout(() => {
            angular.element('#review-form-trigger').click()

            angular.element('.rating-dynamic').raty('cancel')
            this.formReviewData.stars = null
            this.formReviewData.comment = ''
          }, 500)
        } else {
          trackReviewFormService.undefinedReview({ formVersion: 1.1 })
          location.reload()
        }
      } else {
        this.renderErrorMessages(
          angular.element('#review_flash'),
          res.data.errors.split(','),
          allowResubmitCallback
        )
      }
    }

    this.showEditReviewForm = function (e) {
      e.preventDefault()
      var $reviewBody = angular.element(e.target).closest('div.review_body')
      $reviewBody.hide().next('.edit_review_form').show().removeClass('hide')
      return false
    }

    this.cancelEditReviewForm = function (e) {
      var $reviewForm = angular
        .element(e.target)
        .closest('.edit_review_form')
        .hide()
      $reviewForm.prev('.review_body').show()
      e.preventDefault()
      return false
    }

    this.modalShownEvent = function () {
      angular
        .element('#review_' + this.review_id + 'disputeModal')
        .on('shown.bs.modal', function (x) {
          angular.element(this).parent().removeClass('col-viewport')
        })
    }

    this.trackDisputeModalOpened = function (isContractor) {
      var opener = isContractor ? 'CO' : 'PO'
      Track.send('Review Dispute Action', {
        action: 'dispute modal opened (' + opener + ')',
      })
    }

    this.trackDisputeModalSubmitted = function (isContractor) {
      var opener = isContractor ? 'CO' : 'PO'
      Track.send_form(
        '#review_dispute_form_' + this.review_id,
        'Review Dispute Action',
        { action: 'dispute modal submitted (' + opener + ')' }
      )
    }

    this.launchDisputeModal = () => {
      modalScreenService.initDetached({
        controller: 'ReviewDisputeModalCtrl',
        templateUrl: 'reviews/review_dispute_modal',
        windowClass: 'modal modal-modern review-dispute',
        inputData: {
          review: this.review,
          reviewDispute: this.reviewDispute,
        },
      })
    }

    //temp placeholder for review API
    this.initReview = function (review, reviewResponse) {
      if (!reviewResponse) {
        reviewResponse = {}
      }
      reviewData.set({
        id: review.id,
        stars: review.stars,
        body: review.comment,
        name: review.name,
        created_at: review.created_at,
        location: review.location,
        response: {
          body: reviewResponse.body,
          status: reviewResponse.status,
          id: reviewResponse.id,
        },
      })
      this.review = reviewData
    }
  },
])
