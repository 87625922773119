angular.module('bzBidModule').component('bidForm', {
  bindings: {
    lead: '<',
    referralSource: '<',
    onClose: '&',
    onCancel: '&',
    changeScreen: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('leads/unlocked_lead/bid_form')
    },
  ],
  controller: [
    'bidInstance',
    'filepickerService',
    '$timeout',
    'trackLeadsService',
    '$rootScope',
    'globalBannerService',
    function (
      bidInstance,
      filepickerService,
      $timeout,
      trackLeadsService,
      $rootScope,
      globalBannerService
    ) {
      this.$onInit = function () {
        this.bid = new bidInstance()
        this.minimumBid = 500
        this.showAdminDelete =
          this.lead.bid && UserData.is_admin_or_impersonating()

        $timeout(
          function () {
            this.bid.initAttachments('bid-attachment-selector')
          }.bind(this),
          250
        )

        this.showConfirmation = false
        this.hasSubmitted = false

        if (this.lead.bid) {
          this.bid.set(this.lead.bid)
          this.newBid = false
          this.previousBidPrice = this.lead.bid.price

          if (this.bid.bidType === 'admin_detailed_bid') {
            $timeout(
              function () {
                $('.detailed-bid-modal-tooltip').tooltip({
                  html: false,
                  title: `
									This value is based on the latest bid we have on file.
									Please contact the BuildZoom project team to update:
										${this.lead.project.concierge.email} | ${this.lead.project.concierge.phoneNumber}.
									`,
                })
              }.bind(this),
              250
            )
          }
        } else {
          this.bid.set({
            contractorId: this.lead.contractor.id,
            serviceRequestId: this.lead.project.id,
            bidType: 'initial',
          })
          this.newBid = true
        }

        this.setFinishAllowanceOptions()
        filepickerService.initForContractor(this.bid.contractorId)
        trackLeadsService.bidFormViewed(this.lead, this.referralSource)
      }

      this.ok = function () {
        let bidPromise
        this.submissionBlocked = false

        if (this.getValidationData().isValid) {
          this.disableSubmit()

          if (this.bidHasNotChanged()) {
            trackLeadsService.bidUpdated(this.lead)
            bidPromise = this.bid.save()
          } else {
            trackLeadsService.bidCreated(this.lead)
            bidPromise = this.bid.create()
          }

          bidPromise
            .then(
              ({ success, message }) => {
                if (success) {
                  this.lead.bid = this.bid.objectify()
                  this.hasSubmitted = true
                  if (this.bid.attachments.length > 0) {
                    this.showConfirmation = true
                    globalBannerService.reload()
                    $timeout(() => this.close(), 1500)
                  } else {
                    $timeout(
                      function () {
                        this.bid.initAttachments('bid-attachment-selector')
                      }.bind(this),
                      250
                    )
                  }
                } else {
                  this.submissionBlocked = true
                  this.errorMessage = message
                }
              },
              () => {
                this.submissionBlocked = true
                this.errorMessage = 'Error submitting bid.'
              }
            )
            .finally(() => {
              this.enableSubmit()
            })
        } else {
          this.submissionBlocked = true
          this.errorMessage = this.getValidationData().errorMessage
          angular.element('#bid-price').focus()
        }
      }

      this.close = function () {
        this.onClose()
      }

      this.cancel = function ($event) {
        this.onCancel()

        if ($event) {
          $event.preventDefault()
        }
      }

      this.cancelAttachmentScreen = function ($event) {
        if (
          confirm(
            'You have not yet submitted your bid document. Exit without submitting?'
          )
        ) {
          this.onCancel()
        }

        if ($event) {
          $event.preventDefault()
        }
      }

      this.bidHasNotChanged = () =>
        this.bid.id && Number(this.previousBidPrice) === Number(this.bid.price)

      this.removeAttachment = function ($index) {
        this.bid.attachments.splice($index, 1)
      }

      this.modalTitle = function () {
        return this.lead.bid
          ? 'Update your official bid for'
          : 'Send an official bid to '
      }

      this.modalConfirmationTitle = function () {
        return this.newBid ? 'Bid Sent' : 'Updated Bid Sent'
      }

      this.projectOwnerName = function () {
        return this.lead.project.projectOwner &&
          this.lead.project.projectOwner.fullName
          ? this.lead.project.projectOwner.fullName
          : 'the project owner'
      }

      this.resetBlock = () => (this.submissionBlocked = false)

      this.enableSubmit = function (element) {
        angular
          .element('#bid-modal-submit')
          .text('Submit Bid')
          .attr('disabled', false)
      }

      this.disableSubmit = function (element) {
        angular
          .element('#bid-modal-submit')
          .text('Submitting...')
          .attr('disabled', true)
      }

      this.isValidBidPrice = () => {
        return (
          this.bid.price &&
          !isNaN(parseFloat(this.bid.price)) &&
          this.bid.price >= this.minimumBid
        )
      }

      this.getValidationData = function () {
        return {
          isValid: this.isValidBidPrice(),
          errorMessage:
            this.bid.price < this.minimumBid
              ? `Please provide a bid greater than $${this.minimumBid}.`
              : 'Please provide the bid price.',
        }
      }

      this.setFinishAllowanceOptions = function () {
        this.finishAllowanceOptions = [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ]
      }
    },
  ],
})
