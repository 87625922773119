import template from './unsubscribe_confirmation_modal.html'

export default {
  bindings: {
    resolve: '<',
    close: '&?',
  },
  template,
  controller: [
    'communicationPreferenceModel',
    '$scope',
    function (communicationPreferenceModel, $scope) {
      this.$onInit = () => {
        this.undo = false
        this.subscriptionText = this.resolve.inputData.subscriptionText
        this.preferenceObject = {
          communicableId: this.resolve.inputData.communicableId,
          communicableType: this.resolve.inputData.communicableType,
          communicationType: this.resolve.inputData.communicationType,
          subscriptionType: this.resolve.inputData.subscriptionType,
          subscribed: false,
        }

        communicationPreferenceModel.update(this.preferenceObject)
      }

      this.undoUnsubscribe = async () => {
        this.preferenceObject.subscribed = true
        let res = await communicationPreferenceModel.update(
          this.preferenceObject
        )
        if (res.success) $scope.$apply(() => (this.undo = true))
      }
    },
  ],
}
