/* global angular */
import RageTracker from 'Vanilla/services/rageTracker'

angular.module('bzProjectFormModule').component('emailScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/email'
      )
    },
  ],
  controller: [
    '$timeout',
    '$element',
    '$scope',
    'formValidationService',
    'userModel',
    'projectFormScreenService',
    '$q',
    function (
      $timeout,
      $element,
      $scope,
      formValidationService,
      userModel,
      projectFormScreenService,
      $q
    ) {
      this.$onInit = function () {
        this.rageTracker = new RageTracker({
          title: 'SR Flow Email Rage Click',
        })
        this.resetErrors()
        this.initValidateInitialEmail()
        this.watchSavedEmailUpdates()

        this.isExistingUser = false

        this.tabIndex = {
          emailInput: 11,
          continueButton: 12,
        }

        // add callback to be triggered when screen is changed to re-focus email field cursor
        projectFormScreenService.setScreenCallbacks.push(() => {
          projectFormScreenService.focusInputOnScreenShow(
            'email',
            '.project-owner-email'
          )
        })
      }

      this.initValidateInitialEmail = function () {
        if (
          this.project.projectOwner.email &&
          this.project.projectOwner.email.length
        ) {
          this._validateEmail()
        }
      }

      this.validateEmail = function () {
        if (!this.project.id) {
          $timeout(this._validateEmail.bind(this))
        }
      }

      this._validateEmail = function () {
        if (
          formValidationService.isValidEmail(this.project.projectOwner.email)
        ) {
          return userModel
            .findExistingUserByEmail(this.project.projectOwner.email, true)
            .then((response) => {
              if (response && response.success) {
                this.isExistingUser =
                  response.existingUser && !response.signedIn ? true : false
                this.errors.email = ''
                this.errors.password = ''

                return false
              }

              return true
            })
        } else {
          if (this.isExistingUser) {
            this.isExistingUser = false
          }

          this.errors.email = 'Please provide a valid email address'

          return $q(function (resolve) {
            resolve(false)
          })
        }
      }

      this.validatePassword = function () {
        if (!this.project.id) {
          $timeout(this._validatePassword.bind(this))
        }
      }

      this._validatePassword = function () {
        if (
          formValidationService.isValidEmail(this.project.projectOwner.email) &&
          this.project.projectOwner.password
        ) {
          return userModel
            .validateExistingUserPassword(
              this.project.projectOwner.email,
              this.project.projectOwner.password
            )
            .then((response) => {
              if (response && response.success) {
                this.errors.password = ''
                return { success: true }
              } else {
                this.errors.password =
                  'The email / password does not match our records'
                return { success: false }
              }
            })
        } else {
          this.errors.password =
            'Please provide a valid email address / password'

          return $q(function (resolve) {
            resolve({ success: false })
          })
        }
      }

      this.watchSavedEmailUpdates = function () {
        this.savePromise = null
        $scope.$watchGroup(
          ['$ctrl.project.projectOwner.email'],
          this.resolveEmailUpdates.bind(this)
        )
      }

      this.resolveEmailUpdates = function (newValue, oldValue) {
        if (angular.equals(newValue, oldValue)) {
          return
        }

        if (this.project.id && this.project.init) {
          $timeout.cancel(this.savePromise) // cancel previous timeout (if not yet occured)

          this.savePromise = $timeout(
            function () {
              this.project.projectOwner.contactInfo = {
                email: { value: this.project.projectOwner.email },
                phone: { value: this.project.projectOwner.phone },
              }

              this.project
                .saveContactInfo()
                .then(this.processEmailUpdatesResponse.bind(this))
            }.bind(this),
            1000
          ) // set new 1 sec timeout
        }
      }

      this.processEmailUpdatesResponse = function (response) {
        if (response.success) {
          this.errors.email = null
        } else if (this.project.errors.projectOwner.contactInfo) {
          this.errors.email = this.project.errors.projectOwner.contactInfo
          this.project.projectOwner.email =
            this.project.projectOwner.contactInfo.email.value
        }
      }

      this.validateBeforeNextScreen = function () {
        if (!this.errors.email) {
          this.rageTracker.click({
            ...this.project.analytics,
            screen: 'email',
            value: this.project.projectOwner.email || 'no input',
            displayedError: this.errors.email,
            isInitialized: this.project.init,
          })
        }

        this.resetErrors()

        this._validateEmail().then(() => {
          if (this.isExistingUser) {
            this._validatePassword().then((response) => {
              if (response && response.success) {
                this.validateBasics()
              }
            })
          } else {
            this.validateBasics()
          }
        })
      }

      this.validateBasics = function () {
        let hasErrors = false

        if (
          !formValidationService.isValidEmail(this.project.projectOwner.email)
        ) {
          this.errors.email = 'Please provide a valid email address'
          hasErrors = true
        }

        if (
          this.isExistingUser &&
          (!this.project.projectOwner.password ||
            this.project.projectOwner.password.length < 6)
        ) {
          this.errors.password =
            'Please provide a password, with at least 6 characters'
          hasErrors = true
        }

        if (!hasErrors) {
          this.isExistingUser = false

          if (projectFormScreenService.isCurrentScreen('email')) {
            projectFormScreenService.showNextScreen()
          }
        }
      }

      this.resetErrors = function () {
        if (!this.errors) {
          this.errors = {}
        }

        this.errors.email = null
        this.errors.password = null
      }

      this.keyboardToNextScreen = function ($event) {
        if ($event.keyCode === 13) {
          $event.target.blur()

          $timeout(
            function () {
              this.validateBeforeNextScreen()
            }.bind(this),
            200
          )
        }
      }

      this.isFormVersion = function (formVersion) {
        return this.project.analytics.formVersion === formVersion
      }

      this.headerText = function () {
        if (
          this.project.analytics &&
          this.project.analytics.potentialLeadFormVersion
        ) {
          return (
            'What is the best email address for ' +
            (this.project.analytics.potentialLeadContractorBusinessName ||
              'the contractor') +
            ' to reach you?'
          )
        } else {
          return 'What is your email address?'
        }
      }
    },
  ],
})
