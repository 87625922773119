/* global angular */
angular.module('bzProjectFormModule').directive('zipcodeInput', [
  'userLocationModel',
  '$compile',
  'validationService',
  function (userLocationModel, $compile, validationService) {
    return {
      restrict: 'AE',
      replace: true,
      template:
        '<input ng-model="formData.zipcode" class="form-control modern-form-control" maxlength="5" placeholder="Zip code" size="5" pattern="[0-9]*" type="tel" name="service_request[zipcode]" id="service_request_zipcode" autocomplete="shipping postal-code">',
      link: function (scope, element, attr) {
        scope.userLocationData = userLocationModel.data
        scope.$watch('userLocationData.zipcode', function (newValue, oldValue) {
          if (newValue === oldValue) {
            return
          }

          scope.formData.zipcode = userLocationModel.data.zipcode
        })

        if (scope.validateInput) {
          scope.validationService = validationService

          var errorElement = $compile(
            '<div input-error error-text="Please enter your zipcode." error-validator="validationService.isValidZipcode()" error-visible="showFormErrors"></div>'
          )(scope)
          element.before(errorElement)
        }
      },
    }
  },
])
