/* global angular */
angular.module('bzUserModule').service('coLandingPageService', [
  'localStorageModel',
  function (localStorageModel) {
    return {
      landingPageEnabled: function () {
        // if (localStorageModel.get('coLandingPageEnabled')) {
        // 	return JSON.parse(localStorageModel.get('coLandingPageEnabled'));
        // }
        //
        // var enable = Math.random() >= 0.5;
        // localStorageModel.set('coLandingPageEnabled', enable.toString());
        //
        // return enable;
        return false
      },
    }
  },
])
