/* global google */
/* eslint-disable */
bz_app.factory('markerService', [
  '$rootScope',
  function ($rootScope) {
    var GOOGLE_MARKER_Z_INDEX = google.maps.Marker.MAX_ZINDEX
    //sets or resets z-index to default if no change param is passed
    var setZIndex = function (marker, change) {
      if (marker && marker != -1) {
        if (change != 'undefined') {
          marker.setZIndex(GOOGLE_MARKER_Z_INDEX + change)
        } else {
          //reset z-index to default
          marker.setZIndex(GOOGLE_MARKER_Z_INDEX)
        }
      }
    }
    var unselectActive = function (marker) {
      if (marker) {
        marker.setIcon(bzGlobal.map.marker.circle.hover)
        marker.selected = false
      }
    }

    var selectNewActive = function (marker) {
      // unselect last active marker
      if (markerService.activeMarker != '') {
        markerService.unselect(markerService.activeMarker)
      }
      if (marker) {
        marker.setIcon(bzGlobal.map.marker.circle.selected)
        markerService.activeMarker = marker
        marker.selected = true
      }
    }

    var markerService = {
      activeMarker: '',
      hover: function (marker) {
        //check for -1 because propertiesService.getPinByAddress returns -1 if marker not found by address lookup (markers not on map yet)
        if (marker != -1 && !marker.selected && !marker.ignore) {
          marker.setIcon(bzGlobal.map.marker.circle.hover)
        }
      },
      unselect: function (marker) {
        if (marker != -1) {
          if (marker.selected) {
            setZIndex(marker)
          }
          marker.setIcon(bzGlobal.map.marker.circle.unselected)
          marker.selected = false
          $rootScope.$broadcast('pin.unselected', marker)
        }
      },
      unhover: function (marker) {
        if (marker != -1 && !marker.selected && !marker.ignore) {
          markerService.unselect(marker)
        }
      },
      select: function (marker, target) {
        if (!marker.ignore) {
          setZIndex(marker, 1)
          if (marker.selected && target == 'marker') {
            unselectActive(marker)
          } else {
            selectNewActive(marker)
          }
        }
      },
      registerEventListeners: function (marker) {
        google.maps.event.addListener(marker, 'click', function () {
          markerService.select(marker, 'marker')
          $rootScope.$broadcast('pin.clicked', marker)
        })

        google.maps.event.addListener(marker, 'mouseover', function () {
          markerService.hover(marker)
        })

        google.maps.event.addListener(marker, 'mouseout', function () {
          markerService.unhover(marker)
        })
      },
    }
    return markerService
  },
])
