/* global $ */
var AdminUsersAPI = function () {
  this.verify_email = function (user_id) {
    return $.when(
      $.ajax({
        url: this.admin_users_path() + '/' + user_id,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          user: {
            email_verified: 'verified',
            email_verification_source: 'admin',
          },
        }),
        dataType: 'json',
      })
    )
  }

  this.verify_phone = function (user_id) {
    return $.when(
      $.ajax({
        url: this.admin_phone_numbers_api_path() + '/admin_verify',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ user_id: user_id }),
        dataType: 'json',
      })
    )
  }
}

AdminUsersAPI.prototype = new BZAPI()
AdminUsersAPI.prototype.constructor = AdminUsersAPI

window.AdminUsersAPI = AdminUsersAPI
