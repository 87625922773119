/* global bz_app, angular */
bz_app.controller('ConversationCtrl', [
  '$scope',
  '$stateParams',
  '$timeout',
  '$sce',
  '$filter',
  '$rootScope',
  '$compile',
  'conversationModel',
  'urlConstants',
  'userInfoService',
  'dashboardUIService',
  'urlService',
  function (
    $scope,
    $stateParams,
    $timeout,
    $sce,
    $filter,
    $rootScope,
    $compile,
    conversationModel,
    urlConstants,
    userInfoService,
    dashboardUIService,
    urlService
  ) {
    this.$onInit = function () {
      this.conversation = conversationModel
      this.urlConstants = urlConstants
      this.userInfoService = userInfoService
      this.utils = urlService
      this.conversationMessagesCollapsed = true // by default collapse long conversations

      this.conversation.set_id($stateParams.id)

      // load conversation info
      this.conversation.load(
        function () {
          this.collapseConversation()

          if (!userInfoService.current_staff_user) {
            this.conversation.markAsRead().then(function () {
              // mark convo as read
              dashboardUIService.updateUnreadCountUI()
            })
          }

          this.setSubnavSelection()
          this.initBlockAuthTokenLinkClick()
        }.bind(this)
      )

      // listener when convo needs to be reloaded
      $scope.$on(
        'conversation-reload',
        function () {
          this.conversation.load(
            function () {
              this.collapseConversation()
            }.bind(this)
          )
        }.bind(this)
      )

      $scope.$on(
        'conversation-messages-expanded',
        function () {
          this.conversationMessagesCollapsed = false
        }.bind(this)
      )
    }

    this.initBlockAuthTokenLinkClick = function () {
      if (userInfoService.role === 'contractor') {
        $timeout(
          function () {
            angular
              .element('[href*="auth_token="]')
              .each(function (index, element) {
                angular.element(element).attr('href', 'javascript:void(0)')
              })
          }.bind(this),
          500
        )
      }
    }

    this.collapseConversation = function () {
      if (this.conversationMessagesCollapsed) {
        $timeout(function () {
          $compile(
            '<div collapsed-messages message-count="$ctrl.collapsedMessageCount()" ng-cloak></div>'
          )($scope) // create collapsed messages summary (count of collapsed messages)
        }, 50)
      }
    }

    this.setSubnavSelection = function () {
      angular
        .element('ul.list-sub-nav li a')
        .removeClass('list-sub-nav-a-selected')
      angular
        .element(
          "ul.list-sub-nav li a[data-conversations-status='" +
            this.conversation.data.status +
            "']"
        )
        .addClass('list-sub-nav-a-selected')
    }

    this.collapsedMessageCount = function () {
      return angular.element('.conversation-message-collapsed').length
    }
  },
])
