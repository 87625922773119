import bugsnag from 'bugsnag-js'

const bugsnagClient = bugsnag({
  apiKey: bzGlobal.applicationConstants.bugsnagKey,
  releaseStage: bzGlobal.applicationConstants.railsEnv,
  notifyReleaseStages: ['production', 'development', 'staging'],
})
bugsnagClient.user = window.bzGlobal.currentUser

export default bugsnagClient
