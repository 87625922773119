/* global bz_app */
bz_app.factory('contractorLeadBlocksFlow', [
  '$http',
  'urlConstants',
  '$timeout',
  '$uibModal',
  'urlService',
  function ($http, urlConstants, $timeout, $uibModal, urlService) {
    return {
      apiContractorBlocksPath: urlConstants.api.v1.path + 'contractor_blocks/',
      leadPath: '/lead/',
      leadsDashboardPath: function (leadType) {
        var path = urlConstants.contractor.leadsDashboardURL
        if (leadType) {
          path += '?lead_type=' + leadType
        }
        return path
      },
      onLeadsDashboard: function (blockType) {
        return (
          document.location.pathname ===
            urlConstants.contractor.leadsDashboardURL &&
          (!blockType || document.location.search.indexOf(blockType) >= 0)
        )
      },
      delayForModal: 3000,
      delayForRedirect: 4000,
      referrerServiceRequestId: localStorage.getItem(
        'referrerServiceRequestId'
      ),

      run: function (contractorId, customLeadTab) {
        return $http.get(this.apiContractorBlocksPath + contractorId).then(
          function (res) {
            if (res.data.blocks.length) {
              if (!this.onLeadsDashboard()) {
                this.redirectToLeadDashboard('needs_update')
              }
            } else if (customLeadTab) {
              if (!this.onLeadsDashboard(customLeadTab)) {
                this.redirectToLeadDashboard(customLeadTab)
              }
            } else if (this.referrerServiceRequestId) {
              localStorage.removeItem('referrerServiceRequestId')
              this.redirectToLead(this.referrerServiceRequestId)
            } else if (!this.onLeadsDashboard()) {
              this.redirectToLeadDashboard()
            }
          }.bind(this)
        )
      },

      redirectToLead: function (serviceRequestId) {
        $timeout(
          function () {
            document.location = this.leadPath + serviceRequestId
          }.bind(this),
          this.delayForRedirect
        )
        $timeout(
          function () {
            this.showNoticeModal()
          }.bind(this),
          this.delayForModal
        )
      },

      redirectToLeadDashboard: function (blockType) {
        $timeout(
          function () {
            document.location = this.leadsDashboardPath(blockType)
          }.bind(this),
          this.delayForRedirect
        )
        $timeout(
          function () {
            if (!this.onLeadsDashboard()) {
              this.showNoticeModal()
            }
          }.bind(this),
          this.delayForModal
        )
      },

      showNoticeModal: function () {
        var modalInstance = $uibModal.open({
          template:
            '<div class="lead-blocks-flow-notice">' +
            '<div class="space1"></div>' +
            '<center>You Are Being Redirected</center>' +
            '</div>',
          backdrop: 'static',
          windowClass: 'lead-blocks-flow-modal',
        })
        return modalInstance
      },
    }
  },
])
