/* global angular */
angular.module('bzProjectFormModule').component('screenHeader', {
  bindings: {
    project: '<',
    headerText: '@',
    showHeaderIcon: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/screen_header'
      )
    },
  ],
  controller: [
    '$sce',
    function ($sce) {
      this.headerTextHtml = function () {
        return $sce.trustAsHtml(this.headerText)
      }
    },
  ],
})
