/* global bzGlobal, changeURL, google */
/**	G6 TODO: propertiesService provides permit utils functionality	*/
/* eslint-disable */
bz_app.factory('propertiesService', [
  '$rootScope',
  '$q',
  'buildingPermitModel',
  'markerService',
  function ($rootScope, $q, buildingPermitModel, markerService) {
    //updates (1) browser url, (2) API url
    var setParam = function (paramKey, paramValue) {
      if (paramValue) {
        changeURL(paramKey, paramValue)
        if (paramKey != 'lat' && paramKey != 'lng') {
          propertiesService.json_filters[paramKey] = paramValue
        }
        //note: may need to update propertiesService attributes
        //if no paramValue, remove param
      } else {
        resetParam(paramKey)
      }
    }

    var resetParam = function (paramKey) {
      removeElementURL(paramKey)
      //remove key-value pair from query string passed to BZ API
      delete propertiesService.json_filters[paramKey]
    }

    var resetParams = function (paramKeys) {
      for (var i = 0; i < paramKeys.length; i++) {
        resetParam(paramKeys[i])
      }
    }

    var setFullAddressParam = function (address) {
      var fullAddressValue = address.alias.replace(/-usa$/, '')
      propertiesService.setParam('full_address', fullAddressValue)
    }

    var setURLForExactAddress = function (address) {
      //complete street address
      if (!!address.street_address) {
        setFullAddressParam(address)
        if (address.was_searched) {
          propertiesService.setParam('sort', 'proximity')
        } else {
          propertiesService.resetParams(['sort'])
        }
        propertiesService.setLatLngParams(address.latitude, address.longitude)
        //don't pass below params if not complete street address search
      } else {
        propertiesService.resetParams(['full_address', 'sort', 'lat', 'lng'])
      }
    }

    var loadParams = function () {
      //var queryParams = window.location.search;
      var params = [
        'full_address',
        'sort',
        'lat',
        'lng',
        'zoom',
        'job_value_min',
        'job_value_max',
        'date_from',
      ]
      for (var i = 0; i < params.length; i++) {
        if (getURLParameter(params[i])) {
          propertiesService.json_filters[params[i]] = getURLParameter(params[i])
        }
      }
    }

    var propertiesService = {
      /*
		 Note:
		 A permit can only have one pin while a
		 pin can have many permits under it
		 */
      active_pin: '',
      addresses: [],
      clusterPermitPins: [],
      clusterPins: [],
      clusterStyles: [
        {
          height: 20,
          width: 20,
          url: bzGlobal.urlConstants.s3.assetUrl + '_map_icons/circle-20.png',
        },
        {
          height: 30,
          width: 30,
          url: bzGlobal.urlConstants.s3.assetUrl + '_map_icons/circle-30.png',
        },
        {
          height: 50,
          width: 50,
          url: bzGlobal.urlConstants.s3.assetUrl + '_map_icons/circle-50.png',
        },
        {
          height: 75,
          width: 75,
          url: bzGlobal.urlConstants.s3.assetUrl + '_map_icons/circle-75.png',
        },
      ],
      dateFrom: '',
      dateTo: '',
      json_filters: {
        page_size: bzGlobal.applicationConstants.permitMapResults,
        //development env
        // "page_size": 10
      },
      markerClusterers: [],
      model: buildingPermitModel,
      pins: [],
      preselected_pin: '',
      price: '',
      recentBatch: [],
      types: [],
      zoom: '',
      latitude: '',
      longitude: '',
      full_address: '',
      propertyId: 0,
      pinCursor: null,
      /* To keep track of the added pins' addresses */
      addAddress: function (full_address) {
        var normalized = PropertiesHelper.parameterizeAddress(full_address)
        var address_index = propertiesService.addresses.indexOf(normalized)
        if (address_index < 0) {
          propertiesService.addresses.push(normalized)
          return true
        }
        return address_index
      },
      /* Activates a pin so that the info popup will show */
      activatePin: function (pin) {
        $rootScope.$broadcast('properties.activatePinSuccess', pin)
      },
      addNationwideClusters: function (map) {
        //var filters = propertiesService.json_filters;
        propertiesService.model.load_national_clusters().then(function (json) {
          if (json.pins) {
            for (var i = 0; i < json.pins.length; i++) {
              propertiesService.clusterPermitPins.push(json.pins[i])
            }
          }
          if (json.clusters) {
            for (var y = 0; y < json.clusters.length; y++) {
              var cluster = json.clusters[y]
              propertiesService.clusterPins.push(
                new google.maps.Marker({
                  visible: false,
                  position: new google.maps.LatLng(
                    cluster.latitude,
                    cluster.longitude
                  ),
                  map: map,
                  total_permits: cluster.total,
                  name: cluster.region_name ? cluster.region_name : '',
                  title: cluster.city + ', ' + cluster.state,
                  city: cluster.city,
                  state: cluster.state,
                  zoom: cluster.zoom,
                  link: URLify(
                    cluster.city + ' ' + cluster.state
                  ).toLowerCase(),
                })
              )
            }
          }

          var marker_cluster = new MarkerClusterer(
            map,
            propertiesService.clusterPins,
            {
              imagePath:
                '//cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m',
              minimumClusterSize: 1,
              clusterClass: 'map-node',
              gridSize: 10,
              maxZoom: 4,
              styles: propertiesService.clusterStyles,
              calculator: propertiesService.clusterCalculator,
              zoomOnClick: false,
            }
          )
          google.maps.event.addListener(
            marker_cluster,
            'click',
            function (cluster) {
              $rootScope.$broadcast('properties.clusterClicked', cluster)
            }
          )
          propertiesService.markerClusterers.push(marker_cluster)
          $rootScope.$broadcast(
            'properties.nationwideClustersLoaded',
            propertiesService.clusterPermitPins
          )
        })
      },
      /* Adds a permit to the permits array */
      addProperty: function (
        property,
        map,
        preloadMapElements,
        includeEventListeners
      ) {
        if (property.street) {
          var latLng = new google.maps.LatLng(
            parseFloat(property.latitude),
            parseFloat(property.longitude)
          )
          var icon_to_use = property.icon
          if (this.propertyId || property.was_searched) {
            if (property.id == this.propertyId || property.was_searched) {
              icon_to_use = bzGlobal.map.marker.circle.orange
            } else {
              icon_to_use = bzGlobal.map.marker.circle.blue
            }
          }

          var new_address = propertiesService.addAddress(property.street)
          if (new_address === true) {
            propertiesService.recentBatch.push(
              propertiesService.newPin(
                {
                  position: latLng,
                  map: map,
                  icon: icon_to_use,
                  permits: property.permits,
                  property: property,
                  title: property.street.titleCase(),
                },
                includeEventListeners
              )
            )
            propertiesService.addPin(
              propertiesService.recentBatch[
                propertiesService.recentBatch.length - 1
              ]
            )
          } else {
            propertiesService.addPermitToPin(property.permits, new_address)
          }
        }
      },
      addNotFoundMarker: function (propertySearch, map) {
        var notFoundMarker = propertiesService.newPin(
          {
            position: new google.maps.LatLng(
              propertySearch.latitude,
              propertySearch.longitude
            ),
            icon: bzGlobal.map.marker.circle.notFound,
            map: map,
          },
          true
        )
        notFoundMarker.ignore = true
        propertiesService.addPin(notFoundMarker)
        return notFoundMarker
      },
      /* Attach a permit to an existing pin */
      addPermitToPin: function (permits, pin_index) {
        if (typeof propertiesService.pins[pin_index].permits != 'undefined') {
          propertiesService.pins[pin_index].permits.concat(permits)
        }
        propertiesService.recentBatch.push(propertiesService.pins[pin_index])
        $rootScope.$broadcast('properties.permit_appended_to_pin')
      },
      addPin: function (pin) {
        propertiesService.pins.push(pin)
        if (typeof pin.property !== 'undefined' && pin.property.was_searched) {
          $rootScope.$broadcast('zoom-property', pin.property, true)
        }
      },
      /* Add unique property pins */
      addPinsToMap: function (
        properties,
        map,
        preloadMapElements,
        includeEventListeners
      ) {
        for (var i = 0; i < properties.length; i++) {
          propertiesService.addProperty(
            properties[i],
            map,
            preloadMapElements,
            includeEventListeners
          )
        }
      },
      /* Add unique property pins */
      addPinsToMapFromInput: function (
        map,
        icon,
        properties,
        preloadMapElements,
        includeEventListeners,
        callbackEventData
      ) {
        if (properties) {
          var icon_to_use = icon
          for (var i = 0; i < properties.length; i++) {
            var latLng = new google.maps.LatLng(
              parseFloat(properties[i].latitude),
              parseFloat(properties[i].longitude)
            )

            // Blue pin for explore
            if (
              typeof preloadMapElements['explore_coordinates'] != 'undefined'
            ) {
              icon_to_use = preloadMapElements['explore_coordinates'].equals(
                latLng.position
              )
                ? bzGlobal.applicationConstants.mapPinBlue
                : icon
            }

            if (
              typeof preloadMapElements['activate_pin_location'] != 'undefined'
            ) {
              if (preloadMapElements['activate_pin_location'].equals(latLng)) {
                propertiesService.active_pin =
                  propertiesService.pins[propertiesService.pins.length - 1]
              }
            }
            if (typeof properties[i].icon == 'undefined') {
              properties[i].icon = icon_to_use
            }
          }
          propertiesService.addPinsToMap(
            properties,
            map,
            preloadMapElements,
            includeEventListeners
          )
          propertiesService.recentBatch = propertiesService.pins
          $rootScope.$broadcast(
            'properties.allPinsFromInputLoaded',
            callbackEventData
          )
        }
      },
      /* Add pins to map based on the retrieved list of properties */
      addPinsToMapFromAPI: function (
        map,
        icon,
        preloadMapElements,
        includeEventListeners
      ) {
        propertiesService.model
          .load_properties(
            map.getBounds(),
            $.param(propertiesService.json_filters)
          )
          .then(function (results, statusText, args) {
            propertiesService.recentBatch = []
            if (results && results.length) {
              for (var i = 0; i < results.length; i++) {
                // Need to pass through addProperty instead of addPin so we can filter out duplicate properties
                results[i].icon = icon
              }
              propertiesService.addPinsToMap(
                results,
                map,
                preloadMapElements,
                includeEventListeners
              )
              $rootScope.$broadcast('properties.allPinsFromAPILoaded', results)
            } else {
              $rootScope.$broadcast('properties.noPinsFromAPILoaded')
            }
          })
      },
      /* Reset a map */
      clearMap: function (map) {
        for (var x = 0; x < propertiesService.clusterPins.length; x++) {
          propertiesService.clusterPins[x].setMap(null)
        }
        for (var y = 0; y < propertiesService.markerClusterers.length; y++) {
          propertiesService.markerClusterers[y].setMap(null)
        }
        for (var i = 0; i < propertiesService.pins.length; i++) {
          propertiesService.pins[i].setMap(null)
        }
        propertiesService.clearAttributes()
        $rootScope.$broadcast('pins.removed')
      },
      clearAttributes: function () {
        propertiesService.pins = []
        propertiesService.addresses = []
        propertiesService.clusterPins = []
        propertiesService.markerClusterers = []
      },
      /* Calculator for clusters */
      clusterCalculator: function (markers, numStyles) {
        return {
          text: '+',
          index: 1,
          title: propertiesService.clusterTitle(markers),
        }
      },
      clusterTitle: function (cluster_markers) {
        var cluster_cities = []
        var total_permits = 0
        if (cluster_markers.length > 1) {
          for (var i = 0; i < cluster_markers.length; i++) {
            var cluster_marker = cluster_markers[i]
            var city = cluster_marker.name
              ? cluster_marker.name
              : cluster_marker.city
            if (cluster_cities.indexOf(city) < 0) {
              cluster_cities.push(city)
            }
            total_permits += cluster_marker.total_permits
          }
          if (cluster_cities.length > 1) {
            cluster_cities[cluster_cities.length - 1] =
              'and ' + cluster_cities[cluster_cities.length - 1]
          }
        } else {
          // There is only one city represented in the cluster
          if (cluster_markers[0].name) {
            cluster_cities = ['the ' + cluster_markers[0].name + ' region']
          } else {
            cluster_cities = [
              'the city of ' +
                cluster_markers[0].city +
                ', ' +
                cluster_markers[0].state,
            ]
          }
          total_permits = cluster_markers[0].total_permits
        }
        return (
          'We have over ' +
          total_permits.formatMoney(0) +
          ' permits in ' +
          cluster_cities.join(', ') +
          '. Click to see them.'
        )
      },

      getLogo: function (property) {},

      /* Retrieve pin */
      getPin: function (index) {
        return propertiesService.pins[index]
      },
      getPinByAddress: function (addr) {
        var normalized = PropertiesHelper.parameterizeAddress(addr)
        var address_index = propertiesService.addresses.indexOf(normalized)
        if (address_index >= 0) {
          return propertiesService.getPin(address_index)
        }
        return address_index
      },
      newPin: function (a, includeEventListeners) {
        var pin = new google.maps.Marker(a)

        // set cursor style for pins if any provided
        if (this.pinCursor) pin.setCursor(this.pinCursor)
        if (includeEventListeners) {
          markerService.registerEventListeners(pin)
        }
        return pin
      },
      setCity: function (c) {
        propertiesService.json_filters['city'] = c
      },
      /* Filter setters (setMethod) below */
      setDateFrom: function (s) {
        propertiesService.json_filters['date_from'] =
          propertiesService.dateFrom = s
        // $rootScope.$broadcast('dateFrom.set');
      },
      setDateTo: function (s) {
        propertiesService.json_filters['date_to'] = propertiesService.dateTo = s
        // $rootScope.$broadcast('dateTo.set');
      },
      setPrice: function (s) {
        propertiesService.json_filters['price'] = propertiesService.price = s
        // $rootScope.$broadcast('price.set');
      },
      setServiceType: function (s) {
        if (s) {
          propertiesService.types = [s]
        } else {
          propertiesService.types = []
        }
        propertiesService.json_filters['types'] = propertiesService.types
        // $rootScope.$broadcast('service_types.set');
      },
      setPermitAttribute: function (permitAttribute) {
        if (permitAttribute.id) {
          propertiesService.json_filters['permit_attribute'] = permitAttribute
        }
      },
      setState: function (s) {
        propertiesService.json_filters['state'] = s
      },
      setZoom: function (s) {
        propertiesService.json_filters['zoom'] = propertiesService.zoom = s
      },
      setLatitude: function (lat) {
        propertiesService.json_filters['lat'] = propertiesService.latitude = lat
      },
      setLongitude: function (lng) {
        propertiesService.json_filters['lng'] = propertiesService.longitude =
          lng
      },
      setFullAddress: function (address) {
        propertiesService.json_filters['full_address'] =
          propertiesService.full_address = address
      },
      setPageSize: function (pageSize) {
        propertiesService.json_filters['page_size'] =
          propertiesService.page_size = pageSize
      },
      setDateSort: function () {
        propertiesService.json_filters['sort'] = propertiesService.sort =
          'effective_date'
      },
      setPropertyId: function (propertyId) {
        this.propertyId = propertyId
      },
      setPinCursor: function (cursor_style) {
        this.pinCursor = cursor_style
      },
      setRequireContractor: function (require) {
        propertiesService.json_filters['require_contractor'] = require ? 1 : 0
      },
      setLocationId: function (locationId) {
        propertiesService.json_filters['location_id'] = locationId
      },
      updatePrice: function (minimum, maximum) {
        minimum = typeof minimum !== 'undefined' ? minimum : 0
        propertiesService.json_filters['price'] = propertiesService.price =
          minimum
        $rootScope.$broadcast('price.update')
      },
      updateServiceType: function (s) {
        if (s) {
          propertiesService.types = [s]
        } else {
          propertiesService.types = []
        }
        propertiesService.json_filters['types'] = propertiesService.types
        $rootScope.$broadcast('service_types.update')
      },
      /* Looks for the center of an address */
      geocode: function (address, city, state) {
        var query = [address]
        if (typeof city != 'undefined' && address.indexOf(city) < 0) {
          query.push(city)
        }
        if (typeof state != 'undefined' && address.indexOf(state) < 0) {
          query.push(state)
        }

        var deferred = $q.defer()
        var geocoder = new google.maps.Geocoder()

        geocoder.geocode(
          { address: query.join(', ') },
          function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              deferred.resolve(results)
            } else {
              deferred.reject(status)
            }
          }
        )

        return deferred.promise
      },
      setURLForExactAddress: function (address) {
        setURLForExactAddress(address)
      },
      /* Retrieve X most recent projects(properties with permits) */
      getRecentProjects: function (limit) {
        var projects = []
        var pins = propertiesService.recentBatch.slice(0, limit)
        for (var i = 0; i < pins.length; i++) projects.push(pins[i].property)
        return projects
      },
      setParam: function (paramKey, paramValue) {
        setParam(paramKey, paramValue)
      },
      resetParam: function (paramKey) {
        resetParam(paramKey)
      },
      resetParams: function (paramKeys) {
        resetParams(paramKeys)
      },
      loadParams: function () {
        loadParams()
      },
      setLatLngParams: function (lat, lng) {
        propertiesService.setParam('lat', lat)
        propertiesService.setParam('lng', lng)
      },
      removeCityPath: function () {
        if (location.pathname.split('/')[2] !== '') {
          replaceURL(location.pathname.split('/')[2], '')
        }
      },
    }

    return propertiesService
  },
])
