/* global angular */
angular.module('bzCommonUtilitiesModule').filter('currency_short', function () {
  return function (text, prefix, precision) {
    var abbreviateNumber = function (number) {
      if (!precision) {
        precision = 0
      }

      if (number !== undefined) {
        var abs = Math.abs(number)

        if (abs >= Math.pow(10, 12)) {
          number = (number / Math.pow(10, 12)).toFixed(precision) + 'T'
        } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
          number = (number / Math.pow(10, 9)).toFixed(precision) + 'B'
        } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
          number = (number / Math.pow(10, 6)).toFixed(precision) + 'M'
        } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
          number = (number / Math.pow(10, 3)).toFixed(precision) + 'K'
        }
      }
      return number
    }

    if (!prefix) {
      prefix = '$'
    } else if (prefix === '_') {
      prefix = ''
    }

    return prefix + abbreviateNumber(text)
  }
})

angular.module('bzCommonUtilitiesModule').filter('currency_short_range', [
  '$filter',
  function ($filter) {
    return function (text, prefix, precision) {
      var result = ''
      if (text) {
        var splitValues = text.split('-')

        if (splitValues.length === 2) {
          var start = parseInt(splitValues[0])
          var end = parseInt(splitValues[1])

          if (isNaN(start) || isNaN(end)) {
            result = text
          } else {
            if (!prefix) {
              prefix = '$'
            } else if (prefix === '_') {
              prefix = ''
            }

            if (!precision) {
              precision = 0
            }

            if (start === 0) {
              result = '<' + $filter('currency_short')(end, prefix, precision)
            } else if (end > 1000000) {
              result =
                $filter('currency_short')(1000000, prefix, precision) + '+'
            } else {
              result =
                $filter('currency_short')(start, prefix, precision) +
                '-' +
                $filter('currency_short')(end, prefix, precision)
            }
          }
        } else {
          if (text === 'private') {
            text = 'TBD'
          }
          result = text
        }
      } else {
        result = text
      }

      return result
    }
  },
])
