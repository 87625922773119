/* global angular */
angular
  .module('bzProjectModule')
  .factory('postponeDurationsModel', function () {
    return {
      getAll: function () {
        return [
          { value: '1', text: '1 month' },
          { value: '2', text: '2 months' },
          { value: '3', text: '3 months' },
          { value: '6', text: '6 months' },
          { value: '12', text: '12 months' },
        ]
      },
    }
  })
