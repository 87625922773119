import 'AngularBase/_components/bookmark'
/* global $, ProjectAPI, Cookie, ServiceRequestsTrack */
var ContractorBookmarkModal = function () {
  this.projectApi = new ProjectAPI()
  this.projectData = $('#project_data')
  this.contractor_id = $('.profile-info').data('contractor_id')
  this.modalId = 'contractor_invite_modal'

  this.show = function () {
    var that = this

    if ($('#' + this.modalId).length === 0) {
      $('body').append(
        '<div class="modal modal-modern fade" id="' +
          this.modalId +
          '" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">'
      )
    }

    $('#' + this.modalId).load(
      '/contractor/' + this.contractor_id + '/invite',
      function () {
        $('#' + that.modalId).modal('show')
        that.init_invite_select()
        that.init_invite_confirmation()
      }
    )

    return this
  }

  this.init_invite_select = function () {
    // user has multiple service requests
    if ($('#modal-invite-sr-list').length !== 0) {
      var that = this

      // INITIALIZE LOGIC FOR FIRST DISPLAYED SR
      var current_service_request_id = Cookie.get_current_service_request_id()
      var first_service_request = $('#modal-invite-sr-list ul li a').first()

      // current SR is set in cookie
      if (
        current_service_request_id !== undefined &&
        current_service_request_id !== null &&
        current_service_request_id > 0
      ) {
        var current_service_request = $(
          "#modal-invite-sr-list ul li a[data-service_request_id='" +
            current_service_request_id +
            "']"
        )

        // found current SR in list displayed
        if (current_service_request.length !== 0) {
          this.set_invite_select_option(
            current_service_request.data('project_id'),
            current_service_request_id,
            current_service_request.text()
          )
        }
        // no current SR, set 1st in list as current
        else {
          this.set_invite_select_option(
            first_service_request.data('project_id'),
            first_service_request.data('service_request_id'),
            first_service_request.text()
          )
        }
      } else {
        this.set_invite_select_option(
          first_service_request.data('project_id'),
          first_service_request.data('service_request_id'),
          first_service_request.text()
        )
      }

      // INITIALIZE LOGIC FOR CHANGING SR DISPLAYED
      $('#modal-invite-sr-list ul li a').on('click', function (e) {
        that.set_invite_select_option(
          $(this).data('project_id'),
          $(this).data('service_request_id'),
          $(this).text()
        )
        e.preventDefault()
      })
    } else {
      this.check_contractor_range(
        $('#modal-invite-selected').data('project_id')
      )
    }
  }

  this.init_invite_confirmation = function () {
    var bookmark = $('.contractor_bookmark')
    var that = this
    $('#modal-invite-submit').on('click', function (e) {
      var project_id = $('#modal-invite-selected').data('project_id')

      // update current project id values on the page
      that.projectData.attr('data-project_id', project_id)
      Cookie.set_current_project_id(project_id)
      Cookie.set_current_service_request_id(
        $('#modal-invite-selected').data('service_request_id')
      )

      that.projectApi
        .create_contractor_bookmark(project_id, [
          { contractor_id: that.contractor_id },
        ])
        .then(
          function (res) {
            bookmark.addClass('selected')
            that.hide_error()

            ServiceRequestsTrack.bookmark(
              'invite contractor',
              project_id,
              that.contractor_id
            )
            $('body').trigger('contractor_profile_bookmarked')
            $('body').trigger('contractor_profile_init_toggle_invite_text', {
              bookmark: bookmark,
            })

            // load confirmation modal content
            $('#' + that.modalId).load(
              '/contractor/' + that.contractor_id + '/invite_confirmation',
              function () {
                // listen for click to view current SR button
                $('#modal-view-my-project').on('click', function (e) {
                  document.location =
                    '/project/' + Cookie.get_current_service_request_id()
                  e.preventDefault()
                })
              }
            )
          },
          // error case
          function (res) {
            if (res && res.responseText) {
              var response = JSON.parse(res.responseText)
              that.show_error(response.message)
            }
          }
        )

      e.preventDefault()
    })
  }

  /**	sets currently selected SR in INVITE dropdown	*/
  this.set_invite_select_option = function (
    project_id,
    service_request_id,
    text
  ) {
    this.check_contractor_range(project_id)
    $('#modal-invite-selected').text(text)
    $('#modal-invite-selected').data('service_request_id', service_request_id)
    $('#modal-invite-selected').data('project_id', project_id)
  }

  this.check_contractor_range = function (project_id) {
    if (project_id) {
      this.hide_subtext()
      this.projectApi
        .check_contractor_range(project_id, this.contractor_id)
        .then(
          function (res) {
            this.hide_error()
            this.show_subtext()
          }.bind(this),
          function (res) {
            var response = JSON.parse(res.responseText)
            this.show_error(response.message)
            this.hide_subtext()
          }.bind(this)
        )
    }
  }

  this.show_error = function (message) {
    var inviteErrorMessage = $('#' + this.modalId).find('.invite-error-message')

    if (inviteErrorMessage.length) {
      inviteErrorMessage.find('.invite-error-message-text').text(message)
    } else {
      this.inject_error_row(message)
    }
  }

  this.hide_error = function () {
    $('#' + this.modalId)
      .find('.invite-error-message')
      .remove()
  }

  this.inject_error_row = function (message) {
    $('#' + this.modalId)
      .find('.modal-footer .container')
      .prepend(
        '<div class="row invite-error-message"><div class="col-xs-12"><div class="alert alert-danger text-center invite-error-message-text">' +
          message +
          '</div></div></div>'
      )
  }

  this.hide_subtext = function () {
    $('#modal-invite-submit, #modal-body-subtext').addClass('hidden')
  }

  this.show_subtext = function () {
    $('#modal-invite-submit, #modal-body-subtext').removeClass('hidden')
  }
}

window.ContractorBookmarkModal = ContractorBookmarkModal
