/* global $, angular, isMobile, bzGlobal, ServiceRequestLink */
import { PROJECT_CONTROL_FORM_VERSION } from 'Vanilla/constants/project_form'

let initNewServiceRequestLink = function (projectLink) {
  $(projectLink + ':not(".signup-modal")').each(function () {
    $(this).addClass('signup-modal')
    $(this).attr('target-href', $(this).attr('href'))
    $(this).attr('href', 'javascript:void(0)')

    new ServiceRequestLink().init({ dom: $(this) })
  })
}

window.ServiceRequestLink = function () {
  this.dom = ''

  this.init = function (options) {
    if (typeof options !== 'undefined' && typeof options.dom !== 'undefined') {
      this.dom = options.dom
    }

    if (this.dom) {
      $(this.dom).click(this.clicked)
    }

    return this
  }

  this.clicked = (event, data) => {
    event.preventDefault()
    event.stopPropagation()

    /*	Redirect to full-page form when:
     *	1. mobile phone devices
     *	2. browser view port smaller than modal is visible
     *	3. loading from dashboard layout (conflict w/legacy FilePicker for user thumbnail upload)
     */
    let isMobileScreen = isMobile.check({ phoneOnly: true })
    let isSmallDesktopScreen =
      !isMobileScreen && $(window) && $(window).height() < 575
    let isDashboard = !!$('.container-fluid.dashboard').length

    if (isMobileScreen || isSmallDesktopScreen || isDashboard) {
      this.redirectToProjectForm(data)
    } else {
      this.loadProjectModal(data, this.setFormVersion())
    }

    return false
  }

  this.redirectToProjectForm = function (data) {
    document.location =
      '/project/new?' + this.encodedKeyValuesFromData(data) + this.variation()
  }

  this.encodedKeyValuesFromData = function (data) {
    let queryString = ''
    let referralSource = this.getValueFromData(data, 'referral_source')
    let title = this.getValueFromData(data, 'title')
    let urgency = this.getValueFromData(data, 'urgency')
    let zipcode = this.getValueFromData(data, 'zipcode')
    let email = this.getValueFromData(data, 'email')
    let fullName = this.getValueFromData(data, 'fullName')
    let phone = this.getValueFromData(data, 'phone')
    let potentialLeadFormVersion = this.getValueFromData(
      data,
      'potentialLeadFormVersion'
    )
    let potentialLeadContractorId = this.getValueFromData(
      data,
      'potentialLeadContractorId'
    )
    let contractorId = this.getContractorId(data, 'contractor_id')

    if (referralSource) {
      queryString += '&referral_source=' + encodeURIComponent(referralSource)
    }

    if (title) {
      queryString += '&s_request=' + encodeURIComponent(title)
    }

    if (urgency) {
      queryString += '&urgency=' + encodeURIComponent(urgency)
    }

    if (zipcode) {
      queryString += '&zipcode=' + encodeURIComponent(zipcode)
    }

    if (email) {
      queryString += '&email=' + encodeURIComponent(email)
    }

    if (fullName) {
      queryString += '&full_name=' + encodeURIComponent(fullName)
    }

    if (phone) {
      queryString += '&phone=' + encodeURIComponent(phone)
    }

    if (potentialLeadFormVersion) {
      queryString +=
        '&pl_form_version=' + encodeURIComponent(potentialLeadFormVersion)
    }

    if (potentialLeadContractorId) {
      queryString += '&pl_c_id=' + encodeURIComponent(potentialLeadContractorId)
    } else if (contractorId) {
      queryString += '&pl_c_id=' + encodeURIComponent(contractorId)
    }

    queryString += '&reftag=' + encodeURIComponent(document.location.href)

    return queryString
  }

  this.loadProjectModal = function (data, formVersion) {
    if ($('#project-form-trigger-container').length) {
      $('#project-form-trigger-container').remove()
    }

    angular
      .element(document.body)
      .injector()
      .invoke([
        '$compile',
        '$rootScope',
        ($compile, $rootScope) => {
          $('body').append(
            $compile(
              '<div id="project-form-trigger-container" project-form-trigger title="' +
                encodeURIComponent(this.getValueFromData(data, 'title')) +
                '" form-version="' +
                formVersion +
                '" referral-source="' +
                this.getValueFromData(data, 'referral_source') +
                '"  contractor-id="' +
                this.getValueFromData(data, 'contractor_id') +
                '"></div>'
            )($rootScope)
          )
        },
      ])

    $('#project-form-trigger-container').click()
  }

  this.setFormVersion = function () {
    // let isMobileScreen = isMobile.check({ phoneOnly: true })

    let formVersion

    let formVersions = [PROJECT_CONTROL_FORM_VERSION]

    if ($.cookie('sr_form_version') !== undefined) {
      formVersion = parseFloat($.cookie('sr_form_version'))

      if (formVersions.indexOf(formVersion) === -1) {
        formVersion =
          formVersions[Math.floor(Math.random() * formVersions.length)]
        $.cookie('sr_form_version', formVersion, { expires: 1, path: '/' })
      }
    } else {
      formVersion =
        formVersions[Math.floor(Math.random() * formVersions.length)]
      $.cookie('sr_form_version', formVersion, { expires: 1, path: '/' })
    }
    return formVersion
  }

  this.getValueFromData = function (data, key) {
    return data && data[key]
      ? data[key]
      : this.dom.data(key.replace('_', '-')) || ''
  }

  this.variation = function () {
    let variation = (bzGlobal && bzGlobal.variation) || ''
    return variation && variation.length ? '&variation=' + variation : ''
  }

  this.getContractorId = function (data, key) {
    if (window.location.pathname.indexOf('/contractor/') === 0) {
      let co_nid = $('#contractor-header').data('contractor_id')
      if (co_nid) {
        return co_nid
      }
    }
    return this.getValueFromData(data, key)
  }
}

$(function () {
  if (document.readyState === 'complete') {
    initNewServiceRequestLink('[href$="project/new"]') //   hijack service request links and show modal
  } else if (window.addEventListener) {
    window.addEventListener(
      'load',
      function () {
        initNewServiceRequestLink('[href$="project/new"]') //   hijack service request links and show modal
      },
      false
    )
  } else if (window.attachEvent) {
    window.attachEvent('onload', function () {
      initNewServiceRequestLink('[href$="project/new"]') //   hijack service request links and show modal
    })
  }
})
