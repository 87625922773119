import BzGlobalAlertManager from 'BundledModules/global_alert_manager/global_alert_manager_module.js'
import BzHttpModule from 'StandaloneServices/bz_http_module.js'

window.defaultAngularModuleSet = [
  'bzProjectFormModule',
  'bzVarnishModule',
  'bzLocalStorageModule',
  'bzNavbarModule',
  'bzConstantsModule',
  'bzSearchModule',
  'bzCommonUtilitiesModule',
  'bzAnalyticsModule',
  'bzProjectModule',
  'bzUserModule',
  'bzClaimModalModule',
  'bzValidationModule',
  'bzRegistrationModule',
  'bzSessionModule',
  'bzChartModule',
  'bzContractorModule',
  'bzMarketingModule',
  'bzScoreModule',
  'ngSanitize',
  'ui.select',
  'apiModule',
  'bzMessageBannerModule',
  BzGlobalAlertManager,
  BzHttpModule,
]
