/* global bz_app, angular*/
angular.module('bzBidModule').component('bidAdminDelete', {
  bindings: {
    lead: '<',
    onCancel: '&',
    onClose: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('leads/unlocked_lead/bid_admin_delete')
    },
  ],
  controller: [
    'bidInstance',
    '$timeout',
    function (bidInstance, $timeout) {
      this.$onInit = () => {
        this.bid = new bidInstance()
        this.bid.set(this.lead.bid)
        this.reason
        this.showConfirmation = false
      }

      this.deleteBid = () => {
        this.bid.destroy(this.reason).then((res) => {
          if (res.success) {
            this.lead.bid = res.bid
            this.showConfirmation = true
          }
          $timeout(() => this.close(), 1500)
        })
      }

      this.close = () => this.onClose()

      this.cancel = ($event) => {
        this.onCancel()

        if ($event) {
          $event.preventDefault()
        }
      }

      this.projectOwnerName = () => {
        const { projectOwner } = this.lead.project
        return projectOwner && projectOwner.fullName
          ? projectOwner.fullName
          : 'the project owner'
      }
    },
  ],
})
