export default [
  function () {
    return {
      triggerInViewport: function (selector, callback, options) {
        let initialized = false

        let onScroll = () => {
          let mapInitialized = true

          if (options && options.checkForMap) {
            mapInitialized =
              typeof google !== 'undefined' &&
              typeof google.maps !== 'undefined'
          }

          if (!initialized && $(selector).isInViewport() && mapInitialized) {
            callback()
            initialized = true
            $(window).off('resize scroll', onScroll)
          }
        }

        if ($(selector).length) {
          $(window).on('resize scroll', onScroll)
          onScroll()
        }
      },
      scrollToBottom: function () {
        angular
          .element('html, body')
          .animate({ scrollTop: document.body.scrollHeight })
      },
    }
  },
]
