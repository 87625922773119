/* global BZAPI, $ */
var AdminLicenseAPI = function () {
  this.verify = function (review_id, verify) {
    var config = {
      id: review_id,
      verify: verify,
    }
    return $.when(
      $.ajax({
        url: this.admin_license_path() + 'verify',
        data: JSON.stringify(config),
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.saveNotes = function (licenseId, notes) {
    return $.when(
      $.ajax({
        url: this.admin_license_path() + licenseId + '/notes',
        type: 'POST',
        data: JSON.stringify({ notes: notes }),
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.markForReview = function (licenseId) {
    return $.when(
      $.ajax({
        url: this.admin_license_path() + 'review',
        type: 'POST',
        data: JSON.stringify({ licenseId: licenseId }),
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }
}

AdminLicenseAPI.prototype = new BZAPI()
AdminLicenseAPI.prototype.constructor = AdminLicenseAPI

window.AdminLicenseAPI = AdminLicenseAPI
