bz_app.directive('messageExpandLink', function () {
  return {
    restrict: 'AEC',
    replace: false,
    link: function () {
      /*element.click(function(e) {
					var container = element.closest('.message-content');

					if(container.hasClass('message-content-truncated')) {
						container.removeClass('message-content-truncated').addClass('message-content-expanded');
						element.text('collapse');
					}
					else if(container.hasClass('message-content-expanded')) {
						container.removeClass('message-content-expanded').addClass('message-content-truncated');
						element.text('expand');
					}
				});*/
    },
  }
})
