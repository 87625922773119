/* global angular, defaultAngularModuleSet */
import SubscriptionCheckboxesModule from 'StandaloneComponents/subscription_checkboxes_component'
import ButtonWithSpinnerModule from 'StandaloneComponents/button_with_spinner_module'

var requiredModules = [
  'ui.bootstrap',
  'bzProjectLabelsModule',
  'bzLocationModule',
  'bzMessageBannerModule',
  'bzMapModule',
  'bzReviewsModule',
  'bzProposalsModule',
  ButtonWithSpinnerModule,
  SubscriptionCheckboxesModule,
  ...defaultAngularModuleSet,
]

var bz_app = angular.module('bz_app', requiredModules)

angular.element(document).ready(function () {
  angular.bootstrap(document, ['bz_app'])
})

window.bz_app = bz_app
