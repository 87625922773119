import { omit } from 'lodash'
import template from './property_data_application_form.html'

const propertyDataApplicationForm = {
  bindings: {
    close: '&?',
    resolve: '<',
  },
  template,
  controller: [
    'dataApplicationModel',
    'trackDataApplications',
    'bzHttp',
    'emailPasswordFormService',
    'registrationModel',
    function (
      dataApplicationModel,
      trackDataApplications,
      bzHttp,
      emailPasswordFormService,
      registrationModel
    ) {
      this.$onInit = () => {
        Object.assign(this, this.resolve.inputData)

        if (this.isUserSignedIn) {
          this.header = 'Request a Premium Property Report'
          this.submitButtonText = 'Submit & Apply'
          this.subheader = `
						Thank you for your interest in ${this.streetAddress}’s full property report.
						Please fill out your contact info below and our team will be in touch shortly
						to help complete this request.
					`
        } else if (this.referralSource === 'internachi') {
          this.header = 'Get 30 property reports for free'
          this.submitButtonText = 'Create an account'

          this.subheader = `BuildZoom is pleased to offer you, a valued member of the InterNACHI inspector community,
						 a special complimentary plan for 30 property reports valid for 30 days. Please fill out your info below to
						 create an account and activate your free access.`
        } else if (this.referralSource === 'top-agent') {
          this.header = 'Get 30 property reports for free'
          this.submitButtonText = 'Create an account'

          this.subheader = `BuildZoom is pleased to offer you, a top real estate agent, a special complimentary plan for
							30 property reports valid for 30 days. Please fill out your info below to create an account and activate
							your free access.`
        } else {
          this.header = 'Get this property report for free'
          this.submitButtonText = 'Create an account'
          this.subheader = `
						Thank you for your interest in ${this.streetAddress}'s property history.
						Please fill out your contact info below and create an account to get access to 2 free reports.
					`
        }

        this.fieldsList = this._getFieldsList(this.isUserSignedIn)
        trackDataApplications.propertyApplicationFormOpened(this.propertyId)
      }

      this._getFieldsList = (isUserSignedIn) => {
        let fieldsList = []

        fieldsList.push({
          element: 'input',
          label: 'Name',
          modelName: 'name',
          placeholder: 'John Smith',
          type: 'text',
          required: true,
        })

        const emailField = {
          element: 'input',
          label: 'Email address ***we will send you an email to validate***',
          modelName: 'email',
          placeholder: 'example@gmail.com',
          type: 'email',
          required: true,
        }

        if (!isUserSignedIn) {
          emailField.getErrorMessage = (email) => {
            return emailPasswordFormService
              .validateEmail(email, false, false)
              .then(({ error }) => error)
          }

          fieldsList.push(emailField)
          fieldsList.push({
            element: 'input',
            label: 'Password',
            modelName: 'password',
            placeholder: 'password',
            type: 'password',
            required: true,
            getErrorMessage: (password) => {
              return emailPasswordFormService
                .validatePassword(password)
                .then(({ error }) => error)
            },
          })
        } else {
          fieldsList.push(emailField)
        }

        fieldsList = fieldsList.concat([
          {
            element: 'input',
            label: 'Company',
            modelName: 'company',
            placeholder: 'My Company Name',
            type: 'text',
            required: false,
          },
          {
            element: 'input',
            label: 'Phone number',
            modelName: 'phone',
            placeholder: '(000) 000-0000',
            type: 'tel',
            required: true,
          },
          {
            element: 'textarea',
            label:
              'Please give us a short description on how our data can be helpful for your business',
            modelName: 'description',
            placeholder: '',
            required: true,
          },
        ])

        return fieldsList
      }

      this.submit = (form) => {
        const sendEmail = dataApplicationModel.sendEmail(
          omit(form, 'password'),
          this.propertyId
        )

        if (!this.isUserSignedIn) {
          return registrationModel
            .createDataSubscriptionUser(
              form.email,
              form.password,
              form.name,
              form.phone,
              this.propertyId,
              this.referralSource
            )
            .then(({ redirectPath }) => {
              document.location = redirectPath
            })
        } else {
          return sendEmail
        }
      }
    },
  ],
}

export default propertyDataApplicationForm
