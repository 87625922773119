/* global angular */
import filestackClient from 'GlobalShared/clients/filestackClient'

angular.module('bzCommonUtilitiesModule').service('filestackService', [
  'applicationConstants',
  'messageBannerService',
  '$q',
  function (applicationConstants, messageBannerService, $q) {
    this.IMAGE_MIMETYPE_REGEX = /image\/.*/

    const checkUploadError = (file, options) => {
      let error
      if (options.mimetypeRegex && !options.mimetypeRegex.test(file.type)) {
        error = new Error(
          `Sorry, this upload does not accept "${file.type}" file types.`
        )
      }

      return error
    }

    this.pickAndUpload = (options) => {
      const defaultOptions = {
        maxSize: 31457280,
        location: 's3',
        path: '/uploads/user_content/',
        maxFiles: 15,
        minFiles: 1,
        accept: undefined,
      }

      options = Object.assign(defaultOptions, options)

      return filestackClient.picker({
        accept: options.accept,
        maxFiles: options.maxFiles,
        minFiles: options.minFiles,
        maxSize: options.maxSize,
        // fromSources: ['facebook','box'],
        // uploadInBackground: false,
        storeTo: {
          location: options.location,
          path: options.path,
        },
      })
    }

    this.uploadImage = (file, options) => {
      return this.upload(
        file,
        Object.assign({ mimetypeRegex: this.IMAGE_MIMETYPE_REGEX }, options)
      )
    }

    this.upload = (file, options) => {
      const defaultOptions = {
        location: 's3',
        path: '/uploads/filestack/',
        mimetypeRegex: undefined,
      }

      options = Object.assign(defaultOptions, options)
      const error = checkUploadError(file, options)

      if (error) {
        messageBannerService.show({
          message: error.message,
          displayDuration: 10 * 1000,
        })

        return $q((resolve, reject) => {
          reject('Client error')
        })
      }

      return filestackClient.upload(
        file,
        {},
        {
          location: options.location,
          path: options.path,
        }
      )
    }

    this.rotate = function (file, rotationDegrees, options = {}) {
      let rotatedImageUrl = filestackClient.transform(file.filepickerSlug, {
        rotate: { deg: rotationDegrees },
      })

      return filestackClient.storeURL(rotatedImageUrl, {
        location: options.location ? options.location : 's3',
        path: options.path ? options.path : '',
      })
    }
  },
])
