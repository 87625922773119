import template from './contractor_budget_screen.html'

const contractorBudgetScreen = {
  bindings: {
    lead: '<',
    onUpdate: '&',
  },
  template,
  controller: [
    'projectPreferencesModel',
    'contractorModel',
    '$filter',
    'trackDeclineLeadService',
    'declineLeadService',
    function (
      projectPreferencesModel,
      contractorModel,
      $filter,
      trackDeclineLeadService,
      declineLeadService
    ) {
      this.$onInit = function () {
        this.contractor = this.lead.contractor
        this._initialBudget = this.contractor.jobPreferences.minJobValue
      }

      this.update = function () {
        trackDeclineLeadService.saveBudget(this.lead, this.budgetChanged())

        this.submitting = true

        contractorModel
          .updateProfile(this.contractor.id, {
            extra_attributes: {
              min_job_value: this.contractor.jobPreferences.minJobValue,
              id: this.contractor.id,
            },
          })
          .then(
            function () {
              this.submitting = false
              this.onUpdate()
            }.bind(this)
          )
      }

      this.skip = function () {
        this.onUpdate()
      }

      this.budgetChanged = function () {
        return (
          this._initialBudget !== this.contractor.jobPreferences.minJobValue
        )
      }

      this.saveButtonText = function () {
        return declineLeadService.saveButtonText(this.submitting)
      }
    },
  ],
}

export default contractorBudgetScreen
