/* global angular*/
angular.module('bzProjectModule').factory('adminServiceRequestCommentsModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiAdminServiceRequestCommentsPath:
        urlConstants.api.v3.path + 'admin_service_request_comments',

      create: function (serviceRequestId, data) {
        return $http
          .post(
            this.apiAdminServiceRequestCommentsPath,
            this.defaultData(serviceRequestId, data)
          )
          .then(this.extractData)
      },

      delete: function (commentId) {
        return $http.delete(
          this.apiAdminServiceRequestCommentsPath + '/' + commentId
        )
      },

      update: function (commentId, data) {
        return $http.put(
          this.apiAdminServiceRequestCommentsPath + '/' + commentId,
          data
        )
      },

      defaultData: function (serviceRequestId, data) {
        return attributeConversionService.camelToUnderscoreDeepAttributes(
          angular.merge({ serviceRequestId: serviceRequestId }, data)
        )
      },

      extractData: function (response) {
        if (response.data) {
          return attributeConversionService.underscoreToCamelDeepAttributes(
            response.data
          )
        } else {
          return null
        }
      },
    }
  },
])
