/* global angular */
angular.module('bzProjectFormModule').component('propertyTypeScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/property_type'
      )
    },
  ],
  controller: [
    'projectFormScreenService',
    'trackProjectFormService',
    function (projectFormScreenService, trackProjectFormService) {
      this.$onInit = function () {
        this.contractorAttributes = [
          { label: 'Single Family Home', cssClass: 'home' },
          { label: 'Condo/Apartment', cssClass: 'condo' },
          { label: 'Office/Commercial', cssClass: 'office' },
          { label: 'Other', cssClass: 'other' },
        ]
      }

      this.selectPropertyType = function (propertyType) {
        this.project.summary.propertyType = propertyType.label
        trackProjectFormService.selectPropertyType(
          propertyType.label,
          this.project.analytics
        )
        this.showNextScreen()
      }

      this.showNextScreen = function () {
        if (projectFormScreenService.isCurrentScreen('property-type')) {
          projectFormScreenService.showNextScreen()
        }
      }
    },
  ],
})
