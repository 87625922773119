/**	class to access service request api	*/
var ServiceRequestAPI = function () {
  this.load = function (service_request_id, add_on) {
    return $.when(
      $.get(
        this.service_request_api_path() +
          '/' +
          service_request_id +
          '/info' +
          add_on
      )
    )
  }

  this.update = function (service_request_id, service_request_data) {
    // set blank pay_for_materials to NULL to store in DB
    if (service_request_data !== undefined) {
      if (
        service_request_data.pay_for_materials !== undefined &&
        service_request_data.pay_for_materials != null &&
        service_request_data.pay_for_materials.length == 0
      ) {
        service_request_data.pay_for_materials = null
      }

      if (
        service_request_data.urgency !== undefined &&
        service_request_data.urgency != null &&
        service_request_data.urgency.length == 0
      ) {
        service_request_data.urgency = null
      }

      if (
        service_request_data.project_stage !== undefined &&
        service_request_data.project_stage != null &&
        service_request_data.project_stage.length == 0
      ) {
        service_request_data.project_stage = null
      }
    }

    return $.when(
      $.ajax({
        url: this.service_request_api_path() + '/' + service_request_id,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          service_request: service_request_data,
          authenticity_token: $('[name="authenticity_token"]').val(),
        }),
        dataType: 'json',
      })
    )
  }

  this.open = function (service_request_id) {
    return $.when(
      $.get(
        this.service_request_api_path() + '/' + service_request_id + '/open'
      )
    )
  }

  this.close = function (service_request_id) {
    return $.when(
      $.get(
        this.service_request_api_path() + '/' + service_request_id + '/close'
      )
    )
  }

  this.open_leads_modal = function (data) {
    return $.when($.get('/api/v1/service_requests/open_leads_modal', data))
  }
}

ServiceRequestAPI.prototype = new BZAPI()
ServiceRequestAPI.prototype.constructor = ServiceRequestAPI

window.ServiceRequestAPI = ServiceRequestAPI
