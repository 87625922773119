/* global angular, UserData, isMobile, bzGlobal, bugsnagClient */
import { PROJECT_CONTROL_FORM_VERSION } from 'Vanilla/constants/project_form'

export default {
  bindings: {
    formData: '<',
    formVersion: '<',
    serviceRequestId: '=?',
    closeModal: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form'
      )
    },
  ],
  controller: [
    '$scope',
    '$timeout',
    '$attrs',
    'projectFormScreenService',
    'landingPageService',
    'trackProjectFormService',
    'utmService',
    'urlService',
    'projectFormService',
    '$compile',
    'landOwnershipModel',
    'designStatusModel',
    'propertyRelationshipsModel',
    'urgencyTypesModel',
    'formValidationService',
    function (
      $scope,
      $timeout,
      $attrs,
      projectFormScreenService,
      landingPageService,
      trackProjectFormService,
      utmService,
      urlService,
      projectFormService,
      $compile,
      landOwnershipModel,
      designStatusModel,
      propertyRelationshipsModel,
      urgencyTypesModel,
      formValidationService
    ) {
      this.$onInit = () => {
        this.screenService = projectFormScreenService
        this.landOwnershipOptions = landOwnershipModel.getForProjectForm()
        this.designStatusOptions = designStatusModel.getForProjectForm()
        this.urgencyTypesOptions = urgencyTypesModel.getInitial()

        this.setProject()
        this.setAnalytics()
        this.setInvitedContractors()
        this.setInitialScreen()

        this.initScreenChange()
        this.initTabScrollPrevent()

        trackProjectFormService.view(
          angular.extend(
            {
              title: this.project.summary.title,
            },
            this.project.analytics
          )
        )

        this.propertyRelationshipOptions =
          this.project.analytics.variation === 'nreia'
            ? propertyRelationshipsModel.getNreia()
            : propertyRelationshipsModel.getAll()

        $timeout(() => {
          if (this.project.summary.title && this.project.summary.title.length) {
            this.save(() => {
              this.setUserScreen()
            })
          }

          this.resetFormPosition()
        }, 1000)
      }

      this.setAnalytics = () => {
        if (this.project) {
          if (this.formVersion === undefined) {
            this.formVersion = PROJECT_CONTROL_FORM_VERSION
            bugsnagClient.notify(
              new Error('Undefined form version in project_multi_step_form')
            )
          }
          this.project.flowStatusAttributes = {
            formVersion: null,
            lastScreenViewed: null,
          }
          this.project.analytics = {
            formVersion: this.formVersion,
            lastScreenViewed: null,
            formType: this.formData.formType || 'modal',
            landingPage: landingPageService.getLandingPage(),
            referrer: landingPageService.getReferrer(),
            utmSource: utmService.getUtmSource(),
            utmMedium: utmService.getUtmMedium(),
            utmCampaign: utmService.getUtmCampaign(),
            utmTerm: utmService.getUtmTerm(),
            utmContent: utmService.getUtmContent(),
            referralSource: this.formData.referralSource,
            potentialLeadContractorId: this.formData.potentialLeadContractorId
              ? this.formData.potentialLeadContractorId
              : null,
            potentialLeadContractorBusinessName:
              this.formData.potentialLeadContractorBusinessName || null,
            potentialLeadFormVersion: this.formData.potentialLeadFormVersion,
            source: projectFormService.currentDevice(),
            reftag: this.refTag(),
            closeConfirmVersion: projectFormService.getCloseConfirmVersion({
              version: null,
            }),
            variation: bzGlobal.variation || null,
          }
        }
      }

      this.updateAnalyticsForProjectType = () => {
        if (this.project.projectType) {
          this.screenService.setScreensByVersion()
          this.updateScreenList()
        }
      }

      this.launchInformationLoadingScreen = () => {
        if ($('information-loading-screen').length === 0) {
          this.project.analytics.lastScreenViewed =
            this.screenService.farthestScreen

          if (this.project.expectedResponses === 1) {
            return projectFormService.onCloseRedirect(this.project)
          }
          const infoLoadingScreen = angular.element(`
						<information-loading-screen
							project="$ctrl.project">
						</information-loading-screen>
					`)
          $compile(infoLoadingScreen)($scope)
          $('body').prepend(infoLoadingScreen)
        }
      }

      this.isUserInfoPresent = function () {
        return !!(
          this.project.projectOwner.userId ||
          formValidationService.isValidEmail(this.project.projectOwner.email)
        )
      }

      this.isProjectCreated = function () {
        return !!this.project.id
      }

      this.initScreenChange = function () {
        $scope.$watch(
          '$ctrl.screenService.currentScreen',
          (newValue, oldValue) => {
            if (newValue === oldValue) {
              return
            }

            this.save()
            this.matchProjectType()
          }
        )
      }

      this.save = function (callback) {
        if (
          (this.isUserInfoPresent() || this.isProjectCreated()) &&
          this.project.init
        ) {
          callback = callback || function () {}
          this.project.analytics.lastScreenViewed =
            this.screenService.farthestScreen

          this.project
            .saveSummary()
            .then(() => {
              if (!this.serviceRequestId) {
                this.serviceRequestId = this.project.id
              }
            })
            .then(callback)
        }
      }

      this.matchProjectType = () => {
        if (this.screenService.isPrevScreen('title')) {
          this.project.matchProjectType().then((projectType) => {
            this.updateAnalyticsForProjectType()
          })
        }
      }

      this.setInitialScreen = function () {
        if (this.project.summary.title.length) {
          this.screenService.skippedFirstScreen = true
          trackProjectFormService.skipTitleScreen(this.project.analytics)

          if (this.project.summary.urgency.length) {
            this.screenService.setScreen('property-type')
          } else {
            this.screenService.setScreen('urgency')
          }

          if (
            this.project.analytics &&
            this.project.analytics.potentialLeadFormVersion
          ) {
            this.screenService.prevScreen = null
          }
        } else {
          this.screenService.setScreen('title')
        }

        this.updateScreenList()

        this.screenService.showIntro = this.isIntroScreenFormVersion()
      }

      this.updateScreenList = () => {
        this.setUserScreen()
        this.setCompetingBidsScreen()
        this.setDescriptionScreen()
        this.setExpectedResponsesScreen()
      }

      this.setUserScreen = function () {
        if (this.project.projectOwner.userId && UserData.user_signed_in()) {
          if (this.project.projectOwner.phone) {
            this.screenService.removeScreen('phone')
          }

          this.screenService.removeScreen('email')
          this.screenService.removeScreen('name')
        }
      }

      this.setDescriptionScreen = function () {
        if (
          this.project.summary.description &&
          this.project.summary.description.length
        ) {
          this.screenService.removeScreen('description')
        }
      }

      this.setExpectedResponsesScreen = function () {
        if (this.project.isExclusive()) {
          this.screenService.removeScreen('expected-responses')
        }
      }

      this.setCompetingBidsScreen = function () {
        if (
          this.project.analytics &&
          this.project.analytics.potentialLeadFormVersion
        ) {
          this.screenService.addScreenBefore(
            'competing-bids',
            'expected-responses'
          )
        }
      }

      this.setProject = function () {
        this.project = projectFormService.newProject(this.formData)
        this.screenService.project = this.project
      }

      this.setInvitedContractors = function () {
        if (
          this.formData.inviteSelectedContractors &&
          this.formData.invitedContractors
        ) {
          angular.forEach(
            this.formData.invitedContractors,
            function (isInvited, contractorId) {
              if (isInvited) {
                this.project.invitedContractors.push(contractorId)
              }
            }.bind(this)
          )
        }
      }

      this.onSwipeUp = function ($event) {
        if (this.screenService.isNextVisitedScreen()) {
          this.screenService.showNextScreen()
        }
      }

      this.onSwipeDown = function ($event) {
        this.screenService.showPrevScreen()
      }.bind(this)

      this.close = function (referralSource) {
        this.screenService.showIntro = false

        if (this.screenService.isClosing || this.project.id) {
          angular.element(document).off('focus', '.project-form-container > *')

          if (this.screenService.isClosing) {
            trackProjectFormService.closeConfirmAction({
              action: 'close confirm quit',
              referralSource: referralSource || 'x button',
              version: this.project.analytics.closeConfirmVersion,
              projectTypeGroupId: this.project.projectType
                ? this.project.projectType.projectTypeGroupId
                : null,
            })
          }

          $timeout(() => {
            if (this.project.id) {
              this.launchInformationLoadingScreen()
            } else if (
              $attrs.closeModal !== undefined &&
              typeof this.closeModal === 'function'
            ) {
              this.closeModal()
              this.screenService.isClosing = false
            } else {
              projectFormService.onCloseRedirect(this.project)
            }
          }, 300)
        } else {
          this.screenService.isClosing = true
          trackProjectFormService.closeConfirmAction({
            action: 'close confirm viewed',
            version: this.project.analytics.closeConfirmVersion,
            projectTypeGroupId: this.project.projectType
              ? this.project.projectType.projectTypeGroupId
              : null,
          })
        }
      }

      this.initTabScrollPrevent = function () {
        angular
          .element('.project-form-container')
          .on('focus', '.project-form-container > *', function () {
            this.resetFormPosition()
          })

        angular.element(document).keydown((e) => {
          let keycode = e.keyCode ? e.keyCode : e.which
          if (keycode === 0 || keycode === 9) {
            e.preventDefault()
            e.stopPropagation()
          }
        })
      }

      this.resetFormPosition = () =>
        angular.element('.project-form-container').scrollTop(0).scrollLeft(0)

      this.isFormVersion = (formVersion) => {
        return this.project.analytics.formVersion === formVersion
      }

      this.isControlFormVersion = () =>
        this.isFormVersion(PROJECT_CONTROL_FORM_VERSION)

      this.isIntroScreenFormVersion = (formVersion) =>
        [].includes(formVersion || this.project.analytics.formVersion)

      this.refTag = () => {
        return this.formData.reftag || document.location.href
      }

      this.onUrgencyTypeSelected = (selectedOption) => {
        this.project.summary.urgency = selectedOption.value
        this.trackBasicEvent({
          action: 'urgency selected',
          status: selectedOption.value,
        })
      }

      this.onPropertyRelationshipSelected = (selectedOption) => {
        this.project.location.poPropertyRelationship = selectedOption.value
        this.trackBasicEvent({
          action: 'property relationship selected',
          status: selectedOption.value,
        })
      }

      this.onLandOwnershipStatusSelected = (selectedOption) => {
        this.project.summary.landOwnershipStatus = selectedOption.value
        this.trackBasicEvent({
          action: 'landing ownership status selected',
          status: selectedOption.value,
        })
      }

      this.onDesignStatusSelected = (selectedOption) => {
        this.project.summary.designStatus = selectedOption.value
        this.trackBasicEvent({
          action: 'design status selected',
          status: selectedOption.value,
        })
      }

      this.trackBasicEvent = (options) => {
        trackProjectFormService.basicEvent(
          angular.extend(
            {
              formVersion: this.project.analytics.formVersion,
              projectTypeGroupId: this.project.projectType
                ? this.project.projectType.projectTypeGroupId
                : null,
            },
            options
          )
        )
      }
    },
  ],
}
