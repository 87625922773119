export default [
  'bzHttp',
  'urlConstants',
  function (bzHttp, urlConstants) {
    return {
      matchProjectType: function (text) {
        return bzHttp.get(`${urlConstants.api.v1.path}/match_project_types`, {
          params: { q: text },
        })
      },
    }
  },
]
