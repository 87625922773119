/* global bz_app, angular, $, bugsnagClient */
angular.module('bzProjectModule').factory('v2ServiceRequestModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  'modelService',
  'bzHttp',
  function (
    $http,
    urlConstants,
    attributeConversionService,
    modelService,
    bzHttp
  ) {
    return {
      apiV1ServiceRequestsPath: urlConstants.api.v1.path + 'service_requests',
      apiServiceRequestsPath: urlConstants.api.v3.path + 'service_requests',
      apiServiceRequestsContractorsPath: function (
        serviceRequestId,
        contractorId
      ) {
        if (contractorId !== undefined) {
          return (
            this.apiServiceRequestsPath +
            '/' +
            serviceRequestId +
            '/contractors/' +
            contractorId
          )
        } else {
          return (
            this.apiServiceRequestsPath +
            '/' +
            serviceRequestId +
            '/contractors'
          )
        }
      },
      load: function (serviceRequestId, params) {
        var queryString = ''
        if (params) {
          angular.forEach(params, function (param) {
            queryString += '&fields[]=' + param
          })
        }
        return $http
          .get(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              (queryString.length ? '?' + queryString : '')
          )
          .then(this.extractData)
      },
      loadContractorsList: function (serviceRequestId) {
        return this.load(serviceRequestId, [
          'invited_contractors',
          'interested_contractors',
          'declined_contractors',
          'conversations',
          'bids',
          'outcome',
        ])
      },
      loadSentToContractors: function (serviceRequestId) {
        return $http
          .get(
            this.apiServiceRequestsPath + '/' + serviceRequestId + '/sent_to'
          )
          .then(this.extractData)
      },
      loadFields: function (serviceRequestId, fields, hideMetadata = true) {
        const qs = modelService.queryString({
          fields,
          hide_metadata: hideMetadata,
        })
        return bzHttp.get(
          `${this.apiServiceRequestsPath}/${serviceRequestId}${qs}`
        )
      },
      loadFeedItems: function (serviceRequestId) {
        return this.loadFields(serviceRequestId, ['feed_items'])
      },
      loadContractorGroups: function (serviceRequestId) {
        return this.loadFields(serviceRequestId, ['contractor_groups'])
      },
      loadAutomatedCommunications: function (serviceRequestId) {
        return this.loadFields(serviceRequestId, ['automated_communications'])
      },
      create: function (serviceRequest) {
        return $http
          .post(
            this.apiServiceRequestsPath,
            attributeConversionService.camelToUnderscoreDeepAttributes(
              serviceRequest
            )
          )
          .then(this.extractData, (response) => {
            this.errorResponseBugsnag(response, 'create')
          })
      },
      update: function (serviceRequestId, serviceRequest) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(
            serviceRequest
          )
        return $http
          .put(this.apiServiceRequestsPath + '/' + serviceRequestId, data)
          .then(this.extractData, (response) => {
            this.errorResponseBugsnag(response, 'update')
          })
      },
      errorResponseBugsnag: function (response, source) {
        const { data, status, statusText } = response
        bugsnagClient.notify(new Error(`Error in SR ${source}`), {
          metaData: {
            source,
            data,
            status,
            statusText,
          },
        })
      },
      updateConnection: function (
        serviceRequestId,
        connectionId,
        newConnectionData
      ) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(
            newConnectionData
          )
        var url =
          this.apiServiceRequestsPath +
          '/' +
          serviceRequestId +
          '/update_connection/' +
          connectionId
        return $http.put(url, { data: data }).then(this.extractData)
      },
      setWontPay: function (serviceRequestId, connectionId, wontPayStatus) {
        var url =
          this.apiServiceRequestsPath +
          '/' +
          serviceRequestId +
          '/set_wont_pay/' +
          connectionId
        return $http.put(url, { status: wontPayStatus }).then(this.extractData)
      },
      unmarkWontPay: function (serviceRequestId, connectionId) {
        var url =
          this.apiServiceRequestsPath +
          '/' +
          serviceRequestId +
          '/unmark_wont_pay/' +
          connectionId
        return $http.put(url).then(this.extractData)
      },
      toggleMarkedForCollectionsAgency: function (
        serviceRequestId,
        connectionId
      ) {
        return bzHttp.put(
          this.apiServiceRequestsPath +
            '/' +
            serviceRequestId +
            '/toggle_marked_for_collections_agency',
          { connectionId: connectionId }
        )
      },
      toggleSentToCollectionsAgency: function (serviceRequestId) {
        return bzHttp.put(
          this.apiServiceRequestsPath +
            '/' +
            serviceRequestId +
            '/toggle_sent_to_collections_agency'
        )
      },
      unmarkFailedPayment: function (serviceRequestId, connectionId) {
        var path =
          this.apiServiceRequestsPath +
          '/' +
          serviceRequestId +
          '/unmark_failed_payment/' +
          connectionId
        return $http.put(path).then(this.extractData)
      },
      updateConnections: function (serviceRequestId, serviceRequest) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(
            serviceRequest
          )
        return $http
          .put(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/' +
              data.endpoint,
            data
          )
          .then(this.extractData)
      },
      validateHire: function (
        serviceRequestId,
        connectionId,
        isValid,
        hireType
      ) {
        var data = {}
        if (hireType === 'po') {
          data.winValidatedByPo = isValid
        } else if (hireType === 'co') {
          data.winValidatedByCo = isValid
        }

        return $http
          .put(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/validate_win/' +
              connectionId,
            attributeConversionService.camelToUnderscoreDeepAttributes(data)
          )
          .then(this.extractData)
      },
      reopen: function (serviceRequestId, reason) {
        return this.update(serviceRequestId, {
          projectStatus: { open: true, details: reason },
        })
      },
      close: function (serviceRequestId) {
        return this.update(serviceRequestId, { projectStatus: { open: false } })
      },
      closeWithReason: function (
        serviceRequestId,
        reason,
        details,
        hiredContractorId,
        bidAmount,
        postponeDuration
      ) {
        return this.update(serviceRequestId, {
          projectStatus: {
            open: false,
            reason: reason,
            details: details,
            hired_contractor_id: { hired_contractor_id: hiredContractorId },
            postpone_duration: postponeDuration,
          },
          outcome: {
            hired_contractor_id: { hired_contractor_id: hiredContractorId },
            hired_contractor_bid_amount: bidAmount,
          },
        })
      },
      hireContractor: function (
        serviceRequestId,
        contractorId,
        bidAmount,
        origin,
        source
      ) {
        return this.update(serviceRequestId, {
          outcome: {
            hiredContractor: contractorId,
            hiredContractorBidAmount:
              bidAmount === undefined ? null : bidAmount,
            hireSource: origin,
            source: source,
          },
        })
      },
      unHireContractor: function (serviceRequestId) {
        return $http
          .put(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/unhire_contractor'
          )
          .then(this.extractData)
      },
      inviteContractors: function (serviceRequestId, contractorIds) {
        return this.update(serviceRequestId, {
          invitedContractors: contractorIds,
        })
      },
      declineContractors: function (serviceRequestId, contractorIds) {
        return this.update(serviceRequestId, {
          declinedContractors: contractorIds,
        })
      },
      declineContractor: function (
        serviceRequestId,
        contractorId,
        reasonDeclined
      ) {
        var data = { decline: true, reason: reasonDeclined }

        return $http
          .put(
            this.apiServiceRequestsContractorsPath(
              serviceRequestId,
              contractorId
            ),
            data
          )
          .then(this.extractData)
      },
      unDeclineContractor: function (serviceRequestId, contractorId, reason) {
        var data = { decline: false, reason: reason }

        return $http
          .put(
            this.apiServiceRequestsContractorsPath(
              serviceRequestId,
              contractorId
            ),
            data
          )
          .then(this.extractData)
      },
      excludeContractors: function (serviceRequestId, contractorIds) {
        return this.update(serviceRequestId, {
          excluded_contractors: contractorIds,
        })
      },
      dismissContractors: function (serviceRequestId, contractorIds) {
        var data = { contractor_ids: contractorIds }
        return $http
          .post(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/dismissed_contractors',
            data
          )
          .then(this.extractData)
      },
      loadPropertyDetails: function (serviceRequestId) {
        return $http
          .get(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/get_property_details'
          )
          .then(this.extractData)
      },
      confirmContractorWin: function (
        serviceRequestId,
        contractorId,
        confirm,
        origin
      ) {
        var data = { confirmed_win: confirm, hire_source: origin }

        return $http
          .put(
            this.apiServiceRequestsContractorsPath(
              serviceRequestId,
              contractorId
            ),
            data
          )
          .then(this.extractData)
      },
      setNotes: function (serviceRequestId, contractorId, notes, notesType) {
        return $http
          .put(
            this.apiServiceRequestsContractorsPath(
              serviceRequestId,
              contractorId
            ),
            { notes: notes, notes_type: notesType }
          )
          .then(this.extractData)
      },
      requestContractorBid: function (serviceRequestId, contractorId) {
        var data = { request_bid: true }

        return $http
          .put(
            this.apiServiceRequestsContractorsPath(
              serviceRequestId,
              contractorId
            ),
            data
          )
          .then(this.extractData)
      },
      requestAdditionalContractor: function (serviceRequestId) {
        return $http
          .put(this.apiServiceRequestsContractorsPath(serviceRequestId), {
            additional_requested_contractors: 1,
          })
          .then(this.extractData)
      },
      prepForRedistribution: function (serviceRequestId) {
        return $http
          .put(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/prep_for_redistribution'
          )
          .then(this.extractData)
      },
      cancelDistribution: function (serviceRequestId) {
        return $http
          .put(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/cancel_distribution'
          )
          .then(this.extractData)
      },
      markAsSemConversion: function (serviceRequestId) {
        return $http
          .put(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/sem_conversion'
          )
          .then(this.extractData)
      },
      sendAdminMessageToContractors: function (
        serviceRequestId,
        contractorIds,
        customMessage,
        medium
      ) {
        return $http
          .post(
            urlConstants.api.v2.path +
              'manage/admin/service_requests/' +
              serviceRequestId +
              '/send_message_to_contractors',
            {
              contractors: contractorIds,
              service_request_id: serviceRequestId,
              custom_message: customMessage,
              medium: medium,
            }
          )
          .then(this.extractData)
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
      unArchive: function (serviceRequestId) {
        return $http
          .put(this.apiServiceRequestsPath + '/' + serviceRequestId, {
            unarchive: true,
          })
          .then(this.extractData)
      },
      getLoadingScreenStatistics(serviceRequestId) {
        return $http
          .get(
            this.apiServiceRequestsPath +
              '/' +
              serviceRequestId +
              '/loading_screen_stats'
          )
          .then(this.extractData)
          .then((data) => {
            return attributeConversionService.underscoreToCamelDeepAttributes(
              data
            )
          })
      },
      markPermitMatchLoss(serviceRequestId, contractorId) {
        return this.update(serviceRequestId, {
          admin: { status: 'lost', substatus: 'hired_non_bz_permit_match' },
          permitMatchValidatedLoss: true,
          contractorId: contractorId,
        })
      },
      queueForEmailSearch(serviceRequestId) {
        return bzHttp.put(
          this.apiServiceRequestsPath +
            '/' +
            serviceRequestId +
            '/queue_for_email_search'
        )
      },
    }
  },
])
