bz_app.factory('plaidService', [
  'applicationConstants',
  'urlConstants',
  'bankAccountModel',
  function (applicationConstants, urlConstants, bankAccountModel) {
    return {
      handler: null,
      default_config: {
        env: applicationConstants.plaid.env,
        apiVersion: 'v2',
        clientName: 'BuildZoom',
        key: applicationConstants.plaid.public_key,
        product: 'auth',
        selectAccount: true,
        onLoad: function () {
          //TODO track
        },
        onExit: function () {
          //TODO track
        },
      },
      open: function () {
        this.handler.open()
      },
      isInit: false,
      init: function (customSuccess, customFailure, customProcessing) {
        this.customSuccess = customSuccess
        this.customFailure = customFailure
        this.customProcessing = customProcessing
        var that = this
        this.default_config.onSuccess = function (publicToken, metadata) {
          var data = {
            public_token: publicToken,
            account_id: metadata.account_id,
            institution: {
              name: metadata.institution.name,
              type: metadata.institution.type,
            },
          }
          that.customProcessing()
          bankAccountModel.create(data).then(function (response) {
            if (response.data.success) {
              if (that.customSuccess) that.customSuccess(response)
            } else if (that.customFailure) that.customFailure(response)
          })
        }
        this.handler = Plaid.create(this.default_config)
      },
    }
  },
])
