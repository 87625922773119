/*
	Example usage:
		<file-upload-input on-upload="$ctrl.myCallback(files)">
		</file-upload-input>
*/

bz_app.component('fileUploadInput', {
  bindings: {
    onUpload: '&',
    fileUploadId: '<',
  },
  template:
    '<input class="file-upload-component" type="file" multiple ng-attr-id="{{$ctrl.fileUploadId}}">',
  controller: [
    '$element',
    function ($element) {
      $element.bind(
        'change',
        function (changeEvent) {
          this.onUpload({ files: changeEvent.target.files })
        }.bind(this)
      )
    },
  ],
})
