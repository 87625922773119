bz_app.factory('adminLicenseModel', [
  '$rootScope',
  function () {
    return {
      adminLicenseApi: new AdminLicenseAPI(),
      verifyLicenseReview: function (licenseReviewId, verify) {
        return this.adminLicenseApi.verify(licenseReviewId, verify)
      },
      saveNotes: function (licenseId, notes) {
        return this.adminLicenseApi.saveNotes(licenseId, notes)
      },
      markForReview: function (licenseId) {
        return this.adminLicenseApi.markForReview(licenseId)
      },
    }
  },
])
