const P2P_EMAIL_URL =
  bzGlobal.urlConstants.api.v1.path + 'p2p_email_conversations'
import { map } from 'lodash'

export default class P2pEmailConversationsApi {
  constructor(httpClient) {
    this.client = httpClient
  }

  sendEmail({
    body,
    subject,
    connectionId,
    recipientUser,
    sendingUser,
    attachments,
  }) {
    let attachmentIds = map(attachments || [], 'id')
    return this.client
      .post(`${P2P_EMAIL_URL}/send_email`, {
        params: {
          body,
          subject,
          connectionId,
          recipientUser,
          sendingUser,
          attachments,
          attachmentIds,
        },
      })
      .then(this.extractEmailData)
  }

  extractEmailData(response) {
    if (response.data) {
      response.data[0].attachments = response.data[1]
      return response.data[0]
    } else {
      return null
    }
  }

  loadEmailThread({ connectionId }) {
    return this.client.get(`${P2P_EMAIL_URL}/show_email_thread`, {
      params: { connectionId },
    })
  }

  markEmailsAsRead({ emailIds }) {
    return this.client.post(`${P2P_EMAIL_URL}/mark_emails_as_read`, {
      params: { emailIds },
    })
  }
}
