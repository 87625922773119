import template from './communication_preference.html'

const communicationPreferenceComponent = {
  bindings: {
    preferenceKey: '<',
    name: '<',
    description: '<',
    isEmailSubscribed: '=',
    isSmsSubscribed: '=',
    onUpdate: '<',
    getDisabledChoices: '<?',
  },
  template,
  controller: [
    function () {
      this.$onInit = () => {
        this.disabledChoices = { email: false, textMessage: false }
        this.populateDisabledChoices()
      }

      this.updateEmailPreference = () => {
        this.updateCommunicationPreference('email', this.isEmailSubscribed)
      }

      this.updateSmsPreference = () => {
        this.updateCommunicationPreference('sms', this.isSmsSubscribed)
      }

      this.updateCommunicationPreference = (medium, isSubscribed) => {
        if (this.onUpdate) {
          this.onUpdate({
            preferenceKey: this.preferenceKey,
            medium,
            isSubscribed,
          })
        }

        this.populateDisabledChoices()
      }

      this.populateDisabledChoices = () => {
        if (this.getDisabledChoices) {
          const disabledChoices =
            this.getDisabledChoices(
              this.isEmailSubscribed,
              this.isSmsSubscribed
            ) || []
          this.disabledChoices.email = disabledChoices.includes('email')
          this.disabledChoices.sms = disabledChoices.includes('sms')
        }
      }
    },
  ],
}

export default communicationPreferenceComponent
