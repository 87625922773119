/* global google */
bz_app.factory('googleMapsStreetViewService', [
  'applicationConstants',
  '$http',
  function (applicationConstants, $http) {
    return {
      getImage: function (options) {
        if (!options.width) options.width = 200
        if (!options.height) options.height = 200

        if (options.latitude && options.longitude) {
          var ll = new google.maps.LatLng(
            parseFloat(options.latitude),
            parseFloat(options.longitude)
          )
          var sv = new google.maps.StreetViewService()

          sv.getPanoramaByLocation(ll, 50, function (data, status) {
            if (status == google.maps.StreetViewStatus.OK) {
              if (options.successCallback)
                options.successCallback(
                  '//maps.googleapis.com/maps/api/streetview?size=' +
                    options.width +
                    'x' +
                    options.height +
                    '&location=' +
                    options.latitude +
                    ',' +
                    options.longitude +
                    '&heading=0&pitch=0&fov=80&sensor=false&key=' +
                    applicationConstants.googleBrowserKey
                )
            } else if (options.failureCallback) {
              options.failureCallback()
            }
          })
        } else if (options.failureCallback) {
          options.failureCallback()
        }
      },

      isStreetAddressImageAvailable: function (address, callback) {
        this._isImageAvailable(address, callback)
      },

      isLngLatImageAvailable: function (lat, lng, callback) {
        this._isImageAvailable(lat + ',' + lng, callback)
      },

      _isImageAvailable: function (location, callback) {
        return $http
          .get('https://maps.googleapis.com/maps/api/streetview/metadata', {
            params: {
              key: applicationConstants.googleBrowserKey,
              location: location,
            },
            cache: true,
            headers: {
              // Google does not allow this header we send by default
              xsrfCookieName: undefined,
            },
          })
          .then(
            function (resp) {
              callback(resp.data.status === 'OK')
            },
            function (err) {
              callback(false)
            }
          )
      },
      checkValidLatLng: function (data) {
        return (
          data.lat &&
          data.lng &&
          data.lat > 18 &&
          data.lat < 75 &&
          data.lng > -165 &&
          data.lng < -60
        )
      },
    }
  },
])
