/* global bz_app, isMobile */
bz_app.factory('confirmWinModalService', [
  'modalScreenService',
  function (modalScreenService) {
    return {
      openModal: function (lead, source, onCancel) {
        return modalScreenService.initDetached({
          controller: 'ConfirmWinModalCtrl',
          templateUrl: 'leads/unlocked_lead/confirm_win_modal',
          windowClass: 'v3-modal v3-modal-simple small confirm-win-modal',
          backdrop: !isMobile.check({ phoneOnly: true }),
          inputData: {
            lead: lead,
            source: source,
            onCancel: onCancel,
          },
        })
      },
    }
  },
])
