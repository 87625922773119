const referralFeeAcceptanceModel = [
  'bzHttp',
  'urlConstants',
  function (bzHttp, urlConstants) {
    return {
      basePath: urlConstants.api.v3.path + 'referral_fee_acceptances',
      create(leadId, referralFee) {
        return bzHttp.post(this.basePath, { leadId, referralFee })
      },
    }
  },
]

export default referralFeeAcceptanceModel
