var ValidateForm = function () {
  this.init = function (form_selector, required_fields) {
    var that = this

    // check required input value during submit
    $(form_selector + " input[type='submit']").on('click', function (e) {
      e.preventDefault()
      if (!that.required_errors(required_fields)) {
        $(form_selector).submit()
      } else {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    })
  }

  this.required_errors = function (required_fields) {
    var error_flag = false
    var that = this

    $.each(required_fields, function (index, field) {
      if (field.type == 'email') {
        if (!that.valid_email($('#' + field.id).val())) {
          error_flag = that.error_on(field.id)
        } else {
          that.error_off(field.id)
        }
      } else if (field.type == 'phone') {
        if (
          !that.valid_phone(
            $('#' + field.id)
              .val()
              .replace(/[^\d.]/g, '')
          )
        ) {
          error_flag = that.error_on(field.id)
        } else {
          that.error_off(field.id)
        }
      } else if (field.type == 'zipcode') {
        if (!that.valid_zipcode($('#' + field.id).val())) {
          error_flag = that.error_on(field.id)
        } else {
          that.error_off(field.id)
        }
      } else if (field.type == 'password') {
        if (!that.valid_length($('#' + field.id).val(), 6)) {
          error_flag = that.error_on(field.id)
        } else {
          that.error_off(field.id)
        }
      } else if ($('#' + field.id).val().length == 0) {
        error_flag = that.error_on(field.id)
      } else {
        that.error_off(field.id)
      }
    })
    return error_flag
  }

  this.error_on = function (id) {
    $('#' + id)
      .siblings('label')
      .addClass('field-error field-required')
    return true
  }

  this.error_off = function (id) {
    $('#' + id)
      .siblings('label')
      .removeClass('field-error field-required')
    return false
  }

  this.valid_zipcode = function (zip) {
    return /^\d{5}(-\d{4})?$/.test(zip)
  }

  this.valid_phone = function (phone) {
    if (!phone) return false

    return /^\d{10}$/.test(phone.replace(/\D/g, ''))
  }

  this.valid_email = function (email) {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  this.valid_length = function (string, minLength) {
    return string && string.length >= minLength
  }
}

window.ValidateForm = ValidateForm
