if (typeof window.bz_app === 'undefined') {
  var bz_app = angular.module(
    'bz_app',
    ['ui.bootstrap', 'bzReviewsModule'].concat(defaultAngularModuleSet)
  )
  bz_app.config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.defaults.headers.common['xsrfCookieName'] = 'X-XSRF-Token'
    },
  ])

  angular.element(document).ready(function () {
    angular.bootstrap(document, ['bz_app'])
  })

  window.bz_app = bz_app
}
