/**	service to retrieve budget breakdown details	*/
bz_app.factory('budgetIncludesModel', function () {
  return {
    get_all: function () {
      return [
        { value: '', text: 'not sure' },
        { value: 'homeowner', text: 'includes the cost of materials' },
        { value: 'contractor', text: 'does not include the cost of materials' },
      ]
    },
  }
})
