bz_app.factory('checkoutModel', [
  'bzHttp',
  '$http',
  'urlConstants',
  function (bzHttp, $http, urlConstants) {
    return {
      apiCheckoutPath: urlConstants.api.v1.path + 'checkouts',
      apiRefundPath: urlConstants.api.v1.path + 'refunds',
      adminApiCheckoutPath: urlConstants.api.v1.path + 'admin/checkouts',
      create: function (checkout) {
        return $http.post(this.apiCheckoutPath, checkout)
      },
      refund: function (checkout) {
        return $http.post(this.apiRefundPath, checkout)
      },
      adminUpdate: function (checkout) {
        return bzHttp.put(
          this.adminApiCheckoutPath + '/' + checkout.id,
          checkout
        )
      },
      setPlan: function (opts) {
        return $http.put(this.adminApiCheckoutPath + '/' + opts.id, opts)
      },
    }
  },
])
