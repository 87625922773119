/* global mixpanel, window */

const log = (title, data) =>
  // eslint-disable-next-line no-console
  console.log('Mixpanel action: ', { ...data, title })

const env = window.bzGlobal && window.bzGlobal.ev

const track = (title, data) => {
  if (env !== 'production') log(title, data)

  if (window.mixpanel) {
    mixpanel.track(title, data)
  }
}

const trackLink = (query, title, data) => {
  if (env !== 'production') log(title, data)

  if (window.mixpanel) {
    mixpanel.track_links(query, title, data)
  }
}

export { track, trackLink }
