angular.module('bzUserModule').factory('userModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiPath: urlConstants.api.v1.path + 'user',
      impersonationApiPath: urlConstants.api.v3.path + 'impersonation',
      verifyContactMethod: function (type, allowPoSend) {
        var url = this.apiPath + '/verify?type=' + type
        if (allowPoSend) {
          url += '&allow_po_send=true'
        }
        return $http.post(url)
      },
      findExistingUserByEmail: function (email, allowLoggedIn) {
        var url =
          this.apiPath +
          '/find_existing_user?email=' +
          encodeURIComponent(email)
        if (allowLoggedIn) {
          url += '&allow_logged_in=true'
        }
        return $http.get(url).then(this.extractConvertData.bind(this))
      },
      validateExistingUserPassword: function (email, password) {
        var url =
          this.apiPath +
          '/validate_existing_user_password?email=' +
          encodeURIComponent(email) +
          '&password=' +
          encodeURIComponent(password)

        return $http.get(url).then(this.extractConvertData.bind(this))
      },
      updatePassword: function (newPassword) {
        return $http
          .put(this.apiPath + '/update_password', { password: newPassword })
          .then(this.extractData)
      },
      updateBasicInfo: function (projectOwner) {
        return $http
          .put(this.apiPath + '/basic_info', {
            user_id: projectOwner.userId,
            full_name: projectOwner.fullName,
            contact_info: projectOwner.contactInfo,
            facebook_profile: projectOwner.facebookProfile,
            twitter_profile: projectOwner.twitterProfile,
          })
          .then(this.extractData, this.failureCallback)
      },
      getStatus: function () {
        return $http
          .get(this.apiPath + '/status')
          .then(this.extractConvertData.bind(this))
      },
      getImpersonationData: function () {
        return $http
          .get(this.impersonationApiPath)
          .then(this.extractConvertData.bind(this))
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
      failureCallback: function (response) {
        return response
      },
      extractConvertData: function (response) {
        return attributeConversionService.underscoreToCamelDeepAttributes(
          this.extractData(response)
        )
      },
      changeEmailStatus: function (email, newStatus) {
        var url = urlConstants.api.v1.path + 'unclaimed_emails/' + email
        return $http.patch(url, { status: newStatus })
      },
      createEmailStatus: function (email, status) {
        var url = urlConstants.api.v1.path + 'unclaimed_emails'
        return $http.post(url, { email: email, status: status })
      },
    }
  },
])
