/* global angular, UserAPI */
angular.module('bzProjectFormModule').directive('emailInput', [
  '$compile',
  'validationService',
  '$timeout',
  function ($compile, validationService, $timeout) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        formData: '=',
        validateInput: '=',
        showFormErrors: '=',
      },
      template:
        '<input ng-model="formData.email" type="email" id="service_request_email_address" name="service_request[email_address]" class="form-control modern-form-control" placeholder="Email" autocomplete="email" required />',
      link: function (scope, element) {
        scope.user_api = new UserAPI()

        if (scope.validateInput) {
          scope.validationService = validationService

          var errorElement = $compile(
            '<div input-error error-text="Please enter your email." error-validator="validationService.isValidEmail()" error-visible="showFormErrors"></div>'
          )(scope)
          element.before(errorElement)
        }

        element.on('blur', function (res) {
          var emailInput = element.val()

          scope.user_api.find_existing_user(emailInput).then(function (res) {
            scope.$apply(function () {
              if (res && res.existing_user) {
                scope.formData.existingUserName = res.first_name || res.email
                $timeout(function () {
                  element
                    .parent()
                    .find('#service_request_password')
                    .focus()
                    .val('')
                }, 0)
              } else {
                scope.formData.existingUserName = null
              }
            })
          })
        })
      },
    }
  },
])
