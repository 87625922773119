/* global angular */
import { get, reject, isEmpty } from 'lodash'
import AdminServiceRequestApi from 'Vanilla/api/admin_service_request_api'

angular.module('bzProjectModule').factory('serviceRequestInstance', [
  '$rootScope',
  'attributeConversionService',
  'projectAttachmentModel',
  'userCommunicationModel',
  'userModel',
  '$timeout',
  'v2ServiceRequestModel',
  'trackServiceRequestsActionsService',
  'applicationConstants',
  'trackProjectFormService',
  '$filter',
  'adminServiceRequestCommentsModel',
  'serviceRequestPermitDataInstance',
  'arrayService',
  'projectTypeModel',
  'bzHttp',
  function (
    $rootScope,
    attributeConversionService,
    projectAttachmentModel,
    userCommunicationModel,
    userModel,
    $timeout,
    v2ServiceRequestModel,
    trackServiceRequestsActionsService,
    applicationConstants,
    trackProjectFormService,
    $filter,
    adminServiceRequestCommentsModel,
    serviceRequestPermitDataInstance,
    arrayService,
    projectTypeModel,
    bzHttp
  ) {
    return function () {
      const adminServiceRequestApi = new AdminServiceRequestApi(bzHttp)

      this.init = false
      this.summary = {}
      this.config = {}
      this.errors = {
        summary: null,
        projectOwner: {
          contactInfo: null,
        },
      }

      function pick(obj, keyArr) {
        let newObj = {}

        Object.keys(obj).forEach(function (key) {
          if (keyArr.indexOf(key) > -1) {
            newObj[key] = obj[key]
          }
        })

        return newObj
      }

      this.set = function (data) {
        if (data) {
          data =
            attributeConversionService.underscoreToCamelDeepAttributes(data)
          for (let property in data) {
            if (data.hasOwnProperty(property)) {
              this[property] = data[property]
            }
          }

          this.parseData()
          this.init = true
        }

        return this
      }

      this.parseData = function () {
        this.setProjectOwnerUser()
        this.setConcierge()
        this.setConsultant()
        this.setInterestedContractors()
        this.setInvitedContractors()
        this.setHiredContractor()
        this.setContractorClaimingWin()
        this.setCollections()
        this.setBudgetDetails()
        this.parseDates()
        this.setReservedContractors()
      }

      this.load = function () {
        return v2ServiceRequestModel.load(this.id)
      }

      this.loadContractorsList = function () {
        return v2ServiceRequestModel.loadContractorsList(this.id)
      }

      this.loadSentToContractors = function () {
        return v2ServiceRequestModel.loadSentToContractors(this.id)
      }

      this.loadFeedItems = function () {
        return v2ServiceRequestModel
          .loadFeedItems(this.id)
          .then(({ serviceRequest: { feedItems } }) => {
            this.feedItems = feedItems
          })
      }

      this.loadContractorGroups = function () {
        return v2ServiceRequestModel
          .loadContractorGroups(this.id)
          .then(({ serviceRequest: { contractorGroups } }) => {
            this.contractorGroups = contractorGroups
          })
      }

      this.loadAutomatedCommunications = function () {
        return v2ServiceRequestModel
          .loadAutomatedCommunications(this.id)
          .then(({ serviceRequest: { automatedCommunications } }) => {
            this.automatedCommunications = automatedCommunications
          })
      }

      this.saveAttributes = (attributes) =>
        v2ServiceRequestModel
          .update(this.id, attributes)
          .then(this.processSaveSummaryResponse.bind(this))

      this.saveSummary = function () {
        this.init = false
        if (!this.outcome.hiredContractor) {
          this.outcome.hiredContractorBidAmount = null
        }

        this.cleanProjectOwnerPhone()

        if (this.id) {
          return this.updateSummary()
        } else {
          return this.createSummary()
        }
      }

      this.createSummary = function () {
        return v2ServiceRequestModel
          .create(this)
          .then(this.processSaveSummaryResponse.bind(this))
      }

      this.updateSummary = function () {
        return v2ServiceRequestModel
          .update(this.id, this)
          .then(this.processSaveSummaryResponse.bind(this))
      }

      this.updateConnections = function (data) {
        return v2ServiceRequestModel.updateConnections(this.id, data)
      }

      this.updateConnection = function (contractorId, data) {
        return v2ServiceRequestModel
          .updateConnection(this.id, this.connections[contractorId].id, data)
          .then(
            function (resp) {
              let respCamel =
                attributeConversionService.underscoreToCamelDeepAttributes(resp)
              if (respCamel.success) {
                angular.extend(
                  this.connections[contractorId],
                  pick(respCamel.connection, Object.keys(data))
                )
              }

              return respCamel
            }.bind(this)
          )
      }

      this.setWontPay = function (contractorId, wontPayStatus) {
        return v2ServiceRequestModel.setWontPay(
          this.id,
          this.connections[contractorId].id,
          wontPayStatus
        )
      }

      this.unmarkWontPay = function (connectionId) {
        return v2ServiceRequestModel.unmarkWontPay(this.id, connectionId)
      }

      this.unmarkFailedPayment = function (connectionId) {
        return v2ServiceRequestModel.unmarkFailedPayment(this.id, connectionId)
      }

      this.toggleMarkedForCollectionsAgency = (connectionId) => {
        return v2ServiceRequestModel.toggleMarkedForCollectionsAgency(
          this.id,
          connectionId
        )
      }

      this.toggleSentToCollectionsAgency = () =>
        v2ServiceRequestModel.toggleSentToCollectionsAgency(this.id)

      this.createAdminComment = function (data) {
        return adminServiceRequestCommentsModel
          .create(this.id, data)
          .then((res) => {
            if (res.success) {
              this.feedItems = this.feedItems || []
              let firstItem = this.feedItems[0]
              res.feedItem.id = firstItem ? firstItem.id - 1 : 0
              this.feedItems = [res.feedItem, ...this.feedItems]
            }
          })
      }

      this.splitServiceRequest = function () {
        this.split = true
        return v2ServiceRequestModel
          .create({ splitFromServiceRequest: this.id })
          .then(function (response) {
            if (response.success) {
              return response.service_request
            }
            return null
          })
      }

      this.processSaveSummaryResponse = (response) => {
        try {
          if (response && response.success) {
            const convertedSr =
              attributeConversionService.underscoreToCamelDeepAttributes(
                response.service_request
              )

            if (!this.id) {
              this.id = response.service_request.id
              trackProjectFormService.submit(
                angular.extend(
                  {
                    projectTypeGroupId:
                      this.projectType && this.projectType.projectTypeGroupId
                        ? this.projectType.projectTypeGroupId
                        : null,
                    title: this.summary.title,
                  },
                  this.analytics
                )
              )
            }

            if (!this.projectId) {
              this.projectId = response.service_request.project_id
            }

            if (!this.projectOwner.userId) {
              this.projectOwner.userId = response.service_request.project_owner
            }

            if (convertedSr.projectType) {
              this.projectType = convertedSr.projectType
            }

            if (response.service_request && response.service_request.location) {
              this.location.neighborhood =
                response.service_request.location.neighborhood
              this.location.city = response.service_request.location.city
              this.location.state = response.service_request.location.state

              if (response.service_request.location.city_state) {
                this.location.cityState =
                  response.service_request.location.city_state
              }
            }

            this.setBudgetDetails()
          }

          $timeout(() => {
            this.init = true
          })

          this.init = true
        } catch (err) {
          console.error(err)
        }
      }

      this.saveContactInfo = function () {
        this.init = false
        return userCommunicationModel
          .update(this.projectOwner.contactInfo, this.projectOwner.userId)
          .then(
            function (response) {
              if (response) {
                if (response.success) {
                  this.errors.projectOwner.contactInfo = null
                } else {
                  this.errors.projectOwner.contactInfo = response.status
                  this.projectOwner.contactInfo.email.value =
                    response.data.email.value
                }
              }

              $timeout(
                function () {
                  this.init = true
                }.bind(this)
              )

              return response
            }.bind(this)
          )
      }

      this.saveProjectOwnerInfo = function () {
        this.init = false
        return userModel.updateBasicInfo(this.projectOwner).then(
          function (response) {
            if (response) {
              if (response.success) {
                this.errors.projectOwner.contactInfo = null
                if (this.projectOwner.contactInfo.phone) {
                  this.projectOwner.contactInfo.phone.preferred =
                    response.phone_preferred
                }
              } else {
                this.errors.projectOwner.contactInfo = response.data.messages
              }
            }

            $timeout(
              function () {
                this.init = true
              }.bind(this)
            )

            return response
          }.bind(this)
        )
      }

      this.reopen = function (reason) {
        return v2ServiceRequestModel.reopen(this.id, reason)
      }

      this.close = function () {
        return v2ServiceRequestModel.close(this.id)
      }

      this.closeWithReason = function (
        closeReason,
        details,
        hiredContractorId,
        bidAmount,
        postponeDuration
      ) {
        return v2ServiceRequestModel.closeWithReason(
          this.id,
          closeReason,
          details,
          hiredContractorId,
          bidAmount,
          postponeDuration
        )
      }

      this.saveCloseReason = function (closeReason) {
        return v2ServiceRequestModel.saveCloseReason(this.id, closeReason)
      }

      this.hireContractor = function (contractor, bidAmount, origin, source) {
        return v2ServiceRequestModel
          .hireContractor(this.id, contractor.id, bidAmount, origin, source)
          .then(
            function (response) {
              if (response.success) {
                $rootScope.$applyAsync(
                  function () {
                    trackServiceRequestsActionsService.hireContractor(
                      this.id,
                      contractor.id,
                      origin
                    )
                    this.outcome.hiredContractor = contractor
                    this.outcome.hiredContractor.hired = true
                    this.outcome.hiredContractor.declined = false
                    if (bidAmount) {
                      this.outcome.hiredContractorBidAmount = bidAmount
                    }
                  }.bind(this)
                )
              }

              return response
            }.bind(this)
          )
      }

      this.unHireContractor = function () {
        let contractor = this.outcome.hiredContractor
        return v2ServiceRequestModel.unHireContractor(this.id).then(
          function (response) {
            if (response.success) {
              $rootScope.$applyAsync(
                function () {
                  trackServiceRequestsActionsService.unHireContractor(
                    this.id,
                    contractor.id
                  )
                  this.outcome.hiredContractor = null
                  this.outcome.hiredContractorBidAmount = null
                  contractor.hired = false

                  if (contractor.poFinalHireBid) {
                    contractor.poFinalHireBid = null
                  }
                }.bind(this)
              )
            }

            return response
          }.bind(this)
        )
      }

      this.inviteContractors = function (contractorIds) {
        angular.forEach(
          contractorIds,
          function (contractorId) {
            trackServiceRequestsActionsService.inviteContractor(
              this.id,
              contractorId
            )
          }.bind(this)
        )

        return v2ServiceRequestModel.inviteContractors(this.id, contractorIds)
      }

      this.dismissContractors = function (contractorIds) {
        angular.forEach(
          contractorIds,
          function (contractorId) {
            trackServiceRequestsActionsService.dismissContractor(
              this.id,
              contractorId
            )
          }.bind(this)
        )

        return v2ServiceRequestModel.dismissContractors(this.id, contractorIds)
      }

      this.excludeContractors = function (contractorIds) {
        return v2ServiceRequestModel.excludeContractors(this.id, contractorIds)
      }

      this.declineContractors = function (contractorIds) {
        return v2ServiceRequestModel.declineContractors(this.id, contractorIds)
      }

      this.declineContractor = function (contractorId, reasonDeclined) {
        return v2ServiceRequestModel.declineContractor(
          this.id,
          contractorId,
          reasonDeclined
        )
      }

      this.unDeclineContractor = function (contractorId, reason) {
        let index = this.declinedContractors.indexOf(contractorId)
        if (index >= 0) {
          this.declinedContractors.splice(index, 1)
        }

        return v2ServiceRequestModel.unDeclineContractor(
          this.id,
          contractorId,
          reason
        )
      }

      this.requestContractorBid = function (contractorId) {
        return v2ServiceRequestModel.requestContractorBid(this.id, contractorId)
      }

      this.loadPropertyDetails = function () {
        return v2ServiceRequestModel.loadPropertyDetails(this.id).then(
          function (response) {
            this.propertyDetails =
              attributeConversionService.underscoreToCamelDeepAttributes(
                response.property_details
              )
          }.bind(this)
        )
      }

      this.setProjectOwnerUser = function () {
        if (!angular.isObject(this.projectOwner)) {
          this.projectOwner = this.getMetaDataUserById(this.projectOwner)

          if (this.projectOwner) {
            this.projectOwner.fullName = ''

            if (this.projectOwner.firstName && this.projectOwner.lastName) {
              this.projectOwner.fullName =
                this.projectOwner.firstName + ' ' + this.projectOwner.lastName
            } else if (this.projectOwner.firstName) {
              this.projectOwner.fullName = this.projectOwner.firstName
            }
          }
        }
      }

      this.setInterestedContractors = function () {
        let interestedContractorsIds = angular.copy(this.interestedContractors)
        this.interestedContractors = []

        angular.forEach(
          interestedContractorsIds,
          function (contractorData, contractorId) {
            let contractor = this.getMetaDataContractorById(contractorId)
            if (contractor) {
              contractor = this.processContractorMetaData(contractor)
              contractor.connectionId = contractorData.connectionId
              contractor.optedInAt = contractorData.optedInAt
              contractor.calls = contractorData.calls
              contractor.contacted = contractorData.contacted
              contractor.contractorReportedState =
                contractorData.contractorReportedState
              contractor.contractorReportedStateUpdatedAt =
                contractorData.contractorReportedStateUpdatedAt
              contractor.bidRequestedAt = contractorData.bidRequestedAt
              contractor.notes = contractorData.notes
              contractor.adminNotes = contractorData.adminNotes
              contractor.contactNumber = contractorData.contactNumber
              contractor.smsIncapable = contractorData.smsIncapable
              contractor.contractorEmailAlias =
                contractorData.contractorEmailAlias
              contractor.numberOfPermitsInPreviousYear =
                contractorData.numberOfPermitsInPreviousYear

              if (this.outcome.hiredContractor === contractor.id) {
                contractor.hired = true
                this.interestedContractors.unshift(contractor)
              } else {
                contractor.hired = false
                this.interestedContractors.push(contractor)
              }
            }
          }.bind(this)
        )

        this.interestedContractorsWithContact = $filter('filter')(
          this.interestedContractors,
          { contacted: true }
        )

        this.activeContractors = this.getActiveContractors()
      }

      this.getActiveContractors = function () {
        let contractors = []
        angular.forEach(this.interestedContractors, function (contractor) {
          if (
            contractor.contacted &&
            !contractor.declined &&
            contractor.contractorReportedState !== 'inactive'
          ) {
            contractors.push(contractor)
          }
        })

        return contractors
      }

      this.setHiredContractor = function () {
        if (!this.outcome || !this.outcome.hiredContractor) {
          return
        }

        let metadata = this.getMetaDataContractorById(
          this.outcome.hiredContractor
        )
        this.outcome.hiredContractor = metadata
          ? this.processContractorMetaData(metadata)
          : null
      }

      this.setContractorClaimingWin = function () {
        if (!this.outcome || !this.outcome.contractorClaimingWin) {
          return
        }
        let metadata = this.getMetaDataContractorById(
          this.outcome.contractorClaimingWin
        )
        this.outcome.contractorClaimingWin = metadata
          ? this.processContractorMetaData(metadata)
          : null
      }

      this.setCollections = function () {
        if (!this.collections) {
          return
        }
        this.collections = this.collections.map(this.parseCollection.bind(this))
      }

      this.parseCollection = function (collection) {
        return angular.merge(collection, {
          connection: this.connections[collection.contractorId],
          contractor: this.metadata.contractors[collection.contractorId],
        })
      }

      this.setReservedContractors = function () {
        if (!this.availableSlots) return

        angular.forEach(
          this.availableSlots,
          function (slot) {
            if (
              slot.reservedContractorId !== null &&
              slot.reservedContractorId !== -1
            ) {
              slot.contractor = this.getMetaDataContractorById(
                slot.reservedContractorId
              )
            }
          }.bind(this)
        )
      }

      this.setInvitedContractors = function () {
        let invitedContractorsIds = angular.copy(this.invitedContractors)
        this.invitedContractors = []

        angular.forEach(
          invitedContractorsIds,
          function (contractorData, contractorId) {
            let contractor = this.getMetaDataContractorById(contractorId)
            if (contractor) {
              contractor = this.processContractorMetaData(contractor)
              contractor.invitedAt = contractorData.invitedAt
              contractor.invited = true

              if (typeof contractor !== 'undefined') {
                this.invitedContractors.push(contractor)
              }
            }
          }.bind(this)
        )
      }

      this.setConcierge = function () {
        if (!angular.isObject(this.concierge)) {
          this.concierge =
            this.getMetaDataUserById(this.concierge) ||
            this.defaultContactUser()
        }
      }

      this.setConsultant = function () {
        if (!angular.isObject(this.consultant)) {
          this.consultant = this.consultant || this.defaultContactUser()
        }
      }

      this.defaultContactUser = () => ({
        firstName: 'Brooke',
        lastName: 'Adams',
        contactInfo: {
          email: {
            value: applicationConstants.support.email,
          },
          phone: {
            value:
              this.config.autoCallNumber || applicationConstants.support.phone,
          },
        },
      })

      this.parseDates = function () {
        if (this.admin) {
          if (this.admin.followUpDate && this.admin.followUpDate.length) {
            this.admin.followUpDate = new Date(this.admin.followUpDate)
          }

          if (
            this.admin.poRequestedCallDate &&
            this.admin.poRequestedCallDate.length
          ) {
            this.admin.poRequestedCallDate = new Date(
              this.admin.poRequestedCallDate
            )
          }
        }
      }

      this.processContractorMetaData = function (contractor) {
        contractor.allBidsSorted = []

        if (
          this.allSortedBidsByContractor &&
          this.allSortedBidsByContractor[contractor.id]
        ) {
          contractor.allBidsSorted =
            this.allSortedBidsByContractor[contractor.id]
        }

        if (this.bids) {
          contractor.contractorBid = this.bids[contractor.id]
        }

        if (contractor.allBidsSorted) {
          contractor.poFinalHireBid = arrayService.findLast(
            contractor.allBidsSorted,
            function (bid) {
              return bid.bidType === 'hire'
            }
          )
        }

        contractor.conversation = get(this.conversations, contractor.id, null)
        contractor.declined = this.isDeclinedByContractorId(contractor.id)
        contractor.reviewed = this.isReviewedByContractorId(contractor.id)
        contractor.user = this.getMetaDataUserById(contractor.uid)

        if (
          contractor.thumbnails &&
          contractor.thumbnails.length &&
          !contractor.thumbnails[0].generic
        ) {
          contractor.coverUrl = contractor.thumbnails[0].url
        }

        return contractor
      }

      this.getMetaDataUserById = function (userId) {
        if (this.metadata && this.metadata.users) {
          return this.metadata.users[userId] || null
        } else {
          return null
        }
      }

      this.getMetaDataContractorById = function (contractorId) {
        if (this.metadata && this.metadata.contractors) {
          return this.metadata.contractors[contractorId]
        } else {
          return null
        }
      }

      this.getBidByContractorId = function (contractorId) {
        if (this.bids) {
          return this.bids[contractorId]
        } else {
          return null
        }
      }

      this.isDeclinedByContractorId = function (contractorId) {
        if (this.declinedContractors) {
          return this.declinedContractors.indexOf(contractorId) >= 0
        } else {
          return false
        }
      }

      this.isReviewedByContractorId = function (contractorId) {
        if (this.metadata && this.metadata.reviews) {
          return !!this.metadata.reviews[contractorId]
        } else {
          return null
        }
      }

      this.setCurrentStep = function (step) {
        this.poSubstatus = step
      }

      this.setBudgetRange = function (budgetRange) {
        this.summary.budgetRange = budgetRange
      }

      this.setBudgetDetails = function () {
        if (this.summary.budget) {
          let split = this.summary.budget.split('-')
          if (split.length === 2) {
            this.summary.budgetDetails = {
              min: split[0],
              max: split[1],
            }
          }
        }
      }

      this.isBudgetGreaterThan = function (minBudget) {
        if (this.summary.budgetDetails) {
          return parseInt(this.summary.budgetDetails.min) > minBudget
        } else {
          return false
        }
      }

      this.isLowBudgetValue = () =>
        this.summary.budgetDetails && !this.isBudgetGreaterThan(4999)
      this.isMidLowBudgetValue = () =>
        this.summary.budgetDetails &&
        this.isBudgetGreaterThan(4999) &&
        !this.isBudgetGreaterThan(19999)
      this.isMidHighBudgetValue = () =>
        this.summary.budgetDetails &&
        this.isBudgetGreaterThan(19999) &&
        !this.isBudgetGreaterThan(49999)
      this.isHighBudgetValue = () =>
        this.summary.budgetDetails && this.isBudgetGreaterThan(49999)

      this.dateOfFirstCommunicativeOptIn = function () {
        if (this.interestedContractorsWithContact.length) {
          let sortedByOptIn = this.interestedContractorsWithContact.sort(
            (co1, co2) =>
              new Date(co1.optedInAt) < new Date(co2.optedInAt) ? 0 : 1
          )
          return sortedByOptIn[0].optedInAt
        }
      }

      this.daysSinceFirstCommunicativeOptIn = () => {
        let communicativeDate = this.dateOfFirstCommunicativeOptIn()
        if (communicativeDate) {
          return Math.floor(
            (new Date() - new Date(communicativeDate)) / 1000 / 60 / 60 / 24
          )
        } else {
          return 0
        }
      }

      this.expectedDaysToBid = () => {
        if (this.isHighBudgetValue()) {
          return 20
        } else if (this.isMidHighBudgetValue()) {
          return 13
        } else if (this.isMidLowBudgetValue()) {
          return 11
        } else {
          return 8
        }
      }

      this.expectedDaysToHire = () => {
        if (this.isHighBudgetValue()) {
          return 40
        } else if (this.isMidHighBudgetValue()) {
          return 21
        } else if (this.isMidLowBudgetValue()) {
          return 18
        } else {
          return 14
        }
      }

      this.hasBids = () => this.bids && !angular.equals(this.bids, {})

      this.setProjectStage = function (projectStage) {
        this.summary.projectStage = projectStage
      }

      this.initAttachments = function (selectorId, callback) {
        angular.element('#' + selectorId).on('change', ($event) => {
          this.attachSelectedFiles($event.target.files).then(() => {
            if (callback) {
              callback()
            } else {
              trackServiceRequestsActionsService.attachProjectFiles(this.id)
            }
          })
        })
      }

      this.attachSelectedFiles = function (files) {
        this.isAttaching = true

        return projectAttachmentModel
          .attachV2({ projectId: this.projectId, files: files })
          .then((uploadedFiles) => {
            if (uploadedFiles.length) {
              if (!this.summary.attachments) {
                this.summary.attachments = []
              }

              this.summary.attachments =
                this.summary.attachments.concat(uploadedFiles)
            }

            this.isAttaching = false
          })
      }

      this.deleteAttachment = function ($index) {
        projectAttachmentModel
          .delete(this.projectId, this.summary.attachments[$index].id)
          .then(
            function () {
              this.summary.attachments.splice($index, 1)
            }.bind(this)
          )
      }

      this.hasAttachments = function () {
        return this.summary.attachments && this.summary.attachments.length
      }

      this.isInitialSubmission = function () {
        return this.poStatus === 'submitted'
      }

      this.userCreatedRecently = function (minutesAgo) {
        return (
          (new Date() - new Date(this.projectOwner.createdAt)) / 1000 / 60 <
          minutesAgo
        )
      }

      this.conciergePhone = function () {
        let phone

        if (
          this.concierge &&
          this.concierge.contactInfo.phone &&
          this.concierge.contactInfo.phone.value
        ) {
          phone = this.concierge.contactInfo.phone.value
        } else {
          phone = applicationConstants.support.phone
        }

        return phone ? phone.toString().replace(/\D/g, '') : null
      }

      this.setNotes = function (contractorId, notes, notesType) {
        return v2ServiceRequestModel.setNotes(
          this.id,
          contractorId,
          notes,
          notesType
        )
      }

      this.requestAdditionalContractor = function () {
        return v2ServiceRequestModel.requestAdditionalContractor(this.id).then(
          function (resp) {
            if (resp.success) {
              this.requestedContractors =
                resp.service_request.requested_contractors
            } else {
              // should probably inform the user?
            }
          }.bind(this)
        )
      }

      this.needsInfo = function () {
        return (
          !(this.summary.description && this.summary.description.length) &&
          !(this.summary.title && this.summary.title.length)
        )
      }

      this.isContractorHired = function (contractor) {
        return (
          this.outcome.hiredContractor &&
          contractor &&
          this.outcome.hiredContractor.id === contractor.id
        )
      }

      this.adminPostpone = function () {
        this.admin.status = 'postponed'
        this.postpone = true
        this.snooze = true
        this.open = false
        this.saveSummary()
      }

      this.prepForRedistribution = function () {
        return v2ServiceRequestModel.prepForRedistribution(this.id).then(
          function (res) {
            if (res.success) {
              this.admin.status = 'pending'

              this.createAdminComment({
                commentType: 'admin_service_request_prep_for_redistribution',
              })
            }
          }.bind(this)
        )
      }

      this.cancelDistribution = function () {
        return v2ServiceRequestModel.cancelDistribution(this.id).then(
          function (res) {
            if (res.success) {
              this.createAdminComment({
                commentType: 'admin_service_request_cancel_distribution',
              })
            }
          }.bind(this)
        )
      }

      this.sendEmailToContractors = function (contractorIds, message) {
        return this.sendAdminMessageToContractors(
          contractorIds,
          message,
          'email'
        )
      }

      this.sendSmstoContractors = function (contractorIds, message) {
        return this.sendAdminMessageToContractors(
          contractorIds,
          { body: message },
          'sms'
        )
      }

      this.sendAdminMessageToContractors = function (
        contractorIds,
        message,
        medium
      ) {
        return v2ServiceRequestModel.sendAdminMessageToContractors(
          this.id,
          contractorIds,
          message,
          medium
        )
      }

      this.cleanProjectOwnerPhone = function () {
        if (
          this.projectOwner &&
          this.projectOwner.contactInfo &&
          this.projectOwner.contactInfo.phone &&
          this.projectOwner.contactInfo.phone.value
        ) {
          this.projectOwner.contactInfo.phone.value =
            this.projectOwner.contactInfo.phone.value.replace(/\D/g, '')
        }
      }

      this.projectOwnerHasPhone = function () {
        return (
          this.projectOwner.contactInfo &&
          this.projectOwner.contactInfo.phone &&
          this.projectOwner.contactInfo.phone.value &&
          this.projectOwner.contactInfo.phone.value.length >= 10
        )
      }

      this.initPermitData = function () {
        this.permitData = new serviceRequestPermitDataInstance(this.id)
        return this.permitData.load()
      }

      this.hasRequestedBidsFromAllContractors = function () {
        let result = true
        this.activeContractors.forEach(function (contractor) {
          if (!contractor.bidRequestedAt) {
            result = false
          }
        })
        return result
      }

      this.validateHire = function (contractorId, isValid, hireType) {
        let connection = this.connections[contractorId]
        return v2ServiceRequestModel
          .validateHire(
            this.id,
            this.connections[contractorId].id,
            isValid,
            hireType
          )
          .then(function (resp) {
            if (resp.success) {
              let responseConnection =
                resp.serviceRequest.connections[contractorId]
              connection.poStatusValidatedAt =
                responseConnection.poStatusValidatedAt
              connection.coStatusValidatedAt =
                responseConnection.coStatusValidatedAt
            }

            return resp
          })
      }

      this.unArchive = function () {
        return v2ServiceRequestModel.unArchive(this.id)
      }

      this.isArchived = function () {
        return (
          [
            'archived',
            'won',
            'lost',
            'cancelled',
            'spam',
            'disqualified',
          ].indexOf(this.admin.status) > -1
        )
      }
      this.permitMatchNonBZHire = function () {
        return (
          this.summary.systemSubstatus &&
          this.summary.systemSubstatus === 'hired_non_bz_permit_match'
        )
      }

      this.recordPermitResearchOutcome = function (permitResearchOutcome) {
        return adminServiceRequestApi
          .updatePermitResearchOutcome(this.id, permitResearchOutcome)
          .then(() => {
            this.serviceRequest.admin.permitResearchOutcome =
              permitResearchOutcome
          })
      }

      this.markPermitMatchLoss = function (contractorId) {
        return v2ServiceRequestModel.markPermitMatchLoss(this.id, contractorId)
      }

      this.markAsSemConversion = function () {
        return v2ServiceRequestModel.markAsSemConversion(this.id)
      }

      this.isExclusive = function () {
        return this.expectedResponses === 1
      }

      this.matchProjectType = function () {
        return projectTypeModel
          .matchProjectType(this.summary.title)
          .then((response) => {
            if (response && response.success && response.projectType) {
              this.projectType = response.projectType
            } else {
              this.projectType = null
            }

            return this.projectType
          })
      }

      this.unreviewedContractors = function () {
        return reject(this.interestedContractorsWithContact, 'reviewed')
      }

      this.hasUnreviewedContractors = function () {
        return !isEmpty(this.unreviewedContractors())
      }
    }
  },
])
