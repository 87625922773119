/* global BZAPI, $ */
var MessagesAPI = function () {
  this.load_conversation = function (id) {
    return $.when($.get(this.conversations_api_path() + '/' + id))
  }

  this.mark_conversation_as_read = function (conversation_id) {
    return $.when(
      $.ajax({
        url:
          this.conversations_api_path() +
          '/' +
          conversation_id +
          '/mark_as_read',
        type: 'PUT',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.archive_conversation = function (conversation_id) {
    return $.when(
      $.ajax({
        url: this.conversations_api_path() + '/' + conversation_id + '/archive',
        type: 'PUT',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.unarchive_conversation = function (conversation_id) {
    return $.when(
      $.ajax({
        url:
          this.conversations_api_path() + '/' + conversation_id + '/unarchive',
        type: 'PUT',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.get_unread_conversation_count = function () {
    return $.when($.get(this.conversations_api_path() + '/unread_count'))
  }

  this.send_message = function (conversation_id, text) {
    return $.when(
      $.ajax({
        url: this.messages_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ conversation_id: conversation_id, text: text }),
        dataType: 'json',
      })
    )
  }

  this.create_conversation_with_message = function (
    project_id,
    recipient_id,
    subject,
    text
  ) {
    return $.when(
      $.ajax({
        url: this.messages_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          project_id: project_id,
          recipient_id: recipient_id,
          subject: subject,
          text: text,
        }),
        dataType: 'json',
      })
    )
  }
}

MessagesAPI.prototype = new BZAPI()
MessagesAPI.prototype.constructor = MessagesAPI
window.MessagesAPI = MessagesAPI
