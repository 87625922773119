import { flatten, values } from 'lodash'
import template from './contractor_project_types.html'

/* global bz_app, angular */
angular
  .module('bzProjectPreferencesModule')
  .component('contractorProjectTypes', {
    bindings: {
      taxonomy: '<',
      update: '&',
    },
    template,
    controller: [
      function () {
        this.$onInit = function () {
          this.groups = this.taxonomy.projectTypeGroups
          this.addBlankGroup()
          this.projectTypesOfferedByGroup = {}
        }

        this.$onChanges = function (changes) {
          if (changes && !changes.taxonomy.isFirstChange()) {
            this.groups = changes.taxonomy.currentValue.projectTypeGroups
            this.addBlankGroup()

            this.resetProjectTypesSelection()
            this.projectTypesOfferedByGroup = {}
          }
        }

        this.addBlankGroup = function () {
          if (this.groups[0].id === 1) {
            var blankProjectGroup = {
              id: 0,
              name: '',
              projectTypes: [],
              selected: false,
            }
            this.groups.unshift(blankProjectGroup)
          }
        }

        this.resetProjectTypesSelection = function () {
          angular.forEach(this.groups, function (projectTypeGroup) {
            projectTypeGroup.selected = false
            angular.forEach(
              projectTypeGroup.projectTypes,
              function (projectType) {
                projectType.selected = false
              }
            )
          })
        }

        this.updateGroupProjectTypesOffered = function (
          groupId,
          projectTypesOffered
        ) {
          if (groupId === 0) {
            return
          }

          this.projectTypesOfferedByGroup['group-' + groupId] =
            projectTypesOffered
          this.updateProjectTypesOffered()
        }

        this.updateProjectTypesOffered = function () {
          this.projectTypesOffered = flatten(
            values(this.projectTypesOfferedByGroup)
          )
          this.update({ projectTypesOffered: this.projectTypesOffered })
        }
      },
    ],
  })
