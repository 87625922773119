/* global angular */
angular.module('bzCommonUtilitiesModule').component('triggerClickBySelector', {
  bindings: {
    selector: '@',
  },
  controller: [
    '$element',
    function ($element) {
      this.$onInit = function () {
        $element.click(
          function () {
            angular.element(this.selector).click()
          }.bind(this)
        )
      }
    },
  ],
})
