/* global angular */
angular.module('bzProjectFormModule').component('budgetScreen', {
  bindings: {
    project: '=',
    onRedirect: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/budget'
      )
    },
  ],
  controller: [
    '$timeout',
    'budgetTypesModel',
    'projectFormScreenService',
    'trackProjectFormService',
    'projectFormService',
    'urlConstants',
    function (
      $timeout,
      budgetTypesModel,
      projectFormScreenService,
      trackProjectFormService,
      projectFormService,
      urlConstants
    ) {
      this.$onInit = function () {
        this.budgetOptions = budgetTypesModel.getAll(null, 'Please select one')
        this.isAllowedBudget = true
        this.isBudgetFieldDisabled = false
        this.continueButtonLabel = 'continue'
        this.trackedSemConversion = false

        projectFormScreenService.setScreenCallbacks.push(() => {
          projectFormScreenService.focusInputOnScreenShow(
            'budget',
            '.project-form-budget'
          )
        })
      }

      this.onBudgetSelected = function () {
        this.project.isPotentialLead = projectFormService.shouldBePotentialLead(
          this.project.expectedResponses,
          this.project.summary.budget
        )
          ? 1
          : 0

        if (
          this.project.summary.budget &&
          this.project.summary.budget !== 'private' &&
          projectFormService.parseBudget(this.project.summary.budget).max <=
            1000
        ) {
          this.processLowBudget()
        } else {
          this.processAcceptableBudget()
        }
      }

      this.processLowBudget = function () {
        this.isBudgetFieldDisabled = true
        this.continueButtonLabel = 'searching...'

        trackProjectFormService.budgetSelected({
          budget: this.project.summary.budget,
          budgetError: true,
        })

        $timeout(
          function () {
            this.isAllowedBudget = false
            this.isBudgetFieldDisabled = false
            this.continueButtonLabel = 'continue'

            if (this.project.id && this.project.open) {
              this.project.saveSummary().then(
                function () {
                  this.project.open = false
                }.bind(this)
              )
            }
          }.bind(this),
          2000
        )
      }

      this.processAcceptableBudget = function () {
        this.isAllowedBudget = true

        if (this.project.id && !this.project.open) {
          trackProjectFormService.budgetSelected({
            budget: this.project.summary.budget,
            budgetError: false,
          })
          this.project.saveSummary().then(
            function () {
              this.project.open = true
            }.bind(this)
          )
        }
      }

      this.showNextScreen = function () {
        if (
          !this.trackedSemConversion &&
          projectFormService.parseBudget(this.project.summary.budget).min >=
            50000 &&
          this.project.projectOwner.phone &&
          (typeof fbq !== 'undefined' || typeof gtag !== 'undefined')
        ) {
          this.trackedSemConversion = true
          trackProjectFormService.semConvert({
            id: this.project.id,
            budget: this.project.summary.budget,
            state: this.project.location.state,
          })
          this.project.markAsSemConversion()
        }

        if (projectFormScreenService.isCurrentScreen('budget')) {
          projectFormScreenService.showNextScreen()
        }
      }

      this.redirectToProject = function () {
        this.onRedirect()
      }

      this.answersUrl = () => {
        return urlConstants.answers.answersUrl
      }

      this.thumbtackUrl = () => {
        return (
          'https://www.thumbtack.com/k/' +
          this.thumbtackSlugForProjectType() +
          '/near-me/?zip_code=' +
          this.project.location.zipcode
        )
      }

      this.isFormVersion = function (formVersion) {
        return this.project.analytics.formVersion === formVersion
      }

      this.isForThumbtack = () => {
        return this.thumbtackSlugForProjectType() !== undefined
      }

      this.trackClickToThumbtack = () => {
        trackProjectFormService.sendToThumbtack({
          projectTypeId: this.project.projectType.id,
          zipcode: this.project.location.zipcode,
        })
      }

      this.thumbtackSlugForProjectType = function () {
        var slugLookup = {
          88: 'gutters',
          92: 'finish-carpentry',
          93: 'stairs-and-railings',
          167: 'gazebos',
          46: 'drywall-repair',
          91: 'cabinet-installation',
          101: 'patios',
          73: 'garage-addition',
          12: 'bathroom-remodeling',
          42: 'countertop-installation',
          20: 'structural-engineers',
          40: 'fences',
          37: 'decks',
          21: 'roofing',
          36: 'interior-painting',
          53: 'concrete-contractors',
          28: 'hardwood-floor-installation',
          56: 'above-ground-pool-installers',
          32: 'vinyl-siding-repair',
          11: 'kitchen-remodeling',
          13: 'basements',
          39: 'framing-contractors',
          70: 'water-damage',
          38: 'door-repair',
          60: 'demolition-contractors',
        }

        return slugLookup[this.project.projectType.id]
      }
    },
  ],
})
