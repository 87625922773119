bz_app.directive('viewProjectLink', function () {
  return {
    restrict: 'AE',
    replace: true,
    scope: {
      serviceRequestId: '@',
      isMobile: '@',
    },
    template:
      '<a href="/project/{{ serviceRequestId }}" class="conversation-project-link" target="_blank">' +
      '	View project' +
      '	<img ng-show="isMobile" ng-src="{{ urlConstants.s3.assetUrl }}chevron-right-orange.png" class="section-nav-right" />' +
      '	<i ng-if="!isMobile"  class="fas fa-external-link-alt"></i>' +
      '</a>',
    controller: [
      '$scope',
      '$element',
      'urlConstants',
      function ($scope, $element, urlConstants) {
        $scope.urlConstants = urlConstants
      },
    ],
  }
})
