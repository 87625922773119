/* global Track, isMobile */
const trackDataApplications = [
  function () {
    return {
      eventName: 'Data Application Action',
      track: function (action) {
        Track.send(this.eventName, this.defaultData({ action: action }))
      },

      bulkApplicationFormOpened: function () {
        Track.impersonation_check().send(this.eventName, {
          action: 'bulk data application form opened',
        })
      },

      propertyApplicationFormOpened: function (propertyId) {
        Track.impersonation_check().send(this.eventName, {
          action: 'property data application form opened',
          propertyId: propertyId,
        })
      },
    }
  },
]

export default trackDataApplications
