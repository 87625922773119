/* global angular */
import Geocoder from 'Vanilla/services/geocoder'

angular.module('bzProjectFormModule').component('locationScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/location'
      )
    },
  ],
  controller: [
    '$scope',
    '$timeout',
    'locationService',
    'urlService',
    'projectFormScreenService',
    'userLocationModel',
    'formValidationService',
    function (
      $scope,
      $timeout,
      locationService,
      urlService,
      projectFormScreenService,
      userLocationModel,
      formValidationService
    ) {
      this.$onInit = () => {
        this.resetErrors()
        this.geocoder = new Geocoder()
        this.getLocation = locationService.find_address
        this.getCityState = locationService.find_city
        this.setLocationByZipcode()

        projectFormScreenService.setScreenCallbacks.push(() => {
          projectFormScreenService.focusInputOnScreenShow(
            'location',
            '#project-form-street-address'
          )
        })
      }

      this.showNextScreen = () => {
        if (projectFormScreenService.isCurrentScreen('location')) {
          projectFormScreenService.showNextScreen()
        }
      }

      this.validateBeforeNextScreen = () => {
        this.resetErrors()
        let hasErrors = false

        if (
          !formValidationService.isValidZipCode(this.project.location.zipcode)
        ) {
          this.errors.zipcode = 'Please enter a valid job site zip code'
          angular.element('#project-form-zipcode').focus()
          hasErrors = true
        }

        if (
          !hasErrors &&
          projectFormScreenService.isCurrentScreen('location')
        ) {
          this.project.geocodedLatitude = null
          this.project.geocodedLongitude = null

          let query = this.geocoder.queryFromAddressParts(this.project.location)
          this.geocoder.geocode(query).then((result) => {
            if (
              this.geocoder.isServiceRequestMatchingGeocode(
                this.project,
                result
              )
            ) {
              this.geocoder.assignServiceRequestGeocodeResult(
                this.project,
                result
              )
            }
            projectFormScreenService.showNextScreen()
          })
        }
      }

      this.keyboardToNextScreen = ($event) => {
        if ($event.keyCode === 13) {
          $event.target.blur()

          $timeout(() => {
            this.validateBeforeNextScreen()
          }, 200)
        }
      }

      this.setLocationByZipcode = () => {
        if (this.project.location.zipcode) {
          this.geocoder
            .geocode(this.project.location.zipcode)
            .then((result) => {
              if (result) {
                this.geocoder.assignServiceRequestGeocodeResult(
                  this.project,
                  result
                )
                $scope.$applyAsync()
              }
            })
        }
      }

      this.onLocationSelected = ($item) => {
        if ($item) {
          this.project.location.streetAddress = $item.street

          let query = $item.street + ', ' + $item.city + ' ' + $item.state
          this.geocoder.geocode(query).then((result) => {
            if (result) {
              this.geocoder.assignServiceRequestGeocodeResult(
                this.project,
                result
              )
              $scope.$applyAsync()
            }
          })
        }
      }

      this.typeAheadTemplateUrl = () => {
        return urlService.templateUrl(
          '_components/components/project_multi_step_form/location_typeahead'
        )
      }

      this.resetErrors = () => {
        if (!this.errors) {
          this.errors = {}
        }

        this.errors.zipcode = null
        this.errors.streetAddress = null
      }

      this.setProjectLocation = (location) => {
        if (location.streetAddress) {
          this.project.location.streetAddress = location.streetAddress
        }

        if (location.zipcode) {
          this.project.location.zipcode = location.zipcode
        }

        if (location.city) {
          this.project.location.city = location.city
        }

        if (location.state) {
          this.project.location.state = location.state
        }
      }

      this.headerText = () => {
        if (
          this.project.analytics &&
          this.project.analytics.potentialLeadFormVersion
        ) {
          return 'Where is the project located?'
        } else {
          return 'Where do you need a contractor?'
        }
      }

      this.isFormVersion = (formVersion) => {
        return this.project.analytics.formVersion === formVersion
      }
    },
  ],
})
