import template from './select_dropdown.html'

export default {
  bindings: {
    themeClass: '@',
    options: '<',
    selectedValue: '<',
    onChange: '&',
    cannotBeBlank: '<',
  },
  template,
}
