/* global angular */
angular.module('bzSearchModule').directive('searchLocationInput', function () {
  return {
    restrict: 'AE',
    replace: true,
    template:
      '<input name="search[city]" ng-model="locationInput" ' +
      'ng-model-options="{ debounce: 300 }" ng-focus="location_focused()" ' +
      'uib-typeahead="address for address in getLocation($viewValue)" ' +
      'typeahead-editable="false" typeahead-on-select="locationSelected($item)" ' +
      'typeahead-loading="loadingLocations" class="modern-nav-search-form-field search-form-location-field" ' +
      'autocomplete="off" type="text" ' +
      'ng-class="{ loading: loadingLocationSearch, noResults: noResultsLocationSearch }">',
    link: function (scope, element, attr) {},
  }
})
