/* globals angular */
angular.module('bzCommonUtilitiesModule').factory('fileService', function () {
  return {
    fileType: function (description) {
      var fileType = ''

      if (!description) {
        // do nothing
      } else if (description.indexOf('photoshop') >= 0) {
        fileType = 'photoshop'
      } else if (description.indexOf('pdf') >= 0) {
        fileType = 'pdf'
      } else if (description.indexOf('zip') >= 0) {
        fileType = 'zip'
      } else if (description.indexOf('wordprocess') >= 0) {
        fileType = 'document'
      } else if (description.indexOf('spreadsheet') >= 0) {
        fileType = 'spreadsheet'
      } else if (description.indexOf('mp4') >= 0) {
        fileType = 'video'
      }

      return fileType
    },
    isFileImage: function (description) {
      return (
        description &&
        description.indexOf('image') >= 0 &&
        description.indexOf('photoshop') === -1 &&
        description.indexOf('svg') === -1
      )
    },
    knownFileType: function (description) {
      return this.fileType(description) ? true : false
    },
    fileTextIcon: function (fileName) {
      var text = 'File'

      if (fileName) {
        var fileText = fileName.split('.').pop()

        if (fileText) {
          text = fileText
        }
      }

      return text
    },
    iconByFileType: function (description) {
      var fileType = this.fileType(description)

      return fileType ? fileType + '-icon' : ''
    },
  }
})
