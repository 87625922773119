angular.module('bzProjectLabelsModule').factory('newProjectLabelModalService', [
  '$uibModal',
  'applicationConstants',
  function ($uibModal, applicationConstants) {
    return {
      show: function () {
        var modalInstance = $uibModal.open({
          templateUrl:
            '/assets/modules/project_labels/new_project_label_modal.html?v=' +
            applicationConstants.templateVersion,
          controller: 'NewProjectLabelModalCtrl',
          windowClass: 'modal-modern',
        })

        return modalInstance
      },
    }
  },
])
