// TD: once wepack goes in replace these with ramda, or lodash
angular.module('bzCommonUtilitiesModule').factory('arrayService', [
  function () {
    return {
      findLast: function (list, predicate) {
        var result
        for (var i = list.length - 1; i >= 0; i--) {
          if (predicate(list[i])) {
            result = list[i]
            break
          }
        }

        return result
      },
      last: function (list) {
        return list[list.length - 1]
      },
    }
  },
])
