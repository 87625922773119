angular.module('bzVarnishModule').controller('VarnishCtrl', [
  '$scope',
  '$http',
  function ($scope, $http) {
    // A way for admins to refresh page's varnish contents
    $scope.button_label = 'Refresh'
    $scope.purgeContents = function () {
      $scope.button_label = 'Please wait...'
      $http.patch('/varnish/refresh?path=' + location.pathname).then(
        function () {
          $scope.button_label = 'Refresh'
          location.reload()
        },
        function () {
          $scope.button_label = 'Try again'
        }
      )
    }
  },
])
