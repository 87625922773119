/* global mixpanel, gtag, UserData, console, LocationAPI, fbq	*/

var Track = function () {}

Track.init = function () {
  var locationApi = new LocationAPI()

  locationApi.blocked_from_analytics().then(function (response) {
    if (response && response.success) {
      Track.blockedIpAddress = response.blocked
    }
  })

  Track.saveExternalActionCookie()

  if (!Track.isBlocked()) {
    var userEmail = UserData.user_email()
    if (userEmail && userEmail.length) {
      setTimeout(function () {
        mixpanel.identify(userEmail)
      }, 200)
    }
  }
}

Track.alias = function (key) {
  if (key) {
    mixpanel.alias(key)
  }
}

Track.saveExternalActionCookie = function () {
  var externalAction = getURLParameter('external_action')
  if (document.location.search.indexOf('external_action') > -1) {
    var date = new Date()
    var milliToExpire = 60 * 60 * 1000
    date.setTime(date.getTime() + milliToExpire) //keep for 1 hour
    $.cookie('bz_track_external_action', externalAction, { expires: date })
  }
}

Track.initOnLoad = function () {
  if (document.readyState === 'complete') {
    Track.init()
  } else if (window.addEventListener) {
    window.addEventListener('load', Track.init.bind(this), false)
  } else if (window.attachEvent) {
    window.attachEvent('onload', Track.init.bind(this))
  }
}

Track.blockedIpAddress = false

Track.send = function (title, data) {
  Track.saveExternalActionCookie()
  if (!Track.isBlocked()) {
    data.external_action = $.cookie('bz_track_external_action')
    mixpanel.track(title, data)
    Track.log({ title: title, data: data })
  }
}

Track.send_form = function (selector, title, data) {
  if (!Track.isBlocked()) {
    data.external_action = $.cookie('bz_track_external_action')
    mixpanel.track_forms(selector, title, data)
    Track.log({ title: title, data: data, type: 'form' })
  }
}

Track.send_form_dynamic = function (selector, title, data_callback) {
  if (!Track.isBlocked()) {
    mixpanel.track_forms(selector, title, data_callback)
    Track.log({ title: title, type: 'form dynamic' })
  }
}

Track.send_link = function (selector, title, data) {
  if (!Track.isBlocked()) {
    data.external_action = $.cookie('bz_track_external_action')
    mixpanel.track_links(selector, title, data)
    Track.log({ title: title, data: data, type: 'link' })
  }
}

Track.send_callback = function (title, data, callback) {
  Track.saveExternalActionCookie()

  if (Track.isBlocked()) {
    if (callback) {
      callback()
    }
  } else {
    data.external_action = $.cookie('bz_track_external_action')
    if (UserData.is_production_environment()) {
      mixpanel.track(title, data, callback)
    } else {
      Track.log({ title: title, data: data })
      if (callback) {
        callback()
      }
    }
  }
}

Track.sendFbCustom = function (eventName) {
  if (!Track.isBlocked() && typeof fbq !== 'undefined') {
    fbq('trackCustom', eventName)
    Track.log({ eventName: eventName, type: 'facebook custom event' })
  }
}

Track.sendFb = function (eventName) {
  if (!Track.isBlocked() && typeof fbq !== 'undefined') {
    fbq('track', eventName, { currency: 'USD', value: 0 })
    Track.log({ eventName: eventName, type: 'facebook event' })
  }
}

Track.semConversionGa = function (gaConversionId, transactionId) {
  if (!Track.isBlocked() && typeof gtag !== 'undefined') {
    gtag('event', 'conversion', {
      send_to: gaConversionId,
      transaction_id: transactionId,
    })
  }
}

Track.sendGa = function (category, action, label, extraMetaData = {}) {
  if (!Track.isBlocked() && typeof gtag !== 'undefined') {
    gtag('event', category, { action: action, label: label, ...extraMetaData })
    Track.log({
      category: category,
      action: action,
      label: label,
      type: 'google event',
    })
  }
}

Track.sendBing = (category, action, label, value) => {
  window.uetq = window.uetq || []

  if (!Track.isBlocked() && typeof window.uetq !== 'undefined') {
    window.uetq.push({
      ec: category,
      ea: action,
      el: label || '',
      ev: value || 0,
    })
    Track.log({
      category: category,
      action: action,
      label: label,
      value: value,
      type: 'bing event',
    })
  }
}

Track.isBot = function () {
  var bots = ['bot']
  var user_agent =
    navigator && navigator.userAgent ? navigator.userAgent.toLowerCase() : ''
  var is_bot = false

  bots.forEach(function (bot) {
    if (user_agent.indexOf(bot) >= 0) {
      is_bot = true
    }
  })

  return is_bot
}

Track.log = function (data) {
  if (!UserData.is_production_environment()) {
    /*eslint-disable */
    console.log(data)
    /*eslint-enable */
  }
}

Track.isBlocked = function () {
  if (
    (Track.blockedIpAddress && UserData.is_production_environment()) ||
    Track.isBot()
  ) {
    return true
  } else {
    return false
  }
}

Track.impersonation_check = function () {
  if (!UserData.current_staff_user() || !UserData.is_production_environment()) {
    return Track
  } else {
    // add empty methods as required
    return {
      send: function () {},
      send_link: function () {},
    }
  }
}

Track.initOnLoad()

window.Track = Track
