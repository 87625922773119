/* global angular */
import { get } from 'lodash'

angular.module('bzProjectFormModule').component('descriptionScreen', {
  bindings: {
    project: '=',
    onRedirect: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/description'
      )
    },
  ],
  controller: [
    'trackProjectFormService',
    'projectFormScreenService',
    '$timeout',
    'urlConstants',
    function (
      trackProjectFormService,
      projectFormScreenService,
      $timeout,
      urlConstants
    ) {
      const lowBudgetProjetTypeIDs = [
        22, 24, 26, 28, 30, 40, 44, 53, 68, 81, 82, 88, 91, 94,
      ]
      this.$onInit = function () {
        this.continueButtonLabel = 'continue'
        this.clearError()

        projectFormScreenService.setScreenCallbacks.push(() => {
          projectFormScreenService.focusInputOnScreenShow(
            'description',
            '.project-form-description'
          )
        })

        $timeout(() => {
          this.project.initAttachments(
            'project-form-attachment-selector',
            () => {
              trackProjectFormService.attachFiles(this.project.analytics)
            }
          )
        }, 250)
      }

      this.continue = function () {
        if (projectFormScreenService.isCurrentScreen('description')) {
          if (
            !this.project.projectOwner.isNew ||
            this.project.projectOwner.password
          ) {
            this.continueButtonLabel = 'submitting...'
            this.project.analytics.lastScreenViewed = 'project-page'

            this.project.saveSummary().then((response) => {
              if (response && response.success) {
                trackProjectFormService.complete(
                  angular.extend(
                    {
                      projectTypeGroupId: this.project.projectType
                        ? this.project.projectType.projectTypeGroupId
                        : null,
                      title: this.project.summary.title,
                    },
                    this.project.analytics
                  ),
                  () => {
                    this.redirectToProject()
                  }
                )
              } else {
                trackProjectFormService.failedToComplete(
                  {
                    serviceRequestId: this.project.id,
                    title: this.project.summary.title,
                  },
                  () => {
                    this.redirectToProject()
                  }
                )
              }
            })

            // fallback if there's issues with processing final step, to redirect to project page
            $timeout(() => {
              this.redirectToProject()
            }, 10000)
          } else {
            projectFormScreenService.showNextScreen()
          }
        }
      }

      this.validateDescriptionBeforeContinue = function () {
        this.clearError()
        let invalidDesc = false

        if (this.missingRequiredDescription()) {
          this.error = 'Please enter at least 50 characters'
          invalidDesc = true
        }
        if (
          !invalidDesc &&
          projectFormScreenService.isCurrentScreen('description')
        ) {
          this.continue()
        }
      }

      this.clearError = () => (this.error = false)

      this.redirectToProject = function () {
        this.onRedirect()
      }

      this.headerText = function () {
        return 'Please give a brief description of your project.'
      }

      this.missingRequiredDescription = function () {
        return (
          this.project.summary.description.length < 50 &&
          (this.project.summary.budget == '1000-5000' ||
            (!this.project.summary.budget &&
              lowBudgetProjetTypeIDs.includes(
                get(this.project, ['projectType', 'id'])
              )))
        )
      }

      this.termsLink = function () {
        return urlConstants.site.tosURL
      }
    },
  ],
})
