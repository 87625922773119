import template from './generic_banner.html'

const genericBanner = {
  restrict: 'A',
  controllerAs: '$ctrl',
  scope: {},
  transclude: true,
  template,
  controller: function () {},
}

export default genericBanner
