/* global angular */
angular.module('bzProjectFormModule').component('attachmentsGrid', {
  bindings: {
    attachments: '=?',
    editable: '=?',
    scrollable: '<',
    imageTile: '<',
    isUploadAllowed: '<',
    uploadButtonText: '<',
    onUpload: '&',
    removeAttachment: '&',
    onClick: '&',
    imageAttachmentsAreLocked: '<?',
    fileAttachmentsAreLocked: '<?',
    showError: '&?',
  },
  transclude: {
    addMoreButton: '?addMoreButton',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('_components/components/attachments_grid')
    },
  ],
  controller: [
    'urlService',
    'fileService',
    function (urlService, fileService) {
      this.$onInit = function () {
        this.files = undefined
        this.fileService = fileService
        this.uploadButtonText = this.uploadButtonText || 'ADD MORE'
        this.fileUploadId = Math.random().toString().slice(2)

        if (!this.scrollable) {
          this.scrollable = false
        }

        if (!this.imageTile) {
          this.imageTile = false
        }
      }

      this.uploadFiles = (files) => {
        if (this.onUpload) {
          this.onUpload({ files: files })
        }
      }

      this.largeImgUrl = function (attachment) {
        return urlService.imgCropUrl(attachment.cloudfrontSlug, 'high_1000')
      }

      this.lockedFileImgUrl = function (attachment) {
        return urlService.imgCropUrl(attachment.cloudfrontSlug, 'square_200')
      }

      this.tileImgUrl = function (attachment) {
        return urlService.imgCropUrl(attachment.cloudfrontSlug, 'square_150')
      }

      this.smallImgUrl = function (attachment) {
        return urlService.imgCropUrl(attachment.cloudfrontSlug, 'square_60')
      }

      this.isAttachmentLocked = (attachment) => {
        return (
          (this.imageAttachmentsAreLocked &&
            this.fileService.isFileImage(attachment.description)) ||
          (this.fileAttachmentsAreLocked &&
            !this.fileService.isFileImage(attachment.description))
        )
      }

      this.onClickAttachment = (attachment) => {
        if (this.isAttachmentLocked(attachment)) {
          attachment.hasError = true
          this.showError({ message: 'File available after opt-in*' })
        }
      }
    },
  ],
})
