import leadModel from './models/lead_model'
import visitorsModel from './models/visitors_model'
import referralAgreementModel from './models/referral_agreement_model'
import referralFeeAcceptanceModel from './models/referral_fee_acceptance_model'

angular
  .module('apiModule', [])
  .factory('leadModel', leadModel)
  .factory('visitorsModel', visitorsModel)
  .factory('referralAgreementModel', referralAgreementModel)
  .factory('referralFeeAcceptanceModel', referralFeeAcceptanceModel)
