/* global angular, Track */
angular.module('bzAnalyticsModule').directive('trackContractorUpgrade', [
  function () {
    return {
      restrict: 'A',
      controllerAs: '$ctrl',
      scope: {},
      bindToController: {
        referralSource: '@',
      },
      controller: [
        '$attrs',
        function ($attrs) {
          this.$onInit = function () {
            Track.send_link('#' + $attrs.id, 'Contractor Action', {
              action: 'upgrade button clicked',
              referral_source: this.referralSource,
            })
          }
        },
      ],
    }
  },
])
