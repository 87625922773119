/* global bz_app, google */
bz_app.component('contractorLocationMap', {
  bindings: {
    latitude: '<',
    longitude: '<',
    zipcode: '<',
    radius: '<',
    delayLoad: '<',
    initByZipcode: '<',
  },
  controller: [
    'locationService',
    'mapConfigService',
    '$timeout',
    function (locationService, mapConfigService, $timeout) {
      this.$onInit = function () {
        if (this.delayLoad) {
          $timeout(
            function () {
              this.initMap()
            }.bind(this),
            100
          )
        } else {
          this.initMap()
        }
      }

      this.initMap = function () {
        var mapOptions = mapConfigService.disableDefaultOptions()
        mapOptions.center = new google.maps.LatLng(
          this.latitude,
          this.longitude
        )
        this.map = new google.maps.Map(
          document.getElementById('contractor-location-map'),
          mapOptions
        )
        this.marker = new google.maps.Marker({
          position: mapOptions.center,
          map: this.map,
          title: this.zipcode,
        })

        this.circle = new google.maps.Circle({
          strokeColor: '#0000FF',
          strokeOpacity: 0,
          strokeWeight: 0,
          fillColor: '#0000FF',
          fillOpacity: 0.2,
          map: this.map,
          center: mapOptions.center,
          radius: (this.radius || 25) * 1609.34,
        })

        this.map.fitBounds(this.circle.getBounds())

        if (this.initByZipcode) {
          this.updateMapLocation(this.zipcode)
        }
      }

      this.$onChanges = function (changes) {
        if (changes.radius && !changes.radius.isFirstChange()) {
          changes.radius.currentValue = Math.max(changes.radius.currentValue, 0)
          if (
            changes.radius.currentValue &&
            changes.radius.currentValue !== changes.radius.previousValue
          ) {
            this.updateMapRadius(changes.radius.currentValue)
          }
        }
        if (changes.zipcode) {
          if (
            changes.zipcode.currentValue !== changes.zipcode.previousValue &&
            changes.zipcode.currentValue.length === 5
          ) {
            this.updateMapLocation(changes.zipcode.currentValue)
          }
        }
      }

      this.updateMapRadius = function (radius) {
        this.circle.setRadius(radius * 1609.34)
        this.map.fitBounds(this.circle.getBounds())
      }

      this.updateMapLocation = function (zipcode) {
        locationService.find_details(zipcode).then(
          function (data) {
            if (this.checkValidLatLng(data)) {
              this.marker.setPosition(
                new google.maps.LatLng(data.lat, data.lng)
              )
              this.circle.setCenter(this.marker.getPosition())
              this.map.fitBounds(this.circle.getBounds())
            }
          }.bind(this)
        )
      }

      this.checkValidLatLng = function (data) {
        return (
          data.lat &&
          data.lng &&
          data.lat > 18 &&
          data.lat < 75 &&
          data.lng > -165 &&
          data.lng < -60
        )
      }
    },
  ],
})
