const ADMIN_SERVICE_REQUEST_ROUTE = '/api/v3/admin/service_request'

export default class AdminServiceRequestApi {
  constructor(httpClient) {
    this.client = httpClient
  }

  updatePermitResearchOutcome(serviceRequestId, permitResearchOutcome) {
    return this.client.post(
      `${ADMIN_SERVICE_REQUEST_ROUTE}/update_permit_research_outcome`,
      {
        serviceRequestId,
        permitResearchOutcome,
      }
    )
  }
}
