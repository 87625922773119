angular
  .module('bzPotentialLeadFormModule')
  .controller('PotentialLeadFormModalCtrl', [
    '$uibModalInstance',
    'contractor',
    'projectFormData',
    function ($uibModalInstance, contractor, projectFormData) {
      this.$onInit = function () {
        this.contractor = contractor
        projectFormData.invitedContractors[this.contractor.nid] = true
      }

      this.dismissModal = function () {
        return $uibModalInstance.dismiss('cancel')
      }
    },
  ])
