/* global angular */
angular
  .module('bzProjectPreferencesModule')
  .controller('ProjectPreferencesModalCtrl', [
    '$uibModalInstance',
    'inputData',
    'trackProjectPreferencesService',
    'urlConstants',
    function (
      $uibModalInstance,
      inputData,
      trackProjectPreferencesService,
      urlConstants
    ) {
      this.$onInit = function () {
        this.data = inputData
      }

      this.ok = function () {
        trackProjectPreferencesService.submitModal(
          this.data.contractor.analytics,
          function () {
            $uibModalInstance.close('ok')
            $uibModalInstance.closed.then(this.onClose)
          }.bind(this)
        )
      }

      this.cancel = function () {
        trackProjectPreferencesService.exitModal(
          this.data.contractor.analytics,
          function () {
            $uibModalInstance.dismiss('cancel')
          }.bind(this)
        )
      }

      this.onClose = function () {
        if (location.pathname === urlConstants.contractor.servicesURL) {
          /* eslint-disable */
          document.location = document.location
          /* eslint-enable */
        }
      }
    },
  ])
