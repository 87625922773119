bz_app.filter('orderObjectBy', function () {
  return function (input, attribute, direction, type) {
    if (!angular.isObject(input)) return input
    var array = []

    for (var objectKey in input) {
      array.push(input[objectKey])
    }

    if (type === 'string') {
      array.sort(function (a, b) {
        if (direction === 'desc') {
          return a[attribute].toLowerCase() < b[attribute].toLowerCase()
            ? 1
            : -1
        } else {
          return a[attribute].toLowerCase() > b[attribute].toLowerCase()
            ? 1
            : -1
        }
      })
    } else if (type === 'date') {
      array.sort(function (a, b) {
        var dateA = new Date(a[attribute])
        var dateB = new Date(b[attribute])

        if (direction === 'desc') {
          return dateA < dateB ? 1 : -1
        } else {
          return dateA > dateB ? 1 : -1
        }
      })
    } else {
      array.sort(function (a, b) {
        a = parseInt(a[attribute])
        b = parseInt(b[attribute])
        return direction === 'asc' ? a - b : b - a
      })
    }

    return array
    /*var filtered = [];
		angular.forEach(input, function(item) {
			filtered.push(item);
		});
		filtered.sort(function (a, b) {
			return (a[attribute].toLowerCase() > b[attribute].toLowerCase() ? 1 : -1);
		});
		if(direction != 'asc') filtered.reverse();
		return filtered;*/
  }
})
