/* global google, angular  */
angular.module('bzMapModule').component('basicMap', {
  bindings: {
    address: '@',
  },
  template: '<div ng-attr-id="{{$ctrl.mapId}}"></div>',
  controller: [
    '$attrs',
    'locationService',
    'mapConfigService',
    function ($attrs, locationService, mapConfigService) {
      this.$onInit = function () {
        this.mapId = $attrs.mapId

        locationService.find_details(this.address).then(
          function (location) {
            var options = mapConfigService.disableDefaultOptions()
            options.zoom = 13
            options.center = new google.maps.LatLng(location.lat, location.lng)

            // create instance of map (use the ID of the directive declaraction attribute)
            var map = mapConfigService.mapById($attrs.mapId, options)

            // add marker at the center of the map
            mapConfigService.marker({
              position: options.center,
              map: map,
              title: this.address,
            })

            // on window resize, reposition center of map
            google.maps.event.addDomListener(window, 'resize', function () {
              map.setCenter(options.center)
            })
          }.bind(this)
        )
      }
    },
  ],
})
