/* global bz_app */
bz_app.factory('denyWinModalService', [
  'modalScreenService',
  function (modalScreenService) {
    return {
      openModal: function (lead) {
        return modalScreenService.initDetached({
          controller: 'DenyWinModalCtrl',
          templateUrl: 'leads/deny_win_modal',
          windowClass: 'v3-modal-simple deny-win-modal',
          inputData: {
            lead: lead,
          },
        })
      },
    }
  },
])
