/* global bz_app, Track, angular, isMobile */
export default [
  '$window',
  function ($window) {
    return {
      title: 'Lead Action',
      defaultData: function (lead, input) {
        return angular.extend(
          {
            action: input.action ? input.action : '',
            service_request_id: lead.project.id,
            platform: isMobile.check() ? 'mobile' : 'desktop',
            contractor_id: lead.contractor.id,
            lead_referer: this.leadReferer(),
          },
          input
        )
      },
      leadReferer: function () {
        if (document.location.search.indexOf('type=email') >= 0) {
          return 'email'
        } else if (document.location.pathname.indexOf('/job/') >= 0) {
          return 'sms'
        } else {
          return $window.isMobile.check() ? 'mobile_web' : 'web'
        }
      },
      joinWaitList: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'join wait list' })
        )
      },
      trackPhoneLink: function (lead, data) {
        Track.send(
          this.title,
          this.defaultData(
            lead,
            angular.extend({ action: 'call homeowner' }, data)
          )
        )
      },
      nextStepsCloseScreen: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'view next steps close screen' })
        )
      },
      nextStepsCloseScreenCall: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'next steps close screen call' })
        )
      },
      nextStepsCloseScreenMessage: function (lead, medium) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: `next steps close screen set ${medium} message`,
          })
        )
      },
      nextStepsCloseScreenLater: function (lead) {
        const action = 'next steps close screen later'
        Track.send(this.title, this.defaultData(lead, { action }))
      },
      nextStepsCloseScreenDismiss: function (lead) {
        const action = 'dismiss next steps close screen'
        Track.send(this.title, this.defaultData(lead, { action }))
      },
      viewAreYouSureUnsavedMessage: function (lead, medium) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: `view are you sure popup ${medium}`,
          })
        )
      },
      view: function (lead, analytics) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'view lead',
            referralProgramOptIn:
              lead.contractor.billing &&
              lead.contractor.billing.onCommissionPlan,
            responded: lead.respondedAt !== null,
            claimed: lead.contractor.claimed,
            leadFull: !lead.openForContractor,
            isBlocked: lead.hasBlocks(),
            reasonBlocked: lead.mainBlock() ? lead.mainBlock().blockType : null,
            ctaLabel: analytics.ctaLabel,
          })
        )
      },
      claimModalShown: function (lead, interested) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'claim modal shown',
            interested: interested,
          })
        )
      },
      unclaimedIntroModalShown: function (lead, interested) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'unclaimed intro modal shown',
            interested: interested,
          })
        )
      },
      responseRecorded: function (lead, interested, analytics, callback) {
        Track.send_callback(
          this.title,
          this.defaultData(lead, {
            action: 'response recorded',
            interested: interested,
            referralProgramOptIn:
              lead.contractor.billing &&
              lead.contractor.billing.onCommissionPlan,
            contractorRecentlyClaimed: analytics.newContractorClaim,
            ctaLabel: analytics.ctaLabel,
          }),
          callback
        )
      },
      notInterestedSurveyInitiated: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'modal 1 opened',
            state: 'not interested survey',
          })
        )
      },
      notInterestedExplanationSubmitted: function (lead, text) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'modal 1 completed',
            state: 'not interested survey',
            explanation: text,
          })
        )
      },
      messageSent: function (lead, conversation, medium) {
        let data = this.defaultData(lead, { action: `${medium} message sent` })
        data.conversation_id = conversation.id
        data.user_role = 'contractor'
        data.user_id = lead.contractor.user.userId

        Track.send('Messaging Action', data)
      },
      bidFormViewed: function (lead, referralSource) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'bid form viewed',
            referralSource: referralSource,
          })
        )
      },
      bidCreated: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'bid created' })
        )
      },
      bidUpdated: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'bid updated' })
        )
      },
      quickMessages: function (lead, messageType) {
        let data = this.defaultData(lead, { action: 'quick message clicked' })
        data.message_type = messageType
        Track.send(this.title, data)
      },
      addMessageAttachmentClicked: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'add message attachment' })
        )
      },
      messageAttachmentAdded: function (lead, fileCount) {
        let data = this.defaultData(lead, {
          action: 'message attachment added',
        })
        data.file_count = fileCount

        Track.send(this.title, data)
      },
      shareFilesClicked: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'share files clicked' })
        )
      },
      filesAdded: function (lead, fileCount) {
        let data = this.defaultData(lead, { action: 'files added' })
        data.file_count = fileCount
        Track.send(this.title, data)
      },
      tabClicked: function (lead, tab) {
        let data = this.defaultData(lead, { action: 'tab clicked' })
        data.tab = tab
        Track.send(this.title, data)
      },
      initiateWin: function (lead, referralSource) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'initiate win',
            referralSource: referralSource,
          })
        )
      },
      markHired: function (lead) {
        Track.send(this.title, this.defaultData(lead, { action: 'mark hired' }))
      },
      undoHired: function (lead) {
        Track.send(this.title, this.defaultData(lead, { action: 'undo hired' }))
      },
      noteSubmitted: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'note submitted' })
        )
      },
      mapLinkClicked: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, {
            action: 'map link clicked',
            contractorId: lead.contractor.id,
          })
        )
      },
      summaryHeaderClicked: function (lead) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'summary header clicked' })
        )
      },
      mapPanorama: function (lead, action) {
        Track.send(
          this.title,
          this.defaultData(lead, { action: 'map panorama ' + action })
        )
      },
      bidIntent: function (lead, data) {
        Track.send(this.title, this.defaultData(lead, { action: data.action }))
      },
      openStaleLeadModal: function (originLead) {
        Track.send('Stale Lead Flow Action', {
          action: 'view',
          contractorId: originLead.contractor.id,
          originServiceRequest: originLead.project.id,
        })
      },
      staleLead: function (staleLead, action, params = {}) {
        const title = 'Stale Lead Flow Action'
        const defaultParams = {
          action,
          contractorId: staleLead.contractorId,
          serviceRequestId: staleLead.serviceRequest.id,
          platform: isMobile.check() ? 'mobile' : 'desktop',
        }

        Track.send(title, Object.assign(defaultParams, params))
      },
    }
  },
]
