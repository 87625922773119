/* global Track, UserData */
var InvoicesTrack = function () {
  return {
    contractorEvent: 'Payments CO',
    projectOwnerEvent: 'Payments PO',
    eventName: function () {
      return UserData.is_contractor()
        ? this.contractorEvent
        : this.projectOwnerEvent
    },
    view: function (invoice) {
      Track.impersonation_check().send(
        this.eventName(),
        this.defaultData({
          action: 'viewed invoice',
          invoiceId: invoice.id,
        })
      )
    },
    send: function (invoice) {
      Track.impersonation_check().send(
        this.eventName(),
        this.defaultData({
          action: 'sent invoice',
          invoiceId: invoice.id,
          contractorId: invoice.creator_contractor_id,
        })
      )
    },
    defaultData: function (input) {
      var platform = isMobile.check() ? 'mobile' : 'desktop'
      var data = {
        action: input.action,
        platform: platform,
        user_id: UserData.user_id(),
        user_type: UserData.user_role(),
      }

      if (input.invoiceId !== undefined) data.invoice_id = input.invoiceId
      if (input.contractorId !== undefined)
        data.contractor_id = input.contractorId
      if (input.ad_type !== undefined) data.ad_type = input.ad_type
      if (input.content !== undefined) data.content = input.content

      return data
    },
  }
}

window.InvoicesTrack = InvoicesTrack
