/* global angular */
angular.module('bzAnalyticsModule').factory('analyticsEventsModel', [
  '$http',
  'urlConstants',
  'modelService',
  function ($http, urlConstants, modelService) {
    return {
      apiAnalyticsEventsPath: urlConstants.api.v3.path + 'analytics_events',

      create: function (params) {
        return $http.post(
          this.apiAnalyticsEventsPath,
          modelService.toUnderscore(params)
        )
      },
    }
  },
])
