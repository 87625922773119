/* global bz_app, UserData, angular, isMobile */
angular
  .module('bzCommonUtilitiesModule')
  .factory('modalScreenNavigationService', [
    function () {
      return {
        screens: [],
        currentScreen: null,
        prevScreen: null,
        screenErrorsOn: [],
        setScreenCallbacks: [],
        init: function (options) {
          if (options.screens) {
            this.screens = options.screens
          }

          if (options.initialScreen) {
            this.setScreen(options.initialScreen)
          }

          return this
        },
        setScreen: function (screen) {
          this.prevScreen = this.prevScreenOf(screen)
          this.currentScreen = screen

          if (this.setScreenCallbacks.length) {
            angular.forEach(this.setScreenCallbacks, (setScreenCallback) => {
              setScreenCallback()
            })
          }
        },
        isCurrentScreen: function (screen) {
          return this.currentScreen === screen
        },
        isFirstScreen: function () {
          return this.screens.indexOf(this.currentScreen) === 0 ? true : false
        },
        isLastScreen: function () {
          return this.screens.indexOf(this.currentScreen) ===
            this.screens.length - 1
            ? true
            : false
        },
        hasScreen: function (screen) {
          return this.screens.indexOf(screen) >= 0
        },
        nextScreenOf: function (screen) {
          return this.screens.indexOf(screen) < this.screens.length - 1
            ? this.screens[this.screens.indexOf(screen) + 1]
            : null
        },
        prevScreenOf: function (screen) {
          return this.screens.indexOf(screen) > 0
            ? this.screens[this.screens.indexOf(screen) - 1]
            : null
        },
        addScreen: function (screen, index) {
          if (!this.hasScreen(screen)) {
            this.screens.splice(index, 0, screen)
          }
        },
        removeScreen: function (screen) {
          if (screen && this.screens.indexOf(screen) >= 0) {
            this.screens.splice(this.screens.indexOf(screen), 1)
          }
        },
        showNextScreen: function () {
          if (this.currentScreen) {
            this.setScreen(this.nextScreenOf(this.currentScreen))
            angular.element('html, body').animate({ scrollTop: 0 }, 200)
          }
        },
        showPrevScreen: function () {
          if (this.currentScreen && this.prevScreen) {
            this.setScreen(this.prevScreenOf(this.currentScreen))
            angular.element('html, body').animate({ scrollTop: 0 }, 200)
          }
        },
        isMobile: function () {
          return isMobile.check({ phoneOnly: true })
        },
      }
    },
  ])
