/* global bz_app, angular */
angular
  .module('bzCommonUtilitiesModule')
  .factory('attributeConversionService', [
    function () {
      return {
        underscoreToCamelDeepAttributes: function (input) {
          var data = angular.copy(input)

          for (var property in data) {
            if (data.hasOwnProperty(property)) {
              data[property] = this.underscoreToCamelObjectAttributes(
                data[property]
              )
              data[property] = this.underscoreToCamelArrayAttributes(
                data[property]
              )

              if (
                angular.isObject(data[property]) ||
                angular.isArray(data[property])
              ) {
                data[property] = this.underscoreToCamelDeepAttributes(
                  data[property]
                )
              }

              // convert current property, now that all children are done
              var convertedProperty = this.underscoreToCamel(property)
              data[convertedProperty] = data[property]

              if (convertedProperty !== property) {
                delete data[property]
              }
            }
          }

          return data
        },
        underscoreToCamelObjectAttributes: function (object) {
          if (angular.isObject(object) && !angular.isArray(object)) {
            return this.underscoreToCamelAttributes(object, false)
          } else {
            return object
          }
        },
        underscoreToCamelArrayAttributes: function (array) {
          if (angular.isArray(array)) {
            angular.forEach(
              array,
              function (item, index) {
                array[index] = this.underscoreToCamelDeepAttributes(item)
              }.bind(this)
            )

            return array
          } else {
            return array
          }
        },
        underscoreToCamelAttributes: function (obj, clean) {
          if (clean === undefined) {
            clean = true
          }

          var newObj = angular.copy(obj)

          angular.forEach(
            newObj,
            function (value, index) {
              if ((value !== null || !clean) && value !== undefined) {
                var newIndex = this.underscoreToCamel(index)
                newObj[newIndex] = value

                if (newIndex !== index) {
                  delete newObj[index]
                }
              } else if (clean) {
                delete newObj[index] // remove all attributes with no values
              }
            }.bind(this)
          )

          return newObj
        },
        underscoreToCamel: function (str) {
          if (angular.isString(str)) {
            return str.replace(/(\_[a-z])/g, function ($1) {
              return $1.toUpperCase().replace('_', '')
            })
          } else {
            return str
          }
        },
        camelToUnderscoreDeepAttributes: function (input) {
          var data = angular.copy(input)

          for (var property in data) {
            if (data.hasOwnProperty(property)) {
              data[property] = this.camelToUnderscoreObjectAttributes(
                data[property]
              )
              data[property] = this.camelToUnderscoreArrayAttributes(
                data[property]
              )

              if (
                angular.isObject(data[property]) ||
                angular.isArray(data[property])
              ) {
                data[property] = this.camelToUnderscoreDeepAttributes(
                  data[property]
                )
              }

              var convertedProperty = this.camelToUnderscore(property)
              data[convertedProperty] = data[property]

              if (convertedProperty !== property) {
                delete data[property]
              }
            }
          }

          return data
        },
        camelToUnderscoreObjectAttributes: function (object) {
          if (angular.isObject(object) && !angular.isArray(object)) {
            return this.camelToUnderscoreAttributes(object, false)
          } else {
            return object
          }
        },
        camelToUnderscoreArrayAttributes: function (array) {
          if (angular.isArray(array)) {
            angular.forEach(
              array,
              function (item, index) {
                array[index] = this.underscoreToCamelDeepAttributes(item)
              }.bind(this)
            )

            return array
          } else {
            return array
          }
        },
        camelToUnderscoreAttributes: function (obj, clean) {
          if (clean === undefined) {
            clean = true
          }

          var newObj = angular.copy(obj)

          angular.forEach(
            newObj,
            function (value, index) {
              if (value !== null && value !== undefined) {
                var newIndex = this.camelToUnderscore(index)
                newObj[newIndex] = value

                if (newIndex !== index) delete newObj[index]
              } else if (clean) {
                delete newObj[index] // remove all attributes with no values
              }
            }.bind(this)
          )

          return newObj
        },
        camelToUnderscore: function (str) {
          return str
            .replace(/\W+/g, '_')
            .replace(/([a-z\d])([A-Z])/g, '$1_$2')
            .toLowerCase()
        },

        underscoreToTitle: function (str) {
          if (angular.isString(str)) {
            str = str.charAt(0).toUpperCase() + str.slice(1)
            return str.replace(/(\_[a-z])/g, function ($1) {
              return $1.toUpperCase().replace('_', ' ')
            })
          } else {
            return str
          }
        },
      }
    },
  ])
