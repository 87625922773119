/* global angular, getURLParameter */
import template from './auth_screen.html'

angular.module('bzUserModule').component('authScreen', {
  bindings: {
    logIn: '<?',
    userType: '<?',
    logoLink: '<?',
    disableLinks: '<?',
    registerContractor: '<',
    redirectPath: '=?',
    referralId: '<?',
    referralSource: '@',
    disableTabs: '=?',
    variation: '=?',
  },
  template,
  controller: [
    'attributeConversionService',
    '$timeout',
    'contractorModel',
    'trackAuthService',
    function (
      attributeConversionService,
      $timeout,
      contractorModel,
      trackAuthService
    ) {
      this.$onInit = function () {
        this.checkRegisterContractor()
        this.checkUserType()
        this.trackInit()
      }

      this.trackInit = function () {
        if (!this.logIn) {
          this.formVersion = 1.1

          trackAuthService.showSignUp({
            userType: this.userType,
            variation: this.variation,
            formVersion: this.formVersion,
          })
        } else {
          trackAuthService.showSignIn({
            userType: this.userType,
            variation: this.variation,
          })
        }
      }

      this.checkRegisterContractor = function () {
        if (this.registerContractor) {
          this.registerContractor =
            attributeConversionService.underscoreToCamelDeepAttributes(
              this.registerContractor
            )
        }

        if (this.registerContractor && this.registerContractor.businessName) {
          this.setUserType('contractor')
          this.setContractor(
            attributeConversionService.underscoreToCamelDeepAttributes(
              this.registerContractor
            )
          )
        }
      }

      this.checkUserType = function () {
        if (!this.userType) {
          this.userType = getURLParameter('contractor')
            ? 'contractor'
            : 'propertyOwner'
        }
      }

      this.navUnderlinePosition = function () {
        return this.userTypeIs('contractor') ? '50%' : '0%'
      }

      this.userTypeIs = function (type) {
        return this.userType === type
      }

      this.setUserType = function (type) {
        if (this.userType !== type) {
          trackAuthService.toggleUserType(type)
        }
        this.userType = type
        if (type === 'propertyOwner') {
          this.contractor = null
        }

        return this.userType
      }

      this.onAuthentication = function (response) {
        this.redirectPath = response.redirectPath

        if (this.userTypeIs('user') || this.logIn || !response.contractor) {
          this.redirect()
        } else {
          document.location = '/profile-setup/?source=signup'
        }
      }

      this.showNav = function () {
        return !this.logIn && !this.contractorSelected() && !this.disableTabs
      }

      this.showBusinessSearch = function () {
        return this.userTypeIs('contractor') && !this.contractor && !this.logIn
      }

      this.showEmailPassword = function () {
        return !this.showBusinessSearch()
      }

      this.showBack = function () {
        return this.contractorSelected()
      }

      this.setContractor = function (contractor) {
        if (typeof contractor.id === 'undefined') {
          trackAuthService.clickToAddNewContractor({
            variation: this.variation,
            formVersion: this.formVersion,
          })
        } else {
          trackAuthService.clickToClaimContractor({
            variation: this.variation,
            formVersion: this.formVersion,
          })
        }
        this.contractor = contractor
      }

      this.showContractorIcon = function () {
        return true
      }

      this.clearContractor = function () {
        trackAuthService.clearContractor()
        this.contractor = null
      }

      this.contractorSelected = function () {
        return this.userTypeIs('contractor') && this.contractor && !this.logIn
      }

      this.showSignUp = function () {
        this.logIn = false
        trackAuthService.showSignUp({
          userType: this.userType,
          variation: this.variation,
          formVersion: this.formVersion,
        })
      }

      this.showLogIn = function () {
        this.clearContractor()
        trackAuthService.showSignIn({
          userType: this.userType,
          variation: this.variation,
          formVersion: this.formVersion,
        })

        this.logIn = true
      }

      this.redirect = function () {
        document.location = this.redirectPath || document.location
      }

      this.enableLogoLink = () => this.logoLink && !this.disableLinks
      this.isFormVersion = (version) => this.formVersion === version
      this.screenTitlePrefix = () =>
        this.isFormVersion(1.1)
          ? `Let's unlock your free account for `
          : `Unlock your free account for`
      this.ctaLabel = () => (this.isFormVersion(1.1) ? 'Next' : 'Submit')
    },
  ],
})
