bz_app.directive('stickySubnav', function () {
  return {
    restrict: 'AE',
    scope: {
      mainContainerSelector: '@',
    },
    link: function (scope, element, attrs) {
      var main_container = angular.element(scope.mainContainerSelector)

      scope.init_affix = function () {
        element.affix({
          offset: {
            top: element.offset().top - 52,
          },
        })

        element.on('affixed.bs.affix', function (e) {
          if (element.hasClass('affix'))
            main_container.css('margin-top', '52px')
        })

        // reset top-of-page fix when scrolling down
        element.on('affixed-top.bs.affix', function (e) {
          if (element.hasClass('affix-top'))
            main_container.css('margin-top', '0px')
        })
      }

      scope.init_banner_close = function () {
        angular.element('.modern-banner .alert .close').click(function (e) {
          element.data('bs.affix').options.offset = element.offset().top - 104
        })
      }

      scope.init_affix()
      scope.init_banner_close()
    },
  }
})
