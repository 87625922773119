/* global angular */
angular.module('bzUserModule').factory('userCommunicationModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiPath: urlConstants.api.v1.path,
      apiConversationPath:
        urlConstants.api.v1.path + 'user/conversation_settings',
      load: function () {
        return $http.get(this.apiConversationPath).then(
          function (response) {
            return attributeConversionService.underscoreToCamelDeepAttributes(
              this.extractData(response)
            )
          }.bind(this)
        )
      },
      update: function (input, userId) {
        var settings =
          attributeConversionService.camelToUnderscoreDeepAttributes(input)
        if (!settings.phone) {
          settings.phone = {}
        }

        settings.phone.value = settings.phone.value
          ? settings.phone.value.replace(/\D/g, '')
          : ''

        if (settings.sms) {
          settings.phone.phone_preferred = settings.phone.preferred
          settings.phone.sms_preferred = settings.sms.preferred

          delete settings['sms']
        } else if (settings.phone.value) {
          settings.phone.phone_preferred = true
          settings.phone.sms_preferred = true
        } else {
          settings.phone.phone_preferred = false
          settings.phone.sms_preferred = false
        }

        return $http
          .put(this.apiConversationPath, {
            conversation_settings: settings,
            user_id: userId,
          })
          .then(this.extractData, this.extractData)
      },
      extractData: function (response) {
        if (response.status < 400) {
          return { data: response.data, success: true }
        } else {
          return {
            status: response.status,
            success: false,
            data: response.data,
          }
        }
      },
    }
  },
])
