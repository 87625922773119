bz_app.factory('notificationsSettingsData', [
  '$rootScope',
  '$timeout',
  function ($rootScope, $timeout) {
    var email_object = { value: null, preferred: false, verified: false }
    var phone_object = {
      value: null,
      sms_preferred: false,
      phone_preferred: false,
      verified: false,
    }

    return {
      email: email_object,
      phone: phone_object,
      errors: null,

      set: function (input) {
        var that = this

        // make sure data bindings are activated
        $timeout(function () {
          $rootScope.$apply(function () {
            that.email = input.email !== undefined ? input.email : email_object
            that.phone = input.phone !== undefined ? input.phone : phone_object
          })
        })
      },
      set_errors: function (errors) {
        var that = this

        $timeout(function () {
          $rootScope.$apply(function () {
            that.errors = errors
          })
        })
      },
    }
  },
])
