/* global bz_app, angular*/
angular.module('bzBidModule').controller('BidModalCtrl', [
  '$uibModalInstance',
  'inputData',
  function ($uibModalInstance, inputData) {
    this.$onInit = function () {
      this.lead = inputData.lead
      this.referralSource = inputData.referralSource
      this.screen = 'update'
    }

    this.changeScreen = (screen) => {
      this.screen = screen
    }

    this.close = function () {
      $uibModalInstance.close('ok')
    }

    this.cancel = function ($event) {
      if (inputData.onCancel) {
        inputData.onCancel()
      }
      $uibModalInstance.dismiss('cancel')
      if ($event) {
        $event.preventDefault()
      }
    }
  },
])
