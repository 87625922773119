import template from './add_missing_permits_banner.html'
const addMissingPermitsBanner = {
  restrict: 'A',
  controllerAs: '$ctrl',
  bindings: {
    bannerKey: '<',
  },
  template,
  controller: [
    'urlConstants',
    'globalBannerTracking',
    function (urlConstants, globalBannerTracking) {
      this.redirectToCta = () => {
        this.setCookie()
        globalBannerTracking.clickBannerCTA(this.bannerKey)
        document.location = urlConstants.contractor.permitsUrl
      }

      this.close = () => {
        this.setCookie()
        globalBannerTracking.clickClose(this.bannerKey)
        this.closed = true
      }

      this.setCookie = () => {
        const now = new Date()
        const expiry = new Date(
          now.getFullYear(),
          now.getMonth() + 2,
          now.getDate()
        )
        $.cookie(this.bannerKey, 1, expiry)
      }
    },
  ],
}

export default addMissingPermitsBanner
