/* global bz_app */
angular.module('bzYelpModule').factory('yelpModel', [
  '$http',
  'urlConstants',
  'modelService',
  function ($http, urlConstants, modelService) {
    return {
      contractorReviews: function (contractorId) {
        return $http
          .get(
            `${urlConstants.api.v1.path}contractors/${contractorId}/yelp_reviews`
          )
          .then(modelService.extractData)
      },
    }
  },
])
