import template from './unsubscribe_from_projects_modal.html'

const unsubscribeFromProjectsModalService = [
  '$uibModal',
  function ($uibModal) {
    return {
      show: function (inputData) {
        var modalInstance = $uibModal.open({
          template,
          controller: 'UnsubscribeFromProjectsModalCtrl',
          controllerAs: '$ctrl',
          bindToController: true,
          windowClass:
            'v3-modal v3-modal-simple small unsubscribe-from-projects-modal',
          resolve: {
            inputData: function () {
              return inputData
            },
          },
        })

        return modalInstance
      },
    }
  },
]

export default unsubscribeFromProjectsModalService
