/* global angular */
angular
  .module('bzProjectModule')
  .factory('squareFootageOptionsModel', function () {
    return {
      getAll: function () {
        return [
          { value: 100, text: 'Less than 100 sq ft' },
          { value: 200, text: '100 - 200 sq ft' },
          { value: 500, text: '200 - 500 sq ft' },
          { value: 2000, text: '500 - 2000 sq ft' },
          { value: 5000, text: '2000 - 5000 sq ft' },
          { value: null, text: "I'm not sure / Other" },
        ]
      },
    }
  })
