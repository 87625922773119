angular.module('bzSessionModule').factory('sessionModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiPath: urlConstants.api.v1.path + 'sessions.json',

      logIn: function (user) {
        var data = attributeConversionService.camelToUnderscoreDeepAttributes({
          user: user,
        })

        return $http
          .post(this.apiPath, data)
          .then(this.extractData, this.extractData)
      },

      extractData: function (response) {
        return (
          attributeConversionService.underscoreToCamelDeepAttributes(
            response.data
          ) || null
        )
      },
    }
  },
])
