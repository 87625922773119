const coBidPostponeModalService = [
  '$uibModal',
  '$http',
  'leadModel',
  'poBidRequestBannerTracking',
  'globalBannerService',
  'modalScreenService',
  function (
    $uibModal,
    $http,
    leadModel,
    poBidRequestBannerTracking,
    globalBannerService,
    modalScreenService
  ) {
    return {
      open(poBidRequest, serviceRequestId) {
        poBidRequestBannerTracking.postponeModalView(poBidRequest.id)
        modalScreenService.initDetached({
          templateUrl: 'leads/unlocked_lead/co_bid_postpone_modal',
          windowClass: 'v3-modal v3-modal-simple extra-small',
          controller: [
            '$uibModalInstance',
            '$rootScope',
            function ($uibModalInstance, $rootScope) {
              this.title = 'When do you think you will be ready?'
              const { postponeNotificationsSentCount } = poBidRequest

              if (postponeNotificationsSentCount === 0) {
                this.desc =
                  'You will have two more chances to postpone submitting a bid for this project.'
              } else if (postponeNotificationsSentCount === 1) {
                this.desc =
                  'After this, you will only have one more chance to postpone submitting a bid for this project.'
              } else if (postponeNotificationsSentCount === 2) {
                this.desc =
                  'This is your last chance to postpone submitting a bid for this project.'
              }

              const createPostponeClickHandler = (delayDurationDays) => {
                return () => {
                  leadModel.postponePoBidRequest(
                    poBidRequest.connectionId,
                    delayDurationDays,
                    'postpone modal'
                  )
                  $uibModalInstance.close()
                  globalBannerService.reload()
                }
              }

              this.options = [
                {
                  text: 'In the next 2 days',
                  onClick: createPostponeClickHandler(2),
                },
                {
                  text: 'In a week',
                  onClick: createPostponeClickHandler(7),
                },
                {
                  text: 'In 2+ weeks',
                  onClick: createPostponeClickHandler(14),
                },
              ]

              this.close = () => {
                $uibModalInstance.close()
              }

              this.openArchiveModal = () => {
                poBidRequestBannerTracking.openArchiveModal(
                  poBidRequest.id,
                  'postpone modal'
                )
                window.location = `/lead/${serviceRequestId}?page_state=archive`
              }
            },
          ],
        })
      },
    }
  },
]

export default coBidPostponeModalService
