bz_app.controller('ReviewResponseModalCtrl', [
  '$filter',
  '$scope',
  '$uibModalInstance',
  '$timeout',
  'reviewData',
  'reviewResponseModel',
  'reviewResponseModalService',
  function (
    $filter,
    $scope,
    $uibModalInstance,
    $timeout,
    reviewData,
    reviewResponseModel,
    reviewResponseModalService
  ) {
    $scope.review = reviewData
    $scope.validate = new ValidateForm()

    $scope.ok = function ($event) {
      $scope.submitted = true
      if (
        $scope.review.response.body &&
        $scope.validate.valid_length($scope.review.response.body, 10)
      ) {
        angular
          .element($event.target)
          .text('Submitting...')
          .attr('disabled', true)
        if ($scope.review.response.id) {
          reviewResponseModel.update()
        } else {
          reviewResponseModel.create()
        }
        success()
      }
      if ($event) $event.preventDefault()
    }

    $scope.tooShort = function (content) {
      return !content || content.length < 10
    }

    $scope.tooLong = function (content) {
      return content.length > 6000
    }

    $scope.cancel = function ($event) {
      $uibModalInstance.dismiss('cancel')
      if ($event) $event.preventDefault()
    }

    $scope.createdAt = function (date) {
      return $filter('date')(new Date(date), 'shortDate', 'PST')
    }

    var success = function () {
      $scope.cancel()
      if (!UserData.is_admin()) {
        reviewResponseModalService.showSuccessModal()
      }
    }
  },
])
