import communicationPreferenceComponent from './components/communication_preference_component'
import dashboardCommunicationPreferencesComponent from './components/dashboard_communication_preferences_component'
import UnsubscribeFromProjectsModalCtrl from './controllers/unsubscribe_from_projects_modal_controller'
import communicationPreferenceModel from './models/communication_preference_model'
import customerPhoneModel from './models/customer_phone_model'
import unsubscribeFromProjectsModalService from './services/unsubscribe_from_projects_modal_service'

const CommunicationPreferencesModule = angular
  .module('CommunicationPreferencesModule', [])
  .component('communicationPreference', communicationPreferenceComponent)
  .component(
    'dashboardCommunicationPreferences',
    dashboardCommunicationPreferencesComponent
  )
  .controller(
    'UnsubscribeFromProjectsModalCtrl',
    UnsubscribeFromProjectsModalCtrl
  )
  .factory('communicationPreferenceModel', communicationPreferenceModel)
  .factory('customerPhoneModel', customerPhoneModel)
  .factory(
    'unsubscribeFromProjectsModalService',
    unsubscribeFromProjectsModalService
  ).name

export default CommunicationPreferencesModule
