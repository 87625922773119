/* global bzGlobal */
bzGlobal.map = {
  markerPath: function () {
    //return (google !== undefined && google && google.maps && google.maps.SymbolPath) ? google.maps.SymbolPath.CIRCLE : 0;
    return 0
  },
  style: [
    { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }, { lightness: 17 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 18 }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 16 }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#dedede' }, { lightness: 21 }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
    },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{ color: '#fefefe' }, { lightness: 20 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
    },
  ],
  noLandmarksStyle: [
    { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [{ visibility: 'off' }],
    },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
  ],
}
bzGlobal.map.marker = {
  circle: {
    orange: {
      path: bzGlobal.map.markerPath(),
      fillOpacity: 0.8,
      fillColor: '#e69b00',
      scale: 14,
      strokeWeight: 0,
    },
    blue: {
      path: bzGlobal.map.markerPath(),
      fillOpacity: 1.0,
      fillColor: '#3399ff',
      scale: 8,
      strokeWeight: 0,
    },
    unselected: {
      path: bzGlobal.map.markerPath(),
      fillOpacity: 0.85,
      fillColor: '#63B7E5',
      scale: 8,
      strokeWeight: 0,
    },
    hover: {
      path: bzGlobal.map.markerPath(),
      fillOpacity: 1.0,
      fillColor: '#0692DF',
      scale: 8,
      strokeWeight: 0,
    },
    selected: {
      path: bzGlobal.map.markerPath(),
      fillOpacity: 1.0,
      fillColor: '#F5A623',
      scale: 14,
      strokeWeight: 0,
    },
    notFound: {
      path: bzGlobal.map.markerPath(),
      fillOpacity: 1.0,
      fillColor: '#A9A9A9',
      scale: 14,
      strokeWeight: 0,
    },
  },
}
