/* global angular */
angular.module('bzProjectModule').factory('serviceRequestPermitDataInstance', [
  'serviceRequestPermitsModel',
  'serviceRequestPermitMatchesModel',
  'serviceRequestBuildingPermitsModel',
  function (
    serviceRequestPermitsModel,
    serviceRequestPermitMatchesModel,
    serviceRequestBuildingPermitsModel
  ) {
    return function (serviceRequestId) {
      this.init = false
      this.serviceRequestId = serviceRequestId

      this.load = function () {
        return serviceRequestPermitsModel
          .fetch(this.serviceRequestId)
          .then(this.setData.bind(this))
      }

      this.setData = function (data) {
        this.permits = data.permitData.permits
        this.parseDates()

        this.permitSources = data.permitData.permitSources
        this.hasPermitMatch = data.permitData.hasPermitMatch

        this.init = true

        return this
      }

      this.parseDates = function () {
        this.permits.forEach(this.parsePermitDates.bind(this))
      }

      this.parsePermitDates = function (permit) {
        permit.effectiveDate =
          permit.effectiveDate && new Date(permit.effectiveDate)
      }

      this.addPermit = function () {
        serviceRequestPermitsModel.create(this.serviceRequestId).then(
          function (res) {
            res.permit.editable = true
            this.permits.unshift(res.permit)
            this.parsePermitDates(res.permit)
          }.bind(this)
        )
      }

      this.updatePermit = function (permitId, params) {
        serviceRequestPermitsModel.update(
          this.serviceRequestId,
          permitId,
          params
        )
      }

      this.updateBuildingPermit = function (permitId, params) {
        serviceRequestBuildingPermitsModel.update(
          this.serviceRequestId,
          permitId,
          params
        )
      }

      this.deletePermit = function (permitId) {
        serviceRequestPermitsModel.delete(this.serviceRequestId, permitId).then(
          function (res) {
            this.permits = this.permits.filter(function (permit) {
              return permit.id !== permitId
            })
          }.bind(this)
        )
      }

      this.validatePermit = function (permit) {
        permit.isValidated = true
        this.hasPermitMatch = true
        serviceRequestPermitMatchesModel.create(this.serviceRequestId, {
          permitId: permit.id,
          permitType: permit.permitType,
        })
      }

      this.invalidatePermit = function (permit) {
        permit.isValidated = false
        this.hasPermitMatch = false
        serviceRequestPermitMatchesModel.destroy(this.serviceRequestId)
      }
    }
  },
])
