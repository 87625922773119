bz_app.directive('checkbox', function () {
  return {
    restrict: 'AE',
    replace: true,
    scope: {
      checkboxSelected: '=',
      checkboxDisabled: '=',
      checkboxReadOnly: '=',
      checkboxInline: '=',
      checkboxOnByDefault: '@',
      onClickCallback: '&',
    },
    template:
      '<div class="form-checkbox" ' +
      "	ng-class=\"{ 'form-checkbox-selected': checkboxSelected, 'form-checkbox-disabled': checkboxDisabled, 'form-checkbox-readonly': checkboxReadOnly, 'form-checkbox-inline': checkboxInline }\"" +
      '	ng-click="validatedClick()">' +
      '		<i class="fas fa-check"></i>' +
      '</div>',
    link: function (scope, element, attr) {
      scope.validatedClick = function () {
        if (!scope.checkboxDisabled && !scope.checkboxReadOnly) {
          scope.checkboxSelected = !scope.checkboxSelected

          if (scope.onClickCallback) {
            scope.onClickCallback()
          }
        }
      }

      if (scope.checkboxOnByDefault === 'true') {
        scope.validatedClick()
      }
    },
  }
})
