/* global angular */
angular.module('bzUserModule').service('authModalService', [
  '$uibModal',
  'urlService',
  'modalScreenService',
  function ($uibModal, urlService, modalScreenService) {
    return {
      openModal: function (options) {
        modalScreenService.initDetached({
          templateUrl: 'users/auth_modal',
          controller: 'AuthModalCtrl',
          inputData: options,
          backdrop: true,
          keyboard: true,
          windowClass: 'v3-modal-simple auth-modal',
        })
      },
    }
  },
])
