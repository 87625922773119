/* global bz_app, isMobile, angular */
angular.module('bzReviewsModule').component('userStep', {
  bindings: {
    review: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('reviews/user_step')
    },
  ],
  controller: [
    'reviewFormScreenService',
    'formValidationService',
    'userModel',
    '$q',
    function (reviewFormScreenService, formValidationService, userModel, $q) {
      this.$onInit = function () {
        this.submissionBlocked = false
        this.isMobile = isMobile.check({ phoneOnly: true })
        this.errors = {}
        this.isValidForm = false

        this.isExistingUser = false
      }

      this.buttonText = function () {
        return 'next'
      }

      this.submit = function () {
        this.validateForm().then((response) => {
          if (response && response.success) {
            this.submissionBlocked = false
            this.review.save().then(function (response) {
              if (response && response.success) {
                reviewFormScreenService.goTo('details')
              }
            })
          } else {
            this.submissionBlocked = true
          }
        })
      }

      this.validateForm = function () {
        if (!this.review.name) {
          return $q(function (resolve) {
            resolve({ success: false })
          })
        } else if (this.isExistingUser) {
          return this.validateEmailAndPassword()
        } else {
          return this.validateEmail()
        }
      }

      this.validateEmail = function () {
        if (formValidationService.isValidEmail(this.review.email)) {
          return userModel
            .findExistingUserByEmail(this.review.email, true)
            .then((response) => {
              if (response && response.existingUser) {
                this.isExistingUser =
                  response.existingUser && !response.signedIn ? true : false
                this.errors.email = null
                this.errors.password = 'Please enter your password'

                return { success: false }
              }

              this.errors.email = null
              this.errors.password = null
              return { success: true }
            })
        } else {
          if (this.isExistingUser) {
            this.isExistingUser = false
          }
          this.errors.email = 'Please provide a valid email address'

          return $q(function (resolve) {
            resolve({ success: false })
          })
        }
      }

      this.validateEmailAndPassword = function () {
        if (formValidationService.isValidEmail(this.review.email)) {
          return userModel
            .validateExistingUserPassword(
              this.review.email,
              this.review.password
            )
            .then((response) => {
              if (response && response.success) {
                this.errors.email = null
                this.errors.password = null
                return { success: true }
              } else {
                this.errors.email = null
                this.errors.password =
                  'The email / password does not match our records'
                return { success: false }
              }
            })
        } else {
          return $q(function (resolve) {
            resolve({ success: false })
          })
        }
      }

      this.errorMessage = function () {
        if (!this.review.name) {
          return 'Please provide your full name.'
        } else if (this.errors.email) {
          return this.errors.email
        } else if (this.errors.password) {
          return this.errors.password
        }
      }
    },
  ],
})
