/* global bz_app, isMobile, angular */
angular.module('bzReviewsModule').component('reviewForm', {
  bindings: {
    project: '<',
    contractor: '=',
    review: '<',
    closeModal: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('reviews/review_form')
    },
  ],
  controller: [
    '$timeout',
    'reviewInstance',
    'reviewFormScreenService',
    function ($timeout, reviewInstance, reviewFormScreenService) {
      this.$onInit = function () {
        if (typeof this.review === 'undefined') {
          this.review = new reviewInstance()
          this.review.set({
            contractorId: this.contractor.id || this.contractor.nid,
            reviewerId: this.project
              ? this.project.projectOwner.userId
              : UserData.user_id(),
            subject: this.project
              ? (this.project.service.name || 'BuildZoom') + ' project'
              : null, //TODO
            source: 'profile',
          })
        }

        this.screenService = reviewFormScreenService
        this.screenService.init()
      }
    },
  ],
})
