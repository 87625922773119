/* global angular, $, UserData, isMobile */
angular.module('bzNavbarModule').directive('mainNavbar', [
  'navbarService',
  'localStorageModel',
  function (navbarService, localStorageModel) {
    return {
      restrict: 'AE',
      scope: {
        transparentScrollThreshold: '=?',
      },
      link: function (scope, element, attrs) {
        scope.init = function () {
          scope.initNavTransparency()
          scope.initPhotoRibbon()
        }

        scope.initNavTransparency = function () {
          if (navbarService.isInvisibleNav()) {
            $(window).on(
              'scroll',
              function () {
                navbarService.toggleNavTransparency(
                  this.transparentScrollThreshold
                )
              }.bind(this)
            )

            navbarService.toggleNavTransparency(this.transparentScrollThreshold)
          }
        }

        scope.contractorBidBannerNotPresent = function () {
          return (
            (!angular.element('body').is('.contractors') ||
              localStorageModel.get('contractorBidBannerShown')) &&
            !angular.element('.contractor-bid-banner').length
          )
        }

        scope.initPhotoRibbon = function () {
          if (
            angular.element('body').is('.property, .contractors') &&
            !isMobile.check() &&
            !UserData.user_signed_in() &&
            !navbarService.isPhotosSectionMarkedShown() &&
            this.contractorBidBannerNotPresent()
          ) {
            navbarService.markPhotosSectionShown()
            navbarService.shiftForOpenSection()
            navbarService.openPhotosSection()

            if (navbarService.isShiftedForOpenSection()) {
              $(window).one('scroll', function () {
                navbarService.unshiftForOpenSection()
                navbarService.closeAllSections()
              })

              angular
                .element(
                  navbarService.navDropDownSectionSelection +
                    ' .dropdown-toggle'
                )
                .one('mouseover', function () {
                  navbarService.unshiftForOpenSection()
                })
            }
          }
        }

        scope.init()
      },
    }
  },
])
