/* global $ */
window.UserData = function () {}

UserData.present = function () {
  return $('nav#modern-nav').length === 0 ? false : true
}

UserData.data = function (key) {
  return $('nav#modern-nav').data(key)
}

UserData.user_signed_in = function () {
  return UserData.data('user_signed_in') === true ? true : false
}

UserData.is_admin = function () {
  return UserData.data('user_role') === 'admin' ? true : false
}

UserData.is_admin_or_impersonating = function () {
  return !!UserData.staff_user_id() || UserData.is_admin()
}

UserData.is_contractor = function () {
  return UserData.data('user_role') === 'contractor' ? true : false
}

UserData.is_homeowner = function () {
  return UserData.data('user_role') === 'homeowner' ? true : false
}

UserData.contractor_id = function () {
  return UserData.data('contractor_id')
}

UserData.contractor_business_name = function () {
  return UserData.data('contractor_name') || ''
}

UserData.contractor_type = function () {
  return UserData.data('contractor_type') || ''
}

UserData.thumbnail_url = function () {
  return UserData.data('user_thumbnail')
}

UserData.user_id = function () {
  return UserData.data('user_id')
}

UserData.user_track_id = function () {
  return UserData.data('user_track_id')
}

UserData.staff_user_id = function () {
  return UserData.data('staff_user_id')
}

UserData.actual_user_id = function () {
  return UserData.staff_user_id() || UserData.user_id()
}

UserData.user_role = function () {
  return UserData.data('user_role')
}

UserData.user_ip_address = function () {
  return UserData.data('user_ip_address')
}

UserData.current_staff_user = function () {
  return UserData.data('current_staff_user') === true ? true : false
}

UserData.location = function () {
  return UserData.data('location')
}

UserData.user_email = function () {
  return UserData.data('user_email')
}

UserData.user_full_name = function () {
  return UserData.data('user_full_name')
}

UserData.user_first_name = function () {
  return UserData.data('user_first_name')
}

UserData.user_phone = function () {
  return UserData.data('user_phone')
}

UserData.application_environment = function () {
  return UserData.data('app_env')
}

UserData.is_production_environment = function () {
  return UserData.application_environment() === undefined ? true : false
}

UserData.user_subscriptions = function () {
  return UserData.data('user_subscriptions')
}

UserData.has_ad_subscription = function () {
  return (
    UserData.is_contractor() &&
    UserData.user_subscriptions().includes('Advertisement')
  )
}
