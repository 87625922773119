/* global $, angular, BZAPI, ContractorAPI	*/
window.ContractorAPI = function () {
  /**	Update team	*/
  this.teams_member_path = function (id) {
    return this.contractor_employee_api_path() + '/member/' + id
  }

  /**	returns new lead count for CO if current user is CO	*/
  this.new_lead_count = function (contractor_id) {
    return $.when(
      $.get(this.contractor_api_path() + '/new_lead_count/' + contractor_id)
    )
  }

  this.update = function (contractor_id, data, contentType) {
    if (contentType === undefined) {
      contentType = 'application/json'
      data = JSON.stringify(data)
    }

    return $.when(
      $.ajax({
        url: this.contractor_api_path() + '/' + contractor_id,
        type: 'PUT',
        contentType: contentType,
        data: data,
        dataType: 'json',
      })
    )
  }

  /**	Update team	*/
  this.update_team = function (data) {
    return $.when(
      $.ajax({
        url: this.contractor_employee_api_path(),
        type: 'PUT',
        contentType: 'application/json',
        data: angular.toJson(data),
        dataType: 'json',
      })
    )
  }

  this.delete_team_member = function (id) {
    return $.when(
      $.ajax({
        url: this.teams_member_path(id),
        type: 'DELETE',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.remove = function (contractor_id) {}

  /**	load/update profile info of current CO who's logged in	*/
  this.load_profile = function () {
    return $.when($.get(this.contractor_profile_api_path()))
  }

  this.load_intro_message = function () {
    return $.when($.get(this.contractor_intro_message_path()))
  }

  this.update_intro_message = function (contractor_id, text, medium = 'sms') {
    return $.when(
      $.ajax({
        url: this.contractor_intro_message_path(),
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          intro_message: { contractor_id, text, medium },
        }),
        dataType: 'json',
      })
    )
  }

  this.follow = function (contractor_id, user_email) {
    return $.when(
      $.ajax({
        url: this.contractor_api_path() + '/follow',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ id: contractor_id, email: user_email }),
        dataType: 'json',
      })
    )
  }

  this.follow_reviews = function (contractor_id, user_email) {
    return $.when(
      $.ajax({
        url: this.contractor_api_path() + '/follow_reviews',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ id: contractor_id, email: user_email }),
        dataType: 'json',
      })
    )
  }

  this.set_thumbnail = function (image_id) {
    return $.when(
      $.ajax({
        url: this.contractor_api_path() + '/thumbnail/set',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ id: image_id }),
        dataType: 'json',
      })
    )
  }

  this.revert_unclaimed_and_convert_to_homeowner = function (contractor_id) {
    return $.when(
      $.ajax({
        url:
          this.contractor_api_path() +
          '/revert_and_convert_to_homeowner/' +
          contractor_id,
        type: 'GET',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.create_contractor_ranking_adjustment = function (data) {
    return $.when(
      $.ajax({
        url: this.contractors_ranking_adjustments_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: 'json',
      })
    )
  }

  this.update_contractor_ranking_adjustment = function (data) {
    return $.when(
      $.ajax({
        url: this.contractors_ranking_adjustments_api_path() + '/' + data.id,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: 'json',
      })
    )
  }

  this.delete_contractor_ranking_adjustment = function (id) {
    return $.when(
      $.ajax({
        url: this.contractors_ranking_adjustments_api_path() + '/' + id,
        type: 'DELETE',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.admin_destroy_profile = function (contractor_id, reason) {
    return $.when(
      $.get(this.contractor_api_path() + '/delete/' + contractor_id, {
        comment: reason,
      })
    )
  }

  this.display_contractor_phone = function (contractor_id) {
    return $.when(
      $.ajax({
        url:
          this.contractor_admin_v2_api_path() +
          '/' +
          contractor_id +
          '/display_contractor_phone',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.display_routing_phone = function (contractor_id) {
    return $.when(
      $.ajax({
        url:
          this.contractor_admin_v2_api_path() +
          '/' +
          contractor_id +
          '/display_routing_phone',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.fetchPermits = function (contractor_id, sort, _offset) {
    return $.ajax({
      url:
        this.contractor_api_path() + '/' + contractor_id + '/building_permits',
      type: 'GET',
      contentType: 'appliication/json',
      data: { offset: _offset, sort_by: sort },
      dataType: 'html',
    })
  }
}

ContractorAPI.prototype = new BZAPI()
ContractorAPI.prototype.constructor = ContractorAPI
