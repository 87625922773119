/* global Track, $, Cookie, BZAPI	*/
var ProjectAPI = function () {
  this.init = function () {
    $.ajaxSetup({
      beforeSend: function (xhr, settings) {
        if (
          !(/^http:.*/.test(settings.url) || /^https:.*/.test(settings.url))
        ) {
          // Only send the token to relative URLs i.e. locally.
          xhr.setRequestHeader('X-CSRF-Token', Cookie.get('XSRF-TOKEN'))
        }
      },
    })
  }

  this.init()

  this.load = function (project_id) {
    return $.when($.get(this.project_api_path() + '/' + project_id))
  }

  this.load_all = function (status, role) {
    var query = {}

    if (status) {
      query.status = status
    }
    if (role) {
      query.service = role
    }

    return $.when($.get(this.project_api_path() + '?' + $.param(query))) // returns a promise to retrieve list of user's projects
  }

  this.create = function (project_data) {
    if (!project_data.service) {
      project_data.service = {}
      project_data.service.name = 'from invoice'
    }

    Track.send('Project Board Action', {
      action: 'created',
      'project type': project_data.service.name,
    })

    return $.when(
      $.ajax({
        url: this.project_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ project: project_data }),
        dataType: 'json',
      })
    )
  }

  this.update = function (project_id, project_data) {
    return $.when(
      $.ajax({
        url: this.project_api_path() + '/' + project_id,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({ project: project_data }),
        dataType: 'json',
      })
    )
  }

  this.remove = function (project_id) {
    Track.send('Project Board Action', { action: 'delete' })

    return $.when(
      $.ajax({
        type: 'DELETE',
        url: this.project_api_path() + '/' + project_id,
        dataType: 'json',
      })
    )
  }

  this.set_features = function (project_id, features) {
    return $.when(
      $.ajax({
        url: this.project_api_path() + '/' + project_id + '/features',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ project: { features: features } }),
        dataType: 'json',
      })
    )
  }

  this.create_contractor_bookmark = function (
    project_id,
    contractors,
    min_range
  ) {
    Track.send('Project Board Action', {
      action: 'create contractor bookmark',
      'project id': project_id,
    })

    return $.when(
      $.ajax({
        url:
          this.project_api_path() +
          '/' +
          project_id +
          '/bookmarked_contractors',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          contractors: contractors,
          min_range: min_range,
        }),
        dataType: 'json',
      })
    )
  }

  this.check_contractor_range = function (project_id, contractor_id) {
    return $.when(
      $.get(
        this.project_api_path() +
          '/' +
          project_id +
          '/check_contractor_range/' +
          contractor_id
      )
    )
  }

  this.delete_contractor_bookmark = function (project_id, contractor_id) {
    Track.send('Project Board Action', {
      action: 'delete contractor bookmark',
      'project id': project_id,
    })

    return $.when(
      $.ajax({
        url:
          this.project_api_path() +
          '/' +
          project_id +
          '/bookmarked_contractors/' +
          contractor_id,
        type: 'DELETE',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.create_excluded_contractor = function (project_id, contractors) {
    return $.when(
      $.ajax({
        url:
          this.project_api_path() + '/' + project_id + '/excluded_contractors',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ contractors: contractors }),
        dataType: 'json',
      })
    )
  }

  this.delete_excluded_contractor = function (project_id, contractor_id) {
    return $.when(
      $.ajax({
        url:
          this.project_api_path() +
          '/' +
          project_id +
          '/excluded_contractors/' +
          contractor_id,
        type: 'DELETE',
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.create_photo_bookmark = function (project_id, photos) {
    Track.send('Project Board Action', {
      action: 'create photo bookmark from gallery',
      'project id': project_id,
    })

    //inspiration, before, during, after
    return $.when(
      $.ajax({
        url: this.images_api_path() + '/bookmarks',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          project_id: project_id,
          gallery_type: 'inspiration',
          images: photos,
        }),
        dataType: 'json',
      })
    )
  }

  this.create_photo_bookmark_from_upload = function (project_id, photos) {
    Track.send('Project Board Action', {
      action: 'create photo bookmark from upload',
      'project id': project_id,
    })

    return $.when(
      $.ajax({
        url: this.images_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          project_id: project_id,
          gallery_type: 'inspiration',
          images: photos,
        }),
        dataType: 'json',
      })
    )
  }

  this.delete_photo_bookmark = function (project_id, photo_id) {
    Track.send('Project Board Action', {
      action: 'delete photo bookmark',
      'project id': project_id,
    })
    return $.when(
      $.ajax({
        url: this.images_api_path() + '/bookmarks',
        type: 'DELETE',
        contentType: 'application/json',
        data: JSON.stringify({
          project_id: project_id,
          gallery_type: 'inspiration',
          images: [{ image_id: photo_id }],
        }),
        dataType: 'json',
      })
    )
  }

  this.is_photo_bookmarked = function (project_id, photos) {
    return $.when(
      $.ajax({
        url: this.images_api_path() + '/is_bookmarked',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          project_id: project_id,
          gallery_type: 'inspiration',
          images: photos,
        }),
        dataType: 'json',
      })
    )
  }

  this.create_label = function (label_name) {
    Track.send('Project Action', {
      action: 'create label',
      label_name: label_name,
    })

    return $.when(
      $.ajax({
        url: this.project_label_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ project_label: { name: label_name } }),
        dataType: 'json',
      })
    )
  }

  this.update_label = function (label_id, label_name) {
    //console.log('API: update label: ' + label_name);
  }

  this.delete_label = function (label_id) {
    return $.when(
      $.ajax({
        url: this.project_label_api_path() + '/' + label_id,
        type: 'DELETE',
        dataType: 'json',
      })
    )
  }

  this.load_labels = function () {
    return $.when($.get(this.project_label_api_path()))
  }

  this.set_label = function (project_ids, label_id) {
    Track.send('Project Action', {
      action: 'add projects to label',
      label_id: label_id,
    })

    return $.when(
      $.ajax({
        url: this.project_api_path() + '/projects_labels',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          project_id: project_ids.join(','),
          label_id: label_id,
        }),
        dataType: 'json',
      })
    )
  }
}

ProjectAPI.prototype = new BZAPI()
ProjectAPI.prototype.constructor = ProjectAPI
window.ProjectAPI = ProjectAPI
