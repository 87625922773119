import 'AngularBase/_components/bookmark'

/** bookmark for contractor profile */
var ContractorBookmark = function () {
  this.project_has_service_request = false
  this.contractor_id = 0
  this.contractorBookmarkModal = new ContractorBookmarkModal()

  this.init = function () {
    var that = this
    var project = this.project_data()
    this.contractor_id = $('.profile-info').data('contractor_id')

    // determine if contractor is already bookmarked
    this.is_contractor_bookmarked_for_project(
      project.attr('data-project_id'),
      this.contractor_id,
      function (contractor_in_project) {
        that.inject(contractor_in_project)
      }
    )

    $('body').on(
      'contractor_profile_init_toggle_invite_text',
      function (e, data) {
        that.toggle_invite_text(data.bookmark)
      }
    )
  }

  this.inject = function (contractor_in_project) {
    var container = $('.contractor-header-action-buttons')

    if (this.project_has_service_request) container.empty() // remove all existing buttons

    container.append(
      '<a href="#" class="contractor_bookmark btn modern-button modern-green-button btn-block contact_contractor"><span></span></a>'
    )

    var bookmark = $('.contractor_bookmark')
    if (contractor_in_project) bookmark.addClass('selected')

    if (this.project_has_service_request) this.init_invite_click(bookmark)
    else this.init_bookmark_click(bookmark) // logic when BOOKMARK is clicked}

    container.show()
  }

  this.init_bookmark_click = function (bookmark) {
    var that = this

    bookmark.on('click', function (e) {
      if (that.user_signed_in && that.project_data().length > 0)
        that.toggle_contractor_bookmark(
          bookmark,
          that.project_data().attr('data-project_id'),
          that.contractor_id
        )

      e.preventDefault()
    })
  }

  this.init_invite_click = function (bookmark) {
    var that = this
    var project = this.project_data()

    bookmark.addClass('invite')

    bookmark.on('click', function (e) {
      // UNINVITE without confirmation
      if (that.user_signed_in && bookmark.hasClass('selected')) {
        if (that.project_data().length > 0) {
          that.toggle_contractor_bookmark(
            bookmark,
            project.attr('data-project_id'),
            that.contractor_id
          )
        }
      }
      // bring up modal for INVITE confirmation
      else that.contractorBookmarkModal.show()

      e.preventDefault()
    })
  }

  this.is_contractor_bookmarked_for_project = function (
    project_id,
    contractor_id,
    callback
  ) {
    if (this.user_signed_in && this.project_data().length > 0) {
      var that = this
      // get project details and check if current contractor is bookmarked for project
      this.project_api.load(project_id).then(function (res) {
        var contractor_in_project = false

        // find matching contractor in project
        if (
          res.project.components !== undefined &&
          res.project.components != null
        ) {
          // parse project's contractors
          if (
            res.project.components.BookmarkedContractor !== undefined &&
            res.project.components.BookmarkedContractor.length > 0
          ) {
            res.project.components.BookmarkedContractor.forEach(function (
              contractor
            ) {
              if (contractor.component_id == contractor_id)
                contractor_in_project = true
            })
          }

          // parse project's service request
          if (
            res.project.components.ServiceRequest !== undefined &&
            res.project.components.ServiceRequest.length > 0
          )
            that.project_has_service_request = true
          else that.project_has_service_request = false
        }

        if (callback !== undefined) callback(contractor_in_project)
      })
    }
  }

  this.toggle_contractor_bookmark = function (
    bookmark,
    project_id,
    contractor_id
  ) {
    var that = this

    // remove contractor bookmark from project
    if (bookmark.hasClass('selected')) {
      this.project_api
        .delete_contractor_bookmark(project_id, contractor_id)
        .then(function (res) {
          bookmark.removeClass('selected')
          that.toggle_invite_text(bookmark)
          ServiceRequestsTrack.bookmark(
            'remove contractor',
            project_id,
            contractor_id
          )
        })
    }
    // add contractor bookmark to project
    else {
      this.project_api
        .create_contractor_bookmark(project_id, [
          { contractor_id: contractor_id },
        ])
        .then(function (res) {
          bookmark.addClass('selected')
          that.toggle_invite_text(bookmark)
          ServiceRequestsTrack.bookmark(
            'invite contractor',
            project_id,
            contractor_id
          )
        })
    }
  }

  this.toggle_invite_text = function (bookmark) {
    if (this.project_has_service_request) bookmark.addClass('invite')
    else bookmark.removeClass('invite')
  }
}

window.ContractorBookmark = ContractorBookmark
