/* global angular, getURLParameter */
angular.module('bzAnalyticsModule').service('utmService', [
  'localStorageModel',
  function (localStorageModel) {
    return {
      storageKey: 'utmAttributes',
      data: {
        utmSource: null,
        utmMedium: null,
        utmCampaign: null,
        utmTerm: null,
        utmContent: null,
      },
      setFromUrlParams: function () {
        if (this.get()) {
          this.data = this.get()
        }

        if (location.pathname !== '/financing') {
          this.setUtmAttribute('utmSource', 'utm_source')
          this.setUtmAttribute('utmMedium', 'utm_medium')
          this.setUtmAttribute('utmCampaign', 'utm_campaign')
          this.setUtmAttribute('utmTerm', 'utm_term')
          this.setUtmAttribute('utmContent', 'utm_content')

          localStorageModel.set(this.storageKey, this.data)
        }
      },
      setUtmAttribute: function (destinationAttribute, sourceAttribute) {
        var utmAttr =
          getURLParameter(sourceAttribute) ||
          getURLParameter(sourceAttribute.replace(/_/g, ''))
        if (utmAttr) {
          this.data[destinationAttribute] = utmAttr
        }
      },
      get: function () {
        return localStorageModel.get(this.storageKey)
      },
      getUtmAttribute: function (attribute) {
        var value = null

        if (
          this.get() &&
          this.get()[attribute] &&
          this.get()[attribute] !== '0'
        ) {
          value = this.get()[attribute]
        }

        return value
      },
      getUtmSource: function () {
        return this.getUtmAttribute('utmSource')
      },
      getUtmMedium: function () {
        return this.getUtmAttribute('utmMedium')
      },
      getUtmCampaign: function () {
        return this.getUtmAttribute('utmCampaign')
      },
      getUtmTerm: function () {
        return this.getUtmAttribute('utmTerm')
      },
      getUtmContent: function () {
        return this.getUtmAttribute('utmContent')
      },
    }
  },
])
