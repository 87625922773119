bz_app.controller('ExtModalCtrl', [
  '$scope',
  '$uibModalInstance',
  'inputData',
  function ($scope, $uibModalInstance, inputData) {
    $scope.contact = inputData.contact

    $scope.cancel = function ($event) {
      $uibModalInstance.dismiss('cancel')

      if ($event) {
        $event.preventDefault()
      }
    }
  },
])
