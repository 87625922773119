angular.module('bzProjectFormModule').directive('fullNameInput', [
  '$compile',
  'validationService',
  function ($compile, validationService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        formData: '=',
        validateInput: '=',
        showFormErrors: '=',
      },
      template:
        '<input ng-model="formData.fullName" type="text" id="service_request_full_name" name="service_request[full_name]" class="form-control modern-form-control" placeholder="{{ placeholder }}"  autocomplete="name" required />',
      link: function (scope, element, attrs) {
        if (!attrs.placeholder) {
          scope.placeholder = 'Full name'
        }

        if (scope.validateInput) {
          scope.validationService = validationService

          var errorElement = $compile(
            '<div input-error error-text="Please enter your name." error-validator="validationService.isValidFullName()" error-visible="showFormErrors"></div>'
          )(scope)
          element.before(errorElement)
        }
      },
    }
  },
])
