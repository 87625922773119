/* global angular, Track */
angular.module('bzYelpModule').factory('trackYelpService', [
  function () {
    return {
      title: 'Yelp Action',
      matchByPhoneRequested: function (contractorId) {
        var data = this.defaultData({ action: 'match by phone requested' })
        data.contractor_id = contractorId

        Track.send(this.title, data)
      },
      matchByPhoneFound: function (contractorId, yelpId) {
        var data = this.defaultData({ action: 'match by phone found' })
        data.contractor_id = contractorId
        data.yelp_id = yelpId

        Track.send(this.title, data)
      },
      defaultData: function (input) {
        var platform = isMobile.check() ? 'mobile' : 'desktop'

        return {
          action: input.action ? input.action : '',
          platform: platform,
        }
      },
    }
  },
])
