import template from './profile_header_image.html'

const profileHeaderImage = {
  bindings: {
    imageUrl: '<',
    bannerDescription: '@',
  },
  template,
  controller: [
    '$element',
    'urlService',
    'contractorGalleryModel',
    function ($element, urlService, contractorGalleryModel) {
      this.$onInit = () => {
        this.utils = urlService

        angular.element('#profile-header-selector').on('change', ($event) => {
          this.attachmentSelected($event.target.files)
        })
      }

      this.attachmentSelected = (files) => {
        this.isAttaching = true

        contractorGalleryModel
          .addHeaderImage({ files: files })
          .then((uploadedFile) => {
            this.imageUrl = uploadedFile.url
            this.isAttaching = false
          })
      }

      this.deleteHeaderImage = () => {
        contractorGalleryModel.deleteHeaderImage().then(() => {
          this.imageUrl = null
        })
      }
    },
  ],
}

export default profileHeaderImage
