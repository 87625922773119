/* global URLify */
var PropertiesHelper = function () {}

PropertiesHelper.parameterizeAddress = function (address) {
  var param = address.split(',').join(' ').toLowerCase()
  param = param.split('.').join(' ')
  param = param.split('-').join('').split('/').join('')
  param = URLify(param)
  if (param.substr(param.length - 4) == '-usa')
    param = param.substr(0, param.length - 4)
  param = param.split('-').join('_')
  param = param.split('_court_').join('_ct_')
  param = param.split('_boulevard_').join('_blvd_')
  param = param.split('_street_').join('_st_')
  param = param.split('_avenue_').join('_ave_')
  param = param.split('_highway_').join('_hwy_')
  param = param.split('_road_').join('_rd_')
  param = param.split('_north_').join('_n_')
  param = param.split('_east_').join('_e_')
  param = param.split('_south_').join('_s_')
  param = param.split('_west_').join('_w_')
  param = param.split('_northeast_').join('_ne_')
  param = param.split('_southeast_').join('_se_')
  param = param.split('_southwest_').join('_sw_')
  param = param.split('_northwest_').join('_nw_')
  return param
}

window.PropertiesHelper = PropertiesHelper
