const poBidRequestBannerTracking = [
  function () {
    return {
      title: 'Po Bid Request Tracking',
      bannerSubmitBid(bidRequestId) {
        Track.send(this.title, {
          action: 'banner submit bid',
          url: location.pathname,
          bidRequestId,
        })
      },
      bannerNotReady(bidRequestId) {
        Track.send(this.title, {
          action: 'banner not ready',
          url: location.pathname,
          bidRequestId,
        })
      },
      bannerLostInterest(bidRequestId) {
        Track.send(this.title, {
          action: 'banner lost interest',
          url: location.pathname,
          bidRequestId,
        })
      },
      dashboardSubmitBid(bidRequestId) {
        Track.send(this.title, {
          action: 'dashboard submit bid',
          url: location.pathname,
          bidRequestId,
        })
      },
      dashboardNotReady(bidRequestId) {
        Track.send(this.title, {
          action: 'dashboard not ready',
          url: location.pathname,
          bidRequestId,
        })
      },
      postponeModalView(bidRequestId) {
        Track.send(this.title, {
          action: 'postpone modal view',
          url: location.pathname,
          bidRequestId,
        })
      },
      openArchiveModal(bidRequestId, source) {
        Track.send(this.title, {
          action: 'open archive modal',
          url: location.pathname,
          bidRequestId,
          source,
        })
      },
    }
  },
]

export default poBidRequestBannerTracking
