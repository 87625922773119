/* global angular, UserData, isMobile */
angular.module('bzReviewsModule').directive('reviewFormTrigger', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        contractor: '<',
        review: '=',
        referralSource: '@',
      },
      controller: [
        '$element',
        'modalScreenService',
        function ($element, modalScreenService) {
          this.$onInit = function () {
            $element.click(
              function ($event) {
                this.open()

                if ($event) {
                  $event.preventDefault()
                }
              }.bind(this)
            )
          }

          this.open = function () {
            // redirect to standalone page for mobile devices
            if (isMobile.check({ phoneOnly: true })) {
              this.redirectToStandaloneForm()
            } else {
              modalScreenService.initDetached({
                controller: 'ReviewModalCtrl',
                templateUrl: 'reviews/review_form_wrapper',
                windowClass: 'page-modal review-form-modal',
                backdrop: false,
                inputData: {
                  contractor: this.contractor,
                  review: this.review,
                },
              })
            }
          }

          this.redirectToStandaloneForm = function () {
            if (this.review.id) {
              document.location =
                '/contractor/' +
                this.contractor.alias +
                '/reviews/' +
                this.review.id +
                '/edit'
            } else {
              document.location =
                '/contractor/' + this.contractor.alias + '/reviews/new'
            }
          }

          this.getReferralSource = function (data) {
            return data && data.referral_source
              ? data.referral_source
              : this.dom.data('referral-source')
          }
        },
      ],
    }
  },
])
