/* global angular, isMobile, bugsnagClient */
import RageTracker from 'Vanilla/services/rageTracker'

angular.module('bzProjectFormModule').component('phoneScreen', {
  bindings: {
    project: '=',
    save: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/phone'
      )
    },
  ],
  controller: [
    '$timeout',
    '$element',
    '$scope',
    'projectFormScreenService',
    'trackProjectFormService',
    'urlConstants',
    'formValidationService',
    function (
      $timeout,
      $element,
      $scope,
      projectFormScreenService,
      trackProjectFormService,
      urlConstants,
      formValidationService
    ) {
      this.$onInit = function () {
        this.rageTracker = new RageTracker({
          title: 'SR Flow Phone Rage Click',
        })
        // add callback to be triggered when screen is changed to re-focus phone field cursor
        projectFormScreenService.setScreenCallbacks.push(
          this.setPhoneCursor.bind(this)
        )

        this.setPhoneCursor()
        this.resetErrors()
        this.trackedSemEvent = false
      }

      this.setPhoneCursor = function () {
        $timeout(
          function () {
            if (
              projectFormScreenService.currentScreen === 'phone' &&
              !this.project.projectOwner.phone
            ) {
              angular.element('.project-owner-phone-standalone').focus()
              angular
                .element('.project-owner-phone-standalone')
                .get(0)
                .setSelectionRange(0, 0)
            }
          }.bind(this),
          250
        )
      }

      this.resolvePhoneUpdates = () => {
        if (this.project.projectOwner.userId) {
          this.project.projectOwner.contactInfo = {
            email: { value: this.project.projectOwner.email },
            phone: { value: this.project.projectOwner.phone },
          }

          this.project.saveContactInfo().then((response) => {
            if (!this.errors.phone) {
              projectFormScreenService.showNextScreen()
            } else if (this.errors.phone && bugsnagClient) {
              bugsnagClient.notify(new Error('Error saving phone in SR flow'), {
                severity: 'error',
                metaData: {
                  params: this.project.projectOwner,
                  status: response.status,
                  statusText: response.statusText,
                },
              })
            }
          })
        }
      }

      this.showNextScreen = function () {
        if (!this.errors.phone) {
          this.rageTracker.click({
            ...this.project.analytics,
            serviceRequestId: this.project.id,
            screen: 'phone',
            value: this.project.projectOwner.phone || 'no input',
            email: this.project.projectOwner.email,
            displayedError: this.errors.phone,
            isInitialized: this.project.init,
          })
        }

        let hasErrors = false
        this.resetErrors()

        if (
          !formValidationService.isValidPhone(this.project.projectOwner.phone)
        ) {
          this.errors.phone = 'Please enter a valid phone number'
          hasErrors = true
        }

        if (!hasErrors && projectFormScreenService.isCurrentScreen('phone')) {
          if (
            !this.trackedSemEvent &&
            this.project.projectOwner.phone &&
            (typeof fbq !== 'undefined' || typeof gtag !== 'undefined')
          ) {
            this.trackedSemEvent = true
            trackProjectFormService.sendGenericAdEvent(
              'All SR Submission with Phone',
              {
                id: this.project.id,
                state: this.project.location.state,
              }
            )
          }
          this.save({
            callback: () => {
              $timeout(() => {
                this.resolvePhoneUpdates()
              })
            },
          })
        }
      }

      this.keyboardToNextScreen = function ($event) {
        if ($event.keyCode === 13) {
          $event.target.blur()

          $timeout(
            function () {
              this.showNextScreen()
            }.bind(this),
            200
          )
        }
      }

      this.headerText = function () {
        if (
          this.project.analytics &&
          this.project.analytics.potentialLeadFormVersion
        ) {
          return (
            'What is the best phone number for ' +
            (this.project.analytics.potentialLeadContractorBusinessName ||
              'the contractor') +
            ' to reach you at?'
          )
        } else {
          return 'What is the best phone number to reach you at?'
        }
      }

      this.isFormVersion = function (formVersion) {
        return this.project.analytics.formVersion === formVersion
      }

      this.termsOfServiceUrl = function () {
        return urlConstants.site.tosURL
      }

      this.resetErrors = function () {
        if (!this.errors) {
          this.errors = {}
        }

        this.errors.phone = null
      }

      this.showPrivacyDisclaimer = function () {
        return isMobile.check({ phoneOnly: true })
      }
    },
  ],
})
