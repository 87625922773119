/* global ContractorAPI, angular  */
angular.module('bzContractorModule').factory('contractorModel', [
  '$http',
  'modelService',
  'contractorData',
  'urlConstants',
  'attributeConversionService',
  function (
    $http,
    modelService,
    contractorData,
    urlConstants,
    attributeConversionService
  ) {
    return {
      contractor_api: new ContractorAPI(),
      data: contractorData,
      apiContractorsSignalsPath:
        urlConstants.api.v3.path + 'contractor_signals',
      apiTermsOfServiceAcceptancePath:
        urlConstants.api.v3.path + 'terms_of_service_acceptances',
      apiContractorRegistrationsPath:
        urlConstants.api.v1.path + 'registrations',
      apiCustomerPhonesPath: urlConstants.api.v1.path + 'customer_phones',
      apiCreatePhonePath: urlConstants.api.v1.path + 'customer_phone_create',
      apiContractorProfilePath: urlConstants.api.v1.path + 'contractor_profile',
      apiSubscriptionPath: urlConstants.api.v3.path + 'subscriptions',

      load: function (slug, options) {
        let contractorPath = urlConstants.api.v1.path + 'contractor/' + slug
        if (options && options.aboutTextWithFallback) {
          contractorPath += '&about_text_with_fallback=1'
        }
        return $http
          .get(contractorPath)
          .then(modelService.extractData.bind(this))
      },
      load_intro_message: function () {
        return this.contractor_api.load_intro_message()
      },
      follow: function (user_email) {
        return this.contractor_api.follow(this.data.id, user_email)
      },
      follow_reviews: function (user_email) {
        return this.contractor_api.follow_reviews(this.data.id, user_email)
      },
      update: function (contractor_id, fields, contentType) {
        return this.contractor_api.update(contractor_id, fields, contentType)
      },
      set_thumbnail: function (image_id) {
        return this.contractor_api.set_thumbnail(image_id)
      },
      revert_to_unclaimed: function (contractorId) {
        return $http
          .get(urlConstants.api.v1.path + 'contractor/revert/' + contractorId)
          .then(this.extractData.bind(this))
      },
      revert_to_unclaimed_and_convert_to_homeowner: function (contractor_id) {
        return this.contractor_api.revert_unclaimed_and_convert_to_homeowner(
          contractor_id
        )
      },
      snooze: function (snoozeData) {
        var data = {
          reason: snoozeData.reason,
          snoozed_until: snoozeData.snoozedUntil,
          disable: snoozeData.disable,
        }
        return $http.post(
          urlConstants.api.v2.path +
            '/manage/admin/contractor/' +
            snoozeData.contractorId +
            '/snooze',
          data
        )
      },
      selfSnooze: function (snoozeData) {
        return $http.post(
          urlConstants.api.v1.path +
            'contractors/' +
            snoozeData.contractorId +
            '/snoozes',
          {
            service_request_id: snoozeData.serviceRequestId,
            reason: snoozeData.reason || '',
            snooze_type: snoozeData.snoozeType,
            snooze_until: snoozeData.snoozeUntil,
          }
        )
      },
      block: function (block_type, contractor_id, notes, service_request_id) {
        return $http({
          url: urlConstants.api.v1.path + 'contractor_blocks',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({
            block_type: block_type,
            id: contractor_id,
            notes: notes,
            service_request_id: service_request_id,
          }),
          dataType: 'json',
        })
      },

      update_block: function (block_id, contractor_id, notes) {
        return $http({
          url:
            urlConstants.api.v1.path +
            'contractor_blocks' +
            '/' +
            contractor_id,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ block_id: block_id, notes: notes }),
          dataType: 'json',
        })
      },

      unblock: function (block_id, contractor_id) {
        return $http({
          url:
            urlConstants.api.v1.path +
            'contractor_blocks' +
            '/' +
            contractor_id,
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ block_id: block_id }),
          dataType: 'json',
        })
      },

      checked_in_with_contractor: function (contractor_id, timestamp) {
        return $http
          .put(urlConstants.api.v1.path + 'contractor_date_attributes', {
            contractor_date_attribute: {
              contractor_id: contractor_id,
              key: 'last_checked_in_at',
              value: timestamp,
            },
          })
          .then(function (response) {
            return response.data
          })
      },
      display_contractor_phone: function (contractor_id) {
        return this.contractor_api.display_contractor_phone(contractor_id)
      },
      display_routing_phone: function (contractor_id) {
        return this.contractor_api.display_routing_phone(contractor_id)
      },
      leadsSampleDone: function (contractor) {
        return $http
          .put(urlConstants.api.v1.path + 'contractor_attributes', {
            contractor_attribute: {
              contractor_id: contractor.id,
              key: 'leads_sample_done',
              value: 'true',
            },
          })
          .then(function (response) {
            return response.data
          })
      },
      updateClaim: function (contractor) {
        if (!contractor.contractor_id) {
          contractor.contractor_id = contractor.id
        }
        return $http
          .put(
            this.apiContractorRegistrationsPath + '/' + contractor.user_id,
            contractor
          )
          .then(this.extractData)
      },
      profileSetupDone: function (contractor) {
        return $http
          .put(urlConstants.api.v1.path + 'contractor_attributes', {
            contractor_attribute: {
              contractor_id: contractor.id,
              key: 'profile_setup_done',
              value: 'true',
            },
          })
          .then(function (response) {
            return response.data
          })
      },
      acceptTermsOfService: function (
        contractor,
        acceptableId,
        acceptableType,
        rule
      ) {
        return $http.post(this.apiTermsOfServiceAcceptancePath, {
          acceptable_id: acceptableId,
          acceptable_type: acceptableType,
          rule: rule,
        })
      },
      createPhone: function (contractorId, phoneNumber, data) {
        return $http
          .post(this.apiCreatePhonePath + '/' + phoneNumber, {
            callable_id: contractorId,
            callable_type: 'Contractor',
            bypass_authorization: data.bypassAuthorization,
            accept_tcpa: data.acceptTcpa,
          })
          .then(this.extractData)
      },
      updatePhone: function (contractorId, phone, data) {
        return $http
          .put(this.apiCustomerPhonesPath + '/' + phone.number, {
            callable_id: contractorId,
            callable_type: 'Contractor',
            bypass_authorization: data.bypassAuthorization,
            accept_tcpa: data.acceptTcpa,
          })
          .then(this.extractData)
      },
      findExistingContractorByEmail: function (email) {
        var url =
          this.apiContractorRegistrationsPath +
          '/find_existing_contractor?email=' +
          encodeURIComponent(email)

        return $http.get(url).then(this.extractData.bind(this))
      },
      updateYelpId: function (contractorId, yelpId) {
        var url =
          this.apiContractorProfilePath + '/' + contractorId + '/yelp_id'

        return $http
          .put(url, { yelp_id: yelpId })
          .then(this.extractData.bind(this))
      },
      updateProfile: function (contractorId, params) {
        return $http
          .put(this.apiContractorProfilePath, {
            contractor_id: contractorId,
            contractor_profile: params,
          })
          .then(this.extractData.bind(this))
      },
      getSimilarContractors: function (contractorId, options) {
        return $http
          .get(
            urlConstants.api.v1.path +
              'contractor/' +
              contractorId +
              '/similar' +
              modelService.queryString(options)
          )
          .then(modelService.extractData.bind(this))
      },
      cancelSubscription: function (id, contractorId) {
        return $http
          .put(this.apiSubscriptionPath + '/' + id, {
            contractor_id: contractorId,
          })
          .then(this.extractData)
      },
      updateProjectBudgetMin: function (data) {
        return $http
          .put(
            urlConstants.api.v1.path + 'contractor_project_budget_mins',
            attributeConversionService.camelToUnderscoreDeepAttributes(data)
          )
          .then(this.extractData)
      },
      extractData: function (response) {
        return response.data ? response.data : null
      },
    }
  },
])
