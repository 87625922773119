angular.module('bzYelpModule').factory('yelpDataService', [
  function () {
    return {
      parseBusinessData: function (input) {
        if (input) {
          var data = {}

          if (input.id !== undefined) {
            data.id = input.id
          }
          if (input.review_count !== undefined) {
            data.reviewCount = input.review_count
          }
          if (input.rating !== undefined) {
            data.rating = input.rating
          }
          if (input.rating_img_url !== undefined) {
            data.ratingImgUrl = input.rating_img_url
          }
          if (input.url !== undefined) {
            data.url = input.url
          }

          return data
        } else {
          return false
        }
      },
    }
  },
])
