import 'AngularBase/_components/bookmark'
/** bookmark for contractor gallery */
var ContractorGalleryBookmark = function () {
  this.init = function () {
    this.init_thumbnail()
    this.init_modal()
  }

  /**	thumbnails on contractor's profile individual gallery page	*/
  this.init_thumbnail = function () {
    var that = this

    var project_id = this.project_data().attr('data-project_id')
    var thumbnails = $('#gallery .image_item')
    thumbnails
      .find('a')
      .append(that.photo_bookmark_template('thumbnail_bookmark'))

    var photo_ids = that.make_photo_id_array(thumbnails) // make list of images to check bookmark state for

    // select bookmarks of photos that are bookmarked for the project
    that.is_photo_bookmarked_for_project(project_id, photo_ids, function (res) {
      photo_ids.forEach(function (photo) {
        if (res[photo.image_id] !== undefined && res[photo.image_id])
          $('.image_item[data-image_id="' + photo.image_id + '"]')
            .find('.thumbnail_bookmark')
            .addClass('selected')
      })
    })

    $('.thumbnail_bookmark').on('click', function (e) {
      var bookmark = $(this)
      that.toggle_photo_bookmark(
        bookmark,
        that.project_data().attr('data-project_id'),
        bookmark.closest('.image_item').data('image_id')
      )
      e.preventDefault()
      e.stopPropagation()
    })
  }

  /** gallery modal display when photo on profile gallery page is clicked */
  this.init_modal = function () {
    // not connected to UI
    $('body').on('profile_gallery_modal_slide', function (event, data) {
      $('.image_item').eq(data.index).data('image_id')
      //$(data.slide).append('<div>test</div>');
    })
  }
}

ContractorGalleryBookmark.prototype = new Bookmark()
ContractorGalleryBookmark.prototype.constructor = ContractorGalleryBookmark

window.ContractorGalleryBookmark = ContractorGalleryBookmark
