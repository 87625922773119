/* global bz_app, angular, UserData, isMobile */
import { compact } from 'lodash'

bz_app.factory('leadService', [
  'messageBannerService',
  '$rootScope',
  'conversationModel',
  '$sce',
  'urlConstants',
  'trackLeadsService',
  'bidModalService',
  'leadModel',
  'modalScreenService',
  'localStorageModel',
  'dateService',
  'archiveLeadModalService',
  'sessionStorageModel',
  'trackUcLeadsService',
  function (
    messageBannerService,
    $rootScope,
    conversationModel,
    $sce,
    urlConstants,
    trackLeadsService,
    bidModalService,
    leadModel,
    modalScreenService,
    localStorageModel,
    dateService,
    archiveLeadModalService,
    sessionStorageModel,
    trackUcLeadsService
  ) {
    return {
      claimedViaLeadKey: 'claimedViaLead',
      nextStepsModalTriggeredKey: 'nextStepsModalTriggered',
      snoozedBannerKey: 'snoozedBanner',
      init: function (options) {
        if (options) {
          if (options.lead) {
            this.showProjectOwnerIndicatedWinBanner(options.lead)
            this.showTerminatedBanner(options.lead)
            this.markConversationAsRead(options.lead)
            this.setOptInCtaLabel(options.lead)
            this.sendViewEvent(options.lead)
            this.triggerTermsModal(options)
            this.triggerNextStepsModal(options.lead)

            if (!options.lead.property.stats) {
              options.lead.loadPropertyStats()
            }
          }

          if (options.externalData) {
            this.showBidModal(options)
            this.showArchiveLeadModal(options)
          }
        }

        if (this.isMobile()) {
          angular.element('footer').hide()
        }
      },

      shouldShowProjectOwnerIndicatedWinBanner: function (lead) {
        return lead.askToConfirmWin()
      },

      showProjectOwnerIndicatedWinBanner: function (lead) {
        angular.element('#project-owner-indicated-win-banner').remove()
        if (this.shouldShowProjectOwnerIndicatedWinBanner(lead)) {
          messageBannerService.show({
            hideIfGlobal: true,
            selector: 'project-owner-indicated-win-banner',
            position: 'block',
            displayDuration: 0,
            contentDirective: 'project-owner-indicated-win-banner',
            data: {
              lead: lead,
            },
          })

          this.initWinConfirmEvent(lead)
        }
      },

      shouldShowUnlockedLeadBanner: function (lead) {
        return this.shouldShowProjectOwnerIndicatedWinBanner(lead)
      },

      initWinConfirmEvent: function (lead) {
        var destroyConfirmListener = $rootScope.$on(
          'contractorConfirmedWin',
          function ($event, data) {
            destroyConfirmListener()

            lead.markHire(null, { hireSource: 'PO Indicated Win Banner' }).then(
              function (response) {
                if (response && response.success) {
                  this.redirectToCheckout(lead)
                }
              }.bind(this)
            )
          }.bind(this)
        )
      },

      redirectToCheckout: function (lead) {
        document.location = '/checkout/' + lead.project.id
      },

      showTerminatedBanner: function (lead) {
        if (lead.isDisconnectedForNoContact()) {
          messageBannerService.show({
            selector: 'lead-terminated-banner',
            position: 'block',
            displayDuration: 0,
            contentDirective: 'lead-terminated-banner',
            data: {
              message:
                'You have been removed from this project because you did not call or message the project owner within 4 business hours.',
              lead: lead,
            },
          })
        }
      },

      showBidModal: function (options) {
        if (options.externalData.bid_modal === '1') {
          bidModalService.show({
            lead: options.lead,
          })
        }
      },

      showArchiveLeadModal: function (options) {
        if (options.externalData.archive_lead_modal === '1') {
          archiveLeadModalService.show({
            lead: options.lead,
          })
        }
      },

      markConversationAsRead: function (lead) {
        if (
          lead.conversation &&
          !UserData.current_staff_user() &&
          !UserData.is_admin()
        ) {
          conversationModel.markAsRead(lead.conversation.id).then(
            function (response) {
              angular.forEach(lead.conversation.messages, function (message) {
                message.status = 'read'
              })
            }.bind(this)
          )
        }
      },
      zillowTooltip: function () {
        return $sce.trustAsHtml(
          '&copy; Zillow, Inc., 2006-2014. Use is subject to <a href="' +
            urlConstants.external.zillow.tosURL +
            '" target="_blank">Terms of Use</a>. What\'s a Zestimate? See more details for this property on Zillow'
        )
      },
      sendViewEvent: function (lead) {
        if (!this.sentViewEvent) {
          if (lead.showSimpleLockedView) {
            trackUcLeadsService.view(lead)
          } else {
            trackLeadsService.view(lead, { ctaLabel: this.optInCtaLabel })
          }
          this.sentViewEvent = true
        }
      },
      isMobile: function () {
        return isMobile.check({ phoneOnly: true }) ? true : false
      },
      showLeadReferralModal: function (lead) {
        modalScreenService.initDetached({
          controller: 'LeadReferralModalCtrl',
          templateUrl: 'leads/locked_lead/lead_referral_modal',
          windowClass: 'v3-modal-simple medium clear',
          backdrop: true,
          inputData: {
            lead: lead,
          },
        })
      },
      showTermsModal: function (lead, steps) {
        const termsModal = modalScreenService.initDetachedComponent({
          component: 'leadTerms',
          windowClass: 'lead-terms-modal v3-modal-simple medium',
          keyboard: false,
          backdrop: 'static',
          inputData: { lead, steps },
        })

        const $body = $('body')
        $body.addClass('prevent-scroll')
        termsModal.closed.then(() => {
          $body.removeClass('prevent-scroll')
        })
      },
      triggerTermsModal: function ({ lead }) {
        const steps = this.termsModalSteps(lead)
        if (steps.length) this.showTermsModal(lead, steps)
      },
      termsModalSteps: (lead) => {
        const { contractor } = lead
        if (contractor.claimed || lead.isUnlocked()) return []

        const {
          phoneNumber,
          gaveNonAdminTcpaConsent,
          hasSignedReferralAgreement,
        } = contractor

        return compact([
          (!phoneNumber || !gaveNonAdminTcpaConsent) && 'tcpa',
          !hasSignedReferralAgreement && 'tos',
        ])
      },
      showNextStepsModal: function (lead) {
        modalScreenService.initDetached({
          controller: 'NextStepsModalCtrl',
          templateUrl: 'leads/unlocked_lead/next_steps_modal',
          windowClass: 'v3-modal lead-modal next-steps-modal',
          keyboard: true,
          backdrop: true,
          inputData: {
            lead: lead,
          },
        })
      },
      triggerNextStepsModal: function (lead) {
        if (localStorageModel.isPresent()) {
          if (
            lead.project.id === localStorageModel.get(this.claimedViaLeadKey)
          ) {
            this.showNextStepsModal(lead)
            localStorageModel.remove(this.claimedViaLeadKey)
            sessionStorageModel.set(this.nextStepsModalTriggeredKey, true)
          }
        }
      },
      triggerNextStepsModalAfterReload: function (serviceRequestId) {
        if (localStorageModel.isPresent()) {
          localStorageModel.set(this.claimedViaLeadKey, serviceRequestId)
        }
      },
      businessDaysFromPoUpdate: function (lead) {
        var updateDate = lead.outcome.poStatusUpdatedAt
          ? new Date(lead.outcome.poStatusUpdatedAt)
          : new Date()
        return dateService.businessDaysApart(updateDate, new Date())
      },
      setOptInCtaLabel: function (lead) {
        this.optInCtaLabel = 'Yes, contact client'
      },
    }
  },
])
