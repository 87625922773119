/* globals angular */
import recruitingConsoleService from './services/recruiting_console_service'

angular
  .module('bzMarketingModule', [])
  .service('recruitingConsoleService', recruitingConsoleService)

angular.module('bzMarketingModule').run([
  'partnershipsBannerService',
  'recruitingConsoleService',
  function (partnershipsBannerService, recruitingConsoleService) {
    partnershipsBannerService.init()

    if (UserData.is_production_environment()) {
      recruitingConsoleService.init()
    }
  },
])
