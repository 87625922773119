/* global angular, $ */
angular.module('bzCommonUtilitiesModule').factory('uploadModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  'filepickerService',
  'modelService',
  function (
    $http,
    urlConstants,
    attributeConversionService,
    filepickerService,
    modelService
  ) {
    return {
      apiImagesV1Path: urlConstants.api.v1.path + 'images',
      add: function (object) {
        var data =
          attributeConversionService.camelToUnderscoreDeepAttributes(object)

        return $http
          .post(this.apiImagesV1Path, data)
          .then(modelService.extractData)
      },
      inkBlobToAttachment: function (file) {
        return {
          name: file.filename,
          filename: file.filename,
          filepickerSlug: file.url,
          file: true,
          description: file.mimetype,
          s3Slug: file.key,
          cloudfrontSlug: filepickerService.getCloudFrontUrl(file.url),
        }
      },
    }
  },
])
