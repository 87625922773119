export const dateToShortMonth = (d) => {
  const shortMonthNameList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  return shortMonthNameList[d.getMonth()]
}

export const timeDifference = (startDate, endDate, unit = 'seconds') => {
  let unitConversion

  if (unit === 'seconds') {
    unitConversion = 1000
  } else if (unit === 'minutes') {
    unitConversion = 1000 * 60
  } else if (unit === 'hours') {
    unitConversion = 1000 * 60 * 60
  } else if (unit === 'days') {
    unitConversion = 1000 * 60 * 60 * 24
  } else if (unit === 'weeks') {
    unitConversion = 1000 * 60 * 60 * 24 * 7
  } else if (unit === 'months') {
    unitConversion = 1000 * 60 * 60 * 24 * 30
  } else if (unit === 'years') {
    unitConversion = 1000 * 60 * 60 * 24 * 365
  } else {
    throw new Error(`'${unit}' is a invalid unit`)
  }

  return (endDate - startDate) / unitConversion
}

export const addDaysToDate = (d, days) =>
  new Date(d.setDate(d.getDate() + days))
