/* global angular */
angular.module('bzMessageBannerModule').factory('messageBannerService', [
  '$compile',
  '$rootScope',
  'globalBannerService',
  function ($compile, $rootScope, globalBannerService) {
    return {
      show: function (options) {
        options = Object.assign(
          {
            message: undefined,
            hideIfGlobal: false,
            selector: 'global-message-banner',
            position: 'overlay',
            contentDirective: 'message-banner-default-content',
            displayDuration: 3000,
            postInject: undefined,
            cssClass: undefined,
            isFixedTopMobile: false,
          },
          options || {}
        )

        if (options.hideIfGlobal) {
          $rootScope.$on('openGlobalBanner', () => {
            this.bannerInstance.hide()
          })
        } else {
          globalBannerService.close()
        }

        this.removePreviousBanner(options)
        this.bannerInstance = this.injectBanner(options)

        return this.bannerInstance
      },
      removePreviousBanner: function (options) {
        if (angular.element('#' + options.selector).length) {
          angular.element('#' + options.selector).remove()
        }
      },
      injectBanner: function (options) {
        const newScope = $rootScope.$new(true)
        newScope.options = options

        var messageBanner = $compile(`
					<message-banner template-url="${options.templateUrl || ''}" options="options">
					</message-banner>
				`)(newScope)

        angular.element('body').prepend(messageBanner)
        messageBanner.hide = function () {
          this.addClass('message-banner-hidden')
        }

        if (options.postInject) {
          options.postInject(messageBanner)
        }

        return messageBanner
      },
    }
  },
])
