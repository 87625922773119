bz_app.directive('protectedField', [
  '$compile',
  'urlService',
  function ($compile, urlService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {},
      transclude: true,
      templateUrl: urlService.templateUrl(
        '_components/directives/protected_field'
      ),
      link: function ($scope, element, attr, controller, transclude) {
        $scope.fieldDisabled = $scope.locked = true
        $scope.password = ''

        transclude($scope, function (clone) {
          clone.attr('ng-disabled', 'fieldDisabled')
          var input = $compile(clone)($scope)
          element.find('.field-placeholder').prepend(input)
        })
      },
    }
  },
])
