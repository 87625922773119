import template from './banner_manager.html'

const globalBannerManager = {
  restrict: 'A',
  controllerAs: '$ctrl',
  bindings: {
    mobileOnly: '@',
    desktopOnly: '@',
  },
  template,
  controller: [
    'attributeConversionService',
    '$uibModal',
    'urlConstants',
    'urlService',
    '$rootScope',
    'globalBannerTracking',
    'globalBannerData',
    'globalNotificationsModel',
    function (
      attributeConversionService,
      $uibModal,
      urlConstants,
      urlService,
      $rootScope,
      globalBannerTracking,
      globalBannerData,
      globalNotificationsModel
    ) {
      $rootScope.$on('closeGlobalBanner', (angularEvent, disabledBannerKey) => {
        if (!disabledBannerKey || disabledBannerKey === this.bannerKey) {
          this.closeBanner()
        }
      })

      $rootScope.$on('reloadGlobalBanner', () => {
        this.loadGlobalBanner({ shouldCacheResponse: true })
      })

      this.$onInit = function () {
        this.hasBannerBeenClosed = globalBannerData.isDisabled
        this.initialDisabledBannerKey = globalBannerData.disabledBannerKey
        this.isBannerShown = false
        this.bannerKey = undefined
        this.data = {}
        this.blockBannerKeys = [
          'UNPAID_INVOICE',
          'MISSING_WIN_PRICE',
          'ABANDONED_PAYMENT',
          'FAILED_PAYMENT',
          'QUESTIONABLE_WIN_AMOUNT',
          'UNCONFIRMED_WIN',
          'STALE_LEAD',
          'INVALID_LICENSE',
        ]

        this.loadGlobalBanner({
          isInitialLoad: true,
          shouldCacheResponse: true,
        })
      }

      this.loadGlobalBanner = ({
        isInitialLoad = false,
        shouldCacheResponse = true,
      }) => {
        const isMobilePhone = isMobile.check({ phoneOnly: true })

        if (!UserData.user_signed_in() || UserData.is_admin()) return // User must be signed in
        if (this.mobileOnly && !isMobilePhone) return
        if (this.desktopOnly && isMobilePhone) return

        globalNotificationsModel
          .getBannerToShow(shouldCacheResponse)
          .then((res) => {
            const convertedData =
              attributeConversionService.underscoreToCamelDeepAttributes(
                res.data
              )
            if (convertedData.alertKey) {
              $rootScope.$emit('openGlobalBanner')
              const bannerKey = convertedData.alertKey
              const hasAlreadyBeenClosed =
                this.hasBannerBeenClosed &&
                (!this.initialDisabledBannerKey ||
                  this.initialDisabledBannerKey === bannerKey)

              if (!hasAlreadyBeenClosed) {
                this.bannerKey = bannerKey
                this.data = convertedData.alertData
                this.isBannerShown = !!this.bannerKey
                if (isInitialLoad) {
                  globalBannerTracking.viewBanner(this.bannerKey)
                }
                if (!$('body').hasClass('banner-present')) {
                  $('body').addClass('banner-present')
                }
              }
            }
          })
      }

      this.closeBanner = () => {
        this.hasBannerBeenClosed = true
        this.bannerKey = undefined
        this.data = undefined
        this.isBannerShown = false
      }

      this.isBlockBanner = () => {
        return this.blockBannerKeys.indexOf(this.bannerKey) > -1
      }
    },
  ],
}

export default globalBannerManager
