const interactiveStreetView = {
  bindings: {
    resolve: '<',
    modalInstance: '<',
    close: '&',
    dismiss: '&',
  },
  template: `
		<div>
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true"
						ng-click="$ctrl.cancel()">&times;</button>
				<h3 class="modal-title text-center">Interactive street view</h3>
			</div>
			<div class="modal-body">
				<div class="section-body">
					<div id="street-view-pan"></div>
				</div>
			</div>
		</div>
	`,
  controller: [
    'trackMapService',
    'googleMapsStreetViewService',
    'locationService',
    function (trackMapService, googleMapsStreetViewService, locationService) {
      this.$onInit = () => {
        this.initStreetViewPan(this.resolve.inputData.address)
      }

      this.initStreetViewPan = (address) => {
        locationService.find_details(address).then((location) => {
          if (googleMapsStreetViewService.checkValidLatLng(location)) {
            trackMapService.mapPanorama('shown', this.referralSource())

            let panInit = false
            let panorama = new google.maps.StreetViewPanorama(
              document.getElementById('street-view-pan'),
              {
                position: location,
                // pov: {heading: 90, pitch: 0},
                addressControl: false,
                fullscreenControl: false,
              }
            )

            let panListener = panorama.addListener('pov_changed', () => {
              if (panInit) {
                trackMapService.mapPanorama('engaged', this.referralSource())
                google.maps.event.removeListener(panListener)
              } else {
                panInit = true
              }
            })
          }
        })
      }

      this.ok = () => {
        this.close()
      }

      this.cancel = ($event) => {
        this.dismiss()

        if ($event) {
          $event.preventDefault()
        }
      }

      this.referralSource = () => {
        return this.resolve.inputData.referralSource || null
      }
    },
  ],
}

export default interactiveStreetView
