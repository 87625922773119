bz_app.factory('reviewResponseData', [
  '$rootScope',
  '$sce',
  function ($rootScope, $sce) {
    return {
      id: null,
      review_id: null,
      status: null,
      body: null,
      created_at: null,

      //   set: function(review) {
      //     this.id = review.id;
      //     this.stars = review.stars;
      //     this.body = review.body;
      //     this.name = review.name;
      //     this.created_at = review.created_at;
      //     this.location = review.location;
      //   }
    }
  },
])
