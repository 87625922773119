/* global angular, isMobile */
angular.module('bzProjectFormModule').component('competingBidsScreen', {
  bindings: {
    project: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        '_components/components/project_multi_step_form/competing_bids'
      )
    },
  ],
  controller: [
    'urgencyTypesModel',
    'projectFormScreenService',
    'contractorModel',
    'urlService',
    'numberService',
    'projectFormService',
    'trackProjectFormService',
    function (
      urgencyTypesModel,
      projectFormScreenService,
      contractorModel,
      urlService,
      numberService,
      projectFormService,
      trackProjectFormService
    ) {
      this.$onInit = function () {
        this.answers = [
          { value: 3, text: 'Yes (recommended)' },
          { value: 1, text: 'No' },
        ]

        this.utils = urlService
        this.numberService = numberService
        this.allContractors = [] // used to determine labels for similar contractor tiles
        this.setSelectedContractor()
        this.setSimilarContractors()
        this.screenVersion = 2 //(Math.round(Math.random())) ? 1 : 2;
      }

      this.setSelectedContractor = () => {
        this.selectedContractor = null

        if (this.project.analytics.potentialLeadContractorId) {
          contractorModel
            .load(this.project.analytics.potentialLeadContractorId)
            .then((response) => {
              if (response.success && response.contractor) {
                this.selectedContractor = response.contractor
                this.selectedContractor.coverUrl = this.contractorCoverUrl(
                  this.selectedContractor
                )
                this.allContractors.push(this.selectedContractor)
                this.allContractors.sort(this.sortContractorsByScoreDesc)
              }
            })
        }
      }

      this.setSimilarContractors = () => {
        this.similarContractors = []

        if (this.project.analytics.potentialLeadContractorId) {
          contractorModel
            .getSimilarContractors(
              this.project.analytics.potentialLeadContractorId,
              { limit: 2 }
            )
            .then((response) => {
              if (
                response.success &&
                response.similar &&
                response.similar.count
              ) {
                this.similarContractors = response.similar.contractors || []

                angular.forEach(this.similarContractors, (contractor) => {
                  contractor.coverUrl = this.contractorCoverUrl(contractor)
                })

                this.allContractors.push(...this.similarContractors)
                this.allContractors.sort(this.sortContractorsByScoreDesc)
                this.similarContractors.sort(this.sortContractorsByScoreDesc)
              }
            })
        }
      }

      this.setAnswer = (answer) => {
        this.project.expectedResponses = answer.value
        this.project.isPotentialLead = projectFormService.shouldBePotentialLead(
          this.project.expectedResponses,
          this.project.summary.budget
        )
          ? 1
          : 0
        this.showNextScreen()

        trackProjectFormService.selectedCompetingBidsAnswer({
          screenVersion: this.screenVersion,
          selectedResponses: answer.value,
        })
      }

      this.showNextScreen = () => {
        if (projectFormScreenService.isCurrentScreen('competing-bids')) {
          projectFormScreenService.showNextScreen()
        }
      }

      this.headerText = () => {
        return 'Would you also like 2 competing quotes from other contractors?'
      }

      this.isFormVersion = (formVersion) => {
        return this.project.analytics.formVersion === formVersion
      }

      this.contractorTileSubtext = (contractor) => {
        if (this.allContractors.length) {
          if (contractor.id === this.allContractors[0].id) {
            return 'Most experienced'
          } else if (contractor.id === this.similarContractors[0].id) {
            return 'BZ favorite'
          } else {
            return 'Budget conscious'
          }
        }
        return ''
      }

      this.sortContractorsByScoreDesc = (a, b) => {
        let comparison = 0
        if (a.score < b.score) {
          comparison = 1
        } else if (a.score > b.score) {
          comparison = -1
        }

        return comparison
      }

      this.contractorCoverUrl = (contractor) => {
        if (
          contractor.thumbnails &&
          contractor.thumbnails.length &&
          !contractor.thumbnails[0].generic
        ) {
          return contractor.thumbnails[0].url
        } else {
          return null
        }
      }
    },
  ],
})
