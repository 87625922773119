/* global bz_app */
bz_app.directive('archiveLink', [
  '$rootScope',
  '$state',
  'trackMessagingService',
  function ($rootScope, $state, trackMessagingService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        conversationId: '@',
      },
      template:
        '<a href="javascript:void(0)" ng-click="archiveConversation()">Archive</a>',
      controller: [
        '$scope',
        '$element',
        'conversationModel',
        function ($scope, $element, conversationModel) {
          $scope.archiveConversation = function () {
            conversationModel.data.id = $scope.conversationId
            conversationModel.archive().then(function () {
              trackMessagingService.conversationArchived($scope.conversationId)

              $state.go('messages', { status: 'inbox' })
              $rootScope.$broadcast('conversation-archived')
            })
          }
        },
      ],
    }
  },
])
