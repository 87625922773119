/* global angular */
import bzScore from './components/bz_score_component'
import bzScoreDistributionChart from './components/bz_score_distribution_chart_component'
import bzScoreFactorsChart from './components/bz_score_factors_chart_component'

import BZScoreModalCtrl from './controllers/bz_score_modal_controller'
import showScoreExplanationModal from './directives/show_score_explanation_modal_directive'

import bzScoreService from './services/bz_score_service'
import trackBzScoreService from './services/track_bz_score_service'

const BzScoreModule = angular
  .module('bzScoreModule', ['bzCommonUtilitiesModule'])
  .component('bzScore', bzScore)
  .component('bzScoreDistributionChart', bzScoreDistributionChart)
  .component('bzScoreFactorsChart', bzScoreFactorsChart)
  .controller('BZScoreModalCtrl', BZScoreModalCtrl)
  .directive('showScoreExplanationModal', showScoreExplanationModal)
  .service('bzScoreService', bzScoreService)
  .service('trackBzScoreService', trackBzScoreService).name

export default BzScoreModule
