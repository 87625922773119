/* global window */
import { track } from 'Shared/utilities/tracking'

const trackAction = (data) => {
  const currentUserId =
    window.bzGlobal.currentUser && window.bzGlobal.currentUser.id

  track('Referral Agreement Action', {
    ...data,
    currentUserId,
    url: window.location.pathname,
  })
}

export const trackPageView = (source, isLargeProject) =>
  trackAction({
    action: 'view page',
    source,
    largeProject: isLargeProject,
  })

export const trackAcceptDocument = (source, isLargeProject) =>
  trackAction({
    action: 'accept document',
    source,
    largeProject: isLargeProject,
  })

export const trackWriteSignature = (source, isLargeProject) =>
  trackAction({
    action: 'write signature',
    source,
    largeProject: isLargeProject,
  })

export const clickModalAgreementLink = () =>
  trackAction({
    action: 'click modal agreement link',
    agreementVersion: 2,
  })
