export const RA_PLACEHOLDER_TEXT = 'To agree, type your name here'

export const RA_HEADER_SHOVEL_COPY_HTML = `
	Get shovel-ready projects from BuildZoom.<br>
	Only pay us if you get hired
`

export const RA_PROJECT_ACCESS = 'Before you access this project'

export const RA_BODY_COPY = `
	We have the fairest approach in the industry.
	Only pay us if you get hired for a project.
	By signing, you agree to pay BuildZoom a referral fee based on the
	value of the overall project.
`

export const RA_BODY_EXPIRED_COPY =
  'To continue receiving projects, please sign our updated referral agreement.'

export const RA_CLB_HEADER = 'Get shovel-ready projects from BuildZoom.'

export const RA_BODY_ONLY_CLB_UNSIGNED = `
<div class="text-left">
  <p>To start receiving projects, we require you to sign BuildZoom’s Referral Agreement. <a href="https://www.buildzoom.com/contractor-faq">See how BuildZoom works by clicking here</a>.</p>
  <p>As soon as you sign, you’ll just need to set your <a href="https://www.buildzoom.com/manage/contractor/services">Project Preferences</a> to start receiving leads. We’re excited to have you join the network!</p>
</div>
`

export const RA_BODY_UNSIGNED_CLB_WITH_OTHERS_PRESENT = `
<div class="text-left">
  <p>To continue receiving projects, we require a newly signed Referral Agreement. BuildZoom is changing the way fees are charged — depending on the project type, you will be charged either a Connection Fee or an Award Fee.</p>
  <br>
  <p class="small-font"><b>The Award Fee</b> is what you’re already familiar with — pay a percentage of the final contract price after you win a job.</p>
  <p class="small-font"><b>The Connection Fee</b> is our new fee type that charges a contractor a flat fee after successfully connecting with a project owner. This means when you actually talk to a project owner, you’ll pay a fee at the beginning of the following month.</p>
  <p class="small-font">To learn more, visit our <a href="https://www.buildzoom.com/contractor-faq">contractor faq page</a>.</p>
  <p class="small-font">You can also see which fee types are assigned to which project types by <a href="https://docs.google.com/document/d/1zVdL-BzLEhaVMJi2ykQa_C63qdQX8nUMZQagXBrt46E/edit">clicking here</a>.</p>
  <p class="small-font">*Note: Projects will only be assigned one type of fee. This means if a project charges a connection fee, you will not be charged an award fee for that project.</p>
  <p class="small-font">Have questions? Reach out to <a mailto="support@buildzoom.com">support@buildzoom.com</p>
</div>
`
