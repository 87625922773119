/* global angular, Track, UserData */
angular.module('bzNavbarModule').factory('trackNavbarActionsService', [
  function () {
    return {
      title: 'Navbar Action',
      projectsLinkClick: function (selector) {
        Track.send_link(
          selector,
          this.title,
          this.defaultData({ action: 'projects link click' })
        )
      },
      messageClick: function (callback) {
        Track.send_callback(
          this.title,
          this.defaultData({ action: 'message click' }),
          callback
        )
      },
      allMessageClick: function (callback) {
        Track.send_callback(
          this.title,
          this.defaultData({ action: 'view all messages click' }),
          callback
        )
      },
      alertClick: function (callback) {
        Track.send_callback(
          this.title,
          this.defaultData({ action: 'alert click' }),
          callback
        )
      },
      linkClicked: function (selector, action) {
        Track.send_link(
          selector,
          this.title,
          this.defaultData({ action: action })
        )
      },
      defaultData: function (input) {
        return angular.extend(
          {
            action: input.action ? input.action : '',
            user_type: UserData.present() ? UserData.user_role() : 'unknown',
            platform: isMobile.check() ? 'mobile' : 'desktop',
          },
          input
        )
      },
    }
  },
])
