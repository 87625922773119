/* global angular, $, isMobile */
angular
  .module('bzPotentialLeadFormModule')
  .factory('potentialLeadFormService', [
    'landingPageService',
    'utmService',
    function (landingPageService, utmService) {
      return {
        allowedFormVersions: [3.4],
        cookieName: 'coContactFormVersion',
        formVersion: null,
        setFormVersion: function () {
          var formVersion = this.allowedFormVersions[0]

          if ($.cookie(this.cookieName) !== undefined) {
            formVersion = parseFloat($.cookie(this.cookieName))

            if (this.allowedFormVersions.indexOf(formVersion) === -1) {
              formVersion =
                this.allowedFormVersions[
                  Math.floor(Math.random() * this.allowedFormVersions.length)
                ]
              $.cookie(this.cookieName, formVersion, { expires: 90, path: '/' })
            }
          } else {
            formVersion =
              this.allowedFormVersions[
                Math.floor(Math.random() * this.allowedFormVersions.length)
              ]
            $.cookie(this.cookieName, formVersion, { expires: 7, path: '/' })
          }

          this.formVersion = formVersion
          return formVersion
        },
        getFormVersion: function () {
          return this.formVersion || this.setFormVersion()
        },
        newPotentialLead: function () {
          var source
          if (isMobile.check({ phoneOnly: true })) {
            source = 'mobile'
          } else if (isMobile.check()) {
            source = 'tablet'
          } else {
            source = 'desktop'
          }

          return {
            name: '',
            email: '',
            phone: null,
            message: '',
            contractorId: null,
            title: null,
            urgency: null,
            landingPage: landingPageService.getLandingPage(),
            referrer: landingPageService.getReferrer(),
            utmSource: utmService.getUtmSource(),
            utmMedium: utmService.getUtmMedium(),
            utmCampaign: utmService.getUtmCampaign(),
            utmTerm: utmService.getUtmTerm(),
            utmContent: utmService.getUtmContent(),
            referralSource: null,
            source: source,
            reftag: document.location.href,
            requestMoreContractors: null,
          }
        },
      }
    },
  ])
