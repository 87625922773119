/* global angular, $ */
angular.module('bzNavbarModule').directive('navbarTriggerMobile', [
  '$rootScope',
  '$window',
  function ($rootScope, $window) {
    return {
      restrict: 'A',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {},
      link: function (scope, element) {},
      controller: [
        '$element',
        '$window',
        function ($element, $window) {
          this.$onInit = function () {
            this.menu = $element.closest('.nav-popover')

            if (isMobile.check()) {
              $element.click(
                function () {
                  if (this.isOpen()) {
                    this.closeMenu()
                  } else {
                    this.openMenu()
                  }
                }.bind(this)
              )

              $rootScope.$on(
                'closeBulletinMenus',
                function () {
                  this.closeMenu()
                }.bind(this)
              )
            }
          }

          this.openMenu = function () {
            this.closeAllMenus()

            this.menu.addClass('nav-popover-open')

            $.scrollLock(true)
          }

          this.closeMenu = function () {
            this.menu.removeClass('nav-popover-open')

            $.scrollLock(false)
          }

          this.closeAllMenus = function () {
            angular.element('.nav-popover').removeClass('nav-popover-open')

            this.closeMainMenu()

            $.scrollLock(false)
          }

          this.closeMainMenu = function () {
            if (!$('.navbar-toggle').hasClass('collapsed')) {
              $('.navbar-toggle').click()
            }
          }

          this.isOpen = function () {
            return this.menu.hasClass('nav-popover-open')
          }
        },
      ],
    }
  },
])
