window.isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i)
  },
  AndroidPhone: function () {
    return (
      navigator.userAgent.match(/Android/i) &&
      navigator.userAgent.match(/Mobile/i)
    )
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  iOSPhone: function () {
    return navigator.userAgent.match(/iPhone|iPod/i)
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i)
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  },
  phone: function () {
    return (
      isMobile.AndroidPhone() ||
      isMobile.BlackBerry() ||
      isMobile.iOSPhone() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  },
  windowWidth: function () {
    return window.innerWidth < 768
  },
  check: function (options) {
    if (options && options.phoneOnly) {
      return isMobile.phone() != null ? true : false
    } else if (options && options.windowWidth) {
      return isMobile.windowWidth()
    } else {
      return !!isMobile.any()
    }
  },
}
