/* globals blueimp */
/* eslint no-unused-vars: 0 */
/* eslint no-empty: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-eq-null: 0 */

$('#blueimp-gallery[data-gallery-target]').on(
  'slide',
  function (event, index, slide) {
    var _gallery = $($(this).attr('data-gallery-target'))
    var $data_link
    var $image = _gallery.find('.image_item').eq(index)
    $data_link = $image.find('a.data_link')
    var contractor_image_url = $image
      .find('.contractor_logo_wrapper img')
      .attr('src')
    var image_id = $data_link.data('image_id')
    var favorite_button_method = $image.find('.favorite a').data('method')
    var favorite_button_url = $image.find('.favorite a').attr('href')
    var contractor_profile_url = $image.find('.contractor_name a').attr('href')

    var $slide = $('#blueimp-gallery').find('.slide').eq(index)
    $slide.find('.favorite-btn a').attr('href', favorite_button_url)
    $slide.find('.favorite-btn a').data('method', favorite_button_method)
    $slide.addClass('image_' + image_id)

    var img_height = parseInt($slide.find('.modal-body img').css('height'))
    $slide.find('.modal-content').css('height', img_height)

    if ($data_link.data('description') !== undefined) {
      $slide
        .find('.contractor_description')
        .text($data_link.data('description'))
    }
    if ($data_link.data('business_name') !== undefined) {
      $slide.find('.info-wrap img').attr('src', contractor_image_url)
      $slide
        .find('.contractor_info')
        .html(
          'Posted by: <a href=' +
            contractor_profile_url +
            '>' +
            $data_link.data('business_name') +
            '</a>'
        )
      $slide
        .find('.contractor_info')
        .append(
          "Location: <span class= 'contractor_city'> in " +
            $data_link.data('city') +
            '</span>, '
        )
      if ($data_link.data('city') !== undefined) {
      }
      if ($data_link.data('state') !== undefined) {
        $slide
          .find('.contractor_info')
          .append(
            "<span class='contractor_state'>" +
              $data_link.data('state') +
              '</span>'
          )
      }
    }
  }
)

if ($('a.link-carousel').length) {
  $('.zoom-icon').click(function (e) {
    e.preventDefault()
    $(this).parent().click()
  })
  $('a[data-carousel-item-src]').mouseover(function (e) {
    $(this).find('.fa-search').addClass('in')
  })
  $('a[data-carousel-item-src]').mouseout(function (e) {
    $(this).find('.fa-search').removeClass('in').addClass('out')
  })

  $('a.link-carousel').click(init_gallery)
}

var blueimp_gallery
var a_links = []
function init_gallery(event, cssSelector) {
  event.preventDefault()
  if (typeof blueimp_gallery == 'undefined' || !blueimp_gallery) {
    a_links = []
    let cssSelectorFormatted = cssSelector ? cssSelector : ''
    $(`${cssSelectorFormatted} [data-carousel-item-src]`).each(function (
      index
    ) {
      $(this).data('index', index)
      var o_link = {
        index: index,
        href: $(this).data('carousel-item-src'),
        type: 'text/html',
        style: '',
      }

      if ($(this).data('title') != null && $(this).data('title')) {
        o_link.title = $(this).data('title')
      }
      a_links.push(o_link)
    })
    blueimp_gallery = blueimp.Gallery(a_links, {
      onslide: function (index, slide) {
        //console.log(slide);
      },
      onclose: function () {
        $('body').css('overflow', '')
        blueimp_gallery = undefined
      },
      toggleSlideshowOnSpace: false,
    })
  }
  var target = event.target || event.srcElement

  if (typeof $(target).data('index') == 'undefined') {
    blueimp_gallery.slide(
      parseInt($(target).parents('[data-index]:first').data('index'))
    )
  } else {
    blueimp_gallery.slide(parseInt($(target).data('index')))
  }
}

window.init_gallery = init_gallery
