import template from './contractor_project_types_group.html'

/* global bz_app, angular */
angular
  .module('bzProjectPreferencesModule')
  .component('contractorProjectTypesGroup', {
    bindings: {
      group: '<',
      update: '&',
    },
    template,
    controller: [
      '$element',
      function ($element) {
        this.$onInit = function () {
          this.resetProjectTypesSelection()
        }

        this.$onChanges = function (changes) {
          this.resetProjectTypesSelection()
        }

        this.resetProjectTypesSelection = function () {
          this.setProjectTypesOffered()
          this.groupCheckboxElement = $element.find('.group-checkbox')[0]

          if (this.isPartialSelection()) {
            this.groupCheckboxElement.indeterminate = true
            this.groupExpanded = true
          } else {
            this.groupCheckboxElement.indeterminate = false
            this.groupExpanded = false
          }
        }

        this.setProjectTypesOffered = function () {
          this.projectTypesOffered = this.group.projectTypes
            .filter(function (type) {
              return type.selected
            })
            .map(function (type) {
              return type.id
            })

          this.update({
            groupId: this.group.id,
            projectTypesOffered: this.projectTypesOffered,
          })
        }

        this.isPartialSelection = function () {
          return (
            this.projectTypesOffered.length &&
            this.group.projectTypes.length !== this.projectTypesOffered.length
          )
        }

        this.groupCheckUpdate = function (selected, $event) {
          if (this.isPartialSelection()) {
            this.group.selected = true
            this.groupCheckboxElement.indeterminate = false
            selected = true
          }

          this.setAllProjectTypesSelect(selected)
          this.setProjectTypesOffered()
        }

        this.projectTypeCheckUpdate = function () {
          this.setProjectTypesOffered()
          this.setGroupSelect()
        }

        this.setAllProjectTypesSelect = function (selected) {
          angular.forEach(this.group.projectTypes, function (projectType) {
            projectType.selected = selected
          })
        }

        this.setGroupSelect = function () {
          if (this.isPartialSelection()) {
            this.groupCheckboxElement.indeterminate = true
          } else if (!this.projectTypesOffered.length) {
            this.group.selected = false
            this.groupCheckboxElement.indeterminate = false
          } else if (
            this.group.projectTypes.length === this.projectTypesOffered.length
          ) {
            this.group.selected = true
            this.groupCheckboxElement.indeterminate = false
          }
        }

        this.toggleGroupSelect = function () {
          this.group.selected = !this.group.selected
          this.groupCheckUpdate(this.group.selected)
        }

        this.toggleProjectTypeSelect = function (projectType, $event) {
          projectType.selected = !projectType.selected
          this.projectTypeCheckUpdate()
        }

        this.toggleGroupDisplay = function () {
          this.groupExpanded = !this.groupExpanded
        }
      },
    ],
  })
