angular.module('bzProposalsModule').component('proposalsDashboard', {
  bindings: {
    formAuthToken: '@',
    proposalTemplates: '<',
    proposals: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('proposals/proposals_dashboard')
    },
  ],
  controller: [
    'attributeConversionService',
    function (attributeConversionService) {
      this.$onInit = function () {
        this.proposalTemplates =
          attributeConversionService.underscoreToCamelDeepAttributes(
            this.proposalTemplates
          )
      }

      this.addTemplate = function (proposal) {
        this.proposalTemplates = this.proposalTemplates.concat(proposal)
      }

      this.removeTemplate = function (proposal) {
        this.proposalTemplates = this.proposalTemplates.filter(function (pr) {
          return pr.id !== proposal.id
        })
      }
    },
  ],
})
