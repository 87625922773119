angular.module('bzProposalsModule').component('proposalsListItem', {
  bindings: {
    proposal: '<',
    highlighted: '<',
    onDuplicate: '&',
    onSaveAsTemplate: '&',
    onDestroy: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('proposals/proposals_list_item')
    },
  ],
  controller: [
    'attributeConversionService',
    'urlConstants',
    'documentModel',
    'trackProposalService',
    '$scope',
    '$timeout',
    function (
      attributeConversionService,
      urlConstants,
      documentModel,
      trackProposalService,
      $scope,
      $timeout
    ) {
      this.$onInit = function () {
        this.proposalName = this.proposal.documentName
        this.destroyMessage =
          "Are you sure you want to delete '" + this.proposalName + "'?"
        this.dropdownOpen = false
        this.savingAsTemplate = false
        this.duplicating = false
        this.setStyles()
      }

      this.dotColors = {
        draft: '#b9b9b9',
        sent: '#5db1e2',
        opened: '#4abde9',
        accepted: '#40c7b8',
        denied: '#ffa717',
      }

      this.setStyles = function () {
        this.tabStyle = {
          'background-color':
            this.proposal.status === 'draft' ? '#b9b9b9' : '#4abde9',
        }
        this.dotStyle = {
          'background-color': this.dotColors[this.proposal.status],
        }
      }

      this.editUrl = function () {
        return urlConstants.proposals.documentPath + this.proposal.shareSlug
      }

      this.verifyArchive = function (ev) {
        ev.stopPropagation()

        if (window.confirm(this.destroyMessage)) this.archive()
      }

      this.saveAsTemplate = function (ev) {
        ev.stopPropagation()
        if (this.savingAsTemplate || this.duplicating) return
        this.savingAsTemplate = true

        documentModel.saveAsTemplate(this.proposal.shareSlug).then(
          function (proposal) {
            this.onSaveAsTemplate({ proposal: proposal })
            this.savingAsTemplate = false
            this.closeDropdown()
            trackProposalService.templateCreated(proposal.id, this.proposal.id)
          }.bind(this)
        )
      }

      this.duplicate = function (ev) {
        ev.stopPropagation()
        if (this.savingAsTemplate || this.duplicating) return
        this.duplicating = true

        documentModel.duplicate(this.proposal.shareSlug).then(
          function (proposal) {
            this.onDuplicate({ proposal: proposal })
            this.duplicating = false
            this.closeDropdown()
            trackProposalService.proposalDuplicated(
              proposal.id,
              this.proposal.id
            )
          }.bind(this)
        )
      }

      this.archive = function () {
        this.onDestroy()
        documentModel.destroy(this.proposal.shareSlug)
        trackProposalService.proposalDestroyed(this.proposal.id)
      }

      this.toggleDropdown = function () {
        this.dropdownOpen = !this.dropdownOpen

        if (this.dropdownOpen) {
          $timeout(
            function () {
              angular.element(document.body).one(
                'click',
                function () {
                  this.closeDropdown()
                  $scope.$apply()
                }.bind(this)
              )
            }.bind(this)
          )
        }
      }

      this.closeDropdown = function () {
        this.dropdownOpen = false
      }
    },
  ],
})
