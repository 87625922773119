import template from './bz_score_distribution_chart.html'

const bzScoreDistributionChart = {
  bindings: {
    contractor: '<',
    options: '<',
  },
  template,
  controller: [
    'bzScoreService',
    '$scope',
    function (bzScoreService, $scope) {
      this.$onInit = function () {
        this.options = Object.assign(
          {
            showRangeOnHover: true,
            hideText: false,
            showDarkText: false,
            shrinkRadius: false,
            customDetailText: undefined,
          },
          this.options
        )

        this.radius = this.chartRadius()
        this.strokeWidth = this.radius / 22
        this.segmentSpacing = 2
        this.chartWidth = this.radius * 2 + this.strokeWidth
        this.chartHeight = this.radius + this.strokeWidth

        this.segments = [
          {
            range: [0, 79],
            color: '#ee6374',
            label: {
              pos: this.posOnCircle(20),
              class: 'alert-label',
              score: '<80',
              header: 'Alert',
            },
            offset: -this.segmentSpacing,
            angle: 58 + this.segmentSpacing,
          },
          {
            range: [80, 89],
            color: '#ffde00',
            label: {
              pos: this.posOnCircle(70),
              class: 'poor-label',
              score: '80-89',
              header: 'Poor',
            },
            offset: 58,
            angle: 26,
          },
          {
            range: [90, 99],
            color: '#6bd27e',
            label: {
              pos: this.posOnCircle(92),
              class: 'licensed-label',
              score: '90-99',
              header: 'Licensed',
            },
            offset: 84,
            angle: 26,
          },
          {
            range: [100, 120],
            color: '#45ca9c',
            label: {
              pos: this.posOnCircle(135),
              class: 'great-label',
              score: '100-120',
              header: 'Great',
            },
            offset: 110,
            angle: 35,
          },
          {
            range: [
              121,
              Math.max(200, (this.contractor && this.contractor.score) || 0),
            ],
            color: '#4abde9',
            label: {
              pos: this.posOnCircle(160),
              class: 'excellent-label',
              score: '>120',
              header: 'Excellent',
            },
            offset: 145,
            angle: 35 + this.segmentSpacing,
          },
        ]

        this.clearSegment()
        this.setContractorSegment()
      }

      this.setContractorSegment = function () {
        if (!this.contractor || typeof this.contractor.score !== 'number')
          return

        var score = this.contractor.score
        angular.forEach(
          this.segments,
          function (segment) {
            if (segment.range[0] <= score && segment.range[1] >= score) {
              this.coSegment = segment
            }
          }.bind(this)
        )

        var coSegScoreRange = this.coSegment.range[1] - this.coSegment.range[0]
        var coScoreIntoRange = this.contractor.score - this.coSegment.range[0]
        var coScorePosInRange = coScoreIntoRange / coSegScoreRange
        var coIconTheta =
          this.coSegment.offset + this.coSegment.angle * coScorePosInRange
        var coMarkerPos = this.posOnCircle(coIconTheta, this.strokeWidth / 2)
        this.coMarkerStyle = angular.extend(coMarkerPos, {
          'background-color': this.coSegment.color,
        })
      }

      this.posOnCircle = function (theta, strokeOffset) {
        return bzScoreService.posOnCircle(theta, this.radius, strokeOffset || 0)
      }

      this.coordsForSegmentEndpoint = function (theta) {
        var pos = this.posOnCircle(theta, this.strokeWidth / 2)

        return [pos.left, pos.top].join(' ')
      }

      this.pathFor = function (segment) {
        return (
          'M' +
          this.coordsForSegmentEndpoint(segment.offset + this.segmentSpacing) +
          ' ' +
          'A ' +
          this.radius +
          ' ' +
          this.radius +
          ' 0, 0, 1, ' +
          this.coordsForSegmentEndpoint(
            segment.offset + segment.angle - this.segmentSpacing
          )
        )
      }

      this.selectSegment = function (segment) {
        this.activeSegment = segment
        $scope.$applyAsync()
      }

      this.clearSegment = function () {
        this.activeSegment = null
        $scope.$applyAsync()
      }

      this.isSegmentHidden = function (segment) {
        return this.activeSegment && this.activeSegment !== segment
      }

      this.isSegmentActive = function (segment) {
        return this.activeSegment && this.activeSegment === segment
      }

      this.onDeselect = function (segment) {
        if (isMobile.any()) this.clearSegment()
      }

      this.detailScore = function () {
        if (this.activeSegment && this.options.showRangeOnHover)
          return this.activeSegment.label.score
        return this.contractor && this.contractor.score
      }

      this.detailScoreColor = function () {
        if (this.options.showDarkText) return 'black'
        if (this.activeSegment) return this.activeSegment.color
        return this.coSegment && this.coSegment.color
      }

      this.detailText = function () {
        if (this.options.customDetailText) return this.options.customDetailText
        if (this.activeSegment && this.options.showRangeOnHover)
          return this.activeSegment.label.header
        return 'BZ Score'
      }

      this.chartRadius = function () {
        if ($(window).width() < 992) {
          return this.options.mobileChartRadius || 90
        } else {
          return this.options.desktopChartRadius || 140
        }
      }
    },
  ],
}

export default bzScoreDistributionChart
