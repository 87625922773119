import template from './generic_banner_button.html'

const genericBannerButton = {
  restrict: 'A',
  controllerAs: '$ctrl',
  bindings: {
    onClick: '&',
    shouldDisplayInfoCircle: '@',
    shouldDisplayPhoneNumber: '@',
    bannerPhone: '<',
  },
  transclude: true,
  template,
  controller: [
    'applicationConstants',
    function (applicationConstants) {
      this.$onInit = () => {
        if (this.shouldDisplayInfoCircle === undefined) {
          this.shouldDisplayInfoCircle = true
        }

        this.bannerPhone =
          this.bannerPhone || applicationConstants.support.bannerPhone
      }
    },
  ],
}

export default genericBannerButton
