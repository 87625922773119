angular.module('bzSearchModule').factory('contractorsSearchService', [
  function () {
    return {
      submitSearchForm: function (url, search_params) {
        var form = $('<form>', {
          method: 'POST',
          action: url,
        })
        form.append($('input[name="radius"]').val('metro'))
        form.append($('input[name="utf8"]').val('✓'))
        form.append(
          $('input[name="search[longitude]"]').val(search_params.longitude)
        )
        form.append(
          $('input[name="search[latitude]"]').val(search_params.latitude)
        )
        form.append($('input[name="search[city]"]').val(search_params.city))
        form.append($('input[name="search[radius]"]').val('metro'))
        form.append(
          $('input[name="search[keywords]"]').val(search_params.keywords.trim())
        )
        form.submit()
      },
    }
  },
])
