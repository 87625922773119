import 'AngularBase/_components/bookmark'
/** bookmark for photo search */
var PhotoSearchBookmark = function () {
  this.init = function () {
    this.init_thumbnail()
    this.init_modal()
  }

  /**	thumbnails displayed on image search	*/
  this.init_thumbnail = function (thumbnail_filter) {
    var that = this

    // reusable internal function for injecting bookmarks
    var inject_bookmarks = function () {
      var thumbnails = $('#images_wrapper .image_item').has(
        '.buttons .favorite'
      ) // only select photos that don't have bookmark yet
      thumbnails.find('.buttons .favorite').remove()
      thumbnails.append(that.photo_bookmark_template('thumbnail_bookmark'))
      that.init_existing_thumbnail(thumbnails)
    }

    if (thumbnail_filter !== undefined && thumbnail_filter === 'existing')
      this.init_existing_thumbnail($('#images_wrapper .image_item'))
    // for existing bookmark thumbnails, just initialize click logic
    else inject_bookmarks() // for non-existing bookmarks, do full inject logic

    // when more photos are loaded init them too
    $('body').on('photo_search_more_loaded', function (e) {
      inject_bookmarks() // put removable LIKE / addition of bookmark into function from above code and call here to inject
    })
  }

  /**	used for photo search thumbnails; init photos with bookmarks already injected	*/
  this.init_existing_thumbnail = function (thumbnails) {
    var that = this
    var project_id = this.project_data().attr('data-project_id')
    var photo_ids = this.make_photo_id_array(thumbnails) // make list of images to check bookmark state for

    // select bookmarks of photos that are bookmarked for the project
    this.is_photo_bookmarked_for_project(project_id, photo_ids, function (res) {
      photo_ids.forEach(function (photo) {
        if (res[photo.image_id] !== undefined && res[photo.image_id])
          $('.image_item[data-image_id="' + photo.image_id + '"]')
            .find('.thumbnail_bookmark')
            .addClass('selected')
      })
    })

    thumbnails.find('.thumbnail_bookmark').on('click', function (e) {
      var bookmark = $(this)
      that.toggle_photo_bookmark(
        bookmark,
        that.project_data().attr('data-project_id'),
        bookmark.closest('.image_item').data('image_id')
      )
    })
  }

  /**	gallery modal displayed when photo on image search is clicked	*/
  this.init_modal = function () {
    var that = this

    $('#blueimp-gallery').on('slide', function (event, index, slide) {
      var photo_id = $('.image_item').eq(index).data('image_id')
      var project_id = that.project_data().attr('data-project_id')

      var modal_content = $('#blueimp-gallery .modal-content')
      modal_content.find('.favorite').remove()
      modal_content.find('.photo_search_modal_bookmark').remove()
      modal_content.append(
        that.photo_bookmark_template('photo_search_modal_bookmark')
      )

      that.is_photo_bookmarked_for_project(
        project_id,
        [{ image_id: photo_id }],
        function (res) {
          if (res[photo_id] !== undefined && res[photo_id])
            $('.photo_search_modal_bookmark').addClass('selected')
        }
      )

      $('.photo_search_modal_bookmark').on('click', function (e) {
        that.toggle_photo_bookmark(
          $(this),
          project_id,
          photo_id,
          function (action) {
            var bookmark = $(
              '.image_item[data-image_id="' + photo_id + '"]'
            ).find('.thumbnail_bookmark') // set thumbnail bookmark of related photo

            // select/deselect bookmark on search result thumbnails based on modal action
            if (action == 'add') bookmark.addClass('selected')
            else if (action == 'remove') bookmark.removeClass('selected')
          }
        )
      })
    })
  }
}

PhotoSearchBookmark.prototype = new Bookmark()
PhotoSearchBookmark.prototype.constructor = PhotoSearchBookmark

window.PhotoSearchBookmark = PhotoSearchBookmark
