angular.module('bzProjectFormModule').directive('phoneInput', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        formData: '=',
      },
      template:
        '<input ng-model="formData.phone" type="tel" id="service_request_phone_number" name="service_request[phone_number]" class="form-control modern-form-control" placeholder="{{ placeholder }}" autocomplete="tel">',
      link: function (scope, element, attrs) {
        $timeout(function () {
          element.mask('(999) 999-9999')
        }, 50)
        if (!attrs.placeholder) {
          scope.placeholder = 'Phone number'
        }
      },
    }
  },
])
