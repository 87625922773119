/* global  $, angular, isMobile, getURLParameter */
angular
  .module('bzProjectPreferencesModule')
  .component('projectPreferencesContainer', {
    bindings: {
      data: '<',
      containerType: '@',
    },
    template:
      '<div ng-if="$ctrl.showOnPage" class="project-preferences-container">' +
      '<button type="button" class="close" ng-click="$ctrl.onCancel()">&times;</button>' +
      '<project-preferences-form contractor="$ctrl.contractor"contractor-type-options="$ctrl.contractorTypeOptions" project-type-groups="$ctrl.projectTypeGroups" on-submit="$ctrl.onSubmit()"></project-preferences-form>' +
      '</div>',
    controller: [
      'contractorProjectPreferencesService',
      'attributeConversionService',
      'modalScreenService',
      'urlConstants',
      'trackProjectPreferencesService',
      'localStorageModel',
      'sessionStorageModel',
      'globalNotificationsModel',
      function (
        contractorProjectPreferencesService,
        attributeConversionService,
        modalScreenService,
        urlConstants,
        trackProjectPreferencesService,
        localStorageModel,
        sessionStorageModel,
        globalNotificationsModel
      ) {
        this.$onInit = function () {
          this.contractor =
            contractorProjectPreferencesService.prepareContractor(this.data)
          this.showOnPage = false

          this.setAnalytics()
          if (this.data.contractorTypeTaxonomy) {
            this.projectTypeGroups =
              attributeConversionService.underscoreToCamelDeepAttributes(
                this.data.contractorTypeTaxonomy.project_type_groups
              )
          }

          if (this.data.contractorTypes) {
            this.contractorTypeOptions = this.data.contractorTypes
          }

          if (getURLParameter('referral_source') === 'join_proposals') {
            return
          }

          switch (this.containerType) {
            case 'modal':
              this.openModal()
              break
            case 'page':
              this.showOnPage = true
              trackProjectPreferencesService.openModal(
                this.contractor.analytics
              )
              break
          }
        }

        this.openModal = function () {
          this.hasNoInterferingModals(() => {
            if (this.setViewCookie()) {
              if (modalScreenService.isSmallScreen(615)) {
                document.location =
                  urlConstants.contractor.verifyProjectPreferencesURL +
                  '?src=' +
                  encodeURIComponent(
                    (location.pathname + location.search).substr(1)
                  ) +
                  (getURLParameter('referral_source')
                    ? '&referral_source=' + getURLParameter('referral_source')
                    : '')
              } else {
                trackProjectPreferencesService.openModal(
                  this.contractor.analytics
                )

                modalScreenService.initDetached({
                  controller: 'ProjectPreferencesModalCtrl',
                  templateUrl:
                    '_components/components/project_preferences/project_preferences_modal',
                  windowClass: 'v3-modal-simple project-preferences-modal',
                  inputData: {
                    contractor: this.contractor,
                    contractorTypeOptions: this.contractorTypeOptions,
                    projectTypeGroups: this.projectTypeGroups,
                  },
                  keyboard: false,
                  backdrop: 'static',
                })
              }
            }
          })
        }

        this.redirectToSource = function () {
          var path = getURLParameter('src')
          document.location =
            path && path.length
              ? '/' + decodeURIComponent(path)
              : urlConstants.contractor.servicesURL
        }

        this.hasNoInterferingModals = function (onSuccess) {
          var nextStepsModalWillShow =
            localStorageModel.get('claimedViaLead') ||
            sessionStorageModel.get('nextStepsModalTriggered')
          const isExistingModalOpen =
            getURLParameter('bid_modal') ||
            getURLParameter('archive_lead_modal') ||
            getURLParameter('tos') ||
            nextStepsModalWillShow

          if (isExistingModalOpen) return

          globalNotificationsModel.getModalToShow(true).then(({ alertKey }) => {
            if (!alertKey) onSuccess()
          })
        }

        this.setAnalytics = function () {
          this.contractor.analytics = this.contractor.analytics || {}
          this.contractor.analytics.referralSource =
            getURLParameter('referral_source') || 'page'
          this.contractor.analytics.location = 'project_preferences_modal'
        }

        this.setViewCookie = function () {
          if ($.cookie('bzProjectPrefsSet') === undefined) {
            $.cookie('bzProjectPrefsSet', 1, { expires: 1, path: '/' })

            return !this.isContractorDashboard()
          } else {
            let count = parseInt($.cookie('bzProjectPrefsSet'))

            if (!isNaN(count) && count === 1) {
              $.cookie('bzProjectPrefsSet', 2, { expires: 1, path: '/' })
              return true
            } else {
              return false
            }
          }
        }

        this.onSubmit = function () {
          trackProjectPreferencesService.submitModal(
            this.contractor.analytics,
            function () {
              this.redirectToSource()
            }.bind(this)
          )
        }

        this.onCancel = function () {
          trackProjectPreferencesService.exitModal(
            this.contractor.analytics,
            function () {
              this.redirectToSource()
            }.bind(this)
          )
        }

        this.isContractorDashboard = () => {
          return [
            urlConstants.contractor.cockpitURL,
            urlConstants.contractor.dashboardURL,
          ].includes(location.pathname)
        }
      },
    ],
  })
