export default [
  function () {
    return {
      getAll: function () {
        return [
          { value: 'not submitted', text: 'Not submitted' },
          { value: 'submitted', text: 'In process' },
          { value: 'acquired', text: 'Building permit ready' },
        ]
      },
    }
  },
]
