import ReferralAgreementLinkModule from 'StandaloneComponents/referral_agreement_link_module'

import surveyScreen from './components/survey_screen_component.js'
import referralAgreementModal from './components/referral_agreement_modal'
import referralAgreementModalTest from './components/referral_agreement_modal_test'
import genericModalContainer from './components/generic/generic_modal_container'
import referralAgreementModalService from './services/referral_agreement_modal_service'
import signatureInput from '../../components/signature_input_component'
import referralAgreementLink from './directives/referral_agreement_link_directive'

angular
  .module('bzModalModule', [ReferralAgreementLinkModule])
  .component('signatureInput', signatureInput)
  .component('surveyScreen', surveyScreen)
  .component('referralAgreementModal', referralAgreementModal)
  .component('referralAgreementModalTest', referralAgreementModalTest)
  .component('genericModalContainer', genericModalContainer)
  .factory('referralAgreementModalService', referralAgreementModalService)
  .directive('referralAgreementLink', referralAgreementLink)
