angular.module('bzChartModule').directive('animatedCheckmark', [
  'urlService',
  function (urlService) {
    return {
      restrict: 'AE',
      scope: {},
      templateUrl: urlService.templateUrl(
        '_components/directives/animated_checkmark'
      ),
    }
  },
])
