/* global angular, $ */
angular.module('bzCommonUtilitiesModule').factory('cbsaModel', [
  'bzHttp',
  'urlConstants',
  function (bzHttp, urlConstants) {
    return {
      cbsaPath: urlConstants.api.v1.path + 'cbsas',
      search: function (cbsaKeyword) {
        return bzHttp.get(this.cbsaPath, {
          params: { cbsa_keyword: cbsaKeyword },
        })
      },
    }
  },
])
