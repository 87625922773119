angular.module('bzReviewsModule').controller('ReviewModalCtrl', [
  '$uibModalInstance',
  'attributeConversionService',
  'reviewFormScreenService',
  'trackReviewFormService',
  'inputData',
  'urlConstants',
  'urlService',
  function (
    $uibModalInstance,
    attributeConversionService,
    reviewFormScreenService,
    trackReviewFormService,
    inputData,
    urlConstants,
    urlService
  ) {
    this.$onInit = function () {
      this.review = attributeConversionService.underscoreToCamelDeepAttributes(
        inputData.review
      )
      this.contractor =
        attributeConversionService.underscoreToCamelDeepAttributes(
          inputData.contractor
        )
      this.screenService = reviewFormScreenService
      trackReviewFormService.view({ formType: 'modal' })
    }

    this.close = function () {
      trackReviewFormService.close({})
      $uibModalInstance.dismiss('cancel')
    }

    this.closeIconUrl = function () {
      return urlConstants.s3.assetUrl + '_thumbnails/x-close-icon.png'
    }

    this.backButtonUrl = function () {
      return urlConstants.s3.assetUrl + '_thumbnails/back-button2.png'
    }

    this.contractorThumbnail = function (size) {
      if (size === undefined) {
        size = 'square_150'
      }

      if (this.contractor && this.contractor.logo) {
        return urlService.imgCropUrl(this.contractor.logo, size)
      } else {
        return (
          urlConstants.s3.assetUrl + '_thumbnails/contractor-default-icon.png'
        )
      }
    }

    this.contractorProfileUrl = function () {
      if (this.contractor) {
        return urlConstants.contractor.profileURL + this.contractor.alias
      }
    }

    this.postReviewScreen = function () {
      return reviewFormScreenService.currentScreen === 'post_review'
    }
  },
])
