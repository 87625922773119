/* global bz_app, angular, LeadsDashboardTrack, UserData */
import {
  trackApplyButtonClick,
  trackLandingPageView,
} from 'Vanilla/contractor_subscriptions_tracking.js'
import template from './leads_advertising_container.html'

bz_app.component('leadsListAdvertising', {
  bindings: {
    leadType: '<',
    contractorId: '<',
    leads: '<',
  },
  template,
  controller: [
    '$timeout',
    'urlConstants',
    'contractorSubscriptionsModel',
    'applicationConstants',
    function (
      $timeout,
      urlConstants,
      contractorSubscriptionsModel,
      applicationConstants
    ) {
      this.$onInit = function () {
        this.urlConstants = urlConstants

        if (this.leads[this.leadType].length === 0)
          this.trackEmptyDashboardView()

        this.adImgURL =
          applicationConstants.filepicker.stock_image_url +
          '/_thumbnails/icons/leads-advertising-icon.png'
      }

      this.getStartedClick = (buttonActions) => {
        buttonActions.showLoading()

        LeadsDashboardTrack({
          contractorId: this.contractorId,
        }).leadEmptyAdPromoClick(this.leadType, 'CTA')
        trackApplyButtonClick('advertising', 'empty leads ad promo')

        contractorSubscriptionsModel
          .create({
            contractorId: this.contractorId,
            planSlug: 'Advertising',
          })
          .then(buttonActions.showSucceeded, (error) => {
            buttonActions.showFailed()
            $timeout(() => {
              buttonActions.showCta()
            }, 2000)
          })
      }

      this.learnMoreLinkClick = () => {
        LeadsDashboardTrack({
          contractorId: this.contractorId,
        }).leadEmptyAdPromoClick(this.leadType, 'learn more')
        trackLandingPageView('empty leads ad promo', '#buildzoom-advertising')
      }

      this.trackEmptyDashboardView = function () {
        LeadsDashboardTrack({
          contractorId: this.contractorId,
        }).leadEmptyAdPromoView(this.leadType)
      }
    },
  ],
})
