import Geocoder from 'Vanilla/services/geocoder'

/* global bz_app, UserData, getURLParameter */
angular
  .module('bzProjectPreferencesModule')
  .controller('ContractorProjectPreferencesCtrl', [
    '$scope',
    '$timeout',
    'contractorProjectPreferencesModel',
    'formValidationService',
    'attributeConversionService',
    'analyticsEventsModel',
    'projectPreferencesModel',
    'confirmSwitchContractorTypeModalService',
    'locationService',
    function (
      $scope,
      $timeout,
      contractorProjectPreferencesModel,
      formValidationService,
      attributeConversionService,
      analyticsEventsModel,
      projectPreferencesModel,
      confirmSwitchContractorTypeModalService,
      locationService
    ) {
      this.init = function (data) {
        this.unsavedChanges = false

        this.formErrors = {
          invalidLocation: false,
          invalidJobSize: false,
          invalidProjectTypeSelection: false,
        }

        this.processPotentialAnalyticsEvent()
        this.setContractorProfile(data.projectPreferences)
        this.setContractorPropertyType()
        this.setContractorPropertyTypeOptions()
        this.setProjectTypesTaxonomy(data.contractorProjectTypesTaxonomy)

        if (data.contractorTypes) {
          this.contractorTypeOptions = data.contractorTypes
        }

        this.getLocation = locationService.find_address
        this.geocoder = new Geocoder()
        this.geocoder.recordContractorLocationIfValid(this.contractorProfile)
      }

      this.setContractorProfile = function (contractorProjectPreferences) {
        var contractorTypeId = contractorProjectPreferences.contractor_type
          ? contractorProjectPreferences.contractor_type.id
          : null
        this.contractorProfile = {}
        this.contractorProfile.type = {
          id: contractorTypeId,
        }
        this.contractorProfile.workPreferences =
          attributeConversionService.underscoreToCamelDeepAttributes(
            contractorProjectPreferences.work_preferences
          )
        this.contractorProfile.extraAttributes =
          attributeConversionService.underscoreToCamelDeepAttributes(
            contractorProjectPreferences.extra
          )
        this.contractorProfile.latitude = contractorProjectPreferences.latitude
        this.contractorProfile.longitude =
          contractorProjectPreferences.longitude

        this.contractorProfile.streetAddressUnit =
          contractorProjectPreferences.street_address_unit
        this.contractorProfile.streetAddress =
          contractorProjectPreferences.street_address
        this.contractorProfile.zipcode = contractorProjectPreferences.zipcode
        this.contractorProfile.city = contractorProjectPreferences.city
        this.contractorProfile.state = contractorProjectPreferences.state
        this.contractorProfile.lat = contractorProjectPreferences.lat
        this.contractorProfile.lng = contractorProjectPreferences.lng

        if (!this.contractorProfile.extraAttributes.travelRange) {
          this.contractorProfile.extraAttributes.travelRange = 35
        }

        if (!this.contractorProfile.workPreferences) {
          this.contractorProfile.workPreferences = {}
        }

        this.previousContractorTypeId = this.contractorProfile.type.id
      }

      this.setContractorPropertyType = function () {
        if (this.contractorProfile.workPreferences) {
          if (
            this.contractorProfile.workPreferences.commercial &&
            this.contractorProfile.workPreferences.residential
          ) {
            this.contractorPropertyType = 3
          } else if (this.contractorProfile.workPreferences.commercial) {
            this.contractorPropertyType = 2
          } else if (this.contractorProfile.workPreferences.residential) {
            this.contractorPropertyType = 1
          } else {
            this.contractorPropertyType = null
          }
        }
      }

      this.setProjectTypesFromApi = function () {
        var taxonomy
        if (this.contractorProfile.type.id == 1) {
          taxonomy = projectPreferencesModel.getTaxonomy()
        } else {
          taxonomy = projectPreferencesModel.getTaxonomyForContractorByType(
            UserData.contractor_id(),
            this.contractorProfile.type.id
          )
        }

        return taxonomy.then((projectTypeGroups) => {
          return this.setProjectTypesTaxonomy({
            projectTypeGroups: projectTypeGroups,
          })
        })
      }

      this.processPotentialAnalyticsEvent = function () {
        var maydayReason = getURLParameter('mayday_reason')
        if (maydayReason) {
          var maydayReasonNumber = parseInt(maydayReason)
          this.recordAnalyticsEvent(maydayReasonNumber)
        }
      }

      this.recordAnalyticsEvent = function (maydayReasonNumber) {
        var data = {
          entityType: 'Contractor',
          entityId: UserData.contractor_id(),
          metric: 'selected_mayday_reason_' + maydayReasonNumber,
          location: 'email',
        }
        analyticsEventsModel.create(data)
      }

      this.updateContractorTypeId = function () {
        this.setProjectTypesFromApi()
        this.unsavedChanges = true
      }

      this.updateContractorPropertyType = function (type) {
        switch (type) {
          case 1:
            this.contractorProfile.workPreferences.commercial = 0
            this.contractorProfile.workPreferences.residential = 1
            break
          case 2:
            this.contractorProfile.workPreferences.commercial = 1
            this.contractorProfile.workPreferences.residential = 0
            break
          case 3:
            this.contractorProfile.workPreferences.commercial = 1
            this.contractorProfile.workPreferences.residential = 1
            break
        }

        this.unsavedChanges = true
      }

      this.setContractorPropertyTypeOptions = function () {
        this.contractorPropertyTypeOptions = [
          { value: 1, text: 'Residential' },
          { value: 2, text: 'Commercial' },
          { value: 3, text: 'Residential & Commercial' },
        ]
      }

      this.setProjectTypesTaxonomy = function (taxonomy) {
        if (taxonomy) {
          this.taxonomy =
            attributeConversionService.underscoreToCamelDeepAttributes(taxonomy)
        }
      }

      this.validateContractorZipcode = () => {
        return formValidationService
          .isValidZipCodeFromDataset(this.contractorProfile.zipcode)
          .then((response) => {
            this.formErrors.invalidLocation = response.success
              ? !response.valid
              : true
          })
          .catch((error) => {
            this.formErrors.invalidLocation = true
          })
      }

      this.onZipcodeChange = () => {
        if (this.contractorProfile.zipcode.length !== 5) {
          this.formErrors.invalidLocation = true
          return
        }

        this.validateContractorZipcode().finally(() => {
          if (!this.formErrors.invalidLocation) {
            this.geocoder
              .geocode(this.contractorProfile.zipcode)
              .then((result) => {
                this.contractorProfile.city = result.city
                this.contractorProfile.state = result.state
                $scope.$applyAsync()
              })
          }
        })
      }

      this.onStreetSelected = ($item) => {
        if (!$item) return

        let query = $item.street + ',' + $item.city + ',' + $item.state
        this.contractorProfile.streetAddress = $item.street // make input not show [object, object] briefly

        this.geocoder.geocode(query).then((result) => {
          this.geocoder.assignContractorGeocodeResult(
            this.contractorProfile,
            result
          )
          this.validateContractorZipcode()
          $scope.$applyAsync()
        })
      }

      this.validateMaxJobValue = function (maxJobValue) {
        this.formErrors.invalidJobSize = maxJobValue > 1000000000
      }

      this.isNotValid = function () {
        return Object.values(this.formErrors).includes(true)
      }

      this.updateProjectTypesOffered = function (projectTypesOffered) {
        if (projectTypesOffered.length == 0) {
          this.formErrors.invalidProjectTypeSelection = true
        } else {
          this.formErrors.invalidProjectTypeSelection = false
        }

        this.contractorProfile.projectTypesOffered = projectTypesOffered
      }

      this.onSaveSuccess = () => {
        this.unsavedChanges = false
      }

      this.openConfirmSwitchContractorTypeModal = function () {
        confirmSwitchContractorTypeModalService.show().result.then(
          (shouldChangeProjectPreferences) => {
            if (shouldChangeProjectPreferences) {
              this.updateContractorTypeId()
              this.previousContractorTypeId = this.contractorProfile.type.id
            } else {
              this.contractorProfile.type.id = this.previousContractorTypeId
            }
          },
          () => {
            this.contractorProfile.type.id = this.previousContractorTypeId
          }
        )
      }
    },
  ])
