/* global angular */
angular.module('bzAnalyticsModule').factory('analyticsEventsCountsModel', [
  '$http',
  'urlConstants',
  'modelService',
  function ($http, urlConstants, modelService) {
    return {
      apiAnalyticsEventsCountsPath:
        urlConstants.api.v3.path + 'analytics_events_counts',

      incrementCount: function (params) {
        return $http.post(
          this.apiAnalyticsEventsCountsPath,
          modelService.toUnderscore(params)
        )
      },
    }
  },
])
