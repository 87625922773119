/* global MessagesAPI, bz_app, angular */
bz_app.factory('conversationModel', [
  '$http',
  '$rootScope',
  'userInfoService',
  'attributeConversionService',
  'urlConstants',
  function (
    $http,
    $rootScope,
    userInfoService,
    attributeConversionService,
    urlConstants
  ) {
    return {
      data: { id: 0 },
      messages_api: new MessagesAPI(),
      apiConversationsPath: urlConstants.api.v1.path + 'conversations',
      set_id: function (id) {
        this.clearData()
        this.data.id = id
      },
      clearData: function () {
        this.data = { id: 0 }
      },
      load: function (callback) {
        var that = this
        this.messages_api
          .load_conversation(this.data.id)
          .then(function (conversation) {
            $rootScope.$apply(function () {
              that.data = conversation

              // look up conversation participant
              that.data.participant = userInfoService.participant(
                that.data,
                that.data.participant_user_id
              )

              // look up messages senders
              angular.forEach(that.data.messages, function (message) {
                message.sender = userInfoService.participant(
                  that.data,
                  message.sender_user_id
                )
                message.recipient = Object.values(that.data.users).find(
                  (user) => user.id !== message.sender_user_id
                )
                message.attachments =
                  attributeConversionService.underscoreToCamelDeepAttributes(
                    that.data.attachments[message.id]
                  ) || null
                message.serviceRequest = conversation.service_request
              })
            })

            if (typeof callback === 'function') {
              callback(conversation)
            }
          })
      },
      loadConversations: function (status, projectId, userId, page, pageSize) {
        status = !status ? '' : status
        projectId = !projectId ? '' : projectId
        userId = !userId ? '' : userId
        page = !page ? '' : page
        pageSize = !pageSize ? '' : pageSize

        return $http
          .get(
            this.apiConversationsPath +
              '?status=' +
              status +
              '&project_id=' +
              projectId +
              '&user_id=' +
              userId +
              '&page=' +
              page +
              '&page_size=' +
              pageSize
          )
          .then(this.extractData)
      },
      loadByStatus: function (status, page, pageSize) {
        status = !status ? 'inbox' : status
        return this.loadConversations(status, '', '', page, pageSize)
      },
      markAsRead: function (conversationId) {
        if (conversationId) {
          this.set_id(conversationId)
        }

        return this.messages_api.mark_conversation_as_read(this.data.id)
      },
      archive: function () {
        return this.messages_api.archive_conversation(this.data.id)
      },
      unarchive: function () {
        return this.messages_api.unarchive_conversation(this.data.id)
      },
      getUnreadCount: function () {
        return this.messages_api.get_unread_conversation_count()
      },
      extractData: function (response) {
        if (response.data) {
          return response.data
        } else {
          return null
        }
      },
    }
  },
])
