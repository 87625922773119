import 'ContractorInvoices/models/_invoice_model'
/* global bz_app */
bz_app.factory('invoiceInstance', [
  '$filter',
  'invoiceModel',
  function ($filter, invoiceModel) {
    // returns a function to be able to instantiate
    return function () {
      this.id = null
      this.number = null
      this.projectId = null
      this.project = { id: null, title: null }
      this.contractor = { id: null, businessName: null }
      this.recipient = { name: null, email: '', phone: null, address: null }
      this.items = [{ description: null, total: null }]
      this.notes = null
      ;(this.paymentMethod = { buildzoom: true, description: '' }),
        (this.sentAt = null)
      this.createdAt = null
      this.updatedAt = null
      this.dueAt = null
      this.amount = null
      this.status = 'draft'

      this.set = function (input) {
        if (input) {
          if (input.id !== undefined) {
            this.id = input.id
          }
          if (input.number !== undefined) {
            this.number = input.number
          }
          if (input.sent_at !== undefined) {
            this.sentAt = input.sent_at
          }
          if (input.dueAt !== undefined) {
            this.dueAt = input.dueAt
          }
          if (input.createdAt !== undefined) {
            this.createdAt = input.createdAt
          }
          if (input.updatedAt !== undefined) {
            this.updatedAt = input.updatedAt
          }
          if (input.amount !== undefined) {
            this.amount = input.amount
          }
          if (input.status !== undefined) {
            this.status = input.status
          }

          if (input.project !== undefined) {
            if (input.project.id !== undefined) {
              this.project.id = input.project.id
            }
            if (input.project.title !== undefined) {
              this.project.title = input.project.title
            }
          }
          if (input.contractor) {
            if (input.contractor.id !== undefined) {
              this.contractor.id = input.contractor.id
            } else if (input.contractor.id !== undefined) {
              this.contractor.id = input.contractor_id
            }
            if (input.contractor.businessName !== undefined)
              this.contractor.businessName = input.contractor.businessName
            else if (input.contractor.business_name !== undefined)
              this.contractor.businessName = input.contractor.business_name
            if (input.contractor.is_merchant !== undefined)
              this.contractor.isMerchant = input.contractor.is_merchant
          }

          if (input.recipient) {
            if (input.recipient.name !== undefined) {
              this.recipient.name = input.recipient.name
            }
            if (
              input.recipient.email !== undefined &&
              input.recipient.email !== null
            ) {
              this.recipient.email = input.recipient.email
            }
            if (input.recipient.phone !== undefined) {
              this.recipient.phone = input.recipient.phone
            }
            if (input.recipient.address !== undefined) {
              this.recipient.address = input.recipient.address
            }
          }

          if (input.paymentMethod) {
            if (input.paymentMethod.buildzoom !== undefined) {
              this.paymentMethod.buildzoom = input.paymentMethod.buildzoom
            }
            if (input.paymentMethod.description !== undefined) {
              this.paymentMethod.description = input.paymentMethod.description
            }
          }

          if (input.notes !== undefined) {
            this.notes = input.notes
          }
          if (input.items !== undefined && input.items.length) {
            this.items = input.items
          }

          return true
        } else {
          return false
        }
      }

      this.initBase = (status, amount, dueAt) => {
        this.set({
          updatedAt: null,
          createdAt: null,
          dueAt: dueAt,
          status: status,
          amount: amount,
        })
      }

      this.create = function () {
        return invoiceModel.create(this)
      }

      this.update = function () {
        return invoiceModel.update(this)
      }

      this.updateBasicInfo = function () {
        return invoiceModel.update(this, { excludeItems: true })
      }

      this.updateDetails = function () {
        return invoiceModel.update({ id: this.id, items: this.items })
      }

      this.send = function () {
        return invoiceModel.send(this)
      }

      this.total = function () {
        var _total = 0
        if (this.items && this.items.length) {
          this.items.forEach(function (item) {
            if (item.total) {
              _total += item.total
            }
          })
        }

        return _total
      }

      this.addNewItem = function () {
        this.items.push({ description: null, total: null })
      }

      this.removeItemByIndex = function (index) {
        if (this.items && this.items.length) {
          this.items.splice(index, 1)

          if (!this.items.length) {
            this.addNewItem()
          }
        }
      }

      this.hasItems = function () {
        if (this.items && this.items.length) {
          var present = false
          this.items.forEach(function (item) {
            if (item && item.description && item.total) {
              present = true
            }
          })

          return present
        } else {
          return false
        }
      }

      this.isDraft = function () {
        if (this.status === 'draft') {
          return true
        }

        return false
      }

      this.invoiceDate = function () {
        var date = this.dueAt ? this.dueAt : this.updatedAt
        if (this.status === 'paid') {
          return $filter('date')(date, 'MM/dd/yyyy')
        } else if (this.status === 'pending') {
          return 'pending'
        } else {
          return $filter('date')(date, 'MM/dd/yyyy') || 'due now'
        }
      }
    }
  },
])
