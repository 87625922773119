/* global bz_app */
bz_app.component('radiobutton', {
  bindings: {
    radiobuttonSelected: '=',
    radiobuttonDisabled: '=',
    radiobuttonReadOnly: '<',
    radiobuttonInline: '<',
    radiobuttonOnByDefault: '@',
    radiobuttonName: '@',
    onClickCallback: '&',
    tickMarkShape: '@',
  },
  template:
    '<div class="form-radiobutton" ' +
    "	ng-class=\"{ 'form-radiobutton-selected': $ctrl.radiobuttonSelected, 'form-radiobutton-disabled': $ctrl.radiobuttonDisabled, 'form-radiobutton-readonly': $ctrl.radiobuttonReadOnly, 'form-radiobutton-inline': $ctrl.radiobuttonInline }\"" +
    '	ng-click="$ctrl.validatedClick()">' +
    '		<i ng-if="($ctrl.tickMarkShape !== \'circle\')" class="fas fa-square""></i>' +
    '		<div ng-if="($ctrl.tickMarkShape === \'circle\')" class="form-radiobutton-circle-tick"></div>' +
    '</div>',
  controller: [
    '$rootScope',
    function ($rootScope) {
      this.$onInit = function () {
        if (!this.tickMarkShape) {
          this.tickMarkShape = 'circle'
        }

        // listen for selection of other radiobuttons in the group name
        $rootScope.$on(
          this.radiobuttonName,
          function ($event, data) {
            if (data.elementId !== this.elementId) {
              this.radiobuttonSelected = false
            }
          }.bind(this)
        )

        if (this.radiobuttonOnByDefault === 'true') {
          this.validatedClick()
        }
      }

      this.generateElementId = function () {
        return Math.floor(new Date().getTime())
      }

      this.notifyGroupOfSelection = function () {
        if (this.radiobuttonName) {
          if (!this.elementId) {
            this.elementId = this.generateElementId()
          }

          $rootScope.$broadcast(this.radiobuttonName, {
            elementId: this.elementId,
          })
        }
      }

      this.validatedClick = function () {
        if (!this.radiobuttonDisabled && !this.radiobuttonReadOnly) {
          if (!this.radiobuttonSelected) {
            this.radiobuttonSelected = true
            this.notifyGroupOfSelection()
          }

          if (this.onClickCallback) {
            this.onClickCallback()
          }
        }
      }
    },
  ],
})
