/* globals $, jQuery */

function showLoginModal() {
  $('#new_session_Modal').modal({ show: true })
  $('#new_session_Modal').on('shown.bs.modal', function () {
    setTimeout(function () {
      $('input#password').focus()
    }, 500)
  })
}

window.showLoginModal = showLoginModal

$(document).ready(function () {
  /*	listens to uploading of user settings photo	*/
  $('.profile_image_upload_form .filepicker_field input').change(function (e) {
    $('.profile_image').html(
      "<div style='width: 160px; height: 160px; background-color: lightgrey; '></div>"
    )
    $('.profile_image').spin()
    var file = e.originalEvent.fpfile
    var owner_id = $('.profile_image').attr('data-user-id')
    var data = {
      filename: file.filename,
      filepicker_slug: file.url,
      s3_slug: file.key,
      image_type: 'profile',
      owner_type: 'homeowner',
      owner_id: owner_id,
    }
    $.ajax({
      type: 'POST',
      url: '/api/v1/images',
      data: data,
      dataType: 'json',
      success: function (response, status, xhr) {
        $('.profile_image').spin(false)
        $('.profile_image').html(
          "<img src='" +
            response.cloudfront_slug +
            "' alt='user profile image'>"
        )
        if ($('#uploadProfileImageModal').length) {
          setTimeout(show_modals, 1000)
        }
      },
    })
  })

  function show_modals() {
    $('#uploadProfileImageModal').modal('hide')
    $('#serviceRequestModal').modal('show')
  }
})
