/* global angular, isMobile, $ */
angular.module('bzBidModule').factory('bidModalService', [
  'urlService',
  '$uibModal',
  'modalScreenService',
  'urlConstants',
  function (urlService, $uibModal, modalScreenService, urlConstants) {
    return {
      show: function (inputData) {
        if (modalScreenService.isSmallScreen(750)) {
          document.location =
            urlConstants.contractor.leadBidURL +
            inputData.lead.project.id +
            '?src=' +
            encodeURIComponent(this.currentPath())
        } else {
          this.openModal(inputData)
        }
      },
      openModal: function (inputData) {
        var modalInstance = $uibModal.open({
          templateUrl: urlService.templateUrl('leads/unlocked_lead/bid_modal'),
          controller: 'BidModalCtrl',
          controllerAs: '$ctrl',
          bindToController: true,
          windowClass:
            'v3-modal v3-modal-simple extra-small lead-modal lead-bid-modal',
          resolve: {
            inputData: function () {
              return inputData
            },
          },
        })

        return modalInstance
      },
      currentPath: function () {
        if (location.pathname.indexOf('/lead/') === 0) {
          return location.pathname.substr(1)
        } else {
          return (location.pathname + location.search).substr(1)
        }
      },
    }
  },
])
