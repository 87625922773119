import queryString from 'query-string'

const globalNotificationsModel = [
  '$http',
  'attributeConversionService',
  function ($http, attributeConversionService) {
    return {
      getBannerToShow(shouldCacheResponse = true) {
        return $http.get('/api/v3/banner_to_show', {
          cache: shouldCacheResponse,
          params: {
            path: location.pathname,
            is_mobile: isMobile.check({ phoneOnly: true }) ? 1 : 0,
          },
        })
      },

      getModalToShow(shouldCacheResponse = true) {
        let urlParams = queryString.parse(location.search)

        return $http
          .get('/api/v3/modal_to_show', {
            cache: shouldCacheResponse,
            params: {
              path: location.pathname,
              is_mobile: isMobile.check({ phoneOnly: true }) ? 1 : 0,
              is_unsubscribe_request: urlParams.unsubscribe == '1',
              subscription_type: urlParams.subscription_type,
              communication_type: urlParams.communication_type,
            },
          })
          .then(({ data }) => {
            return attributeConversionService.underscoreToCamelDeepAttributes(
              data
            )
          })
      },
    }
  },
]

export default globalNotificationsModel
