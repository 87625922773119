/* global bz_app */
const dateService = [
  '$filter',
  function ($filter) {
    return {
      prettyDate: function (date, format, timezone) {
        if (date) {
          if (format === undefined) {
            format = 'MMM d, y h:mm a'
          }
          return $filter('date')(new Date(date), format, timezone)
        } else {
          return ''
        }
      },
      to12Hours: function (time) {
        var date = new Date()
        var split = time.split(':')
        date.setHours(split[0], split[1], 0)

        return $filter('date')(date, 'h:mm a')
      },
      to24Hours: function (time) {
        var hours = parseInt(time.substr(0, 2))
        if (time.toLowerCase().indexOf('am') !== -1 && hours === 12) {
          time = time.replace('12', '0')
        }
        if (time.toLowerCase().indexOf('pm') !== -1 && hours < 12) {
          time = time.replace(hours, hours + 12)
        }
        return time
          .toLowerCase()
          .replace(/(am|pm)/, '')
          .trim()
      },
      toISOString: function (date) {
        return (
          date.getFullYear() +
          '-' +
          this.pad(date.getMonth() + 1, 2) +
          '-' +
          this.pad(date.getDate(), 2) +
          'T' +
          this.pad(date.getHours(), 2) +
          ':' +
          this.pad(date.getMinutes(), 2) +
          this.convertTimezoneOffsetToHours(date.getTimezoneOffset())
        )
      },
      convertTimezoneOffsetToHours: function (offset) {
        return (
          (offset < 0 ? '+' : '-') +
          this.pad(parseInt(Math.abs(offset / 60)), 2) +
          ':' +
          this.pad(Math.abs(offset % 60), 2)
        )
      },
      pad: function (number, length) {
        var str = '' + number

        while (str.length < length) {
          str = '0' + str
        }

        return str
      },
      businessDaysApart: function (firstDate, secondDate) {
        var businessDays = 0
        var firstDay = firstDate.getDay() + 1
        var daysApart = this.timeDifferenceInDays(secondDate - firstDate)
        for (var i = firstDay; i < firstDay + daysApart; i++) {
          if (i < 6) {
            businessDays++
          } else if (i % 7 > 0 && i % 7 < 6) {
            businessDays++
          }
        }
        return businessDays
      },
      timeDifferenceInDays: function (milliseconds) {
        return Math.floor(milliseconds / 1000 / 60 / 60 / 24)
      },
      timeDifferenceInMinutes: function (milliseconds) {
        return Math.floor(milliseconds / 1000 / 60)
      },
      addDays: function (date, daysToAdd) {
        date.setDate(date.getDate() + daysToAdd)
        return date
      },
      addSeconds: function (date, secondsToAdd) {
        date.setSeconds(date.getSeconds() + secondsToAdd)
        return date
      },
      prettyShortDate: function (date, timeZone) {
        if (!date) {
          return ''
        }

        return this.prettyDate(new Date(date), 'shortDate', timeZone)
      },
      daysToSeconds: function (days) {
        return days * 86400
      },
      tomorrow: function () {
        var tomorrow = new Date()
        return new Date(tomorrow.setDate(tomorrow.getDate() + 1))
      },
    }
  },
]

export default dateService
