import bugsnag from 'Vanilla/clients/bugsnag_client'

export const buildSmsIntroMessage = (
  contractorId,
  coName,
  optionalMessageData = {}
) => {
  const variation = contractorId % 5
  const { serviceRequestTitle, poName, conciergeName } = optionalMessageData

  const formattedSrTitle = serviceRequestTitle ? ` ${serviceRequestTitle}` : ''
  const formattedPoName = poName ? ` ${poName}` : ''
  const formattedConciergeName = conciergeName ? ` ${conciergeName}` : ''

  switch (variation) {
    case 0:
      return (
        'Hi, when is the best time to talk about the' +
        formattedSrTitle +
        ' project you created on BuildZoom? Feel free to call me any ' +
        'time, or let me know when I can reach you. Thanks!'
      )
    case 1:
      return (
        'Hey' +
        formattedPoName +
        ', ' +
        "I'd love to find a time to meet and chat about your" +
        formattedSrTitle +
        ' project. ' +
        'Do you have time to meet this week? Let me know when works best or give me a call. Thanks!'
      )
    case 2:
      return (
        'Hi' +
        formattedPoName +
        ', ' +
        'I received your contact info from BuildZoom. I’d like to chat about your' +
        formattedSrTitle +
        ' project. What time would be best to call you? ' +
        'Feel free to give me a call as well.'
      )
    case 3:
      return (
        'Hi' +
        formattedPoName +
        ', ' +
        'I took a look at your' +
        formattedSrTitle +
        " project on BuildZoom and I'd love to discuss the job with you. " +
        'Let me know a good time to call, or feel free to call me. ' +
        'Looking forward to working with you!'
      )
    case 4:
      return (
        'Hi' +
        formattedPoName +
        ', ' +
        'Your BuildZoom project consultant' +
        formattedConciergeName +
        ' sent me the details ' +
        'about your' +
        formattedSrTitle +
        " project. I'd be happy to give you an estimate. " +
        "Let's do a quick phone call to go over the details. When can I reach you? " +
        'Feel free to call me directly as well.'
      )
    default:
      bugsnag.notify(
        'Unknown variant for default co sms intro message. CO:' +
          contractorId +
          ', MessageData:' +
          Object.assign({}, optionalMessageData, { coName })
      )
  }
}

export const buildEmailIntroMessage = (
  contractorId,
  coName,
  optionalMessageData = {}
) => {
  const variation = contractorId % 5
  const { serviceRequestTitle, poName, conciergeName } = optionalMessageData

  const formattedSrTitle = serviceRequestTitle ? ` ${serviceRequestTitle}` : ''
  const formattedPoName = poName ? ` ${poName}` : ''
  const formattedConciergeName = conciergeName ? ` ${conciergeName}` : ''

  switch (variation) {
    case 0:
      return (
        'Hi' +
        formattedPoName +
        ',\n\n' +
        'When is the best time to talk about the' +
        formattedSrTitle +
        ' project you created on BuildZoom? Feel free to call me any time, or let me know when I can reach you.' +
        '\n\nThanks,\n' +
        coName
      )
    case 1:
      return (
        'Hi' +
        formattedPoName +
        ',\n\n' +
        'I’d love to find a time to meet and chat about your' +
        formattedSrTitle +
        ' project. Do you have time to meet this week? Let me know when works best or give me a call. ' +
        '\n\nThanks,\n' +
        coName
      )
    case 2:
      return (
        'Hi' +
        formattedPoName +
        ',\n\n' +
        'I received your contact info from BuildZoom. I’d like to chat about your' +
        formattedSrTitle +
        ' project. What time would be best to call you? Feel free to give me a call as well.' +
        '\n\nThanks,\n' +
        coName
      )
    case 3:
      return (
        'Hi' +
        formattedPoName +
        ',\n\n' +
        'I took a look at your' +
        formattedSrTitle +
        ' project on BuildZoom and I’d love to discuss the job with you. Let me know a good time to call, or feel free to call me.\n\n' +
        'Looking forward to working with you!' +
        '\n\nBest,\n' +
        coName
      )
    case 4:
      return (
        'Hi' +
        formattedPoName +
        ',\n\n' +
        'Your BuildZoom project consultant' +
        formattedConciergeName +
        ' sent me the details about your' +
        formattedSrTitle +
        ' project. I’d be happy to give you an estimate.\n\n' +
        'Let’s do a quick phone call to go over the details. When can I reach you? Feel free to call me directly as well.' +
        '\n\nBest,\n' +
        coName
      )
    default:
      bugsnag.notify(
        'Unknown variant for default co intro message. CO:' +
          contractorId +
          ', MessageData:' +
          Object.assign({}, optionalMessageData, { coName })
      )
  }
}
