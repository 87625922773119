import template from './property_reports_container.html'

export default {
  bindings: {
    propertyReports: '<',
  },
  template,
  controller: [
    function () {
      this.$onInit = () => {
        this.showActive = true
        this.propertyReports = this.propertyReports.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
      }

      this.setActive = (showActive) => (this.showActive = showActive)
    },
  ],
}
