const API_PATH = bzGlobal.urlConstants.api.v1.path + 'admin_notifications'

export default class AdminNotificationApi {
  constructor(client) {
    this.client = client
  }

  notifyCheckout(checkoutId, eventData, errorMessage) {
    return this.sendAdminNotification('notify_checkout', {
      checkoutId,
      eventData,
      errorMessage,
    })
  }

  notifyConsultantAboutHire(serviceRequestId) {
    return this.sendAdminNotification('notify_consultant_about_hire', {
      serviceRequestId,
    })
  }

  notifyDataSubscriptionUpgrade() {
    return this.sendAdminNotification('notify_data_subscription_upgrade')
  }

  notifyDataSubscription(message) {
    return this.sendAdminNotification('notify_data_subscription', { message })
  }

  private

  sendAdminNotification(notificationType, notificationData) {
    this.client.post(API_PATH, { notificationType, notificationData })
  }
}
