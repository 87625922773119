/* global angular */
angular.module('bzProposalsModule').component('proposalsFormItem', {
  bindings: {
    formItem: '<?',
    template: '<?',
    formAuthToken: '<',
    onDestroy: '&',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('proposals/proposals_form_item')
    },
  ],
  controller: [
    'urlConstants',
    '$sce',
    'trackProposalService',
    'documentModel',
    function (urlConstants, $sce, trackProposalService, documentModel) {
      this.$onInit = function () {
        this.actionPath = $sce.trustAsResourceUrl(
          urlConstants.proposals.apiPath
        )

        this.isTemplate = !!this.template && !this.formItem
        this.headerClasses = {
          template: 'form-item-header-template',
          blank: 'form-item-header-blank',
          home: 'form-item-header-home',
          kitchen: 'form-item-header-kitchen',
          bathroom: 'form-item-header-bathroom',
        }
      }

      this.headerClass = function () {
        return this.isTemplate
          ? this.headerClasses['template']
          : this.headerClasses[this.formItem.alias]
      }

      this.headerClassObj = function () {
        if (!this._headerClassObj) {
          this._headerClassObj = {}
          this._headerClassObj[this.headerClass()] = true
        }

        return this._headerClassObj
      }

      this.handleSubmit = function () {
        if (this.formItem) {
          trackProposalService.proposalCreated({
            templateAlias: this.formItem.alias,
          })
        } else {
          trackProposalService.proposalCreated({
            customTemplateId: this.template.id,
          })
        }
      }

      this.setTemplateHeader = function () {
        var rootNode =
          this.template.documentNodes[this.template.rootDocumentNodeId]

        this.templateHeader = rootNode.attributes.name
      }

      this.verifyDeleteTemplate = function (ev) {
        ev.preventDefault()

        if (window.confirm('Are you sure you want to delete this template?')) {
          this.deleteTemplate()
        }
      }

      this.deleteTemplate = function () {
        this.onDestroy({ proposal: this.template })
        documentModel.destroy(this.template.shareSlug)
        trackProposalService.templateDestroyed(this.template.id)
      }
    },
  ],
})
