import { some } from 'lodash'

export default [
  'validationModel',
  function (validationModel) {
    this.$onInit = () => {
      this.validationStates = {}
    }

    this.validateAndSubmit = (e) => {
      e.preventDefault()
      const { LOADING, ERROR } = validationModel.validationStates

      if (
        some(
          this.validationStates,
          (validationState) => validationState === ERROR
        )
      ) {
        return false
      } else if (
        some(
          this.validationStates,
          (validationState) => validationState === LOADING
        )
      ) {
        // This is a HACK to handle the async validation done in the fields.
        // The fields should really expose a promise and this submit should listen for all of the promises to complete
        setTimeout(() => {
          this.validateAndSubmit(e)
        }, 1000)
      } else {
        this.submit()
      }
    }

    this.submit = () => {
      angular.element('#public-profile-form').submit()
    }
  },
]
