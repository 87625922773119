/* global angular */
angular.module('bzCommonUtilitiesModule').controller('AnimFrameRequesterCtrl', [
  '$scope',
  'animFrameService',
  function ($scope, animFrameService) {
    $scope.broadcastEvent = function () {
      this.$broadcast('animFrameReady')
      animFrameService.requestAnimFrame(this.broadcastEvent.bind(this))
    }

    animFrameService.requestAnimFrame($scope.broadcastEvent.bind($scope))
  },
])
