/* global $, BZAPI */
var InvoiceAPI = function () {
  this.create = function (invoice) {
    return $.when(
      $.ajax({
        url: this.invoice_api_path(),
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(invoice),
        dataType: 'json',
      })
    )
  }

  this.update = function (invoice) {
    return $.when(
      $.ajax({
        url: this.invoice_api_path() + '/' + invoice.id,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify(invoice),
        dataType: 'json',
      })
    )
  }

  this.send = function (invoice) {
    return $.when(
      $.ajax({
        url: this.invoice_api_path() + '/' + invoice.id + '/send',
        type: 'PATCH',
        contentType: 'application/json',
        //data: JSON.stringify(invoice),
        dataType: 'json',
      })
    )
  }

  this.markPaid = function (data) {
    return $.when(
      $.ajax({
        url: this.invoice_api_path() + '/' + data.id + '/pay',
        type: 'PATCH',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }

  this.contractorStatusTransition = function (data) {
    return $.when(
      $.ajax({
        url: this.invoice_api_path() + '/' + data.id + '/status_transition',
        type: 'PUT',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json',
      })
    )
  }
}

InvoiceAPI.prototype = new BZAPI()
InvoiceAPI.prototype.constructor = InvoiceAPI

window.InvoiceAPI = InvoiceAPI
