/* global bz_app */
bz_app.component('setupTooltip', {
  bindings: {
    tooltip: '<',
    onClose: '&',
    isClosable: '<',
  },
  template: `<div ng-if="$ctrl.isClosable" class="setup-tooltip-close">&times;</div>
		<div class="setup-tooltip-icon"></div>
		<div ng-bind-html="$ctrl.tooltip.title" class="setup-tooltip-title"></div>
		<div ng-bind-html="$ctrl.tooltip.body" class="setup-tooltip-body"></div>`,
  controller: [
    '$element',
    function ($element) {
      this.$onInit = function () {
        if (this.isClosable) {
          $element.click(($event) => {
            this.onClose($event)
          })
        }
      }
    },
  ],
})
