import keyFormatConverter from 'Vanilla/services/key_format_converter'

const propertyDataApplicationButton = [
  function () {
    return {
      restrict: 'AE',
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        propertyId: '@',
        streetAddress: '@',
        propertyDataSubscription: '@',
        referralSource: '@',
      },
      controller: [
        '$element',
        '$uibModal',
        function ($element, $uibModal) {
          this.$onInit = () => {
            let parsedPropertyDataSubscription

            if (this.propertyDataSubscription) {
              parsedPropertyDataSubscription = keyFormatConverter.jsonToCamel(
                this.propertyDataSubscription
              )
            }
            $element.click(() => {
              let referralSource = $element.attr('referral-source')
              this.referralSource = referralSource || null
              if (parsedPropertyDataSubscription) {
                this.modalInstance = $uibModal.open({
                  component: 'propertyDataRequestPopup',
                  windowClass: 'v3-modal-simple property-data-request-popup',
                  resolve: {
                    inputData: () => ({
                      remainingRequests:
                        parsedPropertyDataSubscription.remainingRequests,
                      subscriptionEndDate:
                        parsedPropertyDataSubscription.periodEndDate,
                      requiresEmailVerified:
                        parsedPropertyDataSubscription.requiresEmailVerified,
                      propertyId: this.propertyId,
                      referralSource: this.referralSource,
                    }),
                  },
                })
              } else {
                this.modalInstance = $uibModal.open({
                  component: 'propertyDataApplicationForm',
                  windowClass: 'v3-modal-simple full-width',
                  resolve: {
                    inputData: () => ({
                      isUserSignedIn: UserData.user_signed_in(),
                      propertyId: this.propertyId,
                      streetAddress: this.streetAddress,
                      referralSource: this.referralSource,
                    }),
                  },
                })
              }
            })
          }
        },
      ],
    }
  },
]

export default propertyDataApplicationButton
